//Gratuit 3 mois 
.gratuitMois {
	.content-img {
		width: 100%;
	    height: 100%;
	    display: table;
	    border-radius: 70% 40% 70% 40%;
		img {
			border-radius: 40% 70% 40% 70%;
		}
	}	
}

//Responsive 
@media (max-width: $media_991) {
    .gratuitMois {
    	figure {
    		text-align: center;
    	}
    }
}