//Fonctionnalités 
.section-interne {
    .video-bg {
        min-height: 55vh;
        .video-bg-content {
            position: relative; 
            height: 100%; 
            border-radius: 0.5rem;
        }
        .video-bg-replacer-img {
            background-image: url(/images/videos/working-in-office.webp);
        }
    }
}