@charset "UTF-8";
@font-face {
  font-family: "bootstrap-icons";
  src: url("/fonts/bootstrap-icons/bootstrap-icons.woff2") format("woff2"), url("/fonts/bootstrap-icons/bootstrap-icons.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-display: block;
  font-family: "bootstrap-icons";
  src: url("/fonts/bootstrap-icons/bootstrap-icons.woff2") format("woff2"), url("/fonts/bootstrap-icons/bootstrap-icons.woff") format("woff");
}
.bi::before,
[class^=bi-]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: "bootstrap-icons" !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bi-123::before {
  content: "\f67f";
}

.bi-alarm-fill::before {
  content: "\f101";
}

.bi-alarm::before {
  content: "\f102";
}

.bi-align-bottom::before {
  content: "\f103";
}

.bi-align-center::before {
  content: "\f104";
}

.bi-align-end::before {
  content: "\f105";
}

.bi-align-middle::before {
  content: "\f106";
}

.bi-align-start::before {
  content: "\f107";
}

.bi-align-top::before {
  content: "\f108";
}

.bi-alt::before {
  content: "\f109";
}

.bi-app-indicator::before {
  content: "\f10a";
}

.bi-app::before {
  content: "\f10b";
}

.bi-archive-fill::before {
  content: "\f10c";
}

.bi-archive::before {
  content: "\f10d";
}

.bi-arrow-90deg-down::before {
  content: "\f10e";
}

.bi-arrow-90deg-left::before {
  content: "\f10f";
}

.bi-arrow-90deg-right::before {
  content: "\f110";
}

.bi-arrow-90deg-up::before {
  content: "\f111";
}

.bi-arrow-bar-down::before {
  content: "\f112";
}

.bi-arrow-bar-left::before {
  content: "\f113";
}

.bi-arrow-bar-right::before {
  content: "\f114";
}

.bi-arrow-bar-up::before {
  content: "\f115";
}

.bi-arrow-clockwise::before {
  content: "\f116";
}

.bi-arrow-counterclockwise::before {
  content: "\f117";
}

.bi-arrow-down-circle-fill::before {
  content: "\f118";
}

.bi-arrow-down-circle::before {
  content: "\f119";
}

.bi-arrow-down-left-circle-fill::before {
  content: "\f11a";
}

.bi-arrow-down-left-circle::before {
  content: "\f11b";
}

.bi-arrow-down-left-square-fill::before {
  content: "\f11c";
}

.bi-arrow-down-left-square::before {
  content: "\f11d";
}

.bi-arrow-down-left::before {
  content: "\f11e";
}

.bi-arrow-down-right-circle-fill::before {
  content: "\f11f";
}

.bi-arrow-down-right-circle::before {
  content: "\f120";
}

.bi-arrow-down-right-square-fill::before {
  content: "\f121";
}

.bi-arrow-down-right-square::before {
  content: "\f122";
}

.bi-arrow-down-right::before {
  content: "\f123";
}

.bi-arrow-down-short::before {
  content: "\f124";
}

.bi-arrow-down-square-fill::before {
  content: "\f125";
}

.bi-arrow-down-square::before {
  content: "\f126";
}

.bi-arrow-down-up::before {
  content: "\f127";
}

.bi-arrow-down::before {
  content: "\f128";
}

.bi-arrow-left-circle-fill::before {
  content: "\f129";
}

.bi-arrow-left-circle::before {
  content: "\f12a";
}

.bi-arrow-left-right::before {
  content: "\f12b";
}

.bi-arrow-left-short::before {
  content: "\f12c";
}

.bi-arrow-left-square-fill::before {
  content: "\f12d";
}

.bi-arrow-left-square::before {
  content: "\f12e";
}

.bi-arrow-left::before {
  content: "\f12f";
}

.bi-arrow-repeat::before {
  content: "\f130";
}

.bi-arrow-return-left::before {
  content: "\f131";
}

.bi-arrow-return-right::before {
  content: "\f132";
}

.bi-arrow-right-circle-fill::before {
  content: "\f133";
}

.bi-arrow-right-circle::before {
  content: "\f134";
}

.bi-arrow-right-short::before {
  content: "\f135";
}

.bi-arrow-right-square-fill::before {
  content: "\f136";
}

.bi-arrow-right-square::before {
  content: "\f137";
}

.bi-arrow-right::before {
  content: "\f138";
}

.bi-arrow-up-circle-fill::before {
  content: "\f139";
}

.bi-arrow-up-circle::before {
  content: "\f13a";
}

.bi-arrow-up-left-circle-fill::before {
  content: "\f13b";
}

.bi-arrow-up-left-circle::before {
  content: "\f13c";
}

.bi-arrow-up-left-square-fill::before {
  content: "\f13d";
}

.bi-arrow-up-left-square::before {
  content: "\f13e";
}

.bi-arrow-up-left::before {
  content: "\f13f";
}

.bi-arrow-up-right-circle-fill::before {
  content: "\f140";
}

.bi-arrow-up-right-circle::before {
  content: "\f141";
}

.bi-arrow-up-right-square-fill::before {
  content: "\f142";
}

.bi-arrow-up-right-square::before {
  content: "\f143";
}

.bi-arrow-up-right::before {
  content: "\f144";
}

.bi-arrow-up-short::before {
  content: "\f145";
}

.bi-arrow-up-square-fill::before {
  content: "\f146";
}

.bi-arrow-up-square::before {
  content: "\f147";
}

.bi-arrow-up::before {
  content: "\f148";
}

.bi-arrows-angle-contract::before {
  content: "\f149";
}

.bi-arrows-angle-expand::before {
  content: "\f14a";
}

.bi-arrows-collapse::before {
  content: "\f14b";
}

.bi-arrows-expand::before {
  content: "\f14c";
}

.bi-arrows-fullscreen::before {
  content: "\f14d";
}

.bi-arrows-move::before {
  content: "\f14e";
}

.bi-aspect-ratio-fill::before {
  content: "\f14f";
}

.bi-aspect-ratio::before {
  content: "\f150";
}

.bi-asterisk::before {
  content: "\f151";
}

.bi-at::before {
  content: "\f152";
}

.bi-award-fill::before {
  content: "\f153";
}

.bi-award::before {
  content: "\f154";
}

.bi-back::before {
  content: "\f155";
}

.bi-backspace-fill::before {
  content: "\f156";
}

.bi-backspace-reverse-fill::before {
  content: "\f157";
}

.bi-backspace-reverse::before {
  content: "\f158";
}

.bi-backspace::before {
  content: "\f159";
}

.bi-badge-3d-fill::before {
  content: "\f15a";
}

.bi-badge-3d::before {
  content: "\f15b";
}

.bi-badge-4k-fill::before {
  content: "\f15c";
}

.bi-badge-4k::before {
  content: "\f15d";
}

.bi-badge-8k-fill::before {
  content: "\f15e";
}

.bi-badge-8k::before {
  content: "\f15f";
}

.bi-badge-ad-fill::before {
  content: "\f160";
}

.bi-badge-ad::before {
  content: "\f161";
}

.bi-badge-ar-fill::before {
  content: "\f162";
}

.bi-badge-ar::before {
  content: "\f163";
}

.bi-badge-cc-fill::before {
  content: "\f164";
}

.bi-badge-cc::before {
  content: "\f165";
}

.bi-badge-hd-fill::before {
  content: "\f166";
}

.bi-badge-hd::before {
  content: "\f167";
}

.bi-badge-tm-fill::before {
  content: "\f168";
}

.bi-badge-tm::before {
  content: "\f169";
}

.bi-badge-vo-fill::before {
  content: "\f16a";
}

.bi-badge-vo::before {
  content: "\f16b";
}

.bi-badge-vr-fill::before {
  content: "\f16c";
}

.bi-badge-vr::before {
  content: "\f16d";
}

.bi-badge-wc-fill::before {
  content: "\f16e";
}

.bi-badge-wc::before {
  content: "\f16f";
}

.bi-bag-check-fill::before {
  content: "\f170";
}

.bi-bag-check::before {
  content: "\f171";
}

.bi-bag-dash-fill::before {
  content: "\f172";
}

.bi-bag-dash::before {
  content: "\f173";
}

.bi-bag-fill::before {
  content: "\f174";
}

.bi-bag-plus-fill::before {
  content: "\f175";
}

.bi-bag-plus::before {
  content: "\f176";
}

.bi-bag-x-fill::before {
  content: "\f177";
}

.bi-bag-x::before {
  content: "\f178";
}

.bi-bag::before {
  content: "\f179";
}

.bi-bar-chart-fill::before {
  content: "\f17a";
}

.bi-bar-chart-line-fill::before {
  content: "\f17b";
}

.bi-bar-chart-line::before {
  content: "\f17c";
}

.bi-bar-chart-steps::before {
  content: "\f17d";
}

.bi-bar-chart::before {
  content: "\f17e";
}

.bi-basket-fill::before {
  content: "\f17f";
}

.bi-basket::before {
  content: "\f180";
}

.bi-basket2-fill::before {
  content: "\f181";
}

.bi-basket2::before {
  content: "\f182";
}

.bi-basket3-fill::before {
  content: "\f183";
}

.bi-basket3::before {
  content: "\f184";
}

.bi-battery-charging::before {
  content: "\f185";
}

.bi-battery-full::before {
  content: "\f186";
}

.bi-battery-half::before {
  content: "\f187";
}

.bi-battery::before {
  content: "\f188";
}

.bi-bell-fill::before {
  content: "\f189";
}

.bi-bell::before {
  content: "\f18a";
}

.bi-bezier::before {
  content: "\f18b";
}

.bi-bezier2::before {
  content: "\f18c";
}

.bi-bicycle::before {
  content: "\f18d";
}

.bi-binoculars-fill::before {
  content: "\f18e";
}

.bi-binoculars::before {
  content: "\f18f";
}

.bi-blockquote-left::before {
  content: "\f190";
}

.bi-blockquote-right::before {
  content: "\f191";
}

.bi-book-fill::before {
  content: "\f192";
}

.bi-book-half::before {
  content: "\f193";
}

.bi-book::before {
  content: "\f194";
}

.bi-bookmark-check-fill::before {
  content: "\f195";
}

.bi-bookmark-check::before {
  content: "\f196";
}

.bi-bookmark-dash-fill::before {
  content: "\f197";
}

.bi-bookmark-dash::before {
  content: "\f198";
}

.bi-bookmark-fill::before {
  content: "\f199";
}

.bi-bookmark-heart-fill::before {
  content: "\f19a";
}

.bi-bookmark-heart::before {
  content: "\f19b";
}

.bi-bookmark-plus-fill::before {
  content: "\f19c";
}

.bi-bookmark-plus::before {
  content: "\f19d";
}

.bi-bookmark-star-fill::before {
  content: "\f19e";
}

.bi-bookmark-star::before {
  content: "\f19f";
}

.bi-bookmark-x-fill::before {
  content: "\f1a0";
}

.bi-bookmark-x::before {
  content: "\f1a1";
}

.bi-bookmark::before {
  content: "\f1a2";
}

.bi-bookmarks-fill::before {
  content: "\f1a3";
}

.bi-bookmarks::before {
  content: "\f1a4";
}

.bi-bookshelf::before {
  content: "\f1a5";
}

.bi-bootstrap-fill::before {
  content: "\f1a6";
}

.bi-bootstrap-reboot::before {
  content: "\f1a7";
}

.bi-bootstrap::before {
  content: "\f1a8";
}

.bi-border-all::before {
  content: "\f1a9";
}

.bi-border-bottom::before {
  content: "\f1aa";
}

.bi-border-center::before {
  content: "\f1ab";
}

.bi-border-inner::before {
  content: "\f1ac";
}

.bi-border-left::before {
  content: "\f1ad";
}

.bi-border-middle::before {
  content: "\f1ae";
}

.bi-border-outer::before {
  content: "\f1af";
}

.bi-border-right::before {
  content: "\f1b0";
}

.bi-border-style::before {
  content: "\f1b1";
}

.bi-border-top::before {
  content: "\f1b2";
}

.bi-border-width::before {
  content: "\f1b3";
}

.bi-border::before {
  content: "\f1b4";
}

.bi-bounding-box-circles::before {
  content: "\f1b5";
}

.bi-bounding-box::before {
  content: "\f1b6";
}

.bi-box-arrow-down-left::before {
  content: "\f1b7";
}

.bi-box-arrow-down-right::before {
  content: "\f1b8";
}

.bi-box-arrow-down::before {
  content: "\f1b9";
}

.bi-box-arrow-in-down-left::before {
  content: "\f1ba";
}

.bi-box-arrow-in-down-right::before {
  content: "\f1bb";
}

.bi-box-arrow-in-down::before {
  content: "\f1bc";
}

.bi-box-arrow-in-left::before {
  content: "\f1bd";
}

.bi-box-arrow-in-right::before {
  content: "\f1be";
}

.bi-box-arrow-in-up-left::before {
  content: "\f1bf";
}

.bi-box-arrow-in-up-right::before {
  content: "\f1c0";
}

.bi-box-arrow-in-up::before {
  content: "\f1c1";
}

.bi-box-arrow-left::before {
  content: "\f1c2";
}

.bi-box-arrow-right::before {
  content: "\f1c3";
}

.bi-box-arrow-up-left::before {
  content: "\f1c4";
}

.bi-box-arrow-up-right::before {
  content: "\f1c5";
}

.bi-box-arrow-up::before {
  content: "\f1c6";
}

.bi-box-seam::before {
  content: "\f1c7";
}

.bi-box::before {
  content: "\f1c8";
}

.bi-braces::before {
  content: "\f1c9";
}

.bi-bricks::before {
  content: "\f1ca";
}

.bi-briefcase-fill::before {
  content: "\f1cb";
}

.bi-briefcase::before {
  content: "\f1cc";
}

.bi-brightness-alt-high-fill::before {
  content: "\f1cd";
}

.bi-brightness-alt-high::before {
  content: "\f1ce";
}

.bi-brightness-alt-low-fill::before {
  content: "\f1cf";
}

.bi-brightness-alt-low::before {
  content: "\f1d0";
}

.bi-brightness-high-fill::before {
  content: "\f1d1";
}

.bi-brightness-high::before {
  content: "\f1d2";
}

.bi-brightness-low-fill::before {
  content: "\f1d3";
}

.bi-brightness-low::before {
  content: "\f1d4";
}

.bi-broadcast-pin::before {
  content: "\f1d5";
}

.bi-broadcast::before {
  content: "\f1d6";
}

.bi-brush-fill::before {
  content: "\f1d7";
}

.bi-brush::before {
  content: "\f1d8";
}

.bi-bucket-fill::before {
  content: "\f1d9";
}

.bi-bucket::before {
  content: "\f1da";
}

.bi-bug-fill::before {
  content: "\f1db";
}

.bi-bug::before {
  content: "\f1dc";
}

.bi-building::before {
  content: "\f1dd";
}

.bi-bullseye::before {
  content: "\f1de";
}

.bi-calculator-fill::before {
  content: "\f1df";
}

.bi-calculator::before {
  content: "\f1e0";
}

.bi-calendar-check-fill::before {
  content: "\f1e1";
}

.bi-calendar-check::before {
  content: "\f1e2";
}

.bi-calendar-date-fill::before {
  content: "\f1e3";
}

.bi-calendar-date::before {
  content: "\f1e4";
}

.bi-calendar-day-fill::before {
  content: "\f1e5";
}

.bi-calendar-day::before {
  content: "\f1e6";
}

.bi-calendar-event-fill::before {
  content: "\f1e7";
}

.bi-calendar-event::before {
  content: "\f1e8";
}

.bi-calendar-fill::before {
  content: "\f1e9";
}

.bi-calendar-minus-fill::before {
  content: "\f1ea";
}

.bi-calendar-minus::before {
  content: "\f1eb";
}

.bi-calendar-month-fill::before {
  content: "\f1ec";
}

.bi-calendar-month::before {
  content: "\f1ed";
}

.bi-calendar-plus-fill::before {
  content: "\f1ee";
}

.bi-calendar-plus::before {
  content: "\f1ef";
}

.bi-calendar-range-fill::before {
  content: "\f1f0";
}

.bi-calendar-range::before {
  content: "\f1f1";
}

.bi-calendar-week-fill::before {
  content: "\f1f2";
}

.bi-calendar-week::before {
  content: "\f1f3";
}

.bi-calendar-x-fill::before {
  content: "\f1f4";
}

.bi-calendar-x::before {
  content: "\f1f5";
}

.bi-calendar::before {
  content: "\f1f6";
}

.bi-calendar2-check-fill::before {
  content: "\f1f7";
}

.bi-calendar2-check::before {
  content: "\f1f8";
}

.bi-calendar2-date-fill::before {
  content: "\f1f9";
}

.bi-calendar2-date::before {
  content: "\f1fa";
}

.bi-calendar2-day-fill::before {
  content: "\f1fb";
}

.bi-calendar2-day::before {
  content: "\f1fc";
}

.bi-calendar2-event-fill::before {
  content: "\f1fd";
}

.bi-calendar2-event::before {
  content: "\f1fe";
}

.bi-calendar2-fill::before {
  content: "\f1ff";
}

.bi-calendar2-minus-fill::before {
  content: "\f200";
}

.bi-calendar2-minus::before {
  content: "\f201";
}

.bi-calendar2-month-fill::before {
  content: "\f202";
}

.bi-calendar2-month::before {
  content: "\f203";
}

.bi-calendar2-plus-fill::before {
  content: "\f204";
}

.bi-calendar2-plus::before {
  content: "\f205";
}

.bi-calendar2-range-fill::before {
  content: "\f206";
}

.bi-calendar2-range::before {
  content: "\f207";
}

.bi-calendar2-week-fill::before {
  content: "\f208";
}

.bi-calendar2-week::before {
  content: "\f209";
}

.bi-calendar2-x-fill::before {
  content: "\f20a";
}

.bi-calendar2-x::before {
  content: "\f20b";
}

.bi-calendar2::before {
  content: "\f20c";
}

.bi-calendar3-event-fill::before {
  content: "\f20d";
}

.bi-calendar3-event::before {
  content: "\f20e";
}

.bi-calendar3-fill::before {
  content: "\f20f";
}

.bi-calendar3-range-fill::before {
  content: "\f210";
}

.bi-calendar3-range::before {
  content: "\f211";
}

.bi-calendar3-week-fill::before {
  content: "\f212";
}

.bi-calendar3-week::before {
  content: "\f213";
}

.bi-calendar3::before {
  content: "\f214";
}

.bi-calendar4-event::before {
  content: "\f215";
}

.bi-calendar4-range::before {
  content: "\f216";
}

.bi-calendar4-week::before {
  content: "\f217";
}

.bi-calendar4::before {
  content: "\f218";
}

.bi-camera-fill::before {
  content: "\f219";
}

.bi-camera-reels-fill::before {
  content: "\f21a";
}

.bi-camera-reels::before {
  content: "\f21b";
}

.bi-camera-video-fill::before {
  content: "\f21c";
}

.bi-camera-video-off-fill::before {
  content: "\f21d";
}

.bi-camera-video-off::before {
  content: "\f21e";
}

.bi-camera-video::before {
  content: "\f21f";
}

.bi-camera::before {
  content: "\f220";
}

.bi-camera2::before {
  content: "\f221";
}

.bi-capslock-fill::before {
  content: "\f222";
}

.bi-capslock::before {
  content: "\f223";
}

.bi-card-checklist::before {
  content: "\f224";
}

.bi-card-heading::before {
  content: "\f225";
}

.bi-card-image::before {
  content: "\f226";
}

.bi-card-list::before {
  content: "\f227";
}

.bi-card-text::before {
  content: "\f228";
}

.bi-caret-down-fill::before {
  content: "\f229";
}

.bi-caret-down-square-fill::before {
  content: "\f22a";
}

.bi-caret-down-square::before {
  content: "\f22b";
}

.bi-caret-down::before {
  content: "\f22c";
}

.bi-caret-left-fill::before {
  content: "\f22d";
}

.bi-caret-left-square-fill::before {
  content: "\f22e";
}

.bi-caret-left-square::before {
  content: "\f22f";
}

.bi-caret-left::before {
  content: "\f230";
}

.bi-caret-right-fill::before {
  content: "\f231";
}

.bi-caret-right-square-fill::before {
  content: "\f232";
}

.bi-caret-right-square::before {
  content: "\f233";
}

.bi-caret-right::before {
  content: "\f234";
}

.bi-caret-up-fill::before {
  content: "\f235";
}

.bi-caret-up-square-fill::before {
  content: "\f236";
}

.bi-caret-up-square::before {
  content: "\f237";
}

.bi-caret-up::before {
  content: "\f238";
}

.bi-cart-check-fill::before {
  content: "\f239";
}

.bi-cart-check::before {
  content: "\f23a";
}

.bi-cart-dash-fill::before {
  content: "\f23b";
}

.bi-cart-dash::before {
  content: "\f23c";
}

.bi-cart-fill::before {
  content: "\f23d";
}

.bi-cart-plus-fill::before {
  content: "\f23e";
}

.bi-cart-plus::before {
  content: "\f23f";
}

.bi-cart-x-fill::before {
  content: "\f240";
}

.bi-cart-x::before {
  content: "\f241";
}

.bi-cart::before {
  content: "\f242";
}

.bi-cart2::before {
  content: "\f243";
}

.bi-cart3::before {
  content: "\f244";
}

.bi-cart4::before {
  content: "\f245";
}

.bi-cash-stack::before {
  content: "\f246";
}

.bi-cash::before {
  content: "\f247";
}

.bi-cast::before {
  content: "\f248";
}

.bi-chat-dots-fill::before {
  content: "\f249";
}

.bi-chat-dots::before {
  content: "\f24a";
}

.bi-chat-fill::before {
  content: "\f24b";
}

.bi-chat-left-dots-fill::before {
  content: "\f24c";
}

.bi-chat-left-dots::before {
  content: "\f24d";
}

.bi-chat-left-fill::before {
  content: "\f24e";
}

.bi-chat-left-quote-fill::before {
  content: "\f24f";
}

.bi-chat-left-quote::before {
  content: "\f250";
}

.bi-chat-left-text-fill::before {
  content: "\f251";
}

.bi-chat-left-text::before {
  content: "\f252";
}

.bi-chat-left::before {
  content: "\f253";
}

.bi-chat-quote-fill::before {
  content: "\f254";
}

.bi-chat-quote::before {
  content: "\f255";
}

.bi-chat-right-dots-fill::before {
  content: "\f256";
}

.bi-chat-right-dots::before {
  content: "\f257";
}

.bi-chat-right-fill::before {
  content: "\f258";
}

.bi-chat-right-quote-fill::before {
  content: "\f259";
}

.bi-chat-right-quote::before {
  content: "\f25a";
}

.bi-chat-right-text-fill::before {
  content: "\f25b";
}

.bi-chat-right-text::before {
  content: "\f25c";
}

.bi-chat-right::before {
  content: "\f25d";
}

.bi-chat-square-dots-fill::before {
  content: "\f25e";
}

.bi-chat-square-dots::before {
  content: "\f25f";
}

.bi-chat-square-fill::before {
  content: "\f260";
}

.bi-chat-square-quote-fill::before {
  content: "\f261";
}

.bi-chat-square-quote::before {
  content: "\f262";
}

.bi-chat-square-text-fill::before {
  content: "\f263";
}

.bi-chat-square-text::before {
  content: "\f264";
}

.bi-chat-square::before {
  content: "\f265";
}

.bi-chat-text-fill::before {
  content: "\f266";
}

.bi-chat-text::before {
  content: "\f267";
}

.bi-chat::before {
  content: "\f268";
}

.bi-check-all::before {
  content: "\f269";
}

.bi-check-circle-fill::before {
  content: "\f26a";
}

.bi-check-circle::before {
  content: "\f26b";
}

.bi-check-square-fill::before {
  content: "\f26c";
}

.bi-check-square::before {
  content: "\f26d";
}

.bi-check::before {
  content: "\f26e";
}

.bi-check2-all::before {
  content: "\f26f";
}

.bi-check2-circle::before {
  content: "\f270";
}

.bi-check2-square::before {
  content: "\f271";
}

.bi-check2::before {
  content: "\f272";
}

.bi-chevron-bar-contract::before {
  content: "\f273";
}

.bi-chevron-bar-down::before {
  content: "\f274";
}

.bi-chevron-bar-expand::before {
  content: "\f275";
}

.bi-chevron-bar-left::before {
  content: "\f276";
}

.bi-chevron-bar-right::before {
  content: "\f277";
}

.bi-chevron-bar-up::before {
  content: "\f278";
}

.bi-chevron-compact-down::before {
  content: "\f279";
}

.bi-chevron-compact-left::before {
  content: "\f27a";
}

.bi-chevron-compact-right::before {
  content: "\f27b";
}

.bi-chevron-compact-up::before {
  content: "\f27c";
}

.bi-chevron-contract::before {
  content: "\f27d";
}

.bi-chevron-double-down::before {
  content: "\f27e";
}

.bi-chevron-double-left::before {
  content: "\f27f";
}

.bi-chevron-double-right::before {
  content: "\f280";
}

.bi-chevron-double-up::before {
  content: "\f281";
}

.bi-chevron-down::before {
  content: "\f282";
}

.bi-chevron-expand::before {
  content: "\f283";
}

.bi-chevron-left::before {
  content: "\f284";
}

.bi-chevron-right::before {
  content: "\f285";
}

.bi-chevron-up::before {
  content: "\f286";
}

.bi-circle-fill::before {
  content: "\f287";
}

.bi-circle-half::before {
  content: "\f288";
}

.bi-circle-square::before {
  content: "\f289";
}

.bi-circle::before {
  content: "\f28a";
}

.bi-clipboard-check::before {
  content: "\f28b";
}

.bi-clipboard-data::before {
  content: "\f28c";
}

.bi-clipboard-minus::before {
  content: "\f28d";
}

.bi-clipboard-plus::before {
  content: "\f28e";
}

.bi-clipboard-x::before {
  content: "\f28f";
}

.bi-clipboard::before {
  content: "\f290";
}

.bi-clock-fill::before {
  content: "\f291";
}

.bi-clock-history::before {
  content: "\f292";
}

.bi-clock::before {
  content: "\f293";
}

.bi-cloud-arrow-down-fill::before {
  content: "\f294";
}

.bi-cloud-arrow-down::before {
  content: "\f295";
}

.bi-cloud-arrow-up-fill::before {
  content: "\f296";
}

.bi-cloud-arrow-up::before {
  content: "\f297";
}

.bi-cloud-check-fill::before {
  content: "\f298";
}

.bi-cloud-check::before {
  content: "\f299";
}

.bi-cloud-download-fill::before {
  content: "\f29a";
}

.bi-cloud-download::before {
  content: "\f29b";
}

.bi-cloud-drizzle-fill::before {
  content: "\f29c";
}

.bi-cloud-drizzle::before {
  content: "\f29d";
}

.bi-cloud-fill::before {
  content: "\f29e";
}

.bi-cloud-fog-fill::before {
  content: "\f29f";
}

.bi-cloud-fog::before {
  content: "\f2a0";
}

.bi-cloud-fog2-fill::before {
  content: "\f2a1";
}

.bi-cloud-fog2::before {
  content: "\f2a2";
}

.bi-cloud-hail-fill::before {
  content: "\f2a3";
}

.bi-cloud-hail::before {
  content: "\f2a4";
}

.bi-cloud-haze-1::before {
  content: "\f2a5";
}

.bi-cloud-haze-fill::before {
  content: "\f2a6";
}

.bi-cloud-haze::before {
  content: "\f2a7";
}

.bi-cloud-haze2-fill::before {
  content: "\f2a8";
}

.bi-cloud-lightning-fill::before {
  content: "\f2a9";
}

.bi-cloud-lightning-rain-fill::before {
  content: "\f2aa";
}

.bi-cloud-lightning-rain::before {
  content: "\f2ab";
}

.bi-cloud-lightning::before {
  content: "\f2ac";
}

.bi-cloud-minus-fill::before {
  content: "\f2ad";
}

.bi-cloud-minus::before {
  content: "\f2ae";
}

.bi-cloud-moon-fill::before {
  content: "\f2af";
}

.bi-cloud-moon::before {
  content: "\f2b0";
}

.bi-cloud-plus-fill::before {
  content: "\f2b1";
}

.bi-cloud-plus::before {
  content: "\f2b2";
}

.bi-cloud-rain-fill::before {
  content: "\f2b3";
}

.bi-cloud-rain-heavy-fill::before {
  content: "\f2b4";
}

.bi-cloud-rain-heavy::before {
  content: "\f2b5";
}

.bi-cloud-rain::before {
  content: "\f2b6";
}

.bi-cloud-slash-fill::before {
  content: "\f2b7";
}

.bi-cloud-slash::before {
  content: "\f2b8";
}

.bi-cloud-sleet-fill::before {
  content: "\f2b9";
}

.bi-cloud-sleet::before {
  content: "\f2ba";
}

.bi-cloud-snow-fill::before {
  content: "\f2bb";
}

.bi-cloud-snow::before {
  content: "\f2bc";
}

.bi-cloud-sun-fill::before {
  content: "\f2bd";
}

.bi-cloud-sun::before {
  content: "\f2be";
}

.bi-cloud-upload-fill::before {
  content: "\f2bf";
}

.bi-cloud-upload::before {
  content: "\f2c0";
}

.bi-cloud::before {
  content: "\f2c1";
}

.bi-clouds-fill::before {
  content: "\f2c2";
}

.bi-clouds::before {
  content: "\f2c3";
}

.bi-cloudy-fill::before {
  content: "\f2c4";
}

.bi-cloudy::before {
  content: "\f2c5";
}

.bi-code-slash::before {
  content: "\f2c6";
}

.bi-code-square::before {
  content: "\f2c7";
}

.bi-code::before {
  content: "\f2c8";
}

.bi-collection-fill::before {
  content: "\f2c9";
}

.bi-collection-play-fill::before {
  content: "\f2ca";
}

.bi-collection-play::before {
  content: "\f2cb";
}

.bi-collection::before {
  content: "\f2cc";
}

.bi-columns-gap::before {
  content: "\f2cd";
}

.bi-columns::before {
  content: "\f2ce";
}

.bi-command::before {
  content: "\f2cf";
}

.bi-compass-fill::before {
  content: "\f2d0";
}

.bi-compass::before {
  content: "\f2d1";
}

.bi-cone-striped::before {
  content: "\f2d2";
}

.bi-cone::before {
  content: "\f2d3";
}

.bi-controller::before {
  content: "\f2d4";
}

.bi-cpu-fill::before {
  content: "\f2d5";
}

.bi-cpu::before {
  content: "\f2d6";
}

.bi-credit-card-2-back-fill::before {
  content: "\f2d7";
}

.bi-credit-card-2-back::before {
  content: "\f2d8";
}

.bi-credit-card-2-front-fill::before {
  content: "\f2d9";
}

.bi-credit-card-2-front::before {
  content: "\f2da";
}

.bi-credit-card-fill::before {
  content: "\f2db";
}

.bi-credit-card::before {
  content: "\f2dc";
}

.bi-crop::before {
  content: "\f2dd";
}

.bi-cup-fill::before {
  content: "\f2de";
}

.bi-cup-straw::before {
  content: "\f2df";
}

.bi-cup::before {
  content: "\f2e0";
}

.bi-cursor-fill::before {
  content: "\f2e1";
}

.bi-cursor-text::before {
  content: "\f2e2";
}

.bi-cursor::before {
  content: "\f2e3";
}

.bi-dash-circle-dotted::before {
  content: "\f2e4";
}

.bi-dash-circle-fill::before {
  content: "\f2e5";
}

.bi-dash-circle::before {
  content: "\f2e6";
}

.bi-dash-square-dotted::before {
  content: "\f2e7";
}

.bi-dash-square-fill::before {
  content: "\f2e8";
}

.bi-dash-square::before {
  content: "\f2e9";
}

.bi-dash::before {
  content: "\f2ea";
}

.bi-diagram-2-fill::before {
  content: "\f2eb";
}

.bi-diagram-2::before {
  content: "\f2ec";
}

.bi-diagram-3-fill::before {
  content: "\f2ed";
}

.bi-diagram-3::before {
  content: "\f2ee";
}

.bi-diamond-fill::before {
  content: "\f2ef";
}

.bi-diamond-half::before {
  content: "\f2f0";
}

.bi-diamond::before {
  content: "\f2f1";
}

.bi-dice-1-fill::before {
  content: "\f2f2";
}

.bi-dice-1::before {
  content: "\f2f3";
}

.bi-dice-2-fill::before {
  content: "\f2f4";
}

.bi-dice-2::before {
  content: "\f2f5";
}

.bi-dice-3-fill::before {
  content: "\f2f6";
}

.bi-dice-3::before {
  content: "\f2f7";
}

.bi-dice-4-fill::before {
  content: "\f2f8";
}

.bi-dice-4::before {
  content: "\f2f9";
}

.bi-dice-5-fill::before {
  content: "\f2fa";
}

.bi-dice-5::before {
  content: "\f2fb";
}

.bi-dice-6-fill::before {
  content: "\f2fc";
}

.bi-dice-6::before {
  content: "\f2fd";
}

.bi-disc-fill::before {
  content: "\f2fe";
}

.bi-disc::before {
  content: "\f2ff";
}

.bi-discord::before {
  content: "\f300";
}

.bi-display-fill::before {
  content: "\f301";
}

.bi-display::before {
  content: "\f302";
}

.bi-distribute-horizontal::before {
  content: "\f303";
}

.bi-distribute-vertical::before {
  content: "\f304";
}

.bi-door-closed-fill::before {
  content: "\f305";
}

.bi-door-closed::before {
  content: "\f306";
}

.bi-door-open-fill::before {
  content: "\f307";
}

.bi-door-open::before {
  content: "\f308";
}

.bi-dot::before {
  content: "\f309";
}

.bi-download::before {
  content: "\f30a";
}

.bi-droplet-fill::before {
  content: "\f30b";
}

.bi-droplet-half::before {
  content: "\f30c";
}

.bi-droplet::before {
  content: "\f30d";
}

.bi-earbuds::before {
  content: "\f30e";
}

.bi-easel-fill::before {
  content: "\f30f";
}

.bi-easel::before {
  content: "\f310";
}

.bi-egg-fill::before {
  content: "\f311";
}

.bi-egg-fried::before {
  content: "\f312";
}

.bi-egg::before {
  content: "\f313";
}

.bi-eject-fill::before {
  content: "\f314";
}

.bi-eject::before {
  content: "\f315";
}

.bi-emoji-angry-fill::before {
  content: "\f316";
}

.bi-emoji-angry::before {
  content: "\f317";
}

.bi-emoji-dizzy-fill::before {
  content: "\f318";
}

.bi-emoji-dizzy::before {
  content: "\f319";
}

.bi-emoji-expressionless-fill::before {
  content: "\f31a";
}

.bi-emoji-expressionless::before {
  content: "\f31b";
}

.bi-emoji-frown-fill::before {
  content: "\f31c";
}

.bi-emoji-frown::before {
  content: "\f31d";
}

.bi-emoji-heart-eyes-fill::before {
  content: "\f31e";
}

.bi-emoji-heart-eyes::before {
  content: "\f31f";
}

.bi-emoji-laughing-fill::before {
  content: "\f320";
}

.bi-emoji-laughing::before {
  content: "\f321";
}

.bi-emoji-neutral-fill::before {
  content: "\f322";
}

.bi-emoji-neutral::before {
  content: "\f323";
}

.bi-emoji-smile-fill::before {
  content: "\f324";
}

.bi-emoji-smile-upside-down-fill::before {
  content: "\f325";
}

.bi-emoji-smile-upside-down::before {
  content: "\f326";
}

.bi-emoji-smile::before {
  content: "\f327";
}

.bi-emoji-sunglasses-fill::before {
  content: "\f328";
}

.bi-emoji-sunglasses::before {
  content: "\f329";
}

.bi-emoji-wink-fill::before {
  content: "\f32a";
}

.bi-emoji-wink::before {
  content: "\f32b";
}

.bi-envelope-fill::before {
  content: "\f32c";
}

.bi-envelope-open-fill::before {
  content: "\f32d";
}

.bi-envelope-open::before {
  content: "\f32e";
}

.bi-envelope::before {
  content: "\f32f";
}

.bi-eraser-fill::before {
  content: "\f330";
}

.bi-eraser::before {
  content: "\f331";
}

.bi-exclamation-circle-fill::before {
  content: "\f332";
}

.bi-exclamation-circle::before {
  content: "\f333";
}

.bi-exclamation-diamond-fill::before {
  content: "\f334";
}

.bi-exclamation-diamond::before {
  content: "\f335";
}

.bi-exclamation-octagon-fill::before {
  content: "\f336";
}

.bi-exclamation-octagon::before {
  content: "\f337";
}

.bi-exclamation-square-fill::before {
  content: "\f338";
}

.bi-exclamation-square::before {
  content: "\f339";
}

.bi-exclamation-triangle-fill::before {
  content: "\f33a";
}

.bi-exclamation-triangle::before {
  content: "\f33b";
}

.bi-exclamation::before {
  content: "\f33c";
}

.bi-exclude::before {
  content: "\f33d";
}

.bi-eye-fill::before {
  content: "\f33e";
}

.bi-eye-slash-fill::before {
  content: "\f33f";
}

.bi-eye-slash::before {
  content: "\f340";
}

.bi-eye::before {
  content: "\f341";
}

.bi-eyedropper::before {
  content: "\f342";
}

.bi-eyeglasses::before {
  content: "\f343";
}

.bi-facebook::before {
  content: "\f344";
}

.bi-file-arrow-down-fill::before {
  content: "\f345";
}

.bi-file-arrow-down::before {
  content: "\f346";
}

.bi-file-arrow-up-fill::before {
  content: "\f347";
}

.bi-file-arrow-up::before {
  content: "\f348";
}

.bi-file-bar-graph-fill::before {
  content: "\f349";
}

.bi-file-bar-graph::before {
  content: "\f34a";
}

.bi-file-binary-fill::before {
  content: "\f34b";
}

.bi-file-binary::before {
  content: "\f34c";
}

.bi-file-break-fill::before {
  content: "\f34d";
}

.bi-file-break::before {
  content: "\f34e";
}

.bi-file-check-fill::before {
  content: "\f34f";
}

.bi-file-check::before {
  content: "\f350";
}

.bi-file-code-fill::before {
  content: "\f351";
}

.bi-file-code::before {
  content: "\f352";
}

.bi-file-diff-fill::before {
  content: "\f353";
}

.bi-file-diff::before {
  content: "\f354";
}

.bi-file-earmark-arrow-down-fill::before {
  content: "\f355";
}

.bi-file-earmark-arrow-down::before {
  content: "\f356";
}

.bi-file-earmark-arrow-up-fill::before {
  content: "\f357";
}

.bi-file-earmark-arrow-up::before {
  content: "\f358";
}

.bi-file-earmark-bar-graph-fill::before {
  content: "\f359";
}

.bi-file-earmark-bar-graph::before {
  content: "\f35a";
}

.bi-file-earmark-binary-fill::before {
  content: "\f35b";
}

.bi-file-earmark-binary::before {
  content: "\f35c";
}

.bi-file-earmark-break-fill::before {
  content: "\f35d";
}

.bi-file-earmark-break::before {
  content: "\f35e";
}

.bi-file-earmark-check-fill::before {
  content: "\f35f";
}

.bi-file-earmark-check::before {
  content: "\f360";
}

.bi-file-earmark-code-fill::before {
  content: "\f361";
}

.bi-file-earmark-code::before {
  content: "\f362";
}

.bi-file-earmark-diff-fill::before {
  content: "\f363";
}

.bi-file-earmark-diff::before {
  content: "\f364";
}

.bi-file-earmark-easel-fill::before {
  content: "\f365";
}

.bi-file-earmark-easel::before {
  content: "\f366";
}

.bi-file-earmark-excel-fill::before {
  content: "\f367";
}

.bi-file-earmark-excel::before {
  content: "\f368";
}

.bi-file-earmark-fill::before {
  content: "\f369";
}

.bi-file-earmark-font-fill::before {
  content: "\f36a";
}

.bi-file-earmark-font::before {
  content: "\f36b";
}

.bi-file-earmark-image-fill::before {
  content: "\f36c";
}

.bi-file-earmark-image::before {
  content: "\f36d";
}

.bi-file-earmark-lock-fill::before {
  content: "\f36e";
}

.bi-file-earmark-lock::before {
  content: "\f36f";
}

.bi-file-earmark-lock2-fill::before {
  content: "\f370";
}

.bi-file-earmark-lock2::before {
  content: "\f371";
}

.bi-file-earmark-medical-fill::before {
  content: "\f372";
}

.bi-file-earmark-medical::before {
  content: "\f373";
}

.bi-file-earmark-minus-fill::before {
  content: "\f374";
}

.bi-file-earmark-minus::before {
  content: "\f375";
}

.bi-file-earmark-music-fill::before {
  content: "\f376";
}

.bi-file-earmark-music::before {
  content: "\f377";
}

.bi-file-earmark-person-fill::before {
  content: "\f378";
}

.bi-file-earmark-person::before {
  content: "\f379";
}

.bi-file-earmark-play-fill::before {
  content: "\f37a";
}

.bi-file-earmark-play::before {
  content: "\f37b";
}

.bi-file-earmark-plus-fill::before {
  content: "\f37c";
}

.bi-file-earmark-plus::before {
  content: "\f37d";
}

.bi-file-earmark-post-fill::before {
  content: "\f37e";
}

.bi-file-earmark-post::before {
  content: "\f37f";
}

.bi-file-earmark-ppt-fill::before {
  content: "\f380";
}

.bi-file-earmark-ppt::before {
  content: "\f381";
}

.bi-file-earmark-richtext-fill::before {
  content: "\f382";
}

.bi-file-earmark-richtext::before {
  content: "\f383";
}

.bi-file-earmark-ruled-fill::before {
  content: "\f384";
}

.bi-file-earmark-ruled::before {
  content: "\f385";
}

.bi-file-earmark-slides-fill::before {
  content: "\f386";
}

.bi-file-earmark-slides::before {
  content: "\f387";
}

.bi-file-earmark-spreadsheet-fill::before {
  content: "\f388";
}

.bi-file-earmark-spreadsheet::before {
  content: "\f389";
}

.bi-file-earmark-text-fill::before {
  content: "\f38a";
}

.bi-file-earmark-text::before {
  content: "\f38b";
}

.bi-file-earmark-word-fill::before {
  content: "\f38c";
}

.bi-file-earmark-word::before {
  content: "\f38d";
}

.bi-file-earmark-x-fill::before {
  content: "\f38e";
}

.bi-file-earmark-x::before {
  content: "\f38f";
}

.bi-file-earmark-zip-fill::before {
  content: "\f390";
}

.bi-file-earmark-zip::before {
  content: "\f391";
}

.bi-file-earmark::before {
  content: "\f392";
}

.bi-file-easel-fill::before {
  content: "\f393";
}

.bi-file-easel::before {
  content: "\f394";
}

.bi-file-excel-fill::before {
  content: "\f395";
}

.bi-file-excel::before {
  content: "\f396";
}

.bi-file-fill::before {
  content: "\f397";
}

.bi-file-font-fill::before {
  content: "\f398";
}

.bi-file-font::before {
  content: "\f399";
}

.bi-file-image-fill::before {
  content: "\f39a";
}

.bi-file-image::before {
  content: "\f39b";
}

.bi-file-lock-fill::before {
  content: "\f39c";
}

.bi-file-lock::before {
  content: "\f39d";
}

.bi-file-lock2-fill::before {
  content: "\f39e";
}

.bi-file-lock2::before {
  content: "\f39f";
}

.bi-file-medical-fill::before {
  content: "\f3a0";
}

.bi-file-medical::before {
  content: "\f3a1";
}

.bi-file-minus-fill::before {
  content: "\f3a2";
}

.bi-file-minus::before {
  content: "\f3a3";
}

.bi-file-music-fill::before {
  content: "\f3a4";
}

.bi-file-music::before {
  content: "\f3a5";
}

.bi-file-person-fill::before {
  content: "\f3a6";
}

.bi-file-person::before {
  content: "\f3a7";
}

.bi-file-play-fill::before {
  content: "\f3a8";
}

.bi-file-play::before {
  content: "\f3a9";
}

.bi-file-plus-fill::before {
  content: "\f3aa";
}

.bi-file-plus::before {
  content: "\f3ab";
}

.bi-file-post-fill::before {
  content: "\f3ac";
}

.bi-file-post::before {
  content: "\f3ad";
}

.bi-file-ppt-fill::before {
  content: "\f3ae";
}

.bi-file-ppt::before {
  content: "\f3af";
}

.bi-file-richtext-fill::before {
  content: "\f3b0";
}

.bi-file-richtext::before {
  content: "\f3b1";
}

.bi-file-ruled-fill::before {
  content: "\f3b2";
}

.bi-file-ruled::before {
  content: "\f3b3";
}

.bi-file-slides-fill::before {
  content: "\f3b4";
}

.bi-file-slides::before {
  content: "\f3b5";
}

.bi-file-spreadsheet-fill::before {
  content: "\f3b6";
}

.bi-file-spreadsheet::before {
  content: "\f3b7";
}

.bi-file-text-fill::before {
  content: "\f3b8";
}

.bi-file-text::before {
  content: "\f3b9";
}

.bi-file-word-fill::before {
  content: "\f3ba";
}

.bi-file-word::before {
  content: "\f3bb";
}

.bi-file-x-fill::before {
  content: "\f3bc";
}

.bi-file-x::before {
  content: "\f3bd";
}

.bi-file-zip-fill::before {
  content: "\f3be";
}

.bi-file-zip::before {
  content: "\f3bf";
}

.bi-file::before {
  content: "\f3c0";
}

.bi-files-alt::before {
  content: "\f3c1";
}

.bi-files::before {
  content: "\f3c2";
}

.bi-film::before {
  content: "\f3c3";
}

.bi-filter-circle-fill::before {
  content: "\f3c4";
}

.bi-filter-circle::before {
  content: "\f3c5";
}

.bi-filter-left::before {
  content: "\f3c6";
}

.bi-filter-right::before {
  content: "\f3c7";
}

.bi-filter-square-fill::before {
  content: "\f3c8";
}

.bi-filter-square::before {
  content: "\f3c9";
}

.bi-filter::before {
  content: "\f3ca";
}

.bi-flag-fill::before {
  content: "\f3cb";
}

.bi-flag::before {
  content: "\f3cc";
}

.bi-flower1::before {
  content: "\f3cd";
}

.bi-flower2::before {
  content: "\f3ce";
}

.bi-flower3::before {
  content: "\f3cf";
}

.bi-folder-check::before {
  content: "\f3d0";
}

.bi-folder-fill::before {
  content: "\f3d1";
}

.bi-folder-minus::before {
  content: "\f3d2";
}

.bi-folder-plus::before {
  content: "\f3d3";
}

.bi-folder-symlink-fill::before {
  content: "\f3d4";
}

.bi-folder-symlink::before {
  content: "\f3d5";
}

.bi-folder-x::before {
  content: "\f3d6";
}

.bi-folder::before {
  content: "\f3d7";
}

.bi-folder2-open::before {
  content: "\f3d8";
}

.bi-folder2::before {
  content: "\f3d9";
}

.bi-fonts::before {
  content: "\f3da";
}

.bi-forward-fill::before {
  content: "\f3db";
}

.bi-forward::before {
  content: "\f3dc";
}

.bi-front::before {
  content: "\f3dd";
}

.bi-fullscreen-exit::before {
  content: "\f3de";
}

.bi-fullscreen::before {
  content: "\f3df";
}

.bi-funnel-fill::before {
  content: "\f3e0";
}

.bi-funnel::before {
  content: "\f3e1";
}

.bi-gear-fill::before {
  content: "\f3e2";
}

.bi-gear-wide-connected::before {
  content: "\f3e3";
}

.bi-gear-wide::before {
  content: "\f3e4";
}

.bi-gear::before {
  content: "\f3e5";
}

.bi-gem::before {
  content: "\f3e6";
}

.bi-geo-alt-fill::before {
  content: "\f3e7";
}

.bi-geo-alt::before {
  content: "\f3e8";
}

.bi-geo-fill::before {
  content: "\f3e9";
}

.bi-geo::before {
  content: "\f3ea";
}

.bi-gift-fill::before {
  content: "\f3eb";
}

.bi-gift::before {
  content: "\f3ec";
}

.bi-github::before {
  content: "\f3ed";
}

.bi-globe::before {
  content: "\f3ee";
}

.bi-globe2::before {
  content: "\f3ef";
}

.bi-google::before {
  content: "\f3f0";
}

.bi-graph-down::before {
  content: "\f3f1";
}

.bi-graph-up::before {
  content: "\f3f2";
}

.bi-grid-1x2-fill::before {
  content: "\f3f3";
}

.bi-grid-1x2::before {
  content: "\f3f4";
}

.bi-grid-3x2-gap-fill::before {
  content: "\f3f5";
}

.bi-grid-3x2-gap::before {
  content: "\f3f6";
}

.bi-grid-3x2::before {
  content: "\f3f7";
}

.bi-grid-3x3-gap-fill::before {
  content: "\f3f8";
}

.bi-grid-3x3-gap::before {
  content: "\f3f9";
}

.bi-grid-3x3::before {
  content: "\f3fa";
}

.bi-grid-fill::before {
  content: "\f3fb";
}

.bi-grid::before {
  content: "\f3fc";
}

.bi-grip-horizontal::before {
  content: "\f3fd";
}

.bi-grip-vertical::before {
  content: "\f3fe";
}

.bi-hammer::before {
  content: "\f3ff";
}

.bi-hand-index-fill::before {
  content: "\f400";
}

.bi-hand-index-thumb-fill::before {
  content: "\f401";
}

.bi-hand-index-thumb::before {
  content: "\f402";
}

.bi-hand-index::before {
  content: "\f403";
}

.bi-hand-thumbs-down-fill::before {
  content: "\f404";
}

.bi-hand-thumbs-down::before {
  content: "\f405";
}

.bi-hand-thumbs-up-fill::before {
  content: "\f406";
}

.bi-hand-thumbs-up::before {
  content: "\f407";
}

.bi-handbag-fill::before {
  content: "\f408";
}

.bi-handbag::before {
  content: "\f409";
}

.bi-hash::before {
  content: "\f40a";
}

.bi-hdd-fill::before {
  content: "\f40b";
}

.bi-hdd-network-fill::before {
  content: "\f40c";
}

.bi-hdd-network::before {
  content: "\f40d";
}

.bi-hdd-rack-fill::before {
  content: "\f40e";
}

.bi-hdd-rack::before {
  content: "\f40f";
}

.bi-hdd-stack-fill::before {
  content: "\f410";
}

.bi-hdd-stack::before {
  content: "\f411";
}

.bi-hdd::before {
  content: "\f412";
}

.bi-headphones::before {
  content: "\f413";
}

.bi-headset::before {
  content: "\f414";
}

.bi-heart-fill::before {
  content: "\f415";
}

.bi-heart-half::before {
  content: "\f416";
}

.bi-heart::before {
  content: "\f417";
}

.bi-heptagon-fill::before {
  content: "\f418";
}

.bi-heptagon-half::before {
  content: "\f419";
}

.bi-heptagon::before {
  content: "\f41a";
}

.bi-hexagon-fill::before {
  content: "\f41b";
}

.bi-hexagon-half::before {
  content: "\f41c";
}

.bi-hexagon::before {
  content: "\f41d";
}

.bi-hourglass-bottom::before {
  content: "\f41e";
}

.bi-hourglass-split::before {
  content: "\f41f";
}

.bi-hourglass-top::before {
  content: "\f420";
}

.bi-hourglass::before {
  content: "\f421";
}

.bi-house-door-fill::before {
  content: "\f422";
}

.bi-house-door::before {
  content: "\f423";
}

.bi-house-fill::before {
  content: "\f424";
}

.bi-house::before {
  content: "\f425";
}

.bi-hr::before {
  content: "\f426";
}

.bi-hurricane::before {
  content: "\f427";
}

.bi-image-alt::before {
  content: "\f428";
}

.bi-image-fill::before {
  content: "\f429";
}

.bi-image::before {
  content: "\f42a";
}

.bi-images::before {
  content: "\f42b";
}

.bi-inbox-fill::before {
  content: "\f42c";
}

.bi-inbox::before {
  content: "\f42d";
}

.bi-inboxes-fill::before {
  content: "\f42e";
}

.bi-inboxes::before {
  content: "\f42f";
}

.bi-info-circle-fill::before {
  content: "\f430";
}

.bi-info-circle::before {
  content: "\f431";
}

.bi-info-square-fill::before {
  content: "\f432";
}

.bi-info-square::before {
  content: "\f433";
}

.bi-info::before {
  content: "\f434";
}

.bi-input-cursor-text::before {
  content: "\f435";
}

.bi-input-cursor::before {
  content: "\f436";
}

.bi-instagram::before {
  content: "\f437";
}

.bi-intersect::before {
  content: "\f438";
}

.bi-journal-album::before {
  content: "\f439";
}

.bi-journal-arrow-down::before {
  content: "\f43a";
}

.bi-journal-arrow-up::before {
  content: "\f43b";
}

.bi-journal-bookmark-fill::before {
  content: "\f43c";
}

.bi-journal-bookmark::before {
  content: "\f43d";
}

.bi-journal-check::before {
  content: "\f43e";
}

.bi-journal-code::before {
  content: "\f43f";
}

.bi-journal-medical::before {
  content: "\f440";
}

.bi-journal-minus::before {
  content: "\f441";
}

.bi-journal-plus::before {
  content: "\f442";
}

.bi-journal-richtext::before {
  content: "\f443";
}

.bi-journal-text::before {
  content: "\f444";
}

.bi-journal-x::before {
  content: "\f445";
}

.bi-journal::before {
  content: "\f446";
}

.bi-journals::before {
  content: "\f447";
}

.bi-joystick::before {
  content: "\f448";
}

.bi-justify-left::before {
  content: "\f449";
}

.bi-justify-right::before {
  content: "\f44a";
}

.bi-justify::before {
  content: "\f44b";
}

.bi-kanban-fill::before {
  content: "\f44c";
}

.bi-kanban::before {
  content: "\f44d";
}

.bi-key-fill::before {
  content: "\f44e";
}

.bi-key::before {
  content: "\f44f";
}

.bi-keyboard-fill::before {
  content: "\f450";
}

.bi-keyboard::before {
  content: "\f451";
}

.bi-ladder::before {
  content: "\f452";
}

.bi-lamp-fill::before {
  content: "\f453";
}

.bi-lamp::before {
  content: "\f454";
}

.bi-laptop-fill::before {
  content: "\f455";
}

.bi-laptop::before {
  content: "\f456";
}

.bi-layer-backward::before {
  content: "\f457";
}

.bi-layer-forward::before {
  content: "\f458";
}

.bi-layers-fill::before {
  content: "\f459";
}

.bi-layers-half::before {
  content: "\f45a";
}

.bi-layers::before {
  content: "\f45b";
}

.bi-layout-sidebar-inset-reverse::before {
  content: "\f45c";
}

.bi-layout-sidebar-inset::before {
  content: "\f45d";
}

.bi-layout-sidebar-reverse::before {
  content: "\f45e";
}

.bi-layout-sidebar::before {
  content: "\f45f";
}

.bi-layout-split::before {
  content: "\f460";
}

.bi-layout-text-sidebar-reverse::before {
  content: "\f461";
}

.bi-layout-text-sidebar::before {
  content: "\f462";
}

.bi-layout-text-window-reverse::before {
  content: "\f463";
}

.bi-layout-text-window::before {
  content: "\f464";
}

.bi-layout-three-columns::before {
  content: "\f465";
}

.bi-layout-wtf::before {
  content: "\f466";
}

.bi-life-preserver::before {
  content: "\f467";
}

.bi-lightbulb-fill::before {
  content: "\f468";
}

.bi-lightbulb-off-fill::before {
  content: "\f469";
}

.bi-lightbulb-off::before {
  content: "\f46a";
}

.bi-lightbulb::before {
  content: "\f46b";
}

.bi-lightning-charge-fill::before {
  content: "\f46c";
}

.bi-lightning-charge::before {
  content: "\f46d";
}

.bi-lightning-fill::before {
  content: "\f46e";
}

.bi-lightning::before {
  content: "\f46f";
}

.bi-link-45deg::before {
  content: "\f470";
}

.bi-link::before {
  content: "\f471";
}

.bi-linkedin::before {
  content: "\f472";
}

.bi-list-check::before {
  content: "\f473";
}

.bi-list-nested::before {
  content: "\f474";
}

.bi-list-ol::before {
  content: "\f475";
}

.bi-list-stars::before {
  content: "\f476";
}

.bi-list-task::before {
  content: "\f477";
}

.bi-list-ul::before {
  content: "\f478";
}

.bi-list::before {
  content: "\f479";
}

.bi-lock-fill::before {
  content: "\f47a";
}

.bi-lock::before {
  content: "\f47b";
}

.bi-mailbox::before {
  content: "\f47c";
}

.bi-mailbox2::before {
  content: "\f47d";
}

.bi-map-fill::before {
  content: "\f47e";
}

.bi-map::before {
  content: "\f47f";
}

.bi-markdown-fill::before {
  content: "\f480";
}

.bi-markdown::before {
  content: "\f481";
}

.bi-mask::before {
  content: "\f482";
}

.bi-megaphone-fill::before {
  content: "\f483";
}

.bi-megaphone::before {
  content: "\f484";
}

.bi-menu-app-fill::before {
  content: "\f485";
}

.bi-menu-app::before {
  content: "\f486";
}

.bi-menu-button-fill::before {
  content: "\f487";
}

.bi-menu-button-wide-fill::before {
  content: "\f488";
}

.bi-menu-button-wide::before {
  content: "\f489";
}

.bi-menu-button::before {
  content: "\f48a";
}

.bi-menu-down::before {
  content: "\f48b";
}

.bi-menu-up::before {
  content: "\f48c";
}

.bi-mic-fill::before {
  content: "\f48d";
}

.bi-mic-mute-fill::before {
  content: "\f48e";
}

.bi-mic-mute::before {
  content: "\f48f";
}

.bi-mic::before {
  content: "\f490";
}

.bi-minecart-loaded::before {
  content: "\f491";
}

.bi-minecart::before {
  content: "\f492";
}

.bi-moisture::before {
  content: "\f493";
}

.bi-moon-fill::before {
  content: "\f494";
}

.bi-moon-stars-fill::before {
  content: "\f495";
}

.bi-moon-stars::before {
  content: "\f496";
}

.bi-moon::before {
  content: "\f497";
}

.bi-mouse-fill::before {
  content: "\f498";
}

.bi-mouse::before {
  content: "\f499";
}

.bi-mouse2-fill::before {
  content: "\f49a";
}

.bi-mouse2::before {
  content: "\f49b";
}

.bi-mouse3-fill::before {
  content: "\f49c";
}

.bi-mouse3::before {
  content: "\f49d";
}

.bi-music-note-beamed::before {
  content: "\f49e";
}

.bi-music-note-list::before {
  content: "\f49f";
}

.bi-music-note::before {
  content: "\f4a0";
}

.bi-music-player-fill::before {
  content: "\f4a1";
}

.bi-music-player::before {
  content: "\f4a2";
}

.bi-newspaper::before {
  content: "\f4a3";
}

.bi-node-minus-fill::before {
  content: "\f4a4";
}

.bi-node-minus::before {
  content: "\f4a5";
}

.bi-node-plus-fill::before {
  content: "\f4a6";
}

.bi-node-plus::before {
  content: "\f4a7";
}

.bi-nut-fill::before {
  content: "\f4a8";
}

.bi-nut::before {
  content: "\f4a9";
}

.bi-octagon-fill::before {
  content: "\f4aa";
}

.bi-octagon-half::before {
  content: "\f4ab";
}

.bi-octagon::before {
  content: "\f4ac";
}

.bi-option::before {
  content: "\f4ad";
}

.bi-outlet::before {
  content: "\f4ae";
}

.bi-paint-bucket::before {
  content: "\f4af";
}

.bi-palette-fill::before {
  content: "\f4b0";
}

.bi-palette::before {
  content: "\f4b1";
}

.bi-palette2::before {
  content: "\f4b2";
}

.bi-paperclip::before {
  content: "\f4b3";
}

.bi-paragraph::before {
  content: "\f4b4";
}

.bi-patch-check-fill::before {
  content: "\f4b5";
}

.bi-patch-check::before {
  content: "\f4b6";
}

.bi-patch-exclamation-fill::before {
  content: "\f4b7";
}

.bi-patch-exclamation::before {
  content: "\f4b8";
}

.bi-patch-minus-fill::before {
  content: "\f4b9";
}

.bi-patch-minus::before {
  content: "\f4ba";
}

.bi-patch-plus-fill::before {
  content: "\f4bb";
}

.bi-patch-plus::before {
  content: "\f4bc";
}

.bi-patch-question-fill::before {
  content: "\f4bd";
}

.bi-patch-question::before {
  content: "\f4be";
}

.bi-pause-btn-fill::before {
  content: "\f4bf";
}

.bi-pause-btn::before {
  content: "\f4c0";
}

.bi-pause-circle-fill::before {
  content: "\f4c1";
}

.bi-pause-circle::before {
  content: "\f4c2";
}

.bi-pause-fill::before {
  content: "\f4c3";
}

.bi-pause::before {
  content: "\f4c4";
}

.bi-peace-fill::before {
  content: "\f4c5";
}

.bi-peace::before {
  content: "\f4c6";
}

.bi-pen-fill::before {
  content: "\f4c7";
}

.bi-pen::before {
  content: "\f4c8";
}

.bi-pencil-fill::before {
  content: "\f4c9";
}

.bi-pencil-square::before {
  content: "\f4ca";
}

.bi-pencil::before {
  content: "\f4cb";
}

.bi-pentagon-fill::before {
  content: "\f4cc";
}

.bi-pentagon-half::before {
  content: "\f4cd";
}

.bi-pentagon::before {
  content: "\f4ce";
}

.bi-people-fill::before {
  content: "\f4cf";
}

.bi-people::before {
  content: "\f4d0";
}

.bi-percent::before {
  content: "\f4d1";
}

.bi-person-badge-fill::before {
  content: "\f4d2";
}

.bi-person-badge::before {
  content: "\f4d3";
}

.bi-person-bounding-box::before {
  content: "\f4d4";
}

.bi-person-check-fill::before {
  content: "\f4d5";
}

.bi-person-check::before {
  content: "\f4d6";
}

.bi-person-circle::before {
  content: "\f4d7";
}

.bi-person-dash-fill::before {
  content: "\f4d8";
}

.bi-person-dash::before {
  content: "\f4d9";
}

.bi-person-fill::before {
  content: "\f4da";
}

.bi-person-lines-fill::before {
  content: "\f4db";
}

.bi-person-plus-fill::before {
  content: "\f4dc";
}

.bi-person-plus::before {
  content: "\f4dd";
}

.bi-person-square::before {
  content: "\f4de";
}

.bi-person-x-fill::before {
  content: "\f4df";
}

.bi-person-x::before {
  content: "\f4e0";
}

.bi-person::before {
  content: "\f4e1";
}

.bi-phone-fill::before {
  content: "\f4e2";
}

.bi-phone-landscape-fill::before {
  content: "\f4e3";
}

.bi-phone-landscape::before {
  content: "\f4e4";
}

.bi-phone-vibrate-fill::before {
  content: "\f4e5";
}

.bi-phone-vibrate::before {
  content: "\f4e6";
}

.bi-phone::before {
  content: "\f4e7";
}

.bi-pie-chart-fill::before {
  content: "\f4e8";
}

.bi-pie-chart::before {
  content: "\f4e9";
}

.bi-pin-angle-fill::before {
  content: "\f4ea";
}

.bi-pin-angle::before {
  content: "\f4eb";
}

.bi-pin-fill::before {
  content: "\f4ec";
}

.bi-pin::before {
  content: "\f4ed";
}

.bi-pip-fill::before {
  content: "\f4ee";
}

.bi-pip::before {
  content: "\f4ef";
}

.bi-play-btn-fill::before {
  content: "\f4f0";
}

.bi-play-btn::before {
  content: "\f4f1";
}

.bi-play-circle-fill::before {
  content: "\f4f2";
}

.bi-play-circle::before {
  content: "\f4f3";
}

.bi-play-fill::before {
  content: "\f4f4";
}

.bi-play::before {
  content: "\f4f5";
}

.bi-plug-fill::before {
  content: "\f4f6";
}

.bi-plug::before {
  content: "\f4f7";
}

.bi-plus-circle-dotted::before {
  content: "\f4f8";
}

.bi-plus-circle-fill::before {
  content: "\f4f9";
}

.bi-plus-circle::before {
  content: "\f4fa";
}

.bi-plus-square-dotted::before {
  content: "\f4fb";
}

.bi-plus-square-fill::before {
  content: "\f4fc";
}

.bi-plus-square::before {
  content: "\f4fd";
}

.bi-plus::before {
  content: "\f4fe";
}

.bi-power::before {
  content: "\f4ff";
}

.bi-printer-fill::before {
  content: "\f500";
}

.bi-printer::before {
  content: "\f501";
}

.bi-puzzle-fill::before {
  content: "\f502";
}

.bi-puzzle::before {
  content: "\f503";
}

.bi-question-circle-fill::before {
  content: "\f504";
}

.bi-question-circle::before {
  content: "\f505";
}

.bi-question-diamond-fill::before {
  content: "\f506";
}

.bi-question-diamond::before {
  content: "\f507";
}

.bi-question-octagon-fill::before {
  content: "\f508";
}

.bi-question-octagon::before {
  content: "\f509";
}

.bi-question-square-fill::before {
  content: "\f50a";
}

.bi-question-square::before {
  content: "\f50b";
}

.bi-question::before {
  content: "\f50c";
}

.bi-rainbow::before {
  content: "\f50d";
}

.bi-receipt-cutoff::before {
  content: "\f50e";
}

.bi-receipt::before {
  content: "\f50f";
}

.bi-reception-0::before {
  content: "\f510";
}

.bi-reception-1::before {
  content: "\f511";
}

.bi-reception-2::before {
  content: "\f512";
}

.bi-reception-3::before {
  content: "\f513";
}

.bi-reception-4::before {
  content: "\f514";
}

.bi-record-btn-fill::before {
  content: "\f515";
}

.bi-record-btn::before {
  content: "\f516";
}

.bi-record-circle-fill::before {
  content: "\f517";
}

.bi-record-circle::before {
  content: "\f518";
}

.bi-record-fill::before {
  content: "\f519";
}

.bi-record::before {
  content: "\f51a";
}

.bi-record2-fill::before {
  content: "\f51b";
}

.bi-record2::before {
  content: "\f51c";
}

.bi-reply-all-fill::before {
  content: "\f51d";
}

.bi-reply-all::before {
  content: "\f51e";
}

.bi-reply-fill::before {
  content: "\f51f";
}

.bi-reply::before {
  content: "\f520";
}

.bi-rss-fill::before {
  content: "\f521";
}

.bi-rss::before {
  content: "\f522";
}

.bi-rulers::before {
  content: "\f523";
}

.bi-save-fill::before {
  content: "\f524";
}

.bi-save::before {
  content: "\f525";
}

.bi-save2-fill::before {
  content: "\f526";
}

.bi-save2::before {
  content: "\f527";
}

.bi-scissors::before {
  content: "\f528";
}

.bi-screwdriver::before {
  content: "\f529";
}

.bi-search::before {
  content: "\f52a";
}

.bi-segmented-nav::before {
  content: "\f52b";
}

.bi-server::before {
  content: "\f52c";
}

.bi-share-fill::before {
  content: "\f52d";
}

.bi-share::before {
  content: "\f52e";
}

.bi-shield-check::before {
  content: "\f52f";
}

.bi-shield-exclamation::before {
  content: "\f530";
}

.bi-shield-fill-check::before {
  content: "\f531";
}

.bi-shield-fill-exclamation::before {
  content: "\f532";
}

.bi-shield-fill-minus::before {
  content: "\f533";
}

.bi-shield-fill-plus::before {
  content: "\f534";
}

.bi-shield-fill-x::before {
  content: "\f535";
}

.bi-shield-fill::before {
  content: "\f536";
}

.bi-shield-lock-fill::before {
  content: "\f537";
}

.bi-shield-lock::before {
  content: "\f538";
}

.bi-shield-minus::before {
  content: "\f539";
}

.bi-shield-plus::before {
  content: "\f53a";
}

.bi-shield-shaded::before {
  content: "\f53b";
}

.bi-shield-slash-fill::before {
  content: "\f53c";
}

.bi-shield-slash::before {
  content: "\f53d";
}

.bi-shield-x::before {
  content: "\f53e";
}

.bi-shield::before {
  content: "\f53f";
}

.bi-shift-fill::before {
  content: "\f540";
}

.bi-shift::before {
  content: "\f541";
}

.bi-shop-window::before {
  content: "\f542";
}

.bi-shop::before {
  content: "\f543";
}

.bi-shuffle::before {
  content: "\f544";
}

.bi-signpost-2-fill::before {
  content: "\f545";
}

.bi-signpost-2::before {
  content: "\f546";
}

.bi-signpost-fill::before {
  content: "\f547";
}

.bi-signpost-split-fill::before {
  content: "\f548";
}

.bi-signpost-split::before {
  content: "\f549";
}

.bi-signpost::before {
  content: "\f54a";
}

.bi-sim-fill::before {
  content: "\f54b";
}

.bi-sim::before {
  content: "\f54c";
}

.bi-skip-backward-btn-fill::before {
  content: "\f54d";
}

.bi-skip-backward-btn::before {
  content: "\f54e";
}

.bi-skip-backward-circle-fill::before {
  content: "\f54f";
}

.bi-skip-backward-circle::before {
  content: "\f550";
}

.bi-skip-backward-fill::before {
  content: "\f551";
}

.bi-skip-backward::before {
  content: "\f552";
}

.bi-skip-end-btn-fill::before {
  content: "\f553";
}

.bi-skip-end-btn::before {
  content: "\f554";
}

.bi-skip-end-circle-fill::before {
  content: "\f555";
}

.bi-skip-end-circle::before {
  content: "\f556";
}

.bi-skip-end-fill::before {
  content: "\f557";
}

.bi-skip-end::before {
  content: "\f558";
}

.bi-skip-forward-btn-fill::before {
  content: "\f559";
}

.bi-skip-forward-btn::before {
  content: "\f55a";
}

.bi-skip-forward-circle-fill::before {
  content: "\f55b";
}

.bi-skip-forward-circle::before {
  content: "\f55c";
}

.bi-skip-forward-fill::before {
  content: "\f55d";
}

.bi-skip-forward::before {
  content: "\f55e";
}

.bi-skip-start-btn-fill::before {
  content: "\f55f";
}

.bi-skip-start-btn::before {
  content: "\f560";
}

.bi-skip-start-circle-fill::before {
  content: "\f561";
}

.bi-skip-start-circle::before {
  content: "\f562";
}

.bi-skip-start-fill::before {
  content: "\f563";
}

.bi-skip-start::before {
  content: "\f564";
}

.bi-slack::before {
  content: "\f565";
}

.bi-slash-circle-fill::before {
  content: "\f566";
}

.bi-slash-circle::before {
  content: "\f567";
}

.bi-slash-square-fill::before {
  content: "\f568";
}

.bi-slash-square::before {
  content: "\f569";
}

.bi-slash::before {
  content: "\f56a";
}

.bi-sliders::before {
  content: "\f56b";
}

.bi-smartwatch::before {
  content: "\f56c";
}

.bi-snow::before {
  content: "\f56d";
}

.bi-snow2::before {
  content: "\f56e";
}

.bi-snow3::before {
  content: "\f56f";
}

.bi-sort-alpha-down-alt::before {
  content: "\f570";
}

.bi-sort-alpha-down::before {
  content: "\f571";
}

.bi-sort-alpha-up-alt::before {
  content: "\f572";
}

.bi-sort-alpha-up::before {
  content: "\f573";
}

.bi-sort-down-alt::before {
  content: "\f574";
}

.bi-sort-down::before {
  content: "\f575";
}

.bi-sort-numeric-down-alt::before {
  content: "\f576";
}

.bi-sort-numeric-down::before {
  content: "\f577";
}

.bi-sort-numeric-up-alt::before {
  content: "\f578";
}

.bi-sort-numeric-up::before {
  content: "\f579";
}

.bi-sort-up-alt::before {
  content: "\f57a";
}

.bi-sort-up::before {
  content: "\f57b";
}

.bi-soundwave::before {
  content: "\f57c";
}

.bi-speaker-fill::before {
  content: "\f57d";
}

.bi-speaker::before {
  content: "\f57e";
}

.bi-speedometer::before {
  content: "\f57f";
}

.bi-speedometer2::before {
  content: "\f580";
}

.bi-spellcheck::before {
  content: "\f581";
}

.bi-square-fill::before {
  content: "\f582";
}

.bi-square-half::before {
  content: "\f583";
}

.bi-square::before {
  content: "\f584";
}

.bi-stack::before {
  content: "\f585";
}

.bi-star-fill::before {
  content: "\f586";
}

.bi-star-half::before {
  content: "\f587";
}

.bi-star::before {
  content: "\f588";
}

.bi-stars::before {
  content: "\f589";
}

.bi-stickies-fill::before {
  content: "\f58a";
}

.bi-stickies::before {
  content: "\f58b";
}

.bi-sticky-fill::before {
  content: "\f58c";
}

.bi-sticky::before {
  content: "\f58d";
}

.bi-stop-btn-fill::before {
  content: "\f58e";
}

.bi-stop-btn::before {
  content: "\f58f";
}

.bi-stop-circle-fill::before {
  content: "\f590";
}

.bi-stop-circle::before {
  content: "\f591";
}

.bi-stop-fill::before {
  content: "\f592";
}

.bi-stop::before {
  content: "\f593";
}

.bi-stoplights-fill::before {
  content: "\f594";
}

.bi-stoplights::before {
  content: "\f595";
}

.bi-stopwatch-fill::before {
  content: "\f596";
}

.bi-stopwatch::before {
  content: "\f597";
}

.bi-subtract::before {
  content: "\f598";
}

.bi-suit-club-fill::before {
  content: "\f599";
}

.bi-suit-club::before {
  content: "\f59a";
}

.bi-suit-diamond-fill::before {
  content: "\f59b";
}

.bi-suit-diamond::before {
  content: "\f59c";
}

.bi-suit-heart-fill::before {
  content: "\f59d";
}

.bi-suit-heart::before {
  content: "\f59e";
}

.bi-suit-spade-fill::before {
  content: "\f59f";
}

.bi-suit-spade::before {
  content: "\f5a0";
}

.bi-sun-fill::before {
  content: "\f5a1";
}

.bi-sun::before {
  content: "\f5a2";
}

.bi-sunglasses::before {
  content: "\f5a3";
}

.bi-sunrise-fill::before {
  content: "\f5a4";
}

.bi-sunrise::before {
  content: "\f5a5";
}

.bi-sunset-fill::before {
  content: "\f5a6";
}

.bi-sunset::before {
  content: "\f5a7";
}

.bi-symmetry-horizontal::before {
  content: "\f5a8";
}

.bi-symmetry-vertical::before {
  content: "\f5a9";
}

.bi-table::before {
  content: "\f5aa";
}

.bi-tablet-fill::before {
  content: "\f5ab";
}

.bi-tablet-landscape-fill::before {
  content: "\f5ac";
}

.bi-tablet-landscape::before {
  content: "\f5ad";
}

.bi-tablet::before {
  content: "\f5ae";
}

.bi-tag-fill::before {
  content: "\f5af";
}

.bi-tag::before {
  content: "\f5b0";
}

.bi-tags-fill::before {
  content: "\f5b1";
}

.bi-tags::before {
  content: "\f5b2";
}

.bi-telegram::before {
  content: "\f5b3";
}

.bi-telephone-fill::before {
  content: "\f5b4";
}

.bi-telephone-forward-fill::before {
  content: "\f5b5";
}

.bi-telephone-forward::before {
  content: "\f5b6";
}

.bi-telephone-inbound-fill::before {
  content: "\f5b7";
}

.bi-telephone-inbound::before {
  content: "\f5b8";
}

.bi-telephone-minus-fill::before {
  content: "\f5b9";
}

.bi-telephone-minus::before {
  content: "\f5ba";
}

.bi-telephone-outbound-fill::before {
  content: "\f5bb";
}

.bi-telephone-outbound::before {
  content: "\f5bc";
}

.bi-telephone-plus-fill::before {
  content: "\f5bd";
}

.bi-telephone-plus::before {
  content: "\f5be";
}

.bi-telephone-x-fill::before {
  content: "\f5bf";
}

.bi-telephone-x::before {
  content: "\f5c0";
}

.bi-telephone::before {
  content: "\f5c1";
}

.bi-terminal-fill::before {
  content: "\f5c2";
}

.bi-terminal::before {
  content: "\f5c3";
}

.bi-text-center::before {
  content: "\f5c4";
}

.bi-text-indent-left::before {
  content: "\f5c5";
}

.bi-text-indent-right::before {
  content: "\f5c6";
}

.bi-text-left::before {
  content: "\f5c7";
}

.bi-text-paragraph::before {
  content: "\f5c8";
}

.bi-text-right::before {
  content: "\f5c9";
}

.bi-textarea-resize::before {
  content: "\f5ca";
}

.bi-textarea-t::before {
  content: "\f5cb";
}

.bi-textarea::before {
  content: "\f5cc";
}

.bi-thermometer-half::before {
  content: "\f5cd";
}

.bi-thermometer-high::before {
  content: "\f5ce";
}

.bi-thermometer-low::before {
  content: "\f5cf";
}

.bi-thermometer-snow::before {
  content: "\f5d0";
}

.bi-thermometer-sun::before {
  content: "\f5d1";
}

.bi-thermometer::before {
  content: "\f5d2";
}

.bi-three-dots-vertical::before {
  content: "\f5d3";
}

.bi-three-dots::before {
  content: "\f5d4";
}

.bi-toggle-off::before {
  content: "\f5d5";
}

.bi-toggle-on::before {
  content: "\f5d6";
}

.bi-toggle2-off::before {
  content: "\f5d7";
}

.bi-toggle2-on::before {
  content: "\f5d8";
}

.bi-toggles::before {
  content: "\f5d9";
}

.bi-toggles2::before {
  content: "\f5da";
}

.bi-tools::before {
  content: "\f5db";
}

.bi-tornado::before {
  content: "\f5dc";
}

.bi-trash-fill::before {
  content: "\f5dd";
}

.bi-trash::before {
  content: "\f5de";
}

.bi-trash2-fill::before {
  content: "\f5df";
}

.bi-trash2::before {
  content: "\f5e0";
}

.bi-tree-fill::before {
  content: "\f5e1";
}

.bi-tree::before {
  content: "\f5e2";
}

.bi-triangle-fill::before {
  content: "\f5e3";
}

.bi-triangle-half::before {
  content: "\f5e4";
}

.bi-triangle::before {
  content: "\f5e5";
}

.bi-trophy-fill::before {
  content: "\f5e6";
}

.bi-trophy::before {
  content: "\f5e7";
}

.bi-tropical-storm::before {
  content: "\f5e8";
}

.bi-truck-flatbed::before {
  content: "\f5e9";
}

.bi-truck::before {
  content: "\f5ea";
}

.bi-tsunami::before {
  content: "\f5eb";
}

.bi-tv-fill::before {
  content: "\f5ec";
}

.bi-tv::before {
  content: "\f5ed";
}

.bi-twitch::before {
  content: "\f5ee";
}

.bi-twitter::before {
  content: "\f5ef";
}

.bi-type-bold::before {
  content: "\f5f0";
}

.bi-type-h1::before {
  content: "\f5f1";
}

.bi-type-h2::before {
  content: "\f5f2";
}

.bi-type-h3::before {
  content: "\f5f3";
}

.bi-type-italic::before {
  content: "\f5f4";
}

.bi-type-strikethrough::before {
  content: "\f5f5";
}

.bi-type-underline::before {
  content: "\f5f6";
}

.bi-type::before {
  content: "\f5f7";
}

.bi-ui-checks-grid::before {
  content: "\f5f8";
}

.bi-ui-checks::before {
  content: "\f5f9";
}

.bi-ui-radios-grid::before {
  content: "\f5fa";
}

.bi-ui-radios::before {
  content: "\f5fb";
}

.bi-umbrella-fill::before {
  content: "\f5fc";
}

.bi-umbrella::before {
  content: "\f5fd";
}

.bi-union::before {
  content: "\f5fe";
}

.bi-unlock-fill::before {
  content: "\f5ff";
}

.bi-unlock::before {
  content: "\f600";
}

.bi-upc-scan::before {
  content: "\f601";
}

.bi-upc::before {
  content: "\f602";
}

.bi-upload::before {
  content: "\f603";
}

.bi-vector-pen::before {
  content: "\f604";
}

.bi-view-list::before {
  content: "\f605";
}

.bi-view-stacked::before {
  content: "\f606";
}

.bi-vinyl-fill::before {
  content: "\f607";
}

.bi-vinyl::before {
  content: "\f608";
}

.bi-voicemail::before {
  content: "\f609";
}

.bi-volume-down-fill::before {
  content: "\f60a";
}

.bi-volume-down::before {
  content: "\f60b";
}

.bi-volume-mute-fill::before {
  content: "\f60c";
}

.bi-volume-mute::before {
  content: "\f60d";
}

.bi-volume-off-fill::before {
  content: "\f60e";
}

.bi-volume-off::before {
  content: "\f60f";
}

.bi-volume-up-fill::before {
  content: "\f610";
}

.bi-volume-up::before {
  content: "\f611";
}

.bi-vr::before {
  content: "\f612";
}

.bi-wallet-fill::before {
  content: "\f613";
}

.bi-wallet::before {
  content: "\f614";
}

.bi-wallet2::before {
  content: "\f615";
}

.bi-watch::before {
  content: "\f616";
}

.bi-water::before {
  content: "\f617";
}

.bi-whatsapp::before {
  content: "\f618";
}

.bi-wifi-1::before {
  content: "\f619";
}

.bi-wifi-2::before {
  content: "\f61a";
}

.bi-wifi-off::before {
  content: "\f61b";
}

.bi-wifi::before {
  content: "\f61c";
}

.bi-wind::before {
  content: "\f61d";
}

.bi-window-dock::before {
  content: "\f61e";
}

.bi-window-sidebar::before {
  content: "\f61f";
}

.bi-window::before {
  content: "\f620";
}

.bi-wrench::before {
  content: "\f621";
}

.bi-x-circle-fill::before {
  content: "\f622";
}

.bi-x-circle::before {
  content: "\f623";
}

.bi-x-diamond-fill::before {
  content: "\f624";
}

.bi-x-diamond::before {
  content: "\f625";
}

.bi-x-octagon-fill::before {
  content: "\f626";
}

.bi-x-octagon::before {
  content: "\f627";
}

.bi-x-square-fill::before {
  content: "\f628";
}

.bi-x-square::before {
  content: "\f629";
}

.bi-x::before {
  content: "\f62a";
}

.bi-youtube::before {
  content: "\f62b";
}

.bi-zoom-in::before {
  content: "\f62c";
}

.bi-zoom-out::before {
  content: "\f62d";
}

.bi-bank::before {
  content: "\f62e";
}

.bi-bank2::before {
  content: "\f62f";
}

.bi-bell-slash-fill::before {
  content: "\f630";
}

.bi-bell-slash::before {
  content: "\f631";
}

.bi-cash-coin::before {
  content: "\f632";
}

.bi-check-lg::before {
  content: "\f633";
}

.bi-coin::before {
  content: "\f634";
}

.bi-currency-bitcoin::before {
  content: "\f635";
}

.bi-currency-dollar::before {
  content: "\f636";
}

.bi-currency-euro::before {
  content: "\f637";
}

.bi-currency-exchange::before {
  content: "\f638";
}

.bi-currency-pound::before {
  content: "\f639";
}

.bi-currency-yen::before {
  content: "\f63a";
}

.bi-dash-lg::before {
  content: "\f63b";
}

.bi-exclamation-lg::before {
  content: "\f63c";
}

.bi-file-earmark-pdf-fill::before {
  content: "\f63d";
}

.bi-file-earmark-pdf::before {
  content: "\f63e";
}

.bi-file-pdf-fill::before {
  content: "\f63f";
}

.bi-file-pdf::before {
  content: "\f640";
}

.bi-gender-ambiguous::before {
  content: "\f641";
}

.bi-gender-female::before {
  content: "\f642";
}

.bi-gender-male::before {
  content: "\f643";
}

.bi-gender-trans::before {
  content: "\f644";
}

.bi-headset-vr::before {
  content: "\f645";
}

.bi-info-lg::before {
  content: "\f646";
}

.bi-mastodon::before {
  content: "\f647";
}

.bi-messenger::before {
  content: "\f648";
}

.bi-piggy-bank-fill::before {
  content: "\f649";
}

.bi-piggy-bank::before {
  content: "\f64a";
}

.bi-pin-map-fill::before {
  content: "\f64b";
}

.bi-pin-map::before {
  content: "\f64c";
}

.bi-plus-lg::before {
  content: "\f64d";
}

.bi-question-lg::before {
  content: "\f64e";
}

.bi-recycle::before {
  content: "\f64f";
}

.bi-reddit::before {
  content: "\f650";
}

.bi-safe-fill::before {
  content: "\f651";
}

.bi-safe2-fill::before {
  content: "\f652";
}

.bi-safe2::before {
  content: "\f653";
}

.bi-sd-card-fill::before {
  content: "\f654";
}

.bi-sd-card::before {
  content: "\f655";
}

.bi-skype::before {
  content: "\f656";
}

.bi-slash-lg::before {
  content: "\f657";
}

.bi-translate::before {
  content: "\f658";
}

.bi-x-lg::before {
  content: "\f659";
}

.bi-safe::before {
  content: "\f65a";
}

.bi-apple::before {
  content: "\f65b";
}

.bi-microsoft::before {
  content: "\f65d";
}

.bi-windows::before {
  content: "\f65e";
}

.bi-behance::before {
  content: "\f65c";
}

.bi-dribbble::before {
  content: "\f65f";
}

.bi-line::before {
  content: "\f660";
}

.bi-medium::before {
  content: "\f661";
}

.bi-paypal::before {
  content: "\f662";
}

.bi-pinterest::before {
  content: "\f663";
}

.bi-signal::before {
  content: "\f664";
}

.bi-snapchat::before {
  content: "\f665";
}

.bi-spotify::before {
  content: "\f666";
}

.bi-stack-overflow::before {
  content: "\f667";
}

.bi-strava::before {
  content: "\f668";
}

.bi-wordpress::before {
  content: "\f669";
}

.bi-vimeo::before {
  content: "\f66a";
}

.bi-activity::before {
  content: "\f66b";
}

.bi-easel2-fill::before {
  content: "\f66c";
}

.bi-easel2::before {
  content: "\f66d";
}

.bi-easel3-fill::before {
  content: "\f66e";
}

.bi-easel3::before {
  content: "\f66f";
}

.bi-fan::before {
  content: "\f670";
}

.bi-fingerprint::before {
  content: "\f671";
}

.bi-graph-down-arrow::before {
  content: "\f672";
}

.bi-graph-up-arrow::before {
  content: "\f673";
}

.bi-hypnotize::before {
  content: "\f674";
}

.bi-magic::before {
  content: "\f675";
}

.bi-person-rolodex::before {
  content: "\f676";
}

.bi-person-video::before {
  content: "\f677";
}

.bi-person-video2::before {
  content: "\f678";
}

.bi-person-video3::before {
  content: "\f679";
}

.bi-person-workspace::before {
  content: "\f67a";
}

.bi-radioactive::before {
  content: "\f67b";
}

.bi-webcam-fill::before {
  content: "\f67c";
}

.bi-webcam::before {
  content: "\f67d";
}

.bi-yin-yang::before {
  content: "\f67e";
}

.bi-bandaid-fill::before {
  content: "\f680";
}

.bi-bandaid::before {
  content: "\f681";
}

.bi-bluetooth::before {
  content: "\f682";
}

.bi-body-text::before {
  content: "\f683";
}

.bi-boombox::before {
  content: "\f684";
}

.bi-boxes::before {
  content: "\f685";
}

.bi-dpad-fill::before {
  content: "\f686";
}

.bi-dpad::before {
  content: "\f687";
}

.bi-ear-fill::before {
  content: "\f688";
}

.bi-ear::before {
  content: "\f689";
}

.bi-envelope-check-1::before {
  content: "\f68a";
}

.bi-envelope-check-fill::before {
  content: "\f68b";
}

.bi-envelope-check::before {
  content: "\f68c";
}

.bi-envelope-dash-1::before {
  content: "\f68d";
}

.bi-envelope-dash-fill::before {
  content: "\f68e";
}

.bi-envelope-dash::before {
  content: "\f68f";
}

.bi-envelope-exclamation-1::before {
  content: "\f690";
}

.bi-envelope-exclamation-fill::before {
  content: "\f691";
}

.bi-envelope-exclamation::before {
  content: "\f692";
}

.bi-envelope-plus-fill::before {
  content: "\f693";
}

.bi-envelope-plus::before {
  content: "\f694";
}

.bi-envelope-slash-1::before {
  content: "\f695";
}

.bi-envelope-slash-fill::before {
  content: "\f696";
}

.bi-envelope-slash::before {
  content: "\f697";
}

.bi-envelope-x-1::before {
  content: "\f698";
}

.bi-envelope-x-fill::before {
  content: "\f699";
}

.bi-envelope-x::before {
  content: "\f69a";
}

.bi-explicit-fill::before {
  content: "\f69b";
}

.bi-explicit::before {
  content: "\f69c";
}

.bi-git::before {
  content: "\f69d";
}

.bi-infinity::before {
  content: "\f69e";
}

.bi-list-columns-reverse::before {
  content: "\f69f";
}

.bi-list-columns::before {
  content: "\f6a0";
}

.bi-meta::before {
  content: "\f6a1";
}

.bi-mortorboard-fill::before {
  content: "\f6a2";
}

.bi-mortorboard::before {
  content: "\f6a3";
}

.bi-nintendo-switch::before {
  content: "\f6a4";
}

.bi-pc-display-horizontal::before {
  content: "\f6a5";
}

.bi-pc-display::before {
  content: "\f6a6";
}

.bi-pc-horizontal::before {
  content: "\f6a7";
}

.bi-pc::before {
  content: "\f6a8";
}

.bi-playstation::before {
  content: "\f6a9";
}

.bi-plus-slash-minus::before {
  content: "\f6aa";
}

.bi-projector-fill::before {
  content: "\f6ab";
}

.bi-projector::before {
  content: "\f6ac";
}

.bi-qr-code-scan::before {
  content: "\f6ad";
}

.bi-qr-code::before {
  content: "\f6ae";
}

.bi-quora::before {
  content: "\f6af";
}

.bi-quote::before {
  content: "\f6b0";
}

.bi-robot::before {
  content: "\f6b1";
}

.bi-send-check-fill::before {
  content: "\f6b2";
}

.bi-send-check::before {
  content: "\f6b3";
}

.bi-send-dash-fill::before {
  content: "\f6b4";
}

.bi-send-dash::before {
  content: "\f6b5";
}

.bi-send-exclamation-1::before {
  content: "\f6b6";
}

.bi-send-exclamation-fill::before {
  content: "\f6b7";
}

.bi-send-exclamation::before {
  content: "\f6b8";
}

.bi-send-fill::before {
  content: "\f6b9";
}

.bi-send-plus-fill::before {
  content: "\f6ba";
}

.bi-send-plus::before {
  content: "\f6bb";
}

.bi-send-slash-fill::before {
  content: "\f6bc";
}

.bi-send-slash::before {
  content: "\f6bd";
}

.bi-send-x-fill::before {
  content: "\f6be";
}

.bi-send-x::before {
  content: "\f6bf";
}

.bi-send::before {
  content: "\f6c0";
}

.bi-steam::before {
  content: "\f6c1";
}

.bi-terminal-dash-1::before {
  content: "\f6c2";
}

.bi-terminal-dash::before {
  content: "\f6c3";
}

.bi-terminal-plus::before {
  content: "\f6c4";
}

.bi-terminal-split::before {
  content: "\f6c5";
}

.bi-ticket-detailed-fill::before {
  content: "\f6c6";
}

.bi-ticket-detailed::before {
  content: "\f6c7";
}

.bi-ticket-fill::before {
  content: "\f6c8";
}

.bi-ticket-perforated-fill::before {
  content: "\f6c9";
}

.bi-ticket-perforated::before {
  content: "\f6ca";
}

.bi-ticket::before {
  content: "\f6cb";
}

.bi-tiktok::before {
  content: "\f6cc";
}

.bi-window-dash::before {
  content: "\f6cd";
}

.bi-window-desktop::before {
  content: "\f6ce";
}

.bi-window-fullscreen::before {
  content: "\f6cf";
}

.bi-window-plus::before {
  content: "\f6d0";
}

.bi-window-split::before {
  content: "\f6d1";
}

.bi-window-stack::before {
  content: "\f6d2";
}

.bi-window-x::before {
  content: "\f6d3";
}

.bi-xbox::before {
  content: "\f6d4";
}

.bi-ethernet::before {
  content: "\f6d5";
}

.bi-hdmi-fill::before {
  content: "\f6d6";
}

.bi-hdmi::before {
  content: "\f6d7";
}

.bi-usb-c-fill::before {
  content: "\f6d8";
}

.bi-usb-c::before {
  content: "\f6d9";
}

.bi-usb-fill::before {
  content: "\f6da";
}

.bi-usb-plug-fill::before {
  content: "\f6db";
}

.bi-usb-plug::before {
  content: "\f6dc";
}

.bi-usb-symbol::before {
  content: "\f6dd";
}

.bi-usb::before {
  content: "\f6de";
}

.bi-boombox-fill::before {
  content: "\f6df";
}

.bi-displayport-1::before {
  content: "\f6e0";
}

.bi-displayport::before {
  content: "\f6e1";
}

.bi-gpu-card::before {
  content: "\f6e2";
}

.bi-memory::before {
  content: "\f6e3";
}

.bi-modem-fill::before {
  content: "\f6e4";
}

.bi-modem::before {
  content: "\f6e5";
}

.bi-motherboard-fill::before {
  content: "\f6e6";
}

.bi-motherboard::before {
  content: "\f6e7";
}

.bi-optical-audio-fill::before {
  content: "\f6e8";
}

.bi-optical-audio::before {
  content: "\f6e9";
}

.bi-pci-card::before {
  content: "\f6ea";
}

.bi-router-fill::before {
  content: "\f6eb";
}

.bi-router::before {
  content: "\f6ec";
}

.bi-ssd-fill::before {
  content: "\f6ed";
}

.bi-ssd::before {
  content: "\f6ee";
}

.bi-thunderbolt-fill::before {
  content: "\f6ef";
}

.bi-thunderbolt::before {
  content: "\f6f0";
}

.bi-usb-drive-fill::before {
  content: "\f6f1";
}

.bi-usb-drive::before {
  content: "\f6f2";
}

.bi-usb-micro-fill::before {
  content: "\f6f3";
}

.bi-usb-micro::before {
  content: "\f6f4";
}

.bi-usb-mini-fill::before {
  content: "\f6f5";
}

.bi-usb-mini::before {
  content: "\f6f6";
}

.bi-cloud-haze2::before {
  content: "\f6f7";
}

.bi-device-hdd-fill::before {
  content: "\f6f8";
}

.bi-device-hdd::before {
  content: "\f6f9";
}

.bi-device-ssd-fill::before {
  content: "\f6fa";
}

.bi-device-ssd::before {
  content: "\f6fb";
}

.bi-displayport-fill::before {
  content: "\f6fc";
}

.bi-mortarboard-fill::before {
  content: "\f6fd";
}

.bi-mortarboard::before {
  content: "\f6fe";
}

.bi-terminal-x::before {
  content: "\f6ff";
}

.bi-arrow-through-heart-fill::before {
  content: "\f700";
}

.bi-arrow-through-heart::before {
  content: "\f701";
}

.bi-badge-sd-fill::before {
  content: "\f702";
}

.bi-badge-sd::before {
  content: "\f703";
}

.bi-bag-heart-fill::before {
  content: "\f704";
}

.bi-bag-heart::before {
  content: "\f705";
}

.bi-balloon-fill::before {
  content: "\f706";
}

.bi-balloon-heart-fill::before {
  content: "\f707";
}

.bi-balloon-heart::before {
  content: "\f708";
}

.bi-balloon::before {
  content: "\f709";
}

.bi-box2-fill::before {
  content: "\f70a";
}

.bi-box2-heart-fill::before {
  content: "\f70b";
}

.bi-box2-heart::before {
  content: "\f70c";
}

.bi-box2::before {
  content: "\f70d";
}

.bi-braces-asterisk::before {
  content: "\f70e";
}

.bi-calendar-heart-fill::before {
  content: "\f70f";
}

.bi-calendar-heart::before {
  content: "\f710";
}

.bi-calendar2-heart-fill::before {
  content: "\f711";
}

.bi-calendar2-heart::before {
  content: "\f712";
}

.bi-chat-heart-fill::before {
  content: "\f713";
}

.bi-chat-heart::before {
  content: "\f714";
}

.bi-chat-left-heart-fill::before {
  content: "\f715";
}

.bi-chat-left-heart::before {
  content: "\f716";
}

.bi-chat-right-heart-fill::before {
  content: "\f717";
}

.bi-chat-right-heart::before {
  content: "\f718";
}

.bi-chat-square-heart-fill::before {
  content: "\f719";
}

.bi-chat-square-heart::before {
  content: "\f71a";
}

.bi-clipboard-check-fill::before {
  content: "\f71b";
}

.bi-clipboard-data-fill::before {
  content: "\f71c";
}

.bi-clipboard-fill::before {
  content: "\f71d";
}

.bi-clipboard-heart-fill::before {
  content: "\f71e";
}

.bi-clipboard-heart::before {
  content: "\f71f";
}

.bi-clipboard-minus-fill::before {
  content: "\f720";
}

.bi-clipboard-plus-fill::before {
  content: "\f721";
}

.bi-clipboard-pulse::before {
  content: "\f722";
}

.bi-clipboard-x-fill::before {
  content: "\f723";
}

.bi-clipboard2-check-fill::before {
  content: "\f724";
}

.bi-clipboard2-check::before {
  content: "\f725";
}

.bi-clipboard2-data-fill::before {
  content: "\f726";
}

.bi-clipboard2-data::before {
  content: "\f727";
}

.bi-clipboard2-fill::before {
  content: "\f728";
}

.bi-clipboard2-heart-fill::before {
  content: "\f729";
}

.bi-clipboard2-heart::before {
  content: "\f72a";
}

.bi-clipboard2-minus-fill::before {
  content: "\f72b";
}

.bi-clipboard2-minus::before {
  content: "\f72c";
}

.bi-clipboard2-plus-fill::before {
  content: "\f72d";
}

.bi-clipboard2-plus::before {
  content: "\f72e";
}

.bi-clipboard2-pulse-fill::before {
  content: "\f72f";
}

.bi-clipboard2-pulse::before {
  content: "\f730";
}

.bi-clipboard2-x-fill::before {
  content: "\f731";
}

.bi-clipboard2-x::before {
  content: "\f732";
}

.bi-clipboard2::before {
  content: "\f733";
}

.bi-emoji-kiss-fill::before {
  content: "\f734";
}

.bi-emoji-kiss::before {
  content: "\f735";
}

.bi-envelope-heart-fill::before {
  content: "\f736";
}

.bi-envelope-heart::before {
  content: "\f737";
}

.bi-envelope-open-heart-fill::before {
  content: "\f738";
}

.bi-envelope-open-heart::before {
  content: "\f739";
}

.bi-envelope-paper-fill::before {
  content: "\f73a";
}

.bi-envelope-paper-heart-fill::before {
  content: "\f73b";
}

.bi-envelope-paper-heart::before {
  content: "\f73c";
}

.bi-envelope-paper::before {
  content: "\f73d";
}

.bi-filetype-aac::before {
  content: "\f73e";
}

.bi-filetype-ai::before {
  content: "\f73f";
}

.bi-filetype-bmp::before {
  content: "\f740";
}

.bi-filetype-cs::before {
  content: "\f741";
}

.bi-filetype-css::before {
  content: "\f742";
}

.bi-filetype-csv::before {
  content: "\f743";
}

.bi-filetype-doc::before {
  content: "\f744";
}

.bi-filetype-docx::before {
  content: "\f745";
}

.bi-filetype-exe::before {
  content: "\f746";
}

.bi-filetype-gif::before {
  content: "\f747";
}

.bi-filetype-heic::before {
  content: "\f748";
}

.bi-filetype-html::before {
  content: "\f749";
}

.bi-filetype-java::before {
  content: "\f74a";
}

.bi-filetype-jpg::before {
  content: "\f74b";
}

.bi-filetype-js::before {
  content: "\f74c";
}

.bi-filetype-jsx::before {
  content: "\f74d";
}

.bi-filetype-key::before {
  content: "\f74e";
}

.bi-filetype-m4p::before {
  content: "\f74f";
}

.bi-filetype-md::before {
  content: "\f750";
}

.bi-filetype-mdx::before {
  content: "\f751";
}

.bi-filetype-mov::before {
  content: "\f752";
}

.bi-filetype-mp3::before {
  content: "\f753";
}

.bi-filetype-mp4::before {
  content: "\f754";
}

.bi-filetype-otf::before {
  content: "\f755";
}

.bi-filetype-pdf::before {
  content: "\f756";
}

.bi-filetype-php::before {
  content: "\f757";
}

.bi-filetype-png::before {
  content: "\f758";
}

.bi-filetype-ppt-1::before {
  content: "\f759";
}

.bi-filetype-ppt::before {
  content: "\f75a";
}

.bi-filetype-psd::before {
  content: "\f75b";
}

.bi-filetype-py::before {
  content: "\f75c";
}

.bi-filetype-raw::before {
  content: "\f75d";
}

.bi-filetype-rb::before {
  content: "\f75e";
}

.bi-filetype-sass::before {
  content: "\f75f";
}

.bi-filetype-scss::before {
  content: "\f760";
}

.bi-filetype-sh::before {
  content: "\f761";
}

.bi-filetype-svg::before {
  content: "\f762";
}

.bi-filetype-tiff::before {
  content: "\f763";
}

.bi-filetype-tsx::before {
  content: "\f764";
}

.bi-filetype-ttf::before {
  content: "\f765";
}

.bi-filetype-txt::before {
  content: "\f766";
}

.bi-filetype-wav::before {
  content: "\f767";
}

.bi-filetype-woff::before {
  content: "\f768";
}

.bi-filetype-xls-1::before {
  content: "\f769";
}

.bi-filetype-xls::before {
  content: "\f76a";
}

.bi-filetype-xml::before {
  content: "\f76b";
}

.bi-filetype-yml::before {
  content: "\f76c";
}

.bi-heart-arrow::before {
  content: "\f76d";
}

.bi-heart-pulse-fill::before {
  content: "\f76e";
}

.bi-heart-pulse::before {
  content: "\f76f";
}

.bi-heartbreak-fill::before {
  content: "\f770";
}

.bi-heartbreak::before {
  content: "\f771";
}

.bi-hearts::before {
  content: "\f772";
}

.bi-hospital-fill::before {
  content: "\f773";
}

.bi-hospital::before {
  content: "\f774";
}

.bi-house-heart-fill::before {
  content: "\f775";
}

.bi-house-heart::before {
  content: "\f776";
}

.bi-incognito::before {
  content: "\f777";
}

.bi-magnet-fill::before {
  content: "\f778";
}

.bi-magnet::before {
  content: "\f779";
}

.bi-person-heart::before {
  content: "\f77a";
}

.bi-person-hearts::before {
  content: "\f77b";
}

.bi-phone-flip::before {
  content: "\f77c";
}

.bi-plugin::before {
  content: "\f77d";
}

.bi-postage-fill::before {
  content: "\f77e";
}

.bi-postage-heart-fill::before {
  content: "\f77f";
}

.bi-postage-heart::before {
  content: "\f780";
}

.bi-postage::before {
  content: "\f781";
}

.bi-postcard-fill::before {
  content: "\f782";
}

.bi-postcard-heart-fill::before {
  content: "\f783";
}

.bi-postcard-heart::before {
  content: "\f784";
}

.bi-postcard::before {
  content: "\f785";
}

.bi-search-heart-fill::before {
  content: "\f786";
}

.bi-search-heart::before {
  content: "\f787";
}

.bi-sliders2-vertical::before {
  content: "\f788";
}

.bi-sliders2::before {
  content: "\f789";
}

.bi-trash3-fill::before {
  content: "\f78a";
}

.bi-trash3::before {
  content: "\f78b";
}

.bi-valentine::before {
  content: "\f78c";
}

.bi-valentine2::before {
  content: "\f78d";
}

.bi-wrench-adjustable-circle-fill::before {
  content: "\f78e";
}

.bi-wrench-adjustable-circle::before {
  content: "\f78f";
}

.bi-wrench-adjustable::before {
  content: "\f790";
}

.bi-filetype-json::before {
  content: "\f791";
}

.bi-filetype-pptx::before {
  content: "\f792";
}

.bi-filetype-xlsx::before {
  content: "\f793";
}

.bi-1-circle-1::before {
  content: "\f794";
}

.bi-1-circle-fill-1::before {
  content: "\f795";
}

.bi-1-circle-fill::before {
  content: "\f796";
}

.bi-1-circle::before {
  content: "\f797";
}

.bi-1-square-fill::before {
  content: "\f798";
}

.bi-1-square::before {
  content: "\f799";
}

.bi-2-circle-1::before {
  content: "\f79a";
}

.bi-2-circle-fill-1::before {
  content: "\f79b";
}

.bi-2-circle-fill::before {
  content: "\f79c";
}

.bi-2-circle::before {
  content: "\f79d";
}

.bi-2-square-fill::before {
  content: "\f79e";
}

.bi-2-square::before {
  content: "\f79f";
}

.bi-3-circle-1::before {
  content: "\f7a0";
}

.bi-3-circle-fill-1::before {
  content: "\f7a1";
}

.bi-3-circle-fill::before {
  content: "\f7a2";
}

.bi-3-circle::before {
  content: "\f7a3";
}

.bi-3-square-fill::before {
  content: "\f7a4";
}

.bi-3-square::before {
  content: "\f7a5";
}

.bi-4-circle-1::before {
  content: "\f7a6";
}

.bi-4-circle-fill-1::before {
  content: "\f7a7";
}

.bi-4-circle-fill::before {
  content: "\f7a8";
}

.bi-4-circle::before {
  content: "\f7a9";
}

.bi-4-square-fill::before {
  content: "\f7aa";
}

.bi-4-square::before {
  content: "\f7ab";
}

.bi-5-circle-1::before {
  content: "\f7ac";
}

.bi-5-circle-fill-1::before {
  content: "\f7ad";
}

.bi-5-circle-fill::before {
  content: "\f7ae";
}

.bi-5-circle::before {
  content: "\f7af";
}

.bi-5-square-fill::before {
  content: "\f7b0";
}

.bi-5-square::before {
  content: "\f7b1";
}

.bi-6-circle-1::before {
  content: "\f7b2";
}

.bi-6-circle-fill-1::before {
  content: "\f7b3";
}

.bi-6-circle-fill::before {
  content: "\f7b4";
}

.bi-6-circle::before {
  content: "\f7b5";
}

.bi-6-square-fill::before {
  content: "\f7b6";
}

.bi-6-square::before {
  content: "\f7b7";
}

.bi-7-circle-1::before {
  content: "\f7b8";
}

.bi-7-circle-fill-1::before {
  content: "\f7b9";
}

.bi-7-circle-fill::before {
  content: "\f7ba";
}

.bi-7-circle::before {
  content: "\f7bb";
}

.bi-7-square-fill::before {
  content: "\f7bc";
}

.bi-7-square::before {
  content: "\f7bd";
}

.bi-8-circle-1::before {
  content: "\f7be";
}

.bi-8-circle-fill-1::before {
  content: "\f7bf";
}

.bi-8-circle-fill::before {
  content: "\f7c0";
}

.bi-8-circle::before {
  content: "\f7c1";
}

.bi-8-square-fill::before {
  content: "\f7c2";
}

.bi-8-square::before {
  content: "\f7c3";
}

.bi-9-circle-1::before {
  content: "\f7c4";
}

.bi-9-circle-fill-1::before {
  content: "\f7c5";
}

.bi-9-circle-fill::before {
  content: "\f7c6";
}

.bi-9-circle::before {
  content: "\f7c7";
}

.bi-9-square-fill::before {
  content: "\f7c8";
}

.bi-9-square::before {
  content: "\f7c9";
}

.bi-airplane-engines-fill::before {
  content: "\f7ca";
}

.bi-airplane-engines::before {
  content: "\f7cb";
}

.bi-airplane-fill::before {
  content: "\f7cc";
}

.bi-airplane::before {
  content: "\f7cd";
}

.bi-alexa::before {
  content: "\f7ce";
}

.bi-alipay::before {
  content: "\f7cf";
}

.bi-android::before {
  content: "\f7d0";
}

.bi-android2::before {
  content: "\f7d1";
}

.bi-box-fill::before {
  content: "\f7d2";
}

.bi-box-seam-fill::before {
  content: "\f7d3";
}

.bi-browser-chrome::before {
  content: "\f7d4";
}

.bi-browser-edge::before {
  content: "\f7d5";
}

.bi-browser-firefox::before {
  content: "\f7d6";
}

.bi-browser-safari::before {
  content: "\f7d7";
}

.bi-c-circle-1::before {
  content: "\f7d8";
}

.bi-c-circle-fill-1::before {
  content: "\f7d9";
}

.bi-c-circle-fill::before {
  content: "\f7da";
}

.bi-c-circle::before {
  content: "\f7db";
}

.bi-c-square-fill::before {
  content: "\f7dc";
}

.bi-c-square::before {
  content: "\f7dd";
}

.bi-capsule-pill::before {
  content: "\f7de";
}

.bi-capsule::before {
  content: "\f7df";
}

.bi-car-front-fill::before {
  content: "\f7e0";
}

.bi-car-front::before {
  content: "\f7e1";
}

.bi-cassette-fill::before {
  content: "\f7e2";
}

.bi-cassette::before {
  content: "\f7e3";
}

.bi-cc-circle-1::before {
  content: "\f7e4";
}

.bi-cc-circle-fill-1::before {
  content: "\f7e5";
}

.bi-cc-circle-fill::before {
  content: "\f7e6";
}

.bi-cc-circle::before {
  content: "\f7e7";
}

.bi-cc-square-fill::before {
  content: "\f7e8";
}

.bi-cc-square::before {
  content: "\f7e9";
}

.bi-cup-hot-fill::before {
  content: "\f7ea";
}

.bi-cup-hot::before {
  content: "\f7eb";
}

.bi-currency-rupee::before {
  content: "\f7ec";
}

.bi-dropbox::before {
  content: "\f7ed";
}

.bi-escape::before {
  content: "\f7ee";
}

.bi-fast-forward-btn-fill::before {
  content: "\f7ef";
}

.bi-fast-forward-btn::before {
  content: "\f7f0";
}

.bi-fast-forward-circle-fill::before {
  content: "\f7f1";
}

.bi-fast-forward-circle::before {
  content: "\f7f2";
}

.bi-fast-forward-fill::before {
  content: "\f7f3";
}

.bi-fast-forward::before {
  content: "\f7f4";
}

.bi-filetype-sql::before {
  content: "\f7f5";
}

.bi-fire::before {
  content: "\f7f6";
}

.bi-google-play::before {
  content: "\f7f7";
}

.bi-h-circle-1::before {
  content: "\f7f8";
}

.bi-h-circle-fill-1::before {
  content: "\f7f9";
}

.bi-h-circle-fill::before {
  content: "\f7fa";
}

.bi-h-circle::before {
  content: "\f7fb";
}

.bi-h-square-fill::before {
  content: "\f7fc";
}

.bi-h-square::before {
  content: "\f7fd";
}

.bi-indent::before {
  content: "\f7fe";
}

.bi-lungs-fill::before {
  content: "\f7ff";
}

.bi-lungs::before {
  content: "\f800";
}

.bi-microsoft-teams::before {
  content: "\f801";
}

.bi-p-circle-1::before {
  content: "\f802";
}

.bi-p-circle-fill-1::before {
  content: "\f803";
}

.bi-p-circle-fill::before {
  content: "\f804";
}

.bi-p-circle::before {
  content: "\f805";
}

.bi-p-square-fill::before {
  content: "\f806";
}

.bi-p-square::before {
  content: "\f807";
}

.bi-pass-fill::before {
  content: "\f808";
}

.bi-pass::before {
  content: "\f809";
}

.bi-prescription::before {
  content: "\f80a";
}

.bi-prescription2::before {
  content: "\f80b";
}

.bi-r-circle-1::before {
  content: "\f80c";
}

.bi-r-circle-fill-1::before {
  content: "\f80d";
}

.bi-r-circle-fill::before {
  content: "\f80e";
}

.bi-r-circle::before {
  content: "\f80f";
}

.bi-r-square-fill::before {
  content: "\f810";
}

.bi-r-square::before {
  content: "\f811";
}

.bi-repeat-1::before {
  content: "\f812";
}

.bi-repeat::before {
  content: "\f813";
}

.bi-rewind-btn-fill::before {
  content: "\f814";
}

.bi-rewind-btn::before {
  content: "\f815";
}

.bi-rewind-circle-fill::before {
  content: "\f816";
}

.bi-rewind-circle::before {
  content: "\f817";
}

.bi-rewind-fill::before {
  content: "\f818";
}

.bi-rewind::before {
  content: "\f819";
}

.bi-train-freight-front-fill::before {
  content: "\f81a";
}

.bi-train-freight-front::before {
  content: "\f81b";
}

.bi-train-front-fill::before {
  content: "\f81c";
}

.bi-train-front::before {
  content: "\f81d";
}

.bi-train-lightrail-front-fill::before {
  content: "\f81e";
}

.bi-train-lightrail-front::before {
  content: "\f81f";
}

.bi-truck-front-fill::before {
  content: "\f820";
}

.bi-truck-front::before {
  content: "\f821";
}

.bi-ubuntu::before {
  content: "\f822";
}

.bi-unindent::before {
  content: "\f823";
}

.bi-unity::before {
  content: "\f824";
}

.bi-universal-access-circle::before {
  content: "\f825";
}

.bi-universal-access::before {
  content: "\f826";
}

.bi-virus::before {
  content: "\f827";
}

.bi-virus2::before {
  content: "\f828";
}

.bi-wechat::before {
  content: "\f829";
}

.bi-yelp::before {
  content: "\f82a";
}

.bi-sign-stop-fill::before {
  content: "\f82b";
}

.bi-sign-stop-lights-fill::before {
  content: "\f82c";
}

.bi-sign-stop-lights::before {
  content: "\f82d";
}

.bi-sign-stop::before {
  content: "\f82e";
}

.bi-sign-turn-left-fill::before {
  content: "\f82f";
}

.bi-sign-turn-left::before {
  content: "\f830";
}

.bi-sign-turn-right-fill::before {
  content: "\f831";
}

.bi-sign-turn-right::before {
  content: "\f832";
}

.bi-sign-turn-slight-left-fill::before {
  content: "\f833";
}

.bi-sign-turn-slight-left::before {
  content: "\f834";
}

.bi-sign-turn-slight-right-fill::before {
  content: "\f835";
}

.bi-sign-turn-slight-right::before {
  content: "\f836";
}

.bi-sign-yield-fill::before {
  content: "\f837";
}

.bi-sign-yield::before {
  content: "\f838";
}

.bi-ev-station-fill::before {
  content: "\f839";
}

.bi-ev-station::before {
  content: "\f83a";
}

.bi-fuel-pump-diesel-fill::before {
  content: "\f83b";
}

.bi-fuel-pump-diesel::before {
  content: "\f83c";
}

.bi-fuel-pump-fill::before {
  content: "\f83d";
}

.bi-fuel-pump::before {
  content: "\f83e";
}

/*!
 * Bootstrap  v5.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-primary: #3699FF;
  --bs-secondary: #F5CA99;
  --bs-third: #242939;
  --bs-fourth: #377DFF;
  --bs-fifth: #9FC0DA;
  --bs-sixth: #C9A7CF;
  --bs-seventh: #F2F5FA;
  --bs-eighth: #FAF6FB;
  --bs-nineth: #A0C0DA;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 248, 250, 252;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #f8fafc;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8fafc;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #f8fafc;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #f8fafc;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #f8fafc;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #f8fafc;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
:not(.btn-check) + .btn:hover, .btn:first-child:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #f8fafc;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #f8fafc;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: #f8fafc;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #084298;
  --bs-alert-bg: #cfe2ff;
  --bs-alert-border-color: #b6d4fe;
}
.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #0d6efd !important;
}
.link-primary:hover, .link-primary:focus {
  color: #0a58ca !important;
}

.link-secondary {
  color: #6c757d !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #198754 !important;
}
.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*!
 * Bootstrap v5.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #377dff;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #ed4c78;
  --bs-orange: #fd7e14;
  --bs-yellow: #f5ca99;
  --bs-green: #198754;
  --bs-teal: #00c9a7;
  --bs-cyan: #09a5be;
  --bs-white: #fff;
  --bs-gray: #8c98a4;
  --bs-gray-dark: #71869d;
  --bs-gray-100: #f7faff;
  --bs-gray-200: #f8fafd;
  --bs-gray-300: #e7eaf3;
  --bs-gray-400: #bdc5d1;
  --bs-gray-500: #97a4af;
  --bs-gray-600: #8c98a4;
  --bs-gray-700: #677788;
  --bs-gray-800: #71869d;
  --bs-gray-900: #1e2022;
  --bs-primary: #377dff;
  --bs-secondary: #71869d;
  --bs-success: #00c9a7;
  --bs-info: #09a5be;
  --bs-warning: #f5ca99;
  --bs-danger: #ed4c78;
  --bs-light: #f7faff;
  --bs-dark: #21325b;
  --bs-primary-rgb: 55, 125, 255;
  --bs-secondary-rgb: 113, 134, 157;
  --bs-success-rgb: 0, 201, 167;
  --bs-info-rgb: 9, 165, 190;
  --bs-warning-rgb: 245, 202, 153;
  --bs-danger-rgb: 237, 76, 120;
  --bs-light-rgb: 247, 250, 255;
  --bs-dark-rgb: 33, 50, 91;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-rgb: 103, 119, 136;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Inter, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #677788;
  --bs-body-bg: #fff;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

hr {
  margin: 1rem 0;
  color: rgba(33, 50, 91, 0.1);
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

hr:not([size]) {
  height: 0.0625rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #1e2022;
}

.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  .h1,
h1 {
    font-size: 2.5rem;
  }
}
.h2,
h2 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  .h2,
h2 {
    font-size: 1.75rem;
  }
}
.h3,
h3 {
  font-size: calc(1.25625rem + 0.075vw);
}

@media (min-width: 1200px) {
  .h3,
h3 {
    font-size: 1.3125rem;
  }
}
.h4,
h4 {
  font-size: 1.125rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: 0.875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-bs-original-title],
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

.small,
small {
  font-size: 0.875em;
}

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #377dff;
  text-decoration: none;
}

a:hover {
  color: #1366ff;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code,
kbd,
pre,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 1em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 1em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 1em;
  color: #1e2022;
  background-color: #e7eaf3;
  border-radius: 0.3125rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #8c98a4;
  text-align: left;
}

th {
  font-weight: 400;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.25625rem + 0.075vw);
  font-weight: 400;
}

@media (min-width: 1200px) {
  .lead {
    font-size: 1.3125rem;
  }
}
.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 600;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 600;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 600;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.25rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 0;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  color: #1e2022;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.5rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #8c98a4;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1400px) {
  .container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.row > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
}

.row-cols-auto > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 2.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 2.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 3.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 3.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 4rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 4rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 4.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 4.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 2rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 2rem;
  }
  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 3rem;
  }
  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 3rem;
  }
  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 4rem;
  }
  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 4rem;
  }
  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 4.5rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #677788;
  --bs-table-striped-bg: #f7faff;
  --bs-table-active-color: #677788;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #677788;
  --bs-table-hover-bg: rgba(231, 234, 243, 0.4);
  width: 100%;
  margin-bottom: 1rem;
  color: #677788;
  vertical-align: top;
  border-color: rgba(33, 50, 91, 0.1);
}

.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 0.0625rem;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 0.0625rem 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 0.0625rem;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d7e5ff;
  --bs-table-striped-bg: #ccdaf2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2cee6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c7d4ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c2cee6;
}

.table-secondary {
  --bs-table-bg: #e3e7eb;
  --bs-table-striped-bg: #d8dbdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ccd0d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d2d6d9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #ccd0d4;
}

.table-success {
  --bs-table-bg: #ccf4ed;
  --bs-table-striped-bg: #c2e8e1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8dcd5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bde2db;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8dcd5;
}

.table-info {
  --bs-table-bg: #ceedf2;
  --bs-table-striped-bg: #c4e1e6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b9d5da;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfdbe0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b9d5da;
}

.table-warning {
  --bs-table-bg: #fdf4eb;
  --bs-table-striped-bg: #f0e8df;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4dcd4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eae2d9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e4dcd4;
}

.table-danger {
  --bs-table-bg: #fbdbe4;
  --bs-table-striped-bg: #eed0d9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2c5cd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8cbd3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e2c5cd;
}

.table-light {
  --bs-table-bg: #f7faff;
  --bs-table-striped-bg: #ebeef2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dee1e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4e7ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dee1e6;
}

.table-dark {
  --bs-table-bg: #21325b;
  --bs-table-striped-bg: #2c3c63;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #37476b;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #324167;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #37476b;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
/* purgecss start ignore */
.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #1e2022;
}

.col-form-label {
  padding-top: 0.675rem;
  padding-bottom: 0.675rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  color: #1e2022;
}

.col-form-label-lg {
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  font-size: 0.875rem;
}

.form-text {
  margin-top: 1rem;
  font-size: 0.875em;
  color: #8c98a4;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.6125rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1e2022;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #1e2022;
  background-color: #fff;
  border-color: rgba(140, 152, 164, 0.25);
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::-webkit-input-placeholder {
  color: #8c98a4;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #8c98a4;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #8c98a4;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #8c98a4;
  opacity: 1;
}

.form-control::placeholder {
  color: #8c98a4;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f8fafd;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.6125rem 1rem;
  margin: -0.6125rem -1rem;
  -webkit-margin-end: 1rem;
  -moz-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: #1e2022;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0.0625rem;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f2f2f2;
}

.form-control::-webkit-file-upload-button {
  padding: 0.6125rem 1rem;
  margin: -0.6125rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: #1e2022;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0.0625rem;
  border-radius: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #f2f2f2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6125rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #677788;
  background-color: transparent;
  border: solid transparent;
  border-width: 0.0625rem 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 1.125rem);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}

.form-control-sm::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  -moz-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.625rem);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
}

.form-control-lg::file-selector-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  -webkit-margin-end: 1rem;
  -moz-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.35rem);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 1.125rem);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1.625rem);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.6125rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.3125rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.3125rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.6125rem 3rem 0.6125rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1e2022;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2371869d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: rgba(140, 152, 164, 0.25);
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #f8fafd;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1e2022;
}

.form-select-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(33, 50, 91, 0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:active {
  -webkit-filter: 100%;
  filter: 100%;
}

.form-check-input:focus {
  border-color: rgba(140, 152, 164, 0.25);
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}

.form-check-input:checked {
  background-color: #377dff;
  border-color: #377dff;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #377dff;
  border-color: #377dff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 0.5;
}

.form-check-label {
  color: #677788;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28140, 152, 164, 0.25%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #377dff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c3d8ff;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e7eaf3;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #377dff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c3d8ff;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e7eaf3;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #97a4af;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #97a4af;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: 3.625rem;
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1rem;
  pointer-events: none;
  border: 0.0625rem solid transparent;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 1rem;
}

.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}

.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.6125rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8c98a4;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
}

.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
}

.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -0.0625rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #00c9a7;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #00c9a7;
  border-radius: 0.5rem;
}

.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #00c9a7;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #00c9a7;
  box-shadow: 0 0 1rem 0 rgba(0, 201, 167, 0.25);
}

.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: #00c9a7;
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: #00c9a7;
  box-shadow: 0 0 1rem 0 rgba(0, 201, 167, 0.25);
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #00c9a7;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #00c9a7;
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 1rem 0 rgba(0, 201, 167, 0.25);
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #00c9a7;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
  z-index: 1;
}

.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ed4c78;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #ed4c78;
  border-radius: 0.5rem;
}

.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #ed4c78;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #ed4c78;
  box-shadow: 0 0 1rem 0 rgba(237, 76, 120, 0.25);
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: #ed4c78;
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: #ed4c78;
  box-shadow: 0 0 1rem 0 rgba(237, 76, 120, 0.25);
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #ed4c78;
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #ed4c78;
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 1rem 0 rgba(237, 76, 120, 0.25);
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #ed4c78;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
  z-index: 2;
}

.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
  z-index: 3;
}

/* purgecss end ignore */
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #677788;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  padding: 0.6125rem 1rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #677788;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2f6ad9;
  border-color: #2c64cc;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #2f6ad9;
  border-color: #2c64cc;
  box-shadow: 0 0 0 0 rgba(85, 145, 255, 0.5);
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2c64cc;
  border-color: #295ebf;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(85, 145, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.btn-secondary {
  color: #fff;
  background-color: #71869d;
  border-color: #71869d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #607285;
  border-color: #5a6b7e;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #607285;
  border-color: #5a6b7e;
  box-shadow: 0 0 0 0 rgba(134, 152, 172, 0.5);
}

.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5a6b7e;
  border-color: #556576;
}

.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(134, 152, 172, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #71869d;
  border-color: #71869d;
}

.btn-success {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-success:hover {
  color: #fff;
  background-color: #00ab8e;
  border-color: #00a186;
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #00ab8e;
  border-color: #00a186;
  box-shadow: 0 0 0 0 rgba(38, 209, 180, 0.5);
}

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00a186;
  border-color: #00977d;
}

.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 209, 180, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-info {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}

.btn-info:hover {
  color: #fff;
  background-color: #088ca2;
  border-color: #078498;
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #fff;
  background-color: #088ca2;
  border-color: #078498;
  box-shadow: 0 0 0 0 rgba(46, 179, 200, 0.5);
}

.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #078498;
  border-color: #077c8f;
}

.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(46, 179, 200, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}

.btn-warning {
  color: #000;
  background-color: #f5ca99;
  border-color: #f5ca99;
}

.btn-warning:hover {
  color: #000;
  background-color: #f7d2a8;
  border-color: #f6cfa3;
}

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #f7d2a8;
  border-color: #f6cfa3;
  box-shadow: 0 0 0 0 rgba(208, 172, 130, 0.5);
}

.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning:active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #f7d5ad;
  border-color: #f6cfa3;
}

.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(208, 172, 130, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #000;
  background-color: #f5ca99;
  border-color: #f5ca99;
}

.btn-danger {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c94166;
  border-color: #be3d60;
}

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #c94166;
  border-color: #be3d60;
  box-shadow: 0 0 0 0 rgba(240, 103, 140, 0.5);
}

.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger:active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #be3d60;
  border-color: #b2395a;
}

.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(240, 103, 140, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78;
}

.btn-light {
  color: #000;
  background-color: #f7faff;
  border-color: #f7faff;
}

.btn-light:hover {
  color: #000;
  background-color: #f8fbff;
  border-color: #f8fbff;
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #f8fbff;
  border-color: #f8fbff;
  box-shadow: 0 0 0 0 rgba(210, 213, 217, 0.5);
}

.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light:active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fbff;
  border-color: #f8fbff;
}

.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(210, 213, 217, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #000;
  background-color: #f7faff;
  border-color: #f7faff;
}

.btn-dark {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}

.btn-dark:hover {
  color: #fff;
  background-color: #1c2b4d;
  border-color: #1a2849;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #1c2b4d;
  border-color: #1a2849;
  box-shadow: 0 0 0 0 rgba(66, 81, 116, 0.5);
}

.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark:active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a2849;
  border-color: #192644;
}

.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(66, 81, 116, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}

.btn-outline-primary {
  color: #377dff;
  border-color: #377dff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(55, 125, 255, 0.5);
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0 rgba(55, 125, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #377dff;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #71869d;
  border-color: #71869d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #71869d;
  border-color: #71869d;
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(113, 134, 157, 0.5);
}

.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #fff;
  background-color: #71869d;
  border-color: #71869d;
}

.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0 rgba(113, 134, 157, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #71869d;
  background-color: transparent;
}

.btn-outline-success {
  color: #00c9a7;
  border-color: #00c9a7;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(0, 201, 167, 0.5);
}

.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  box-shadow: 0 0 0 0 rgba(0, 201, 167, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #00c9a7;
  background-color: transparent;
}

.btn-outline-info {
  color: #09a5be;
  border-color: #09a5be;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(9, 165, 190, 0.5);
}

.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}

.btn-check:active + .btn-outline-info:focus,
.btn-check:checked + .btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  box-shadow: 0 0 0 0 rgba(9, 165, 190, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #09a5be;
  background-color: transparent;
}

.btn-outline-warning {
  color: #f5ca99;
  border-color: #f5ca99;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #f5ca99;
  border-color: #f5ca99;
}

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(245, 202, 153, 0.5);
}

.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: #000;
  background-color: #f5ca99;
  border-color: #f5ca99;
}

.btn-check:active + .btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  box-shadow: 0 0 0 0 rgba(245, 202, 153, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #f5ca99;
  background-color: transparent;
}

.btn-outline-danger {
  color: #ed4c78;
  border-color: #ed4c78;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78;
}

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(237, 76, 120, 0.5);
}

.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78;
}

.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  box-shadow: 0 0 0 0 rgba(237, 76, 120, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #ed4c78;
  background-color: transparent;
}

.btn-outline-light {
  color: #f7faff;
  border-color: #f7faff;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f7faff;
  border-color: #f7faff;
}

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(247, 250, 255, 0.5);
}

.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  color: #000;
  background-color: #f7faff;
  border-color: #f7faff;
}

.btn-check:active + .btn-outline-light:focus,
.btn-check:checked + .btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  box-shadow: 0 0 0 0 rgba(247, 250, 255, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f7faff;
  background-color: transparent;
}

.btn-outline-dark {
  color: #21325b;
  border-color: #21325b;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(33, 50, 91, 0.5);
}

.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}

.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  box-shadow: 0 0 0 0 rgba(33, 50, 91, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #21325b;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #377dff;
  text-decoration: none;
}

.btn-link:hover {
  color: #1366ff;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #8c98a4;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0.5rem;
  margin: 0;
  font-size: 1rem;
  color: #677788;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.625rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.625rem;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.625rem;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.625rem;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e7eaf3;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #1e2022;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #1b1d1f;
  background-color: rgba(189, 197, 209, 0.2);
}

.dropdown-item.active,
.dropdown-item:active {
  color: #1e2022;
  text-decoration: none;
  background-color: rgba(189, 197, 209, 0.2);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #97a4af;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #8c98a4;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1rem;
  color: #1e2022;
}

.dropdown-menu-dark {
  color: #e7eaf3;
  background-color: #71869d;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #e7eaf3;
}

.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.2);
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #97a4af;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: #e7eaf3;
}

.dropdown-menu-dark .dropdown-item-text {
  color: #e7eaf3;
}

.dropdown-menu-dark .dropdown-header {
  color: #97a4af;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -0.0625rem;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -0.0625rem;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #21325b;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:focus,
.nav-link:hover {
  color: #1366ff;
}

.nav-link.disabled {
  color: #8c98a4;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0.1875rem solid rgba(33, 50, 91, 0.1);
}

.nav-tabs .nav-link {
  margin-bottom: -0.1875rem;
  background: 0 0;
  border: 0.1875rem solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: rgba(33, 50, 91, 0.1);
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #8c98a4;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #377dff;
  background-color: transparent;
  border-color: #377dff;
}

.nav-tabs .dropdown-menu {
  margin-top: -0.1875rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.5rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: initial;
  background-color: rgba(189, 197, 209, 0.2);
}

.nav-fill .nav-item,
.nav-fill > .nav-link {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item,
.nav-justified > .nav-link {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.5rem 0.5rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-bottom,
.navbar-expand-sm .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-bottom,
.navbar-expand-md .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-bottom,
.navbar-expand-lg .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-bottom,
.navbar-expand-xl .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-bottom,
.navbar-expand-xxl .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  -ms-flex-positive: 1;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  -webkit-transform: none;
  transform: none;
}

.navbar-expand .offcanvas-bottom,
.navbar-expand .offcanvas-top {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}

.navbar-expand .offcanvas-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 0;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: #677788;
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #677788;
}

.navbar-light .navbar-nav .nav-link {
  color: #677788;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #377dff;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: #677788;
}

.navbar-light .navbar-toggler {
  color: #677788;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23677788' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #677788;
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: #677788;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.5rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.4375rem;
  border-top-right-radius: 0.4375rem;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.4375rem;
  border-bottom-left-radius: 0.4375rem;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 2rem 2rem;
}

.card-title {
  margin-bottom: 0.25rem;
}

.card-subtitle {
  margin-top: -0.125rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 2rem;
}

.card-header {
  padding: 2rem 2rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.card-header:first-child {
  border-radius: 0.4375rem 0.4375rem 0 0;
}

.card-footer {
  padding: 2rem 2rem;
  background-color: transparent;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.card-footer:last-child {
  border-radius: 0 0 0.4375rem 0.4375rem;
}

.card-header-tabs {
  margin-right: -1rem;
  margin-bottom: -2rem;
  margin-left: -1rem;
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -1rem;
  margin-left: -1rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0.4375rem;
}

.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.4375rem;
  border-top-right-radius: 0.4375rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.4375rem;
  border-bottom-left-radius: 0.4375rem;
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
.card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
.card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
.card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
.card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.accordion-button {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  color: #21325b;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: all 0.2s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #3271e6;
  background-color: #fff;
  box-shadow: inset 0 -0.0625rem 0 rgba(33, 50, 91, 0.1);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23377dff'%3e%3cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.accordion-button::after {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2321325b'%3e%3cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: rgba(140, 152, 164, 0.25);
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.4375rem;
  border-top-right-radius: 0.4375rem;
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0.4375rem;
  border-bottom-left-radius: 0.4375rem;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.accordion-body {
  padding: 0.75rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #97a4af;
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: #8c98a4;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #677788;
  background-color: #fff;
  border: 0 solid #e7eaf3;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #1366ff;
  background-color: #f8fafd;
  border-color: #e7eaf3;
}

.page-link:focus {
  z-index: 3;
  color: #1366ff;
  background-color: #f8fafd;
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.page-item.disabled .page-link {
  color: #8c98a4;
  pointer-events: none;
  background-color: #fff;
  border-color: #e7eaf3;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 0.75rem 0.75rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.5rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 2.25rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.9375rem 0.75rem;
}

.alert-primary {
  color: #214b99;
  background-color: #d7e5ff;
  border-color: #c3d8ff;
}

.alert-primary .alert-link {
  color: #1a3c7a;
}

.alert-secondary {
  color: #44505e;
  background-color: #e3e7eb;
  border-color: #d4dbe2;
}

.alert-secondary .alert-link {
  color: #36404b;
}

.alert-success {
  color: #007964;
  background-color: #ccf4ed;
  border-color: #b3efe5;
}

.alert-success .alert-link {
  color: #006150;
}

.alert-info {
  color: #056372;
  background-color: #ceedf2;
  border-color: #b5e4ec;
}

.alert-info .alert-link {
  color: #044f5b;
}

.alert-warning {
  color: #93795c;
  background-color: #fdf4eb;
  border-color: #fcefe0;
}

.alert-warning .alert-link {
  color: #76614a;
}

.alert-danger {
  color: #8e2e48;
  background-color: #fbdbe4;
  border-color: #fac9d7;
}

.alert-danger .alert-link {
  color: #72253a;
}

.alert-light {
  color: #949699;
  background-color: #fdfeff;
  border-color: #fdfeff;
}

.alert-light .alert-link {
  color: #76787a;
}

.alert-dark {
  color: #141e37;
  background-color: #d3d6de;
  border-color: #bcc2ce;
}

.alert-dark .alert-link {
  color: #10182c;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.5rem;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.5rem;
  }
}
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e7eaf3;
  border-radius: 0.5rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #377dff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.5rem 0.5rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.5rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #677788;
  text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #677788;
  text-decoration: none;
  background-color: #f7faff;
}

.list-group-item-action:active {
  color: #677788;
  background-color: #f8fafd;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 1rem;
  color: #1e2022;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #8c98a4;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -0.0625rem;
  border-top-width: 0.0625rem;
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 0.0625rem;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -0.0625rem;
  border-left-width: 0.0625rem;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}
.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 0.0625rem;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #214b99;
  background-color: #d7e5ff;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #214b99;
  background-color: #c2cee6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #214b99;
  border-color: #214b99;
}

.list-group-item-secondary {
  color: #44505e;
  background-color: #e3e7eb;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #44505e;
  background-color: #ccd0d4;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #44505e;
  border-color: #44505e;
}

.list-group-item-success {
  color: #007964;
  background-color: #ccf4ed;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #007964;
  background-color: #b8dcd5;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #007964;
  border-color: #007964;
}

.list-group-item-info {
  color: #056372;
  background-color: #ceedf2;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #056372;
  background-color: #b9d5da;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #056372;
  border-color: #056372;
}

.list-group-item-warning {
  color: #93795c;
  background-color: #fdf4eb;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #93795c;
  background-color: #e4dcd4;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #93795c;
  border-color: #93795c;
}

.list-group-item-danger {
  color: #8e2e48;
  background-color: #fbdbe4;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #8e2e48;
  background-color: #e2c5cd;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #8e2e48;
  border-color: #8e2e48;
}

.list-group-item-light {
  color: #949699;
  background-color: #fdfeff;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #949699;
  background-color: #e4e5e6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #949699;
  border-color: #949699;
}

.list-group-item-dark {
  color: #141e37;
  background-color: #d3d6de;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #141e37;
  background-color: #bec1c8;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141e37;
  border-color: #141e37;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
  opacity: 1;
}

.btn-close.disabled,
.btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(33, 50, 91, 0.1);
  box-shadow: 0 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
  border-radius: 0.5rem;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 1rem;
  color: #8c98a4;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.5rem;
  margin-left: 1rem;
}

.toast-body {
  padding: 1rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.75rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(33, 50, 91, 0.25);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 1;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: 0 solid rgba(33, 50, 91, 0.1);
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;
}

.modal-header .btn-close {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 2rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1.75rem;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-bottom-right-radius: 0.6875rem;
  border-bottom-left-radius: 0.6875rem;
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 400px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[data-popper-placement^=top],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #21325b;
}

.bs-tooltip-auto[data-popper-placement^=right],
.bs-tooltip-end {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #21325b;
}

.bs-tooltip-auto[data-popper-placement^=bottom],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #21325b;
}

.bs-tooltip-auto[data-popper-placement^=left],
.bs-tooltip-start {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #21325b;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #21325b;
  border-radius: 0.5rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.75rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: -0.5625rem;
}

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: transparent;
}

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after,
.bs-popover-top > .popover-arrow::after {
  bottom: 0.0625rem;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  left: -0.5625rem;
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: transparent;
}

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after,
.bs-popover-end > .popover-arrow::after {
  left: 0.0625rem;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: -0.5625rem;
}

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: transparent;
}

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after {
  top: 0.0625rem;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 0.0625rem solid #f8fafd;
}

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  right: -0.5625rem;
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: transparent;
}

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after,
.bs-popover-start > .popover-arrow::after {
  right: 0.0625rem;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #1e2022;
  background-color: #f8fafd;
  border-bottom: 0.0625rem solid transparent;
  border-top-left-radius: 0.6875rem;
  border-top-right-radius: 0.6875rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1.5rem 1.5rem;
  color: #677788;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
.carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  -webkit-filter: invert(1) grayscale(100);
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(33, 50, 91, 0.25);
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 1;
}

.offcanvas-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 2.5rem 2.5rem;
}

.offcanvas-header .btn-close {
  padding: 1.25rem 1.25rem;
  margin-top: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: -1.25rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 2.5rem 2.5rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 0.0625rem solid transparent;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 0.0625rem solid transparent;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0.0625rem solid transparent;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0.0625rem solid transparent;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.offcanvas.show {
  -webkit-transform: none;
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
}

@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}
@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #377dff;
}

.link-primary:focus,
.link-primary:hover {
  color: #2c64cc;
}

.link-secondary {
  color: #71869d;
}

.link-secondary:focus,
.link-secondary:hover {
  color: #5a6b7e;
}

.link-success {
  color: #00c9a7;
}

.link-success:focus,
.link-success:hover {
  color: #00a186;
}

.link-info {
  color: #09a5be;
}

.link-info:focus,
.link-info:hover {
  color: #078498;
}

.link-warning {
  color: #f5ca99;
}

.link-warning:focus,
.link-warning:hover {
  color: #f7d5ad;
}

.link-danger {
  color: #ed4c78;
}

.link-danger:focus,
.link-danger:hover {
  color: #be3d60;
}

.link-light {
  color: #f7faff;
}

.link-light:focus,
.link-light:hover {
  color: #f9fbff;
}

.link-dark {
  color: #21325b;
}

.link-dark:focus,
.link-dark:hover {
  color: #1a2849;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.vstack {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125) !important;
}

.shadow-sm {
  box-shadow: 0 0.1875rem 0.375rem rgba(140, 152, 164, 0.25) !important;
}

.shadow-lg {
  box-shadow: 0 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-xl {
  box-shadow: 0 3.75rem 5rem 0 rgba(140, 152, 164, 0.3) !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.border {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 0.0625rem solid rgba(33, 50, 91, 0.1) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 0.0625rem solid rgba(33, 50, 91, 0.1) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #377dff !important;
}

.border-secondary {
  border-color: #71869d !important;
}

.border-success {
  border-color: #00c9a7 !important;
}

.border-info {
  border-color: #09a5be !important;
}

.border-warning {
  border-color: #f5ca99 !important;
}

.border-danger {
  border-color: #ed4c78 !important;
}

.border-light {
  border-color: #f7faff !important;
}

.border-dark {
  border-color: #21325b !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white-10 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-35 {
  width: 35% !important;
}

.w-65 {
  width: 65% !important;
}

.w-85 {
  width: 85% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.vh-30 {
  height: 30vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-70 {
  height: 70vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 2.5rem !important;
}

.gap-7 {
  gap: 3rem !important;
}

.gap-8 {
  gap: 3.5rem !important;
}

.gap-9 {
  gap: 4rem !important;
}

.gap-10 {
  gap: 4.5rem !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.justify-content-evenly {
  -ms-flex-pack: space-evenly !important;
  justify-content: space-evenly !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

.order-first {
  -ms-flex-order: -1 !important;
  order: -1 !important;
}

.order-0 {
  -ms-flex-order: 0 !important;
  order: 0 !important;
}

.order-1 {
  -ms-flex-order: 1 !important;
  order: 1 !important;
}

.order-2 {
  -ms-flex-order: 2 !important;
  order: 2 !important;
}

.order-3 {
  -ms-flex-order: 3 !important;
  order: 3 !important;
}

.order-4 {
  -ms-flex-order: 4 !important;
  order: 4 !important;
}

.order-5 {
  -ms-flex-order: 5 !important;
  order: 5 !important;
}

.order-last {
  -ms-flex-order: 6 !important;
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-8 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-10 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.mt-8 {
  margin-top: 3.5rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.me-8 {
  margin-right: 3.5rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.mb-8 {
  margin-bottom: 3.5rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.ms-8 {
  margin-left: 3.5rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 4.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n6 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n7 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n8 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n9 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n10 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n7 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n8 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n10 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n6 {
  margin-top: -2.5rem !important;
}

.mt-n7 {
  margin-top: -3rem !important;
}

.mt-n8 {
  margin-top: -3.5rem !important;
}

.mt-n9 {
  margin-top: -4rem !important;
}

.mt-n10 {
  margin-top: -4.5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n6 {
  margin-right: -2.5rem !important;
}

.me-n7 {
  margin-right: -3rem !important;
}

.me-n8 {
  margin-right: -3.5rem !important;
}

.me-n9 {
  margin-right: -4rem !important;
}

.me-n10 {
  margin-right: -4.5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n6 {
  margin-bottom: -2.5rem !important;
}

.mb-n7 {
  margin-bottom: -3rem !important;
}

.mb-n8 {
  margin-bottom: -3.5rem !important;
}

.mb-n9 {
  margin-bottom: -4rem !important;
}

.mb-n10 {
  margin-bottom: -4.5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n6 {
  margin-left: -2.5rem !important;
}

.ms-n7 {
  margin-left: -3rem !important;
}

.ms-n8 {
  margin-left: -3.5rem !important;
}

.ms-n9 {
  margin-left: -4rem !important;
}

.ms-n10 {
  margin-left: -4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-8 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-10 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pe-8 {
  padding-right: 3.5rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 3.5rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.ps-8 {
  padding-left: 3.5rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 4.5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-3 {
  font-size: calc(1.25625rem + 0.075vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 0.875rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #8c98a4 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-white-70 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.5rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.3125rem !important;
}

.rounded-2 {
  border-radius: 0.5rem !important;
}

.rounded-3 {
  border-radius: 0.75rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-end {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.content-space-t-0 {
  padding-top: 0 !important;
}

.content-space-t-1 {
  padding-top: 3rem !important;
}

.content-space-t-2 {
  padding-top: 5rem !important;
}

.content-space-t-3 {
  padding-top: 7.5rem !important;
}

.content-space-t-4 {
  padding-top: 10rem !important;
}

.content-space-t-5 {
  padding-top: 12.5rem !important;
}

.content-space-t-auto {
  padding-top: auto !important;
}

.content-space-b-0 {
  padding-bottom: 0 !important;
}

.content-space-b-1 {
  padding-bottom: 3rem !important;
}

.content-space-b-2 {
  padding-bottom: 5rem !important;
}

.content-space-b-3 {
  padding-bottom: 7.5rem !important;
}

.content-space-b-4 {
  padding-bottom: 10rem !important;
}

.content-space-b-5 {
  padding-bottom: 12.5rem !important;
}

.content-space-b-auto {
  padding-bottom: auto !important;
}

.content-space-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.content-space-1 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.content-space-2 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.content-space-3 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.content-space-4 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.content-space-5 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important;
}

.content-space-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.zi-n1 {
  z-index: -1 !important;
}

.zi-0 {
  z-index: 0 !important;
}

.zi-1 {
  z-index: 1 !important;
}

.zi-2 {
  z-index: 2 !important;
}

.zi-3 {
  z-index: 3 !important;
}

.zi-999 {
  z-index: 999 !important;
}

.bg-soft-primary {
  background-color: rgba(55, 125, 255, 0.1) !important;
}

.bg-soft-secondary {
  background-color: rgba(113, 134, 157, 0.1) !important;
}

.bg-soft-success {
  background-color: rgba(0, 201, 167, 0.1) !important;
}

.bg-soft-info {
  background-color: rgba(9, 165, 190, 0.1) !important;
}

.bg-soft-warning {
  background-color: rgba(245, 202, 153, 0.1) !important;
}

.bg-soft-danger {
  background-color: rgba(237, 76, 120, 0.1) !important;
}

.bg-soft-light {
  background-color: rgba(247, 250, 255, 0.1) !important;
}

.bg-soft-dark {
  background-color: rgba(33, 50, 91, 0.1) !important;
}

.min-h-100 {
  min-height: 100% !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .top-sm-0 {
    top: 0 !important;
  }
  .top-sm-50 {
    top: 50% !important;
  }
  .top-sm-100 {
    top: 100% !important;
  }
  .bottom-sm-0 {
    bottom: 0 !important;
  }
  .bottom-sm-50 {
    bottom: 50% !important;
  }
  .bottom-sm-100 {
    bottom: 100% !important;
  }
  .start-sm-0 {
    left: 0 !important;
  }
  .start-sm-50 {
    left: 50% !important;
  }
  .start-sm-100 {
    left: 100% !important;
  }
  .end-sm-0 {
    right: 0 !important;
  }
  .end-sm-50 {
    right: 50% !important;
  }
  .end-sm-100 {
    right: 100% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-35 {
    width: 35% !important;
  }
  .w-sm-65 {
    width: 65% !important;
  }
  .w-sm-85 {
    width: 85% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .vh-sm-100 {
    height: 100vh !important;
  }
  .vh-sm-30 {
    height: 30vh !important;
  }
  .vh-sm-50 {
    height: 50vh !important;
  }
  .vh-sm-70 {
    height: 70vh !important;
  }
  .min-vh-sm-100 {
    min-height: 100vh !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 2rem !important;
  }
  .gap-sm-6 {
    gap: 2.5rem !important;
  }
  .gap-sm-7 {
    gap: 3rem !important;
  }
  .gap-sm-8 {
    gap: 3.5rem !important;
  }
  .gap-sm-9 {
    gap: 4rem !important;
  }
  .gap-sm-10 {
    gap: 4.5rem !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-sm-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-sm-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-sm-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-sm-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-sm-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-sm-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-sm-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-sm-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .m-sm-10 {
    margin: 4.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 3rem !important;
  }
  .mt-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 4rem !important;
  }
  .mt-sm-10 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2rem !important;
  }
  .me-sm-6 {
    margin-right: 2.5rem !important;
  }
  .me-sm-7 {
    margin-right: 3rem !important;
  }
  .me-sm-8 {
    margin-right: 3.5rem !important;
  }
  .me-sm-9 {
    margin-right: 4rem !important;
  }
  .me-sm-10 {
    margin-right: 4.5rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2rem !important;
  }
  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 3rem !important;
  }
  .ms-sm-8 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-9 {
    margin-left: 4rem !important;
  }
  .ms-sm-10 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .m-sm-n6 {
    margin: -2.5rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .m-sm-n8 {
    margin: -3.5rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .m-sm-n10 {
    margin: -4.5rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -2rem !important;
  }
  .mt-sm-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -3rem !important;
  }
  .mt-sm-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -4rem !important;
  }
  .mt-sm-n10 {
    margin-top: -4.5rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -2rem !important;
  }
  .me-sm-n6 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -3rem !important;
  }
  .me-sm-n8 {
    margin-right: -3.5rem !important;
  }
  .me-sm-n9 {
    margin-right: -4rem !important;
  }
  .me-sm-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -2rem !important;
  }
  .ms-sm-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -3rem !important;
  }
  .ms-sm-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-sm-n9 {
    margin-left: -4rem !important;
  }
  .ms-sm-n10 {
    margin-left: -4.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .p-sm-10 {
    padding: 4.5rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 3rem !important;
  }
  .pt-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 4rem !important;
  }
  .pt-sm-10 {
    padding-top: 4.5rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 2rem !important;
  }
  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 3rem !important;
  }
  .pe-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-9 {
    padding-right: 4rem !important;
  }
  .pe-sm-10 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 4.5rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 2rem !important;
  }
  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 3rem !important;
  }
  .ps-sm-8 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-9 {
    padding-left: 4rem !important;
  }
  .ps-sm-10 {
    padding-left: 4.5rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .content-space-t-sm-0 {
    padding-top: 0 !important;
  }
  .content-space-t-sm-1 {
    padding-top: 3rem !important;
  }
  .content-space-t-sm-2 {
    padding-top: 5rem !important;
  }
  .content-space-t-sm-3 {
    padding-top: 7.5rem !important;
  }
  .content-space-t-sm-4 {
    padding-top: 10rem !important;
  }
  .content-space-t-sm-5 {
    padding-top: 12.5rem !important;
  }
  .content-space-t-sm-auto {
    padding-top: auto !important;
  }
  .content-space-b-sm-0 {
    padding-bottom: 0 !important;
  }
  .content-space-b-sm-1 {
    padding-bottom: 3rem !important;
  }
  .content-space-b-sm-2 {
    padding-bottom: 5rem !important;
  }
  .content-space-b-sm-3 {
    padding-bottom: 7.5rem !important;
  }
  .content-space-b-sm-4 {
    padding-bottom: 10rem !important;
  }
  .content-space-b-sm-5 {
    padding-bottom: 12.5rem !important;
  }
  .content-space-b-sm-auto {
    padding-bottom: auto !important;
  }
  .content-space-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .content-space-sm-1 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .content-space-sm-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .content-space-sm-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .content-space-sm-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .content-space-sm-5 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .content-space-sm-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .top-md-0 {
    top: 0 !important;
  }
  .top-md-50 {
    top: 50% !important;
  }
  .top-md-100 {
    top: 100% !important;
  }
  .bottom-md-0 {
    bottom: 0 !important;
  }
  .bottom-md-50 {
    bottom: 50% !important;
  }
  .bottom-md-100 {
    bottom: 100% !important;
  }
  .start-md-0 {
    left: 0 !important;
  }
  .start-md-50 {
    left: 50% !important;
  }
  .start-md-100 {
    left: 100% !important;
  }
  .end-md-0 {
    right: 0 !important;
  }
  .end-md-50 {
    right: 50% !important;
  }
  .end-md-100 {
    right: 100% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-35 {
    width: 35% !important;
  }
  .w-md-65 {
    width: 65% !important;
  }
  .w-md-85 {
    width: 85% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .vh-md-100 {
    height: 100vh !important;
  }
  .vh-md-30 {
    height: 30vh !important;
  }
  .vh-md-50 {
    height: 50vh !important;
  }
  .vh-md-70 {
    height: 70vh !important;
  }
  .min-vh-md-100 {
    min-height: 100vh !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 2rem !important;
  }
  .gap-md-6 {
    gap: 2.5rem !important;
  }
  .gap-md-7 {
    gap: 3rem !important;
  }
  .gap-md-8 {
    gap: 3.5rem !important;
  }
  .gap-md-9 {
    gap: 4rem !important;
  }
  .gap-md-10 {
    gap: 4.5rem !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-md-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-md-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-md-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-md-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-md-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-md-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-md-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-md-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .m-md-10 {
    margin: 4.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2rem !important;
  }
  .mt-md-6 {
    margin-top: 2.5rem !important;
  }
  .mt-md-7 {
    margin-top: 3rem !important;
  }
  .mt-md-8 {
    margin-top: 3.5rem !important;
  }
  .mt-md-9 {
    margin-top: 4rem !important;
  }
  .mt-md-10 {
    margin-top: 4.5rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2rem !important;
  }
  .me-md-6 {
    margin-right: 2.5rem !important;
  }
  .me-md-7 {
    margin-right: 3rem !important;
  }
  .me-md-8 {
    margin-right: 3.5rem !important;
  }
  .me-md-9 {
    margin-right: 4rem !important;
  }
  .me-md-10 {
    margin-right: 4.5rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 3rem !important;
  }
  .mb-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 4rem !important;
  }
  .mb-md-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2rem !important;
  }
  .ms-md-6 {
    margin-left: 2.5rem !important;
  }
  .ms-md-7 {
    margin-left: 3rem !important;
  }
  .ms-md-8 {
    margin-left: 3.5rem !important;
  }
  .ms-md-9 {
    margin-left: 4rem !important;
  }
  .ms-md-10 {
    margin-left: 4.5rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .m-md-n6 {
    margin: -2.5rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .m-md-n8 {
    margin: -3.5rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .m-md-n10 {
    margin: -4.5rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-md-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -2rem !important;
  }
  .mt-md-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -3rem !important;
  }
  .mt-md-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-md-n9 {
    margin-top: -4rem !important;
  }
  .mt-md-n10 {
    margin-top: -4.5rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -2rem !important;
  }
  .me-md-n6 {
    margin-right: -2.5rem !important;
  }
  .me-md-n7 {
    margin-right: -3rem !important;
  }
  .me-md-n8 {
    margin-right: -3.5rem !important;
  }
  .me-md-n9 {
    margin-right: -4rem !important;
  }
  .me-md-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -2rem !important;
  }
  .ms-md-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -3rem !important;
  }
  .ms-md-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-md-n9 {
    margin-left: -4rem !important;
  }
  .ms-md-n10 {
    margin-left: -4.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .p-md-10 {
    padding: 4.5rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 2rem !important;
  }
  .pt-md-6 {
    padding-top: 2.5rem !important;
  }
  .pt-md-7 {
    padding-top: 3rem !important;
  }
  .pt-md-8 {
    padding-top: 3.5rem !important;
  }
  .pt-md-9 {
    padding-top: 4rem !important;
  }
  .pt-md-10 {
    padding-top: 4.5rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 2rem !important;
  }
  .pe-md-6 {
    padding-right: 2.5rem !important;
  }
  .pe-md-7 {
    padding-right: 3rem !important;
  }
  .pe-md-8 {
    padding-right: 3.5rem !important;
  }
  .pe-md-9 {
    padding-right: 4rem !important;
  }
  .pe-md-10 {
    padding-right: 4.5rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 3rem !important;
  }
  .pb-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 4rem !important;
  }
  .pb-md-10 {
    padding-bottom: 4.5rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 2rem !important;
  }
  .ps-md-6 {
    padding-left: 2.5rem !important;
  }
  .ps-md-7 {
    padding-left: 3rem !important;
  }
  .ps-md-8 {
    padding-left: 3.5rem !important;
  }
  .ps-md-9 {
    padding-left: 4rem !important;
  }
  .ps-md-10 {
    padding-left: 4.5rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .content-space-t-md-0 {
    padding-top: 0 !important;
  }
  .content-space-t-md-1 {
    padding-top: 3rem !important;
  }
  .content-space-t-md-2 {
    padding-top: 5rem !important;
  }
  .content-space-t-md-3 {
    padding-top: 7.5rem !important;
  }
  .content-space-t-md-4 {
    padding-top: 10rem !important;
  }
  .content-space-t-md-5 {
    padding-top: 12.5rem !important;
  }
  .content-space-t-md-auto {
    padding-top: auto !important;
  }
  .content-space-b-md-0 {
    padding-bottom: 0 !important;
  }
  .content-space-b-md-1 {
    padding-bottom: 3rem !important;
  }
  .content-space-b-md-2 {
    padding-bottom: 5rem !important;
  }
  .content-space-b-md-3 {
    padding-bottom: 7.5rem !important;
  }
  .content-space-b-md-4 {
    padding-bottom: 10rem !important;
  }
  .content-space-b-md-5 {
    padding-bottom: 12.5rem !important;
  }
  .content-space-b-md-auto {
    padding-bottom: auto !important;
  }
  .content-space-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .content-space-md-1 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .content-space-md-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .content-space-md-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .content-space-md-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .content-space-md-5 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .content-space-md-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .top-lg-0 {
    top: 0 !important;
  }
  .top-lg-50 {
    top: 50% !important;
  }
  .top-lg-100 {
    top: 100% !important;
  }
  .bottom-lg-0 {
    bottom: 0 !important;
  }
  .bottom-lg-50 {
    bottom: 50% !important;
  }
  .bottom-lg-100 {
    bottom: 100% !important;
  }
  .start-lg-0 {
    left: 0 !important;
  }
  .start-lg-50 {
    left: 50% !important;
  }
  .start-lg-100 {
    left: 100% !important;
  }
  .end-lg-0 {
    right: 0 !important;
  }
  .end-lg-50 {
    right: 50% !important;
  }
  .end-lg-100 {
    right: 100% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-35 {
    width: 35% !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .w-lg-85 {
    width: 85% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .vh-lg-100 {
    height: 100vh !important;
  }
  .vh-lg-30 {
    height: 30vh !important;
  }
  .vh-lg-50 {
    height: 50vh !important;
  }
  .vh-lg-70 {
    height: 70vh !important;
  }
  .min-vh-lg-100 {
    min-height: 100vh !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 2rem !important;
  }
  .gap-lg-6 {
    gap: 2.5rem !important;
  }
  .gap-lg-7 {
    gap: 3rem !important;
  }
  .gap-lg-8 {
    gap: 3.5rem !important;
  }
  .gap-lg-9 {
    gap: 4rem !important;
  }
  .gap-lg-10 {
    gap: 4.5rem !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-lg-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-lg-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-lg-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-lg-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-lg-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-lg-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-lg-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-lg-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .m-lg-10 {
    margin: 4.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2rem !important;
  }
  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 3rem !important;
  }
  .mt-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 4rem !important;
  }
  .mt-lg-10 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2rem !important;
  }
  .me-lg-6 {
    margin-right: 2.5rem !important;
  }
  .me-lg-7 {
    margin-right: 3rem !important;
  }
  .me-lg-8 {
    margin-right: 3.5rem !important;
  }
  .me-lg-9 {
    margin-right: 4rem !important;
  }
  .me-lg-10 {
    margin-right: 4.5rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2rem !important;
  }
  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 3rem !important;
  }
  .ms-lg-8 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-9 {
    margin-left: 4rem !important;
  }
  .ms-lg-10 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .m-lg-n6 {
    margin: -2.5rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .m-lg-n8 {
    margin: -3.5rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .m-lg-n10 {
    margin: -4.5rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -2rem !important;
  }
  .mt-lg-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -3rem !important;
  }
  .mt-lg-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -4rem !important;
  }
  .mt-lg-n10 {
    margin-top: -4.5rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -2rem !important;
  }
  .me-lg-n6 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -3rem !important;
  }
  .me-lg-n8 {
    margin-right: -3.5rem !important;
  }
  .me-lg-n9 {
    margin-right: -4rem !important;
  }
  .me-lg-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -2rem !important;
  }
  .ms-lg-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -3rem !important;
  }
  .ms-lg-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-lg-n9 {
    margin-left: -4rem !important;
  }
  .ms-lg-n10 {
    margin-left: -4.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .p-lg-10 {
    padding: 4.5rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 2rem !important;
  }
  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 3rem !important;
  }
  .pt-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 4rem !important;
  }
  .pt-lg-10 {
    padding-top: 4.5rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 2rem !important;
  }
  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 3rem !important;
  }
  .pe-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-9 {
    padding-right: 4rem !important;
  }
  .pe-lg-10 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 4.5rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 2rem !important;
  }
  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 3rem !important;
  }
  .ps-lg-8 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-9 {
    padding-left: 4rem !important;
  }
  .ps-lg-10 {
    padding-left: 4.5rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .content-space-t-lg-0 {
    padding-top: 0 !important;
  }
  .content-space-t-lg-1 {
    padding-top: 3rem !important;
  }
  .content-space-t-lg-2 {
    padding-top: 5rem !important;
  }
  .content-space-t-lg-3 {
    padding-top: 7.5rem !important;
  }
  .content-space-t-lg-4 {
    padding-top: 10rem !important;
  }
  .content-space-t-lg-5 {
    padding-top: 12.5rem !important;
  }
  .content-space-t-lg-auto {
    padding-top: auto !important;
  }
  .content-space-b-lg-0 {
    padding-bottom: 0 !important;
  }
  .content-space-b-lg-1 {
    padding-bottom: 3rem !important;
  }
  .content-space-b-lg-2 {
    padding-bottom: 5rem !important;
  }
  .content-space-b-lg-3 {
    padding-bottom: 7.5rem !important;
  }
  .content-space-b-lg-4 {
    padding-bottom: 10rem !important;
  }
  .content-space-b-lg-5 {
    padding-bottom: 12.5rem !important;
  }
  .content-space-b-lg-auto {
    padding-bottom: auto !important;
  }
  .content-space-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .content-space-lg-1 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .content-space-lg-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .content-space-lg-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .content-space-lg-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .content-space-lg-5 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .content-space-lg-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .top-xl-0 {
    top: 0 !important;
  }
  .top-xl-50 {
    top: 50% !important;
  }
  .top-xl-100 {
    top: 100% !important;
  }
  .bottom-xl-0 {
    bottom: 0 !important;
  }
  .bottom-xl-50 {
    bottom: 50% !important;
  }
  .bottom-xl-100 {
    bottom: 100% !important;
  }
  .start-xl-0 {
    left: 0 !important;
  }
  .start-xl-50 {
    left: 50% !important;
  }
  .start-xl-100 {
    left: 100% !important;
  }
  .end-xl-0 {
    right: 0 !important;
  }
  .end-xl-50 {
    right: 50% !important;
  }
  .end-xl-100 {
    right: 100% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-35 {
    width: 35% !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .w-xl-85 {
    width: 85% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .vh-xl-100 {
    height: 100vh !important;
  }
  .vh-xl-30 {
    height: 30vh !important;
  }
  .vh-xl-50 {
    height: 50vh !important;
  }
  .vh-xl-70 {
    height: 70vh !important;
  }
  .min-vh-xl-100 {
    min-height: 100vh !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 2rem !important;
  }
  .gap-xl-6 {
    gap: 2.5rem !important;
  }
  .gap-xl-7 {
    gap: 3rem !important;
  }
  .gap-xl-8 {
    gap: 3.5rem !important;
  }
  .gap-xl-9 {
    gap: 4rem !important;
  }
  .gap-xl-10 {
    gap: 4.5rem !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-xl-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-xl-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-xl-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-xl-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-xl-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-xl-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-xl-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-xl-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .m-xl-10 {
    margin: 4.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2rem !important;
  }
  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 3rem !important;
  }
  .mt-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 4rem !important;
  }
  .mt-xl-10 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2rem !important;
  }
  .me-xl-6 {
    margin-right: 2.5rem !important;
  }
  .me-xl-7 {
    margin-right: 3rem !important;
  }
  .me-xl-8 {
    margin-right: 3.5rem !important;
  }
  .me-xl-9 {
    margin-right: 4rem !important;
  }
  .me-xl-10 {
    margin-right: 4.5rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2rem !important;
  }
  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 3rem !important;
  }
  .ms-xl-8 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-9 {
    margin-left: 4rem !important;
  }
  .ms-xl-10 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .m-xl-n6 {
    margin: -2.5rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .m-xl-n8 {
    margin: -3.5rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .m-xl-n10 {
    margin: -4.5rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -2rem !important;
  }
  .mt-xl-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -3rem !important;
  }
  .mt-xl-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -4rem !important;
  }
  .mt-xl-n10 {
    margin-top: -4.5rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -2rem !important;
  }
  .me-xl-n6 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -3rem !important;
  }
  .me-xl-n8 {
    margin-right: -3.5rem !important;
  }
  .me-xl-n9 {
    margin-right: -4rem !important;
  }
  .me-xl-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -2rem !important;
  }
  .ms-xl-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -3rem !important;
  }
  .ms-xl-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-xl-n9 {
    margin-left: -4rem !important;
  }
  .ms-xl-n10 {
    margin-left: -4.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .p-xl-10 {
    padding: 4.5rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 2rem !important;
  }
  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 3rem !important;
  }
  .pt-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 4rem !important;
  }
  .pt-xl-10 {
    padding-top: 4.5rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 2rem !important;
  }
  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 3rem !important;
  }
  .pe-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-9 {
    padding-right: 4rem !important;
  }
  .pe-xl-10 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 4.5rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 2rem !important;
  }
  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 3rem !important;
  }
  .ps-xl-8 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-9 {
    padding-left: 4rem !important;
  }
  .ps-xl-10 {
    padding-left: 4.5rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .content-space-t-xl-0 {
    padding-top: 0 !important;
  }
  .content-space-t-xl-1 {
    padding-top: 3rem !important;
  }
  .content-space-t-xl-2 {
    padding-top: 5rem !important;
  }
  .content-space-t-xl-3 {
    padding-top: 7.5rem !important;
  }
  .content-space-t-xl-4 {
    padding-top: 10rem !important;
  }
  .content-space-t-xl-5 {
    padding-top: 12.5rem !important;
  }
  .content-space-t-xl-auto {
    padding-top: auto !important;
  }
  .content-space-b-xl-0 {
    padding-bottom: 0 !important;
  }
  .content-space-b-xl-1 {
    padding-bottom: 3rem !important;
  }
  .content-space-b-xl-2 {
    padding-bottom: 5rem !important;
  }
  .content-space-b-xl-3 {
    padding-bottom: 7.5rem !important;
  }
  .content-space-b-xl-4 {
    padding-bottom: 10rem !important;
  }
  .content-space-b-xl-5 {
    padding-bottom: 12.5rem !important;
  }
  .content-space-b-xl-auto {
    padding-bottom: auto !important;
  }
  .content-space-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .content-space-xl-1 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .content-space-xl-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .content-space-xl-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .content-space-xl-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .content-space-xl-5 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .content-space-xl-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .top-xxl-0 {
    top: 0 !important;
  }
  .top-xxl-50 {
    top: 50% !important;
  }
  .top-xxl-100 {
    top: 100% !important;
  }
  .bottom-xxl-0 {
    bottom: 0 !important;
  }
  .bottom-xxl-50 {
    bottom: 50% !important;
  }
  .bottom-xxl-100 {
    bottom: 100% !important;
  }
  .start-xxl-0 {
    left: 0 !important;
  }
  .start-xxl-50 {
    left: 50% !important;
  }
  .start-xxl-100 {
    left: 100% !important;
  }
  .end-xxl-0 {
    right: 0 !important;
  }
  .end-xxl-50 {
    right: 50% !important;
  }
  .end-xxl-100 {
    right: 100% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .w-xxl-35 {
    width: 35% !important;
  }
  .w-xxl-65 {
    width: 65% !important;
  }
  .w-xxl-85 {
    width: 85% !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .vh-xxl-100 {
    height: 100vh !important;
  }
  .vh-xxl-30 {
    height: 30vh !important;
  }
  .vh-xxl-50 {
    height: 50vh !important;
  }
  .vh-xxl-70 {
    height: 70vh !important;
  }
  .min-vh-xxl-100 {
    min-height: 100vh !important;
  }
  .flex-xxl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xxl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 2rem !important;
  }
  .gap-xxl-6 {
    gap: 2.5rem !important;
  }
  .gap-xxl-7 {
    gap: 3rem !important;
  }
  .gap-xxl-8 {
    gap: 3.5rem !important;
  }
  .gap-xxl-9 {
    gap: 4rem !important;
  }
  .gap-xxl-10 {
    gap: 4.5rem !important;
  }
  .justify-content-xxl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-xxl-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-xxl-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-xxl-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-xxl-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-xxl-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-xxl-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-xxl-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-xxl-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2rem !important;
  }
  .m-xxl-6 {
    margin: 2.5rem !important;
  }
  .m-xxl-7 {
    margin: 3rem !important;
  }
  .m-xxl-8 {
    margin: 3.5rem !important;
  }
  .m-xxl-9 {
    margin: 4rem !important;
  }
  .m-xxl-10 {
    margin: 4.5rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 2rem !important;
  }
  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 3rem !important;
  }
  .mt-xxl-8 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 4rem !important;
  }
  .mt-xxl-10 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 2rem !important;
  }
  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 3rem !important;
  }
  .me-xxl-8 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-9 {
    margin-right: 4rem !important;
  }
  .me-xxl-10 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 2rem !important;
  }
  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 3rem !important;
  }
  .ms-xxl-8 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 4rem !important;
  }
  .ms-xxl-10 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -2rem !important;
  }
  .m-xxl-n6 {
    margin: -2.5rem !important;
  }
  .m-xxl-n7 {
    margin: -3rem !important;
  }
  .m-xxl-n8 {
    margin: -3.5rem !important;
  }
  .m-xxl-n9 {
    margin: -4rem !important;
  }
  .m-xxl-n10 {
    margin: -4.5rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xxl-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -4.5rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -2rem !important;
  }
  .me-xxl-n6 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -3rem !important;
  }
  .me-xxl-n8 {
    margin-right: -3.5rem !important;
  }
  .me-xxl-n9 {
    margin-right: -4rem !important;
  }
  .me-xxl-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -4rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -4.5rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2rem !important;
  }
  .p-xxl-6 {
    padding: 2.5rem !important;
  }
  .p-xxl-7 {
    padding: 3rem !important;
  }
  .p-xxl-8 {
    padding: 3.5rem !important;
  }
  .p-xxl-9 {
    padding: 4rem !important;
  }
  .p-xxl-10 {
    padding: 4.5rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 2rem !important;
  }
  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 3rem !important;
  }
  .pt-xxl-8 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 4rem !important;
  }
  .pt-xxl-10 {
    padding-top: 4.5rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 2rem !important;
  }
  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 3rem !important;
  }
  .pe-xxl-8 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 4rem !important;
  }
  .pe-xxl-10 {
    padding-right: 4.5rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 4.5rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 2rem !important;
  }
  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 3rem !important;
  }
  .ps-xxl-8 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 4rem !important;
  }
  .ps-xxl-10 {
    padding-left: 4.5rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
  .content-space-t-xxl-0 {
    padding-top: 0 !important;
  }
  .content-space-t-xxl-1 {
    padding-top: 3rem !important;
  }
  .content-space-t-xxl-2 {
    padding-top: 5rem !important;
  }
  .content-space-t-xxl-3 {
    padding-top: 7.5rem !important;
  }
  .content-space-t-xxl-4 {
    padding-top: 10rem !important;
  }
  .content-space-t-xxl-5 {
    padding-top: 12.5rem !important;
  }
  .content-space-t-xxl-auto {
    padding-top: auto !important;
  }
  .content-space-b-xxl-0 {
    padding-bottom: 0 !important;
  }
  .content-space-b-xxl-1 {
    padding-bottom: 3rem !important;
  }
  .content-space-b-xxl-2 {
    padding-bottom: 5rem !important;
  }
  .content-space-b-xxl-3 {
    padding-bottom: 7.5rem !important;
  }
  .content-space-b-xxl-4 {
    padding-bottom: 10rem !important;
  }
  .content-space-b-xxl-5 {
    padding-bottom: 12.5rem !important;
  }
  .content-space-b-xxl-auto {
    padding-bottom: auto !important;
  }
  .content-space-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .content-space-xxl-1 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .content-space-xxl-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .content-space-xxl-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .content-space-xxl-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .content-space-xxl-5 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .content-space-xxl-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 1.75rem !important;
  }
  .fs-3 {
    font-size: 1.3125rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* purgecss start ignore */
[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s;
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s;
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s;
}

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s;
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s;
}

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s;
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s;
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s;
}

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s;
}

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s;
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s;
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s;
}

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s;
}

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s;
}

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s;
}

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s;
}

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s;
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s;
}

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s;
}

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s;
}

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s;
}

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s;
}

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s;
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s;
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s;
}

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s;
}

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s;
}

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s;
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s;
}

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s;
}

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s;
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

/* purgecss end ignore */
/*----------------------------------------------------------------------
  * Front - Responsive Website Template version v4.0.0
  * Copyright 2021 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/
/*------------------------------------
  Default Styles
------------------------------------*/
a {
  text-decoration: none;
}

:focus,
a:focus,
button:focus {
  outline-color: rgba(55, 125, 255, 0.5);
}

figure {
  margin-bottom: 0;
}

dl {
  margin-bottom: 0;
}

dt {
  color: #1e2022;
}

dd {
  margin-bottom: 0.75rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Highlight Color */
::-moz-selection {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

::selection {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.bg-primary ::-moz-selection {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-primary ::selection {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

/*------------------------------------
  Animation
------------------------------------*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

/*------------------------------------
  Accordion
------------------------------------*/
.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-button {
  color: #21325b;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1.25rem;
}

@media (max-width: 575.98px) {
  .accordion-button {
    padding: 0.5rem 0.83333rem;
  }
}
.accordion-body {
  padding-top: 0;
}

@media (max-width: 575.98px) {
  .accordion-button,
.accordion-body {
    padding: 0.5rem 0.83333rem;
  }
}
.accordion-flush .accordion-button,
.accordion-flush .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.accordion-lg .accordion-button {
  font-size: 1.125rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.accordion-lg .accordion-body {
  padding-bottom: 1.5rem;
}

.accordion-btn-icon-start .accordion-button::before {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2321325b'%3e%3cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-btn-icon-start .accordion-button::before {
    transition: none;
  }
}
.accordion-btn-icon-start .accordion-button:not(.collapsed)::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23377dff'%3e%3cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.accordion-btn-icon-start .accordion-button::after {
  display: none;
}

/*------------------------------------
  Alert Styles
------------------------------------*/
.alert-primary {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.alert-primary .alert-link {
  color: #fff;
}

.alert-secondary {
  color: #fff;
  background-color: #71869d;
  border-color: #71869d;
}

.alert-secondary .alert-link {
  color: #fff;
}

.alert-success {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.alert-success .alert-link {
  color: #fff;
}

.alert-info {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}

.alert-info .alert-link {
  color: #fff;
}

.alert-warning {
  color: #000;
  background-color: #f5ca99;
  border-color: #f5ca99;
}

.alert-warning .alert-link {
  color: #000;
}

.alert-danger {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78;
}

.alert-danger .alert-link {
  color: #fff;
}

.alert-light {
  color: #000;
  background-color: #f7faff;
  border-color: #f7faff;
}

.alert-light .alert-link {
  color: #000;
}

.alert-dark {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}

.alert-dark .alert-link {
  color: #fff;
}

.alert-soft-primary {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.15);
}

.alert-soft-primary .alert-link {
  color: #377dff;
}

.alert-soft-primary .alert-link:hover {
  color: #1366ff;
}

.alert-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.15);
}

.alert-soft-secondary .alert-link {
  color: #71869d;
}

.alert-soft-secondary .alert-link:hover {
  color: #60748b;
}

.alert-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.15);
}

.alert-soft-success .alert-link {
  color: #00c9a7;
}

.alert-soft-success .alert-link:hover {
  color: #00a589;
}

.alert-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.15);
}

.alert-soft-info .alert-link {
  color: #09a5be;
}

.alert-soft-info .alert-link:hover {
  color: #07879c;
}

.alert-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.15);
}

.alert-soft-warning .alert-link {
  color: #f5ca99;
}

.alert-soft-warning .alert-link:hover {
  color: #f2b978;
}

.alert-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.15);
}

.alert-soft-danger .alert-link {
  color: #ed4c78;
}

.alert-soft-danger .alert-link:hover {
  color: #ea2c60;
}

.alert-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.15);
}

.alert-soft-light .alert-link {
  color: #f7faff;
}

.alert-soft-light .alert-link:hover {
  color: #d3e4ff;
}

.alert-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.15);
}

.alert-soft-dark .alert-link {
  color: #21325b;
}

.alert-soft-dark .alert-link:hover {
  color: #172441;
}

.alert-white {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  background-color: #fff;
}

/*------------------------------------
  Avatar
------------------------------------*/
.avatar {
  position: relative;
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.5rem;
}

.avatar:not(img) {
  background-color: #fff;
}

.avatar-img {
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0.5rem;
}

.avatar-initials {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-weight: 600;
  pointer-events: none;
  text-transform: uppercase;
  border-radius: 0.5rem;
}

.avatar-circle {
  border-radius: 50%;
}

.avatar-circle .avatar,
.avatar-circle .avatar-initials,
.avatar-circle .avatar-img {
  border-radius: 50%;
}

.avatar-centered {
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

/*------------------------------------
  Avatar Group
------------------------------------*/
.avatar-group {
  display: -ms-flexbox;
  display: flex;
}

.avatar-group .avatar:hover {
  z-index: 2;
}

.avatar-group .avatar-circle .avatar-initials {
  border-radius: 50%;
}

.avatar-group .avatar-xs .avatar-img,
.avatar-group .avatar-xs .avatar-initials,
.avatar-group .avatar-sm .avatar-img,
.avatar-group .avatar-sm .avatar-initials,
.avatar-group .avatar .avatar-img,
.avatar-group .avatar .avatar-initials {
  border: 2px solid #fff;
}

.avatar-group .avatar-lg .avatar-img,
.avatar-group .avatar-lg .avatar-initials {
  border: 5px solid #fff;
}

.avatar-group .avatar-xl .avatar-img,
.avatar-group .avatar-xl .avatar-initials {
  border: 7px solid #fff;
}

.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.6125rem;
}

.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.875rem;
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1.5rem;
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -2rem;
}

/*------------------------------------
  Avatar Group Sizes
------------------------------------*/
.avatar-group .avatar {
  width: 3.125rem;
  height: 3.125rem;
}

.avatar-group .avatar .avatar-initials {
  font-size: 1rem;
}

.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}

.avatar-group-xs .avatar {
  width: 1.75rem;
  height: 1.75rem;
}

.avatar-group-xs .avatar .avatar-initials {
  font-size: 0.75rem;
}

.avatar-group-xs .avatar + .avatar {
  margin-left: -0.6125rem;
}

.avatar-group-sm .avatar {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-group-sm .avatar .avatar-initials {
  font-size: 0.8125rem;
}

.avatar-group-sm .avatar + .avatar {
  margin-left: -0.875rem;
}

.avatar-group-lg .avatar {
  width: 4.25rem;
  height: 4.25rem;
}

.avatar-group-lg .avatar .avatar-initials {
  font-size: 1.25rem;
}

.avatar-group-lg .avatar + .avatar {
  margin-left: -1.5rem;
}

.avatar-group-xl .avatar {
  width: 5.625rem;
  height: 5.625rem;
}

.avatar-group-xl .avatar .avatar-initials {
  font-size: 2rem;
}

.avatar-group-xl .avatar + .avatar {
  margin-left: -1.5rem;
}

.avatar-group-xxl .avatar {
  width: 7rem;
  height: 7rem;
}

.avatar-group-xxl .avatar .avatar-initials {
  font-size: 3rem;
}

.avatar-group-xxl .avatar + .avatar {
  margin-left: -2rem;
}

/*------------------------------------
  Avatar Sizes
------------------------------------*/
.avatar.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}

.avatar.avatar-circle .avatar-sm-status {
  bottom: 0;
  right: 0;
}

.avatar.avatar-circle .avatar-lg-status {
  bottom: -0.325rem;
  right: -0.325rem;
}

.avatar-xss {
  width: 1rem;
  height: 1rem;
}

.avatar-xss .avatar-initials {
  font-size: 0.75rem;
}

.avatar-xs {
  width: 1.75rem;
  height: 1.75rem;
}

.avatar-xs .avatar-initials {
  font-size: 0.75rem;
}

.avatar-xs.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}

.avatar-xs.avatar-circle .avatar-sm-status {
  bottom: -0.11719rem;
  right: -0.11719rem;
}

.avatar-xs.avatar-circle .avatar-lg-status {
  bottom: -0.325rem;
  right: -0.325rem;
}

.avatar-xs {
  width: 1.75rem;
  height: 1.75rem;
}

.avatar-xs .avatar-initials {
  font-size: 0.75rem;
}

.avatar-xs.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}

.avatar-xs.avatar-circle .avatar-sm-status {
  bottom: -0.11719rem;
  right: -0.11719rem;
}

.avatar-xs.avatar-circle .avatar-lg-status {
  bottom: -0.325rem;
  right: -0.325rem;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-sm .avatar-initials {
  font-size: 0.8125rem;
}

.avatar-sm.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}

.avatar-sm.avatar-circle .avatar-sm-status {
  bottom: -0.09375rem;
  right: -0.09375rem;
}

.avatar-sm.avatar-circle .avatar-lg-status {
  bottom: -0.40625rem;
  right: -0.40625rem;
}

.avatar-lg {
  width: 4.25rem;
  height: 4.25rem;
}

.avatar-lg .avatar-initials {
  font-size: 1.25rem;
}

.avatar-lg.avatar-circle .avatar-status {
  bottom: -0.13125rem;
  right: -0.13125rem;
}

.avatar-lg.avatar-circle .avatar-sm-status {
  bottom: 0.09375rem;
  right: 0.09375rem;
}

.avatar-lg.avatar-circle .avatar-lg-status {
  bottom: -0.20312rem;
  right: -0.20312rem;
}

.avatar-xl {
  width: 5.625rem;
  height: 5.625rem;
}

.avatar-xl .avatar-initials {
  font-size: 2rem;
}

.avatar-xl.avatar-circle .avatar-status {
  bottom: 0.16406rem;
  right: 0.16406rem;
}

.avatar-xl.avatar-circle .avatar-sm-status {
  bottom: 0.23438rem;
  right: 0.23438rem;
}

.avatar-xl.avatar-circle .avatar-lg-status {
  bottom: 0.13542rem;
  right: 0.13542rem;
}

.avatar-xxl {
  width: 7rem;
  height: 7rem;
}

.avatar-xxl .avatar-initials {
  font-size: 3rem;
}

.avatar-xxl.avatar-circle .avatar-status {
  bottom: 0.75rem;
  right: 0.75rem;
}

.avatar-xxl.avatar-circle .avatar-sm-status {
  bottom: 0.875rem;
  right: 0.875rem;
}

.avatar-xxl.avatar-circle .avatar-lg-status {
  bottom: 0.27083rem;
  right: 0.27083rem;
}

/*------------------------------------
  Avatar Status
------------------------------------*/
.avatar-status {
  position: absolute;
  bottom: -0.525rem;
  right: -0.525rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  border: 0.125rem solid #fff;
  width: 1.3125rem;
  height: 1.3125rem;
  line-height: 1;
  font-size: 0.625rem;
  border-radius: 50%;
}

.avatar-sm-status {
  bottom: -0.2625rem;
  right: -0.2625rem;
  width: 0.9375rem;
  height: 0.9375rem;
  font-size: 0.5rem;
}

.avatar-lg-status {
  width: 1.625rem;
  height: 1.625rem;
  font-size: 0.75rem;
}

/*------------------------------------
  Avatar Styles
------------------------------------*/
.avatar-primary .avatar-initials {
  color: #fff;
  background-color: #377dff;
}

.avatar-status-primary {
  color: #fff;
  background-color: #377dff;
}

.avatar-secondary .avatar-initials {
  color: #fff;
  background-color: #71869d;
}

.avatar-status-secondary {
  color: #fff;
  background-color: #71869d;
}

.avatar-success .avatar-initials {
  color: #fff;
  background-color: #00c9a7;
}

.avatar-status-success {
  color: #fff;
  background-color: #00c9a7;
}

.avatar-info .avatar-initials {
  color: #fff;
  background-color: #09a5be;
}

.avatar-status-info {
  color: #fff;
  background-color: #09a5be;
}

.avatar-warning .avatar-initials {
  color: #000;
  background-color: #f5ca99;
}

.avatar-status-warning {
  color: #000;
  background-color: #f5ca99;
}

.avatar-danger .avatar-initials {
  color: #fff;
  background-color: #ed4c78;
}

.avatar-status-danger {
  color: #fff;
  background-color: #ed4c78;
}

.avatar-light .avatar-initials {
  color: #000;
  background-color: #f7faff;
}

.avatar-status-light {
  color: #000;
  background-color: #f7faff;
}

.avatar-dark .avatar-initials {
  color: #fff;
  background-color: #21325b;
}

.avatar-status-dark {
  color: #fff;
  background-color: #21325b;
}

.avatar-soft-primary .avatar-initials {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.avatar-status-soft-primary {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.avatar-soft-secondary .avatar-initials {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}

.avatar-status-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}

.avatar-soft-success .avatar-initials {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.avatar-status-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.avatar-soft-info .avatar-initials {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

.avatar-status-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

.avatar-soft-warning .avatar-initials {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.avatar-status-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.avatar-soft-danger .avatar-initials {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.avatar-status-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.avatar-soft-light .avatar-initials {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
}

.avatar-status-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
}

.avatar-soft-dark .avatar-initials {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

.avatar-status-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Avatar Ratio
------------------------------------*/
.avatar.avatar-4x3 {
  width: 4.16667rem;
  height: auto;
  border-radius: 0;
}

.avatar.avatar-4x3 .avatar-img {
  height: inherit;
}

.avatar-xss.avatar-4x3 {
  width: 1.33333rem;
  height: 1rem;
  border-radius: 0;
}

.avatar-xss.avatar-4x3 .avatar-img {
  height: inherit;
}

.avatar-xs.avatar-4x3 {
  width: 2.33333rem;
  height: auto;
  border-radius: 0;
}

.avatar-xs.avatar-4x3 .avatar-img {
  height: inherit;
}

.avatar-sm.avatar-4x3 {
  width: 3.33333rem;
  height: auto;
  border-radius: 0;
}

.avatar-sm.avatar-4x3 .avatar-img {
  height: inherit;
}

.avatar-lg.avatar-4x3 {
  width: 5.66667rem;
  height: auto;
  border-radius: 0;
}

.avatar-lg.avatar-4x3 .avatar-img {
  height: inherit;
}

.avatar-xl.avatar-4x3 {
  width: 7.5rem;
  height: auto;
  border-radius: 0;
}

.avatar-xl.avatar-4x3 .avatar-img {
  height: inherit;
}

.avatar-xxl.avatar-4x3 {
  width: 9.33333rem;
  height: auto;
  border-radius: 0;
}

.avatar-xxl.avatar-4x3 .avatar-img {
  height: inherit;
}

/*------------------------------------
  Badge
------------------------------------*/
.badge {
  line-height: normal;
}

/*------------------------------------
  Banner
------------------------------------*/
.banner-half-middle-x {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  z-index: -1;
}

.banner-half-middle-x::before {
  position: absolute;
  top: 50%;
  right: 100%;
  width: 80%;
  height: 80%;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(55, 125, 255, 0.075) 50%, rgba(255, 255, 255, 0));
  content: "";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*------------------------------------
  Blockquote
------------------------------------*/
.blockquote {
  color: #1e2022;
}

.blockquote-left-border {
  color: #677788;
  border-left: 0.1875rem solid rgba(33, 50, 91, 0.1);
  padding-left: 0.75rem;
}

.blockquote-footer {
  font-weight: 600;
  line-height: normal;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.blockquote-footer::before {
  content: "";
}

.blockquote-footer-source {
  display: block;
  color: #677788;
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0.25rem;
}

.blockquote-light {
  color: #fff;
}

.blockquote-light .blockquote-footer-source {
  color: rgba(255, 255, 255, 0.7);
}

.blockquote-sm {
  font-size: 1rem;
}

.blockquote-lg {
  font-size: 1.75rem;
}

/*------------------------------------
  Brand
------------------------------------*/
.brand {
  width: 100%;
  max-width: 7.5rem;
}

/*------------------------------------
  Breadcrumb
------------------------------------*/
.breadcrumb .breadcrumb-item {
  color: #1e2022;
}

.breadcrumb-light .breadcrumb-item {
  color: #fff;
}

.breadcrumb-light .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.5);
}

.breadcrumb-light .breadcrumb-link {
  color: rgba(255, 255, 255, 0.5);
}

.breadcrumb-light .breadcrumb-link:hover {
  color: #fff;
}

/*------------------------------------
  Buttons
------------------------------------*/
.btn-link {
  font-weight: 400;
}

.btn-link:focus {
  box-shadow: none;
}

.btn-check:focus + .btn, .btn:focus {
  box-shadow: none;
}

.btn.dropdown-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}

.btn-transition {
  transition: all 0.2s ease-in-out;
}

.btn-transition:hover, .btn-transition:focus {
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-primary, .btn-primary:hover, .btn-primary:focus {
  box-shadow: 0 4px 11px rgba(55, 125, 255, 0.35);
}

.btn-check:focus + .btn-secondary, .btn-secondary:hover, .btn-secondary:focus {
  box-shadow: 0 4px 11px rgba(113, 134, 157, 0.35);
}

.btn-check:focus + .btn-success, .btn-success:hover, .btn-success:focus {
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.35);
}

.btn-check:focus + .btn-info, .btn-info:hover, .btn-info:focus {
  box-shadow: 0 4px 11px rgba(9, 165, 190, 0.35);
}

.btn-check:focus + .btn-warning, .btn-warning:hover, .btn-warning:focus {
  box-shadow: 0 4px 11px rgba(245, 202, 153, 0.35);
}

.btn-check:focus + .btn-danger, .btn-danger:hover, .btn-danger:focus {
  box-shadow: 0 4px 11px rgba(237, 76, 120, 0.35);
}

.btn-check:focus + .btn-light, .btn-light:hover, .btn-light:focus {
  box-shadow: 0 4px 11px rgba(247, 250, 255, 0.35);
}

.btn-check:focus + .btn-dark, .btn-dark:hover, .btn-dark:focus {
  box-shadow: 0 4px 11px rgba(33, 50, 91, 0.35);
}

.btn-toggle .btn-toggle-default {
  display: inline-block;
}

.btn-toggle .btn-toggle-toggled {
  display: none;
}

.btn-toggle.toggled .btn-toggle-default {
  display: none;
}

.btn-toggle.toggled .btn-toggle-toggled {
  display: inline-block;
}

/*------------------------------------
  Custom Buttons
------------------------------------*/
.btn-white {
  background-color: #fff;
  border-color: rgba(33, 50, 91, 0.1);
}

.btn-white.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-white, .btn-white:hover, .btn-white:focus {
  color: #1366ff;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}

.btn-check:focus + .btn-white.dropdown-toggle::after, .btn-white:hover.dropdown-toggle::after, .btn-white:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-white.disabled, .btn-white:disabled {
  color: #bdc5d1;
  background-color: rgba(231, 234, 243, 0.5);
}

.btn-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

/*------------------------------------
  Ghost Buttons Styles
------------------------------------*/
.btn-ghost-primary {
  color: #377dff;
  background-color: transparent;
}

.btn-ghost-primary:hover,
.btn-check:focus + .btn-ghost-primary, .btn-ghost-primary:focus,
.btn-check:checked + .btn-ghost-primary,
.btn-check:active + .btn-ghost-primary, .btn-ghost-primary:active, .btn-ghost-primary.active,
.show > .btn-ghost-primary.dropdown-toggle {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.btn-ghost-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-primary.btn-no-focus, .btn-ghost-primary.btn-no-focus:hover, .btn-ghost-primary.btn-no-focus.focus, .btn-ghost-primary.btn-no-focus:focus {
  color: #377dff;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-primary.btn-no-focus:hover, .btn-ghost-primary.btn-no-focus:hover:hover, .btn-ghost-primary.btn-no-focus.focus:hover, .btn-ghost-primary.btn-no-focus:focus:hover {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-primary.btn-no-focus.btn-transition, .btn-ghost-primary.btn-no-focus.btn-transition:hover, .btn-ghost-primary.btn-no-focus.btn-transition.focus, .btn-ghost-primary.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.btn-check:focus + .btn-ghost-primary.btn-no-focus.btn-transition:hover, .btn-ghost-primary.btn-no-focus.btn-transition:hover:hover, .btn-ghost-primary.btn-no-focus.btn-transition.focus:hover, .btn-ghost-primary.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-ghost-secondary {
  color: #71869d;
  background-color: transparent;
}

.btn-ghost-secondary:hover,
.btn-check:focus + .btn-ghost-secondary, .btn-ghost-secondary:focus,
.btn-check:checked + .btn-ghost-secondary,
.btn-check:active + .btn-ghost-secondary, .btn-ghost-secondary:active, .btn-ghost-secondary.active,
.show > .btn-ghost-secondary.dropdown-toggle {
  color: #71869d;
  background-color: rgba(55, 125, 255, 0.1);
}

.btn-ghost-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus, .btn-ghost-secondary.btn-no-focus:hover, .btn-ghost-secondary.btn-no-focus.focus, .btn-ghost-secondary.btn-no-focus:focus {
  color: #71869d;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus:hover, .btn-ghost-secondary.btn-no-focus:hover:hover, .btn-ghost-secondary.btn-no-focus.focus:hover, .btn-ghost-secondary.btn-no-focus:focus:hover {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus.btn-transition, .btn-ghost-secondary.btn-no-focus.btn-transition:hover, .btn-ghost-secondary.btn-no-focus.btn-transition.focus, .btn-ghost-secondary.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus.btn-transition:hover, .btn-ghost-secondary.btn-no-focus.btn-transition:hover:hover, .btn-ghost-secondary.btn-no-focus.btn-transition.focus:hover, .btn-ghost-secondary.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-ghost-success {
  color: #00c9a7;
  background-color: transparent;
}

.btn-ghost-success:hover,
.btn-check:focus + .btn-ghost-success, .btn-ghost-success:focus,
.btn-check:checked + .btn-ghost-success,
.btn-check:active + .btn-ghost-success, .btn-ghost-success:active, .btn-ghost-success.active,
.show > .btn-ghost-success.dropdown-toggle {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.btn-ghost-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-success.btn-no-focus, .btn-ghost-success.btn-no-focus:hover, .btn-ghost-success.btn-no-focus.focus, .btn-ghost-success.btn-no-focus:focus {
  color: #00c9a7;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-success.btn-no-focus:hover, .btn-ghost-success.btn-no-focus:hover:hover, .btn-ghost-success.btn-no-focus.focus:hover, .btn-ghost-success.btn-no-focus:focus:hover {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-success.btn-no-focus.btn-transition, .btn-ghost-success.btn-no-focus.btn-transition:hover, .btn-ghost-success.btn-no-focus.btn-transition.focus, .btn-ghost-success.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.btn-check:focus + .btn-ghost-success.btn-no-focus.btn-transition:hover, .btn-ghost-success.btn-no-focus.btn-transition:hover:hover, .btn-ghost-success.btn-no-focus.btn-transition.focus:hover, .btn-ghost-success.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-ghost-info {
  color: #09a5be;
  background-color: transparent;
}

.btn-ghost-info:hover,
.btn-check:focus + .btn-ghost-info, .btn-ghost-info:focus,
.btn-check:checked + .btn-ghost-info,
.btn-check:active + .btn-ghost-info, .btn-ghost-info:active, .btn-ghost-info.active,
.show > .btn-ghost-info.dropdown-toggle {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

.btn-ghost-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-info.btn-no-focus, .btn-ghost-info.btn-no-focus:hover, .btn-ghost-info.btn-no-focus.focus, .btn-ghost-info.btn-no-focus:focus {
  color: #09a5be;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-info.btn-no-focus:hover, .btn-ghost-info.btn-no-focus:hover:hover, .btn-ghost-info.btn-no-focus.focus:hover, .btn-ghost-info.btn-no-focus:focus:hover {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-info.btn-no-focus.btn-transition, .btn-ghost-info.btn-no-focus.btn-transition:hover, .btn-ghost-info.btn-no-focus.btn-transition.focus, .btn-ghost-info.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.btn-check:focus + .btn-ghost-info.btn-no-focus.btn-transition:hover, .btn-ghost-info.btn-no-focus.btn-transition:hover:hover, .btn-ghost-info.btn-no-focus.btn-transition.focus:hover, .btn-ghost-info.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-ghost-warning {
  color: #f5ca99;
  background-color: transparent;
}

.btn-ghost-warning:hover,
.btn-check:focus + .btn-ghost-warning, .btn-ghost-warning:focus,
.btn-check:checked + .btn-ghost-warning,
.btn-check:active + .btn-ghost-warning, .btn-ghost-warning:active, .btn-ghost-warning.active,
.show > .btn-ghost-warning.dropdown-toggle {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.btn-ghost-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-warning.btn-no-focus, .btn-ghost-warning.btn-no-focus:hover, .btn-ghost-warning.btn-no-focus.focus, .btn-ghost-warning.btn-no-focus:focus {
  color: #f5ca99;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-warning.btn-no-focus:hover, .btn-ghost-warning.btn-no-focus:hover:hover, .btn-ghost-warning.btn-no-focus.focus:hover, .btn-ghost-warning.btn-no-focus:focus:hover {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-warning.btn-no-focus.btn-transition, .btn-ghost-warning.btn-no-focus.btn-transition:hover, .btn-ghost-warning.btn-no-focus.btn-transition.focus, .btn-ghost-warning.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.btn-check:focus + .btn-ghost-warning.btn-no-focus.btn-transition:hover, .btn-ghost-warning.btn-no-focus.btn-transition:hover:hover, .btn-ghost-warning.btn-no-focus.btn-transition.focus:hover, .btn-ghost-warning.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-ghost-danger {
  color: #ed4c78;
  background-color: transparent;
}

.btn-ghost-danger:hover,
.btn-check:focus + .btn-ghost-danger, .btn-ghost-danger:focus,
.btn-check:checked + .btn-ghost-danger,
.btn-check:active + .btn-ghost-danger, .btn-ghost-danger:active, .btn-ghost-danger.active,
.show > .btn-ghost-danger.dropdown-toggle {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.btn-ghost-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-danger.btn-no-focus, .btn-ghost-danger.btn-no-focus:hover, .btn-ghost-danger.btn-no-focus.focus, .btn-ghost-danger.btn-no-focus:focus {
  color: #ed4c78;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-danger.btn-no-focus:hover, .btn-ghost-danger.btn-no-focus:hover:hover, .btn-ghost-danger.btn-no-focus.focus:hover, .btn-ghost-danger.btn-no-focus:focus:hover {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-danger.btn-no-focus.btn-transition, .btn-ghost-danger.btn-no-focus.btn-transition:hover, .btn-ghost-danger.btn-no-focus.btn-transition.focus, .btn-ghost-danger.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.btn-check:focus + .btn-ghost-danger.btn-no-focus.btn-transition:hover, .btn-ghost-danger.btn-no-focus.btn-transition:hover:hover, .btn-ghost-danger.btn-no-focus.btn-transition.focus:hover, .btn-ghost-danger.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-ghost-light {
  color: #f7faff;
  background-color: transparent;
}

.btn-ghost-light:hover,
.btn-check:focus + .btn-ghost-light, .btn-ghost-light:focus,
.btn-check:checked + .btn-ghost-light,
.btn-check:active + .btn-ghost-light, .btn-ghost-light:active, .btn-ghost-light.active,
.show > .btn-ghost-light.dropdown-toggle {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
}

.btn-ghost-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-light.btn-no-focus, .btn-ghost-light.btn-no-focus:hover, .btn-ghost-light.btn-no-focus.focus, .btn-ghost-light.btn-no-focus:focus {
  color: #f7faff;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-light.btn-no-focus:hover, .btn-ghost-light.btn-no-focus:hover:hover, .btn-ghost-light.btn-no-focus.focus:hover, .btn-ghost-light.btn-no-focus:focus:hover {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-light.btn-no-focus.btn-transition, .btn-ghost-light.btn-no-focus.btn-transition:hover, .btn-ghost-light.btn-no-focus.btn-transition.focus, .btn-ghost-light.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.btn-check:focus + .btn-ghost-light.btn-no-focus.btn-transition:hover, .btn-ghost-light.btn-no-focus.btn-transition:hover:hover, .btn-ghost-light.btn-no-focus.btn-transition.focus:hover, .btn-ghost-light.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-ghost-dark {
  color: #21325b;
  background-color: transparent;
}

.btn-ghost-dark:hover,
.btn-check:focus + .btn-ghost-dark, .btn-ghost-dark:focus,
.btn-check:checked + .btn-ghost-dark,
.btn-check:active + .btn-ghost-dark, .btn-ghost-dark:active, .btn-ghost-dark.active,
.show > .btn-ghost-dark.dropdown-toggle {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

.btn-ghost-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-dark.btn-no-focus, .btn-ghost-dark.btn-no-focus:hover, .btn-ghost-dark.btn-no-focus.focus, .btn-ghost-dark.btn-no-focus:focus {
  color: #21325b;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-dark.btn-no-focus:hover, .btn-ghost-dark.btn-no-focus:hover:hover, .btn-ghost-dark.btn-no-focus.focus:hover, .btn-ghost-dark.btn-no-focus:focus:hover {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-dark.btn-no-focus.btn-transition, .btn-ghost-dark.btn-no-focus.btn-transition:hover, .btn-ghost-dark.btn-no-focus.btn-transition.focus, .btn-ghost-dark.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.btn-check:focus + .btn-ghost-dark.btn-no-focus.btn-transition:hover, .btn-ghost-dark.btn-no-focus.btn-transition:hover:hover, .btn-ghost-dark.btn-no-focus.btn-transition.focus:hover, .btn-ghost-dark.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-secondary, .btn-ghost-secondary:hover, .btn-ghost-secondary:focus {
  color: #377dff;
}

.btn-check:focus + .btn-ghost-secondary.dropdown-toggle::after, .btn-ghost-secondary:hover.dropdown-toggle::after, .btn-ghost-secondary:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus:hover, .btn-ghost-secondary.btn-no-focus:hover:hover, .btn-ghost-secondary.btn-no-focus.focus:hover, .btn-ghost-secondary.btn-no-focus:focus:hover {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus.btn-transition, .btn-ghost-secondary.btn-no-focus.btn-transition:hover, .btn-ghost-secondary.btn-no-focus.btn-transition.focus, .btn-ghost-secondary.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus.btn-transition:hover, .btn-ghost-secondary.btn-no-focus.btn-transition:hover:hover, .btn-ghost-secondary.btn-no-focus.btn-transition.focus:hover, .btn-ghost-secondary.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

/*------------------------------------
  Button Groups
------------------------------------*/
.btn-group-segment {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f8fafd;
  border-radius: 0.5rem;
  padding: 0.25rem 0.25rem;
}

.btn-group-segment .btn {
  color: #677788;
}

.btn-group-segment .btn:not(:last-child) {
  margin-right: 0.25rem;
}

.btn-group-segment .btn:hover {
  color: #1366ff;
}

.btn-group-segment > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-segment > .btn-group:not(:last-child) > .btn,
.btn-group-segment > .btn:nth-child(n+3),
.btn-group-segment > :not(.btn-check) + .btn,
.btn-group-segment > .btn-group:not(:first-child) > .btn {
  border-radius: 0.5rem;
}

.btn-group-segment > .btn-check:checked + .btn,
.btn-group-segment > .btn-check:focus + .btn {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}

.btn-group-segment.btn-group-pills {
  border-radius: 50rem;
}

.btn-group-segment.btn-group-pills > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-segment.btn-group-pills > .btn-group:not(:last-child) > .btn,
.btn-group-segment.btn-group-pills > .btn:not(:first-child),
.btn-group-segment.btn-group-pills > .btn-group:not(:first-child) > .btn {
  border-radius: 50rem;
}

/*------------------------------------
  Button Soft
------------------------------------*/
.btn-soft-primary {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
  border-color: transparent;
}

.btn-soft-primary:hover,
.btn-check:focus + .btn-soft-primary, .btn-soft-primary:focus {
  color: #fff;
  background-color: #377dff;
}

.btn-check:checked + .btn-soft-primary,
.btn-check:active + .btn-soft-primary, .btn-soft-primary:active, .btn-soft-primary.active,
.show > .btn-soft-primary.dropdown-toggle {
  color: #fff;
  background-color: #377dff;
  border-color: transparent;
}

.btn-soft-primary:disabled, .btn-soft-primary.disabled {
  color: #377dff;
  background-color: #377dff;
  border-color: transparent;
}

.btn-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
  border-color: transparent;
}

.btn-soft-secondary:hover,
.btn-check:focus + .btn-soft-secondary, .btn-soft-secondary:focus {
  color: #fff;
  background-color: #71869d;
}

.btn-check:checked + .btn-soft-secondary,
.btn-check:active + .btn-soft-secondary, .btn-soft-secondary:active, .btn-soft-secondary.active,
.show > .btn-soft-secondary.dropdown-toggle {
  color: #fff;
  background-color: #71869d;
  border-color: transparent;
}

.btn-soft-secondary:disabled, .btn-soft-secondary.disabled {
  color: #71869d;
  background-color: #71869d;
  border-color: transparent;
}

.btn-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  border-color: transparent;
}

.btn-soft-success:hover,
.btn-check:focus + .btn-soft-success, .btn-soft-success:focus {
  color: #fff;
  background-color: #00c9a7;
}

.btn-check:checked + .btn-soft-success,
.btn-check:active + .btn-soft-success, .btn-soft-success:active, .btn-soft-success.active,
.show > .btn-soft-success.dropdown-toggle {
  color: #fff;
  background-color: #00c9a7;
  border-color: transparent;
}

.btn-soft-success:disabled, .btn-soft-success.disabled {
  color: #00c9a7;
  background-color: #00c9a7;
  border-color: transparent;
}

.btn-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
  border-color: transparent;
}

.btn-soft-info:hover,
.btn-check:focus + .btn-soft-info, .btn-soft-info:focus {
  color: #fff;
  background-color: #09a5be;
}

.btn-check:checked + .btn-soft-info,
.btn-check:active + .btn-soft-info, .btn-soft-info:active, .btn-soft-info.active,
.show > .btn-soft-info.dropdown-toggle {
  color: #fff;
  background-color: #09a5be;
  border-color: transparent;
}

.btn-soft-info:disabled, .btn-soft-info.disabled {
  color: #09a5be;
  background-color: #09a5be;
  border-color: transparent;
}

.btn-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
  border-color: transparent;
}

.btn-soft-warning:hover,
.btn-check:focus + .btn-soft-warning, .btn-soft-warning:focus {
  color: #000;
  background-color: #f5ca99;
}

.btn-check:checked + .btn-soft-warning,
.btn-check:active + .btn-soft-warning, .btn-soft-warning:active, .btn-soft-warning.active,
.show > .btn-soft-warning.dropdown-toggle {
  color: #000;
  background-color: #f5ca99;
  border-color: transparent;
}

.btn-soft-warning:disabled, .btn-soft-warning.disabled {
  color: #f5ca99;
  background-color: #f5ca99;
  border-color: transparent;
}

.btn-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
  border-color: transparent;
}

.btn-soft-danger:hover,
.btn-check:focus + .btn-soft-danger, .btn-soft-danger:focus {
  color: #fff;
  background-color: #ed4c78;
}

.btn-check:checked + .btn-soft-danger,
.btn-check:active + .btn-soft-danger, .btn-soft-danger:active, .btn-soft-danger.active,
.show > .btn-soft-danger.dropdown-toggle {
  color: #fff;
  background-color: #ed4c78;
  border-color: transparent;
}

.btn-soft-danger:disabled, .btn-soft-danger.disabled {
  color: #ed4c78;
  background-color: #ed4c78;
  border-color: transparent;
}

.btn-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
  border-color: transparent;
}

.btn-soft-light:hover,
.btn-check:focus + .btn-soft-light, .btn-soft-light:focus {
  color: #000;
  background-color: #f7faff;
}

.btn-check:checked + .btn-soft-light,
.btn-check:active + .btn-soft-light, .btn-soft-light:active, .btn-soft-light.active,
.show > .btn-soft-light.dropdown-toggle {
  color: #000;
  background-color: #f7faff;
  border-color: transparent;
}

.btn-soft-light:disabled, .btn-soft-light.disabled {
  color: #f7faff;
  background-color: #f7faff;
  border-color: transparent;
}

.btn-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
  border-color: transparent;
}

.btn-soft-dark:hover,
.btn-check:focus + .btn-soft-dark, .btn-soft-dark:focus {
  color: #fff;
  background-color: #21325b;
}

.btn-check:checked + .btn-soft-dark,
.btn-check:active + .btn-soft-dark, .btn-soft-dark:active, .btn-soft-dark.active,
.show > .btn-soft-dark.dropdown-toggle {
  color: #fff;
  background-color: #21325b;
  border-color: transparent;
}

.btn-soft-dark:disabled, .btn-soft-dark.disabled {
  color: #21325b;
  background-color: #21325b;
  border-color: transparent;
}

.btn-soft-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-primary.dropdown-toggle::after, .btn-soft-primary:hover.dropdown-toggle::after, .btn-soft-primary:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-secondary.dropdown-toggle::after, .btn-soft-secondary:hover.dropdown-toggle::after, .btn-soft-secondary:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-success.dropdown-toggle::after, .btn-soft-success:hover.dropdown-toggle::after, .btn-soft-success:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-info.dropdown-toggle::after, .btn-soft-info:hover.dropdown-toggle::after, .btn-soft-info:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-warning.dropdown-toggle::after, .btn-soft-warning:hover.dropdown-toggle::after, .btn-soft-warning:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-danger.dropdown-toggle::after, .btn-soft-danger:hover.dropdown-toggle::after, .btn-soft-danger:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-light.dropdown-toggle::after, .btn-soft-light:hover.dropdown-toggle::after, .btn-soft-light:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-dark.dropdown-toggle::after, .btn-soft-dark:hover.dropdown-toggle::after, .btn-soft-dark:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:focus + .btn-soft-secondary, .btn-soft-secondary:hover, .btn-soft-secondary:focus {
  color: #fff;
}

.btn-check:focus + .btn-soft-secondary.dropdown-toggle::after, .btn-soft-secondary:hover.dropdown-toggle::after, .btn-soft-secondary:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

/*------------------------------------
  Button Icon
------------------------------------*/
.btn-icon {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
}

.btn-icon > svg {
  width: 1rem;
  height: auto;
}

/*------------------------------------
  Outline Button Styles
------------------------------------*/
.btn-outline-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-primary:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-primary.dropdown-toggle::after,
.btn-check:active + .btn-outline-primary.dropdown-toggle::after, .btn-outline-primary:active.dropdown-toggle::after, .btn-outline-primary.active.dropdown-toggle::after, .btn-outline-primary.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-secondary:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-secondary.dropdown-toggle::after,
.btn-check:active + .btn-outline-secondary.dropdown-toggle::after, .btn-outline-secondary:active.dropdown-toggle::after, .btn-outline-secondary.active.dropdown-toggle::after, .btn-outline-secondary.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-success:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-success.dropdown-toggle::after,
.btn-check:active + .btn-outline-success.dropdown-toggle::after, .btn-outline-success:active.dropdown-toggle::after, .btn-outline-success.active.dropdown-toggle::after, .btn-outline-success.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-info:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-info.dropdown-toggle::after,
.btn-check:active + .btn-outline-info.dropdown-toggle::after, .btn-outline-info:active.dropdown-toggle::after, .btn-outline-info.active.dropdown-toggle::after, .btn-outline-info.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-warning:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-warning.dropdown-toggle::after,
.btn-check:active + .btn-outline-warning.dropdown-toggle::after, .btn-outline-warning:active.dropdown-toggle::after, .btn-outline-warning.active.dropdown-toggle::after, .btn-outline-warning.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-danger:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-danger.dropdown-toggle::after,
.btn-check:active + .btn-outline-danger.dropdown-toggle::after, .btn-outline-danger:active.dropdown-toggle::after, .btn-outline-danger.active.dropdown-toggle::after, .btn-outline-danger.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-light:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-light.dropdown-toggle::after,
.btn-check:active + .btn-outline-light.dropdown-toggle::after, .btn-outline-light:active.dropdown-toggle::after, .btn-outline-light.active.dropdown-toggle::after, .btn-outline-light.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-dark:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-dark.dropdown-toggle::after,
.btn-check:active + .btn-outline-dark.dropdown-toggle::after, .btn-outline-dark:active.dropdown-toggle::after, .btn-outline-dark.active.dropdown-toggle::after, .btn-outline-dark.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-primary,
.btn-outline-secondary {
  border-color: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Button Sizes
------------------------------------*/
.btn-xs:not(.btn-icon) {
  font-size: 0.75rem;
  padding: 0.3125rem 0.75rem;
}

.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.btn-icon.btn-xs > svg {
  width: 0.75rem;
  height: auto;
}

.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  font-size: 0.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}

.btn-icon.btn-sm > svg, .btn-group-sm > .btn-icon.btn > svg {
  width: 0.8125rem;
  height: auto;
}

.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  font-size: 1.25rem;
  width: 4.25rem;
  height: 4.25rem;
}

.btn-icon.btn-lg > svg, .btn-group-lg > .btn-icon.btn > svg {
  width: 1.25rem;
  height: auto;
}

/*------------------------------------
  Card
------------------------------------*/
.card {
  border-width: 0;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}

.card table tr th {
  font-weight: 400;
}

.card-header {
  border-bottom-width: 0;
}

.card-footer {
  border-top-width: 0;
}

.card-subtitle {
  display: block;
  text-transform: uppercase;
  font-size: 0.8125rem;
  margin-bottom: 0.25rem;
}

.card-title {
  margin-bottom: 0;
}

.card-title:not(:last-child) {
  margin-bottom: 0.5rem;
}

.card-header-title {
  margin-bottom: 0;
}

.card-header-title + .card-text {
  margin-top: 0.25rem;
}

.card-link {
  font-weight: 600;
  white-space: nowrap;
}

.card-bordered {
  border-width: 0.0625rem;
}

.card-transition {
  transition: all 0.2s ease-in-out;
}

.card-transition:hover, .card-transition:focus {
  -webkit-transform: translateY(-0.1875rem) !important;
  transform: translateY(-0.1875rem) !important;
}

.card-ghost {
  background-color: transparent;
  box-shadow: none;
}

.card-dashed {
  border: 0.0625rem dashed rgba(33, 50, 91, 0.1);
}

.card-centered .card-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-group .card {
  box-shadow: none;
}

.card-group .card + .card {
  border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.card .card-header + .table-responsive {
  margin-top: -1px;
}

.card .table {
  margin-bottom: 0;
}

.card .card-table > tbody > tr:first-child > td {
  padding-top: 2rem;
}

.card .card-table > tbody > tr:last-child > td {
  padding-bottom: 2rem;
}

.card .card-table > :not(caption) > * > * {
  padding-right: 2rem;
  padding-left: 2rem;
}

.card .thead-light th:first-child {
  border-top-left-radius: 0.5rem;
}

.card .thead-light th:last-child {
  border-top-right-radius: 0.5rem;
}

.card .card-header + .table-responsive .thead-light th:first-child,
.card .card-header + .table .thead-light th:first-child {
  border-top-left-radius: 0;
}

.card .card-header + .table-responsive .thead-light th:last-child,
.card .card-header + .table .thead-light th:last-child {
  border-top-right-radius: 0;
}

.card .card-header + .table-responsive .table-thead-bordered > :not(:last-child) > :last-child > *,
.card .card-header + .table .table-thead-bordered > :not(:last-child) > :last-child > * {
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.card .table-thead-bordered > :not(:last-child) > :last-child > * {
  border-top-width: 0;
}

.card-sm > .card-header,
.card-sm > .card-footer {
  padding: 1.25rem 1.25rem;
}

.card-sm > .card-body,
.card-sm > .collapse .card-body {
  padding: 1.25rem 1.25rem;
}

.card-sm .card-table > tbody > tr:first-child > td {
  padding-top: 1.25rem;
}

.card-sm .card-table > tbody > tr:last-child > td {
  padding-bottom: 1.25rem;
}

.card-sm .card-table > :not(caption) > * > * {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.card-lg > .card-header,
.card-lg > .card-footer {
  padding-right: 2.75rem;
  padding-left: 2.75rem;
}

.card-lg > .card-body,
.card-lg > .collapse .card-body {
  padding: 2.75rem 2.75rem;
}

.card-lg .card-table > tbody > tr:first-child > td {
  padding-top: 2.75rem;
}

.card-lg .card-table > tbody > tr:last-child > td {
  padding-bottom: 2.75rem;
}

.card-lg .card-table > :not(caption) > * > * {
  padding-right: 2.75rem;
  padding-left: 2.75rem;
}

.card-info-link {
  display: inline-block;
  text-align: center;
  border-width: 0.0625rem;
  box-shadow: none;
  border-radius: 50rem;
}

.card-info-link .card-body {
  padding: 0.8125rem 1.25rem;
}

.card-pinned {
  position: relative;
  display: block;
}

.card-pinned-top-start {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.card-pinned-top-end {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.card-pinned-bottom-start {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.card-pinned-bottom-end {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.card-flush {
  box-shadow: none;
}

.card-flush .card-header,
.card-flush .card-footer,
.card-flush .card-body,
.card-flush .collapse .card-body {
  padding-right: 0;
  padding-left: 0;
}

.card-flush > .card-img-top {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-flush.card-stretched-vertical .card-body .card-footer {
  padding-bottom: 0;
}

.card-stretched-vertical .card-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.card-stretched-vertical .card-body .card-footer {
  padding: 0;
  margin-top: auto;
}

.card-alert {
  border-radius: 0;
  margin-bottom: 0;
}

/*------------------------------------
  Card Group Break
------------------------------------*/
@media (max-width: 575.98px) {
  .card-group-sm-break {
    display: block;
  }
  .card-group-sm-break > .card {
    margin-bottom: 0;
  }
  .card-group-sm-break > .card:not(:last-child) {
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-sm-break > .card + .card {
    border-left: none;
  }
  .card-group-sm-break > .card:not(:first-child):not(:last-child),
.card-group-sm-break > .card:not(:first-child):not(:last-child) .card-header,
.card-group-sm-break > .card:not(:first-child):not(:last-child) .card-img-top,
.card-group-sm-break > .card:not(:first-child):not(:last-child) .card-footer,
.card-group-sm-break > .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-sm-break > .card:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-break > .card:first-child .card-header,
.card-group-sm-break > .card:first-child .card-img-top {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .card-group-sm-break > .card:first-child .card-footer,
.card-group-sm-break > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-break > .card:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-sm-break > .card:last-child .card-header,
.card-group-sm-break > .card:last-child .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-sm-break > .card:last-child .card-footer,
.card-group-sm-break > .card:last-child .card-img-bottom {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .card-group-md-break {
    display: block;
  }
  .card-group-md-break > .card {
    margin-bottom: 0;
  }
  .card-group-md-break > .card:not(:last-child) {
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-md-break > .card + .card {
    border-left: none;
  }
  .card-group-md-break > .card:not(:first-child):not(:last-child),
.card-group-md-break > .card:not(:first-child):not(:last-child) .card-header,
.card-group-md-break > .card:not(:first-child):not(:last-child) .card-img-top,
.card-group-md-break > .card:not(:first-child):not(:last-child) .card-footer,
.card-group-md-break > .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-md-break > .card:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-break > .card:first-child .card-header,
.card-group-md-break > .card:first-child .card-img-top {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .card-group-md-break > .card:first-child .card-footer,
.card-group-md-break > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-break > .card:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-md-break > .card:last-child .card-header,
.card-group-md-break > .card:last-child .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-md-break > .card:last-child .card-footer,
.card-group-md-break > .card:last-child .card-img-bottom {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .card-group-lg-break {
    display: block;
  }
  .card-group-lg-break > .card {
    margin-bottom: 0;
  }
  .card-group-lg-break > .card:not(:last-child) {
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-lg-break > .card + .card {
    border-left: none;
  }
  .card-group-lg-break > .card:not(:first-child):not(:last-child),
.card-group-lg-break > .card:not(:first-child):not(:last-child) .card-header,
.card-group-lg-break > .card:not(:first-child):not(:last-child) .card-img-top,
.card-group-lg-break > .card:not(:first-child):not(:last-child) .card-footer,
.card-group-lg-break > .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-lg-break > .card:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-break > .card:first-child .card-header,
.card-group-lg-break > .card:first-child .card-img-top {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .card-group-lg-break > .card:first-child .card-footer,
.card-group-lg-break > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-break > .card:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-lg-break > .card:last-child .card-header,
.card-group-lg-break > .card:last-child .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-lg-break > .card:last-child .card-footer,
.card-group-lg-break > .card:last-child .card-img-bottom {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
/*------------------------------------
  Card Group Row
------------------------------------*/
@media (min-width: 576px) {
  .card-group-sm-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-sm-row > * {
    padding: 0;
  }
  .card-group-sm-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-sm-row > *:not(:first-child):not(:last-child),
.card-group-sm-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-sm-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-sm-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-sm-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-sm-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-sm-row > *:first-child > .card,
.card-group-sm-row > *:first-child > .card .card-header,
.card-group-sm-row > *:first-child > .card .card-img-top,
.card-group-sm-row > *:first-child > .card .card-footer,
.card-group-sm-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-sm-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-sm-row > *:last-child > .card,
.card-group-sm-row > *:last-child > .card .card-header,
.card-group-sm-row > *:last-child > .card .card-img-top,
.card-group-sm-row > *:last-child > .card .card-footer,
.card-group-sm-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-sm-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-sm-2 > *:first-child > .card,
.card-group-sm-2 > *:first-child > .card .card-header,
.card-group-sm-2 > *:first-child > .card .card-img-top,
.card-group-sm-2 > *:first-child > .card .card-footer,
.card-group-sm-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-2 > *:nth-child(2) > .card,
.card-group-sm-2 > *:nth-child(2) > .card .card-header,
.card-group-sm-2 > *:nth-child(2) > .card .card-img-top,
.card-group-sm-2 > *:nth-child(2) > .card .card-footer,
.card-group-sm-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-sm-2 > *:last-child > .card,
.card-group-sm-2 > *:last-child > .card .card-header,
.card-group-sm-2 > *:last-child > .card .card-img-top,
.card-group-sm-2 > *:last-child > .card .card-footer,
.card-group-sm-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-sm-3 > *:first-child > .card,
.card-group-sm-3 > *:first-child > .card .card-header,
.card-group-sm-3 > *:first-child > .card .card-img-top,
.card-group-sm-3 > *:first-child > .card .card-footer,
.card-group-sm-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-3 > *:nth-child(3) > .card,
.card-group-sm-3 > *:nth-child(3) > .card .card-header,
.card-group-sm-3 > *:nth-child(3) > .card .card-img-top,
.card-group-sm-3 > *:nth-child(3) > .card .card-footer,
.card-group-sm-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-sm-3 > *:last-child > .card,
.card-group-sm-3 > *:last-child > .card .card-header,
.card-group-sm-3 > *:last-child > .card .card-img-top,
.card-group-sm-3 > *:last-child > .card .card-footer,
.card-group-sm-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-sm-4 > *:first-child > .card,
.card-group-sm-4 > *:first-child > .card .card-header,
.card-group-sm-4 > *:first-child > .card .card-img-top,
.card-group-sm-4 > *:first-child > .card .card-footer,
.card-group-sm-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-4 > *:nth-child(4) > .card,
.card-group-sm-4 > *:nth-child(4) > .card .card-header,
.card-group-sm-4 > *:nth-child(4) > .card .card-img-top,
.card-group-sm-4 > *:nth-child(4) > .card .card-footer,
.card-group-sm-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-sm-4 > *:last-child > .card,
.card-group-sm-4 > *:last-child > .card .card-header,
.card-group-sm-4 > *:last-child > .card .card-img-top,
.card-group-sm-4 > *:last-child > .card .card-footer,
.card-group-sm-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
@media (min-width: 768px) {
  .card-group-md-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-md-row > * {
    padding: 0;
  }
  .card-group-md-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-md-row > *:not(:first-child):not(:last-child),
.card-group-md-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-md-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-md-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-md-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-md-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-md-row > *:first-child > .card,
.card-group-md-row > *:first-child > .card .card-header,
.card-group-md-row > *:first-child > .card .card-img-top,
.card-group-md-row > *:first-child > .card .card-footer,
.card-group-md-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-md-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-md-row > *:last-child > .card,
.card-group-md-row > *:last-child > .card .card-header,
.card-group-md-row > *:last-child > .card .card-img-top,
.card-group-md-row > *:last-child > .card .card-footer,
.card-group-md-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-md-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-md-2 > *:first-child > .card,
.card-group-md-2 > *:first-child > .card .card-header,
.card-group-md-2 > *:first-child > .card .card-img-top,
.card-group-md-2 > *:first-child > .card .card-footer,
.card-group-md-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-md-2 > *:nth-child(2) > .card,
.card-group-md-2 > *:nth-child(2) > .card .card-header,
.card-group-md-2 > *:nth-child(2) > .card .card-img-top,
.card-group-md-2 > *:nth-child(2) > .card .card-footer,
.card-group-md-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-md-2 > *:last-child > .card,
.card-group-md-2 > *:last-child > .card .card-header,
.card-group-md-2 > *:last-child > .card .card-img-top,
.card-group-md-2 > *:last-child > .card .card-footer,
.card-group-md-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-md-3 > *:first-child > .card,
.card-group-md-3 > *:first-child > .card .card-header,
.card-group-md-3 > *:first-child > .card .card-img-top,
.card-group-md-3 > *:first-child > .card .card-footer,
.card-group-md-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-md-3 > *:nth-child(3) > .card,
.card-group-md-3 > *:nth-child(3) > .card .card-header,
.card-group-md-3 > *:nth-child(3) > .card .card-img-top,
.card-group-md-3 > *:nth-child(3) > .card .card-footer,
.card-group-md-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-md-3 > *:last-child > .card,
.card-group-md-3 > *:last-child > .card .card-header,
.card-group-md-3 > *:last-child > .card .card-img-top,
.card-group-md-3 > *:last-child > .card .card-footer,
.card-group-md-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-md-4 > *:first-child > .card,
.card-group-md-4 > *:first-child > .card .card-header,
.card-group-md-4 > *:first-child > .card .card-img-top,
.card-group-md-4 > *:first-child > .card .card-footer,
.card-group-md-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-md-4 > *:nth-child(4) > .card,
.card-group-md-4 > *:nth-child(4) > .card .card-header,
.card-group-md-4 > *:nth-child(4) > .card .card-img-top,
.card-group-md-4 > *:nth-child(4) > .card .card-footer,
.card-group-md-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-md-4 > *:last-child > .card,
.card-group-md-4 > *:last-child > .card .card-header,
.card-group-md-4 > *:last-child > .card .card-img-top,
.card-group-md-4 > *:last-child > .card .card-footer,
.card-group-md-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
@media (min-width: 992px) {
  .card-group-lg-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-lg-row > * {
    padding: 0;
  }
  .card-group-lg-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-lg-row > *:not(:first-child):not(:last-child),
.card-group-lg-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-lg-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-lg-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-lg-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-lg-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-lg-row > *:first-child > .card,
.card-group-lg-row > *:first-child > .card .card-header,
.card-group-lg-row > *:first-child > .card .card-img-top,
.card-group-lg-row > *:first-child > .card .card-footer,
.card-group-lg-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-lg-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-lg-row > *:last-child > .card,
.card-group-lg-row > *:last-child > .card .card-header,
.card-group-lg-row > *:last-child > .card .card-img-top,
.card-group-lg-row > *:last-child > .card .card-footer,
.card-group-lg-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-lg-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-lg-2 > *:first-child > .card,
.card-group-lg-2 > *:first-child > .card .card-header,
.card-group-lg-2 > *:first-child > .card .card-img-top,
.card-group-lg-2 > *:first-child > .card .card-footer,
.card-group-lg-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-2 > *:nth-child(2) > .card,
.card-group-lg-2 > *:nth-child(2) > .card .card-header,
.card-group-lg-2 > *:nth-child(2) > .card .card-img-top,
.card-group-lg-2 > *:nth-child(2) > .card .card-footer,
.card-group-lg-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-lg-2 > *:last-child > .card,
.card-group-lg-2 > *:last-child > .card .card-header,
.card-group-lg-2 > *:last-child > .card .card-img-top,
.card-group-lg-2 > *:last-child > .card .card-footer,
.card-group-lg-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-lg-3 > *:first-child > .card,
.card-group-lg-3 > *:first-child > .card .card-header,
.card-group-lg-3 > *:first-child > .card .card-img-top,
.card-group-lg-3 > *:first-child > .card .card-footer,
.card-group-lg-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-3 > *:nth-child(3) > .card,
.card-group-lg-3 > *:nth-child(3) > .card .card-header,
.card-group-lg-3 > *:nth-child(3) > .card .card-img-top,
.card-group-lg-3 > *:nth-child(3) > .card .card-footer,
.card-group-lg-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-lg-3 > *:last-child > .card,
.card-group-lg-3 > *:last-child > .card .card-header,
.card-group-lg-3 > *:last-child > .card .card-img-top,
.card-group-lg-3 > *:last-child > .card .card-footer,
.card-group-lg-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-lg-4 > *:first-child > .card,
.card-group-lg-4 > *:first-child > .card .card-header,
.card-group-lg-4 > *:first-child > .card .card-img-top,
.card-group-lg-4 > *:first-child > .card .card-footer,
.card-group-lg-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-4 > *:nth-child(4) > .card,
.card-group-lg-4 > *:nth-child(4) > .card .card-header,
.card-group-lg-4 > *:nth-child(4) > .card .card-img-top,
.card-group-lg-4 > *:nth-child(4) > .card .card-footer,
.card-group-lg-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-lg-4 > *:last-child > .card,
.card-group-lg-4 > *:last-child > .card .card-header,
.card-group-lg-4 > *:last-child > .card .card-img-top,
.card-group-lg-4 > *:last-child > .card .card-footer,
.card-group-lg-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .card-group-xl-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-xl-row > * {
    padding: 0;
  }
  .card-group-xl-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-xl-row > *:not(:first-child):not(:last-child),
.card-group-xl-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-xl-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-xl-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-xl-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-xl-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xl-row > *:first-child > .card,
.card-group-xl-row > *:first-child > .card .card-header,
.card-group-xl-row > *:first-child > .card .card-img-top,
.card-group-xl-row > *:first-child > .card .card-footer,
.card-group-xl-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-xl-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-xl-row > *:last-child > .card,
.card-group-xl-row > *:last-child > .card .card-header,
.card-group-xl-row > *:last-child > .card .card-img-top,
.card-group-xl-row > *:last-child > .card .card-footer,
.card-group-xl-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-xl-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-xl-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-xl-2 > *:first-child > .card,
.card-group-xl-2 > *:first-child > .card .card-header,
.card-group-xl-2 > *:first-child > .card .card-img-top,
.card-group-xl-2 > *:first-child > .card .card-footer,
.card-group-xl-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xl-2 > *:nth-child(2) > .card,
.card-group-xl-2 > *:nth-child(2) > .card .card-header,
.card-group-xl-2 > *:nth-child(2) > .card .card-img-top,
.card-group-xl-2 > *:nth-child(2) > .card .card-footer,
.card-group-xl-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xl-2 > *:last-child > .card,
.card-group-xl-2 > *:last-child > .card .card-header,
.card-group-xl-2 > *:last-child > .card .card-img-top,
.card-group-xl-2 > *:last-child > .card .card-footer,
.card-group-xl-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xl-3 > *:first-child > .card,
.card-group-xl-3 > *:first-child > .card .card-header,
.card-group-xl-3 > *:first-child > .card .card-img-top,
.card-group-xl-3 > *:first-child > .card .card-footer,
.card-group-xl-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xl-3 > *:nth-child(3) > .card,
.card-group-xl-3 > *:nth-child(3) > .card .card-header,
.card-group-xl-3 > *:nth-child(3) > .card .card-img-top,
.card-group-xl-3 > *:nth-child(3) > .card .card-footer,
.card-group-xl-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xl-3 > *:last-child > .card,
.card-group-xl-3 > *:last-child > .card .card-header,
.card-group-xl-3 > *:last-child > .card .card-img-top,
.card-group-xl-3 > *:last-child > .card .card-footer,
.card-group-xl-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xl-4 > *:first-child > .card,
.card-group-xl-4 > *:first-child > .card .card-header,
.card-group-xl-4 > *:first-child > .card .card-img-top,
.card-group-xl-4 > *:first-child > .card .card-footer,
.card-group-xl-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xl-4 > *:nth-child(4) > .card,
.card-group-xl-4 > *:nth-child(4) > .card .card-header,
.card-group-xl-4 > *:nth-child(4) > .card .card-img-top,
.card-group-xl-4 > *:nth-child(4) > .card .card-footer,
.card-group-xl-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xl-4 > *:last-child > .card,
.card-group-xl-4 > *:last-child > .card .card-header,
.card-group-xl-4 > *:last-child > .card .card-img-top,
.card-group-xl-4 > *:last-child > .card .card-footer,
.card-group-xl-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
@media (min-width: 1400px) {
  .card-group-xxl-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-xxl-row > * {
    padding: 0;
  }
  .card-group-xxl-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-xxl-row > *:not(:first-child):not(:last-child),
.card-group-xxl-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-xxl-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-xxl-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-xxl-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-xxl-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xxl-row > *:first-child > .card,
.card-group-xxl-row > *:first-child > .card .card-header,
.card-group-xxl-row > *:first-child > .card .card-img-top,
.card-group-xxl-row > *:first-child > .card .card-footer,
.card-group-xxl-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-xxl-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-xxl-row > *:last-child > .card,
.card-group-xxl-row > *:last-child > .card .card-header,
.card-group-xxl-row > *:last-child > .card .card-img-top,
.card-group-xxl-row > *:last-child > .card .card-footer,
.card-group-xxl-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-xxl-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-xxl-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-xxl-2 > *:first-child > .card,
.card-group-xxl-2 > *:first-child > .card .card-header,
.card-group-xxl-2 > *:first-child > .card .card-img-top,
.card-group-xxl-2 > *:first-child > .card .card-footer,
.card-group-xxl-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xxl-2 > *:nth-child(2) > .card,
.card-group-xxl-2 > *:nth-child(2) > .card .card-header,
.card-group-xxl-2 > *:nth-child(2) > .card .card-img-top,
.card-group-xxl-2 > *:nth-child(2) > .card .card-footer,
.card-group-xxl-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xxl-2 > *:last-child > .card,
.card-group-xxl-2 > *:last-child > .card .card-header,
.card-group-xxl-2 > *:last-child > .card .card-img-top,
.card-group-xxl-2 > *:last-child > .card .card-footer,
.card-group-xxl-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xxl-3 > *:first-child > .card,
.card-group-xxl-3 > *:first-child > .card .card-header,
.card-group-xxl-3 > *:first-child > .card .card-img-top,
.card-group-xxl-3 > *:first-child > .card .card-footer,
.card-group-xxl-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xxl-3 > *:nth-child(3) > .card,
.card-group-xxl-3 > *:nth-child(3) > .card .card-header,
.card-group-xxl-3 > *:nth-child(3) > .card .card-img-top,
.card-group-xxl-3 > *:nth-child(3) > .card .card-footer,
.card-group-xxl-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xxl-3 > *:last-child > .card,
.card-group-xxl-3 > *:last-child > .card .card-header,
.card-group-xxl-3 > *:last-child > .card .card-img-top,
.card-group-xxl-3 > *:last-child > .card .card-footer,
.card-group-xxl-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xxl-4 > *:first-child > .card,
.card-group-xxl-4 > *:first-child > .card .card-header,
.card-group-xxl-4 > *:first-child > .card .card-img-top,
.card-group-xxl-4 > *:first-child > .card .card-footer,
.card-group-xxl-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xxl-4 > *:nth-child(4) > .card,
.card-group-xxl-4 > *:nth-child(4) > .card .card-header,
.card-group-xxl-4 > *:nth-child(4) > .card .card-img-top,
.card-group-xxl-4 > *:nth-child(4) > .card .card-footer,
.card-group-xxl-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xxl-4 > *:last-child > .card,
.card-group-xxl-4 > *:last-child > .card .card-header,
.card-group-xxl-4 > *:last-child > .card .card-img-top,
.card-group-xxl-4 > *:last-child > .card .card-footer,
.card-group-xxl-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
.card-group-row {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  border-radius: 0.5rem;
  margin: 0;
}

.card-group-row > * {
  padding: 0;
}

.card-group-row > * > .card {
  height: 100%;
  box-shadow: none;
  border-radius: 0;
}

.card-group-row > *:not(:first-child):not(:last-child),
.card-group-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-row > *:not(:first-child):not(:last-child) .card-img-bottom {
  border-radius: 0;
}

.card-group-row > *:first-child > .card {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-group-row > *:first-child > .card,
.card-group-row > *:first-child > .card .card-header,
.card-group-row > *:first-child > .card .card-img-top,
.card-group-row > *:first-child > .card .card-footer,
.card-group-row > *:first-child > .card .card-img-bottom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.card-group-row > *:last-child > .card {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.card-group-row > *:last-child > .card,
.card-group-row > *:last-child > .card .card-header,
.card-group-row > *:last-child > .card .card-img-top,
.card-group-row > *:last-child > .card .card-footer,
.card-group-row > *:last-child > .card .card-img-bottom {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.card-group-row > * + * > .card {
  border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.card-group-row .card-divider {
  border-left-width: 0;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.card-group-2 > *:first-child > .card,
.card-group-2 > *:first-child > .card .card-header,
.card-group-2 > *:first-child > .card .card-img-top,
.card-group-2 > *:first-child > .card .card-footer,
.card-group-2 > *:first-child > .card .card-img-bottom {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0;
}

.card-group-2 > *:nth-child(2) > .card,
.card-group-2 > *:nth-child(2) > .card .card-header,
.card-group-2 > *:nth-child(2) > .card .card-img-top,
.card-group-2 > *:nth-child(2) > .card .card-footer,
.card-group-2 > *:nth-child(2) > .card .card-img-bottom {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0;
}

.card-group-2 > *:last-child > .card,
.card-group-2 > *:last-child > .card .card-header,
.card-group-2 > *:last-child > .card .card-img-top,
.card-group-2 > *:last-child > .card .card-footer,
.card-group-2 > *:last-child > .card .card-img-bottom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-group-3 > *:first-child > .card,
.card-group-3 > *:first-child > .card .card-header,
.card-group-3 > *:first-child > .card .card-img-top,
.card-group-3 > *:first-child > .card .card-footer,
.card-group-3 > *:first-child > .card .card-img-bottom {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0;
}

.card-group-3 > *:nth-child(3) > .card,
.card-group-3 > *:nth-child(3) > .card .card-header,
.card-group-3 > *:nth-child(3) > .card .card-img-top,
.card-group-3 > *:nth-child(3) > .card .card-footer,
.card-group-3 > *:nth-child(3) > .card .card-img-bottom {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0;
}

.card-group-3 > *:last-child > .card,
.card-group-3 > *:last-child > .card .card-header,
.card-group-3 > *:last-child > .card .card-img-top,
.card-group-3 > *:last-child > .card .card-footer,
.card-group-3 > *:last-child > .card .card-img-bottom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-group-4 > *:first-child > .card,
.card-group-4 > *:first-child > .card .card-header,
.card-group-4 > *:first-child > .card .card-img-top,
.card-group-4 > *:first-child > .card .card-footer,
.card-group-4 > *:first-child > .card .card-img-bottom {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0;
}

.card-group-4 > *:nth-child(4) > .card,
.card-group-4 > *:nth-child(4) > .card .card-header,
.card-group-4 > *:nth-child(4) > .card .card-img-top,
.card-group-4 > *:nth-child(4) > .card .card-footer,
.card-group-4 > *:nth-child(4) > .card .card-img-bottom {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0;
}

.card-group-4 > *:last-child > .card,
.card-group-4 > *:last-child > .card .card-header,
.card-group-4 > *:last-child > .card .card-img-top,
.card-group-4 > *:last-child > .card .card-footer,
.card-group-4 > *:last-child > .card .card-img-bottom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

/*------------------------------------
  Circles Chart
-------------------------------------*/
.circles-chart {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 13.75rem;
  height: 13.75rem;
  border-radius: 50%;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  margin-left: auto;
  margin-right: auto;
}

.circles-chart-content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  padding: 2rem 2rem;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

/*------------------------------------
  Close Button
------------------------------------*/
.btn-close:focus {
  box-shadow: none;
}

.btn-close-light {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center;
}

/*------------------------------------
  Col Divider
------------------------------------*/
@media (min-width: 576px) {
  .col-sm-divider > *:not(:first-child) {
    position: relative;
  }
  .col-sm-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
  }
}
.col-divider-rotated > *:not(:first-child)::before {
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

@media (min-width: 768px) {
  .col-md-divider > *:not(:first-child) {
    position: relative;
  }
  .col-md-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
  }
}
.col-divider-rotated > *:not(:first-child)::before {
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

@media (min-width: 992px) {
  .col-lg-divider > *:not(:first-child) {
    position: relative;
  }
  .col-lg-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
  }
}
.col-divider-rotated > *:not(:first-child)::before {
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

@media (min-width: 1200px) {
  .col-xl-divider > *:not(:first-child) {
    position: relative;
  }
  .col-xl-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
  }
}
.col-divider-rotated > *:not(:first-child)::before {
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

@media (min-width: 1400px) {
  .col-xxl-divider > *:not(:first-child) {
    position: relative;
  }
  .col-xxl-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
  }
}
.col-divider-rotated > *:not(:first-child)::before {
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

.col-divider > *:not(:first-child) {
  position: relative;
}

.col-divider > *:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.0625rem;
  height: 100%;
  background-color: rgba(33, 50, 91, 0.1);
  content: "";
}

.col-divider-rotated > *:not(:first-child)::before {
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

.col-divider > *:not(:first-child) {
  position: relative;
}

.col-divider > *:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: 50%;
  width: calc(100% - 1.5rem);
  height: 0.0625rem;
  background-color: rgba(33, 50, 91, 0.1);
  content: "";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.col-divider > * {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.col-divider > *:first-child {
  padding-top: 0;
}

.col-divider > *:last-child {
  padding-bottom: 0;
}

@media (max-width: 575.98px) {
  .col-sm-divider > *:not(:first-child) {
    position: relative;
  }
  .col-sm-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .col-sm-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-sm-divider > *:first-child {
    padding-top: 0;
  }
  .col-sm-divider > *:last-child {
    padding-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .col-md-divider > *:not(:first-child) {
    position: relative;
  }
  .col-md-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .col-md-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-md-divider > *:first-child {
    padding-top: 0;
  }
  .col-md-divider > *:last-child {
    padding-bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .col-lg-divider > *:not(:first-child) {
    position: relative;
  }
  .col-lg-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .col-lg-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-lg-divider > *:first-child {
    padding-top: 0;
  }
  .col-lg-divider > *:last-child {
    padding-bottom: 0;
  }
}
@media (max-width: 1199.98px) {
  .col-xl-divider > *:not(:first-child) {
    position: relative;
  }
  .col-xl-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .col-xl-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-xl-divider > *:first-child {
    padding-top: 0;
  }
  .col-xl-divider > *:last-child {
    padding-bottom: 0;
  }
}
@media (max-width: 1399.98px) {
  .col-xxl-divider > *:not(:first-child) {
    position: relative;
  }
  .col-xxl-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .col-xxl-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-xxl-divider > *:first-child {
    padding-top: 0;
  }
  .col-xxl-divider > *:last-child {
    padding-bottom: 0;
  }
}
.col-divider > *:not(:first-child) {
  position: relative;
}

.col-divider > *:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.0625rem;
  height: 100%;
  background-color: rgba(33, 50, 91, 0.1);
  content: "";
}

.col-divider > *,
.col-divider > *:first-child,
.col-divider > *:last-child {
  padding-top: 0;
  padding-bottom: 0;
}

.col-divider-light > *:not(:first-child)::before {
  background-color: rgba(255, 255, 255, 0.1);
}

/*------------------------------------
  Devices
------------------------------------*/
.devices {
  max-width: 1140px;
  position: relative;
  overflow: hidden;
  padding: 0 0.75rem 7rem;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.devices .device-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  margin-left: 5rem;
  margin-bottom: 5rem;
}

@media (max-width: 991.98px) {
  .devices .device-mobile {
    display: none;
  }
}
.devices .device-browser {
  margin-left: auto;
  margin-right: 5rem;
}

@media (max-width: 991.98px) {
  .devices .device-mobile {
    margin-right: 0.5rem;
  }
  .devices .device-browser {
    margin-right: auto;
  }
}
.devices-shadow-none .device-mobile-frame,
.devices-shadow-none .device-browser-frame {
  box-shadow: none;
}

.devices-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: end;
  align-items: flex-end;
  grid-gap: 1rem;
}

@media (max-width: 991.98px) {
  .devices-wrap {
    display: none;
  }
}
.devices-rotated {
  transform: rotate(-12deg);
}

.devices-rotated .devices-rotated-body {
  transform: translate3d(15%, 0, 0);
}

.devices-top-start-50 {
  position: absolute;
  top: 0;
  left: 50%;
}

/*------------------------------------
  Mobile Device
------------------------------------*/
.device-mobile {
  position: relative;
  z-index: 1;
  display: block;
  max-width: 100%;
  width: 15rem;
  height: auto;
}

.device-mobile-frame {
  background: #f8fafd;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3), inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  border-radius: 2rem;
  padding: 0.3125rem;
}

.device-mobile-img {
  max-width: 100%;
  height: auto;
  border-radius: 1.6rem;
}

/*------------------------------------
  Tablet Devices
------------------------------------*/
.device-tablet {
  position: relative;
  z-index: 1;
  display: block;
  max-width: 100%;
  width: 50rem;
  height: auto;
}

.device-tablet-frame {
  background: #f8fafd;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3), inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  border-radius: 1rem;
  padding: 0.3125rem;
}

.device-tablet-img {
  max-width: 100%;
  height: auto;
  border-radius: 1rem;
}

.device-vertical-tablet {
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 30rem;
  height: auto;
}

.device-vertical-tablet-frame {
  background: #f8fafd;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3), inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  border-radius: 1rem;
  padding: 0.3125rem;
}

.device-vertical-tablet-img {
  max-width: 100%;
  height: auto;
  border-radius: 1rem;
}

/*------------------------------------
  Browser Device
------------------------------------*/
.device-browser {
  position: relative;
  z-index: 1;
  display: block;
  max-width: 100%;
  width: 50rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.device-browser-frame {
  background: #fff;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.device-browser-img {
  max-width: 100%;
  height: auto;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.device-browser-header {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  max-width: 50rem;
  background: #fff;
  border-bottom: 0.0625rem solid #f1f3f8;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.5rem 6.5rem;
}

.device-browser-header-btn-list {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 0.25rem;
  position: absolute;
  top: calc(50% - 0.25rem);
  left: 1rem;
}

.device-browser-header-btn-list-btn {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #e7eaf3;
  border-radius: 50%;
}

.device-browser-header-browser-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #677788;
  background-color: #e7eaf3;
  font-size: 0.5rem;
  border-radius: 0.125rem;
}

.device-browser-lg {
  width: 60rem;
}

.device-browser-lg .device-browser-header {
  max-width: 60rem;
}

/*------------------------------------
  Dropdown
------------------------------------*/
.dropdown-menu {
  box-shadow: 0 0.75rem 1rem rgba(189, 197, 209, 0.3);
  margin-top: 0.3125rem;
}

.dropdown-menu .dropdown-item.dropdown-toggle::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.dropdown-item {
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}

.dropdown-item:not(:last-child) {
  margin-bottom: 0.25rem;
}

.dropdown-item:active {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.2);
}

.dropdown-item.active:not(:focus):not(:active), .dropdown-item.active:active:focus {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.2);
}

.dropdown-header {
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
}

.dropdown-item-icon {
  display: inline-block;
  opacity: 0.7;
  width: 1.5rem;
  color: #677788;
}

.dropdown-toggle {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1rem 1rem;
  margin-left: auto;
  padding-left: 1.25rem;
  content: "";
  border: none;
}

.dropdown-toggle[aria-expanded=true]::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.dropdown-toggle .dropdown-item-icon {
  width: 1.75rem;
}

.dropup .dropdown-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.dropright .dropdown-toggle::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin-top: 0.25rem;
}

.dropleft .dropdown-toggle::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-top: -0.25rem;
}

.dropdown-card {
  padding: 0;
}

.dropdown-card .card {
  box-shadow: none;
}

.dropdown-card-shopping-cart .card > .card-footer {
  border-top-width: 0.0625rem;
}

.dropdown-course-search {
  position: static !important;
}

.dropdown-course-search .dropdown-menu {
  width: 100%;
}

/*------------------------------------
  Form Check
------------------------------------*/
.form-check-label {
  font-size: 0.875rem;
  margin-top: 0.125rem;
}

.form-check .form-check-label {
  margin-top: 0;
}

label.form-control,
.form-check-input,
.form-check-label {
  cursor: pointer;
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border: 1px solid rgba(33, 50, 91, 0.1);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #677788;
}

/*------------------------------------
  Form Check Card
------------------------------------*/
.form-check-card {
  position: relative;
  padding: 1rem 1rem;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.5rem;
}

.form-check-card .form-check-input {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  margin: 0;
}

/*------------------------------------
  Form Check Bookmark
------------------------------------*/
.form-check-bookmark .form-check-input {
  width: 0;
  height: 0;
  margin: 0;
  border: none;
}

.form-check-bookmark .form-check-bookmark-active {
  color: #f5ca99;
}

.form-check-bookmark .form-check-input ~ .form-check-label .form-check-bookmark-default {
  display: block;
}

.form-check-bookmark .form-check-input ~ .form-check-label .form-check-bookmark-active {
  display: none;
}

.form-check-bookmark .form-check-input:checked ~ .form-check-label .form-check-bookmark-default {
  display: none;
}

.form-check-bookmark .form-check-input:checked ~ .form-check-label .form-check-bookmark-active {
  display: block;
}

/*------------------------------------
  Form Select
------------------------------------*/
.form-select {
  cursor: pointer;
}

/*------------------------------------
  Form Switch
------------------------------------*/
.form-switch {
  position: relative;
}

.form-switch .form-check-input {
  width: 2.8125em;
  height: 1.8125em;
  border-width: 0;
  background-color: #e7eaf3;
  margin-top: -0.25em;
  margin-right: 0.75rem;
}

.form-switch .form-check-input:active {
  -webkit-filter: 100%;
  filter: 100%;
}

.form-check-input:checked {
  background-color: #377dff;
}

.form-switch-between {
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
}

.form-switch-between .form-check-input {
  float: none;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 0.5rem;
}

.form-switch-between .form-check-label {
  cursor: inherit;
}

.form-switch-promotion {
  position: relative;
  min-width: 5rem;
}

.form-switch-promotion-container {
  position: absolute;
  top: -1.75rem;
  left: 1.25rem;
}

.form-switch-promotion-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -1rem;
}

.form-switch-promotion-arrow {
  margin-right: -1.75rem;
}

.form-switch-promotion-text {
  display: block;
  margin-top: 0.3125rem;
}

/*------------------------------------
  Form Attachment
------------------------------------*/
.form-attachment-btn {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.form-attachment-btn-label {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  cursor: inherit;
  opacity: 0;
}

/*------------------------------------
  Form Text
------------------------------------*/
.form-text {
  margin-bottom: 0;
}

/*------------------------------------
  Form Link
------------------------------------*/
.form-link {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 1rem;
}

/*------------------------------------
  Input Group Merge
------------------------------------*/
.input-group-merge {
  position: relative;
  overflow: hidden;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
}

.input-group-merge .input-group-prepend,
.input-group-merge .input-group-append {
  position: absolute;
  top: 1px;
  bottom: 1px;
  z-index: 4;
  background-color: transparent;
}

.input-group-merge .input-group-prepend.input-group-text,
.input-group-merge .input-group-append.input-group-text {
  border-width: 0;
}

.input-group-merge .input-group-prepend {
  left: 1px;
}

.input-group-merge .input-group-append {
  right: 1px;
}

.input-group-merge .form-select,
.input-group-merge .form-control {
  border-width: 0;
}

.input-group-merge .form-select:not(:first-child),
.input-group-merge .form-control:not(:first-child) {
  padding-left: 3.375rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.input-group-merge .form-select:not(:last-child),
.input-group-merge .form-control:not(:last-child) {
  padding-right: 3.375rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.input-group-merge .form-select-sm,
.input-group-merge .form-control-sm {
  min-height: calc(1.5em + 0.875rem);
}

.input-group-merge .form-select-sm:not(:first-child),
.input-group-merge .form-control-sm:not(:first-child) {
  padding-left: 3.375rem;
}

.input-group-merge .form-select-sm:not(:last-child),
.input-group-merge .form-control-sm:not(:last-child) {
  padding-right: 3.375rem;
}

.input-group-merge .form-select-lg,
.input-group-merge .form-control-lg {
  min-height: calc(1.5em + 1.375rem);
}

.was-validated .input-group-merge.is-valid {
  border-color: #00c9a7;
}

.was-validated .input-group-merge.is-valid.focus {
  box-shadow: 0 0 1rem 0 rgba(0, 201, 167, 0.25);
}

.was-validated .input-group-merge.is-invalid {
  border-color: #ed4c78;
}

.was-validated .input-group-merge.is-invalid.focus {
  box-shadow: 0 0 1rem 0 rgba(237, 76, 120, 0.25);
}

/*------------------------------------
  Input Group
------------------------------------*/
.input-group-append,
.input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/*------------------------------------
  Input Group Break
------------------------------------*/
.input-group-down-break {
  display: block;
}

.input-group-down-break > .form-control,
.input-group-down-break > .form-select {
  width: 100%;
}

.input-group-down-break > .form-control:first-child,
.input-group-down-break > .form-select:first-child {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-down-break > .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-down-break > .form-select:last-child,
.input-group-down-break > .form-control:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.input-group-down-break .form-control + .form-control,
.input-group-down-break .form-control + .form-select,
.input-group-down-break .form-control-plaintext + .form-control,
.input-group-down-break .form-control-plaintext + .form-select,
.input-group-down-break .form-select + .form-control,
.input-group-down-break .form-select + .form-select {
  margin-left: 0 !important;
  margin-top: -0.0625rem;
}

@media (max-width: 575.98px) {
  .input-group-sm-down-break {
    display: block;
  }
  .input-group-sm-down-break > .form-control,
.input-group-sm-down-break > .form-select {
    width: 100%;
  }
  .input-group-sm-down-break > .form-control:first-child,
.input-group-sm-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-sm-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-sm-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-sm-down-break > .form-select:last-child,
.input-group-sm-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-sm-down-break .form-control + .form-control,
.input-group-sm-down-break .form-control + .form-select,
.input-group-sm-down-break .form-control-plaintext + .form-control,
.input-group-sm-down-break .form-control-plaintext + .form-select,
.input-group-sm-down-break .form-select + .form-control,
.input-group-sm-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}
@media (max-width: 767.98px) {
  .input-group-md-down-break {
    display: block;
  }
  .input-group-md-down-break > .form-control,
.input-group-md-down-break > .form-select {
    width: 100%;
  }
  .input-group-md-down-break > .form-control:first-child,
.input-group-md-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-md-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-md-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-md-down-break > .form-select:last-child,
.input-group-md-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-md-down-break .form-control + .form-control,
.input-group-md-down-break .form-control + .form-select,
.input-group-md-down-break .form-control-plaintext + .form-control,
.input-group-md-down-break .form-control-plaintext + .form-select,
.input-group-md-down-break .form-select + .form-control,
.input-group-md-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}
@media (max-width: 991.98px) {
  .input-group-lg-down-break {
    display: block;
  }
  .input-group-lg-down-break > .form-control,
.input-group-lg-down-break > .form-select {
    width: 100%;
  }
  .input-group-lg-down-break > .form-control:first-child,
.input-group-lg-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-lg-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-lg-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-lg-down-break > .form-select:last-child,
.input-group-lg-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-lg-down-break .form-control + .form-control,
.input-group-lg-down-break .form-control + .form-select,
.input-group-lg-down-break .form-control-plaintext + .form-control,
.input-group-lg-down-break .form-control-plaintext + .form-select,
.input-group-lg-down-break .form-select + .form-control,
.input-group-lg-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .input-group-xl-down-break {
    display: block;
  }
  .input-group-xl-down-break > .form-control,
.input-group-xl-down-break > .form-select {
    width: 100%;
  }
  .input-group-xl-down-break > .form-control:first-child,
.input-group-xl-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-xl-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-xl-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-xl-down-break > .form-select:last-child,
.input-group-xl-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-xl-down-break .form-control + .form-control,
.input-group-xl-down-break .form-control + .form-select,
.input-group-xl-down-break .form-control-plaintext + .form-control,
.input-group-xl-down-break .form-control-plaintext + .form-select,
.input-group-xl-down-break .form-select + .form-control,
.input-group-xl-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}
@media (max-width: 1399.98px) {
  .input-group-xxl-down-break {
    display: block;
  }
  .input-group-xxl-down-break > .form-control,
.input-group-xxl-down-break > .form-select {
    width: 100%;
  }
  .input-group-xxl-down-break > .form-control:first-child,
.input-group-xxl-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-xxl-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-xxl-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-xxl-down-break > .form-select:last-child,
.input-group-xxl-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-xxl-down-break .form-control + .form-control,
.input-group-xxl-down-break .form-control + .form-select,
.input-group-xxl-down-break .form-control-plaintext + .form-control,
.input-group-xxl-down-break .form-control-plaintext + .form-select,
.input-group-xxl-down-break .form-select + .form-control,
.input-group-xxl-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}
/*------------------------------------
  Input Group
------------------------------------*/
.input-group-add-field {
  position: relative;
  margin-top: 1rem;
}

.input-group-add-field-delete {
  position: absolute;
  top: 0.75rem;
  right: -1.25rem;
  color: #ed4c78;
}

.input-group-add-field-delete:hover {
  color: #ea2c60;
}

/*------------------------------------
  Input Card
------------------------------------*/
.input-card {
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  padding: 0.6125rem 0.6125rem;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  border-radius: 0.5rem;
}

.input-card .input-card-form {
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
}

.input-card .btn {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.input-card .form-control {
  border-width: 0;
}

.input-card .form-control:focus {
  box-shadow: none;
}

.input-card .input-group {
  border-width: 0;
}

.input-card .input-card-form {
  position: relative;
}

.input-card .input-card-form:not(:first-child) {
  padding-left: 1rem;
}

.input-card .input-card-form:not(:first-child)::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0.0625rem;
  height: 2rem;
  background-color: rgba(33, 50, 91, 0.1);
  content: "";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-card .input-card-form:not(:last-child) {
  padding-right: 1rem;
}

@media (min-width: 576px) {
  .input-card-pill {
    border-radius: 50rem;
  }
}
@media (max-width: 575.98px) {
  .input-card-sm {
    display: grid;
  }
  .input-card-sm .btn,
.input-card-sm .input-card-form {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .input-card-sm .input-card-form {
    padding: 1rem 0 !important;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .input-card-sm .input-card-form:first-child {
    padding-top: 0 !important;
  }
  .input-card-sm .input-card-form:not(:first-child)::before {
    display: none;
  }
}
/*------------------------------------
  Labels
------------------------------------*/
.col-form-label {
  font-size: 0.875rem;
}

.form-label-secondary {
  font-size: 0.875rem;
  color: #8c98a4;
}

.form-label-link {
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

/*------------------------------------
  Go To
------------------------------------*/
.go-to {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  width: 3.125rem;
  height: 3.125rem;
  background-color: rgba(113, 134, 157, 0.1);
  color: #677788;
  font-size: 1rem;
  opacity: 0.5;
  border-radius: 50%;
  transition: 0.3s ease-out;
}

.go-to:hover, .go-to:focus:hover {
  color: #fff;
  background-color: #377dff;
  opacity: 1;
}

/*------------------------------------
  Icon
------------------------------------*/
.icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.3125rem;
}

.icon-circle {
  border-radius: 50%;
}

.icon.icon-xs {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.icon.icon-sm {
  font-size: 0.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}

.icon.icon-lg {
  font-size: 1.25rem;
  width: 4.25rem;
  height: 4.25rem;
}

/*------------------------------------
  Icon
------------------------------------*/
.icon-primary {
  color: #fff;
  border-color: #377dff;
  background-color: #377dff;
}

.icon-secondary {
  color: #fff;
  border-color: #71869d;
  background-color: #71869d;
}

.icon-success {
  color: #fff;
  border-color: #00c9a7;
  background-color: #00c9a7;
}

.icon-info {
  color: #fff;
  border-color: #09a5be;
  background-color: #09a5be;
}

.icon-warning {
  color: #000;
  border-color: #f5ca99;
  background-color: #f5ca99;
}

.icon-danger {
  color: #fff;
  border-color: #ed4c78;
  background-color: #ed4c78;
}

.icon-light {
  color: #000;
  border-color: #f7faff;
  background-color: #f7faff;
}

.icon-dark {
  color: #fff;
  border-color: #21325b;
  background-color: #21325b;
}

.icon-soft-primary {
  color: #377dff;
  background: rgba(55, 125, 255, 0.1);
}

.icon-soft-secondary {
  color: #71869d;
  background: rgba(113, 134, 157, 0.1);
}

.icon-soft-success {
  color: #00c9a7;
  background: rgba(0, 201, 167, 0.1);
}

.icon-soft-info {
  color: #09a5be;
  background: rgba(9, 165, 190, 0.1);
}

.icon-soft-warning {
  color: #f5ca99;
  background: rgba(245, 202, 153, 0.1);
}

.icon-soft-danger {
  color: #ed4c78;
  background: rgba(237, 76, 120, 0.1);
}

.icon-soft-light {
  color: #f7faff;
  background: rgba(247, 250, 255, 0.1);
}

.icon-soft-dark {
  color: #21325b;
  background: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Legend Indicator
------------------------------------*/
.legend-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #bdc5d1;
  border-radius: 50%;
  margin-right: 0.4375rem;
}

/*------------------------------------
  List Comment
------------------------------------*/
.list-comment {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.list-comment:first-child {
  margin-top: -2.5rem;
}

.list-comment-item {
  margin-top: 2.5rem;
}

.list-comment-item .list-comment .list-comment-item {
  padding-left: 1rem;
  border-left: 0.1875rem solid rgba(33, 50, 91, 0.1);
}

.list-comment-divider .list-comment-item:not(:last-child) {
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding-bottom: 2.5rem;
}

/*------------------------------------
  List Padding
------------------------------------*/
.list-py-1 > li {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.list-py-2 > li {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.list-py-3 > li {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/*------------------------------------
  List Separator
------------------------------------*/
.list-separator {
  margin-bottom: 0;
}

.list-separator .list-inline-item {
  position: relative;
  margin-left: 0;
  margin-right: -0.25rem;
}

.list-separator .list-inline-item:not(:last-child) {
  padding-right: 2rem;
}

.list-separator .list-inline-item:not(:last-child)::after {
  position: absolute;
  top: 50%;
  right: 0.8rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "/";
  opacity: 0.4;
}

.list-separator .list-separator-link {
  color: #677788;
}

.list-separator .list-separator-link:hover {
  color: #1366ff;
}

.list-separator-light .list-inline-item::after {
  color: #fff;
}

.list-separator-light .list-separator-link, .list-separator-light .list-separator-link:hover {
  color: #fff;
}

.list-separator-light .list-separator-link:hover {
  text-decoration: underline;
}

/*------------------------------------
  List Group
------------------------------------*/
.list-group-item:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.list-group-item.active .list-group-icon {
  color: #fff;
}

.list-group-icon {
  display: inline-block;
  color: #677788;
  width: 2rem;
  text-align: center;
  margin-right: 0.5rem;
}

.list-group-striped > li:nth-of-type(odd) {
  background-color: rgba(55, 125, 255, 0.1);
}

.list-group-sm .list-group-item {
  font-size: 0.875rem;
  padding: 0.5rem 0.5rem;
}

.list-group-lg .list-group-item {
  padding: 1.5rem 1.5rem;
}

.list-group-lg .list-group-icon {
  font-size: 1.5rem;
  width: 2.5rem;
}

.list-group-no-gutters .list-group-item {
  padding-right: 0;
  padding-left: 0;
}

/*------------------------------------
  List Checked
------------------------------------*/
.list-checked {
  padding-left: 0;
  list-style: none;
}

.list-checked-item {
  position: relative;
  color: #677788;
  padding-left: 2rem;
}

.list-checked-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.list-checked-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2371869d'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1.25rem 1.25rem;
  content: "";
  margin-top: 0.125rem;
}

.list-checked-item[hover]:hover {
  color: #377dff;
}

[class*=list-checked-bg-] .list-checked-item::before,
[class*=list-checked-soft-bg-] .list-checked-item::before {
  margin-top: 0.25rem;
}

.list-checked-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23377dff'/%3e%3c/svg%3e");
}

.list-checked-bg-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23377dff'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23377dff' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23377dff'/%3e%3c/svg%3e");
}

.list-checked-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2371869d'/%3e%3c/svg%3e");
}

.list-checked-bg-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2371869d'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2371869d' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2371869d'/%3e%3c/svg%3e");
}

.list-checked-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2300c9a7'/%3e%3c/svg%3e");
}

.list-checked-bg-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2300c9a7'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2300c9a7' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2300c9a7'/%3e%3c/svg%3e");
}

.list-checked-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2309a5be'/%3e%3c/svg%3e");
}

.list-checked-bg-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2309a5be'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2309a5be' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2309a5be'/%3e%3c/svg%3e");
}

.list-checked-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23f5ca99'/%3e%3c/svg%3e");
}

.list-checked-bg-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f5ca99'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f5ca99' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23f5ca99'/%3e%3c/svg%3e");
}

.list-checked-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23ed4c78'/%3e%3c/svg%3e");
}

.list-checked-bg-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23ed4c78'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23ed4c78' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23ed4c78'/%3e%3c/svg%3e");
}

.list-checked-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23f7faff'/%3e%3c/svg%3e");
}

.list-checked-bg-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f7faff'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f7faff' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23f7faff'/%3e%3c/svg%3e");
}

.list-checked-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2321325b'/%3e%3c/svg%3e");
}

.list-checked-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2321325b'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2321325b' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2321325b'/%3e%3c/svg%3e");
}

.list-checked-sm .list-checked-item {
  padding-left: 1.5rem;
}

.list-checked-sm .list-checked-item::before {
  width: 1rem;
  height: 1rem;
  background-size: 1rem 1rem;
  margin-top: 0.3125rem;
}

.list-checked-sm[class*=list-checked-bg-] .list-checked-item::before, .list-checked-sm[class*=list-checked-soft-bg-] .list-checked-item::before {
  margin-top: 0.25rem;
}

.list-checked-lg .list-checked-item {
  padding-left: 2.75rem;
}

.list-checked-lg .list-checked-item:not(:last-child) {
  margin-bottom: 1.25rem;
}

.list-checked-lg .list-checked-item::before {
  width: 1.75rem;
  height: 1.75rem;
  background-size: 1.75rem 1.75rem;
  margin-top: 0;
}

.list-checked-lg[class*=list-checked-bg-] .list-checked-item::before, .list-checked-lg[class*=list-checked-soft-bg-] .list-checked-item::before {
  margin-top: -0.0625rem;
}

/*------------------------------------
  List Pointer
------------------------------------*/
.list-pointer {
  padding-left: 0;
  list-style: none;
}

.list-pointer-item {
  position: relative;
  color: #677788;
  padding-left: 1.75rem;
}

.list-pointer-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.list-pointer-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2371869d' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1.25rem 1.25rem;
  content: "";
  margin-top: 0.1875rem;
}

.list-pointer-item[hover]:hover {
  color: #377dff;
}

[class*=list-pointer-bg-] .list-pointer-item::before,
[class*=list-pointer-soft-bg-] .list-pointer-item::before {
  margin-top: 0.25rem;
}

.list-pointer-primary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23377dff' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-primary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23377dff'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-primary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23377dff' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23377dff'/%3e%3c/svg%3e");
}

.list-pointer-secondary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2371869d' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-secondary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2371869d'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-secondary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2371869d' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2371869d'/%3e%3c/svg%3e");
}

.list-pointer-success .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2300c9a7' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-success .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2300c9a7'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-success .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2300c9a7' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2300c9a7'/%3e%3c/svg%3e");
}

.list-pointer-info .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2309a5be' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-info .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2309a5be'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-info .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2309a5be' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2309a5be'/%3e%3c/svg%3e");
}

.list-pointer-warning .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23f5ca99' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-warning .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f5ca99'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23000'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-warning .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f5ca99' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23f5ca99'/%3e%3c/svg%3e");
}

.list-pointer-danger .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23ed4c78' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-danger .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23ed4c78'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-danger .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23ed4c78' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23ed4c78'/%3e%3c/svg%3e");
}

.list-pointer-light .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23f7faff' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-light .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f7faff'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23000'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-light .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f7faff' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23f7faff'/%3e%3c/svg%3e");
}

.list-pointer-dark .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2321325b' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-dark .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2321325b'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-dark .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2321325b' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2321325b'/%3e%3c/svg%3e");
}

.list-pointer-sm .list-pointer-item {
  padding-left: 1.5rem;
}

.list-pointer-sm .list-pointer-item::before {
  width: 1rem;
  height: 1rem;
  background-size: 1rem 1rem;
  margin-top: 0.3125rem;
}

.list-pointer-sm[class*=list-pointer-bg-] .list-pointer-item::before, .list-pointer-sm[class*=list-pointer-soft-bg-] .list-pointer-item::before {
  margin-top: 0.25rem;
}

.list-pointer-lg .list-pointer-item {
  padding-left: 2.75rem;
}

.list-pointer-lg .list-pointer-item:not(:last-child) {
  margin-bottom: 1.25rem;
}

.list-pointer-lg .list-pointer-item::before {
  width: 1.75rem;
  height: 1.75rem;
  background-size: 1.75rem 1.75rem;
  margin-top: 0;
}

.list-pointer-lg[class*=list-pointer-bg-] .list-pointer-item::before, .list-pointer-lg[class*=list-pointer-soft-bg-] .list-pointer-item::before {
  margin-top: -0.0625rem;
}

/*------------------------------------
  Media Viewer
------------------------------------*/
.media-viewer {
  position: relative;
  display: block;
}

.media-viewer:hover .media-viewer-icon {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.media-viewer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.media-viewer-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 1.75rem;
  height: 1.75rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: #377dff;
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  transition: 0.3s;
}

.media-viewer-icon:hover, .media-viewer-icon:focus {
  color: #fff;
}

.media-viewer-icon-active {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*------------------------------------
  Modal
------------------------------------*/
.modal-header {
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 0;
}

.modal-header .close {
  padding: 0.25rem 0.25rem;
  margin: 0 0 0 auto;
}

.modal-footer {
  border-top-width: 0.0625rem;
}

.modal-footer > * {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-footer-text:last-child {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.modal-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 2;
}

.modal-top-cover {
  position: relative;
  overflow: hidden;
  min-height: 8rem;
  border-top-right-radius: 0.6875rem;
  border-top-left-radius: 0.6875rem;
}

.modal-top-cover-avatar {
  position: relative;
  z-index: 2;
  margin-top: -3rem;
}

/*------------------------------------
  Nav
------------------------------------*/
.nav-subtitle {
  display: block;
  color: #8c98a4;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
}

.nav-link.dropdown-toggle {
  -ms-flex-align: center;
  align-items: center;
}

.nav-link.active {
  color: #377dff;
}

.nav-link.active .nav-link-svg path,
.nav-item.show .nav-link-svg path {
  fill: #377dff;
}

.nav-title:last-child,
.nav-text:last-child {
  margin-bottom: 0;
}

.nav-icon {
  opacity: 0.7;
  -ms-flex: 0 0 1.9375rem;
  flex: 0 0 1.9375rem;
}

.nav.nav-link-gray .nav-link {
  color: #677788;
}

.nav.nav-link-gray .nav-link.active, .nav.nav-link-gray .nav-link:hover {
  color: #377dff;
}

.nav.nav-link-gray .nav-link.disabled {
  color: #bdc5d1;
}

.nav-tabs .nav-link {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom: 0.1875rem solid transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-weight: 600;
}

.nav-tabs:not(.nav-vertical) .nav-link {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.nav-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
}

.nav-vertical.nav .nav-item:not(:last-child) {
  margin-right: 0;
}

.nav-vertical.nav .nav-link {
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
}

.nav-vertical .nav-subtitle {
  padding-left: 1rem;
}

.nav-vertical.nav-tabs {
  border-bottom-width: 0;
}

.nav-vertical.nav-tabs .nav-link {
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left: 0.1875rem solid transparent;
  margin-bottom: 0;
}

.nav-vertical.nav-tabs .nav-subtitle {
  border-left: 0.1875rem solid rgba(33, 50, 91, 0.1);
}

.nav-vertical.nav-tabs .nav-link.active,
.nav-vertical.nav-tabs .nav-item.show .nav-link {
  border-color: #377dff;
}

.nav-vertical.nav.nav-tabs .nav-collapse .nav-link {
  padding-left: 3rem;
}

.nav-vertical.nav.nav-tabs .nav-collapse .nav-collapse .nav-link {
  padding-left: 4.5rem;
}

.nav-vertical.nav-pills .nav-link.active,
.nav-vertical.nav-pills .show > .nav-link {
  color: #21325b;
  background-color: transparent;
}

.nav-vertical.nav-pills .nav-link {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.25rem;
}

.nav-vertical.nav-pills .nav-link.active {
  background-color: rgba(189, 197, 209, 0.2);
}

.nav-vertical.nav-pills .nav-link:hover, .nav-vertical.nav-pills .nav-link:focus {
  color: #21325b;
}

.nav-vertical.nav-pills .nav-link[aria-expanded=true] {
  color: #377dff;
}

.nav-vertical.nav-pills .nav-collapse {
  position: relative;
  padding-left: 1.9375rem;
}

.nav-vertical.nav-pills .nav-collapse::before {
  position: absolute;
  top: 0;
  left: 1.3125rem;
  width: 0.125rem;
  height: calc(100% - 0.25rem);
  content: "";
  background-color: rgba(33, 50, 91, 0.1);
}

.nav-vertical.nav-pills .nav-link.dropdown-toggle {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.nav-pills .nav-item {
  margin: 0.25rem 0.25rem;
}

.nav-pills .nav-link {
  padding: 1rem 1rem;
}

.nav-pills .nav-link:hover {
  color: #fff;
}

.nav-pills .nav-link.active:hover,
.nav-pills .show > .nav-link:hover {
  border-color: transparent;
}

.nav-pills-shadow .nav-link.active {
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}

.nav-segment {
  position: relative;
  background-color: #f8fafd;
  padding: 0.25rem 0.25rem;
  border-radius: 0.5rem;
}

.nav-segment:not(.nav-fill) {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.nav-segment .nav-link {
  color: #677788;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.nav-segment .nav-link:hover {
  color: #377dff;
}

.nav-segment .nav-link.active {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0rem 0.1875rem 0.375rem 0rem rgba(140, 152, 164, 0.25);
}

.nav-segment.nav-pills {
  border-radius: 50rem;
}

.nav-segment.nav-pills .nav-link {
  border-radius: 50rem;
}

.nav-light .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.nav-light .nav-link.active, .nav-light .nav-link:hover {
  color: #fff;
}

.nav-light.nav-pills .nav-link:hover {
  background-color: rgba(189, 197, 209, 0.2);
}

.nav-light.nav-pills .nav-link.active, .nav-light.nav-pills .nav-link:hover {
  color: #fff;
}

.nav-sm .nav-link {
  font-size: 0.9375rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.nav-sm.nav-pills .nav-link {
  padding: 0.75rem 0.75rem;
}

.nav-lg .nav-link {
  font-size: 1rem;
}

.nav-lg.nav-pills .nav-link {
  padding: 1.5rem 1.5rem;
}

.nav-link-badge {
  margin-left: auto;
}

/*------------------------------------
  Navbar
------------------------------------*/
/* purgecss start ignore */
.navbar {
  display: block;
  z-index: 99;
}

.navbar-collapse {
  -ms-flex-align: start;
  align-items: flex-start;
}

.navbar-nav {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.navbar-shadow {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}

.navbar-height {
  height: 3.875rem;
}

.navbar.navbar-scrolled.navbar-light {
  background-color: #fff;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}

.navbar.navbar-scrolled.navbar-dark {
  background-color: #21325b;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand-logo {
  width: 100%;
  min-width: 7.5rem;
  max-width: 7.5rem;
}

.navbar-brand-collapsed,
.navbar-brand-on-scroll {
  display: none;
}

.navbar-scrolled .navbar-brand-default {
  display: none;
}

.navbar-scrolled .navbar-brand-on-scroll {
  display: inline-block;
}

.navbar-nav-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-nav-wrap .navbar-toggler {
  margin-left: auto;
}

.navbar-nav-wrap-secondary-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
}

.navbar-toggler {
  padding: 0.5rem 0.5rem;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler .navbar-toggler-text {
  color: #21325b;
}

.navbar-toggler .navbar-toggler-default {
  display: -ms-flexbox;
  display: flex;
}

.navbar-toggler .navbar-toggler-toggled {
  display: none;
}

.navbar-toggler[aria-expanded=true] .navbar-toggler-default {
  display: none;
}

.navbar-toggler[aria-expanded=true] .navbar-toggler-toggled {
  display: -ms-flexbox;
  display: flex;
}

.navbar-topbar .navbar-toggler {
  margin-left: 0;
  font-size: 0.875rem;
}

.navbar-topbar .js-mega-menu {
  z-index: 11;
}

.navbar-topbar-toggler {
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  margin: 0.75rem 0.75rem 0 0.75rem;
  padding-bottom: 0.75rem;
}

@-webkit-keyframes navbar-topbar-collapse-scale-up {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
@keyframes navbar-topbar-collapse-scale-up {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
.navbar-topbar-collapse .navbar-toggler {
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
}

.navbar .navbar-nav .nav-link {
  padding: 1rem 0.75rem;
}

.navbar .nav-item:hover > .nav-link {
  color: #1366ff;
}

.navbar .dropdown-menu {
  border-top: 0.1875rem solid #377dff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.navbar .dropdown-menu::before {
  position: absolute;
  top: -0.625rem;
  display: block;
  left: 0;
  width: 100%;
  height: 1.75rem;
  content: "";
}

.navbar-dropdown-menu-inner {
  padding: 0.75rem 0.75rem;
}

.navbar-dropdown-menu-media-link {
  display: block;
  text-decoration: none;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-media-link:hover:not(.disabled):not(:disabled) {
  background-color: rgba(189, 197, 209, 0.2);
}

.navbar-dropdown-menu-media-link:hover:not(.disabled):not(:disabled) .navbar-dropdown-menu-media-title {
  color: #377dff;
}

.navbar-dropdown-menu-media-link.disabled {
  pointer-events: none;
}

.navbar-dropdown-menu-media-link + .navbar-dropdown-menu-media-link {
  margin-top: 0.5rem;
}

.navbar-dropdown-menu-media-title {
  color: #1e2022;
  font-weight: 600;
}

.navbar-dropdown-menu-media-desc {
  color: #677788;
  font-size: 0.875rem;
  margin-bottom: 0;
}

.navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item {
  position: relative;
}

.navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
  position: absolute;
  top: -0.75rem;
  right: 0;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  width: 100%;
  content: "";
}

.navbar-dropdown-menu-promo-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  margin: 0.75rem 0.75rem;
}

.navbar-dropdown-menu-promo-item ~ .navbar-dropdown-menu-promo-item {
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.navbar .navbar-dropdown-menu-borderless {
  border-top-width: 0;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-promo .navbar-dropdown-menu-promo-item {
  border-top: none;
}

.navbar-dropdown-menu-promo-link {
  display: block;
  height: 100%;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-promo-link.active {
  background-color: rgba(189, 197, 209, 0.2);
}

.navbar-dropdown-menu-promo-link.active .navbar-dropdown-menu-media-title {
  color: #377dff;
}

.navbar-dropdown-menu-promo-link.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.navbar-dropdown-menu-promo-link:hover:not(.disabled):not(:disabled) {
  background-color: rgba(189, 197, 209, 0.2);
}

.navbar-dropdown-menu-promo-link:hover:not(.disabled):not(:disabled) .navbar-dropdown-menu-media-title {
  color: #377dff;
}

.navbar-dropdown-menu-banner {
  position: relative;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-banner-content {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 100%;
  text-align: center;
  padding: 2rem 2rem;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.navbar-dropdown-menu-shop-banner {
  position: relative;
  background-color: #f7faff;
  padding-left: 10rem;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-shop-banner-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10rem;
}

.navbar.navbar-vertical .navbar-nav .nav-subtitle,
.navbar.navbar-vertical .navbar-nav .nav-link {
  padding: 0.3125rem 1.5rem;
}

.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical {
  padding-right: 0;
  padding-left: 0;
}

.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-link,
.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-subtitle {
  border-left-color: transparent;
}

.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-link.active,
.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-item.show .nav-link {
  border-color: #377dff;
}

.navbar-input-group {
  /* clears the 'X' from Internet Explorer */
  /* clears the 'X' from Chrome */
}

.navbar-input-group input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.navbar-input-group input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.navbar-input-group input[type=search]::-webkit-search-decoration,
.navbar-input-group input[type=search]::-webkit-search-cancel-button,
.navbar-input-group input[type=search]::-webkit-search-results-button,
.navbar-input-group input[type=search]::-webkit-search-results-decoration {
  display: none;
}

.navbar-vertical .card .nav-tabs .nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 0;
  padding-left: 1.8125rem;
  margin-left: -2rem;
}

.navbar-vertical .card .nav-tabs .nav-link:not(.active) {
  border-color: transparent;
}

.navbar-promo {
  background-color: #f8fafd;
}

.navbar-promo-inner {
  color: inherit;
  padding: 2rem 2rem;
}

.navbar-promo-item {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.navbar-promo-item ~ .navbar-promo-item {
  border-top: 0.0625rem solid #e7eaf3;
}

.navbar-promo-link {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.navbar-promo-link.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.navbar-promo-link:hover .navbar-promo-title {
  color: #377dff;
}

.navbar-promo-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.navbar-promo-card-deck {
  display: block;
}

.navbar-promo-card-deck:not(:last-child) .navbar-promo-item {
  border-bottom: 0.0625rem solid #e7eaf3;
}

.navbar-promo-card-deck .navbar-promo-item {
  border-top: none;
}

.navbar-promo-card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.navbar-promo-icon {
  width: 100%;
  max-width: 2.75rem;
  margin-right: 1rem;
}

.navbar-promo-title {
  display: block;
  color: #1e2022;
  font-size: 1rem;
  font-weight: 600;
}

.navbar-promo-text {
  display: block;
  font-size: 0.875rem;
  color: #677788;
}

.navbar-promo-footer {
  background-color: #f8fafd;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.navbar-promo-footer-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-promo-footer-text {
  display: block;
  font-size: 0.8125rem;
}

.navbar-promo-footer-ver-divider {
  position: relative;
}

.navbar-promo-footer-ver-divider::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 0.0625rem solid #e7eaf3;
  content: "";
}

.navbar-expand .navbar-promo {
  border-bottom-right-radius: 0.3125rem;
}

.navbar-expand .navbar-promo-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  -ms-flex-direction: column;
  flex-direction: column;
}

.navbar-expand .navbar-promo-card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.navbar-expand .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
  position: relative;
}

.navbar-expand .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -1.5rem;
  border-right: 0.0625rem solid #e7eaf3;
  height: 100%;
  content: "";
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .navbar-expand-sm .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-sm .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid #377dff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-sm .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-sm .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand-sm .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-sm .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-sm .navbar-promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-sm .navbar-promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-sm .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand-sm .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid #e7eaf3;
    height: 100%;
    content: "";
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .navbar-expand-md .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-md .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid #377dff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
  }
  .navbar-expand-md .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-md .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-md .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand-md .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-md .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-md .navbar-promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-md .navbar-promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-md .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand-md .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid #e7eaf3;
    height: 100%;
    content: "";
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .navbar-expand-lg .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-lg .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid #377dff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-lg .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-lg .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand-lg .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-lg .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-lg .navbar-promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-lg .navbar-promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-lg .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand-lg .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid #e7eaf3;
    height: 100%;
    content: "";
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .navbar-expand-xl .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-xl .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid #377dff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-xl .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-xl .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand-xl .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-xl .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-xl .navbar-promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-xl .navbar-promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-xl .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand-xl .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid #e7eaf3;
    height: 100%;
    content: "";
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-sm .navbar-brand,
.navbar-expand-sm .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand-sm .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand-sm .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand-sm .nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-sm .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-sm .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-sm .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand-sm .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-sm .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-sm .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-sm .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-sm .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-sm .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid #e7eaf3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand-sm .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-sm .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand-sm .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-md .navbar-brand,
.navbar-expand-md .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand-md .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand-md .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand-md .nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-md .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-md .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-md .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand-md .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-md .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-md .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-md .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-md .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-md .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid #e7eaf3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand-md .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand-md .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-md .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand-md .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-brand,
.navbar-expand-lg .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand-lg .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand-lg .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand-lg .nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-lg .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-lg .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-lg .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand-lg .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-lg .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-lg .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-lg .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-lg .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-lg .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid #e7eaf3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand-lg .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-lg .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand-lg .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xl .navbar-brand,
.navbar-expand-xl .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand-xl .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand-xl .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand-xl .nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-xl .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-xl .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-xl .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand-xl .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-xl .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-xl .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-xl .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-xl .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-xl .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid #e7eaf3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand-xl .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-xl .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand-xl .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
}
.navbar-expand.navbar {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-expand .navbar-brand,
.navbar-expand .navbar-brand > img {
  width: 7rem;
}

.navbar-expand .navbar-nav {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.navbar-expand .navbar-nav-last-item {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.navbar-expand .nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-expand .nav-link-toggle::after {
  margin-left: auto;
}

.navbar-expand .hs-mega-menu {
  max-width: 100% !important;
}

.navbar-expand .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand .hs-sub-menu-opened .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.navbar-expand .dropdown-item-toggle::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.navbar-expand .hs-sub-menu-opened > .dropdown-item-toggle::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.navbar-expand .mega-menu-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.navbar-expand .dropdown-menu {
  box-shadow: none;
}

.navbar-expand .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
  border-left: 0.1875rem solid #e7eaf3;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.navbar-expand .dropdown-menu .mega-menu-body {
  padding-left: 1.5rem;
}

.navbar-expand .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
  margin-left: 1.5rem;
}

.navbar-expand .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
  padding-right: 0.6125rem;
}

.navbar-expand .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
  border-bottom: 0.0625rem solid #e7eaf3;
}

@media (max-width: 767.98px) {
  .navbar-expand-sm .header-nav-last-item {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-xs .navbar-banner,
.navbar-expand-xs .navbar-product-banner,
.navbar-expand-sm .navbar-banner,
.navbar-expand-sm .navbar-product-banner,
.navbar-expand-md .navbar-banner,
.navbar-expand-md .navbar-product-banner,
.navbar-expand-lg .navbar-banner,
.navbar-expand-lg .navbar-product-banner {
    display: none;
  }
  .navbar-expand .navbar-brand {
    padding-top: 0.5rem;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-banner,
.navbar-expand-xl .navbar-product-banner {
    display: none;
  }
}
@media (min-width: 1200px) {
  .header .mega-menu-position-right-fix-xl.hs-mega-menu.hs-position-right {
    right: 20%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar-expand-md .navbar-brand {
    padding-top: 0.5rem;
  }
  .header .mega-menu-position-right-fix-md.hs-mega-menu.hs-position-right {
    right: 20%;
  }
}
@media (min-width: 992px) {
  [class*=navbar-expand]:not(.navbar-expand-xl) {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg-collapse-block .navbar-collapse {
    display: block !important;
  }
}
/*------------------------------------
  Absolute Positions
------------------------------------*/
@media (min-width: 576px) {
  .navbar-absolute-sm-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media (min-width: 768px) {
  .navbar-absolute-md-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media (min-width: 992px) {
  .navbar-absolute-lg-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-absolute-xl-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-absolute-xxl-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
.navbar-absolute-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 575.98px) {
  .navbar-absolute-md-top .navbar-absolute-top-scroller {
    max-height: 75vh;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-absolute-md-top .navbar-absolute-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-absolute-md-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-absolute-md-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 767.98px) {
  .navbar-absolute-top .navbar-absolute-top-scroller,
.navbar-absolute-sm-top .navbar-absolute-top-scroller,
.navbar-absolute-lg-top .navbar-absolute-top-scroller {
    max-height: 75vh;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-absolute-top .navbar-absolute-top-scroller::-webkit-scrollbar,
.navbar-absolute-sm-top .navbar-absolute-top-scroller::-webkit-scrollbar,
.navbar-absolute-lg-top .navbar-absolute-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-absolute-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb,
.navbar-absolute-sm-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb,
.navbar-absolute-lg-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-absolute-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb,
.navbar-absolute-sm-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb,
.navbar-absolute-lg-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 991.98px) {
  .navbar-absolute-xl-top .navbar-absolute-top-scroller {
    max-height: 75vh;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-absolute-xl-top .navbar-absolute-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-absolute-xl-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-absolute-xl-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
/*------------------------------------
  Sticky Positions
------------------------------------*/
@media (min-width: 576px) {
  .navbar-sticky-sm-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media (min-width: 768px) {
  .navbar-sticky-md-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media (min-width: 992px) {
  .navbar-sticky-lg-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-sticky-xl-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-sticky-xxl-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
.navbar-sticky-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 575.98px) {
  .navbar-sticky-md-top .navbar-sticky-top-scroller {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-sticky-md-top .navbar-sticky-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-sticky-md-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-sticky-md-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 767.98px) {
  .navbar-sticky-top .navbar-sticky-top-scroller,
.navbar-sticky-sm-top .navbar-sticky-top-scroller,
.navbar-sticky-lg-top .navbar-sticky-top-scroller {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-sticky-top .navbar-sticky-top-scroller::-webkit-scrollbar,
.navbar-sticky-sm-top .navbar-sticky-top-scroller::-webkit-scrollbar,
.navbar-sticky-lg-top .navbar-sticky-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-sticky-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb,
.navbar-sticky-sm-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb,
.navbar-sticky-lg-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-sticky-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb,
.navbar-sticky-sm-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb,
.navbar-sticky-lg-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 991.98px) {
  .navbar-sticky-xl-top .navbar-sticky-top-scroller {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-sticky-xl-top .navbar-sticky-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-sticky-xl-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-sticky-xl-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
/*------------------------------------
  Navbar Expand
------------------------------------*/
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-toggler {
    -ms-flex-order: 1;
    order: 1;
  }
  .navbar-expand-sm .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-sm .navbar-collapse {
    width: auto;
    -ms-flex-order: 2;
    order: 2;
  }
  .navbar-expand-sm .navbar-nav-wrap-secondary-content {
    -ms-flex-order: 3;
    order: 3;
  }
  .navbar-expand-sm:not(.navbar-vertical) .navbar-nav {
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-sm .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-sm .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-sm .hs-sub-menu.dropdown-menu,
.navbar-expand-sm .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-sm .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-sm .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-sm .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-sm .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-sm .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-sm.navbar-end .navbar-topbar .navbar-nav {
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-sm.navbar-end .navbar-nav {
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-sm.navbar-end .navbar-sticky-top-scroller,
.navbar-expand-sm.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-sm.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-sm.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-sm.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-sm.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-sm.navbar-floating .dropdown-menu, .navbar-expand-sm.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-sm.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand-sm.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-sm .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar {
    -ms-flex-flow: column;
    flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: "";
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-toggler {
    -ms-flex-order: 1;
    order: 1;
  }
  .navbar-expand-md .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-md .navbar-collapse {
    width: auto;
    -ms-flex-order: 2;
    order: 2;
  }
  .navbar-expand-md .navbar-nav-wrap-secondary-content {
    -ms-flex-order: 3;
    order: 3;
  }
  .navbar-expand-md:not(.navbar-vertical) .navbar-nav {
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-md .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-md .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-md .hs-sub-menu.dropdown-menu,
.navbar-expand-md .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-md .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-md .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-md .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-md .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-md .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-md.navbar-end .navbar-topbar .navbar-nav {
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-md.navbar-end .navbar-nav {
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-md.navbar-end .navbar-sticky-top-scroller,
.navbar-expand-md.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-md.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-md.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-md.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-md.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-md.navbar-floating .dropdown-menu, .navbar-expand-md.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-md.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand-md.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-md .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar {
    -ms-flex-flow: column;
    flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: "";
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-toggler {
    -ms-flex-order: 1;
    order: 1;
  }
  .navbar-expand-lg .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-lg .navbar-collapse {
    width: auto;
    -ms-flex-order: 2;
    order: 2;
  }
  .navbar-expand-lg .navbar-nav-wrap-secondary-content {
    -ms-flex-order: 3;
    order: 3;
  }
  .navbar-expand-lg:not(.navbar-vertical) .navbar-nav {
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-lg .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-lg .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-lg .hs-sub-menu.dropdown-menu,
.navbar-expand-lg .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-lg .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-lg .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-lg .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-lg .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-lg .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-lg.navbar-end .navbar-topbar .navbar-nav {
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-lg.navbar-end .navbar-nav {
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-lg.navbar-end .navbar-sticky-top-scroller,
.navbar-expand-lg.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-lg.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-lg.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-lg.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-lg.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-lg.navbar-floating .dropdown-menu, .navbar-expand-lg.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-lg.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand-lg.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-lg .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar {
    -ms-flex-flow: column;
    flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: "";
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-toggler {
    -ms-flex-order: 1;
    order: 1;
  }
  .navbar-expand-xl .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-xl .navbar-collapse {
    width: auto;
    -ms-flex-order: 2;
    order: 2;
  }
  .navbar-expand-xl .navbar-nav-wrap-secondary-content {
    -ms-flex-order: 3;
    order: 3;
  }
  .navbar-expand-xl:not(.navbar-vertical) .navbar-nav {
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-xl .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-xl .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-xl .hs-sub-menu.dropdown-menu,
.navbar-expand-xl .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-xl .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-xl .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-xl .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-xl .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-xl .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-xl.navbar-end .navbar-topbar .navbar-nav {
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-xl.navbar-end .navbar-nav {
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-xl.navbar-end .navbar-sticky-top-scroller,
.navbar-expand-xl.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-xl.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-xl.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-xl.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-xl.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xl.navbar-floating .dropdown-menu, .navbar-expand-xl.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-xl.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand-xl.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-xl .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar {
    -ms-flex-flow: column;
    flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: "";
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-toggler {
    -ms-flex-order: 1;
    order: 1;
  }
  .navbar-expand-xxl .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-xxl .navbar-collapse {
    width: auto;
    -ms-flex-order: 2;
    order: 2;
  }
  .navbar-expand-xxl .navbar-nav-wrap-secondary-content {
    -ms-flex-order: 3;
    order: 3;
  }
  .navbar-expand-xxl:not(.navbar-vertical) .navbar-nav {
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-expand-xxl .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-xxl .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-xxl .hs-sub-menu.dropdown-menu,
.navbar-expand-xxl .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-xxl .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-xxl .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-xxl .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-xxl .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-xxl .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-xxl.navbar-end .navbar-topbar .navbar-nav {
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-xxl.navbar-end .navbar-nav {
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-xxl.navbar-end .navbar-sticky-top-scroller,
.navbar-expand-xxl.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-xxl.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-xxl.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-xxl.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xxl.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-xxl.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xxl.navbar-floating .dropdown-menu, .navbar-expand-xxl.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-xxl.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand-xxl.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-xxl .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar {
    -ms-flex-flow: column;
    flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: "";
  }
}
.navbar-expand .navbar-toggler {
  -ms-flex-order: 1;
  order: 1;
}

.navbar-expand .navbar-toggler-wrapper {
  display: none;
}

.navbar-expand .navbar-collapse {
  width: auto;
  -ms-flex-order: 2;
  order: 2;
}

.navbar-expand .navbar-nav-wrap-secondary-content {
  -ms-flex-order: 3;
  order: 3;
}

.navbar-expand:not(.navbar-vertical) .navbar-nav {
  -ms-flex-align: center;
  align-items: center;
}

.navbar-expand .nav-item:not(:last-child) {
  margin-right: 0.5rem;
}

.navbar-expand .nav-item .nav-item {
  margin-right: 0;
}

.navbar-expand .hs-sub-menu.dropdown-menu,
.navbar-expand .hs-sub-menu.dropdown-menu[data-bs-popper] {
  margin-top: 0.5rem;
}

.navbar-expand .dropdown-menu {
  margin-top: 0.5rem;
}

.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu {
  margin-top: -0.6875rem;
  margin-left: 0.5rem;
}

.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
  top: 0%;
  left: 100%;
}

.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
  top: 0;
  left: -1rem;
  width: 1rem;
  height: 100%;
}

.navbar-expand .navbar-topbar .navbar-toggler {
  margin-left: 0;
}

.navbar-expand .navbar-topbar .nav-link {
  font-size: 0.9375rem;
  padding: 0.5rem 0.75rem;
}

.navbar-expand .navbar-topbar .nav-item:first-child .nav-link {
  padding-left: 0;
}

.navbar-expand .navbar-topbar .nav-item:last-child .nav-link {
  padding-right: 0;
}

.navbar-expand.navbar-end .navbar-topbar .navbar-nav {
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-left: 0;
}

.navbar-expand.navbar-end .navbar-nav {
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-left: auto;
}

.navbar-expand.navbar-end .navbar-sticky-top-scroller,
.navbar-expand.navbar-end .navbar-absolute-top-scroller {
  margin-left: auto;
}

.navbar-expand.navbar-floating {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 2.5rem;
}

.navbar-expand.navbar-floating.navbar-scrolled {
  position: fixed;
  background-color: #fff;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  margin-top: 0;
}

.navbar-expand.navbar-floating.navbar-scrolled .navbar-floating-nav {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
  box-shadow: none;
}

.navbar-expand.navbar-floating .navbar-floating-nav {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-expand.navbar-floating .dropdown-menu, .navbar-expand.navbar-floating .dropdown-menu[data-bs-popper] {
  margin-top: 0.5rem;
}

.navbar-expand.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand.navbar-floating .dropdown-menu .hs-mega-menu {
  margin-top: 0;
}

.navbar-expand .navbar-floating-nav {
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-expand.navbar-vertical.navbar-sidebar {
  -ms-flex-flow: column;
  flex-flow: column;
  overflow-y: scroll;
  height: 100%;
  max-height: 100vh;
}

.navbar-expand.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
  width: 0.6125rem;
}

.navbar-expand.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.navbar-expand.navbar-vertical.navbar-sidebar .navbar-collapse {
  width: 100%;
  display: block !important;
}

.navbar-expand.navbar-vertical.navbar-sidebar .navbar-nav {
  display: block;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
}

.navbar-expand .navbar-dropdown-menu-promo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.navbar-expand .navbar-dropdown-menu-promo-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  -ms-flex-direction: column;
  flex-direction: column;
}

.navbar-expand .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
  position: relative;
}

.navbar-expand .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -0.75rem;
  border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
  height: 100%;
  content: "";
}

.navbar-expand .navbar-nav-wrap-secondary-content {
  margin-left: auto;
}

.navbar-expand .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
  margin-left: 0.5rem;
}

.navbar-expand .navbar-collapse {
  background-color: #fff;
}

.navbar-expand .navbar-nav {
  padding: 1rem 1rem;
}

.navbar-expand .navbar-nav .nav-subtitle,
.navbar-expand .navbar-nav .nav-link {
  padding: 0.5rem 0;
}

.navbar-expand .hs-mega-menu {
  max-width: 100% !important;
  min-width: 100% !important;
}

.navbar-expand .navbar-topbar-collapse {
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  width: calc(100% - 1rem);
  height: auto;
  max-height: calc(100% - 3rem);
  overflow-y: scroll;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
}

.navbar-expand .navbar-topbar-collapse.collapsing,
.navbar-expand .navbar-topbar-collapse.show {
  -webkit-animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.navbar-expand .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
  position: relative;
  box-shadow: none;
  border-top-width: 0;
  margin-top: 0;
  border-radius: 0;
  padding-left: 1rem;
}

.navbar-expand .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
  position: absolute;
  top: 0;
  left: 0.4375rem;
  width: 0.125rem;
  height: calc(100% - 0.25rem);
  content: "";
  background-color: rgba(33, 50, 91, 0.1);
}

.navbar-expand .navbar-dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-dropdown-sub-menu {
  position: static;
  box-shadow: none;
  padding: 0;
  margin-top: 0;
}

.navbar-expand.navbar-dark .navbar-nav .dropdown-menu {
  border-left-width: 0;
}

.navbar-expand .hs-sub-menu-opened > .dropdown-toggle::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.navbar-expand .dropdown-menu .dropdown-item.dropdown-toggle::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.navbar-expand .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.navbar-expand .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.navbar-expand .hs-mega-menu .hs-mega-menu,
.navbar-expand .hs-mega-menu .hs-sub-menu,
.navbar-expand .hs-menu-vertical .hs-mega-menu,
.navbar-expand .hs-menu-vertical .hs-sub-menu,
.navbar-expand .hs-sub-menu .hs-mega-menu,
.navbar-expand .hs-sub-menu .hs-sub-menu {
  left: 0;
}

.navbar-expand .navbar-topbar {
  margin-bottom: 0.4375rem;
}

.navbar-expand.navbar-dark .navbar-nav,
.navbar-expand.navbar-dark .navbar-topbar-collapse {
  background-color: #21325b;
}

.navbar-expand.navbar-dark .navbar-topbar-toggler {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.navbar-expand.navbar-vertical .navbar-nav {
  padding: 0;
}

.navbar-expand .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
  top: -0.375rem;
}

.navbar-expand .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
  position: relative;
}

.navbar-expand .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
  position: absolute;
  bottom: -0.375rem;
  right: 0;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  width: 100%;
  content: "";
}

.navbar-expand .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
  border-top-width: 0;
}

.navbar-expand .navbar-absolute-top-inner {
  background-color: #fff;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-sm .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-sm .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-sm .navbar-nav .nav-subtitle,
.navbar-expand-sm .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-sm .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-sm .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-sm .navbar-topbar-collapse.collapsing,
.navbar-expand-sm .navbar-topbar-collapse.show {
    -webkit-animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - 0.25rem);
    content: "";
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-sm .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-sm.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-sm .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-sm .dropdown-menu .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .navbar-expand-sm .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-sm .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-sm .hs-mega-menu .hs-mega-menu,
.navbar-expand-sm .hs-mega-menu .hs-sub-menu,
.navbar-expand-sm .hs-menu-vertical .hs-mega-menu,
.navbar-expand-sm .hs-menu-vertical .hs-sub-menu,
.navbar-expand-sm .hs-sub-menu .hs-mega-menu,
.navbar-expand-sm .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-sm .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-sm.navbar-dark .navbar-nav,
.navbar-expand-sm.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-sm.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-sm.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: "";
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-sm .navbar-absolute-top-inner {
    background-color: #fff;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-md .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-md .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-md .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-md .navbar-nav .nav-subtitle,
.navbar-expand-md .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-md .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-md .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-md .navbar-topbar-collapse.collapsing,
.navbar-expand-md .navbar-topbar-collapse.show {
    -webkit-animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - 0.25rem);
    content: "";
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-md .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-md.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-md .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-md .dropdown-menu .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .navbar-expand-md .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-md .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-md .hs-mega-menu .hs-mega-menu,
.navbar-expand-md .hs-mega-menu .hs-sub-menu,
.navbar-expand-md .hs-menu-vertical .hs-mega-menu,
.navbar-expand-md .hs-menu-vertical .hs-sub-menu,
.navbar-expand-md .hs-sub-menu .hs-mega-menu,
.navbar-expand-md .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-md .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-md.navbar-dark .navbar-nav,
.navbar-expand-md.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-md.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-md.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: "";
  }
  .navbar-expand-md .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-md .navbar-absolute-top-inner {
    background-color: #fff;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-lg .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-lg .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-lg .navbar-nav .nav-subtitle,
.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-lg .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-lg .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-lg .navbar-topbar-collapse.collapsing,
.navbar-expand-lg .navbar-topbar-collapse.show {
    -webkit-animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - 0.25rem);
    content: "";
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-lg .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-lg.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-lg .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-lg .dropdown-menu .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .navbar-expand-lg .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-lg .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-lg .hs-mega-menu .hs-mega-menu,
.navbar-expand-lg .hs-mega-menu .hs-sub-menu,
.navbar-expand-lg .hs-menu-vertical .hs-mega-menu,
.navbar-expand-lg .hs-menu-vertical .hs-sub-menu,
.navbar-expand-lg .hs-sub-menu .hs-mega-menu,
.navbar-expand-lg .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-lg .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-lg.navbar-dark .navbar-nav,
.navbar-expand-lg.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-lg.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-lg.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: "";
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-lg .navbar-absolute-top-inner {
    background-color: #fff;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-xl .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-xl .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-xl .navbar-nav .nav-subtitle,
.navbar-expand-xl .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-xl .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-xl .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-xl .navbar-topbar-collapse.collapsing,
.navbar-expand-xl .navbar-topbar-collapse.show {
    -webkit-animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - 0.25rem);
    content: "";
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-xl .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-xl.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-xl .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-xl .dropdown-menu .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .navbar-expand-xl .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-xl .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-xl .hs-mega-menu .hs-mega-menu,
.navbar-expand-xl .hs-mega-menu .hs-sub-menu,
.navbar-expand-xl .hs-menu-vertical .hs-mega-menu,
.navbar-expand-xl .hs-menu-vertical .hs-sub-menu,
.navbar-expand-xl .hs-sub-menu .hs-mega-menu,
.navbar-expand-xl .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-xl .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-xl.navbar-dark .navbar-nav,
.navbar-expand-xl.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-xl.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-xl.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: "";
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-xl .navbar-absolute-top-inner {
    background-color: #fff;
  }
}
@media (max-width: 1399.98px) {
  .navbar-expand-xxl .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-xxl .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-xxl .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-xxl .navbar-nav .nav-subtitle,
.navbar-expand-xxl .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-xxl .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-xxl .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-xxl .navbar-topbar-collapse.collapsing,
.navbar-expand-xxl .navbar-topbar-collapse.show {
    -webkit-animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - 0.25rem);
    content: "";
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-xxl .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-xxl.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-xxl .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-xxl .dropdown-menu .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .navbar-expand-xxl .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-xxl .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .navbar-expand-xxl .hs-mega-menu .hs-mega-menu,
.navbar-expand-xxl .hs-mega-menu .hs-sub-menu,
.navbar-expand-xxl .hs-menu-vertical .hs-mega-menu,
.navbar-expand-xxl .hs-menu-vertical .hs-sub-menu,
.navbar-expand-xxl .hs-sub-menu .hs-mega-menu,
.navbar-expand-xxl .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-xxl .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-xxl.navbar-dark .navbar-nav,
.navbar-expand-xxl.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-xxl.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-xxl.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: "";
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-xxl .navbar-absolute-top-inner {
    background-color: #fff;
  }
}
.navbar-expand .navbar-nav .nav-subtitle,
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

/*------------------------------------
  Navbar Sidebar
------------------------------------*/
.navbar-sidebar-aside-content {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

@media (min-width: 576px) {
  .navbar-sidebar-aside-sm .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}
@media (min-width: 768px) {
  .navbar-sidebar-aside-md .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-md .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}
@media (min-width: 992px) {
  .navbar-sidebar-aside-lg .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}
@media (min-width: 1200px) {
  .navbar-sidebar-aside-xl .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}
@media (min-width: 1400px) {
  .navbar-sidebar-aside-xxl .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}
.navbar-sidebar-aside .navbar-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 17rem;
  padding: 0;
}

.navbar-sidebar-aside .navbar-sidebar .navbar-collapse {
  width: 100%;
}

.navbar-sidebar-aside .navbar-sidebar .navbar-brand-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 17rem;
  height: 5rem;
  background-color: #fff;
  z-index: 99;
  padding: 1.25rem 1.5rem;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.navbar-sidebar-aside .navbar-sidebar .navbar-brand {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0;
}

.navbar-sidebar-aside .navbar-sidebar .navbar-nav {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.navbar-sidebar-aside .navbar-sidebar .navbar-sidebar-aside-body {
  padding-top: 6.25rem;
}

.navbar-sidebar-aside .navbar-sidebar-aside-content {
  margin-left: 17rem;
}

.navbar-sidebar-aside .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside .navbar-sidebar .navbar-brand-badge {
  display: none;
}

.navbar-sidebar-aside .nav-segment {
  margin: 1.25rem 1.5rem;
}

@media (max-width: 575.98px) {
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-sm .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside-md .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-md .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-lg .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-xl .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}
@media (max-width: 1399.98px) {
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-xxl .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}
/*------------------------------------
  Navbar Skins
------------------------------------*/
.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #677788;
}

.navbar-light .navbar-toggler {
  color: #677788;
  border-color: rgba(33, 50, 91, 0.1);
}

.navbar-light .navbar-nav .nav-link {
  color: #677788;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #377dff;
}

.navbar-light .navbar-nav .nav-link.active .dropdown-item-icon {
  color: #377dff;
  opacity: 1;
}

.navbar-dark .navbar-nav .nav-item:hover .nav-link, .navbar-dark .navbar-nav .nav-item:hover .nav-link:hover, .navbar-dark .navbar-nav .nav-item:hover .nav-link:focus {
  color: #fff;
}

.navbar-dark .navbar-toggler-text {
  color: #fff;
}

.navbar-dark .nav-link.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='rgba(255, 255, 255, 0.55)' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>");
}

.navbar-dark.navbar-fullscreen.navbar-expand .navbar-nav {
  background-color: transparent;
}

.navbar-dark.navbar-fullscreen .navbar-nav .nav-link:hover, .navbar-dark.navbar-fullscreen .navbar-nav .nav-link:hover:hover, .navbar-dark.navbar-fullscreen .navbar-nav .nav-link:hover:focus,
.navbar-dark.navbar-fullscreen .navbar-nav .nav-link:focus,
.navbar-dark.navbar-fullscreen .navbar-nav .nav-link:focus:hover,
.navbar-dark.navbar-fullscreen .navbar-nav .nav-link:focus:focus {
  color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Navbar Helpers
------------------------------------*/
.navbar-invisible {
  display: none;
}

.navbar-moved-up {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.navbar-faded {
  opacity: 0;
  visibility: hidden;
}

.navbar-section-hidden {
  position: relative;
}

.navbar[data-hs-header-options*=fixMoment] {
  transition: 0.3s ease;
}

.navbar.navbar-untransitioned {
  transition: none;
}

.navbar.navbar-scrolled {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
}

.navbar.navbar-scrolled .navbar-topbar {
  display: none;
}

.navbar-fix-top {
  position: fixed;
}

.navbar.navbar-fix-top[data-hs-header-options*=effectCompensation] {
  transition: none;
}

/* purgecss end ignore */
/*------------------------------------
  Fullscreen
------------------------------------*/
.navbar-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-fullscreen .navbar-collapse {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.navbar-fullscreen .navbar-collapse.show {
  opacity: 1;
  visibility: visible;
  background-color: rgba(33, 50, 91, 0.9);
}

.navbar-fullscreen .navbar-toggler {
  display: block;
  z-index: 999;
}

.navbar-fullscreen .navbar-collapse .container, .navbar-fullscreen .navbar-collapse .container > *, .navbar-fullscreen .navbar-collapse .container > * > * {
  height: 100%;
}

.navbar-fullscreen .navbar-nav {
  display: block;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
}

.navbar-fullscreen .navbar-nav .nav-item .nav-item .nav-link {
  font-size: 1rem;
}

.navbar-fullscreen .navbar-nav .navbar-fullscreen-collapse {
  border-left: 0.1875rem solid rgba(255, 255, 255, 0.4);
}

.navbar-fullscreen .navbar-nav .navbar-fullscreen-collapse {
  padding-left: 1rem;
}

.navbar-fullscreen .navbar-nav .nav-link {
  color: #fff;
  font-size: 1.5rem;
}

.navbar-fullscreen .navbar-nav > .nav-item .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-fullscreen .navbar-nav .dropdown-item, .navbar-fullscreen .navbar-nav .dropdown-item.active {
  color: #fff;
}

.navbar-fullscreen .navbar-fullscreen-scroller {
  max-height: 75vh;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 12.5vh;
}

.navbar-fullscreen .navbar-fullscreen-scroller::-webkit-scrollbar {
  width: 0.6125rem;
}

.navbar-fullscreen .navbar-fullscreen-scroller::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
  visibility: hidden;
}

.navbar-fullscreen .navbar-fullscreen-scroller:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

/*------------------------------------
  Offcanvas
------------------------------------*/
.offcanvas-header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.offcanvas-navbar-search {
  bottom: auto;
  height: auto;
}

/*------------------------------------
  Page Header
------------------------------------*/
.page-header {
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding-bottom: 2rem;
  margin-bottom: 1rem;
}

.page-header .breadcrumb {
  margin-bottom: 0.25rem;
}

.page-header-title {
  margin-bottom: 0.25rem;
}

.page-header-text {
  margin-bottom: 0.25rem;
}

.page-header-tabs {
  margin-bottom: -2rem;
  border-bottom-width: 0;
}

.hs-nav-scroller-horizontal .page-header-tabs {
  margin-bottom: -2rem;
}

.page-header-reset {
  border-bottom-width: 0;
  padding-bottom: 0;
}

/*------------------------------------
  Page Preloader
------------------------------------*/
.page-preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #fff;
}

.page-preloader-middle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

/*------------------------------------
  Pagination
------------------------------------*/
.page-item:not(:last-child) {
  margin-right: 0.25rem;
}

.page-link {
  min-width: 2.25rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.3125rem;
}

/*------------------------------------
  Popover
------------------------------------*/
.popover {
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
}

.popover-header {
  font-size: 1.25rem;
  font-weight: 600;
}

/*------------------------------------
  Profile Cover
------------------------------------*/
.profile-cover {
  position: relative;
  height: 7.5rem;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
}

.profile-cover-content {
  position: relative;
  z-index: 1;
}

.profile-cover-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 7.5rem;
  background-color: #e7eaf3;
  border-radius: 0.5rem;
}

.profile-cover-img {
  width: 100%;
  height: 7.5rem;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: top;
  border-radius: 0.5rem;
}

.profile-cover-avatar {
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  padding: 0.75rem 0.75rem;
  margin-top: -1.75rem;
}

.profile-cover-content {
  padding: 1rem 1rem;
}

.profile-cover-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 992px) {
  .profile-cover {
    height: 10rem;
  }
  .profile-cover-img-wrapper {
    height: 10rem;
  }
  .profile-cover-img {
    height: 10rem;
  }
}
/*------------------------------------
  Vertical Progress Bar
------------------------------------*/
.progress-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #e7eaf3;
  width: 0.5rem;
  height: 12.5rem;
}

/*------------------------------------
  Shapes
------------------------------------*/
.shape-container {
  position: relative;
}

.shape[class*=text-] > svg [fill]:not([fill=none]) {
  fill: currentColor !important;
}

.shape {
  position: absolute;
  z-index: -1;
}

.shape-top {
  top: 0;
  left: 0;
  right: 0;
}

.shape-top > svg {
  width: 100%;
  height: auto;
  margin-bottom: -1px;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

.shape-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.shape-bottom > svg {
  width: 100%;
  height: auto;
  margin-bottom: -1px;
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

.shape-start {
  top: 0;
  left: 0;
  bottom: 0;
}

.shape-start > svg {
  width: auto;
  height: 100%;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.shape-end {
  top: 0;
  right: 0;
  bottom: 0;
}

.shape-end > svg {
  width: auto;
  height: 100%;
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

/*------------------------------------
  Step
------------------------------------*/
.step {
  position: relative;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.step.step-dashed .step-icon::after {
  border-left-style: dashed;
}

.step-icon-border {
  border: 0.125rem solid rgba(33, 50, 91, 0.1);
}

.step-title {
  display: block;
  color: #1e2022;
  font-weight: 600;
}

.step-text:last-child {
  color: #677788;
  margin-bottom: 0;
}

.step-border-last-0 .step-item:last-child .step-icon::after {
  display: none;
}

.step .step-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-bottom: 2.25rem;
}

.step-item-between .step-item:last-child {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.step .step-content-wrapper {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.step .step-content {
  -ms-flex: 1;
  flex: 1;
}

.step-item.collapse:not(.show) {
  display: none;
}

.step-item .step-title-description {
  display: none;
}

.step-item.focus .step-title-description {
  display: block;
}

/*------------------------------------
  Step Avatar
------------------------------------*/
.step .step-avatar {
  font-size: 1rem;
  font-weight: 600;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.step .step-avatar-img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.step .step-avatar::after {
  position: absolute;
  top: 3.875rem;
  left: 1.5625rem;
  height: calc(100% - 2.375rem);
  border-left: 0.125rem solid rgba(33, 50, 91, 0.1);
  content: "";
}

/*------------------------------------
  Step Avatar
------------------------------------*/
.step-avatar-xs .step-avatar, .step-avatar-xs.step-avatar {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.step-avatar-xs .step-avatar::after, .step-avatar-xs.step-avatar::after {
  top: 2.5rem;
  left: 0.8125rem;
  width: 1.625rem;
  height: calc(100% - 1rem);
}

.step-avatar-xs .step-divider::after {
  left: 0.875rem;
}

.step-avatar-sm .step-avatar, .step-avatar-sm.step-avatar {
  font-size: 0.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}

.step-avatar-sm .step-avatar::after, .step-avatar-sm.step-avatar::after {
  top: 3.25rem;
  left: 1.1875rem;
  width: 1.625rem;
  height: calc(100% - 1.75rem);
}

.step-avatar-sm .step-divider::after {
  left: 1.25rem;
}

.step-avatar-lg .step-avatar, .step-avatar-lg.step-avatar {
  font-size: 1.25rem;
  width: 5.5rem;
  height: 5.5rem;
}

.step-avatar-lg .step-avatar::after, .step-avatar-lg.step-avatar::after {
  top: 6.25rem;
  left: 2.6875rem;
  width: 1.625rem;
  height: calc(100% - 4.75rem);
}

.step-avatar-lg .step-divider::after {
  left: 2.75rem;
}

/*------------------------------------
  Step Divider
------------------------------------*/
.step-divider {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  height: 1rem;
  font-size: 0.8125rem;
  font-weight: 600;
}

.step-divider::after {
  position: absolute;
  top: 1.75rem;
  left: 1.5625rem;
  height: calc(100% - 0.25rem);
  border-left: 0.125rem solid rgba(33, 50, 91, 0.1);
  content: "";
}

/*------------------------------------
  Step Icon
------------------------------------*/
.step .step-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.step .step-icon::after {
  position: absolute;
  top: 3.875rem;
  left: 1.5625rem;
  height: calc(100% - 2.375rem);
  border-left: 0.125rem solid rgba(33, 50, 91, 0.1);
  content: "";
}

.step .step-icon-pseudo::before {
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #97a4af;
  border-radius: 50%;
  content: "";
}

/*------------------------------------
  Step Icon Sizes
------------------------------------*/
.step-icon-xs .step-icon, .step-icon-xs.step-icon {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.step-icon-xs .step-icon::after, .step-icon-xs.step-icon::after {
  top: 2.5rem;
  left: 0.8125rem;
  width: 1.625rem;
  height: calc(100% - 1rem);
}

.step-icon-xs .step-divider::after {
  left: 0.875rem;
}

.step-icon-sm .step-icon, .step-icon-sm.step-icon {
  font-size: 0.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}

.step-icon-sm .step-icon::after, .step-icon-sm.step-icon::after {
  top: 3.25rem;
  left: 1.1875rem;
  width: 1.625rem;
  height: calc(100% - 1.75rem);
}

.step-icon-sm .step-divider::after {
  left: 1.25rem;
}

.step-icon-lg .step-icon, .step-icon-lg.step-icon {
  font-size: 1.25rem;
  width: 5.5rem;
  height: 5.5rem;
}

.step-icon-lg .step-icon::after, .step-icon-lg.step-icon::after {
  top: 6.25rem;
  left: 2.6875rem;
  width: 1.625rem;
  height: calc(100% - 4.75rem);
}

.step-icon-lg .step-divider::after {
  left: 2.75rem;
}

/*------------------------------------
  Step Breakpoints
------------------------------------*/
@media (min-width: 576px) {
  .step-sm.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-sm .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-sm:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-sm .step-icon {
    margin-bottom: 1rem;
  }
  .step-sm .step-icon::after {
    top: 1.5625rem;
    left: 4.625rem;
    width: calc(100% - 4.625rem);
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    border-left: none;
  }
  .step-sm.step-icon-xs .step-icon::after,
.step-sm .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 3.25rem;
    width: calc(100% - 3.25rem);
  }
  .step-sm.step-icon-sm .step-icon::after,
.step-sm .step-icon.step-icon-sm::after {
    top: 1.25rem;
    left: 4rem;
    width: calc(100% - 4rem);
  }
  .step-sm.step-icon-lg .step-icon::after,
.step-sm .step-icon.step-icon-lg::after {
    top: 2.75rem;
    left: 7rem;
    width: calc(100% - 7rem);
  }
}
@media (min-width: 768px) {
  .step-md.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-md .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-md:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-md .step-icon {
    margin-bottom: 1rem;
  }
  .step-md .step-icon::after {
    top: 1.5625rem;
    left: 4.625rem;
    width: calc(100% - 4.625rem);
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    border-left: none;
  }
  .step-md.step-icon-xs .step-icon::after,
.step-md .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 3.25rem;
    width: calc(100% - 3.25rem);
  }
  .step-md.step-icon-sm .step-icon::after,
.step-md .step-icon.step-icon-sm::after {
    top: 1.25rem;
    left: 4rem;
    width: calc(100% - 4rem);
  }
  .step-md.step-icon-lg .step-icon::after,
.step-md .step-icon.step-icon-lg::after {
    top: 2.75rem;
    left: 7rem;
    width: calc(100% - 7rem);
  }
}
@media (min-width: 992px) {
  .step-lg.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-lg .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-lg:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-lg .step-icon {
    margin-bottom: 1rem;
  }
  .step-lg .step-icon::after {
    top: 1.5625rem;
    left: 4.625rem;
    width: calc(100% - 4.625rem);
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    border-left: none;
  }
  .step-lg.step-icon-xs .step-icon::after,
.step-lg .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 3.25rem;
    width: calc(100% - 3.25rem);
  }
  .step-lg.step-icon-sm .step-icon::after,
.step-lg .step-icon.step-icon-sm::after {
    top: 1.25rem;
    left: 4rem;
    width: calc(100% - 4rem);
  }
  .step-lg.step-icon-lg .step-icon::after,
.step-lg .step-icon.step-icon-lg::after {
    top: 2.75rem;
    left: 7rem;
    width: calc(100% - 7rem);
  }
}
@media (min-width: 1200px) {
  .step-xl.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-xl .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-xl:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-xl .step-icon {
    margin-bottom: 1rem;
  }
  .step-xl .step-icon::after {
    top: 1.5625rem;
    left: 4.625rem;
    width: calc(100% - 4.625rem);
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    border-left: none;
  }
  .step-xl.step-icon-xs .step-icon::after,
.step-xl .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 3.25rem;
    width: calc(100% - 3.25rem);
  }
  .step-xl.step-icon-sm .step-icon::after,
.step-xl .step-icon.step-icon-sm::after {
    top: 1.25rem;
    left: 4rem;
    width: calc(100% - 4rem);
  }
  .step-xl.step-icon-lg .step-icon::after,
.step-xl .step-icon.step-icon-lg::after {
    top: 2.75rem;
    left: 7rem;
    width: calc(100% - 7rem);
  }
}
/*------------------------------------
  Step Centered
------------------------------------*/
@media (min-width: 576px) {
  .step-sm.step-centered {
    text-align: center;
  }
  .step-sm.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-sm.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-sm.step-centered .step-icon::after {
    width: calc(100% - 4.625rem);
    left: calc(50% + 3.0625rem);
  }
  .step-sm.step-centered.step-icon-xs .step-icon::after,
.step-sm.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 3.25rem);
    left: calc(50% + 2.375rem);
  }
  .step-sm.step-centered.step-icon-sm .step-icon::after,
.step-sm.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 4rem);
    left: calc(50% + 2.75rem);
  }
  .step-sm.step-centered.step-icon-lg .step-icon::after,
.step-sm.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 7rem);
    left: calc(50% + 4.25rem);
  }
}
@media (min-width: 768px) {
  .step-md.step-centered {
    text-align: center;
  }
  .step-md.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-md.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-md.step-centered .step-icon::after {
    width: calc(100% - 4.625rem);
    left: calc(50% + 3.0625rem);
  }
  .step-md.step-centered.step-icon-xs .step-icon::after,
.step-md.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 3.25rem);
    left: calc(50% + 2.375rem);
  }
  .step-md.step-centered.step-icon-sm .step-icon::after,
.step-md.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 4rem);
    left: calc(50% + 2.75rem);
  }
  .step-md.step-centered.step-icon-lg .step-icon::after,
.step-md.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 7rem);
    left: calc(50% + 4.25rem);
  }
}
@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 4.625rem);
    left: calc(50% + 3.0625rem);
  }
  .step-lg.step-centered.step-icon-xs .step-icon::after,
.step-lg.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 3.25rem);
    left: calc(50% + 2.375rem);
  }
  .step-lg.step-centered.step-icon-sm .step-icon::after,
.step-lg.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 4rem);
    left: calc(50% + 2.75rem);
  }
  .step-lg.step-centered.step-icon-lg .step-icon::after,
.step-lg.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 7rem);
    left: calc(50% + 4.25rem);
  }
}
@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 4.625rem);
    left: calc(50% + 3.0625rem);
  }
  .step-lg.step-centered.step-icon-xs .step-icon::after,
.step-lg.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 3.25rem);
    left: calc(50% + 2.375rem);
  }
  .step-lg.step-centered.step-icon-sm .step-icon::after,
.step-lg.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 4rem);
    left: calc(50% + 2.75rem);
  }
  .step-lg.step-centered.step-icon-lg .step-icon::after,
.step-lg.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 7rem);
    left: calc(50% + 4.25rem);
  }
}
/*------------------------------------
  Step States
------------------------------------*/
.step .step-is-valid-icon,
.step .step-is-invalid-icon {
  display: none;
}

.step .active .step-icon,
.step .active.is-valid .step-icon {
  color: #fff;
  background-color: #377dff;
}

.step .active .step-title,
.step .active.is-valid .step-title {
  color: #377dff;
}

.step .is-valid .step-icon {
  color: #fff;
  background-color: #377dff;
}

.step .is-valid .step-title {
  color: #377dff;
}

.step .is-valid .step-is-valid-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.step .is-valid .step-is-default-icon,
.step .is-valid .step-is-invalid-icon {
  display: none;
}

.step .is-invalid .step-icon {
  color: #fff;
  background-color: #ed4c78;
}

.step .is-invalid .step-title {
  color: #ed4c78;
}

.step .is-invalid .step-is-invalid-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.step .is-invalid .step-is-default-icon,
.step .is-invalid .step-is-valid-icon {
  display: none;
}

/*------------------------------------
  Step Colors
------------------------------------*/
.step-icon-primary {
  color: #fff;
  background-color: #377dff;
}

.step-icon-primary.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-secondary {
  color: #fff;
  background-color: #71869d;
}

.step-icon-secondary.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-success {
  color: #fff;
  background-color: #00c9a7;
}

.step-icon-success.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-info {
  color: #fff;
  background-color: #09a5be;
}

.step-icon-info.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-warning {
  color: #000;
  background-color: #f5ca99;
}

.step-icon-warning.step-icon-pseudo::before {
  background-color: #000;
}

.step-icon-danger {
  color: #fff;
  background-color: #ed4c78;
}

.step-icon-danger.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-light {
  color: #000;
  background-color: #f7faff;
}

.step-icon-light.step-icon-pseudo::before {
  background-color: #000;
}

.step-icon-dark {
  color: #fff;
  background-color: #21325b;
}

.step-icon-dark.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-soft-primary {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.step-icon-soft-primary.step-icon-pseudo::before {
  background-color: #377dff;
}

.step-icon-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}

.step-icon-soft-secondary.step-icon-pseudo::before {
  background-color: #71869d;
}

.step-icon-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.step-icon-soft-success.step-icon-pseudo::before {
  background-color: #00c9a7;
}

.step-icon-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

.step-icon-soft-info.step-icon-pseudo::before {
  background-color: #09a5be;
}

.step-icon-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.step-icon-soft-warning.step-icon-pseudo::before {
  background-color: #f5ca99;
}

.step-icon-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.step-icon-soft-danger.step-icon-pseudo::before {
  background-color: #ed4c78;
}

.step-icon-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
}

.step-icon-soft-light.step-icon-pseudo::before {
  background-color: #f7faff;
}

.step-icon-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

.step-icon-soft-dark.step-icon-pseudo::before {
  background-color: #21325b;
}

/*------------------------------------
  Step Inline
------------------------------------*/
.step-inline .step-content-wrapper {
  -ms-flex-align: center;
  align-items: center;
}

.step-inline .step-item:last-child .step-title::after {
  display: none;
}

.step-inline .step-title {
  display: inline-block;
}

@media (min-width: 576px) {
  .step-sm.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-sm.step-inline .step-item {
    overflow: hidden;
  }
  .step-sm.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-sm.step-inline .step-icon::after {
    display: none;
  }
  .step-sm.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    margin-left: 1.5rem;
    content: "";
  }
  .step-sm.step-inline.step-icon-xs .step-content .step-title::after,
.step-sm.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-sm.step-inline.step-icon-sm .step-content .step-title::after,
.step-sm.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.25rem;
  }
  .step-sm.step-inline.step-icon-lg .step-content .step-title::after,
.step-sm.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.75rem;
  }
}
@media (min-width: 768px) {
  .step-md.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-md.step-inline .step-item {
    overflow: hidden;
  }
  .step-md.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-md.step-inline .step-icon::after {
    display: none;
  }
  .step-md.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    margin-left: 1.5rem;
    content: "";
  }
  .step-md.step-inline.step-icon-xs .step-content .step-title::after,
.step-md.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-md.step-inline.step-icon-sm .step-content .step-title::after,
.step-md.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.25rem;
  }
  .step-md.step-inline.step-icon-lg .step-content .step-title::after,
.step-md.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.75rem;
  }
}
@media (min-width: 992px) {
  .step-lg.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-lg.step-inline .step-item {
    overflow: hidden;
  }
  .step-lg.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-lg.step-inline .step-icon::after {
    display: none;
  }
  .step-lg.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    margin-left: 1.5rem;
    content: "";
  }
  .step-lg.step-inline.step-icon-xs .step-content .step-title::after,
.step-lg.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-lg.step-inline.step-icon-sm .step-content .step-title::after,
.step-lg.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.25rem;
  }
  .step-lg.step-inline.step-icon-lg .step-content .step-title::after,
.step-lg.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.75rem;
  }
}
@media (min-width: 1200px) {
  .step-xl.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-xl.step-inline .step-item {
    overflow: hidden;
  }
  .step-xl.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-xl.step-inline .step-icon::after {
    display: none;
  }
  .step-xl.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    margin-left: 1.5rem;
    content: "";
  }
  .step-xl.step-inline.step-icon-xs .step-content .step-title::after,
.step-xl.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-xl.step-inline.step-icon-sm .step-content .step-title::after,
.step-xl.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.25rem;
  }
  .step-xl.step-inline.step-icon-lg .step-content .step-title::after,
.step-xl.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.75rem;
  }
}
/*------------------------------------
  Step Timeline
------------------------------------*/
@media (min-width: 576px) {
  .step-timeline-sm {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-sm .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.25rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.75rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-sm .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-sm .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-sm .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-sm .step-icon-sm {
    margin-left: -1.25rem;
  }
  .step-timeline-sm .step-icon-lg {
    margin-left: -2.75rem;
  }
}
@media (min-width: 768px) {
  .step-timeline-md {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-md .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.25rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.75rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-md .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-md .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-md .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-md .step-icon-sm {
    margin-left: -1.25rem;
  }
  .step-timeline-md .step-icon-lg {
    margin-left: -2.75rem;
  }
}
@media (min-width: 992px) {
  .step-timeline-lg {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-lg .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.25rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.75rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-lg .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-lg .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-lg .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-lg .step-icon-sm {
    margin-left: -1.25rem;
  }
  .step-timeline-lg .step-icon-lg {
    margin-left: -2.75rem;
  }
}
@media (min-width: 1200px) {
  .step-timeline-xl {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-xl .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.25rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.75rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-xl .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-xl .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-xl .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-xl .step-icon-sm {
    margin-left: -1.25rem;
  }
  .step-timeline-xl .step-icon-lg {
    margin-left: -2.75rem;
  }
}
/*------------------------------------
  SVG Icon
------------------------------------*/
.svg-icon {
  display: block;
  margin-top: 0.125rem;
}

.svg-icon[class*=text-] > svg [fill]:not([fill=none]) {
  fill: currentColor !important;
}

.svg-inline {
  display: inline-block;
}

.svg-icon > svg {
  width: 2.75rem;
  height: 2.75rem;
}

.svg-icon-xs > svg {
  width: 1.25rem;
  height: 1.25rem;
}

.svg-icon-sm > svg {
  width: 1.75rem;
  height: 1.75rem;
}

.svg-icon-lg > svg {
  width: 3.5rem;
  height: 3.5rem;
}

/*------------------------------------
  Toasts
------------------------------------*/
.toast-header .btn-close {
  margin-right: 0;
}

/*------------------------------------
  Tables
------------------------------------*/
.table th {
  font-weight: 400;
}

.table tr {
  color: #21325b;
}

.table thead th {
  color: #1e2022;
  font-weight: 600;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(33, 50, 91, 0.1);
}

.table > :not(caption) > *:last-child > * {
  border-bottom-width: 0;
}

.table .btn {
  white-space: nowrap;
}

.table-nowrap th, .table-nowrap td {
  white-space: nowrap;
}

.table-align-middle tbody tr, .table-align-middle th, .table-align-middle td {
  vertical-align: middle;
}

.table-text-center, .table-text-center th, .table-text-center td {
  text-align: center;
}

.table-text-end, .table-text-end th, .table-text-end td {
  text-align: right;
}

.thead-light th {
  background-color: #f8fafd;
}

.table-lg > :not(caption) > * > * {
  padding: 1rem 1.5rem;
}

.table-thead-bordered > :not(:last-child) > :last-child > * {
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.table-vertical-border-striped > thead > tr > th:not(:first-child):not(:last-child),
.table-vertical-border-striped > tbody > tr > td:not(:first-child):not(:last-child) {
  border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Text Highlight
------------------------------------*/
[class*=text-highlight-] {
  background: left 1em/1em 0.2em;
  background-repeat: repeat-x;
}

.text-highlight-primary {
  background-image: linear-gradient(to bottom, rgba(55, 125, 255, 0.5), rgba(55, 125, 255, 0.5));
}

.text-highlight-secondary {
  background-image: linear-gradient(to bottom, rgba(113, 134, 157, 0.5), rgba(113, 134, 157, 0.5));
}

.text-highlight-success {
  background-image: linear-gradient(to bottom, rgba(0, 201, 167, 0.5), rgba(0, 201, 167, 0.5));
}

.text-highlight-info {
  background-image: linear-gradient(to bottom, rgba(9, 165, 190, 0.5), rgba(9, 165, 190, 0.5));
}

.text-highlight-warning {
  background-image: linear-gradient(to bottom, rgba(245, 202, 153, 0.5), rgba(245, 202, 153, 0.5));
}

.text-highlight-danger {
  background-image: linear-gradient(to bottom, rgba(237, 76, 120, 0.5), rgba(237, 76, 120, 0.5));
}

.text-highlight-light {
  background-image: linear-gradient(to bottom, rgba(247, 250, 255, 0.5), rgba(247, 250, 255, 0.5));
}

.text-highlight-dark {
  background-image: linear-gradient(to bottom, rgba(33, 50, 91, 0.5), rgba(33, 50, 91, 0.5));
}

/*------------------------------------
  Video Player
------------------------------------*/
.video-player {
  position: relative;
  display: inline-block;
}

.video-player-inline-btn {
  display: block;
  background-color: #000;
}

.video-player-preview {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 1;
  -o-object-fit: cover;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.video-player-played .video-player-preview {
  opacity: 0;
  pointer-events: none;
}

.video-player-btn {
  z-index: 3;
  color: #1e2022;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.video-player-played .video-player-btn {
  -webkit-animation: videoPlayerButton 0.3s ease-in-out forwards;
  animation: videoPlayerButton 0.3s ease-in-out forwards;
  pointer-events: none;
}

.video-player-btn:hover .video-player-icon {
  color: #377dff;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.video-player-icon {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 4.25rem;
  height: 4.25rem;
  font-size: 1.25rem;
  border-radius: 50%;
  color: #1e2022;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  transition: 0.3s;
}

.video-player-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@-webkit-keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.3);
    transform: translate(-50%, -50%) scale(1.3);
  }
}
@keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.3);
    transform: translate(-50%, -50%) scale(1.3);
  }
}
/*------------------------------------
  Video Bg
------------------------------------*/
.video-bg {
  position: relative;
  min-height: 75vh;
}

.video-bg-content {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-bg-replacer-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

@media (min-width: 768px) {
  .video-bg-replacer-img {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .video-bg-content {
    display: none;
  }
}
.hs-video-bg-video iframe,
.hs-video-bg-video video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/*------------------------------------
  Background Image Style
------------------------------------*/
.bg-img-start {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.bg-img-end {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.bg-img-center {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/*------------------------------------
  Background Gradients
------------------------------------*/
.gradient-y-sm-primary {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(55, 125, 255, 0.075));
}

.gradient-y-gray {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(231, 234, 243, 0.5));
}

.gradient-y-lg-white {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
}

.gradient-y-three-sm-primary {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(55, 125, 255, 0.075) 50%, rgba(255, 255, 255, 0));
}

.gradient-x-three-sm-primary {
  background-image: linear-gradient(to right, rgba(55, 125, 255, 0.03), rgba(9, 165, 190, 0.05) 50%, rgba(245, 202, 153, 0.05));
}

/*------------------------------------
  Background Overlay Gradients
------------------------------------*/
[class*=gradient-x-overlay-],
[class*=gradient-y-overlay-],
[class*=gradient-y-three-overlay-] {
  position: relative;
  z-index: 1;
}

[class*=gradient-x-overlay-]::before,
[class*=gradient-y-overlay-]::before,
[class*=gradient-y-three-overlay-]::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
}

[class*=gradient-x-overlay-].card::before,
[class*=gradient-y-overlay-].card::before,
[class*=gradient-y-three-overlay-].card::before {
  border-radius: 0.5rem;
}

.gradient-x-overlay-lg-dark-video::before {
  z-index: 2;
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.95) 0%, rgba(33, 50, 91, 0.95) 100%);
}

.gradient-x-overlay-sm-primary::before {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(55, 125, 255, 0.05));
}

.gradient-x-overlay-sm-dark::before {
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.25) 0%, rgba(30, 32, 34, 0.25) 100%);
}

.gradient-x-overlay-lg-dark::before {
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.9) 0%, rgba(33, 50, 91, 0.9) 100%);
}

.gradient-y-overlay-lg-dark::before {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 40%, rgba(12, 13, 14, 0.6));
}

.gradient-y-overlay-lg-white::before {
  background-image: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0.9));
}

.gradient-y-overlay-sm-gray-900::before {
  background-image: linear-gradient(to bottom, rgba(30, 32, 34, 0.3), rgba(33, 50, 91, 0.2));
}

/*------------------------------------
  Link
------------------------------------*/
.link {
  font-weight: 600;
}

.link-primary:hover, .link-primary:focus {
  color: #1366ff;
}

.link-secondary:hover, .link-secondary:focus {
  color: #1366ff;
}

.link-success:hover, .link-success:focus {
  color: #1366ff;
}

.link-info:hover, .link-info:focus {
  color: #1366ff;
}

.link-warning:hover, .link-warning:focus {
  color: #1366ff;
}

.link-danger:hover, .link-danger:focus {
  color: #1366ff;
}

.link-light:hover, .link-light:focus {
  color: rgba(255, 255, 255, 0.7);
}

.link-dark:hover, .link-dark:focus {
  color: #1366ff;
}

.link-sm {
  font-size: 0.875rem;
}

.link-collapse[aria-expanded=false] .link-collapse-default {
  display: inline-block;
}

.link-collapse[aria-expanded=false] .link-collapse-active {
  display: none;
}

.link-collapse[aria-expanded=true] .link-collapse-default {
  display: none;
}

.link-collapse[aria-expanded=true] .link-collapse-active {
  display: inline-block;
}

/*------------------------------------
  Typography
------------------------------------*/
.text-cap {
  display: block;
  color: #1e2022;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

/*------------------------------------
  Text Colors
------------------------------------*/
.text-dark {
  color: #1e2022 !important;
}

.text-dark[href]:hover {
  color: #1366ff !important;
}

.link-secondary[href]:hover,
.text-secondary[href]:hover,
.text-muted[href]:hover,
.text-body[href]:hover {
  color: #1366ff !important;
}

a:hover .text-inherit {
  color: #1366ff !important;
}

/*------------------------------------
  Transform
------------------------------------*/
.transform-rotate-n40deg {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  -webkit-transform-origin: 30% 20%;
  transform-origin: 30% 20%;
}

.transform-rotate-n22deg {
  -webkit-transform: rotate(-22deg);
  transform: rotate(-22deg);
}

.rotated-3d-left {
  -webkit-transform: perspective(1140px) rotateX(7deg) rotateY(-17deg) rotateZ(4deg);
  transform: perspective(1140px) rotateX(7deg) rotateY(-17deg) rotateZ(4deg);
}

.rotated-3d-right {
  -webkit-transform: perspective(1140px) rotateX(7deg) rotateY(17deg) rotateZ(-4deg);
  transform: perspective(1140px) rotateX(7deg) rotateY(17deg) rotateZ(-4deg);
}

/*------------------------------------
  Divider
------------------------------------*/
.divider-start {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #8c98a4;
}

.divider-start::after {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  margin-top: 0.0625rem;
  content: "";
}

.divider-start::after {
  margin-left: 1.5rem;
}

.divider-end {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #8c98a4;
}

.divider-end::before {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  margin-top: 0.0625rem;
  content: "";
}

.divider-end::before {
  margin-right: 1.5rem;
}

.divider-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #8c98a4;
}

.divider-center::before, .divider-center::after {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  margin-top: 0.0625rem;
  content: "";
}

.divider-center::before {
  margin-right: 1.5rem;
}

.divider-center::after {
  margin-left: 1.5rem;
}

/*------------------------------------
  Nav Scroll Horizontal
------------------------------------*/
.hs-nav-scroller-horizontal {
  position: relative;
}

.hs-nav-scroller-horizontal .nav {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  white-space: nowrap;
  scroll-behavior: smooth;
}

.hs-nav-scroller-horizontal .nav .nav-item {
  white-space: nowrap;
}

.hs-nav-scroller-horizontal .nav .nav-link {
  white-space: nowrap;
}

.hs-nav-scroller-horizontal .nav::-webkit-scrollbar {
  display: none;
}

.hs-nav-scroller-horizontal .nav-tabs {
  padding-bottom: 0.1875rem;
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev,
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next {
  position: absolute;
  height: 100%;
  z-index: 1;
  font-size: 1.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-link {
  width: 3.125rem;
  color: #677788;
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-link:hover {
  color: #1366ff;
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev {
  left: 0;
  margin-left: -0.125rem;
  background-image: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, 0) 100%);
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev .hs-nav-scroller-arrow-link {
  padding: 0.5rem 0;
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next {
  right: 0;
  margin-right: -0.125rem;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 50%);
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next .hs-nav-scroller-arrow-link {
  padding: 0.5rem 0;
  text-align: right;
}

.hs-nav-scroller-light .hs-nav-scroller-arrow-prev .hs-nav-scroller-arrow-link,
.hs-nav-scroller-light .hs-nav-scroller-arrow-next .hs-nav-scroller-arrow-link {
  color: rgba(255, 255, 255, 0.7);
}

.hs-nav-scroller-light .hs-nav-scroller-arrow-prev .hs-nav-scroller-arrow-link:hover,
.hs-nav-scroller-light .hs-nav-scroller-arrow-next .hs-nav-scroller-arrow-link:hover {
  color: #fff;
}

.hs-nav-scroller-light .hs-nav-scroller-arrow-prev {
  background-image: linear-gradient(to right, #21325b 50%, rgba(255, 255, 255, 0) 100%);
}

.hs-nav-scroller-light .hs-nav-scroller-arrow-next {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #21325b 50%);
}

.hs-nav-scroller-vertical {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.hs-nav-scroller-vertical::-webkit-scrollbar {
  width: 0.6125rem;
}

.hs-nav-scroller-vertical::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.hs-nav-scroller-unfold {
  position: static;
}

/*------------------------------------
  Dropzone
------------------------------------*/
.dz-dropzone {
  cursor: pointer;
}

.dz-dropzone .dz-message {
  width: 100%;
  text-align: center;
}

.dz-dropzone .dz-details {
  margin-bottom: 1rem;
}

.dz-dropzone .dz-file-preview {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}

.dz-dropzone .dz-file-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dz-dropzone .dz-filename {
  margin-bottom: 0.25rem;
}

.dz-dropzone .dz-img {
  max-width: 3.125rem;
  border-radius: 0.5rem;
  margin-right: 0.75rem;
}

.dz-dropzone .dz-img-inner {
  border-radius: 0.5rem;
}

.dz-dropzone .dz-close-icon,
.dz-dropzone .dz-size {
  color: #677788;
}

.dz-dropzone .dz-title {
  font-size: 0.75rem;
  font-weight: 600;
}

.dz-dropzone .dz-size {
  font-size: 80%;
}

.dz-dropzone .dz-file-initials {
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  line-height: 3.125rem;
  font-weight: 600;
  font-size: 1rem;
  color: #377dff;
  text-align: center;
  background-color: rgba(55, 125, 255, 0.1);
  border-radius: 0.5rem;
  margin-right: 0.75rem;
}

.dz-dropzone [data-dz-thumbnail]:not([src]) {
  display: none;
  margin-bottom: 0;
}

.dz-dropzone .dz-progress {
  margin-bottom: 1rem;
}

.dz-dropzone .dz-processing .dz-error-mark,
.dz-dropzone .dz-processing .dz-success-mark {
  display: none;
}

.dz-dropzone .dz-processing.dz-error .dz-error-mark,
.dz-dropzone .dz-processing.dz-success .dz-success-mark {
  display: block;
}

.dz-dropzone .dz-processing .dz-error-mark {
  color: #ed4c78;
}

.dz-dropzone .dz-processing .dz-success-mark {
  color: #00c9a7;
}

.dz-dropzone-card {
  width: 100%;
  background-color: #f8fafd;
  border: 0.125rem dashed rgba(33, 50, 91, 0.1);
  border-radius: 0.5rem;
  padding: 3rem 3rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/
.fancybox-custom .fancybox-slide.animated {
  display: block;
  opacity: 0;
  z-index: 0;
}

.fancybox-custom .fancybox-slide.animated.fancybox-slide--current {
  opacity: 1;
  z-index: 1;
}

.fancybox-custom .fancybox-content {
  background-color: transparent;
}

.fancybox-custom .fancybox-bg {
  background-color: #1e2022;
}

.fancybox-custom .fancybox-button svg {
  margin-bottom: 0;
}

.fancybox-custom .fancybox-progress {
  background-color: #377dff;
}

.fancybox-blur header,
.fancybox-blur aside,
.fancybox-blur main,
.fancybox-blur footer {
  -webkit-filter: blur(30px);
  filter: blur(30px);
}

/*------------------------------------
  Quantity Counter
------------------------------------*/
.quantity-counter {
  padding: 0.5rem 1rem;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
}

.quantity-counter-btn.btn > svg {
  width: 1.25rem;
}

.form-control-quantity-counter {
  border-width: 0;
  height: auto;
  padding: 0;
}

.form-control-quantity-counter:focus {
  box-shadow: none;
}

/*------------------------------------
  Quill Editor
------------------------------------*/
.quill-custom .ql-container {
  position: static;
}

.quill-custom .ql-toolbar.ql-snow,
.quill-custom .ql-container.ql-snow {
  border-color: rgba(33, 50, 91, 0.1);
}

.quill-custom .ql-toolbar.ql-snow {
  padding: 0.75rem 1rem;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.quill-custom .ql-container.ql-snow {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.quill-custom .ql-formats:first-child {
  padding-left: 0;
}

.quill-custom .ql-formats:first-child button {
  margin-right: 0.5rem;
}

.quill-custom .ql-formats:first-child button:first-child {
  margin-left: -0.25rem;
}

.quill-custom .ql-editor {
  position: relative;
  padding: 0.6125rem 1rem;
}

.quill-custom .ql-editor p {
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  color: #1e2022;
}

.quill-custom .ql-editor.ql-blank::before {
  left: 1rem;
  color: #8c98a4;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

.quill-custom .ql-snow.ql-toolbar .ql-fill {
  fill: #677788;
}

.quill-custom .ql-snow.ql-toolbar .ql-stroke {
  stroke: #677788;
}

.quill-custom .ql-snow.ql-toolbar button:hover {
  color: #377dff;
}

.quill-custom .ql-snow.ql-toolbar button:hover .ql-fill {
  fill: #377dff;
}

.quill-custom .ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: #377dff;
}

.quill-custom .ql-snow.ql-toolbar button,
.quill-custom .ql-snow .ql-toolbar button {
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.25rem 0.25rem;
}

.quill-custom .ql-snow.ql-toolbar button svg,
.quill-custom .ql-snow .ql-toolbar button svg {
  margin-bottom: 0;
}

.quill-custom .ql-snow .ql-tooltip {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  z-index: 1;
  min-width: 20rem;
  border-width: 0;
  text-align: center;
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  padding: 1.5rem 1.5rem;
  border-radius: 0.3125rem;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.quill-custom .ql-snow .ql-tooltip::before {
  display: block;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  margin-right: 0;
}

.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.quill-custom .ql-snow .ql-tooltip a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type=text] {
  min-width: 20rem;
  font-size: 1rem;
  line-height: normal;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding: 0.6125rem 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}

.quill-custom .ql-snow .ql-tooltip a.ql-preview {
  display: block;
}

.quill-custom .ql-snow .ql-tooltip a.ql-preview:hover {
  border-color: #377dff;
}

.quill-custom .ql-snow .ql-tooltip.ql-editing input[type=text] {
  height: auto;
  display: block;
  font-family: "Inter", sans-serif;
}

.quill-custom .ql-snow .ql-tooltip.ql-editing input[type=text]:focus {
  border-color: rgba(140, 152, 164, 0.25);
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}

.quill-custom .ql-snow .ql-action {
  display: inline-block;
  color: #fff;
  background-color: #377dff;
  font-size: 1rem;
  line-height: normal;
  padding: 0.6125rem 1rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}

.quill-custom .ql-snow .ql-action:hover {
  background-color: #1366ff;
}

.quill-custom .ql-snow .ql-tooltip a.ql-remove::before,
.quill-custom .ql-snow .ql-tooltip a.ql-action::after {
  padding-right: 0;
  margin-left: 0;
  border-right: none;
}

.quill-custom .ql-snow .ql-tooltip a.ql-remove {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  font-size: 1rem;
  padding: 0.6125rem 1rem;
  border-radius: 0.3125rem;
  margin-left: 0.5rem;
  transition: 0.3s;
}

.quill-custom .ql-snow .ql-tooltip a.ql-remove:hover {
  color: #1366ff;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}

/*------------------------------------
  Select
------------------------------------*/
.tom-select-custom .input-group-sm > .ts-control .ts-input.has-items,
.tom-select-custom .ts-control.form-select-sm .ts-input.has-items,
.tom-select-custom .ts-control.form-control-sm .ts-input.has-items {
  padding: 0.5rem 3rem 0.5rem 1rem;
}

.tom-select-custom .input-group-lg > .ts-control > .ts-input,
.tom-select-custom .ts-control.form-control-lg .ts-input,
.tom-select-custom .ts-control.form-select-lg .ts-input {
  padding: 0.75rem 3rem 0.75rem 1rem;
}

.tom-select-custom .hs-select-single-multiple .item {
  display: none !important;
}

.tom-select-custom .ts-control.form-select .ts-input,
.tom-select-custom .ts-control.multi .ts-input.has-items.hs-select-single-multiple {
  padding: 0.6125rem 3rem 0.6125rem 1rem;
}

.tom-select-custom .ts-control.form-select .ts-input .tom-select-custom-hide,
.tom-select-custom .ts-control.multi .ts-input.has-items.hs-select-single-multiple .tom-select-custom-hide {
  display: none;
}

.tom-select-custom .ts-control.form-select .ts-input:focus,
.tom-select-custom .ts-control.form-select .ts-input .focus,
.tom-select-custom .ts-control.multi .ts-input.has-items.hs-select-single-multiple:focus,
.tom-select-custom .ts-control.multi .ts-input.has-items.hs-select-single-multiple .focus {
  box-shadow: none;
}

.tom-select-custom .ts-dropdown,
.tom-select-custom .ts-dropdown.form-control,
.tom-select-custom .ts-dropdown.form-select {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.tom-select-custom .ts-dropdown .create:hover,
.tom-select-custom .ts-dropdown .option:hover,
.tom-select-custom .ts-dropdown .active {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}

.tom-select-custom .ts-dropdown .option,
.tom-select-custom .ts-dropdown .optgroup-header,
.tom-select-custom .ts-dropdown .no-results,
.tom-select-custom .ts-dropdown .create {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
}

.tom-select-custom .ts-dropdown .option {
  position: relative;
}

.tom-select-custom .ts-dropdown .option::after {
  display: none;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 110 110' fill='%23377dff'%3e%3cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1rem 1rem;
  content: "";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.tom-select-custom .ts-dropdown .option.selected::after {
  display: block;
}

.tom-select-custom .ts-input,
.tom-select-custom .ts-control.single .ts-input.input-active {
  cursor: pointer;
}

.tom-select-custom .input-group > .ts-control,
.tom-select-custom .input-group > .form-select {
  -ms-flex-positive: unset;
  flex-grow: unset;
}

.tom-select-custom .input-group > .form-select {
  width: auto;
}

.tom-select-custom .ts-control {
  display: inline-block;
}

.tom-select-custom .ts-dropdown .optgroup-header {
  font-weight: bold;
  color: #000;
}

.tom-select-custom .ts-dropdown .optgroup:before {
  display: none;
}

.tom-select-custom .ts-dropdown .optgroup .option {
  padding-left: 1.5rem;
}

.tom-select-custom .ts-control.multi .ts-input > div {
  background-color: #e7eaf3;
  font-size: 0.875rem;
  padding: 0.28rem 0.875rem;
  border-radius: 4px;
}

.tom-select-custom .ts-control.plugin-remove_button .item .remove {
  border-left: none;
  color: #71869d;
}

.tom-select-custom .ts-control.plugin-remove_button .item .remove:hover {
  background: transparent;
  color: #333;
}

.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap {
  padding: 0.4rem;
}

.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap .dropdown-input {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
}

.tom-select-custom-with-tags .ts-control.form-select .ts-input,
.tom-select-custom-with-tags .tom-select-custom .ts-control.multi .ts-input.has-items.hs-select-single-multiple {
  padding: calc(0.375rem - 1px - 0px) 0.75rem calc(0.375rem - 1px - 4px - 0px);
}

.tom-select-custom-with-tags .ts-control.multi .ts-input > .item {
  color: #1e2022;
  background-color: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Swiper
------------------------------------*/
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  z-index: 1;
}

.swiper-button-next,
.swiper-button-prev {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #677788;
  width: 3.125rem;
  height: 3.125rem;
  background-color: #fff;
  box-shadow: 0rem 0.1875rem 0.4375rem 0rem rgba(140, 152, 164, 0.4);
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  width: 1rem;
  height: 1rem;
  font-family: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  content: "";
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.5;
  box-shadow: none;
}

.swiper-button-next:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23677788'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.swiper-button-next:hover:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23377dff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.swiper-button-prev:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23677788'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.swiper-button-prev:hover:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23377dff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.swiper-button-next-soft-white,
.swiper-button-prev-soft-white {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.swiper-button-next-soft-white:hover,
.swiper-button-prev-soft-white:hover {
  background-color: #377dff;
}

.swiper-button-next-soft-white:after, .swiper-button-next-soft-white:hover:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.swiper-button-prev-soft-white:after, .swiper-button-prev-soft-white:hover:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.swiper-pagination {
  position: static;
  width: 100% !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 2rem;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 0%;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0.25rem;
}

.swiper-pagination-bullet {
  position: relative;
  opacity: 1;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.0625rem solid transparent;
  background-color: transparent;
  transition: 0.2s;
}

.swiper-pagination-bullet::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #bdc5d1;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.2s;
  content: "";
}

.swiper-pagination-bullet:hover {
  border-color: rgba(55, 125, 255, 0.5);
}

.swiper-pagination-bullet:hover::before {
  background-color: rgba(55, 125, 255, 0.5);
}

.swiper-pagination-bullet-active, .swiper-pagination-bullet-active:hover {
  border-color: #377dff;
}

.swiper-pagination-bullet-active::before, .swiper-pagination-bullet-active:hover::before {
  background-color: #377dff;
}

.swiper-pagination-light .swiper-pagination-bullet::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-light .swiper-pagination-bullet:hover {
  border-color: #fff;
}

.swiper-pagination-light .swiper-pagination-bullet:hover::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-light .swiper-pagination-bullet-active, .swiper-pagination-light .swiper-pagination-bullet-active:hover {
  border-color: #fff;
}

.swiper-pagination-light .swiper-pagination-bullet-active::before, .swiper-pagination-light .swiper-pagination-bullet-active:hover::before {
  background-color: #fff;
}

.swiper-pagination-progress {
  cursor: pointer;
}

.swiper-pagination-progress-body {
  position: relative;
  display: block;
  width: 100%;
  height: 0.25rem;
  background-color: rgba(55, 125, 255, 0.1);
}

.swiper-pagination-progress-body-helper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  background-color: #377dff;
  transition: none;
}

.swiper-slide-thumb-active .swiper-pagination-progress-body-helper {
  transition-property: width;
  transition-timing-function: linear;
  width: 100%;
}

.swiper-pagination-progress-light .swiper-pagination-progress-body {
  background-color: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress-light .swiper-pagination-progress-body-helper {
  background-color: #fff;
}

.swiper-pagination-progressbar {
  background-color: rgba(55, 125, 255, 0.1);
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #377dff;
}

.swiper-pagination-fraction {
  display: block;
  letter-spacing: 0.125rem;
}

.swiper-pagination-fraction .swiper-pagination-current {
  font-size: 4rem;
  line-height: 4rem;
}

.swiper-thumbs {
  box-sizing: border-box;
}

.swiper-thumbs .swiper-slide {
  cursor: pointer;
  opacity: 0.4;
}

.swiper-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-equal-height .swiper-wrapper {
  display: -ms-flexbox;
  display: flex;
}

.swiper-equal-height .swiper-slide {
  display: -ms-flexbox;
  display: flex;
  height: auto;
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-container-vertical > .swiper-pagination-bullets {
  position: absolute;
  width: auto !important;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 0;
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0.25rem 0;
}

.swiper-container-horizontal > .swiper-pagination-middle-y-end {
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.swiper-center-mode-end {
  margin-right: calc(-1px - (100vw - 100%) / 2 + 15px) !important;
}

.swiper-thumb-progress {
  width: 110%;
  height: 110%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.swiper-thumb-progress-avatar {
  position: relative;
  display: block;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding: 0.25rem;
  margin: 0.25rem;
  border-radius: 50%;
}

.swiper-thumb-progress-avatar-img {
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.swiper-thumb-progress .swiper-thumb-progress-path {
  opacity: 0;
  fill: transparent;
  stroke: #377dff;
  stroke-width: 8;
  stroke-dashoffset: 477;
  stroke-dashoffset: 0px;
}

.swiper-thumb-progress .swiper-thumb-progress-path {
  opacity: 0;
  fill: transparent;
  stroke: #377dff;
  stroke-width: 8;
  stroke-dashoffset: 477;
  stroke-dashoffset: 0px;
}

@-webkit-keyframes swiperThumbProgressDash {
  from {
    stroke-dasharray: 0 477;
  }
  to {
    stroke-dasharray: 477 477;
  }
}
@keyframes swiperThumbProgressDash {
  from {
    stroke-dasharray: 0 477;
  }
  to {
    stroke-dasharray: 477 477;
  }
}
/*------------------------------------
  Leaflet
------------------------------------*/
.leaflet {
  min-height: 30rem;
  height: 100%;
  z-index: 0;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-bar,
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}

.leaflet-bar,
.leaflet-popup-content-wrapper {
  border-radius: 0.5rem;
}

.leaflet-popup {
  margin-bottom: 2.5rem;
}

/*------------------------------------
  NoUiSlider
------------------------------------*/
.range-slider.range-slider-pips {
  min-height: 3rem;
}

.range-slider .noUi-target {
  border: none;
  box-shadow: none;
  background: #e7eaf3;
  margin-top: 1.25rem;
}

.range-slider .noUi-connect {
  background-color: #377dff;
}

.range-slider .noUi-horizontal {
  height: 0.3125rem;
}

.range-slider .noUi-horizontal .noUi-handle {
  top: -0.75rem;
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
}

.range-slider .noUi-handle {
  border: none;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
  border-radius: 50%;
}

.range-slider .noUi-handle:before, .range-slider .noUi-handle:after {
  display: none;
}

.range-slider .noUi-horizontal .noUi-tooltip {
  bottom: 130%;
}

.range-slider .noUi-tooltip {
  border: none;
  font-size: 0.8125rem;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
  min-width: 2.5rem;
  padding: 0.5rem 0.5rem;
}

.range-slider .noUi-tooltip::after {
  position: absolute;
  display: block;
  bottom: -0.625rem;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -0.3125rem;
  overflow: hidden;
  border: 0.3125rem solid transparent;
  border-top-color: #fff;
  content: "";
}

.range-slider .noUi-marker {
  width: 0.0625rem;
  background: #bdc5d1;
}

.range-slider .noUi-pips {
  color: #677788;
}

.range-slider .noUi-value-large {
  color: #677788;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.is-primary {
  color: #3699FF;
}

.bg-is-primary {
  background-color: #3699FF;
}

.bg-is-primary-0 {
  background-color: rgba(54, 153, 255, 0);
}

.bg-is-primary-1 {
  background-color: rgba(54, 153, 255, 0.01);
}

.bg-is-primary-2 {
  background-color: rgba(54, 153, 255, 0.02);
}

.bg-is-primary-3 {
  background-color: rgba(54, 153, 255, 0.03);
}

.bg-is-primary-4 {
  background-color: rgba(54, 153, 255, 0.04);
}

.bg-is-primary-5 {
  background-color: rgba(54, 153, 255, 0.05);
}

.bg-is-primary-6 {
  background-color: rgba(54, 153, 255, 0.06);
}

.bg-is-primary-7 {
  background-color: rgba(54, 153, 255, 0.07);
}

.bg-is-primary-8 {
  background-color: rgba(54, 153, 255, 0.08);
}

.bg-is-primary-9 {
  background-color: rgba(54, 153, 255, 0.09);
}

.bg-is-primary-10 {
  background-color: rgba(54, 153, 255, 0.1);
}

.bg-is-primary-11 {
  background-color: rgba(54, 153, 255, 0.11);
}

.bg-is-primary-12 {
  background-color: rgba(54, 153, 255, 0.12);
}

.bg-is-primary-13 {
  background-color: rgba(54, 153, 255, 0.13);
}

.bg-is-primary-14 {
  background-color: rgba(54, 153, 255, 0.14);
}

.bg-is-primary-15 {
  background-color: rgba(54, 153, 255, 0.15);
}

.bg-is-primary-16 {
  background-color: rgba(54, 153, 255, 0.16);
}

.bg-is-primary-17 {
  background-color: rgba(54, 153, 255, 0.17);
}

.bg-is-primary-18 {
  background-color: rgba(54, 153, 255, 0.18);
}

.bg-is-primary-19 {
  background-color: rgba(54, 153, 255, 0.19);
}

.bg-is-primary-20 {
  background-color: rgba(54, 153, 255, 0.2);
}

.bg-is-primary-21 {
  background-color: rgba(54, 153, 255, 0.21);
}

.bg-is-primary-22 {
  background-color: rgba(54, 153, 255, 0.22);
}

.bg-is-primary-23 {
  background-color: rgba(54, 153, 255, 0.23);
}

.bg-is-primary-24 {
  background-color: rgba(54, 153, 255, 0.24);
}

.bg-is-primary-25 {
  background-color: rgba(54, 153, 255, 0.25);
}

.bg-is-primary-26 {
  background-color: rgba(54, 153, 255, 0.26);
}

.bg-is-primary-27 {
  background-color: rgba(54, 153, 255, 0.27);
}

.bg-is-primary-28 {
  background-color: rgba(54, 153, 255, 0.28);
}

.bg-is-primary-29 {
  background-color: rgba(54, 153, 255, 0.29);
}

.bg-is-primary-30 {
  background-color: rgba(54, 153, 255, 0.3);
}

.bg-is-primary-31 {
  background-color: rgba(54, 153, 255, 0.31);
}

.bg-is-primary-32 {
  background-color: rgba(54, 153, 255, 0.32);
}

.bg-is-primary-33 {
  background-color: rgba(54, 153, 255, 0.33);
}

.bg-is-primary-34 {
  background-color: rgba(54, 153, 255, 0.34);
}

.bg-is-primary-35 {
  background-color: rgba(54, 153, 255, 0.35);
}

.bg-is-primary-36 {
  background-color: rgba(54, 153, 255, 0.36);
}

.bg-is-primary-37 {
  background-color: rgba(54, 153, 255, 0.37);
}

.bg-is-primary-38 {
  background-color: rgba(54, 153, 255, 0.38);
}

.bg-is-primary-39 {
  background-color: rgba(54, 153, 255, 0.39);
}

.bg-is-primary-40 {
  background-color: rgba(54, 153, 255, 0.4);
}

.bg-is-primary-41 {
  background-color: rgba(54, 153, 255, 0.41);
}

.bg-is-primary-42 {
  background-color: rgba(54, 153, 255, 0.42);
}

.bg-is-primary-43 {
  background-color: rgba(54, 153, 255, 0.43);
}

.bg-is-primary-44 {
  background-color: rgba(54, 153, 255, 0.44);
}

.bg-is-primary-45 {
  background-color: rgba(54, 153, 255, 0.45);
}

.bg-is-primary-46 {
  background-color: rgba(54, 153, 255, 0.46);
}

.bg-is-primary-47 {
  background-color: rgba(54, 153, 255, 0.47);
}

.bg-is-primary-48 {
  background-color: rgba(54, 153, 255, 0.48);
}

.bg-is-primary-49 {
  background-color: rgba(54, 153, 255, 0.49);
}

.bg-is-primary-50 {
  background-color: rgba(54, 153, 255, 0.5);
}

.bg-is-primary-51 {
  background-color: rgba(54, 153, 255, 0.51);
}

.bg-is-primary-52 {
  background-color: rgba(54, 153, 255, 0.52);
}

.bg-is-primary-53 {
  background-color: rgba(54, 153, 255, 0.53);
}

.bg-is-primary-54 {
  background-color: rgba(54, 153, 255, 0.54);
}

.bg-is-primary-55 {
  background-color: rgba(54, 153, 255, 0.55);
}

.bg-is-primary-56 {
  background-color: rgba(54, 153, 255, 0.56);
}

.bg-is-primary-57 {
  background-color: rgba(54, 153, 255, 0.57);
}

.bg-is-primary-58 {
  background-color: rgba(54, 153, 255, 0.58);
}

.bg-is-primary-59 {
  background-color: rgba(54, 153, 255, 0.59);
}

.bg-is-primary-60 {
  background-color: rgba(54, 153, 255, 0.6);
}

.bg-is-primary-61 {
  background-color: rgba(54, 153, 255, 0.61);
}

.bg-is-primary-62 {
  background-color: rgba(54, 153, 255, 0.62);
}

.bg-is-primary-63 {
  background-color: rgba(54, 153, 255, 0.63);
}

.bg-is-primary-64 {
  background-color: rgba(54, 153, 255, 0.64);
}

.bg-is-primary-65 {
  background-color: rgba(54, 153, 255, 0.65);
}

.bg-is-primary-66 {
  background-color: rgba(54, 153, 255, 0.66);
}

.bg-is-primary-67 {
  background-color: rgba(54, 153, 255, 0.67);
}

.bg-is-primary-68 {
  background-color: rgba(54, 153, 255, 0.68);
}

.bg-is-primary-69 {
  background-color: rgba(54, 153, 255, 0.69);
}

.bg-is-primary-70 {
  background-color: rgba(54, 153, 255, 0.7);
}

.bg-is-primary-71 {
  background-color: rgba(54, 153, 255, 0.71);
}

.bg-is-primary-72 {
  background-color: rgba(54, 153, 255, 0.72);
}

.bg-is-primary-73 {
  background-color: rgba(54, 153, 255, 0.73);
}

.bg-is-primary-74 {
  background-color: rgba(54, 153, 255, 0.74);
}

.bg-is-primary-75 {
  background-color: rgba(54, 153, 255, 0.75);
}

.bg-is-primary-76 {
  background-color: rgba(54, 153, 255, 0.76);
}

.bg-is-primary-77 {
  background-color: rgba(54, 153, 255, 0.77);
}

.bg-is-primary-78 {
  background-color: rgba(54, 153, 255, 0.78);
}

.bg-is-primary-79 {
  background-color: rgba(54, 153, 255, 0.79);
}

.bg-is-primary-80 {
  background-color: rgba(54, 153, 255, 0.8);
}

.bg-is-primary-81 {
  background-color: rgba(54, 153, 255, 0.81);
}

.bg-is-primary-82 {
  background-color: rgba(54, 153, 255, 0.82);
}

.bg-is-primary-83 {
  background-color: rgba(54, 153, 255, 0.83);
}

.bg-is-primary-84 {
  background-color: rgba(54, 153, 255, 0.84);
}

.bg-is-primary-85 {
  background-color: rgba(54, 153, 255, 0.85);
}

.bg-is-primary-86 {
  background-color: rgba(54, 153, 255, 0.86);
}

.bg-is-primary-87 {
  background-color: rgba(54, 153, 255, 0.87);
}

.bg-is-primary-88 {
  background-color: rgba(54, 153, 255, 0.88);
}

.bg-is-primary-89 {
  background-color: rgba(54, 153, 255, 0.89);
}

.bg-is-primary-90 {
  background-color: rgba(54, 153, 255, 0.9);
}

.bg-is-primary-91 {
  background-color: rgba(54, 153, 255, 0.91);
}

.bg-is-primary-92 {
  background-color: rgba(54, 153, 255, 0.92);
}

.bg-is-primary-93 {
  background-color: rgba(54, 153, 255, 0.93);
}

.bg-is-primary-94 {
  background-color: rgba(54, 153, 255, 0.94);
}

.bg-is-primary-95 {
  background-color: rgba(54, 153, 255, 0.95);
}

.bg-is-primary-96 {
  background-color: rgba(54, 153, 255, 0.96);
}

.bg-is-primary-97 {
  background-color: rgba(54, 153, 255, 0.97);
}

.bg-is-primary-98 {
  background-color: rgba(54, 153, 255, 0.98);
}

.bg-is-primary-99 {
  background-color: rgba(54, 153, 255, 0.99);
}

.bg-is-primary-100 {
  background-color: #3699ff;
}

.border-is-primary {
  border-color: #3699FF;
}

.is-primary.typing .typed-cursor {
  color: #3699FF;
}

.is-secondary {
  color: #F5CA99;
}

.bg-is-secondary {
  background-color: #F5CA99;
}

.bg-is-secondary-0 {
  background-color: rgba(245, 202, 153, 0);
}

.bg-is-secondary-1 {
  background-color: rgba(245, 202, 153, 0.01);
}

.bg-is-secondary-2 {
  background-color: rgba(245, 202, 153, 0.02);
}

.bg-is-secondary-3 {
  background-color: rgba(245, 202, 153, 0.03);
}

.bg-is-secondary-4 {
  background-color: rgba(245, 202, 153, 0.04);
}

.bg-is-secondary-5 {
  background-color: rgba(245, 202, 153, 0.05);
}

.bg-is-secondary-6 {
  background-color: rgba(245, 202, 153, 0.06);
}

.bg-is-secondary-7 {
  background-color: rgba(245, 202, 153, 0.07);
}

.bg-is-secondary-8 {
  background-color: rgba(245, 202, 153, 0.08);
}

.bg-is-secondary-9 {
  background-color: rgba(245, 202, 153, 0.09);
}

.bg-is-secondary-10 {
  background-color: rgba(245, 202, 153, 0.1);
}

.bg-is-secondary-11 {
  background-color: rgba(245, 202, 153, 0.11);
}

.bg-is-secondary-12 {
  background-color: rgba(245, 202, 153, 0.12);
}

.bg-is-secondary-13 {
  background-color: rgba(245, 202, 153, 0.13);
}

.bg-is-secondary-14 {
  background-color: rgba(245, 202, 153, 0.14);
}

.bg-is-secondary-15 {
  background-color: rgba(245, 202, 153, 0.15);
}

.bg-is-secondary-16 {
  background-color: rgba(245, 202, 153, 0.16);
}

.bg-is-secondary-17 {
  background-color: rgba(245, 202, 153, 0.17);
}

.bg-is-secondary-18 {
  background-color: rgba(245, 202, 153, 0.18);
}

.bg-is-secondary-19 {
  background-color: rgba(245, 202, 153, 0.19);
}

.bg-is-secondary-20 {
  background-color: rgba(245, 202, 153, 0.2);
}

.bg-is-secondary-21 {
  background-color: rgba(245, 202, 153, 0.21);
}

.bg-is-secondary-22 {
  background-color: rgba(245, 202, 153, 0.22);
}

.bg-is-secondary-23 {
  background-color: rgba(245, 202, 153, 0.23);
}

.bg-is-secondary-24 {
  background-color: rgba(245, 202, 153, 0.24);
}

.bg-is-secondary-25 {
  background-color: rgba(245, 202, 153, 0.25);
}

.bg-is-secondary-26 {
  background-color: rgba(245, 202, 153, 0.26);
}

.bg-is-secondary-27 {
  background-color: rgba(245, 202, 153, 0.27);
}

.bg-is-secondary-28 {
  background-color: rgba(245, 202, 153, 0.28);
}

.bg-is-secondary-29 {
  background-color: rgba(245, 202, 153, 0.29);
}

.bg-is-secondary-30 {
  background-color: rgba(245, 202, 153, 0.3);
}

.bg-is-secondary-31 {
  background-color: rgba(245, 202, 153, 0.31);
}

.bg-is-secondary-32 {
  background-color: rgba(245, 202, 153, 0.32);
}

.bg-is-secondary-33 {
  background-color: rgba(245, 202, 153, 0.33);
}

.bg-is-secondary-34 {
  background-color: rgba(245, 202, 153, 0.34);
}

.bg-is-secondary-35 {
  background-color: rgba(245, 202, 153, 0.35);
}

.bg-is-secondary-36 {
  background-color: rgba(245, 202, 153, 0.36);
}

.bg-is-secondary-37 {
  background-color: rgba(245, 202, 153, 0.37);
}

.bg-is-secondary-38 {
  background-color: rgba(245, 202, 153, 0.38);
}

.bg-is-secondary-39 {
  background-color: rgba(245, 202, 153, 0.39);
}

.bg-is-secondary-40 {
  background-color: rgba(245, 202, 153, 0.4);
}

.bg-is-secondary-41 {
  background-color: rgba(245, 202, 153, 0.41);
}

.bg-is-secondary-42 {
  background-color: rgba(245, 202, 153, 0.42);
}

.bg-is-secondary-43 {
  background-color: rgba(245, 202, 153, 0.43);
}

.bg-is-secondary-44 {
  background-color: rgba(245, 202, 153, 0.44);
}

.bg-is-secondary-45 {
  background-color: rgba(245, 202, 153, 0.45);
}

.bg-is-secondary-46 {
  background-color: rgba(245, 202, 153, 0.46);
}

.bg-is-secondary-47 {
  background-color: rgba(245, 202, 153, 0.47);
}

.bg-is-secondary-48 {
  background-color: rgba(245, 202, 153, 0.48);
}

.bg-is-secondary-49 {
  background-color: rgba(245, 202, 153, 0.49);
}

.bg-is-secondary-50 {
  background-color: rgba(245, 202, 153, 0.5);
}

.bg-is-secondary-51 {
  background-color: rgba(245, 202, 153, 0.51);
}

.bg-is-secondary-52 {
  background-color: rgba(245, 202, 153, 0.52);
}

.bg-is-secondary-53 {
  background-color: rgba(245, 202, 153, 0.53);
}

.bg-is-secondary-54 {
  background-color: rgba(245, 202, 153, 0.54);
}

.bg-is-secondary-55 {
  background-color: rgba(245, 202, 153, 0.55);
}

.bg-is-secondary-56 {
  background-color: rgba(245, 202, 153, 0.56);
}

.bg-is-secondary-57 {
  background-color: rgba(245, 202, 153, 0.57);
}

.bg-is-secondary-58 {
  background-color: rgba(245, 202, 153, 0.58);
}

.bg-is-secondary-59 {
  background-color: rgba(245, 202, 153, 0.59);
}

.bg-is-secondary-60 {
  background-color: rgba(245, 202, 153, 0.6);
}

.bg-is-secondary-61 {
  background-color: rgba(245, 202, 153, 0.61);
}

.bg-is-secondary-62 {
  background-color: rgba(245, 202, 153, 0.62);
}

.bg-is-secondary-63 {
  background-color: rgba(245, 202, 153, 0.63);
}

.bg-is-secondary-64 {
  background-color: rgba(245, 202, 153, 0.64);
}

.bg-is-secondary-65 {
  background-color: rgba(245, 202, 153, 0.65);
}

.bg-is-secondary-66 {
  background-color: rgba(245, 202, 153, 0.66);
}

.bg-is-secondary-67 {
  background-color: rgba(245, 202, 153, 0.67);
}

.bg-is-secondary-68 {
  background-color: rgba(245, 202, 153, 0.68);
}

.bg-is-secondary-69 {
  background-color: rgba(245, 202, 153, 0.69);
}

.bg-is-secondary-70 {
  background-color: rgba(245, 202, 153, 0.7);
}

.bg-is-secondary-71 {
  background-color: rgba(245, 202, 153, 0.71);
}

.bg-is-secondary-72 {
  background-color: rgba(245, 202, 153, 0.72);
}

.bg-is-secondary-73 {
  background-color: rgba(245, 202, 153, 0.73);
}

.bg-is-secondary-74 {
  background-color: rgba(245, 202, 153, 0.74);
}

.bg-is-secondary-75 {
  background-color: rgba(245, 202, 153, 0.75);
}

.bg-is-secondary-76 {
  background-color: rgba(245, 202, 153, 0.76);
}

.bg-is-secondary-77 {
  background-color: rgba(245, 202, 153, 0.77);
}

.bg-is-secondary-78 {
  background-color: rgba(245, 202, 153, 0.78);
}

.bg-is-secondary-79 {
  background-color: rgba(245, 202, 153, 0.79);
}

.bg-is-secondary-80 {
  background-color: rgba(245, 202, 153, 0.8);
}

.bg-is-secondary-81 {
  background-color: rgba(245, 202, 153, 0.81);
}

.bg-is-secondary-82 {
  background-color: rgba(245, 202, 153, 0.82);
}

.bg-is-secondary-83 {
  background-color: rgba(245, 202, 153, 0.83);
}

.bg-is-secondary-84 {
  background-color: rgba(245, 202, 153, 0.84);
}

.bg-is-secondary-85 {
  background-color: rgba(245, 202, 153, 0.85);
}

.bg-is-secondary-86 {
  background-color: rgba(245, 202, 153, 0.86);
}

.bg-is-secondary-87 {
  background-color: rgba(245, 202, 153, 0.87);
}

.bg-is-secondary-88 {
  background-color: rgba(245, 202, 153, 0.88);
}

.bg-is-secondary-89 {
  background-color: rgba(245, 202, 153, 0.89);
}

.bg-is-secondary-90 {
  background-color: rgba(245, 202, 153, 0.9);
}

.bg-is-secondary-91 {
  background-color: rgba(245, 202, 153, 0.91);
}

.bg-is-secondary-92 {
  background-color: rgba(245, 202, 153, 0.92);
}

.bg-is-secondary-93 {
  background-color: rgba(245, 202, 153, 0.93);
}

.bg-is-secondary-94 {
  background-color: rgba(245, 202, 153, 0.94);
}

.bg-is-secondary-95 {
  background-color: rgba(245, 202, 153, 0.95);
}

.bg-is-secondary-96 {
  background-color: rgba(245, 202, 153, 0.96);
}

.bg-is-secondary-97 {
  background-color: rgba(245, 202, 153, 0.97);
}

.bg-is-secondary-98 {
  background-color: rgba(245, 202, 153, 0.98);
}

.bg-is-secondary-99 {
  background-color: rgba(245, 202, 153, 0.99);
}

.bg-is-secondary-100 {
  background-color: #f5ca99;
}

.border-is-secondary {
  border-color: #F5CA99;
}

.is-secondary.typing .typed-cursor {
  color: #F5CA99;
}

.is-third {
  color: #242939;
}

.bg-is-third {
  background-color: #242939;
}

.bg-is-third-0 {
  background-color: rgba(36, 41, 57, 0);
}

.bg-is-third-1 {
  background-color: rgba(36, 41, 57, 0.01);
}

.bg-is-third-2 {
  background-color: rgba(36, 41, 57, 0.02);
}

.bg-is-third-3 {
  background-color: rgba(36, 41, 57, 0.03);
}

.bg-is-third-4 {
  background-color: rgba(36, 41, 57, 0.04);
}

.bg-is-third-5 {
  background-color: rgba(36, 41, 57, 0.05);
}

.bg-is-third-6 {
  background-color: rgba(36, 41, 57, 0.06);
}

.bg-is-third-7 {
  background-color: rgba(36, 41, 57, 0.07);
}

.bg-is-third-8 {
  background-color: rgba(36, 41, 57, 0.08);
}

.bg-is-third-9 {
  background-color: rgba(36, 41, 57, 0.09);
}

.bg-is-third-10 {
  background-color: rgba(36, 41, 57, 0.1);
}

.bg-is-third-11 {
  background-color: rgba(36, 41, 57, 0.11);
}

.bg-is-third-12 {
  background-color: rgba(36, 41, 57, 0.12);
}

.bg-is-third-13 {
  background-color: rgba(36, 41, 57, 0.13);
}

.bg-is-third-14 {
  background-color: rgba(36, 41, 57, 0.14);
}

.bg-is-third-15 {
  background-color: rgba(36, 41, 57, 0.15);
}

.bg-is-third-16 {
  background-color: rgba(36, 41, 57, 0.16);
}

.bg-is-third-17 {
  background-color: rgba(36, 41, 57, 0.17);
}

.bg-is-third-18 {
  background-color: rgba(36, 41, 57, 0.18);
}

.bg-is-third-19 {
  background-color: rgba(36, 41, 57, 0.19);
}

.bg-is-third-20 {
  background-color: rgba(36, 41, 57, 0.2);
}

.bg-is-third-21 {
  background-color: rgba(36, 41, 57, 0.21);
}

.bg-is-third-22 {
  background-color: rgba(36, 41, 57, 0.22);
}

.bg-is-third-23 {
  background-color: rgba(36, 41, 57, 0.23);
}

.bg-is-third-24 {
  background-color: rgba(36, 41, 57, 0.24);
}

.bg-is-third-25 {
  background-color: rgba(36, 41, 57, 0.25);
}

.bg-is-third-26 {
  background-color: rgba(36, 41, 57, 0.26);
}

.bg-is-third-27 {
  background-color: rgba(36, 41, 57, 0.27);
}

.bg-is-third-28 {
  background-color: rgba(36, 41, 57, 0.28);
}

.bg-is-third-29 {
  background-color: rgba(36, 41, 57, 0.29);
}

.bg-is-third-30 {
  background-color: rgba(36, 41, 57, 0.3);
}

.bg-is-third-31 {
  background-color: rgba(36, 41, 57, 0.31);
}

.bg-is-third-32 {
  background-color: rgba(36, 41, 57, 0.32);
}

.bg-is-third-33 {
  background-color: rgba(36, 41, 57, 0.33);
}

.bg-is-third-34 {
  background-color: rgba(36, 41, 57, 0.34);
}

.bg-is-third-35 {
  background-color: rgba(36, 41, 57, 0.35);
}

.bg-is-third-36 {
  background-color: rgba(36, 41, 57, 0.36);
}

.bg-is-third-37 {
  background-color: rgba(36, 41, 57, 0.37);
}

.bg-is-third-38 {
  background-color: rgba(36, 41, 57, 0.38);
}

.bg-is-third-39 {
  background-color: rgba(36, 41, 57, 0.39);
}

.bg-is-third-40 {
  background-color: rgba(36, 41, 57, 0.4);
}

.bg-is-third-41 {
  background-color: rgba(36, 41, 57, 0.41);
}

.bg-is-third-42 {
  background-color: rgba(36, 41, 57, 0.42);
}

.bg-is-third-43 {
  background-color: rgba(36, 41, 57, 0.43);
}

.bg-is-third-44 {
  background-color: rgba(36, 41, 57, 0.44);
}

.bg-is-third-45 {
  background-color: rgba(36, 41, 57, 0.45);
}

.bg-is-third-46 {
  background-color: rgba(36, 41, 57, 0.46);
}

.bg-is-third-47 {
  background-color: rgba(36, 41, 57, 0.47);
}

.bg-is-third-48 {
  background-color: rgba(36, 41, 57, 0.48);
}

.bg-is-third-49 {
  background-color: rgba(36, 41, 57, 0.49);
}

.bg-is-third-50 {
  background-color: rgba(36, 41, 57, 0.5);
}

.bg-is-third-51 {
  background-color: rgba(36, 41, 57, 0.51);
}

.bg-is-third-52 {
  background-color: rgba(36, 41, 57, 0.52);
}

.bg-is-third-53 {
  background-color: rgba(36, 41, 57, 0.53);
}

.bg-is-third-54 {
  background-color: rgba(36, 41, 57, 0.54);
}

.bg-is-third-55 {
  background-color: rgba(36, 41, 57, 0.55);
}

.bg-is-third-56 {
  background-color: rgba(36, 41, 57, 0.56);
}

.bg-is-third-57 {
  background-color: rgba(36, 41, 57, 0.57);
}

.bg-is-third-58 {
  background-color: rgba(36, 41, 57, 0.58);
}

.bg-is-third-59 {
  background-color: rgba(36, 41, 57, 0.59);
}

.bg-is-third-60 {
  background-color: rgba(36, 41, 57, 0.6);
}

.bg-is-third-61 {
  background-color: rgba(36, 41, 57, 0.61);
}

.bg-is-third-62 {
  background-color: rgba(36, 41, 57, 0.62);
}

.bg-is-third-63 {
  background-color: rgba(36, 41, 57, 0.63);
}

.bg-is-third-64 {
  background-color: rgba(36, 41, 57, 0.64);
}

.bg-is-third-65 {
  background-color: rgba(36, 41, 57, 0.65);
}

.bg-is-third-66 {
  background-color: rgba(36, 41, 57, 0.66);
}

.bg-is-third-67 {
  background-color: rgba(36, 41, 57, 0.67);
}

.bg-is-third-68 {
  background-color: rgba(36, 41, 57, 0.68);
}

.bg-is-third-69 {
  background-color: rgba(36, 41, 57, 0.69);
}

.bg-is-third-70 {
  background-color: rgba(36, 41, 57, 0.7);
}

.bg-is-third-71 {
  background-color: rgba(36, 41, 57, 0.71);
}

.bg-is-third-72 {
  background-color: rgba(36, 41, 57, 0.72);
}

.bg-is-third-73 {
  background-color: rgba(36, 41, 57, 0.73);
}

.bg-is-third-74 {
  background-color: rgba(36, 41, 57, 0.74);
}

.bg-is-third-75 {
  background-color: rgba(36, 41, 57, 0.75);
}

.bg-is-third-76 {
  background-color: rgba(36, 41, 57, 0.76);
}

.bg-is-third-77 {
  background-color: rgba(36, 41, 57, 0.77);
}

.bg-is-third-78 {
  background-color: rgba(36, 41, 57, 0.78);
}

.bg-is-third-79 {
  background-color: rgba(36, 41, 57, 0.79);
}

.bg-is-third-80 {
  background-color: rgba(36, 41, 57, 0.8);
}

.bg-is-third-81 {
  background-color: rgba(36, 41, 57, 0.81);
}

.bg-is-third-82 {
  background-color: rgba(36, 41, 57, 0.82);
}

.bg-is-third-83 {
  background-color: rgba(36, 41, 57, 0.83);
}

.bg-is-third-84 {
  background-color: rgba(36, 41, 57, 0.84);
}

.bg-is-third-85 {
  background-color: rgba(36, 41, 57, 0.85);
}

.bg-is-third-86 {
  background-color: rgba(36, 41, 57, 0.86);
}

.bg-is-third-87 {
  background-color: rgba(36, 41, 57, 0.87);
}

.bg-is-third-88 {
  background-color: rgba(36, 41, 57, 0.88);
}

.bg-is-third-89 {
  background-color: rgba(36, 41, 57, 0.89);
}

.bg-is-third-90 {
  background-color: rgba(36, 41, 57, 0.9);
}

.bg-is-third-91 {
  background-color: rgba(36, 41, 57, 0.91);
}

.bg-is-third-92 {
  background-color: rgba(36, 41, 57, 0.92);
}

.bg-is-third-93 {
  background-color: rgba(36, 41, 57, 0.93);
}

.bg-is-third-94 {
  background-color: rgba(36, 41, 57, 0.94);
}

.bg-is-third-95 {
  background-color: rgba(36, 41, 57, 0.95);
}

.bg-is-third-96 {
  background-color: rgba(36, 41, 57, 0.96);
}

.bg-is-third-97 {
  background-color: rgba(36, 41, 57, 0.97);
}

.bg-is-third-98 {
  background-color: rgba(36, 41, 57, 0.98);
}

.bg-is-third-99 {
  background-color: rgba(36, 41, 57, 0.99);
}

.bg-is-third-100 {
  background-color: #242939;
}

.border-is-third {
  border-color: #242939;
}

.is-third.typing .typed-cursor {
  color: #242939;
}

.is-fourth {
  color: #377DFF;
}

.bg-is-fourth {
  background-color: #377DFF;
}

.bg-is-fourth-0 {
  background-color: rgba(55, 125, 255, 0);
}

.bg-is-fourth-1 {
  background-color: rgba(55, 125, 255, 0.01);
}

.bg-is-fourth-2 {
  background-color: rgba(55, 125, 255, 0.02);
}

.bg-is-fourth-3 {
  background-color: rgba(55, 125, 255, 0.03);
}

.bg-is-fourth-4 {
  background-color: rgba(55, 125, 255, 0.04);
}

.bg-is-fourth-5 {
  background-color: rgba(55, 125, 255, 0.05);
}

.bg-is-fourth-6 {
  background-color: rgba(55, 125, 255, 0.06);
}

.bg-is-fourth-7 {
  background-color: rgba(55, 125, 255, 0.07);
}

.bg-is-fourth-8 {
  background-color: rgba(55, 125, 255, 0.08);
}

.bg-is-fourth-9 {
  background-color: rgba(55, 125, 255, 0.09);
}

.bg-is-fourth-10 {
  background-color: rgba(55, 125, 255, 0.1);
}

.bg-is-fourth-11 {
  background-color: rgba(55, 125, 255, 0.11);
}

.bg-is-fourth-12 {
  background-color: rgba(55, 125, 255, 0.12);
}

.bg-is-fourth-13 {
  background-color: rgba(55, 125, 255, 0.13);
}

.bg-is-fourth-14 {
  background-color: rgba(55, 125, 255, 0.14);
}

.bg-is-fourth-15 {
  background-color: rgba(55, 125, 255, 0.15);
}

.bg-is-fourth-16 {
  background-color: rgba(55, 125, 255, 0.16);
}

.bg-is-fourth-17 {
  background-color: rgba(55, 125, 255, 0.17);
}

.bg-is-fourth-18 {
  background-color: rgba(55, 125, 255, 0.18);
}

.bg-is-fourth-19 {
  background-color: rgba(55, 125, 255, 0.19);
}

.bg-is-fourth-20 {
  background-color: rgba(55, 125, 255, 0.2);
}

.bg-is-fourth-21 {
  background-color: rgba(55, 125, 255, 0.21);
}

.bg-is-fourth-22 {
  background-color: rgba(55, 125, 255, 0.22);
}

.bg-is-fourth-23 {
  background-color: rgba(55, 125, 255, 0.23);
}

.bg-is-fourth-24 {
  background-color: rgba(55, 125, 255, 0.24);
}

.bg-is-fourth-25 {
  background-color: rgba(55, 125, 255, 0.25);
}

.bg-is-fourth-26 {
  background-color: rgba(55, 125, 255, 0.26);
}

.bg-is-fourth-27 {
  background-color: rgba(55, 125, 255, 0.27);
}

.bg-is-fourth-28 {
  background-color: rgba(55, 125, 255, 0.28);
}

.bg-is-fourth-29 {
  background-color: rgba(55, 125, 255, 0.29);
}

.bg-is-fourth-30 {
  background-color: rgba(55, 125, 255, 0.3);
}

.bg-is-fourth-31 {
  background-color: rgba(55, 125, 255, 0.31);
}

.bg-is-fourth-32 {
  background-color: rgba(55, 125, 255, 0.32);
}

.bg-is-fourth-33 {
  background-color: rgba(55, 125, 255, 0.33);
}

.bg-is-fourth-34 {
  background-color: rgba(55, 125, 255, 0.34);
}

.bg-is-fourth-35 {
  background-color: rgba(55, 125, 255, 0.35);
}

.bg-is-fourth-36 {
  background-color: rgba(55, 125, 255, 0.36);
}

.bg-is-fourth-37 {
  background-color: rgba(55, 125, 255, 0.37);
}

.bg-is-fourth-38 {
  background-color: rgba(55, 125, 255, 0.38);
}

.bg-is-fourth-39 {
  background-color: rgba(55, 125, 255, 0.39);
}

.bg-is-fourth-40 {
  background-color: rgba(55, 125, 255, 0.4);
}

.bg-is-fourth-41 {
  background-color: rgba(55, 125, 255, 0.41);
}

.bg-is-fourth-42 {
  background-color: rgba(55, 125, 255, 0.42);
}

.bg-is-fourth-43 {
  background-color: rgba(55, 125, 255, 0.43);
}

.bg-is-fourth-44 {
  background-color: rgba(55, 125, 255, 0.44);
}

.bg-is-fourth-45 {
  background-color: rgba(55, 125, 255, 0.45);
}

.bg-is-fourth-46 {
  background-color: rgba(55, 125, 255, 0.46);
}

.bg-is-fourth-47 {
  background-color: rgba(55, 125, 255, 0.47);
}

.bg-is-fourth-48 {
  background-color: rgba(55, 125, 255, 0.48);
}

.bg-is-fourth-49 {
  background-color: rgba(55, 125, 255, 0.49);
}

.bg-is-fourth-50 {
  background-color: rgba(55, 125, 255, 0.5);
}

.bg-is-fourth-51 {
  background-color: rgba(55, 125, 255, 0.51);
}

.bg-is-fourth-52 {
  background-color: rgba(55, 125, 255, 0.52);
}

.bg-is-fourth-53 {
  background-color: rgba(55, 125, 255, 0.53);
}

.bg-is-fourth-54 {
  background-color: rgba(55, 125, 255, 0.54);
}

.bg-is-fourth-55 {
  background-color: rgba(55, 125, 255, 0.55);
}

.bg-is-fourth-56 {
  background-color: rgba(55, 125, 255, 0.56);
}

.bg-is-fourth-57 {
  background-color: rgba(55, 125, 255, 0.57);
}

.bg-is-fourth-58 {
  background-color: rgba(55, 125, 255, 0.58);
}

.bg-is-fourth-59 {
  background-color: rgba(55, 125, 255, 0.59);
}

.bg-is-fourth-60 {
  background-color: rgba(55, 125, 255, 0.6);
}

.bg-is-fourth-61 {
  background-color: rgba(55, 125, 255, 0.61);
}

.bg-is-fourth-62 {
  background-color: rgba(55, 125, 255, 0.62);
}

.bg-is-fourth-63 {
  background-color: rgba(55, 125, 255, 0.63);
}

.bg-is-fourth-64 {
  background-color: rgba(55, 125, 255, 0.64);
}

.bg-is-fourth-65 {
  background-color: rgba(55, 125, 255, 0.65);
}

.bg-is-fourth-66 {
  background-color: rgba(55, 125, 255, 0.66);
}

.bg-is-fourth-67 {
  background-color: rgba(55, 125, 255, 0.67);
}

.bg-is-fourth-68 {
  background-color: rgba(55, 125, 255, 0.68);
}

.bg-is-fourth-69 {
  background-color: rgba(55, 125, 255, 0.69);
}

.bg-is-fourth-70 {
  background-color: rgba(55, 125, 255, 0.7);
}

.bg-is-fourth-71 {
  background-color: rgba(55, 125, 255, 0.71);
}

.bg-is-fourth-72 {
  background-color: rgba(55, 125, 255, 0.72);
}

.bg-is-fourth-73 {
  background-color: rgba(55, 125, 255, 0.73);
}

.bg-is-fourth-74 {
  background-color: rgba(55, 125, 255, 0.74);
}

.bg-is-fourth-75 {
  background-color: rgba(55, 125, 255, 0.75);
}

.bg-is-fourth-76 {
  background-color: rgba(55, 125, 255, 0.76);
}

.bg-is-fourth-77 {
  background-color: rgba(55, 125, 255, 0.77);
}

.bg-is-fourth-78 {
  background-color: rgba(55, 125, 255, 0.78);
}

.bg-is-fourth-79 {
  background-color: rgba(55, 125, 255, 0.79);
}

.bg-is-fourth-80 {
  background-color: rgba(55, 125, 255, 0.8);
}

.bg-is-fourth-81 {
  background-color: rgba(55, 125, 255, 0.81);
}

.bg-is-fourth-82 {
  background-color: rgba(55, 125, 255, 0.82);
}

.bg-is-fourth-83 {
  background-color: rgba(55, 125, 255, 0.83);
}

.bg-is-fourth-84 {
  background-color: rgba(55, 125, 255, 0.84);
}

.bg-is-fourth-85 {
  background-color: rgba(55, 125, 255, 0.85);
}

.bg-is-fourth-86 {
  background-color: rgba(55, 125, 255, 0.86);
}

.bg-is-fourth-87 {
  background-color: rgba(55, 125, 255, 0.87);
}

.bg-is-fourth-88 {
  background-color: rgba(55, 125, 255, 0.88);
}

.bg-is-fourth-89 {
  background-color: rgba(55, 125, 255, 0.89);
}

.bg-is-fourth-90 {
  background-color: rgba(55, 125, 255, 0.9);
}

.bg-is-fourth-91 {
  background-color: rgba(55, 125, 255, 0.91);
}

.bg-is-fourth-92 {
  background-color: rgba(55, 125, 255, 0.92);
}

.bg-is-fourth-93 {
  background-color: rgba(55, 125, 255, 0.93);
}

.bg-is-fourth-94 {
  background-color: rgba(55, 125, 255, 0.94);
}

.bg-is-fourth-95 {
  background-color: rgba(55, 125, 255, 0.95);
}

.bg-is-fourth-96 {
  background-color: rgba(55, 125, 255, 0.96);
}

.bg-is-fourth-97 {
  background-color: rgba(55, 125, 255, 0.97);
}

.bg-is-fourth-98 {
  background-color: rgba(55, 125, 255, 0.98);
}

.bg-is-fourth-99 {
  background-color: rgba(55, 125, 255, 0.99);
}

.bg-is-fourth-100 {
  background-color: #377dff;
}

.border-is-fourth {
  border-color: #377DFF;
}

.is-fourth.typing .typed-cursor {
  color: #377DFF;
}

.is-fifth {
  color: #9FC0DA;
}

.bg-is-fifth {
  background-color: #9FC0DA;
}

.bg-is-fifth-0 {
  background-color: rgba(159, 192, 218, 0);
}

.bg-is-fifth-1 {
  background-color: rgba(159, 192, 218, 0.01);
}

.bg-is-fifth-2 {
  background-color: rgba(159, 192, 218, 0.02);
}

.bg-is-fifth-3 {
  background-color: rgba(159, 192, 218, 0.03);
}

.bg-is-fifth-4 {
  background-color: rgba(159, 192, 218, 0.04);
}

.bg-is-fifth-5 {
  background-color: rgba(159, 192, 218, 0.05);
}

.bg-is-fifth-6 {
  background-color: rgba(159, 192, 218, 0.06);
}

.bg-is-fifth-7 {
  background-color: rgba(159, 192, 218, 0.07);
}

.bg-is-fifth-8 {
  background-color: rgba(159, 192, 218, 0.08);
}

.bg-is-fifth-9 {
  background-color: rgba(159, 192, 218, 0.09);
}

.bg-is-fifth-10 {
  background-color: rgba(159, 192, 218, 0.1);
}

.bg-is-fifth-11 {
  background-color: rgba(159, 192, 218, 0.11);
}

.bg-is-fifth-12 {
  background-color: rgba(159, 192, 218, 0.12);
}

.bg-is-fifth-13 {
  background-color: rgba(159, 192, 218, 0.13);
}

.bg-is-fifth-14 {
  background-color: rgba(159, 192, 218, 0.14);
}

.bg-is-fifth-15 {
  background-color: rgba(159, 192, 218, 0.15);
}

.bg-is-fifth-16 {
  background-color: rgba(159, 192, 218, 0.16);
}

.bg-is-fifth-17 {
  background-color: rgba(159, 192, 218, 0.17);
}

.bg-is-fifth-18 {
  background-color: rgba(159, 192, 218, 0.18);
}

.bg-is-fifth-19 {
  background-color: rgba(159, 192, 218, 0.19);
}

.bg-is-fifth-20 {
  background-color: rgba(159, 192, 218, 0.2);
}

.bg-is-fifth-21 {
  background-color: rgba(159, 192, 218, 0.21);
}

.bg-is-fifth-22 {
  background-color: rgba(159, 192, 218, 0.22);
}

.bg-is-fifth-23 {
  background-color: rgba(159, 192, 218, 0.23);
}

.bg-is-fifth-24 {
  background-color: rgba(159, 192, 218, 0.24);
}

.bg-is-fifth-25 {
  background-color: rgba(159, 192, 218, 0.25);
}

.bg-is-fifth-26 {
  background-color: rgba(159, 192, 218, 0.26);
}

.bg-is-fifth-27 {
  background-color: rgba(159, 192, 218, 0.27);
}

.bg-is-fifth-28 {
  background-color: rgba(159, 192, 218, 0.28);
}

.bg-is-fifth-29 {
  background-color: rgba(159, 192, 218, 0.29);
}

.bg-is-fifth-30 {
  background-color: rgba(159, 192, 218, 0.3);
}

.bg-is-fifth-31 {
  background-color: rgba(159, 192, 218, 0.31);
}

.bg-is-fifth-32 {
  background-color: rgba(159, 192, 218, 0.32);
}

.bg-is-fifth-33 {
  background-color: rgba(159, 192, 218, 0.33);
}

.bg-is-fifth-34 {
  background-color: rgba(159, 192, 218, 0.34);
}

.bg-is-fifth-35 {
  background-color: rgba(159, 192, 218, 0.35);
}

.bg-is-fifth-36 {
  background-color: rgba(159, 192, 218, 0.36);
}

.bg-is-fifth-37 {
  background-color: rgba(159, 192, 218, 0.37);
}

.bg-is-fifth-38 {
  background-color: rgba(159, 192, 218, 0.38);
}

.bg-is-fifth-39 {
  background-color: rgba(159, 192, 218, 0.39);
}

.bg-is-fifth-40 {
  background-color: rgba(159, 192, 218, 0.4);
}

.bg-is-fifth-41 {
  background-color: rgba(159, 192, 218, 0.41);
}

.bg-is-fifth-42 {
  background-color: rgba(159, 192, 218, 0.42);
}

.bg-is-fifth-43 {
  background-color: rgba(159, 192, 218, 0.43);
}

.bg-is-fifth-44 {
  background-color: rgba(159, 192, 218, 0.44);
}

.bg-is-fifth-45 {
  background-color: rgba(159, 192, 218, 0.45);
}

.bg-is-fifth-46 {
  background-color: rgba(159, 192, 218, 0.46);
}

.bg-is-fifth-47 {
  background-color: rgba(159, 192, 218, 0.47);
}

.bg-is-fifth-48 {
  background-color: rgba(159, 192, 218, 0.48);
}

.bg-is-fifth-49 {
  background-color: rgba(159, 192, 218, 0.49);
}

.bg-is-fifth-50 {
  background-color: rgba(159, 192, 218, 0.5);
}

.bg-is-fifth-51 {
  background-color: rgba(159, 192, 218, 0.51);
}

.bg-is-fifth-52 {
  background-color: rgba(159, 192, 218, 0.52);
}

.bg-is-fifth-53 {
  background-color: rgba(159, 192, 218, 0.53);
}

.bg-is-fifth-54 {
  background-color: rgba(159, 192, 218, 0.54);
}

.bg-is-fifth-55 {
  background-color: rgba(159, 192, 218, 0.55);
}

.bg-is-fifth-56 {
  background-color: rgba(159, 192, 218, 0.56);
}

.bg-is-fifth-57 {
  background-color: rgba(159, 192, 218, 0.57);
}

.bg-is-fifth-58 {
  background-color: rgba(159, 192, 218, 0.58);
}

.bg-is-fifth-59 {
  background-color: rgba(159, 192, 218, 0.59);
}

.bg-is-fifth-60 {
  background-color: rgba(159, 192, 218, 0.6);
}

.bg-is-fifth-61 {
  background-color: rgba(159, 192, 218, 0.61);
}

.bg-is-fifth-62 {
  background-color: rgba(159, 192, 218, 0.62);
}

.bg-is-fifth-63 {
  background-color: rgba(159, 192, 218, 0.63);
}

.bg-is-fifth-64 {
  background-color: rgba(159, 192, 218, 0.64);
}

.bg-is-fifth-65 {
  background-color: rgba(159, 192, 218, 0.65);
}

.bg-is-fifth-66 {
  background-color: rgba(159, 192, 218, 0.66);
}

.bg-is-fifth-67 {
  background-color: rgba(159, 192, 218, 0.67);
}

.bg-is-fifth-68 {
  background-color: rgba(159, 192, 218, 0.68);
}

.bg-is-fifth-69 {
  background-color: rgba(159, 192, 218, 0.69);
}

.bg-is-fifth-70 {
  background-color: rgba(159, 192, 218, 0.7);
}

.bg-is-fifth-71 {
  background-color: rgba(159, 192, 218, 0.71);
}

.bg-is-fifth-72 {
  background-color: rgba(159, 192, 218, 0.72);
}

.bg-is-fifth-73 {
  background-color: rgba(159, 192, 218, 0.73);
}

.bg-is-fifth-74 {
  background-color: rgba(159, 192, 218, 0.74);
}

.bg-is-fifth-75 {
  background-color: rgba(159, 192, 218, 0.75);
}

.bg-is-fifth-76 {
  background-color: rgba(159, 192, 218, 0.76);
}

.bg-is-fifth-77 {
  background-color: rgba(159, 192, 218, 0.77);
}

.bg-is-fifth-78 {
  background-color: rgba(159, 192, 218, 0.78);
}

.bg-is-fifth-79 {
  background-color: rgba(159, 192, 218, 0.79);
}

.bg-is-fifth-80 {
  background-color: rgba(159, 192, 218, 0.8);
}

.bg-is-fifth-81 {
  background-color: rgba(159, 192, 218, 0.81);
}

.bg-is-fifth-82 {
  background-color: rgba(159, 192, 218, 0.82);
}

.bg-is-fifth-83 {
  background-color: rgba(159, 192, 218, 0.83);
}

.bg-is-fifth-84 {
  background-color: rgba(159, 192, 218, 0.84);
}

.bg-is-fifth-85 {
  background-color: rgba(159, 192, 218, 0.85);
}

.bg-is-fifth-86 {
  background-color: rgba(159, 192, 218, 0.86);
}

.bg-is-fifth-87 {
  background-color: rgba(159, 192, 218, 0.87);
}

.bg-is-fifth-88 {
  background-color: rgba(159, 192, 218, 0.88);
}

.bg-is-fifth-89 {
  background-color: rgba(159, 192, 218, 0.89);
}

.bg-is-fifth-90 {
  background-color: rgba(159, 192, 218, 0.9);
}

.bg-is-fifth-91 {
  background-color: rgba(159, 192, 218, 0.91);
}

.bg-is-fifth-92 {
  background-color: rgba(159, 192, 218, 0.92);
}

.bg-is-fifth-93 {
  background-color: rgba(159, 192, 218, 0.93);
}

.bg-is-fifth-94 {
  background-color: rgba(159, 192, 218, 0.94);
}

.bg-is-fifth-95 {
  background-color: rgba(159, 192, 218, 0.95);
}

.bg-is-fifth-96 {
  background-color: rgba(159, 192, 218, 0.96);
}

.bg-is-fifth-97 {
  background-color: rgba(159, 192, 218, 0.97);
}

.bg-is-fifth-98 {
  background-color: rgba(159, 192, 218, 0.98);
}

.bg-is-fifth-99 {
  background-color: rgba(159, 192, 218, 0.99);
}

.bg-is-fifth-100 {
  background-color: #9fc0da;
}

.border-is-fifth {
  border-color: #9FC0DA;
}

.is-fifth.typing .typed-cursor {
  color: #9FC0DA;
}

.is-sixth {
  color: #C9A7CF;
}

.bg-is-sixth {
  background-color: #C9A7CF;
}

.bg-is-sixth-0 {
  background-color: rgba(201, 167, 207, 0);
}

.bg-is-sixth-1 {
  background-color: rgba(201, 167, 207, 0.01);
}

.bg-is-sixth-2 {
  background-color: rgba(201, 167, 207, 0.02);
}

.bg-is-sixth-3 {
  background-color: rgba(201, 167, 207, 0.03);
}

.bg-is-sixth-4 {
  background-color: rgba(201, 167, 207, 0.04);
}

.bg-is-sixth-5 {
  background-color: rgba(201, 167, 207, 0.05);
}

.bg-is-sixth-6 {
  background-color: rgba(201, 167, 207, 0.06);
}

.bg-is-sixth-7 {
  background-color: rgba(201, 167, 207, 0.07);
}

.bg-is-sixth-8 {
  background-color: rgba(201, 167, 207, 0.08);
}

.bg-is-sixth-9 {
  background-color: rgba(201, 167, 207, 0.09);
}

.bg-is-sixth-10 {
  background-color: rgba(201, 167, 207, 0.1);
}

.bg-is-sixth-11 {
  background-color: rgba(201, 167, 207, 0.11);
}

.bg-is-sixth-12 {
  background-color: rgba(201, 167, 207, 0.12);
}

.bg-is-sixth-13 {
  background-color: rgba(201, 167, 207, 0.13);
}

.bg-is-sixth-14 {
  background-color: rgba(201, 167, 207, 0.14);
}

.bg-is-sixth-15 {
  background-color: rgba(201, 167, 207, 0.15);
}

.bg-is-sixth-16 {
  background-color: rgba(201, 167, 207, 0.16);
}

.bg-is-sixth-17 {
  background-color: rgba(201, 167, 207, 0.17);
}

.bg-is-sixth-18 {
  background-color: rgba(201, 167, 207, 0.18);
}

.bg-is-sixth-19 {
  background-color: rgba(201, 167, 207, 0.19);
}

.bg-is-sixth-20 {
  background-color: rgba(201, 167, 207, 0.2);
}

.bg-is-sixth-21 {
  background-color: rgba(201, 167, 207, 0.21);
}

.bg-is-sixth-22 {
  background-color: rgba(201, 167, 207, 0.22);
}

.bg-is-sixth-23 {
  background-color: rgba(201, 167, 207, 0.23);
}

.bg-is-sixth-24 {
  background-color: rgba(201, 167, 207, 0.24);
}

.bg-is-sixth-25 {
  background-color: rgba(201, 167, 207, 0.25);
}

.bg-is-sixth-26 {
  background-color: rgba(201, 167, 207, 0.26);
}

.bg-is-sixth-27 {
  background-color: rgba(201, 167, 207, 0.27);
}

.bg-is-sixth-28 {
  background-color: rgba(201, 167, 207, 0.28);
}

.bg-is-sixth-29 {
  background-color: rgba(201, 167, 207, 0.29);
}

.bg-is-sixth-30 {
  background-color: rgba(201, 167, 207, 0.3);
}

.bg-is-sixth-31 {
  background-color: rgba(201, 167, 207, 0.31);
}

.bg-is-sixth-32 {
  background-color: rgba(201, 167, 207, 0.32);
}

.bg-is-sixth-33 {
  background-color: rgba(201, 167, 207, 0.33);
}

.bg-is-sixth-34 {
  background-color: rgba(201, 167, 207, 0.34);
}

.bg-is-sixth-35 {
  background-color: rgba(201, 167, 207, 0.35);
}

.bg-is-sixth-36 {
  background-color: rgba(201, 167, 207, 0.36);
}

.bg-is-sixth-37 {
  background-color: rgba(201, 167, 207, 0.37);
}

.bg-is-sixth-38 {
  background-color: rgba(201, 167, 207, 0.38);
}

.bg-is-sixth-39 {
  background-color: rgba(201, 167, 207, 0.39);
}

.bg-is-sixth-40 {
  background-color: rgba(201, 167, 207, 0.4);
}

.bg-is-sixth-41 {
  background-color: rgba(201, 167, 207, 0.41);
}

.bg-is-sixth-42 {
  background-color: rgba(201, 167, 207, 0.42);
}

.bg-is-sixth-43 {
  background-color: rgba(201, 167, 207, 0.43);
}

.bg-is-sixth-44 {
  background-color: rgba(201, 167, 207, 0.44);
}

.bg-is-sixth-45 {
  background-color: rgba(201, 167, 207, 0.45);
}

.bg-is-sixth-46 {
  background-color: rgba(201, 167, 207, 0.46);
}

.bg-is-sixth-47 {
  background-color: rgba(201, 167, 207, 0.47);
}

.bg-is-sixth-48 {
  background-color: rgba(201, 167, 207, 0.48);
}

.bg-is-sixth-49 {
  background-color: rgba(201, 167, 207, 0.49);
}

.bg-is-sixth-50 {
  background-color: rgba(201, 167, 207, 0.5);
}

.bg-is-sixth-51 {
  background-color: rgba(201, 167, 207, 0.51);
}

.bg-is-sixth-52 {
  background-color: rgba(201, 167, 207, 0.52);
}

.bg-is-sixth-53 {
  background-color: rgba(201, 167, 207, 0.53);
}

.bg-is-sixth-54 {
  background-color: rgba(201, 167, 207, 0.54);
}

.bg-is-sixth-55 {
  background-color: rgba(201, 167, 207, 0.55);
}

.bg-is-sixth-56 {
  background-color: rgba(201, 167, 207, 0.56);
}

.bg-is-sixth-57 {
  background-color: rgba(201, 167, 207, 0.57);
}

.bg-is-sixth-58 {
  background-color: rgba(201, 167, 207, 0.58);
}

.bg-is-sixth-59 {
  background-color: rgba(201, 167, 207, 0.59);
}

.bg-is-sixth-60 {
  background-color: rgba(201, 167, 207, 0.6);
}

.bg-is-sixth-61 {
  background-color: rgba(201, 167, 207, 0.61);
}

.bg-is-sixth-62 {
  background-color: rgba(201, 167, 207, 0.62);
}

.bg-is-sixth-63 {
  background-color: rgba(201, 167, 207, 0.63);
}

.bg-is-sixth-64 {
  background-color: rgba(201, 167, 207, 0.64);
}

.bg-is-sixth-65 {
  background-color: rgba(201, 167, 207, 0.65);
}

.bg-is-sixth-66 {
  background-color: rgba(201, 167, 207, 0.66);
}

.bg-is-sixth-67 {
  background-color: rgba(201, 167, 207, 0.67);
}

.bg-is-sixth-68 {
  background-color: rgba(201, 167, 207, 0.68);
}

.bg-is-sixth-69 {
  background-color: rgba(201, 167, 207, 0.69);
}

.bg-is-sixth-70 {
  background-color: rgba(201, 167, 207, 0.7);
}

.bg-is-sixth-71 {
  background-color: rgba(201, 167, 207, 0.71);
}

.bg-is-sixth-72 {
  background-color: rgba(201, 167, 207, 0.72);
}

.bg-is-sixth-73 {
  background-color: rgba(201, 167, 207, 0.73);
}

.bg-is-sixth-74 {
  background-color: rgba(201, 167, 207, 0.74);
}

.bg-is-sixth-75 {
  background-color: rgba(201, 167, 207, 0.75);
}

.bg-is-sixth-76 {
  background-color: rgba(201, 167, 207, 0.76);
}

.bg-is-sixth-77 {
  background-color: rgba(201, 167, 207, 0.77);
}

.bg-is-sixth-78 {
  background-color: rgba(201, 167, 207, 0.78);
}

.bg-is-sixth-79 {
  background-color: rgba(201, 167, 207, 0.79);
}

.bg-is-sixth-80 {
  background-color: rgba(201, 167, 207, 0.8);
}

.bg-is-sixth-81 {
  background-color: rgba(201, 167, 207, 0.81);
}

.bg-is-sixth-82 {
  background-color: rgba(201, 167, 207, 0.82);
}

.bg-is-sixth-83 {
  background-color: rgba(201, 167, 207, 0.83);
}

.bg-is-sixth-84 {
  background-color: rgba(201, 167, 207, 0.84);
}

.bg-is-sixth-85 {
  background-color: rgba(201, 167, 207, 0.85);
}

.bg-is-sixth-86 {
  background-color: rgba(201, 167, 207, 0.86);
}

.bg-is-sixth-87 {
  background-color: rgba(201, 167, 207, 0.87);
}

.bg-is-sixth-88 {
  background-color: rgba(201, 167, 207, 0.88);
}

.bg-is-sixth-89 {
  background-color: rgba(201, 167, 207, 0.89);
}

.bg-is-sixth-90 {
  background-color: rgba(201, 167, 207, 0.9);
}

.bg-is-sixth-91 {
  background-color: rgba(201, 167, 207, 0.91);
}

.bg-is-sixth-92 {
  background-color: rgba(201, 167, 207, 0.92);
}

.bg-is-sixth-93 {
  background-color: rgba(201, 167, 207, 0.93);
}

.bg-is-sixth-94 {
  background-color: rgba(201, 167, 207, 0.94);
}

.bg-is-sixth-95 {
  background-color: rgba(201, 167, 207, 0.95);
}

.bg-is-sixth-96 {
  background-color: rgba(201, 167, 207, 0.96);
}

.bg-is-sixth-97 {
  background-color: rgba(201, 167, 207, 0.97);
}

.bg-is-sixth-98 {
  background-color: rgba(201, 167, 207, 0.98);
}

.bg-is-sixth-99 {
  background-color: rgba(201, 167, 207, 0.99);
}

.bg-is-sixth-100 {
  background-color: #c9a7cf;
}

.border-is-sixth {
  border-color: #C9A7CF;
}

.is-sixth.typing .typed-cursor {
  color: #C9A7CF;
}

.is-seventh {
  color: #F2F5FA;
}

.bg-is-seventh {
  background-color: #F2F5FA;
}

.bg-is-seventh-0 {
  background-color: rgba(242, 245, 250, 0);
}

.bg-is-seventh-1 {
  background-color: rgba(242, 245, 250, 0.01);
}

.bg-is-seventh-2 {
  background-color: rgba(242, 245, 250, 0.02);
}

.bg-is-seventh-3 {
  background-color: rgba(242, 245, 250, 0.03);
}

.bg-is-seventh-4 {
  background-color: rgba(242, 245, 250, 0.04);
}

.bg-is-seventh-5 {
  background-color: rgba(242, 245, 250, 0.05);
}

.bg-is-seventh-6 {
  background-color: rgba(242, 245, 250, 0.06);
}

.bg-is-seventh-7 {
  background-color: rgba(242, 245, 250, 0.07);
}

.bg-is-seventh-8 {
  background-color: rgba(242, 245, 250, 0.08);
}

.bg-is-seventh-9 {
  background-color: rgba(242, 245, 250, 0.09);
}

.bg-is-seventh-10 {
  background-color: rgba(242, 245, 250, 0.1);
}

.bg-is-seventh-11 {
  background-color: rgba(242, 245, 250, 0.11);
}

.bg-is-seventh-12 {
  background-color: rgba(242, 245, 250, 0.12);
}

.bg-is-seventh-13 {
  background-color: rgba(242, 245, 250, 0.13);
}

.bg-is-seventh-14 {
  background-color: rgba(242, 245, 250, 0.14);
}

.bg-is-seventh-15 {
  background-color: rgba(242, 245, 250, 0.15);
}

.bg-is-seventh-16 {
  background-color: rgba(242, 245, 250, 0.16);
}

.bg-is-seventh-17 {
  background-color: rgba(242, 245, 250, 0.17);
}

.bg-is-seventh-18 {
  background-color: rgba(242, 245, 250, 0.18);
}

.bg-is-seventh-19 {
  background-color: rgba(242, 245, 250, 0.19);
}

.bg-is-seventh-20 {
  background-color: rgba(242, 245, 250, 0.2);
}

.bg-is-seventh-21 {
  background-color: rgba(242, 245, 250, 0.21);
}

.bg-is-seventh-22 {
  background-color: rgba(242, 245, 250, 0.22);
}

.bg-is-seventh-23 {
  background-color: rgba(242, 245, 250, 0.23);
}

.bg-is-seventh-24 {
  background-color: rgba(242, 245, 250, 0.24);
}

.bg-is-seventh-25 {
  background-color: rgba(242, 245, 250, 0.25);
}

.bg-is-seventh-26 {
  background-color: rgba(242, 245, 250, 0.26);
}

.bg-is-seventh-27 {
  background-color: rgba(242, 245, 250, 0.27);
}

.bg-is-seventh-28 {
  background-color: rgba(242, 245, 250, 0.28);
}

.bg-is-seventh-29 {
  background-color: rgba(242, 245, 250, 0.29);
}

.bg-is-seventh-30 {
  background-color: rgba(242, 245, 250, 0.3);
}

.bg-is-seventh-31 {
  background-color: rgba(242, 245, 250, 0.31);
}

.bg-is-seventh-32 {
  background-color: rgba(242, 245, 250, 0.32);
}

.bg-is-seventh-33 {
  background-color: rgba(242, 245, 250, 0.33);
}

.bg-is-seventh-34 {
  background-color: rgba(242, 245, 250, 0.34);
}

.bg-is-seventh-35 {
  background-color: rgba(242, 245, 250, 0.35);
}

.bg-is-seventh-36 {
  background-color: rgba(242, 245, 250, 0.36);
}

.bg-is-seventh-37 {
  background-color: rgba(242, 245, 250, 0.37);
}

.bg-is-seventh-38 {
  background-color: rgba(242, 245, 250, 0.38);
}

.bg-is-seventh-39 {
  background-color: rgba(242, 245, 250, 0.39);
}

.bg-is-seventh-40 {
  background-color: rgba(242, 245, 250, 0.4);
}

.bg-is-seventh-41 {
  background-color: rgba(242, 245, 250, 0.41);
}

.bg-is-seventh-42 {
  background-color: rgba(242, 245, 250, 0.42);
}

.bg-is-seventh-43 {
  background-color: rgba(242, 245, 250, 0.43);
}

.bg-is-seventh-44 {
  background-color: rgba(242, 245, 250, 0.44);
}

.bg-is-seventh-45 {
  background-color: rgba(242, 245, 250, 0.45);
}

.bg-is-seventh-46 {
  background-color: rgba(242, 245, 250, 0.46);
}

.bg-is-seventh-47 {
  background-color: rgba(242, 245, 250, 0.47);
}

.bg-is-seventh-48 {
  background-color: rgba(242, 245, 250, 0.48);
}

.bg-is-seventh-49 {
  background-color: rgba(242, 245, 250, 0.49);
}

.bg-is-seventh-50 {
  background-color: rgba(242, 245, 250, 0.5);
}

.bg-is-seventh-51 {
  background-color: rgba(242, 245, 250, 0.51);
}

.bg-is-seventh-52 {
  background-color: rgba(242, 245, 250, 0.52);
}

.bg-is-seventh-53 {
  background-color: rgba(242, 245, 250, 0.53);
}

.bg-is-seventh-54 {
  background-color: rgba(242, 245, 250, 0.54);
}

.bg-is-seventh-55 {
  background-color: rgba(242, 245, 250, 0.55);
}

.bg-is-seventh-56 {
  background-color: rgba(242, 245, 250, 0.56);
}

.bg-is-seventh-57 {
  background-color: rgba(242, 245, 250, 0.57);
}

.bg-is-seventh-58 {
  background-color: rgba(242, 245, 250, 0.58);
}

.bg-is-seventh-59 {
  background-color: rgba(242, 245, 250, 0.59);
}

.bg-is-seventh-60 {
  background-color: rgba(242, 245, 250, 0.6);
}

.bg-is-seventh-61 {
  background-color: rgba(242, 245, 250, 0.61);
}

.bg-is-seventh-62 {
  background-color: rgba(242, 245, 250, 0.62);
}

.bg-is-seventh-63 {
  background-color: rgba(242, 245, 250, 0.63);
}

.bg-is-seventh-64 {
  background-color: rgba(242, 245, 250, 0.64);
}

.bg-is-seventh-65 {
  background-color: rgba(242, 245, 250, 0.65);
}

.bg-is-seventh-66 {
  background-color: rgba(242, 245, 250, 0.66);
}

.bg-is-seventh-67 {
  background-color: rgba(242, 245, 250, 0.67);
}

.bg-is-seventh-68 {
  background-color: rgba(242, 245, 250, 0.68);
}

.bg-is-seventh-69 {
  background-color: rgba(242, 245, 250, 0.69);
}

.bg-is-seventh-70 {
  background-color: rgba(242, 245, 250, 0.7);
}

.bg-is-seventh-71 {
  background-color: rgba(242, 245, 250, 0.71);
}

.bg-is-seventh-72 {
  background-color: rgba(242, 245, 250, 0.72);
}

.bg-is-seventh-73 {
  background-color: rgba(242, 245, 250, 0.73);
}

.bg-is-seventh-74 {
  background-color: rgba(242, 245, 250, 0.74);
}

.bg-is-seventh-75 {
  background-color: rgba(242, 245, 250, 0.75);
}

.bg-is-seventh-76 {
  background-color: rgba(242, 245, 250, 0.76);
}

.bg-is-seventh-77 {
  background-color: rgba(242, 245, 250, 0.77);
}

.bg-is-seventh-78 {
  background-color: rgba(242, 245, 250, 0.78);
}

.bg-is-seventh-79 {
  background-color: rgba(242, 245, 250, 0.79);
}

.bg-is-seventh-80 {
  background-color: rgba(242, 245, 250, 0.8);
}

.bg-is-seventh-81 {
  background-color: rgba(242, 245, 250, 0.81);
}

.bg-is-seventh-82 {
  background-color: rgba(242, 245, 250, 0.82);
}

.bg-is-seventh-83 {
  background-color: rgba(242, 245, 250, 0.83);
}

.bg-is-seventh-84 {
  background-color: rgba(242, 245, 250, 0.84);
}

.bg-is-seventh-85 {
  background-color: rgba(242, 245, 250, 0.85);
}

.bg-is-seventh-86 {
  background-color: rgba(242, 245, 250, 0.86);
}

.bg-is-seventh-87 {
  background-color: rgba(242, 245, 250, 0.87);
}

.bg-is-seventh-88 {
  background-color: rgba(242, 245, 250, 0.88);
}

.bg-is-seventh-89 {
  background-color: rgba(242, 245, 250, 0.89);
}

.bg-is-seventh-90 {
  background-color: rgba(242, 245, 250, 0.9);
}

.bg-is-seventh-91 {
  background-color: rgba(242, 245, 250, 0.91);
}

.bg-is-seventh-92 {
  background-color: rgba(242, 245, 250, 0.92);
}

.bg-is-seventh-93 {
  background-color: rgba(242, 245, 250, 0.93);
}

.bg-is-seventh-94 {
  background-color: rgba(242, 245, 250, 0.94);
}

.bg-is-seventh-95 {
  background-color: rgba(242, 245, 250, 0.95);
}

.bg-is-seventh-96 {
  background-color: rgba(242, 245, 250, 0.96);
}

.bg-is-seventh-97 {
  background-color: rgba(242, 245, 250, 0.97);
}

.bg-is-seventh-98 {
  background-color: rgba(242, 245, 250, 0.98);
}

.bg-is-seventh-99 {
  background-color: rgba(242, 245, 250, 0.99);
}

.bg-is-seventh-100 {
  background-color: #f2f5fa;
}

.border-is-seventh {
  border-color: #F2F5FA;
}

.is-seventh.typing .typed-cursor {
  color: #F2F5FA;
}

.is-eighth {
  color: #FAF6FB;
}

.bg-is-eighth {
  background-color: #FAF6FB;
}

.bg-is-eighth-0 {
  background-color: rgba(250, 246, 251, 0);
}

.bg-is-eighth-1 {
  background-color: rgba(250, 246, 251, 0.01);
}

.bg-is-eighth-2 {
  background-color: rgba(250, 246, 251, 0.02);
}

.bg-is-eighth-3 {
  background-color: rgba(250, 246, 251, 0.03);
}

.bg-is-eighth-4 {
  background-color: rgba(250, 246, 251, 0.04);
}

.bg-is-eighth-5 {
  background-color: rgba(250, 246, 251, 0.05);
}

.bg-is-eighth-6 {
  background-color: rgba(250, 246, 251, 0.06);
}

.bg-is-eighth-7 {
  background-color: rgba(250, 246, 251, 0.07);
}

.bg-is-eighth-8 {
  background-color: rgba(250, 246, 251, 0.08);
}

.bg-is-eighth-9 {
  background-color: rgba(250, 246, 251, 0.09);
}

.bg-is-eighth-10 {
  background-color: rgba(250, 246, 251, 0.1);
}

.bg-is-eighth-11 {
  background-color: rgba(250, 246, 251, 0.11);
}

.bg-is-eighth-12 {
  background-color: rgba(250, 246, 251, 0.12);
}

.bg-is-eighth-13 {
  background-color: rgba(250, 246, 251, 0.13);
}

.bg-is-eighth-14 {
  background-color: rgba(250, 246, 251, 0.14);
}

.bg-is-eighth-15 {
  background-color: rgba(250, 246, 251, 0.15);
}

.bg-is-eighth-16 {
  background-color: rgba(250, 246, 251, 0.16);
}

.bg-is-eighth-17 {
  background-color: rgba(250, 246, 251, 0.17);
}

.bg-is-eighth-18 {
  background-color: rgba(250, 246, 251, 0.18);
}

.bg-is-eighth-19 {
  background-color: rgba(250, 246, 251, 0.19);
}

.bg-is-eighth-20 {
  background-color: rgba(250, 246, 251, 0.2);
}

.bg-is-eighth-21 {
  background-color: rgba(250, 246, 251, 0.21);
}

.bg-is-eighth-22 {
  background-color: rgba(250, 246, 251, 0.22);
}

.bg-is-eighth-23 {
  background-color: rgba(250, 246, 251, 0.23);
}

.bg-is-eighth-24 {
  background-color: rgba(250, 246, 251, 0.24);
}

.bg-is-eighth-25 {
  background-color: rgba(250, 246, 251, 0.25);
}

.bg-is-eighth-26 {
  background-color: rgba(250, 246, 251, 0.26);
}

.bg-is-eighth-27 {
  background-color: rgba(250, 246, 251, 0.27);
}

.bg-is-eighth-28 {
  background-color: rgba(250, 246, 251, 0.28);
}

.bg-is-eighth-29 {
  background-color: rgba(250, 246, 251, 0.29);
}

.bg-is-eighth-30 {
  background-color: rgba(250, 246, 251, 0.3);
}

.bg-is-eighth-31 {
  background-color: rgba(250, 246, 251, 0.31);
}

.bg-is-eighth-32 {
  background-color: rgba(250, 246, 251, 0.32);
}

.bg-is-eighth-33 {
  background-color: rgba(250, 246, 251, 0.33);
}

.bg-is-eighth-34 {
  background-color: rgba(250, 246, 251, 0.34);
}

.bg-is-eighth-35 {
  background-color: rgba(250, 246, 251, 0.35);
}

.bg-is-eighth-36 {
  background-color: rgba(250, 246, 251, 0.36);
}

.bg-is-eighth-37 {
  background-color: rgba(250, 246, 251, 0.37);
}

.bg-is-eighth-38 {
  background-color: rgba(250, 246, 251, 0.38);
}

.bg-is-eighth-39 {
  background-color: rgba(250, 246, 251, 0.39);
}

.bg-is-eighth-40 {
  background-color: rgba(250, 246, 251, 0.4);
}

.bg-is-eighth-41 {
  background-color: rgba(250, 246, 251, 0.41);
}

.bg-is-eighth-42 {
  background-color: rgba(250, 246, 251, 0.42);
}

.bg-is-eighth-43 {
  background-color: rgba(250, 246, 251, 0.43);
}

.bg-is-eighth-44 {
  background-color: rgba(250, 246, 251, 0.44);
}

.bg-is-eighth-45 {
  background-color: rgba(250, 246, 251, 0.45);
}

.bg-is-eighth-46 {
  background-color: rgba(250, 246, 251, 0.46);
}

.bg-is-eighth-47 {
  background-color: rgba(250, 246, 251, 0.47);
}

.bg-is-eighth-48 {
  background-color: rgba(250, 246, 251, 0.48);
}

.bg-is-eighth-49 {
  background-color: rgba(250, 246, 251, 0.49);
}

.bg-is-eighth-50 {
  background-color: rgba(250, 246, 251, 0.5);
}

.bg-is-eighth-51 {
  background-color: rgba(250, 246, 251, 0.51);
}

.bg-is-eighth-52 {
  background-color: rgba(250, 246, 251, 0.52);
}

.bg-is-eighth-53 {
  background-color: rgba(250, 246, 251, 0.53);
}

.bg-is-eighth-54 {
  background-color: rgba(250, 246, 251, 0.54);
}

.bg-is-eighth-55 {
  background-color: rgba(250, 246, 251, 0.55);
}

.bg-is-eighth-56 {
  background-color: rgba(250, 246, 251, 0.56);
}

.bg-is-eighth-57 {
  background-color: rgba(250, 246, 251, 0.57);
}

.bg-is-eighth-58 {
  background-color: rgba(250, 246, 251, 0.58);
}

.bg-is-eighth-59 {
  background-color: rgba(250, 246, 251, 0.59);
}

.bg-is-eighth-60 {
  background-color: rgba(250, 246, 251, 0.6);
}

.bg-is-eighth-61 {
  background-color: rgba(250, 246, 251, 0.61);
}

.bg-is-eighth-62 {
  background-color: rgba(250, 246, 251, 0.62);
}

.bg-is-eighth-63 {
  background-color: rgba(250, 246, 251, 0.63);
}

.bg-is-eighth-64 {
  background-color: rgba(250, 246, 251, 0.64);
}

.bg-is-eighth-65 {
  background-color: rgba(250, 246, 251, 0.65);
}

.bg-is-eighth-66 {
  background-color: rgba(250, 246, 251, 0.66);
}

.bg-is-eighth-67 {
  background-color: rgba(250, 246, 251, 0.67);
}

.bg-is-eighth-68 {
  background-color: rgba(250, 246, 251, 0.68);
}

.bg-is-eighth-69 {
  background-color: rgba(250, 246, 251, 0.69);
}

.bg-is-eighth-70 {
  background-color: rgba(250, 246, 251, 0.7);
}

.bg-is-eighth-71 {
  background-color: rgba(250, 246, 251, 0.71);
}

.bg-is-eighth-72 {
  background-color: rgba(250, 246, 251, 0.72);
}

.bg-is-eighth-73 {
  background-color: rgba(250, 246, 251, 0.73);
}

.bg-is-eighth-74 {
  background-color: rgba(250, 246, 251, 0.74);
}

.bg-is-eighth-75 {
  background-color: rgba(250, 246, 251, 0.75);
}

.bg-is-eighth-76 {
  background-color: rgba(250, 246, 251, 0.76);
}

.bg-is-eighth-77 {
  background-color: rgba(250, 246, 251, 0.77);
}

.bg-is-eighth-78 {
  background-color: rgba(250, 246, 251, 0.78);
}

.bg-is-eighth-79 {
  background-color: rgba(250, 246, 251, 0.79);
}

.bg-is-eighth-80 {
  background-color: rgba(250, 246, 251, 0.8);
}

.bg-is-eighth-81 {
  background-color: rgba(250, 246, 251, 0.81);
}

.bg-is-eighth-82 {
  background-color: rgba(250, 246, 251, 0.82);
}

.bg-is-eighth-83 {
  background-color: rgba(250, 246, 251, 0.83);
}

.bg-is-eighth-84 {
  background-color: rgba(250, 246, 251, 0.84);
}

.bg-is-eighth-85 {
  background-color: rgba(250, 246, 251, 0.85);
}

.bg-is-eighth-86 {
  background-color: rgba(250, 246, 251, 0.86);
}

.bg-is-eighth-87 {
  background-color: rgba(250, 246, 251, 0.87);
}

.bg-is-eighth-88 {
  background-color: rgba(250, 246, 251, 0.88);
}

.bg-is-eighth-89 {
  background-color: rgba(250, 246, 251, 0.89);
}

.bg-is-eighth-90 {
  background-color: rgba(250, 246, 251, 0.9);
}

.bg-is-eighth-91 {
  background-color: rgba(250, 246, 251, 0.91);
}

.bg-is-eighth-92 {
  background-color: rgba(250, 246, 251, 0.92);
}

.bg-is-eighth-93 {
  background-color: rgba(250, 246, 251, 0.93);
}

.bg-is-eighth-94 {
  background-color: rgba(250, 246, 251, 0.94);
}

.bg-is-eighth-95 {
  background-color: rgba(250, 246, 251, 0.95);
}

.bg-is-eighth-96 {
  background-color: rgba(250, 246, 251, 0.96);
}

.bg-is-eighth-97 {
  background-color: rgba(250, 246, 251, 0.97);
}

.bg-is-eighth-98 {
  background-color: rgba(250, 246, 251, 0.98);
}

.bg-is-eighth-99 {
  background-color: rgba(250, 246, 251, 0.99);
}

.bg-is-eighth-100 {
  background-color: #faf6fb;
}

.border-is-eighth {
  border-color: #FAF6FB;
}

.is-eighth.typing .typed-cursor {
  color: #FAF6FB;
}

.is-nineth {
  color: #A0C0DA;
}

.bg-is-nineth {
  background-color: #A0C0DA;
}

.bg-is-nineth-0 {
  background-color: rgba(160, 192, 218, 0);
}

.bg-is-nineth-1 {
  background-color: rgba(160, 192, 218, 0.01);
}

.bg-is-nineth-2 {
  background-color: rgba(160, 192, 218, 0.02);
}

.bg-is-nineth-3 {
  background-color: rgba(160, 192, 218, 0.03);
}

.bg-is-nineth-4 {
  background-color: rgba(160, 192, 218, 0.04);
}

.bg-is-nineth-5 {
  background-color: rgba(160, 192, 218, 0.05);
}

.bg-is-nineth-6 {
  background-color: rgba(160, 192, 218, 0.06);
}

.bg-is-nineth-7 {
  background-color: rgba(160, 192, 218, 0.07);
}

.bg-is-nineth-8 {
  background-color: rgba(160, 192, 218, 0.08);
}

.bg-is-nineth-9 {
  background-color: rgba(160, 192, 218, 0.09);
}

.bg-is-nineth-10 {
  background-color: rgba(160, 192, 218, 0.1);
}

.bg-is-nineth-11 {
  background-color: rgba(160, 192, 218, 0.11);
}

.bg-is-nineth-12 {
  background-color: rgba(160, 192, 218, 0.12);
}

.bg-is-nineth-13 {
  background-color: rgba(160, 192, 218, 0.13);
}

.bg-is-nineth-14 {
  background-color: rgba(160, 192, 218, 0.14);
}

.bg-is-nineth-15 {
  background-color: rgba(160, 192, 218, 0.15);
}

.bg-is-nineth-16 {
  background-color: rgba(160, 192, 218, 0.16);
}

.bg-is-nineth-17 {
  background-color: rgba(160, 192, 218, 0.17);
}

.bg-is-nineth-18 {
  background-color: rgba(160, 192, 218, 0.18);
}

.bg-is-nineth-19 {
  background-color: rgba(160, 192, 218, 0.19);
}

.bg-is-nineth-20 {
  background-color: rgba(160, 192, 218, 0.2);
}

.bg-is-nineth-21 {
  background-color: rgba(160, 192, 218, 0.21);
}

.bg-is-nineth-22 {
  background-color: rgba(160, 192, 218, 0.22);
}

.bg-is-nineth-23 {
  background-color: rgba(160, 192, 218, 0.23);
}

.bg-is-nineth-24 {
  background-color: rgba(160, 192, 218, 0.24);
}

.bg-is-nineth-25 {
  background-color: rgba(160, 192, 218, 0.25);
}

.bg-is-nineth-26 {
  background-color: rgba(160, 192, 218, 0.26);
}

.bg-is-nineth-27 {
  background-color: rgba(160, 192, 218, 0.27);
}

.bg-is-nineth-28 {
  background-color: rgba(160, 192, 218, 0.28);
}

.bg-is-nineth-29 {
  background-color: rgba(160, 192, 218, 0.29);
}

.bg-is-nineth-30 {
  background-color: rgba(160, 192, 218, 0.3);
}

.bg-is-nineth-31 {
  background-color: rgba(160, 192, 218, 0.31);
}

.bg-is-nineth-32 {
  background-color: rgba(160, 192, 218, 0.32);
}

.bg-is-nineth-33 {
  background-color: rgba(160, 192, 218, 0.33);
}

.bg-is-nineth-34 {
  background-color: rgba(160, 192, 218, 0.34);
}

.bg-is-nineth-35 {
  background-color: rgba(160, 192, 218, 0.35);
}

.bg-is-nineth-36 {
  background-color: rgba(160, 192, 218, 0.36);
}

.bg-is-nineth-37 {
  background-color: rgba(160, 192, 218, 0.37);
}

.bg-is-nineth-38 {
  background-color: rgba(160, 192, 218, 0.38);
}

.bg-is-nineth-39 {
  background-color: rgba(160, 192, 218, 0.39);
}

.bg-is-nineth-40 {
  background-color: rgba(160, 192, 218, 0.4);
}

.bg-is-nineth-41 {
  background-color: rgba(160, 192, 218, 0.41);
}

.bg-is-nineth-42 {
  background-color: rgba(160, 192, 218, 0.42);
}

.bg-is-nineth-43 {
  background-color: rgba(160, 192, 218, 0.43);
}

.bg-is-nineth-44 {
  background-color: rgba(160, 192, 218, 0.44);
}

.bg-is-nineth-45 {
  background-color: rgba(160, 192, 218, 0.45);
}

.bg-is-nineth-46 {
  background-color: rgba(160, 192, 218, 0.46);
}

.bg-is-nineth-47 {
  background-color: rgba(160, 192, 218, 0.47);
}

.bg-is-nineth-48 {
  background-color: rgba(160, 192, 218, 0.48);
}

.bg-is-nineth-49 {
  background-color: rgba(160, 192, 218, 0.49);
}

.bg-is-nineth-50 {
  background-color: rgba(160, 192, 218, 0.5);
}

.bg-is-nineth-51 {
  background-color: rgba(160, 192, 218, 0.51);
}

.bg-is-nineth-52 {
  background-color: rgba(160, 192, 218, 0.52);
}

.bg-is-nineth-53 {
  background-color: rgba(160, 192, 218, 0.53);
}

.bg-is-nineth-54 {
  background-color: rgba(160, 192, 218, 0.54);
}

.bg-is-nineth-55 {
  background-color: rgba(160, 192, 218, 0.55);
}

.bg-is-nineth-56 {
  background-color: rgba(160, 192, 218, 0.56);
}

.bg-is-nineth-57 {
  background-color: rgba(160, 192, 218, 0.57);
}

.bg-is-nineth-58 {
  background-color: rgba(160, 192, 218, 0.58);
}

.bg-is-nineth-59 {
  background-color: rgba(160, 192, 218, 0.59);
}

.bg-is-nineth-60 {
  background-color: rgba(160, 192, 218, 0.6);
}

.bg-is-nineth-61 {
  background-color: rgba(160, 192, 218, 0.61);
}

.bg-is-nineth-62 {
  background-color: rgba(160, 192, 218, 0.62);
}

.bg-is-nineth-63 {
  background-color: rgba(160, 192, 218, 0.63);
}

.bg-is-nineth-64 {
  background-color: rgba(160, 192, 218, 0.64);
}

.bg-is-nineth-65 {
  background-color: rgba(160, 192, 218, 0.65);
}

.bg-is-nineth-66 {
  background-color: rgba(160, 192, 218, 0.66);
}

.bg-is-nineth-67 {
  background-color: rgba(160, 192, 218, 0.67);
}

.bg-is-nineth-68 {
  background-color: rgba(160, 192, 218, 0.68);
}

.bg-is-nineth-69 {
  background-color: rgba(160, 192, 218, 0.69);
}

.bg-is-nineth-70 {
  background-color: rgba(160, 192, 218, 0.7);
}

.bg-is-nineth-71 {
  background-color: rgba(160, 192, 218, 0.71);
}

.bg-is-nineth-72 {
  background-color: rgba(160, 192, 218, 0.72);
}

.bg-is-nineth-73 {
  background-color: rgba(160, 192, 218, 0.73);
}

.bg-is-nineth-74 {
  background-color: rgba(160, 192, 218, 0.74);
}

.bg-is-nineth-75 {
  background-color: rgba(160, 192, 218, 0.75);
}

.bg-is-nineth-76 {
  background-color: rgba(160, 192, 218, 0.76);
}

.bg-is-nineth-77 {
  background-color: rgba(160, 192, 218, 0.77);
}

.bg-is-nineth-78 {
  background-color: rgba(160, 192, 218, 0.78);
}

.bg-is-nineth-79 {
  background-color: rgba(160, 192, 218, 0.79);
}

.bg-is-nineth-80 {
  background-color: rgba(160, 192, 218, 0.8);
}

.bg-is-nineth-81 {
  background-color: rgba(160, 192, 218, 0.81);
}

.bg-is-nineth-82 {
  background-color: rgba(160, 192, 218, 0.82);
}

.bg-is-nineth-83 {
  background-color: rgba(160, 192, 218, 0.83);
}

.bg-is-nineth-84 {
  background-color: rgba(160, 192, 218, 0.84);
}

.bg-is-nineth-85 {
  background-color: rgba(160, 192, 218, 0.85);
}

.bg-is-nineth-86 {
  background-color: rgba(160, 192, 218, 0.86);
}

.bg-is-nineth-87 {
  background-color: rgba(160, 192, 218, 0.87);
}

.bg-is-nineth-88 {
  background-color: rgba(160, 192, 218, 0.88);
}

.bg-is-nineth-89 {
  background-color: rgba(160, 192, 218, 0.89);
}

.bg-is-nineth-90 {
  background-color: rgba(160, 192, 218, 0.9);
}

.bg-is-nineth-91 {
  background-color: rgba(160, 192, 218, 0.91);
}

.bg-is-nineth-92 {
  background-color: rgba(160, 192, 218, 0.92);
}

.bg-is-nineth-93 {
  background-color: rgba(160, 192, 218, 0.93);
}

.bg-is-nineth-94 {
  background-color: rgba(160, 192, 218, 0.94);
}

.bg-is-nineth-95 {
  background-color: rgba(160, 192, 218, 0.95);
}

.bg-is-nineth-96 {
  background-color: rgba(160, 192, 218, 0.96);
}

.bg-is-nineth-97 {
  background-color: rgba(160, 192, 218, 0.97);
}

.bg-is-nineth-98 {
  background-color: rgba(160, 192, 218, 0.98);
}

.bg-is-nineth-99 {
  background-color: rgba(160, 192, 218, 0.99);
}

.bg-is-nineth-100 {
  background-color: #a0c0da;
}

.border-is-nineth {
  border-color: #A0C0DA;
}

.is-nineth.typing .typed-cursor {
  color: #A0C0DA;
}

.filter-is-primary {
  filter: brightness(0) saturate(100%) invert(45%) sepia(69%) saturate(1216%) hue-rotate(191deg) brightness(103%) contrast(101%);
}

.filter-is-secondary {
  filter: brightness(0) saturate(100%) invert(79%) sepia(90%) saturate(272%) hue-rotate(316deg) brightness(96%) contrast(99%);
}

.bg-is-sixth-nineth {
  background: linear-gradient(to right, #c9a7cf, #a0c0da);
}

.mt-8 {
  margin-top: 4.5rem;
}

.mtn-8px {
  margin-top: -8px;
}

.mb-8 {
  margin-bottom: 4.5rem;
}

.gx-8 {
  --bs-gutter-x: 4.5rem;
}

.mb-xs-8 {
  margin-bottom: 4.5rem;
}

.gx-xs-8 {
  --bs-gutter-x: 4.5rem;
}

@media (min-width: 576px) {
  .mb-sm-8 {
    margin-bottom: 4.5rem;
  }
  .gx-sm-8 {
    --bs-gutter-x: 4.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-8 {
    margin-bottom: 4.5rem;
  }
  .gx-md-8 {
    --bs-gutter-x: 4.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-8 {
    margin-bottom: 4.5rem;
  }
  .gx-lg-8 {
    --bs-gutter-x: 4.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-8 {
    margin-bottom: 4.5rem;
  }
  .gx-xl-8 {
    --bs-gutter-x: 4.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-8 {
    margin-bottom: 4.5rem;
  }
  .gx-xxl-8 {
    --bs-gutter-x: 4.5rem;
  }
}
.mt-9 {
  margin-top: 5rem;
}

.mtn-9px {
  margin-top: -9px;
}

.mb-9 {
  margin-bottom: 5rem;
}

.gx-9 {
  --bs-gutter-x: 5rem;
}

.mb-xs-9 {
  margin-bottom: 5rem;
}

.gx-xs-9 {
  --bs-gutter-x: 5rem;
}

@media (min-width: 576px) {
  .mb-sm-9 {
    margin-bottom: 5rem;
  }
  .gx-sm-9 {
    --bs-gutter-x: 5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-9 {
    margin-bottom: 5rem;
  }
  .gx-md-9 {
    --bs-gutter-x: 5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-9 {
    margin-bottom: 5rem;
  }
  .gx-lg-9 {
    --bs-gutter-x: 5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-9 {
    margin-bottom: 5rem;
  }
  .gx-xl-9 {
    --bs-gutter-x: 5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-9 {
    margin-bottom: 5rem;
  }
  .gx-xxl-9 {
    --bs-gutter-x: 5rem;
  }
}
.mt-10 {
  margin-top: 5.5rem;
}

.mtn-10px {
  margin-top: -10px;
}

.mb-10 {
  margin-bottom: 5.5rem;
}

.gx-10 {
  --bs-gutter-x: 5.5rem;
}

.mb-xs-10 {
  margin-bottom: 5.5rem;
}

.gx-xs-10 {
  --bs-gutter-x: 5.5rem;
}

@media (min-width: 576px) {
  .mb-sm-10 {
    margin-bottom: 5.5rem;
  }
  .gx-sm-10 {
    --bs-gutter-x: 5.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-10 {
    margin-bottom: 5.5rem;
  }
  .gx-md-10 {
    --bs-gutter-x: 5.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-10 {
    margin-bottom: 5.5rem;
  }
  .gx-lg-10 {
    --bs-gutter-x: 5.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-10 {
    margin-bottom: 5.5rem;
  }
  .gx-xl-10 {
    --bs-gutter-x: 5.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-10 {
    margin-bottom: 5.5rem;
  }
  .gx-xxl-10 {
    --bs-gutter-x: 5.5rem;
  }
}
.mt-11 {
  margin-top: 6rem;
}

.mtn-11px {
  margin-top: -11px;
}

.mb-11 {
  margin-bottom: 6rem;
}

.gx-11 {
  --bs-gutter-x: 6rem;
}

.mb-xs-11 {
  margin-bottom: 6rem;
}

.gx-xs-11 {
  --bs-gutter-x: 6rem;
}

@media (min-width: 576px) {
  .mb-sm-11 {
    margin-bottom: 6rem;
  }
  .gx-sm-11 {
    --bs-gutter-x: 6rem;
  }
}
@media (min-width: 768px) {
  .mb-md-11 {
    margin-bottom: 6rem;
  }
  .gx-md-11 {
    --bs-gutter-x: 6rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-11 {
    margin-bottom: 6rem;
  }
  .gx-lg-11 {
    --bs-gutter-x: 6rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-11 {
    margin-bottom: 6rem;
  }
  .gx-xl-11 {
    --bs-gutter-x: 6rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-11 {
    margin-bottom: 6rem;
  }
  .gx-xxl-11 {
    --bs-gutter-x: 6rem;
  }
}
.mt-12 {
  margin-top: 6.5rem;
}

.mtn-12px {
  margin-top: -12px;
}

.mb-12 {
  margin-bottom: 6.5rem;
}

.gx-12 {
  --bs-gutter-x: 6.5rem;
}

.mb-xs-12 {
  margin-bottom: 6.5rem;
}

.gx-xs-12 {
  --bs-gutter-x: 6.5rem;
}

@media (min-width: 576px) {
  .mb-sm-12 {
    margin-bottom: 6.5rem;
  }
  .gx-sm-12 {
    --bs-gutter-x: 6.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-12 {
    margin-bottom: 6.5rem;
  }
  .gx-md-12 {
    --bs-gutter-x: 6.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-12 {
    margin-bottom: 6.5rem;
  }
  .gx-lg-12 {
    --bs-gutter-x: 6.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-12 {
    margin-bottom: 6.5rem;
  }
  .gx-xl-12 {
    --bs-gutter-x: 6.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-12 {
    margin-bottom: 6.5rem;
  }
  .gx-xxl-12 {
    --bs-gutter-x: 6.5rem;
  }
}
.mt-13 {
  margin-top: 7rem;
}

.mtn-13px {
  margin-top: -13px;
}

.mb-13 {
  margin-bottom: 7rem;
}

.gx-13 {
  --bs-gutter-x: 7rem;
}

.mb-xs-13 {
  margin-bottom: 7rem;
}

.gx-xs-13 {
  --bs-gutter-x: 7rem;
}

@media (min-width: 576px) {
  .mb-sm-13 {
    margin-bottom: 7rem;
  }
  .gx-sm-13 {
    --bs-gutter-x: 7rem;
  }
}
@media (min-width: 768px) {
  .mb-md-13 {
    margin-bottom: 7rem;
  }
  .gx-md-13 {
    --bs-gutter-x: 7rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-13 {
    margin-bottom: 7rem;
  }
  .gx-lg-13 {
    --bs-gutter-x: 7rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-13 {
    margin-bottom: 7rem;
  }
  .gx-xl-13 {
    --bs-gutter-x: 7rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-13 {
    margin-bottom: 7rem;
  }
  .gx-xxl-13 {
    --bs-gutter-x: 7rem;
  }
}
.mt-14 {
  margin-top: 7.5rem;
}

.mtn-14px {
  margin-top: -14px;
}

.mb-14 {
  margin-bottom: 7.5rem;
}

.gx-14 {
  --bs-gutter-x: 7.5rem;
}

.mb-xs-14 {
  margin-bottom: 7.5rem;
}

.gx-xs-14 {
  --bs-gutter-x: 7.5rem;
}

@media (min-width: 576px) {
  .mb-sm-14 {
    margin-bottom: 7.5rem;
  }
  .gx-sm-14 {
    --bs-gutter-x: 7.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-14 {
    margin-bottom: 7.5rem;
  }
  .gx-md-14 {
    --bs-gutter-x: 7.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-14 {
    margin-bottom: 7.5rem;
  }
  .gx-lg-14 {
    --bs-gutter-x: 7.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-14 {
    margin-bottom: 7.5rem;
  }
  .gx-xl-14 {
    --bs-gutter-x: 7.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-14 {
    margin-bottom: 7.5rem;
  }
  .gx-xxl-14 {
    --bs-gutter-x: 7.5rem;
  }
}
.mt-15 {
  margin-top: 8rem;
}

.mtn-15px {
  margin-top: -15px;
}

.mb-15 {
  margin-bottom: 8rem;
}

.gx-15 {
  --bs-gutter-x: 8rem;
}

.mb-xs-15 {
  margin-bottom: 8rem;
}

.gx-xs-15 {
  --bs-gutter-x: 8rem;
}

@media (min-width: 576px) {
  .mb-sm-15 {
    margin-bottom: 8rem;
  }
  .gx-sm-15 {
    --bs-gutter-x: 8rem;
  }
}
@media (min-width: 768px) {
  .mb-md-15 {
    margin-bottom: 8rem;
  }
  .gx-md-15 {
    --bs-gutter-x: 8rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-15 {
    margin-bottom: 8rem;
  }
  .gx-lg-15 {
    --bs-gutter-x: 8rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-15 {
    margin-bottom: 8rem;
  }
  .gx-xl-15 {
    --bs-gutter-x: 8rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-15 {
    margin-bottom: 8rem;
  }
  .gx-xxl-15 {
    --bs-gutter-x: 8rem;
  }
}
.mt-16 {
  margin-top: 8.5rem;
}

.mtn-16px {
  margin-top: -16px;
}

.mb-16 {
  margin-bottom: 8.5rem;
}

.gx-16 {
  --bs-gutter-x: 8.5rem;
}

.mb-xs-16 {
  margin-bottom: 8.5rem;
}

.gx-xs-16 {
  --bs-gutter-x: 8.5rem;
}

@media (min-width: 576px) {
  .mb-sm-16 {
    margin-bottom: 8.5rem;
  }
  .gx-sm-16 {
    --bs-gutter-x: 8.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-16 {
    margin-bottom: 8.5rem;
  }
  .gx-md-16 {
    --bs-gutter-x: 8.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-16 {
    margin-bottom: 8.5rem;
  }
  .gx-lg-16 {
    --bs-gutter-x: 8.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-16 {
    margin-bottom: 8.5rem;
  }
  .gx-xl-16 {
    --bs-gutter-x: 8.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-16 {
    margin-bottom: 8.5rem;
  }
  .gx-xxl-16 {
    --bs-gutter-x: 8.5rem;
  }
}
.mt-17 {
  margin-top: 9rem;
}

.mtn-17px {
  margin-top: -17px;
}

.mb-17 {
  margin-bottom: 9rem;
}

.gx-17 {
  --bs-gutter-x: 9rem;
}

.mb-xs-17 {
  margin-bottom: 9rem;
}

.gx-xs-17 {
  --bs-gutter-x: 9rem;
}

@media (min-width: 576px) {
  .mb-sm-17 {
    margin-bottom: 9rem;
  }
  .gx-sm-17 {
    --bs-gutter-x: 9rem;
  }
}
@media (min-width: 768px) {
  .mb-md-17 {
    margin-bottom: 9rem;
  }
  .gx-md-17 {
    --bs-gutter-x: 9rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-17 {
    margin-bottom: 9rem;
  }
  .gx-lg-17 {
    --bs-gutter-x: 9rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-17 {
    margin-bottom: 9rem;
  }
  .gx-xl-17 {
    --bs-gutter-x: 9rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-17 {
    margin-bottom: 9rem;
  }
  .gx-xxl-17 {
    --bs-gutter-x: 9rem;
  }
}
.mt-18 {
  margin-top: 9.5rem;
}

.mtn-18px {
  margin-top: -18px;
}

.mb-18 {
  margin-bottom: 9.5rem;
}

.gx-18 {
  --bs-gutter-x: 9.5rem;
}

.mb-xs-18 {
  margin-bottom: 9.5rem;
}

.gx-xs-18 {
  --bs-gutter-x: 9.5rem;
}

@media (min-width: 576px) {
  .mb-sm-18 {
    margin-bottom: 9.5rem;
  }
  .gx-sm-18 {
    --bs-gutter-x: 9.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-18 {
    margin-bottom: 9.5rem;
  }
  .gx-md-18 {
    --bs-gutter-x: 9.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-18 {
    margin-bottom: 9.5rem;
  }
  .gx-lg-18 {
    --bs-gutter-x: 9.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-18 {
    margin-bottom: 9.5rem;
  }
  .gx-xl-18 {
    --bs-gutter-x: 9.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-18 {
    margin-bottom: 9.5rem;
  }
  .gx-xxl-18 {
    --bs-gutter-x: 9.5rem;
  }
}
.mt-19 {
  margin-top: 10rem;
}

.mtn-19px {
  margin-top: -19px;
}

.mb-19 {
  margin-bottom: 10rem;
}

.gx-19 {
  --bs-gutter-x: 10rem;
}

.mb-xs-19 {
  margin-bottom: 10rem;
}

.gx-xs-19 {
  --bs-gutter-x: 10rem;
}

@media (min-width: 576px) {
  .mb-sm-19 {
    margin-bottom: 10rem;
  }
  .gx-sm-19 {
    --bs-gutter-x: 10rem;
  }
}
@media (min-width: 768px) {
  .mb-md-19 {
    margin-bottom: 10rem;
  }
  .gx-md-19 {
    --bs-gutter-x: 10rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-19 {
    margin-bottom: 10rem;
  }
  .gx-lg-19 {
    --bs-gutter-x: 10rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-19 {
    margin-bottom: 10rem;
  }
  .gx-xl-19 {
    --bs-gutter-x: 10rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-19 {
    margin-bottom: 10rem;
  }
  .gx-xxl-19 {
    --bs-gutter-x: 10rem;
  }
}
.mt-20 {
  margin-top: 10.5rem;
}

.mtn-20px {
  margin-top: -20px;
}

.mb-20 {
  margin-bottom: 10.5rem;
}

.gx-20 {
  --bs-gutter-x: 10.5rem;
}

.mb-xs-20 {
  margin-bottom: 10.5rem;
}

.gx-xs-20 {
  --bs-gutter-x: 10.5rem;
}

@media (min-width: 576px) {
  .mb-sm-20 {
    margin-bottom: 10.5rem;
  }
  .gx-sm-20 {
    --bs-gutter-x: 10.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-20 {
    margin-bottom: 10.5rem;
  }
  .gx-md-20 {
    --bs-gutter-x: 10.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-20 {
    margin-bottom: 10.5rem;
  }
  .gx-lg-20 {
    --bs-gutter-x: 10.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-20 {
    margin-bottom: 10.5rem;
  }
  .gx-xl-20 {
    --bs-gutter-x: 10.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-20 {
    margin-bottom: 10.5rem;
  }
  .gx-xxl-20 {
    --bs-gutter-x: 10.5rem;
  }
}
.mt-21 {
  margin-top: 11rem;
}

.mtn-21px {
  margin-top: -21px;
}

.mb-21 {
  margin-bottom: 11rem;
}

.gx-21 {
  --bs-gutter-x: 11rem;
}

.mb-xs-21 {
  margin-bottom: 11rem;
}

.gx-xs-21 {
  --bs-gutter-x: 11rem;
}

@media (min-width: 576px) {
  .mb-sm-21 {
    margin-bottom: 11rem;
  }
  .gx-sm-21 {
    --bs-gutter-x: 11rem;
  }
}
@media (min-width: 768px) {
  .mb-md-21 {
    margin-bottom: 11rem;
  }
  .gx-md-21 {
    --bs-gutter-x: 11rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-21 {
    margin-bottom: 11rem;
  }
  .gx-lg-21 {
    --bs-gutter-x: 11rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-21 {
    margin-bottom: 11rem;
  }
  .gx-xl-21 {
    --bs-gutter-x: 11rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-21 {
    margin-bottom: 11rem;
  }
  .gx-xxl-21 {
    --bs-gutter-x: 11rem;
  }
}
.mt-22 {
  margin-top: 11.5rem;
}

.mtn-22px {
  margin-top: -22px;
}

.mb-22 {
  margin-bottom: 11.5rem;
}

.gx-22 {
  --bs-gutter-x: 11.5rem;
}

.mb-xs-22 {
  margin-bottom: 11.5rem;
}

.gx-xs-22 {
  --bs-gutter-x: 11.5rem;
}

@media (min-width: 576px) {
  .mb-sm-22 {
    margin-bottom: 11.5rem;
  }
  .gx-sm-22 {
    --bs-gutter-x: 11.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-22 {
    margin-bottom: 11.5rem;
  }
  .gx-md-22 {
    --bs-gutter-x: 11.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-22 {
    margin-bottom: 11.5rem;
  }
  .gx-lg-22 {
    --bs-gutter-x: 11.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-22 {
    margin-bottom: 11.5rem;
  }
  .gx-xl-22 {
    --bs-gutter-x: 11.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-22 {
    margin-bottom: 11.5rem;
  }
  .gx-xxl-22 {
    --bs-gutter-x: 11.5rem;
  }
}
.mt-23 {
  margin-top: 12rem;
}

.mtn-23px {
  margin-top: -23px;
}

.mb-23 {
  margin-bottom: 12rem;
}

.gx-23 {
  --bs-gutter-x: 12rem;
}

.mb-xs-23 {
  margin-bottom: 12rem;
}

.gx-xs-23 {
  --bs-gutter-x: 12rem;
}

@media (min-width: 576px) {
  .mb-sm-23 {
    margin-bottom: 12rem;
  }
  .gx-sm-23 {
    --bs-gutter-x: 12rem;
  }
}
@media (min-width: 768px) {
  .mb-md-23 {
    margin-bottom: 12rem;
  }
  .gx-md-23 {
    --bs-gutter-x: 12rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-23 {
    margin-bottom: 12rem;
  }
  .gx-lg-23 {
    --bs-gutter-x: 12rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-23 {
    margin-bottom: 12rem;
  }
  .gx-xl-23 {
    --bs-gutter-x: 12rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-23 {
    margin-bottom: 12rem;
  }
  .gx-xxl-23 {
    --bs-gutter-x: 12rem;
  }
}
.mt-24 {
  margin-top: 12.5rem;
}

.mtn-24px {
  margin-top: -24px;
}

.mb-24 {
  margin-bottom: 12.5rem;
}

.gx-24 {
  --bs-gutter-x: 12.5rem;
}

.mb-xs-24 {
  margin-bottom: 12.5rem;
}

.gx-xs-24 {
  --bs-gutter-x: 12.5rem;
}

@media (min-width: 576px) {
  .mb-sm-24 {
    margin-bottom: 12.5rem;
  }
  .gx-sm-24 {
    --bs-gutter-x: 12.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-24 {
    margin-bottom: 12.5rem;
  }
  .gx-md-24 {
    --bs-gutter-x: 12.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-24 {
    margin-bottom: 12.5rem;
  }
  .gx-lg-24 {
    --bs-gutter-x: 12.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-24 {
    margin-bottom: 12.5rem;
  }
  .gx-xl-24 {
    --bs-gutter-x: 12.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-24 {
    margin-bottom: 12.5rem;
  }
  .gx-xxl-24 {
    --bs-gutter-x: 12.5rem;
  }
}
.mt-25 {
  margin-top: 13rem;
}

.mtn-25px {
  margin-top: -25px;
}

.mb-25 {
  margin-bottom: 13rem;
}

.gx-25 {
  --bs-gutter-x: 13rem;
}

.mb-xs-25 {
  margin-bottom: 13rem;
}

.gx-xs-25 {
  --bs-gutter-x: 13rem;
}

@media (min-width: 576px) {
  .mb-sm-25 {
    margin-bottom: 13rem;
  }
  .gx-sm-25 {
    --bs-gutter-x: 13rem;
  }
}
@media (min-width: 768px) {
  .mb-md-25 {
    margin-bottom: 13rem;
  }
  .gx-md-25 {
    --bs-gutter-x: 13rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-25 {
    margin-bottom: 13rem;
  }
  .gx-lg-25 {
    --bs-gutter-x: 13rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-25 {
    margin-bottom: 13rem;
  }
  .gx-xl-25 {
    --bs-gutter-x: 13rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-25 {
    margin-bottom: 13rem;
  }
  .gx-xxl-25 {
    --bs-gutter-x: 13rem;
  }
}
.mt-26 {
  margin-top: 13.5rem;
}

.mtn-26px {
  margin-top: -26px;
}

.mb-26 {
  margin-bottom: 13.5rem;
}

.gx-26 {
  --bs-gutter-x: 13.5rem;
}

.mb-xs-26 {
  margin-bottom: 13.5rem;
}

.gx-xs-26 {
  --bs-gutter-x: 13.5rem;
}

@media (min-width: 576px) {
  .mb-sm-26 {
    margin-bottom: 13.5rem;
  }
  .gx-sm-26 {
    --bs-gutter-x: 13.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-26 {
    margin-bottom: 13.5rem;
  }
  .gx-md-26 {
    --bs-gutter-x: 13.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-26 {
    margin-bottom: 13.5rem;
  }
  .gx-lg-26 {
    --bs-gutter-x: 13.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-26 {
    margin-bottom: 13.5rem;
  }
  .gx-xl-26 {
    --bs-gutter-x: 13.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-26 {
    margin-bottom: 13.5rem;
  }
  .gx-xxl-26 {
    --bs-gutter-x: 13.5rem;
  }
}
.mt-27 {
  margin-top: 14rem;
}

.mtn-27px {
  margin-top: -27px;
}

.mb-27 {
  margin-bottom: 14rem;
}

.gx-27 {
  --bs-gutter-x: 14rem;
}

.mb-xs-27 {
  margin-bottom: 14rem;
}

.gx-xs-27 {
  --bs-gutter-x: 14rem;
}

@media (min-width: 576px) {
  .mb-sm-27 {
    margin-bottom: 14rem;
  }
  .gx-sm-27 {
    --bs-gutter-x: 14rem;
  }
}
@media (min-width: 768px) {
  .mb-md-27 {
    margin-bottom: 14rem;
  }
  .gx-md-27 {
    --bs-gutter-x: 14rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-27 {
    margin-bottom: 14rem;
  }
  .gx-lg-27 {
    --bs-gutter-x: 14rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-27 {
    margin-bottom: 14rem;
  }
  .gx-xl-27 {
    --bs-gutter-x: 14rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-27 {
    margin-bottom: 14rem;
  }
  .gx-xxl-27 {
    --bs-gutter-x: 14rem;
  }
}
.mt-28 {
  margin-top: 14.5rem;
}

.mtn-28px {
  margin-top: -28px;
}

.mb-28 {
  margin-bottom: 14.5rem;
}

.gx-28 {
  --bs-gutter-x: 14.5rem;
}

.mb-xs-28 {
  margin-bottom: 14.5rem;
}

.gx-xs-28 {
  --bs-gutter-x: 14.5rem;
}

@media (min-width: 576px) {
  .mb-sm-28 {
    margin-bottom: 14.5rem;
  }
  .gx-sm-28 {
    --bs-gutter-x: 14.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-28 {
    margin-bottom: 14.5rem;
  }
  .gx-md-28 {
    --bs-gutter-x: 14.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-28 {
    margin-bottom: 14.5rem;
  }
  .gx-lg-28 {
    --bs-gutter-x: 14.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-28 {
    margin-bottom: 14.5rem;
  }
  .gx-xl-28 {
    --bs-gutter-x: 14.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-28 {
    margin-bottom: 14.5rem;
  }
  .gx-xxl-28 {
    --bs-gutter-x: 14.5rem;
  }
}
.mt-29 {
  margin-top: 15rem;
}

.mtn-29px {
  margin-top: -29px;
}

.mb-29 {
  margin-bottom: 15rem;
}

.gx-29 {
  --bs-gutter-x: 15rem;
}

.mb-xs-29 {
  margin-bottom: 15rem;
}

.gx-xs-29 {
  --bs-gutter-x: 15rem;
}

@media (min-width: 576px) {
  .mb-sm-29 {
    margin-bottom: 15rem;
  }
  .gx-sm-29 {
    --bs-gutter-x: 15rem;
  }
}
@media (min-width: 768px) {
  .mb-md-29 {
    margin-bottom: 15rem;
  }
  .gx-md-29 {
    --bs-gutter-x: 15rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-29 {
    margin-bottom: 15rem;
  }
  .gx-lg-29 {
    --bs-gutter-x: 15rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-29 {
    margin-bottom: 15rem;
  }
  .gx-xl-29 {
    --bs-gutter-x: 15rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-29 {
    margin-bottom: 15rem;
  }
  .gx-xxl-29 {
    --bs-gutter-x: 15rem;
  }
}
.mt-30 {
  margin-top: 15.5rem;
}

.mtn-30px {
  margin-top: -30px;
}

.mb-30 {
  margin-bottom: 15.5rem;
}

.gx-30 {
  --bs-gutter-x: 15.5rem;
}

.mb-xs-30 {
  margin-bottom: 15.5rem;
}

.gx-xs-30 {
  --bs-gutter-x: 15.5rem;
}

@media (min-width: 576px) {
  .mb-sm-30 {
    margin-bottom: 15.5rem;
  }
  .gx-sm-30 {
    --bs-gutter-x: 15.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-30 {
    margin-bottom: 15.5rem;
  }
  .gx-md-30 {
    --bs-gutter-x: 15.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-30 {
    margin-bottom: 15.5rem;
  }
  .gx-lg-30 {
    --bs-gutter-x: 15.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-30 {
    margin-bottom: 15.5rem;
  }
  .gx-xl-30 {
    --bs-gutter-x: 15.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-30 {
    margin-bottom: 15.5rem;
  }
  .gx-xxl-30 {
    --bs-gutter-x: 15.5rem;
  }
}
.mt-31 {
  margin-top: 16rem;
}

.mtn-31px {
  margin-top: -31px;
}

.mb-31 {
  margin-bottom: 16rem;
}

.gx-31 {
  --bs-gutter-x: 16rem;
}

.mb-xs-31 {
  margin-bottom: 16rem;
}

.gx-xs-31 {
  --bs-gutter-x: 16rem;
}

@media (min-width: 576px) {
  .mb-sm-31 {
    margin-bottom: 16rem;
  }
  .gx-sm-31 {
    --bs-gutter-x: 16rem;
  }
}
@media (min-width: 768px) {
  .mb-md-31 {
    margin-bottom: 16rem;
  }
  .gx-md-31 {
    --bs-gutter-x: 16rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-31 {
    margin-bottom: 16rem;
  }
  .gx-lg-31 {
    --bs-gutter-x: 16rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-31 {
    margin-bottom: 16rem;
  }
  .gx-xl-31 {
    --bs-gutter-x: 16rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-31 {
    margin-bottom: 16rem;
  }
  .gx-xxl-31 {
    --bs-gutter-x: 16rem;
  }
}
.mt-32 {
  margin-top: 16.5rem;
}

.mtn-32px {
  margin-top: -32px;
}

.mb-32 {
  margin-bottom: 16.5rem;
}

.gx-32 {
  --bs-gutter-x: 16.5rem;
}

.mb-xs-32 {
  margin-bottom: 16.5rem;
}

.gx-xs-32 {
  --bs-gutter-x: 16.5rem;
}

@media (min-width: 576px) {
  .mb-sm-32 {
    margin-bottom: 16.5rem;
  }
  .gx-sm-32 {
    --bs-gutter-x: 16.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-32 {
    margin-bottom: 16.5rem;
  }
  .gx-md-32 {
    --bs-gutter-x: 16.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-32 {
    margin-bottom: 16.5rem;
  }
  .gx-lg-32 {
    --bs-gutter-x: 16.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-32 {
    margin-bottom: 16.5rem;
  }
  .gx-xl-32 {
    --bs-gutter-x: 16.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-32 {
    margin-bottom: 16.5rem;
  }
  .gx-xxl-32 {
    --bs-gutter-x: 16.5rem;
  }
}
.mt-33 {
  margin-top: 17rem;
}

.mtn-33px {
  margin-top: -33px;
}

.mb-33 {
  margin-bottom: 17rem;
}

.gx-33 {
  --bs-gutter-x: 17rem;
}

.mb-xs-33 {
  margin-bottom: 17rem;
}

.gx-xs-33 {
  --bs-gutter-x: 17rem;
}

@media (min-width: 576px) {
  .mb-sm-33 {
    margin-bottom: 17rem;
  }
  .gx-sm-33 {
    --bs-gutter-x: 17rem;
  }
}
@media (min-width: 768px) {
  .mb-md-33 {
    margin-bottom: 17rem;
  }
  .gx-md-33 {
    --bs-gutter-x: 17rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-33 {
    margin-bottom: 17rem;
  }
  .gx-lg-33 {
    --bs-gutter-x: 17rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-33 {
    margin-bottom: 17rem;
  }
  .gx-xl-33 {
    --bs-gutter-x: 17rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-33 {
    margin-bottom: 17rem;
  }
  .gx-xxl-33 {
    --bs-gutter-x: 17rem;
  }
}
.mt-34 {
  margin-top: 17.5rem;
}

.mtn-34px {
  margin-top: -34px;
}

.mb-34 {
  margin-bottom: 17.5rem;
}

.gx-34 {
  --bs-gutter-x: 17.5rem;
}

.mb-xs-34 {
  margin-bottom: 17.5rem;
}

.gx-xs-34 {
  --bs-gutter-x: 17.5rem;
}

@media (min-width: 576px) {
  .mb-sm-34 {
    margin-bottom: 17.5rem;
  }
  .gx-sm-34 {
    --bs-gutter-x: 17.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-34 {
    margin-bottom: 17.5rem;
  }
  .gx-md-34 {
    --bs-gutter-x: 17.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-34 {
    margin-bottom: 17.5rem;
  }
  .gx-lg-34 {
    --bs-gutter-x: 17.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-34 {
    margin-bottom: 17.5rem;
  }
  .gx-xl-34 {
    --bs-gutter-x: 17.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-34 {
    margin-bottom: 17.5rem;
  }
  .gx-xxl-34 {
    --bs-gutter-x: 17.5rem;
  }
}
.mt-35 {
  margin-top: 18rem;
}

.mtn-35px {
  margin-top: -35px;
}

.mb-35 {
  margin-bottom: 18rem;
}

.gx-35 {
  --bs-gutter-x: 18rem;
}

.mb-xs-35 {
  margin-bottom: 18rem;
}

.gx-xs-35 {
  --bs-gutter-x: 18rem;
}

@media (min-width: 576px) {
  .mb-sm-35 {
    margin-bottom: 18rem;
  }
  .gx-sm-35 {
    --bs-gutter-x: 18rem;
  }
}
@media (min-width: 768px) {
  .mb-md-35 {
    margin-bottom: 18rem;
  }
  .gx-md-35 {
    --bs-gutter-x: 18rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-35 {
    margin-bottom: 18rem;
  }
  .gx-lg-35 {
    --bs-gutter-x: 18rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-35 {
    margin-bottom: 18rem;
  }
  .gx-xl-35 {
    --bs-gutter-x: 18rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-35 {
    margin-bottom: 18rem;
  }
  .gx-xxl-35 {
    --bs-gutter-x: 18rem;
  }
}
.mt-36 {
  margin-top: 18.5rem;
}

.mtn-36px {
  margin-top: -36px;
}

.mb-36 {
  margin-bottom: 18.5rem;
}

.gx-36 {
  --bs-gutter-x: 18.5rem;
}

.mb-xs-36 {
  margin-bottom: 18.5rem;
}

.gx-xs-36 {
  --bs-gutter-x: 18.5rem;
}

@media (min-width: 576px) {
  .mb-sm-36 {
    margin-bottom: 18.5rem;
  }
  .gx-sm-36 {
    --bs-gutter-x: 18.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-36 {
    margin-bottom: 18.5rem;
  }
  .gx-md-36 {
    --bs-gutter-x: 18.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-36 {
    margin-bottom: 18.5rem;
  }
  .gx-lg-36 {
    --bs-gutter-x: 18.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-36 {
    margin-bottom: 18.5rem;
  }
  .gx-xl-36 {
    --bs-gutter-x: 18.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-36 {
    margin-bottom: 18.5rem;
  }
  .gx-xxl-36 {
    --bs-gutter-x: 18.5rem;
  }
}
.mt-37 {
  margin-top: 19rem;
}

.mtn-37px {
  margin-top: -37px;
}

.mb-37 {
  margin-bottom: 19rem;
}

.gx-37 {
  --bs-gutter-x: 19rem;
}

.mb-xs-37 {
  margin-bottom: 19rem;
}

.gx-xs-37 {
  --bs-gutter-x: 19rem;
}

@media (min-width: 576px) {
  .mb-sm-37 {
    margin-bottom: 19rem;
  }
  .gx-sm-37 {
    --bs-gutter-x: 19rem;
  }
}
@media (min-width: 768px) {
  .mb-md-37 {
    margin-bottom: 19rem;
  }
  .gx-md-37 {
    --bs-gutter-x: 19rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-37 {
    margin-bottom: 19rem;
  }
  .gx-lg-37 {
    --bs-gutter-x: 19rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-37 {
    margin-bottom: 19rem;
  }
  .gx-xl-37 {
    --bs-gutter-x: 19rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-37 {
    margin-bottom: 19rem;
  }
  .gx-xxl-37 {
    --bs-gutter-x: 19rem;
  }
}
.mt-38 {
  margin-top: 19.5rem;
}

.mtn-38px {
  margin-top: -38px;
}

.mb-38 {
  margin-bottom: 19.5rem;
}

.gx-38 {
  --bs-gutter-x: 19.5rem;
}

.mb-xs-38 {
  margin-bottom: 19.5rem;
}

.gx-xs-38 {
  --bs-gutter-x: 19.5rem;
}

@media (min-width: 576px) {
  .mb-sm-38 {
    margin-bottom: 19.5rem;
  }
  .gx-sm-38 {
    --bs-gutter-x: 19.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-38 {
    margin-bottom: 19.5rem;
  }
  .gx-md-38 {
    --bs-gutter-x: 19.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-38 {
    margin-bottom: 19.5rem;
  }
  .gx-lg-38 {
    --bs-gutter-x: 19.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-38 {
    margin-bottom: 19.5rem;
  }
  .gx-xl-38 {
    --bs-gutter-x: 19.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-38 {
    margin-bottom: 19.5rem;
  }
  .gx-xxl-38 {
    --bs-gutter-x: 19.5rem;
  }
}
.mt-39 {
  margin-top: 20rem;
}

.mtn-39px {
  margin-top: -39px;
}

.mb-39 {
  margin-bottom: 20rem;
}

.gx-39 {
  --bs-gutter-x: 20rem;
}

.mb-xs-39 {
  margin-bottom: 20rem;
}

.gx-xs-39 {
  --bs-gutter-x: 20rem;
}

@media (min-width: 576px) {
  .mb-sm-39 {
    margin-bottom: 20rem;
  }
  .gx-sm-39 {
    --bs-gutter-x: 20rem;
  }
}
@media (min-width: 768px) {
  .mb-md-39 {
    margin-bottom: 20rem;
  }
  .gx-md-39 {
    --bs-gutter-x: 20rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-39 {
    margin-bottom: 20rem;
  }
  .gx-lg-39 {
    --bs-gutter-x: 20rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-39 {
    margin-bottom: 20rem;
  }
  .gx-xl-39 {
    --bs-gutter-x: 20rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-39 {
    margin-bottom: 20rem;
  }
  .gx-xxl-39 {
    --bs-gutter-x: 20rem;
  }
}
.mt-40 {
  margin-top: 20.5rem;
}

.mtn-40px {
  margin-top: -40px;
}

.mb-40 {
  margin-bottom: 20.5rem;
}

.gx-40 {
  --bs-gutter-x: 20.5rem;
}

.mb-xs-40 {
  margin-bottom: 20.5rem;
}

.gx-xs-40 {
  --bs-gutter-x: 20.5rem;
}

@media (min-width: 576px) {
  .mb-sm-40 {
    margin-bottom: 20.5rem;
  }
  .gx-sm-40 {
    --bs-gutter-x: 20.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-40 {
    margin-bottom: 20.5rem;
  }
  .gx-md-40 {
    --bs-gutter-x: 20.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-40 {
    margin-bottom: 20.5rem;
  }
  .gx-lg-40 {
    --bs-gutter-x: 20.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-40 {
    margin-bottom: 20.5rem;
  }
  .gx-xl-40 {
    --bs-gutter-x: 20.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-40 {
    margin-bottom: 20.5rem;
  }
  .gx-xxl-40 {
    --bs-gutter-x: 20.5rem;
  }
}
.mt-41 {
  margin-top: 21rem;
}

.mtn-41px {
  margin-top: -41px;
}

.mb-41 {
  margin-bottom: 21rem;
}

.gx-41 {
  --bs-gutter-x: 21rem;
}

.mb-xs-41 {
  margin-bottom: 21rem;
}

.gx-xs-41 {
  --bs-gutter-x: 21rem;
}

@media (min-width: 576px) {
  .mb-sm-41 {
    margin-bottom: 21rem;
  }
  .gx-sm-41 {
    --bs-gutter-x: 21rem;
  }
}
@media (min-width: 768px) {
  .mb-md-41 {
    margin-bottom: 21rem;
  }
  .gx-md-41 {
    --bs-gutter-x: 21rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-41 {
    margin-bottom: 21rem;
  }
  .gx-lg-41 {
    --bs-gutter-x: 21rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-41 {
    margin-bottom: 21rem;
  }
  .gx-xl-41 {
    --bs-gutter-x: 21rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-41 {
    margin-bottom: 21rem;
  }
  .gx-xxl-41 {
    --bs-gutter-x: 21rem;
  }
}
.mt-42 {
  margin-top: 21.5rem;
}

.mtn-42px {
  margin-top: -42px;
}

.mb-42 {
  margin-bottom: 21.5rem;
}

.gx-42 {
  --bs-gutter-x: 21.5rem;
}

.mb-xs-42 {
  margin-bottom: 21.5rem;
}

.gx-xs-42 {
  --bs-gutter-x: 21.5rem;
}

@media (min-width: 576px) {
  .mb-sm-42 {
    margin-bottom: 21.5rem;
  }
  .gx-sm-42 {
    --bs-gutter-x: 21.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-42 {
    margin-bottom: 21.5rem;
  }
  .gx-md-42 {
    --bs-gutter-x: 21.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-42 {
    margin-bottom: 21.5rem;
  }
  .gx-lg-42 {
    --bs-gutter-x: 21.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-42 {
    margin-bottom: 21.5rem;
  }
  .gx-xl-42 {
    --bs-gutter-x: 21.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-42 {
    margin-bottom: 21.5rem;
  }
  .gx-xxl-42 {
    --bs-gutter-x: 21.5rem;
  }
}
.mt-43 {
  margin-top: 22rem;
}

.mtn-43px {
  margin-top: -43px;
}

.mb-43 {
  margin-bottom: 22rem;
}

.gx-43 {
  --bs-gutter-x: 22rem;
}

.mb-xs-43 {
  margin-bottom: 22rem;
}

.gx-xs-43 {
  --bs-gutter-x: 22rem;
}

@media (min-width: 576px) {
  .mb-sm-43 {
    margin-bottom: 22rem;
  }
  .gx-sm-43 {
    --bs-gutter-x: 22rem;
  }
}
@media (min-width: 768px) {
  .mb-md-43 {
    margin-bottom: 22rem;
  }
  .gx-md-43 {
    --bs-gutter-x: 22rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-43 {
    margin-bottom: 22rem;
  }
  .gx-lg-43 {
    --bs-gutter-x: 22rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-43 {
    margin-bottom: 22rem;
  }
  .gx-xl-43 {
    --bs-gutter-x: 22rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-43 {
    margin-bottom: 22rem;
  }
  .gx-xxl-43 {
    --bs-gutter-x: 22rem;
  }
}
.mt-44 {
  margin-top: 22.5rem;
}

.mtn-44px {
  margin-top: -44px;
}

.mb-44 {
  margin-bottom: 22.5rem;
}

.gx-44 {
  --bs-gutter-x: 22.5rem;
}

.mb-xs-44 {
  margin-bottom: 22.5rem;
}

.gx-xs-44 {
  --bs-gutter-x: 22.5rem;
}

@media (min-width: 576px) {
  .mb-sm-44 {
    margin-bottom: 22.5rem;
  }
  .gx-sm-44 {
    --bs-gutter-x: 22.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-44 {
    margin-bottom: 22.5rem;
  }
  .gx-md-44 {
    --bs-gutter-x: 22.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-44 {
    margin-bottom: 22.5rem;
  }
  .gx-lg-44 {
    --bs-gutter-x: 22.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-44 {
    margin-bottom: 22.5rem;
  }
  .gx-xl-44 {
    --bs-gutter-x: 22.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-44 {
    margin-bottom: 22.5rem;
  }
  .gx-xxl-44 {
    --bs-gutter-x: 22.5rem;
  }
}
.mt-45 {
  margin-top: 23rem;
}

.mtn-45px {
  margin-top: -45px;
}

.mb-45 {
  margin-bottom: 23rem;
}

.gx-45 {
  --bs-gutter-x: 23rem;
}

.mb-xs-45 {
  margin-bottom: 23rem;
}

.gx-xs-45 {
  --bs-gutter-x: 23rem;
}

@media (min-width: 576px) {
  .mb-sm-45 {
    margin-bottom: 23rem;
  }
  .gx-sm-45 {
    --bs-gutter-x: 23rem;
  }
}
@media (min-width: 768px) {
  .mb-md-45 {
    margin-bottom: 23rem;
  }
  .gx-md-45 {
    --bs-gutter-x: 23rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-45 {
    margin-bottom: 23rem;
  }
  .gx-lg-45 {
    --bs-gutter-x: 23rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-45 {
    margin-bottom: 23rem;
  }
  .gx-xl-45 {
    --bs-gutter-x: 23rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-45 {
    margin-bottom: 23rem;
  }
  .gx-xxl-45 {
    --bs-gutter-x: 23rem;
  }
}
.mt-46 {
  margin-top: 23.5rem;
}

.mtn-46px {
  margin-top: -46px;
}

.mb-46 {
  margin-bottom: 23.5rem;
}

.gx-46 {
  --bs-gutter-x: 23.5rem;
}

.mb-xs-46 {
  margin-bottom: 23.5rem;
}

.gx-xs-46 {
  --bs-gutter-x: 23.5rem;
}

@media (min-width: 576px) {
  .mb-sm-46 {
    margin-bottom: 23.5rem;
  }
  .gx-sm-46 {
    --bs-gutter-x: 23.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-46 {
    margin-bottom: 23.5rem;
  }
  .gx-md-46 {
    --bs-gutter-x: 23.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-46 {
    margin-bottom: 23.5rem;
  }
  .gx-lg-46 {
    --bs-gutter-x: 23.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-46 {
    margin-bottom: 23.5rem;
  }
  .gx-xl-46 {
    --bs-gutter-x: 23.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-46 {
    margin-bottom: 23.5rem;
  }
  .gx-xxl-46 {
    --bs-gutter-x: 23.5rem;
  }
}
.mt-47 {
  margin-top: 24rem;
}

.mtn-47px {
  margin-top: -47px;
}

.mb-47 {
  margin-bottom: 24rem;
}

.gx-47 {
  --bs-gutter-x: 24rem;
}

.mb-xs-47 {
  margin-bottom: 24rem;
}

.gx-xs-47 {
  --bs-gutter-x: 24rem;
}

@media (min-width: 576px) {
  .mb-sm-47 {
    margin-bottom: 24rem;
  }
  .gx-sm-47 {
    --bs-gutter-x: 24rem;
  }
}
@media (min-width: 768px) {
  .mb-md-47 {
    margin-bottom: 24rem;
  }
  .gx-md-47 {
    --bs-gutter-x: 24rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-47 {
    margin-bottom: 24rem;
  }
  .gx-lg-47 {
    --bs-gutter-x: 24rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-47 {
    margin-bottom: 24rem;
  }
  .gx-xl-47 {
    --bs-gutter-x: 24rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-47 {
    margin-bottom: 24rem;
  }
  .gx-xxl-47 {
    --bs-gutter-x: 24rem;
  }
}
.mt-48 {
  margin-top: 24.5rem;
}

.mtn-48px {
  margin-top: -48px;
}

.mb-48 {
  margin-bottom: 24.5rem;
}

.gx-48 {
  --bs-gutter-x: 24.5rem;
}

.mb-xs-48 {
  margin-bottom: 24.5rem;
}

.gx-xs-48 {
  --bs-gutter-x: 24.5rem;
}

@media (min-width: 576px) {
  .mb-sm-48 {
    margin-bottom: 24.5rem;
  }
  .gx-sm-48 {
    --bs-gutter-x: 24.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-48 {
    margin-bottom: 24.5rem;
  }
  .gx-md-48 {
    --bs-gutter-x: 24.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-48 {
    margin-bottom: 24.5rem;
  }
  .gx-lg-48 {
    --bs-gutter-x: 24.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-48 {
    margin-bottom: 24.5rem;
  }
  .gx-xl-48 {
    --bs-gutter-x: 24.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-48 {
    margin-bottom: 24.5rem;
  }
  .gx-xxl-48 {
    --bs-gutter-x: 24.5rem;
  }
}
.mt-49 {
  margin-top: 25rem;
}

.mtn-49px {
  margin-top: -49px;
}

.mb-49 {
  margin-bottom: 25rem;
}

.gx-49 {
  --bs-gutter-x: 25rem;
}

.mb-xs-49 {
  margin-bottom: 25rem;
}

.gx-xs-49 {
  --bs-gutter-x: 25rem;
}

@media (min-width: 576px) {
  .mb-sm-49 {
    margin-bottom: 25rem;
  }
  .gx-sm-49 {
    --bs-gutter-x: 25rem;
  }
}
@media (min-width: 768px) {
  .mb-md-49 {
    margin-bottom: 25rem;
  }
  .gx-md-49 {
    --bs-gutter-x: 25rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-49 {
    margin-bottom: 25rem;
  }
  .gx-lg-49 {
    --bs-gutter-x: 25rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-49 {
    margin-bottom: 25rem;
  }
  .gx-xl-49 {
    --bs-gutter-x: 25rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-49 {
    margin-bottom: 25rem;
  }
  .gx-xxl-49 {
    --bs-gutter-x: 25rem;
  }
}
.mt-50 {
  margin-top: 25.5rem;
}

.mtn-50px {
  margin-top: -50px;
}

.mb-50 {
  margin-bottom: 25.5rem;
}

.gx-50 {
  --bs-gutter-x: 25.5rem;
}

.mb-xs-50 {
  margin-bottom: 25.5rem;
}

.gx-xs-50 {
  --bs-gutter-x: 25.5rem;
}

@media (min-width: 576px) {
  .mb-sm-50 {
    margin-bottom: 25.5rem;
  }
  .gx-sm-50 {
    --bs-gutter-x: 25.5rem;
  }
}
@media (min-width: 768px) {
  .mb-md-50 {
    margin-bottom: 25.5rem;
  }
  .gx-md-50 {
    --bs-gutter-x: 25.5rem;
  }
}
@media (min-width: 992px) {
  .mb-lg-50 {
    margin-bottom: 25.5rem;
  }
  .gx-lg-50 {
    --bs-gutter-x: 25.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-50 {
    margin-bottom: 25.5rem;
  }
  .gx-xl-50 {
    --bs-gutter-x: 25.5rem;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-50 {
    margin-bottom: 25.5rem;
  }
  .gx-xxl-50 {
    --bs-gutter-x: 25.5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-n15 {
    margin-top: -7rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-20 {
    margin-bottom: 10.5rem !important;
  }
}
@media (min-width: 992px) {
  .mb-lg-0 {
    margin-bottom: 0rem !important;
  }
}
/* purgecss start ignore */
* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  position: relative;
  min-height: 100%;
}
html:focus {
  outline: none;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
}

p, span, ul {
  color: #242939;
}

.typed-cursor {
  color: #3699FF;
}

.panel-default > .panel-heading {
  color: #333;
  background-color: #fff;
  border-color: #e4e5e7;
  padding: 0;
  user-select: none;
}

.panel-default > .panel-heading a {
  display: block;
  padding: 10px 15px;
}
.panel-default > .panel-heading a::after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  transition: transform 0.25s linear;
  -webkit-transition: -webkit-transform 0.25s linear;
}

.panel-default > .panel-heading a[aria-expanded=true] {
  background-color: #eee;
}

.panel-default > .panel-heading a[aria-expanded=true]:after {
  content: "−";
  transform: rotate(180deg);
}

.panel-default > .panel-heading a[aria-expanded=false]:after {
  content: "+";
  transform: rotate(90deg);
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.rotate-90 {
  transform: rotate(-90deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[data-popper-placement^=top],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #21325b;
}

.bs-tooltip-auto[data-popper-placement^=right],
.bs-tooltip-end {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #21325b;
}

.bs-tooltip-auto[data-popper-placement^=bottom],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #21325b;
}

.bs-tooltip-auto[data-popper-placement^=left],
.bs-tooltip-start {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #21325b;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #21325b;
  border-radius: 0.5rem;
}

/* purgecss end ignore */
.btn.bg-is-primary:hover {
  color: #3699FF !important;
  border-color: #3699FF;
}
.btn.bg-is-secondary:hover {
  color: #F5CA99 !important;
  border-color: #F5CA99;
}
.btn.bg-is-third:hover {
  color: #242939 !important;
  border-color: #242939;
}
.btn.bg-is-fourth:hover {
  color: #377DFF !important;
  border-color: #377DFF;
}
.btn.bg-is-fifth:hover {
  color: #9FC0DA !important;
  border-color: #9FC0DA;
}
.btn.bg-is-sixth:hover {
  color: #C9A7CF !important;
  border-color: #C9A7CF;
}
.btn.bg-is-seventh:hover {
  color: #F2F5FA !important;
  border-color: #F2F5FA;
}
.btn.bg-is-eighth:hover {
  color: #FAF6FB !important;
  border-color: #FAF6FB;
}
.btn.bg-is-nineth:hover {
  color: #A0C0DA !important;
  border-color: #A0C0DA;
}

.back-to-top {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  z-index: 2;
}

.w-0 {
  width: 0%;
}

.w-0rem {
  width: 0rem;
}

.w-0px {
  width: 0px;
}

.w-1 {
  width: 1%;
}

.w-1rem {
  width: 1rem;
}

.w-1px {
  width: 1px;
}

.w-2 {
  width: 2%;
}

.w-2rem {
  width: 2rem;
}

.w-2px {
  width: 2px;
}

.w-3 {
  width: 3%;
}

.w-3rem {
  width: 3rem;
}

.w-3px {
  width: 3px;
}

.w-4 {
  width: 4%;
}

.w-4rem {
  width: 4rem;
}

.w-4px {
  width: 4px;
}

.w-5 {
  width: 5%;
}

.w-5rem {
  width: 5rem;
}

.w-5px {
  width: 5px;
}

.w-6 {
  width: 6%;
}

.w-6rem {
  width: 6rem;
}

.w-6px {
  width: 6px;
}

.w-7 {
  width: 7%;
}

.w-7rem {
  width: 7rem;
}

.w-7px {
  width: 7px;
}

.w-8 {
  width: 8%;
}

.w-8rem {
  width: 8rem;
}

.w-8px {
  width: 8px;
}

.w-9 {
  width: 9%;
}

.w-9rem {
  width: 9rem;
}

.w-9px {
  width: 9px;
}

.w-10 {
  width: 10%;
}

.w-10rem {
  width: 10rem;
}

.w-10px {
  width: 10px;
}

.w-11 {
  width: 11%;
}

.w-11rem {
  width: 11rem;
}

.w-11px {
  width: 11px;
}

.w-12 {
  width: 12%;
}

.w-12rem {
  width: 12rem;
}

.w-12px {
  width: 12px;
}

.w-13 {
  width: 13%;
}

.w-13rem {
  width: 13rem;
}

.w-13px {
  width: 13px;
}

.w-14 {
  width: 14%;
}

.w-14rem {
  width: 14rem;
}

.w-14px {
  width: 14px;
}

.w-15 {
  width: 15%;
}

.w-15rem {
  width: 15rem;
}

.w-15px {
  width: 15px;
}

.w-16 {
  width: 16%;
}

.w-16rem {
  width: 16rem;
}

.w-16px {
  width: 16px;
}

.w-17 {
  width: 17%;
}

.w-17rem {
  width: 17rem;
}

.w-17px {
  width: 17px;
}

.w-18 {
  width: 18%;
}

.w-18rem {
  width: 18rem;
}

.w-18px {
  width: 18px;
}

.w-19 {
  width: 19%;
}

.w-19rem {
  width: 19rem;
}

.w-19px {
  width: 19px;
}

.w-20 {
  width: 20%;
}

.w-20rem {
  width: 20rem;
}

.w-20px {
  width: 20px;
}

.w-21 {
  width: 21%;
}

.w-21rem {
  width: 21rem;
}

.w-21px {
  width: 21px;
}

.w-22 {
  width: 22%;
}

.w-22rem {
  width: 22rem;
}

.w-22px {
  width: 22px;
}

.w-23 {
  width: 23%;
}

.w-23rem {
  width: 23rem;
}

.w-23px {
  width: 23px;
}

.w-24 {
  width: 24%;
}

.w-24rem {
  width: 24rem;
}

.w-24px {
  width: 24px;
}

.w-25 {
  width: 25%;
}

.w-25rem {
  width: 25rem;
}

.w-25px {
  width: 25px;
}

.w-26 {
  width: 26%;
}

.w-26rem {
  width: 26rem;
}

.w-26px {
  width: 26px;
}

.w-27 {
  width: 27%;
}

.w-27rem {
  width: 27rem;
}

.w-27px {
  width: 27px;
}

.w-28 {
  width: 28%;
}

.w-28rem {
  width: 28rem;
}

.w-28px {
  width: 28px;
}

.w-29 {
  width: 29%;
}

.w-29rem {
  width: 29rem;
}

.w-29px {
  width: 29px;
}

.w-30 {
  width: 30%;
}

.w-30rem {
  width: 30rem;
}

.w-30px {
  width: 30px;
}

.w-31 {
  width: 31%;
}

.w-31rem {
  width: 31rem;
}

.w-31px {
  width: 31px;
}

.w-32 {
  width: 32%;
}

.w-32rem {
  width: 32rem;
}

.w-32px {
  width: 32px;
}

.w-33 {
  width: 33%;
}

.w-33rem {
  width: 33rem;
}

.w-33px {
  width: 33px;
}

.w-34 {
  width: 34%;
}

.w-34rem {
  width: 34rem;
}

.w-34px {
  width: 34px;
}

.w-35 {
  width: 35%;
}

.w-35rem {
  width: 35rem;
}

.w-35px {
  width: 35px;
}

.w-36 {
  width: 36%;
}

.w-36rem {
  width: 36rem;
}

.w-36px {
  width: 36px;
}

.w-37 {
  width: 37%;
}

.w-37rem {
  width: 37rem;
}

.w-37px {
  width: 37px;
}

.w-38 {
  width: 38%;
}

.w-38rem {
  width: 38rem;
}

.w-38px {
  width: 38px;
}

.w-39 {
  width: 39%;
}

.w-39rem {
  width: 39rem;
}

.w-39px {
  width: 39px;
}

.w-40 {
  width: 40%;
}

.w-40rem {
  width: 40rem;
}

.w-40px {
  width: 40px;
}

.w-41 {
  width: 41%;
}

.w-41rem {
  width: 41rem;
}

.w-41px {
  width: 41px;
}

.w-42 {
  width: 42%;
}

.w-42rem {
  width: 42rem;
}

.w-42px {
  width: 42px;
}

.w-43 {
  width: 43%;
}

.w-43rem {
  width: 43rem;
}

.w-43px {
  width: 43px;
}

.w-44 {
  width: 44%;
}

.w-44rem {
  width: 44rem;
}

.w-44px {
  width: 44px;
}

.w-45 {
  width: 45%;
}

.w-45rem {
  width: 45rem;
}

.w-45px {
  width: 45px;
}

.w-46 {
  width: 46%;
}

.w-46rem {
  width: 46rem;
}

.w-46px {
  width: 46px;
}

.w-47 {
  width: 47%;
}

.w-47rem {
  width: 47rem;
}

.w-47px {
  width: 47px;
}

.w-48 {
  width: 48%;
}

.w-48rem {
  width: 48rem;
}

.w-48px {
  width: 48px;
}

.w-49 {
  width: 49%;
}

.w-49rem {
  width: 49rem;
}

.w-49px {
  width: 49px;
}

.w-50 {
  width: 50%;
}

.w-50rem {
  width: 50rem;
}

.w-50px {
  width: 50px;
}

.w-51 {
  width: 51%;
}

.w-51rem {
  width: 51rem;
}

.w-51px {
  width: 51px;
}

.w-52 {
  width: 52%;
}

.w-52rem {
  width: 52rem;
}

.w-52px {
  width: 52px;
}

.w-53 {
  width: 53%;
}

.w-53rem {
  width: 53rem;
}

.w-53px {
  width: 53px;
}

.w-54 {
  width: 54%;
}

.w-54rem {
  width: 54rem;
}

.w-54px {
  width: 54px;
}

.w-55 {
  width: 55%;
}

.w-55rem {
  width: 55rem;
}

.w-55px {
  width: 55px;
}

.w-56 {
  width: 56%;
}

.w-56rem {
  width: 56rem;
}

.w-56px {
  width: 56px;
}

.w-57 {
  width: 57%;
}

.w-57rem {
  width: 57rem;
}

.w-57px {
  width: 57px;
}

.w-58 {
  width: 58%;
}

.w-58rem {
  width: 58rem;
}

.w-58px {
  width: 58px;
}

.w-59 {
  width: 59%;
}

.w-59rem {
  width: 59rem;
}

.w-59px {
  width: 59px;
}

.w-60 {
  width: 60%;
}

.w-60rem {
  width: 60rem;
}

.w-60px {
  width: 60px;
}

.w-61 {
  width: 61%;
}

.w-61rem {
  width: 61rem;
}

.w-61px {
  width: 61px;
}

.w-62 {
  width: 62%;
}

.w-62rem {
  width: 62rem;
}

.w-62px {
  width: 62px;
}

.w-63 {
  width: 63%;
}

.w-63rem {
  width: 63rem;
}

.w-63px {
  width: 63px;
}

.w-64 {
  width: 64%;
}

.w-64rem {
  width: 64rem;
}

.w-64px {
  width: 64px;
}

.w-65 {
  width: 65%;
}

.w-65rem {
  width: 65rem;
}

.w-65px {
  width: 65px;
}

.w-66 {
  width: 66%;
}

.w-66rem {
  width: 66rem;
}

.w-66px {
  width: 66px;
}

.w-67 {
  width: 67%;
}

.w-67rem {
  width: 67rem;
}

.w-67px {
  width: 67px;
}

.w-68 {
  width: 68%;
}

.w-68rem {
  width: 68rem;
}

.w-68px {
  width: 68px;
}

.w-69 {
  width: 69%;
}

.w-69rem {
  width: 69rem;
}

.w-69px {
  width: 69px;
}

.w-70 {
  width: 70%;
}

.w-70rem {
  width: 70rem;
}

.w-70px {
  width: 70px;
}

.w-71 {
  width: 71%;
}

.w-71rem {
  width: 71rem;
}

.w-71px {
  width: 71px;
}

.w-72 {
  width: 72%;
}

.w-72rem {
  width: 72rem;
}

.w-72px {
  width: 72px;
}

.w-73 {
  width: 73%;
}

.w-73rem {
  width: 73rem;
}

.w-73px {
  width: 73px;
}

.w-74 {
  width: 74%;
}

.w-74rem {
  width: 74rem;
}

.w-74px {
  width: 74px;
}

.w-75 {
  width: 75%;
}

.w-75rem {
  width: 75rem;
}

.w-75px {
  width: 75px;
}

.w-76 {
  width: 76%;
}

.w-76rem {
  width: 76rem;
}

.w-76px {
  width: 76px;
}

.w-77 {
  width: 77%;
}

.w-77rem {
  width: 77rem;
}

.w-77px {
  width: 77px;
}

.w-78 {
  width: 78%;
}

.w-78rem {
  width: 78rem;
}

.w-78px {
  width: 78px;
}

.w-79 {
  width: 79%;
}

.w-79rem {
  width: 79rem;
}

.w-79px {
  width: 79px;
}

.w-80 {
  width: 80%;
}

.w-80rem {
  width: 80rem;
}

.w-80px {
  width: 80px;
}

.w-81 {
  width: 81%;
}

.w-81rem {
  width: 81rem;
}

.w-81px {
  width: 81px;
}

.w-82 {
  width: 82%;
}

.w-82rem {
  width: 82rem;
}

.w-82px {
  width: 82px;
}

.w-83 {
  width: 83%;
}

.w-83rem {
  width: 83rem;
}

.w-83px {
  width: 83px;
}

.w-84 {
  width: 84%;
}

.w-84rem {
  width: 84rem;
}

.w-84px {
  width: 84px;
}

.w-85 {
  width: 85%;
}

.w-85rem {
  width: 85rem;
}

.w-85px {
  width: 85px;
}

.w-86 {
  width: 86%;
}

.w-86rem {
  width: 86rem;
}

.w-86px {
  width: 86px;
}

.w-87 {
  width: 87%;
}

.w-87rem {
  width: 87rem;
}

.w-87px {
  width: 87px;
}

.w-88 {
  width: 88%;
}

.w-88rem {
  width: 88rem;
}

.w-88px {
  width: 88px;
}

.w-89 {
  width: 89%;
}

.w-89rem {
  width: 89rem;
}

.w-89px {
  width: 89px;
}

.w-90 {
  width: 90%;
}

.w-90rem {
  width: 90rem;
}

.w-90px {
  width: 90px;
}

.w-91 {
  width: 91%;
}

.w-91rem {
  width: 91rem;
}

.w-91px {
  width: 91px;
}

.w-92 {
  width: 92%;
}

.w-92rem {
  width: 92rem;
}

.w-92px {
  width: 92px;
}

.w-93 {
  width: 93%;
}

.w-93rem {
  width: 93rem;
}

.w-93px {
  width: 93px;
}

.w-94 {
  width: 94%;
}

.w-94rem {
  width: 94rem;
}

.w-94px {
  width: 94px;
}

.w-95 {
  width: 95%;
}

.w-95rem {
  width: 95rem;
}

.w-95px {
  width: 95px;
}

.w-96 {
  width: 96%;
}

.w-96rem {
  width: 96rem;
}

.w-96px {
  width: 96px;
}

.w-97 {
  width: 97%;
}

.w-97rem {
  width: 97rem;
}

.w-97px {
  width: 97px;
}

.w-98 {
  width: 98%;
}

.w-98rem {
  width: 98rem;
}

.w-98px {
  width: 98px;
}

.w-99 {
  width: 99%;
}

.w-99rem {
  width: 99rem;
}

.w-99px {
  width: 99px;
}

.w-100 {
  width: 100%;
}

.w-100rem {
  width: 100rem;
}

.w-100px {
  width: 100px;
}

.w-101 {
  width: 101%;
}

.w-101rem {
  width: 101rem;
}

.w-101px {
  width: 101px;
}

.w-102 {
  width: 102%;
}

.w-102rem {
  width: 102rem;
}

.w-102px {
  width: 102px;
}

.w-103 {
  width: 103%;
}

.w-103rem {
  width: 103rem;
}

.w-103px {
  width: 103px;
}

.w-104 {
  width: 104%;
}

.w-104rem {
  width: 104rem;
}

.w-104px {
  width: 104px;
}

.w-105 {
  width: 105%;
}

.w-105rem {
  width: 105rem;
}

.w-105px {
  width: 105px;
}

.w-106 {
  width: 106%;
}

.w-106rem {
  width: 106rem;
}

.w-106px {
  width: 106px;
}

.w-107 {
  width: 107%;
}

.w-107rem {
  width: 107rem;
}

.w-107px {
  width: 107px;
}

.w-108 {
  width: 108%;
}

.w-108rem {
  width: 108rem;
}

.w-108px {
  width: 108px;
}

.w-109 {
  width: 109%;
}

.w-109rem {
  width: 109rem;
}

.w-109px {
  width: 109px;
}

.w-110 {
  width: 110%;
}

.w-110rem {
  width: 110rem;
}

.w-110px {
  width: 110px;
}

.w-111 {
  width: 111%;
}

.w-111rem {
  width: 111rem;
}

.w-111px {
  width: 111px;
}

.w-112 {
  width: 112%;
}

.w-112rem {
  width: 112rem;
}

.w-112px {
  width: 112px;
}

.w-113 {
  width: 113%;
}

.w-113rem {
  width: 113rem;
}

.w-113px {
  width: 113px;
}

.w-114 {
  width: 114%;
}

.w-114rem {
  width: 114rem;
}

.w-114px {
  width: 114px;
}

.w-115 {
  width: 115%;
}

.w-115rem {
  width: 115rem;
}

.w-115px {
  width: 115px;
}

.w-116 {
  width: 116%;
}

.w-116rem {
  width: 116rem;
}

.w-116px {
  width: 116px;
}

.w-117 {
  width: 117%;
}

.w-117rem {
  width: 117rem;
}

.w-117px {
  width: 117px;
}

.w-118 {
  width: 118%;
}

.w-118rem {
  width: 118rem;
}

.w-118px {
  width: 118px;
}

.w-119 {
  width: 119%;
}

.w-119rem {
  width: 119rem;
}

.w-119px {
  width: 119px;
}

.w-120 {
  width: 120%;
}

.w-120rem {
  width: 120rem;
}

.w-120px {
  width: 120px;
}

.w-121 {
  width: 121%;
}

.w-121rem {
  width: 121rem;
}

.w-121px {
  width: 121px;
}

.w-122 {
  width: 122%;
}

.w-122rem {
  width: 122rem;
}

.w-122px {
  width: 122px;
}

.w-123 {
  width: 123%;
}

.w-123rem {
  width: 123rem;
}

.w-123px {
  width: 123px;
}

.w-124 {
  width: 124%;
}

.w-124rem {
  width: 124rem;
}

.w-124px {
  width: 124px;
}

.w-125 {
  width: 125%;
}

.w-125rem {
  width: 125rem;
}

.w-125px {
  width: 125px;
}

.w-126 {
  width: 126%;
}

.w-126rem {
  width: 126rem;
}

.w-126px {
  width: 126px;
}

.w-127 {
  width: 127%;
}

.w-127rem {
  width: 127rem;
}

.w-127px {
  width: 127px;
}

.w-128 {
  width: 128%;
}

.w-128rem {
  width: 128rem;
}

.w-128px {
  width: 128px;
}

.w-129 {
  width: 129%;
}

.w-129rem {
  width: 129rem;
}

.w-129px {
  width: 129px;
}

.w-130 {
  width: 130%;
}

.w-130rem {
  width: 130rem;
}

.w-130px {
  width: 130px;
}

.w-131 {
  width: 131%;
}

.w-131rem {
  width: 131rem;
}

.w-131px {
  width: 131px;
}

.w-132 {
  width: 132%;
}

.w-132rem {
  width: 132rem;
}

.w-132px {
  width: 132px;
}

.w-133 {
  width: 133%;
}

.w-133rem {
  width: 133rem;
}

.w-133px {
  width: 133px;
}

.w-134 {
  width: 134%;
}

.w-134rem {
  width: 134rem;
}

.w-134px {
  width: 134px;
}

.w-135 {
  width: 135%;
}

.w-135rem {
  width: 135rem;
}

.w-135px {
  width: 135px;
}

.w-136 {
  width: 136%;
}

.w-136rem {
  width: 136rem;
}

.w-136px {
  width: 136px;
}

.w-137 {
  width: 137%;
}

.w-137rem {
  width: 137rem;
}

.w-137px {
  width: 137px;
}

.w-138 {
  width: 138%;
}

.w-138rem {
  width: 138rem;
}

.w-138px {
  width: 138px;
}

.w-139 {
  width: 139%;
}

.w-139rem {
  width: 139rem;
}

.w-139px {
  width: 139px;
}

.w-140 {
  width: 140%;
}

.w-140rem {
  width: 140rem;
}

.w-140px {
  width: 140px;
}

.w-141 {
  width: 141%;
}

.w-141rem {
  width: 141rem;
}

.w-141px {
  width: 141px;
}

.w-142 {
  width: 142%;
}

.w-142rem {
  width: 142rem;
}

.w-142px {
  width: 142px;
}

.w-143 {
  width: 143%;
}

.w-143rem {
  width: 143rem;
}

.w-143px {
  width: 143px;
}

.w-144 {
  width: 144%;
}

.w-144rem {
  width: 144rem;
}

.w-144px {
  width: 144px;
}

.w-145 {
  width: 145%;
}

.w-145rem {
  width: 145rem;
}

.w-145px {
  width: 145px;
}

.w-146 {
  width: 146%;
}

.w-146rem {
  width: 146rem;
}

.w-146px {
  width: 146px;
}

.w-147 {
  width: 147%;
}

.w-147rem {
  width: 147rem;
}

.w-147px {
  width: 147px;
}

.w-148 {
  width: 148%;
}

.w-148rem {
  width: 148rem;
}

.w-148px {
  width: 148px;
}

.w-149 {
  width: 149%;
}

.w-149rem {
  width: 149rem;
}

.w-149px {
  width: 149px;
}

.w-150 {
  width: 150%;
}

.w-150rem {
  width: 150rem;
}

.w-150px {
  width: 150px;
}

.w-151 {
  width: 151%;
}

.w-151rem {
  width: 151rem;
}

.w-151px {
  width: 151px;
}

.w-152 {
  width: 152%;
}

.w-152rem {
  width: 152rem;
}

.w-152px {
  width: 152px;
}

.w-153 {
  width: 153%;
}

.w-153rem {
  width: 153rem;
}

.w-153px {
  width: 153px;
}

.w-154 {
  width: 154%;
}

.w-154rem {
  width: 154rem;
}

.w-154px {
  width: 154px;
}

.w-155 {
  width: 155%;
}

.w-155rem {
  width: 155rem;
}

.w-155px {
  width: 155px;
}

.w-156 {
  width: 156%;
}

.w-156rem {
  width: 156rem;
}

.w-156px {
  width: 156px;
}

.w-157 {
  width: 157%;
}

.w-157rem {
  width: 157rem;
}

.w-157px {
  width: 157px;
}

.w-158 {
  width: 158%;
}

.w-158rem {
  width: 158rem;
}

.w-158px {
  width: 158px;
}

.w-159 {
  width: 159%;
}

.w-159rem {
  width: 159rem;
}

.w-159px {
  width: 159px;
}

.w-160 {
  width: 160%;
}

.w-160rem {
  width: 160rem;
}

.w-160px {
  width: 160px;
}

.w-161 {
  width: 161%;
}

.w-161rem {
  width: 161rem;
}

.w-161px {
  width: 161px;
}

.w-162 {
  width: 162%;
}

.w-162rem {
  width: 162rem;
}

.w-162px {
  width: 162px;
}

.w-163 {
  width: 163%;
}

.w-163rem {
  width: 163rem;
}

.w-163px {
  width: 163px;
}

.w-164 {
  width: 164%;
}

.w-164rem {
  width: 164rem;
}

.w-164px {
  width: 164px;
}

.w-165 {
  width: 165%;
}

.w-165rem {
  width: 165rem;
}

.w-165px {
  width: 165px;
}

.w-166 {
  width: 166%;
}

.w-166rem {
  width: 166rem;
}

.w-166px {
  width: 166px;
}

.w-167 {
  width: 167%;
}

.w-167rem {
  width: 167rem;
}

.w-167px {
  width: 167px;
}

.w-168 {
  width: 168%;
}

.w-168rem {
  width: 168rem;
}

.w-168px {
  width: 168px;
}

.w-169 {
  width: 169%;
}

.w-169rem {
  width: 169rem;
}

.w-169px {
  width: 169px;
}

.w-170 {
  width: 170%;
}

.w-170rem {
  width: 170rem;
}

.w-170px {
  width: 170px;
}

.w-171 {
  width: 171%;
}

.w-171rem {
  width: 171rem;
}

.w-171px {
  width: 171px;
}

.w-172 {
  width: 172%;
}

.w-172rem {
  width: 172rem;
}

.w-172px {
  width: 172px;
}

.w-173 {
  width: 173%;
}

.w-173rem {
  width: 173rem;
}

.w-173px {
  width: 173px;
}

.w-174 {
  width: 174%;
}

.w-174rem {
  width: 174rem;
}

.w-174px {
  width: 174px;
}

.w-175 {
  width: 175%;
}

.w-175rem {
  width: 175rem;
}

.w-175px {
  width: 175px;
}

.w-176 {
  width: 176%;
}

.w-176rem {
  width: 176rem;
}

.w-176px {
  width: 176px;
}

.w-177 {
  width: 177%;
}

.w-177rem {
  width: 177rem;
}

.w-177px {
  width: 177px;
}

.w-178 {
  width: 178%;
}

.w-178rem {
  width: 178rem;
}

.w-178px {
  width: 178px;
}

.w-179 {
  width: 179%;
}

.w-179rem {
  width: 179rem;
}

.w-179px {
  width: 179px;
}

.w-180 {
  width: 180%;
}

.w-180rem {
  width: 180rem;
}

.w-180px {
  width: 180px;
}

.w-181 {
  width: 181%;
}

.w-181rem {
  width: 181rem;
}

.w-181px {
  width: 181px;
}

.w-182 {
  width: 182%;
}

.w-182rem {
  width: 182rem;
}

.w-182px {
  width: 182px;
}

.w-183 {
  width: 183%;
}

.w-183rem {
  width: 183rem;
}

.w-183px {
  width: 183px;
}

.w-184 {
  width: 184%;
}

.w-184rem {
  width: 184rem;
}

.w-184px {
  width: 184px;
}

.w-185 {
  width: 185%;
}

.w-185rem {
  width: 185rem;
}

.w-185px {
  width: 185px;
}

.w-186 {
  width: 186%;
}

.w-186rem {
  width: 186rem;
}

.w-186px {
  width: 186px;
}

.w-187 {
  width: 187%;
}

.w-187rem {
  width: 187rem;
}

.w-187px {
  width: 187px;
}

.w-188 {
  width: 188%;
}

.w-188rem {
  width: 188rem;
}

.w-188px {
  width: 188px;
}

.w-189 {
  width: 189%;
}

.w-189rem {
  width: 189rem;
}

.w-189px {
  width: 189px;
}

.w-190 {
  width: 190%;
}

.w-190rem {
  width: 190rem;
}

.w-190px {
  width: 190px;
}

.w-191 {
  width: 191%;
}

.w-191rem {
  width: 191rem;
}

.w-191px {
  width: 191px;
}

.w-192 {
  width: 192%;
}

.w-192rem {
  width: 192rem;
}

.w-192px {
  width: 192px;
}

.w-193 {
  width: 193%;
}

.w-193rem {
  width: 193rem;
}

.w-193px {
  width: 193px;
}

.w-194 {
  width: 194%;
}

.w-194rem {
  width: 194rem;
}

.w-194px {
  width: 194px;
}

.w-195 {
  width: 195%;
}

.w-195rem {
  width: 195rem;
}

.w-195px {
  width: 195px;
}

.w-196 {
  width: 196%;
}

.w-196rem {
  width: 196rem;
}

.w-196px {
  width: 196px;
}

.w-197 {
  width: 197%;
}

.w-197rem {
  width: 197rem;
}

.w-197px {
  width: 197px;
}

.w-198 {
  width: 198%;
}

.w-198rem {
  width: 198rem;
}

.w-198px {
  width: 198px;
}

.w-199 {
  width: 199%;
}

.w-199rem {
  width: 199rem;
}

.w-199px {
  width: 199px;
}

.w-200 {
  width: 200%;
}

.w-200rem {
  width: 200rem;
}

.w-200px {
  width: 200px;
}

.w-201 {
  width: 201%;
}

.w-201rem {
  width: 201rem;
}

.w-201px {
  width: 201px;
}

.w-202 {
  width: 202%;
}

.w-202rem {
  width: 202rem;
}

.w-202px {
  width: 202px;
}

.w-203 {
  width: 203%;
}

.w-203rem {
  width: 203rem;
}

.w-203px {
  width: 203px;
}

.w-204 {
  width: 204%;
}

.w-204rem {
  width: 204rem;
}

.w-204px {
  width: 204px;
}

.w-205 {
  width: 205%;
}

.w-205rem {
  width: 205rem;
}

.w-205px {
  width: 205px;
}

.w-206 {
  width: 206%;
}

.w-206rem {
  width: 206rem;
}

.w-206px {
  width: 206px;
}

.w-207 {
  width: 207%;
}

.w-207rem {
  width: 207rem;
}

.w-207px {
  width: 207px;
}

.w-208 {
  width: 208%;
}

.w-208rem {
  width: 208rem;
}

.w-208px {
  width: 208px;
}

.w-209 {
  width: 209%;
}

.w-209rem {
  width: 209rem;
}

.w-209px {
  width: 209px;
}

.w-210 {
  width: 210%;
}

.w-210rem {
  width: 210rem;
}

.w-210px {
  width: 210px;
}

.w-211 {
  width: 211%;
}

.w-211rem {
  width: 211rem;
}

.w-211px {
  width: 211px;
}

.w-212 {
  width: 212%;
}

.w-212rem {
  width: 212rem;
}

.w-212px {
  width: 212px;
}

.w-213 {
  width: 213%;
}

.w-213rem {
  width: 213rem;
}

.w-213px {
  width: 213px;
}

.w-214 {
  width: 214%;
}

.w-214rem {
  width: 214rem;
}

.w-214px {
  width: 214px;
}

.w-215 {
  width: 215%;
}

.w-215rem {
  width: 215rem;
}

.w-215px {
  width: 215px;
}

.w-216 {
  width: 216%;
}

.w-216rem {
  width: 216rem;
}

.w-216px {
  width: 216px;
}

.w-217 {
  width: 217%;
}

.w-217rem {
  width: 217rem;
}

.w-217px {
  width: 217px;
}

.w-218 {
  width: 218%;
}

.w-218rem {
  width: 218rem;
}

.w-218px {
  width: 218px;
}

.w-219 {
  width: 219%;
}

.w-219rem {
  width: 219rem;
}

.w-219px {
  width: 219px;
}

.w-220 {
  width: 220%;
}

.w-220rem {
  width: 220rem;
}

.w-220px {
  width: 220px;
}

.w-221 {
  width: 221%;
}

.w-221rem {
  width: 221rem;
}

.w-221px {
  width: 221px;
}

.w-222 {
  width: 222%;
}

.w-222rem {
  width: 222rem;
}

.w-222px {
  width: 222px;
}

.w-223 {
  width: 223%;
}

.w-223rem {
  width: 223rem;
}

.w-223px {
  width: 223px;
}

.w-224 {
  width: 224%;
}

.w-224rem {
  width: 224rem;
}

.w-224px {
  width: 224px;
}

.w-225 {
  width: 225%;
}

.w-225rem {
  width: 225rem;
}

.w-225px {
  width: 225px;
}

.w-226 {
  width: 226%;
}

.w-226rem {
  width: 226rem;
}

.w-226px {
  width: 226px;
}

.w-227 {
  width: 227%;
}

.w-227rem {
  width: 227rem;
}

.w-227px {
  width: 227px;
}

.w-228 {
  width: 228%;
}

.w-228rem {
  width: 228rem;
}

.w-228px {
  width: 228px;
}

.w-229 {
  width: 229%;
}

.w-229rem {
  width: 229rem;
}

.w-229px {
  width: 229px;
}

.w-230 {
  width: 230%;
}

.w-230rem {
  width: 230rem;
}

.w-230px {
  width: 230px;
}

.w-231 {
  width: 231%;
}

.w-231rem {
  width: 231rem;
}

.w-231px {
  width: 231px;
}

.w-232 {
  width: 232%;
}

.w-232rem {
  width: 232rem;
}

.w-232px {
  width: 232px;
}

.w-233 {
  width: 233%;
}

.w-233rem {
  width: 233rem;
}

.w-233px {
  width: 233px;
}

.w-234 {
  width: 234%;
}

.w-234rem {
  width: 234rem;
}

.w-234px {
  width: 234px;
}

.w-235 {
  width: 235%;
}

.w-235rem {
  width: 235rem;
}

.w-235px {
  width: 235px;
}

.w-236 {
  width: 236%;
}

.w-236rem {
  width: 236rem;
}

.w-236px {
  width: 236px;
}

.w-237 {
  width: 237%;
}

.w-237rem {
  width: 237rem;
}

.w-237px {
  width: 237px;
}

.w-238 {
  width: 238%;
}

.w-238rem {
  width: 238rem;
}

.w-238px {
  width: 238px;
}

.w-239 {
  width: 239%;
}

.w-239rem {
  width: 239rem;
}

.w-239px {
  width: 239px;
}

.w-240 {
  width: 240%;
}

.w-240rem {
  width: 240rem;
}

.w-240px {
  width: 240px;
}

.w-241 {
  width: 241%;
}

.w-241rem {
  width: 241rem;
}

.w-241px {
  width: 241px;
}

.w-242 {
  width: 242%;
}

.w-242rem {
  width: 242rem;
}

.w-242px {
  width: 242px;
}

.w-243 {
  width: 243%;
}

.w-243rem {
  width: 243rem;
}

.w-243px {
  width: 243px;
}

.w-244 {
  width: 244%;
}

.w-244rem {
  width: 244rem;
}

.w-244px {
  width: 244px;
}

.w-245 {
  width: 245%;
}

.w-245rem {
  width: 245rem;
}

.w-245px {
  width: 245px;
}

.w-246 {
  width: 246%;
}

.w-246rem {
  width: 246rem;
}

.w-246px {
  width: 246px;
}

.w-247 {
  width: 247%;
}

.w-247rem {
  width: 247rem;
}

.w-247px {
  width: 247px;
}

.w-248 {
  width: 248%;
}

.w-248rem {
  width: 248rem;
}

.w-248px {
  width: 248px;
}

.w-249 {
  width: 249%;
}

.w-249rem {
  width: 249rem;
}

.w-249px {
  width: 249px;
}

.w-250 {
  width: 250%;
}

.w-250rem {
  width: 250rem;
}

.w-250px {
  width: 250px;
}

.w-251 {
  width: 251%;
}

.w-251rem {
  width: 251rem;
}

.w-251px {
  width: 251px;
}

.w-252 {
  width: 252%;
}

.w-252rem {
  width: 252rem;
}

.w-252px {
  width: 252px;
}

.w-253 {
  width: 253%;
}

.w-253rem {
  width: 253rem;
}

.w-253px {
  width: 253px;
}

.w-254 {
  width: 254%;
}

.w-254rem {
  width: 254rem;
}

.w-254px {
  width: 254px;
}

.w-255 {
  width: 255%;
}

.w-255rem {
  width: 255rem;
}

.w-255px {
  width: 255px;
}

.w-256 {
  width: 256%;
}

.w-256rem {
  width: 256rem;
}

.w-256px {
  width: 256px;
}

.w-257 {
  width: 257%;
}

.w-257rem {
  width: 257rem;
}

.w-257px {
  width: 257px;
}

.w-258 {
  width: 258%;
}

.w-258rem {
  width: 258rem;
}

.w-258px {
  width: 258px;
}

.w-259 {
  width: 259%;
}

.w-259rem {
  width: 259rem;
}

.w-259px {
  width: 259px;
}

.w-260 {
  width: 260%;
}

.w-260rem {
  width: 260rem;
}

.w-260px {
  width: 260px;
}

.w-261 {
  width: 261%;
}

.w-261rem {
  width: 261rem;
}

.w-261px {
  width: 261px;
}

.w-262 {
  width: 262%;
}

.w-262rem {
  width: 262rem;
}

.w-262px {
  width: 262px;
}

.w-263 {
  width: 263%;
}

.w-263rem {
  width: 263rem;
}

.w-263px {
  width: 263px;
}

.w-264 {
  width: 264%;
}

.w-264rem {
  width: 264rem;
}

.w-264px {
  width: 264px;
}

.w-265 {
  width: 265%;
}

.w-265rem {
  width: 265rem;
}

.w-265px {
  width: 265px;
}

.w-266 {
  width: 266%;
}

.w-266rem {
  width: 266rem;
}

.w-266px {
  width: 266px;
}

.w-267 {
  width: 267%;
}

.w-267rem {
  width: 267rem;
}

.w-267px {
  width: 267px;
}

.w-268 {
  width: 268%;
}

.w-268rem {
  width: 268rem;
}

.w-268px {
  width: 268px;
}

.w-269 {
  width: 269%;
}

.w-269rem {
  width: 269rem;
}

.w-269px {
  width: 269px;
}

.w-270 {
  width: 270%;
}

.w-270rem {
  width: 270rem;
}

.w-270px {
  width: 270px;
}

.w-271 {
  width: 271%;
}

.w-271rem {
  width: 271rem;
}

.w-271px {
  width: 271px;
}

.w-272 {
  width: 272%;
}

.w-272rem {
  width: 272rem;
}

.w-272px {
  width: 272px;
}

.w-273 {
  width: 273%;
}

.w-273rem {
  width: 273rem;
}

.w-273px {
  width: 273px;
}

.w-274 {
  width: 274%;
}

.w-274rem {
  width: 274rem;
}

.w-274px {
  width: 274px;
}

.w-275 {
  width: 275%;
}

.w-275rem {
  width: 275rem;
}

.w-275px {
  width: 275px;
}

.w-276 {
  width: 276%;
}

.w-276rem {
  width: 276rem;
}

.w-276px {
  width: 276px;
}

.w-277 {
  width: 277%;
}

.w-277rem {
  width: 277rem;
}

.w-277px {
  width: 277px;
}

.w-278 {
  width: 278%;
}

.w-278rem {
  width: 278rem;
}

.w-278px {
  width: 278px;
}

.w-279 {
  width: 279%;
}

.w-279rem {
  width: 279rem;
}

.w-279px {
  width: 279px;
}

.w-280 {
  width: 280%;
}

.w-280rem {
  width: 280rem;
}

.w-280px {
  width: 280px;
}

.w-281 {
  width: 281%;
}

.w-281rem {
  width: 281rem;
}

.w-281px {
  width: 281px;
}

.w-282 {
  width: 282%;
}

.w-282rem {
  width: 282rem;
}

.w-282px {
  width: 282px;
}

.w-283 {
  width: 283%;
}

.w-283rem {
  width: 283rem;
}

.w-283px {
  width: 283px;
}

.w-284 {
  width: 284%;
}

.w-284rem {
  width: 284rem;
}

.w-284px {
  width: 284px;
}

.w-285 {
  width: 285%;
}

.w-285rem {
  width: 285rem;
}

.w-285px {
  width: 285px;
}

.w-286 {
  width: 286%;
}

.w-286rem {
  width: 286rem;
}

.w-286px {
  width: 286px;
}

.w-287 {
  width: 287%;
}

.w-287rem {
  width: 287rem;
}

.w-287px {
  width: 287px;
}

.w-288 {
  width: 288%;
}

.w-288rem {
  width: 288rem;
}

.w-288px {
  width: 288px;
}

.w-289 {
  width: 289%;
}

.w-289rem {
  width: 289rem;
}

.w-289px {
  width: 289px;
}

.w-290 {
  width: 290%;
}

.w-290rem {
  width: 290rem;
}

.w-290px {
  width: 290px;
}

.w-291 {
  width: 291%;
}

.w-291rem {
  width: 291rem;
}

.w-291px {
  width: 291px;
}

.w-292 {
  width: 292%;
}

.w-292rem {
  width: 292rem;
}

.w-292px {
  width: 292px;
}

.w-293 {
  width: 293%;
}

.w-293rem {
  width: 293rem;
}

.w-293px {
  width: 293px;
}

.w-294 {
  width: 294%;
}

.w-294rem {
  width: 294rem;
}

.w-294px {
  width: 294px;
}

.w-295 {
  width: 295%;
}

.w-295rem {
  width: 295rem;
}

.w-295px {
  width: 295px;
}

.w-296 {
  width: 296%;
}

.w-296rem {
  width: 296rem;
}

.w-296px {
  width: 296px;
}

.w-297 {
  width: 297%;
}

.w-297rem {
  width: 297rem;
}

.w-297px {
  width: 297px;
}

.w-298 {
  width: 298%;
}

.w-298rem {
  width: 298rem;
}

.w-298px {
  width: 298px;
}

.w-299 {
  width: 299%;
}

.w-299rem {
  width: 299rem;
}

.w-299px {
  width: 299px;
}

.w-300 {
  width: 300%;
}

.w-300rem {
  width: 300rem;
}

.w-300px {
  width: 300px;
}

.w-301 {
  width: 301%;
}

.w-301rem {
  width: 301rem;
}

.w-301px {
  width: 301px;
}

.w-302 {
  width: 302%;
}

.w-302rem {
  width: 302rem;
}

.w-302px {
  width: 302px;
}

.w-303 {
  width: 303%;
}

.w-303rem {
  width: 303rem;
}

.w-303px {
  width: 303px;
}

.w-304 {
  width: 304%;
}

.w-304rem {
  width: 304rem;
}

.w-304px {
  width: 304px;
}

.w-305 {
  width: 305%;
}

.w-305rem {
  width: 305rem;
}

.w-305px {
  width: 305px;
}

.w-306 {
  width: 306%;
}

.w-306rem {
  width: 306rem;
}

.w-306px {
  width: 306px;
}

.w-307 {
  width: 307%;
}

.w-307rem {
  width: 307rem;
}

.w-307px {
  width: 307px;
}

.w-308 {
  width: 308%;
}

.w-308rem {
  width: 308rem;
}

.w-308px {
  width: 308px;
}

.w-309 {
  width: 309%;
}

.w-309rem {
  width: 309rem;
}

.w-309px {
  width: 309px;
}

.w-310 {
  width: 310%;
}

.w-310rem {
  width: 310rem;
}

.w-310px {
  width: 310px;
}

.w-311 {
  width: 311%;
}

.w-311rem {
  width: 311rem;
}

.w-311px {
  width: 311px;
}

.w-312 {
  width: 312%;
}

.w-312rem {
  width: 312rem;
}

.w-312px {
  width: 312px;
}

.w-313 {
  width: 313%;
}

.w-313rem {
  width: 313rem;
}

.w-313px {
  width: 313px;
}

.w-314 {
  width: 314%;
}

.w-314rem {
  width: 314rem;
}

.w-314px {
  width: 314px;
}

.w-315 {
  width: 315%;
}

.w-315rem {
  width: 315rem;
}

.w-315px {
  width: 315px;
}

.w-316 {
  width: 316%;
}

.w-316rem {
  width: 316rem;
}

.w-316px {
  width: 316px;
}

.w-317 {
  width: 317%;
}

.w-317rem {
  width: 317rem;
}

.w-317px {
  width: 317px;
}

.w-318 {
  width: 318%;
}

.w-318rem {
  width: 318rem;
}

.w-318px {
  width: 318px;
}

.w-319 {
  width: 319%;
}

.w-319rem {
  width: 319rem;
}

.w-319px {
  width: 319px;
}

.w-320 {
  width: 320%;
}

.w-320rem {
  width: 320rem;
}

.w-320px {
  width: 320px;
}

.w-321 {
  width: 321%;
}

.w-321rem {
  width: 321rem;
}

.w-321px {
  width: 321px;
}

.w-322 {
  width: 322%;
}

.w-322rem {
  width: 322rem;
}

.w-322px {
  width: 322px;
}

.w-323 {
  width: 323%;
}

.w-323rem {
  width: 323rem;
}

.w-323px {
  width: 323px;
}

.w-324 {
  width: 324%;
}

.w-324rem {
  width: 324rem;
}

.w-324px {
  width: 324px;
}

.w-325 {
  width: 325%;
}

.w-325rem {
  width: 325rem;
}

.w-325px {
  width: 325px;
}

.w-326 {
  width: 326%;
}

.w-326rem {
  width: 326rem;
}

.w-326px {
  width: 326px;
}

.w-327 {
  width: 327%;
}

.w-327rem {
  width: 327rem;
}

.w-327px {
  width: 327px;
}

.w-328 {
  width: 328%;
}

.w-328rem {
  width: 328rem;
}

.w-328px {
  width: 328px;
}

.w-329 {
  width: 329%;
}

.w-329rem {
  width: 329rem;
}

.w-329px {
  width: 329px;
}

.w-330 {
  width: 330%;
}

.w-330rem {
  width: 330rem;
}

.w-330px {
  width: 330px;
}

.w-331 {
  width: 331%;
}

.w-331rem {
  width: 331rem;
}

.w-331px {
  width: 331px;
}

.w-332 {
  width: 332%;
}

.w-332rem {
  width: 332rem;
}

.w-332px {
  width: 332px;
}

.w-333 {
  width: 333%;
}

.w-333rem {
  width: 333rem;
}

.w-333px {
  width: 333px;
}

.w-334 {
  width: 334%;
}

.w-334rem {
  width: 334rem;
}

.w-334px {
  width: 334px;
}

.w-335 {
  width: 335%;
}

.w-335rem {
  width: 335rem;
}

.w-335px {
  width: 335px;
}

.w-336 {
  width: 336%;
}

.w-336rem {
  width: 336rem;
}

.w-336px {
  width: 336px;
}

.w-337 {
  width: 337%;
}

.w-337rem {
  width: 337rem;
}

.w-337px {
  width: 337px;
}

.w-338 {
  width: 338%;
}

.w-338rem {
  width: 338rem;
}

.w-338px {
  width: 338px;
}

.w-339 {
  width: 339%;
}

.w-339rem {
  width: 339rem;
}

.w-339px {
  width: 339px;
}

.w-340 {
  width: 340%;
}

.w-340rem {
  width: 340rem;
}

.w-340px {
  width: 340px;
}

.w-341 {
  width: 341%;
}

.w-341rem {
  width: 341rem;
}

.w-341px {
  width: 341px;
}

.w-342 {
  width: 342%;
}

.w-342rem {
  width: 342rem;
}

.w-342px {
  width: 342px;
}

.w-343 {
  width: 343%;
}

.w-343rem {
  width: 343rem;
}

.w-343px {
  width: 343px;
}

.w-344 {
  width: 344%;
}

.w-344rem {
  width: 344rem;
}

.w-344px {
  width: 344px;
}

.w-345 {
  width: 345%;
}

.w-345rem {
  width: 345rem;
}

.w-345px {
  width: 345px;
}

.w-346 {
  width: 346%;
}

.w-346rem {
  width: 346rem;
}

.w-346px {
  width: 346px;
}

.w-347 {
  width: 347%;
}

.w-347rem {
  width: 347rem;
}

.w-347px {
  width: 347px;
}

.w-348 {
  width: 348%;
}

.w-348rem {
  width: 348rem;
}

.w-348px {
  width: 348px;
}

.w-349 {
  width: 349%;
}

.w-349rem {
  width: 349rem;
}

.w-349px {
  width: 349px;
}

.w-350 {
  width: 350%;
}

.w-350rem {
  width: 350rem;
}

.w-350px {
  width: 350px;
}

.w-351 {
  width: 351%;
}

.w-351rem {
  width: 351rem;
}

.w-351px {
  width: 351px;
}

.w-352 {
  width: 352%;
}

.w-352rem {
  width: 352rem;
}

.w-352px {
  width: 352px;
}

.w-353 {
  width: 353%;
}

.w-353rem {
  width: 353rem;
}

.w-353px {
  width: 353px;
}

.w-354 {
  width: 354%;
}

.w-354rem {
  width: 354rem;
}

.w-354px {
  width: 354px;
}

.w-355 {
  width: 355%;
}

.w-355rem {
  width: 355rem;
}

.w-355px {
  width: 355px;
}

.w-356 {
  width: 356%;
}

.w-356rem {
  width: 356rem;
}

.w-356px {
  width: 356px;
}

.w-357 {
  width: 357%;
}

.w-357rem {
  width: 357rem;
}

.w-357px {
  width: 357px;
}

.w-358 {
  width: 358%;
}

.w-358rem {
  width: 358rem;
}

.w-358px {
  width: 358px;
}

.w-359 {
  width: 359%;
}

.w-359rem {
  width: 359rem;
}

.w-359px {
  width: 359px;
}

.w-360 {
  width: 360%;
}

.w-360rem {
  width: 360rem;
}

.w-360px {
  width: 360px;
}

.w-361 {
  width: 361%;
}

.w-361rem {
  width: 361rem;
}

.w-361px {
  width: 361px;
}

.w-362 {
  width: 362%;
}

.w-362rem {
  width: 362rem;
}

.w-362px {
  width: 362px;
}

.w-363 {
  width: 363%;
}

.w-363rem {
  width: 363rem;
}

.w-363px {
  width: 363px;
}

.w-364 {
  width: 364%;
}

.w-364rem {
  width: 364rem;
}

.w-364px {
  width: 364px;
}

.w-365 {
  width: 365%;
}

.w-365rem {
  width: 365rem;
}

.w-365px {
  width: 365px;
}

.w-366 {
  width: 366%;
}

.w-366rem {
  width: 366rem;
}

.w-366px {
  width: 366px;
}

.w-367 {
  width: 367%;
}

.w-367rem {
  width: 367rem;
}

.w-367px {
  width: 367px;
}

.w-368 {
  width: 368%;
}

.w-368rem {
  width: 368rem;
}

.w-368px {
  width: 368px;
}

.w-369 {
  width: 369%;
}

.w-369rem {
  width: 369rem;
}

.w-369px {
  width: 369px;
}

.w-370 {
  width: 370%;
}

.w-370rem {
  width: 370rem;
}

.w-370px {
  width: 370px;
}

.w-371 {
  width: 371%;
}

.w-371rem {
  width: 371rem;
}

.w-371px {
  width: 371px;
}

.w-372 {
  width: 372%;
}

.w-372rem {
  width: 372rem;
}

.w-372px {
  width: 372px;
}

.w-373 {
  width: 373%;
}

.w-373rem {
  width: 373rem;
}

.w-373px {
  width: 373px;
}

.w-374 {
  width: 374%;
}

.w-374rem {
  width: 374rem;
}

.w-374px {
  width: 374px;
}

.w-375 {
  width: 375%;
}

.w-375rem {
  width: 375rem;
}

.w-375px {
  width: 375px;
}

.w-376 {
  width: 376%;
}

.w-376rem {
  width: 376rem;
}

.w-376px {
  width: 376px;
}

.w-377 {
  width: 377%;
}

.w-377rem {
  width: 377rem;
}

.w-377px {
  width: 377px;
}

.w-378 {
  width: 378%;
}

.w-378rem {
  width: 378rem;
}

.w-378px {
  width: 378px;
}

.w-379 {
  width: 379%;
}

.w-379rem {
  width: 379rem;
}

.w-379px {
  width: 379px;
}

.w-380 {
  width: 380%;
}

.w-380rem {
  width: 380rem;
}

.w-380px {
  width: 380px;
}

.w-381 {
  width: 381%;
}

.w-381rem {
  width: 381rem;
}

.w-381px {
  width: 381px;
}

.w-382 {
  width: 382%;
}

.w-382rem {
  width: 382rem;
}

.w-382px {
  width: 382px;
}

.w-383 {
  width: 383%;
}

.w-383rem {
  width: 383rem;
}

.w-383px {
  width: 383px;
}

.w-384 {
  width: 384%;
}

.w-384rem {
  width: 384rem;
}

.w-384px {
  width: 384px;
}

.w-385 {
  width: 385%;
}

.w-385rem {
  width: 385rem;
}

.w-385px {
  width: 385px;
}

.w-386 {
  width: 386%;
}

.w-386rem {
  width: 386rem;
}

.w-386px {
  width: 386px;
}

.w-387 {
  width: 387%;
}

.w-387rem {
  width: 387rem;
}

.w-387px {
  width: 387px;
}

.w-388 {
  width: 388%;
}

.w-388rem {
  width: 388rem;
}

.w-388px {
  width: 388px;
}

.w-389 {
  width: 389%;
}

.w-389rem {
  width: 389rem;
}

.w-389px {
  width: 389px;
}

.w-390 {
  width: 390%;
}

.w-390rem {
  width: 390rem;
}

.w-390px {
  width: 390px;
}

.w-391 {
  width: 391%;
}

.w-391rem {
  width: 391rem;
}

.w-391px {
  width: 391px;
}

.w-392 {
  width: 392%;
}

.w-392rem {
  width: 392rem;
}

.w-392px {
  width: 392px;
}

.w-393 {
  width: 393%;
}

.w-393rem {
  width: 393rem;
}

.w-393px {
  width: 393px;
}

.w-394 {
  width: 394%;
}

.w-394rem {
  width: 394rem;
}

.w-394px {
  width: 394px;
}

.w-395 {
  width: 395%;
}

.w-395rem {
  width: 395rem;
}

.w-395px {
  width: 395px;
}

.w-396 {
  width: 396%;
}

.w-396rem {
  width: 396rem;
}

.w-396px {
  width: 396px;
}

.w-397 {
  width: 397%;
}

.w-397rem {
  width: 397rem;
}

.w-397px {
  width: 397px;
}

.w-398 {
  width: 398%;
}

.w-398rem {
  width: 398rem;
}

.w-398px {
  width: 398px;
}

.w-399 {
  width: 399%;
}

.w-399rem {
  width: 399rem;
}

.w-399px {
  width: 399px;
}

.w-400 {
  width: 400%;
}

.w-400rem {
  width: 400rem;
}

.w-400px {
  width: 400px;
}

.w-401 {
  width: 401%;
}

.w-401rem {
  width: 401rem;
}

.w-401px {
  width: 401px;
}

.w-402 {
  width: 402%;
}

.w-402rem {
  width: 402rem;
}

.w-402px {
  width: 402px;
}

.w-403 {
  width: 403%;
}

.w-403rem {
  width: 403rem;
}

.w-403px {
  width: 403px;
}

.w-404 {
  width: 404%;
}

.w-404rem {
  width: 404rem;
}

.w-404px {
  width: 404px;
}

.w-405 {
  width: 405%;
}

.w-405rem {
  width: 405rem;
}

.w-405px {
  width: 405px;
}

.w-406 {
  width: 406%;
}

.w-406rem {
  width: 406rem;
}

.w-406px {
  width: 406px;
}

.w-407 {
  width: 407%;
}

.w-407rem {
  width: 407rem;
}

.w-407px {
  width: 407px;
}

.w-408 {
  width: 408%;
}

.w-408rem {
  width: 408rem;
}

.w-408px {
  width: 408px;
}

.w-409 {
  width: 409%;
}

.w-409rem {
  width: 409rem;
}

.w-409px {
  width: 409px;
}

.w-410 {
  width: 410%;
}

.w-410rem {
  width: 410rem;
}

.w-410px {
  width: 410px;
}

.w-411 {
  width: 411%;
}

.w-411rem {
  width: 411rem;
}

.w-411px {
  width: 411px;
}

.w-412 {
  width: 412%;
}

.w-412rem {
  width: 412rem;
}

.w-412px {
  width: 412px;
}

.w-413 {
  width: 413%;
}

.w-413rem {
  width: 413rem;
}

.w-413px {
  width: 413px;
}

.w-414 {
  width: 414%;
}

.w-414rem {
  width: 414rem;
}

.w-414px {
  width: 414px;
}

.w-415 {
  width: 415%;
}

.w-415rem {
  width: 415rem;
}

.w-415px {
  width: 415px;
}

.w-416 {
  width: 416%;
}

.w-416rem {
  width: 416rem;
}

.w-416px {
  width: 416px;
}

.w-417 {
  width: 417%;
}

.w-417rem {
  width: 417rem;
}

.w-417px {
  width: 417px;
}

.w-418 {
  width: 418%;
}

.w-418rem {
  width: 418rem;
}

.w-418px {
  width: 418px;
}

.w-419 {
  width: 419%;
}

.w-419rem {
  width: 419rem;
}

.w-419px {
  width: 419px;
}

.w-420 {
  width: 420%;
}

.w-420rem {
  width: 420rem;
}

.w-420px {
  width: 420px;
}

.w-421 {
  width: 421%;
}

.w-421rem {
  width: 421rem;
}

.w-421px {
  width: 421px;
}

.w-422 {
  width: 422%;
}

.w-422rem {
  width: 422rem;
}

.w-422px {
  width: 422px;
}

.w-423 {
  width: 423%;
}

.w-423rem {
  width: 423rem;
}

.w-423px {
  width: 423px;
}

.w-424 {
  width: 424%;
}

.w-424rem {
  width: 424rem;
}

.w-424px {
  width: 424px;
}

.w-425 {
  width: 425%;
}

.w-425rem {
  width: 425rem;
}

.w-425px {
  width: 425px;
}

.w-426 {
  width: 426%;
}

.w-426rem {
  width: 426rem;
}

.w-426px {
  width: 426px;
}

.w-427 {
  width: 427%;
}

.w-427rem {
  width: 427rem;
}

.w-427px {
  width: 427px;
}

.w-428 {
  width: 428%;
}

.w-428rem {
  width: 428rem;
}

.w-428px {
  width: 428px;
}

.w-429 {
  width: 429%;
}

.w-429rem {
  width: 429rem;
}

.w-429px {
  width: 429px;
}

.w-430 {
  width: 430%;
}

.w-430rem {
  width: 430rem;
}

.w-430px {
  width: 430px;
}

.w-431 {
  width: 431%;
}

.w-431rem {
  width: 431rem;
}

.w-431px {
  width: 431px;
}

.w-432 {
  width: 432%;
}

.w-432rem {
  width: 432rem;
}

.w-432px {
  width: 432px;
}

.w-433 {
  width: 433%;
}

.w-433rem {
  width: 433rem;
}

.w-433px {
  width: 433px;
}

.w-434 {
  width: 434%;
}

.w-434rem {
  width: 434rem;
}

.w-434px {
  width: 434px;
}

.w-435 {
  width: 435%;
}

.w-435rem {
  width: 435rem;
}

.w-435px {
  width: 435px;
}

.w-436 {
  width: 436%;
}

.w-436rem {
  width: 436rem;
}

.w-436px {
  width: 436px;
}

.w-437 {
  width: 437%;
}

.w-437rem {
  width: 437rem;
}

.w-437px {
  width: 437px;
}

.w-438 {
  width: 438%;
}

.w-438rem {
  width: 438rem;
}

.w-438px {
  width: 438px;
}

.w-439 {
  width: 439%;
}

.w-439rem {
  width: 439rem;
}

.w-439px {
  width: 439px;
}

.w-440 {
  width: 440%;
}

.w-440rem {
  width: 440rem;
}

.w-440px {
  width: 440px;
}

.w-441 {
  width: 441%;
}

.w-441rem {
  width: 441rem;
}

.w-441px {
  width: 441px;
}

.w-442 {
  width: 442%;
}

.w-442rem {
  width: 442rem;
}

.w-442px {
  width: 442px;
}

.w-443 {
  width: 443%;
}

.w-443rem {
  width: 443rem;
}

.w-443px {
  width: 443px;
}

.w-444 {
  width: 444%;
}

.w-444rem {
  width: 444rem;
}

.w-444px {
  width: 444px;
}

.w-445 {
  width: 445%;
}

.w-445rem {
  width: 445rem;
}

.w-445px {
  width: 445px;
}

.w-446 {
  width: 446%;
}

.w-446rem {
  width: 446rem;
}

.w-446px {
  width: 446px;
}

.w-447 {
  width: 447%;
}

.w-447rem {
  width: 447rem;
}

.w-447px {
  width: 447px;
}

.w-448 {
  width: 448%;
}

.w-448rem {
  width: 448rem;
}

.w-448px {
  width: 448px;
}

.w-449 {
  width: 449%;
}

.w-449rem {
  width: 449rem;
}

.w-449px {
  width: 449px;
}

.w-450 {
  width: 450%;
}

.w-450rem {
  width: 450rem;
}

.w-450px {
  width: 450px;
}

.w-451 {
  width: 451%;
}

.w-451rem {
  width: 451rem;
}

.w-451px {
  width: 451px;
}

.w-452 {
  width: 452%;
}

.w-452rem {
  width: 452rem;
}

.w-452px {
  width: 452px;
}

.w-453 {
  width: 453%;
}

.w-453rem {
  width: 453rem;
}

.w-453px {
  width: 453px;
}

.w-454 {
  width: 454%;
}

.w-454rem {
  width: 454rem;
}

.w-454px {
  width: 454px;
}

.w-455 {
  width: 455%;
}

.w-455rem {
  width: 455rem;
}

.w-455px {
  width: 455px;
}

.w-456 {
  width: 456%;
}

.w-456rem {
  width: 456rem;
}

.w-456px {
  width: 456px;
}

.w-457 {
  width: 457%;
}

.w-457rem {
  width: 457rem;
}

.w-457px {
  width: 457px;
}

.w-458 {
  width: 458%;
}

.w-458rem {
  width: 458rem;
}

.w-458px {
  width: 458px;
}

.w-459 {
  width: 459%;
}

.w-459rem {
  width: 459rem;
}

.w-459px {
  width: 459px;
}

.w-460 {
  width: 460%;
}

.w-460rem {
  width: 460rem;
}

.w-460px {
  width: 460px;
}

.w-461 {
  width: 461%;
}

.w-461rem {
  width: 461rem;
}

.w-461px {
  width: 461px;
}

.w-462 {
  width: 462%;
}

.w-462rem {
  width: 462rem;
}

.w-462px {
  width: 462px;
}

.w-463 {
  width: 463%;
}

.w-463rem {
  width: 463rem;
}

.w-463px {
  width: 463px;
}

.w-464 {
  width: 464%;
}

.w-464rem {
  width: 464rem;
}

.w-464px {
  width: 464px;
}

.w-465 {
  width: 465%;
}

.w-465rem {
  width: 465rem;
}

.w-465px {
  width: 465px;
}

.w-466 {
  width: 466%;
}

.w-466rem {
  width: 466rem;
}

.w-466px {
  width: 466px;
}

.w-467 {
  width: 467%;
}

.w-467rem {
  width: 467rem;
}

.w-467px {
  width: 467px;
}

.w-468 {
  width: 468%;
}

.w-468rem {
  width: 468rem;
}

.w-468px {
  width: 468px;
}

.w-469 {
  width: 469%;
}

.w-469rem {
  width: 469rem;
}

.w-469px {
  width: 469px;
}

.w-470 {
  width: 470%;
}

.w-470rem {
  width: 470rem;
}

.w-470px {
  width: 470px;
}

.w-471 {
  width: 471%;
}

.w-471rem {
  width: 471rem;
}

.w-471px {
  width: 471px;
}

.w-472 {
  width: 472%;
}

.w-472rem {
  width: 472rem;
}

.w-472px {
  width: 472px;
}

.w-473 {
  width: 473%;
}

.w-473rem {
  width: 473rem;
}

.w-473px {
  width: 473px;
}

.w-474 {
  width: 474%;
}

.w-474rem {
  width: 474rem;
}

.w-474px {
  width: 474px;
}

.w-475 {
  width: 475%;
}

.w-475rem {
  width: 475rem;
}

.w-475px {
  width: 475px;
}

.w-476 {
  width: 476%;
}

.w-476rem {
  width: 476rem;
}

.w-476px {
  width: 476px;
}

.w-477 {
  width: 477%;
}

.w-477rem {
  width: 477rem;
}

.w-477px {
  width: 477px;
}

.w-478 {
  width: 478%;
}

.w-478rem {
  width: 478rem;
}

.w-478px {
  width: 478px;
}

.w-479 {
  width: 479%;
}

.w-479rem {
  width: 479rem;
}

.w-479px {
  width: 479px;
}

.w-480 {
  width: 480%;
}

.w-480rem {
  width: 480rem;
}

.w-480px {
  width: 480px;
}

.w-481 {
  width: 481%;
}

.w-481rem {
  width: 481rem;
}

.w-481px {
  width: 481px;
}

.w-482 {
  width: 482%;
}

.w-482rem {
  width: 482rem;
}

.w-482px {
  width: 482px;
}

.w-483 {
  width: 483%;
}

.w-483rem {
  width: 483rem;
}

.w-483px {
  width: 483px;
}

.w-484 {
  width: 484%;
}

.w-484rem {
  width: 484rem;
}

.w-484px {
  width: 484px;
}

.w-485 {
  width: 485%;
}

.w-485rem {
  width: 485rem;
}

.w-485px {
  width: 485px;
}

.w-486 {
  width: 486%;
}

.w-486rem {
  width: 486rem;
}

.w-486px {
  width: 486px;
}

.w-487 {
  width: 487%;
}

.w-487rem {
  width: 487rem;
}

.w-487px {
  width: 487px;
}

.w-488 {
  width: 488%;
}

.w-488rem {
  width: 488rem;
}

.w-488px {
  width: 488px;
}

.w-489 {
  width: 489%;
}

.w-489rem {
  width: 489rem;
}

.w-489px {
  width: 489px;
}

.w-490 {
  width: 490%;
}

.w-490rem {
  width: 490rem;
}

.w-490px {
  width: 490px;
}

.w-491 {
  width: 491%;
}

.w-491rem {
  width: 491rem;
}

.w-491px {
  width: 491px;
}

.w-492 {
  width: 492%;
}

.w-492rem {
  width: 492rem;
}

.w-492px {
  width: 492px;
}

.w-493 {
  width: 493%;
}

.w-493rem {
  width: 493rem;
}

.w-493px {
  width: 493px;
}

.w-494 {
  width: 494%;
}

.w-494rem {
  width: 494rem;
}

.w-494px {
  width: 494px;
}

.w-495 {
  width: 495%;
}

.w-495rem {
  width: 495rem;
}

.w-495px {
  width: 495px;
}

.w-496 {
  width: 496%;
}

.w-496rem {
  width: 496rem;
}

.w-496px {
  width: 496px;
}

.w-497 {
  width: 497%;
}

.w-497rem {
  width: 497rem;
}

.w-497px {
  width: 497px;
}

.w-498 {
  width: 498%;
}

.w-498rem {
  width: 498rem;
}

.w-498px {
  width: 498px;
}

.w-499 {
  width: 499%;
}

.w-499rem {
  width: 499rem;
}

.w-499px {
  width: 499px;
}

.w-500 {
  width: 500%;
}

.w-500rem {
  width: 500rem;
}

.w-500px {
  width: 500px;
}

.w-501 {
  width: 501%;
}

.w-501rem {
  width: 501rem;
}

.w-501px {
  width: 501px;
}

.w-502 {
  width: 502%;
}

.w-502rem {
  width: 502rem;
}

.w-502px {
  width: 502px;
}

.w-503 {
  width: 503%;
}

.w-503rem {
  width: 503rem;
}

.w-503px {
  width: 503px;
}

.w-504 {
  width: 504%;
}

.w-504rem {
  width: 504rem;
}

.w-504px {
  width: 504px;
}

.w-505 {
  width: 505%;
}

.w-505rem {
  width: 505rem;
}

.w-505px {
  width: 505px;
}

.w-506 {
  width: 506%;
}

.w-506rem {
  width: 506rem;
}

.w-506px {
  width: 506px;
}

.w-507 {
  width: 507%;
}

.w-507rem {
  width: 507rem;
}

.w-507px {
  width: 507px;
}

.w-508 {
  width: 508%;
}

.w-508rem {
  width: 508rem;
}

.w-508px {
  width: 508px;
}

.w-509 {
  width: 509%;
}

.w-509rem {
  width: 509rem;
}

.w-509px {
  width: 509px;
}

.w-510 {
  width: 510%;
}

.w-510rem {
  width: 510rem;
}

.w-510px {
  width: 510px;
}

.w-511 {
  width: 511%;
}

.w-511rem {
  width: 511rem;
}

.w-511px {
  width: 511px;
}

.w-512 {
  width: 512%;
}

.w-512rem {
  width: 512rem;
}

.w-512px {
  width: 512px;
}

.w-513 {
  width: 513%;
}

.w-513rem {
  width: 513rem;
}

.w-513px {
  width: 513px;
}

.w-514 {
  width: 514%;
}

.w-514rem {
  width: 514rem;
}

.w-514px {
  width: 514px;
}

.w-515 {
  width: 515%;
}

.w-515rem {
  width: 515rem;
}

.w-515px {
  width: 515px;
}

.w-516 {
  width: 516%;
}

.w-516rem {
  width: 516rem;
}

.w-516px {
  width: 516px;
}

.w-517 {
  width: 517%;
}

.w-517rem {
  width: 517rem;
}

.w-517px {
  width: 517px;
}

.w-518 {
  width: 518%;
}

.w-518rem {
  width: 518rem;
}

.w-518px {
  width: 518px;
}

.w-519 {
  width: 519%;
}

.w-519rem {
  width: 519rem;
}

.w-519px {
  width: 519px;
}

.w-520 {
  width: 520%;
}

.w-520rem {
  width: 520rem;
}

.w-520px {
  width: 520px;
}

.w-521 {
  width: 521%;
}

.w-521rem {
  width: 521rem;
}

.w-521px {
  width: 521px;
}

.w-522 {
  width: 522%;
}

.w-522rem {
  width: 522rem;
}

.w-522px {
  width: 522px;
}

.w-523 {
  width: 523%;
}

.w-523rem {
  width: 523rem;
}

.w-523px {
  width: 523px;
}

.w-524 {
  width: 524%;
}

.w-524rem {
  width: 524rem;
}

.w-524px {
  width: 524px;
}

.w-525 {
  width: 525%;
}

.w-525rem {
  width: 525rem;
}

.w-525px {
  width: 525px;
}

.w-526 {
  width: 526%;
}

.w-526rem {
  width: 526rem;
}

.w-526px {
  width: 526px;
}

.w-527 {
  width: 527%;
}

.w-527rem {
  width: 527rem;
}

.w-527px {
  width: 527px;
}

.w-528 {
  width: 528%;
}

.w-528rem {
  width: 528rem;
}

.w-528px {
  width: 528px;
}

.w-529 {
  width: 529%;
}

.w-529rem {
  width: 529rem;
}

.w-529px {
  width: 529px;
}

.w-530 {
  width: 530%;
}

.w-530rem {
  width: 530rem;
}

.w-530px {
  width: 530px;
}

.w-531 {
  width: 531%;
}

.w-531rem {
  width: 531rem;
}

.w-531px {
  width: 531px;
}

.w-532 {
  width: 532%;
}

.w-532rem {
  width: 532rem;
}

.w-532px {
  width: 532px;
}

.w-533 {
  width: 533%;
}

.w-533rem {
  width: 533rem;
}

.w-533px {
  width: 533px;
}

.w-534 {
  width: 534%;
}

.w-534rem {
  width: 534rem;
}

.w-534px {
  width: 534px;
}

.w-535 {
  width: 535%;
}

.w-535rem {
  width: 535rem;
}

.w-535px {
  width: 535px;
}

.w-536 {
  width: 536%;
}

.w-536rem {
  width: 536rem;
}

.w-536px {
  width: 536px;
}

.w-537 {
  width: 537%;
}

.w-537rem {
  width: 537rem;
}

.w-537px {
  width: 537px;
}

.w-538 {
  width: 538%;
}

.w-538rem {
  width: 538rem;
}

.w-538px {
  width: 538px;
}

.w-539 {
  width: 539%;
}

.w-539rem {
  width: 539rem;
}

.w-539px {
  width: 539px;
}

.w-540 {
  width: 540%;
}

.w-540rem {
  width: 540rem;
}

.w-540px {
  width: 540px;
}

.w-541 {
  width: 541%;
}

.w-541rem {
  width: 541rem;
}

.w-541px {
  width: 541px;
}

.w-542 {
  width: 542%;
}

.w-542rem {
  width: 542rem;
}

.w-542px {
  width: 542px;
}

.w-543 {
  width: 543%;
}

.w-543rem {
  width: 543rem;
}

.w-543px {
  width: 543px;
}

.w-544 {
  width: 544%;
}

.w-544rem {
  width: 544rem;
}

.w-544px {
  width: 544px;
}

.w-545 {
  width: 545%;
}

.w-545rem {
  width: 545rem;
}

.w-545px {
  width: 545px;
}

.w-546 {
  width: 546%;
}

.w-546rem {
  width: 546rem;
}

.w-546px {
  width: 546px;
}

.w-547 {
  width: 547%;
}

.w-547rem {
  width: 547rem;
}

.w-547px {
  width: 547px;
}

.w-548 {
  width: 548%;
}

.w-548rem {
  width: 548rem;
}

.w-548px {
  width: 548px;
}

.w-549 {
  width: 549%;
}

.w-549rem {
  width: 549rem;
}

.w-549px {
  width: 549px;
}

.w-550 {
  width: 550%;
}

.w-550rem {
  width: 550rem;
}

.w-550px {
  width: 550px;
}

.w-551 {
  width: 551%;
}

.w-551rem {
  width: 551rem;
}

.w-551px {
  width: 551px;
}

.w-552 {
  width: 552%;
}

.w-552rem {
  width: 552rem;
}

.w-552px {
  width: 552px;
}

.w-553 {
  width: 553%;
}

.w-553rem {
  width: 553rem;
}

.w-553px {
  width: 553px;
}

.w-554 {
  width: 554%;
}

.w-554rem {
  width: 554rem;
}

.w-554px {
  width: 554px;
}

.w-555 {
  width: 555%;
}

.w-555rem {
  width: 555rem;
}

.w-555px {
  width: 555px;
}

.w-556 {
  width: 556%;
}

.w-556rem {
  width: 556rem;
}

.w-556px {
  width: 556px;
}

.w-557 {
  width: 557%;
}

.w-557rem {
  width: 557rem;
}

.w-557px {
  width: 557px;
}

.w-558 {
  width: 558%;
}

.w-558rem {
  width: 558rem;
}

.w-558px {
  width: 558px;
}

.w-559 {
  width: 559%;
}

.w-559rem {
  width: 559rem;
}

.w-559px {
  width: 559px;
}

.w-560 {
  width: 560%;
}

.w-560rem {
  width: 560rem;
}

.w-560px {
  width: 560px;
}

.w-561 {
  width: 561%;
}

.w-561rem {
  width: 561rem;
}

.w-561px {
  width: 561px;
}

.w-562 {
  width: 562%;
}

.w-562rem {
  width: 562rem;
}

.w-562px {
  width: 562px;
}

.w-563 {
  width: 563%;
}

.w-563rem {
  width: 563rem;
}

.w-563px {
  width: 563px;
}

.w-564 {
  width: 564%;
}

.w-564rem {
  width: 564rem;
}

.w-564px {
  width: 564px;
}

.w-565 {
  width: 565%;
}

.w-565rem {
  width: 565rem;
}

.w-565px {
  width: 565px;
}

.w-566 {
  width: 566%;
}

.w-566rem {
  width: 566rem;
}

.w-566px {
  width: 566px;
}

.w-567 {
  width: 567%;
}

.w-567rem {
  width: 567rem;
}

.w-567px {
  width: 567px;
}

.w-568 {
  width: 568%;
}

.w-568rem {
  width: 568rem;
}

.w-568px {
  width: 568px;
}

.w-569 {
  width: 569%;
}

.w-569rem {
  width: 569rem;
}

.w-569px {
  width: 569px;
}

.w-570 {
  width: 570%;
}

.w-570rem {
  width: 570rem;
}

.w-570px {
  width: 570px;
}

.w-571 {
  width: 571%;
}

.w-571rem {
  width: 571rem;
}

.w-571px {
  width: 571px;
}

.w-572 {
  width: 572%;
}

.w-572rem {
  width: 572rem;
}

.w-572px {
  width: 572px;
}

.w-573 {
  width: 573%;
}

.w-573rem {
  width: 573rem;
}

.w-573px {
  width: 573px;
}

.w-574 {
  width: 574%;
}

.w-574rem {
  width: 574rem;
}

.w-574px {
  width: 574px;
}

.w-575 {
  width: 575%;
}

.w-575rem {
  width: 575rem;
}

.w-575px {
  width: 575px;
}

.w-576 {
  width: 576%;
}

.w-576rem {
  width: 576rem;
}

.w-576px {
  width: 576px;
}

.w-577 {
  width: 577%;
}

.w-577rem {
  width: 577rem;
}

.w-577px {
  width: 577px;
}

.w-578 {
  width: 578%;
}

.w-578rem {
  width: 578rem;
}

.w-578px {
  width: 578px;
}

.w-579 {
  width: 579%;
}

.w-579rem {
  width: 579rem;
}

.w-579px {
  width: 579px;
}

.w-580 {
  width: 580%;
}

.w-580rem {
  width: 580rem;
}

.w-580px {
  width: 580px;
}

.w-581 {
  width: 581%;
}

.w-581rem {
  width: 581rem;
}

.w-581px {
  width: 581px;
}

.w-582 {
  width: 582%;
}

.w-582rem {
  width: 582rem;
}

.w-582px {
  width: 582px;
}

.w-583 {
  width: 583%;
}

.w-583rem {
  width: 583rem;
}

.w-583px {
  width: 583px;
}

.w-584 {
  width: 584%;
}

.w-584rem {
  width: 584rem;
}

.w-584px {
  width: 584px;
}

.w-585 {
  width: 585%;
}

.w-585rem {
  width: 585rem;
}

.w-585px {
  width: 585px;
}

.w-586 {
  width: 586%;
}

.w-586rem {
  width: 586rem;
}

.w-586px {
  width: 586px;
}

.w-587 {
  width: 587%;
}

.w-587rem {
  width: 587rem;
}

.w-587px {
  width: 587px;
}

.w-588 {
  width: 588%;
}

.w-588rem {
  width: 588rem;
}

.w-588px {
  width: 588px;
}

.w-589 {
  width: 589%;
}

.w-589rem {
  width: 589rem;
}

.w-589px {
  width: 589px;
}

.w-590 {
  width: 590%;
}

.w-590rem {
  width: 590rem;
}

.w-590px {
  width: 590px;
}

.w-591 {
  width: 591%;
}

.w-591rem {
  width: 591rem;
}

.w-591px {
  width: 591px;
}

.w-592 {
  width: 592%;
}

.w-592rem {
  width: 592rem;
}

.w-592px {
  width: 592px;
}

.w-593 {
  width: 593%;
}

.w-593rem {
  width: 593rem;
}

.w-593px {
  width: 593px;
}

.w-594 {
  width: 594%;
}

.w-594rem {
  width: 594rem;
}

.w-594px {
  width: 594px;
}

.w-595 {
  width: 595%;
}

.w-595rem {
  width: 595rem;
}

.w-595px {
  width: 595px;
}

.w-596 {
  width: 596%;
}

.w-596rem {
  width: 596rem;
}

.w-596px {
  width: 596px;
}

.w-597 {
  width: 597%;
}

.w-597rem {
  width: 597rem;
}

.w-597px {
  width: 597px;
}

.w-598 {
  width: 598%;
}

.w-598rem {
  width: 598rem;
}

.w-598px {
  width: 598px;
}

.w-599 {
  width: 599%;
}

.w-599rem {
  width: 599rem;
}

.w-599px {
  width: 599px;
}

.w-600 {
  width: 600%;
}

.w-600rem {
  width: 600rem;
}

.w-600px {
  width: 600px;
}

.w-601 {
  width: 601%;
}

.w-601rem {
  width: 601rem;
}

.w-601px {
  width: 601px;
}

.w-602 {
  width: 602%;
}

.w-602rem {
  width: 602rem;
}

.w-602px {
  width: 602px;
}

.w-603 {
  width: 603%;
}

.w-603rem {
  width: 603rem;
}

.w-603px {
  width: 603px;
}

.w-604 {
  width: 604%;
}

.w-604rem {
  width: 604rem;
}

.w-604px {
  width: 604px;
}

.w-605 {
  width: 605%;
}

.w-605rem {
  width: 605rem;
}

.w-605px {
  width: 605px;
}

.w-606 {
  width: 606%;
}

.w-606rem {
  width: 606rem;
}

.w-606px {
  width: 606px;
}

.w-607 {
  width: 607%;
}

.w-607rem {
  width: 607rem;
}

.w-607px {
  width: 607px;
}

.w-608 {
  width: 608%;
}

.w-608rem {
  width: 608rem;
}

.w-608px {
  width: 608px;
}

.w-609 {
  width: 609%;
}

.w-609rem {
  width: 609rem;
}

.w-609px {
  width: 609px;
}

.w-610 {
  width: 610%;
}

.w-610rem {
  width: 610rem;
}

.w-610px {
  width: 610px;
}

.w-611 {
  width: 611%;
}

.w-611rem {
  width: 611rem;
}

.w-611px {
  width: 611px;
}

.w-612 {
  width: 612%;
}

.w-612rem {
  width: 612rem;
}

.w-612px {
  width: 612px;
}

.w-613 {
  width: 613%;
}

.w-613rem {
  width: 613rem;
}

.w-613px {
  width: 613px;
}

.w-614 {
  width: 614%;
}

.w-614rem {
  width: 614rem;
}

.w-614px {
  width: 614px;
}

.w-615 {
  width: 615%;
}

.w-615rem {
  width: 615rem;
}

.w-615px {
  width: 615px;
}

.w-616 {
  width: 616%;
}

.w-616rem {
  width: 616rem;
}

.w-616px {
  width: 616px;
}

.w-617 {
  width: 617%;
}

.w-617rem {
  width: 617rem;
}

.w-617px {
  width: 617px;
}

.w-618 {
  width: 618%;
}

.w-618rem {
  width: 618rem;
}

.w-618px {
  width: 618px;
}

.w-619 {
  width: 619%;
}

.w-619rem {
  width: 619rem;
}

.w-619px {
  width: 619px;
}

.w-620 {
  width: 620%;
}

.w-620rem {
  width: 620rem;
}

.w-620px {
  width: 620px;
}

.w-621 {
  width: 621%;
}

.w-621rem {
  width: 621rem;
}

.w-621px {
  width: 621px;
}

.w-622 {
  width: 622%;
}

.w-622rem {
  width: 622rem;
}

.w-622px {
  width: 622px;
}

.w-623 {
  width: 623%;
}

.w-623rem {
  width: 623rem;
}

.w-623px {
  width: 623px;
}

.w-624 {
  width: 624%;
}

.w-624rem {
  width: 624rem;
}

.w-624px {
  width: 624px;
}

.w-625 {
  width: 625%;
}

.w-625rem {
  width: 625rem;
}

.w-625px {
  width: 625px;
}

.w-626 {
  width: 626%;
}

.w-626rem {
  width: 626rem;
}

.w-626px {
  width: 626px;
}

.w-627 {
  width: 627%;
}

.w-627rem {
  width: 627rem;
}

.w-627px {
  width: 627px;
}

.w-628 {
  width: 628%;
}

.w-628rem {
  width: 628rem;
}

.w-628px {
  width: 628px;
}

.w-629 {
  width: 629%;
}

.w-629rem {
  width: 629rem;
}

.w-629px {
  width: 629px;
}

.w-630 {
  width: 630%;
}

.w-630rem {
  width: 630rem;
}

.w-630px {
  width: 630px;
}

.w-631 {
  width: 631%;
}

.w-631rem {
  width: 631rem;
}

.w-631px {
  width: 631px;
}

.w-632 {
  width: 632%;
}

.w-632rem {
  width: 632rem;
}

.w-632px {
  width: 632px;
}

.w-633 {
  width: 633%;
}

.w-633rem {
  width: 633rem;
}

.w-633px {
  width: 633px;
}

.w-634 {
  width: 634%;
}

.w-634rem {
  width: 634rem;
}

.w-634px {
  width: 634px;
}

.w-635 {
  width: 635%;
}

.w-635rem {
  width: 635rem;
}

.w-635px {
  width: 635px;
}

.w-636 {
  width: 636%;
}

.w-636rem {
  width: 636rem;
}

.w-636px {
  width: 636px;
}

.w-637 {
  width: 637%;
}

.w-637rem {
  width: 637rem;
}

.w-637px {
  width: 637px;
}

.w-638 {
  width: 638%;
}

.w-638rem {
  width: 638rem;
}

.w-638px {
  width: 638px;
}

.w-639 {
  width: 639%;
}

.w-639rem {
  width: 639rem;
}

.w-639px {
  width: 639px;
}

.w-640 {
  width: 640%;
}

.w-640rem {
  width: 640rem;
}

.w-640px {
  width: 640px;
}

.w-641 {
  width: 641%;
}

.w-641rem {
  width: 641rem;
}

.w-641px {
  width: 641px;
}

.w-642 {
  width: 642%;
}

.w-642rem {
  width: 642rem;
}

.w-642px {
  width: 642px;
}

.w-643 {
  width: 643%;
}

.w-643rem {
  width: 643rem;
}

.w-643px {
  width: 643px;
}

.w-644 {
  width: 644%;
}

.w-644rem {
  width: 644rem;
}

.w-644px {
  width: 644px;
}

.w-645 {
  width: 645%;
}

.w-645rem {
  width: 645rem;
}

.w-645px {
  width: 645px;
}

.w-646 {
  width: 646%;
}

.w-646rem {
  width: 646rem;
}

.w-646px {
  width: 646px;
}

.w-647 {
  width: 647%;
}

.w-647rem {
  width: 647rem;
}

.w-647px {
  width: 647px;
}

.w-648 {
  width: 648%;
}

.w-648rem {
  width: 648rem;
}

.w-648px {
  width: 648px;
}

.w-649 {
  width: 649%;
}

.w-649rem {
  width: 649rem;
}

.w-649px {
  width: 649px;
}

.w-650 {
  width: 650%;
}

.w-650rem {
  width: 650rem;
}

.w-650px {
  width: 650px;
}

.w-651 {
  width: 651%;
}

.w-651rem {
  width: 651rem;
}

.w-651px {
  width: 651px;
}

.w-652 {
  width: 652%;
}

.w-652rem {
  width: 652rem;
}

.w-652px {
  width: 652px;
}

.w-653 {
  width: 653%;
}

.w-653rem {
  width: 653rem;
}

.w-653px {
  width: 653px;
}

.w-654 {
  width: 654%;
}

.w-654rem {
  width: 654rem;
}

.w-654px {
  width: 654px;
}

.w-655 {
  width: 655%;
}

.w-655rem {
  width: 655rem;
}

.w-655px {
  width: 655px;
}

.w-656 {
  width: 656%;
}

.w-656rem {
  width: 656rem;
}

.w-656px {
  width: 656px;
}

.w-657 {
  width: 657%;
}

.w-657rem {
  width: 657rem;
}

.w-657px {
  width: 657px;
}

.w-658 {
  width: 658%;
}

.w-658rem {
  width: 658rem;
}

.w-658px {
  width: 658px;
}

.w-659 {
  width: 659%;
}

.w-659rem {
  width: 659rem;
}

.w-659px {
  width: 659px;
}

.w-660 {
  width: 660%;
}

.w-660rem {
  width: 660rem;
}

.w-660px {
  width: 660px;
}

.w-661 {
  width: 661%;
}

.w-661rem {
  width: 661rem;
}

.w-661px {
  width: 661px;
}

.w-662 {
  width: 662%;
}

.w-662rem {
  width: 662rem;
}

.w-662px {
  width: 662px;
}

.w-663 {
  width: 663%;
}

.w-663rem {
  width: 663rem;
}

.w-663px {
  width: 663px;
}

.w-664 {
  width: 664%;
}

.w-664rem {
  width: 664rem;
}

.w-664px {
  width: 664px;
}

.w-665 {
  width: 665%;
}

.w-665rem {
  width: 665rem;
}

.w-665px {
  width: 665px;
}

.w-666 {
  width: 666%;
}

.w-666rem {
  width: 666rem;
}

.w-666px {
  width: 666px;
}

.w-667 {
  width: 667%;
}

.w-667rem {
  width: 667rem;
}

.w-667px {
  width: 667px;
}

.w-668 {
  width: 668%;
}

.w-668rem {
  width: 668rem;
}

.w-668px {
  width: 668px;
}

.w-669 {
  width: 669%;
}

.w-669rem {
  width: 669rem;
}

.w-669px {
  width: 669px;
}

.w-670 {
  width: 670%;
}

.w-670rem {
  width: 670rem;
}

.w-670px {
  width: 670px;
}

.w-671 {
  width: 671%;
}

.w-671rem {
  width: 671rem;
}

.w-671px {
  width: 671px;
}

.w-672 {
  width: 672%;
}

.w-672rem {
  width: 672rem;
}

.w-672px {
  width: 672px;
}

.w-673 {
  width: 673%;
}

.w-673rem {
  width: 673rem;
}

.w-673px {
  width: 673px;
}

.w-674 {
  width: 674%;
}

.w-674rem {
  width: 674rem;
}

.w-674px {
  width: 674px;
}

.w-675 {
  width: 675%;
}

.w-675rem {
  width: 675rem;
}

.w-675px {
  width: 675px;
}

.w-676 {
  width: 676%;
}

.w-676rem {
  width: 676rem;
}

.w-676px {
  width: 676px;
}

.w-677 {
  width: 677%;
}

.w-677rem {
  width: 677rem;
}

.w-677px {
  width: 677px;
}

.w-678 {
  width: 678%;
}

.w-678rem {
  width: 678rem;
}

.w-678px {
  width: 678px;
}

.w-679 {
  width: 679%;
}

.w-679rem {
  width: 679rem;
}

.w-679px {
  width: 679px;
}

.w-680 {
  width: 680%;
}

.w-680rem {
  width: 680rem;
}

.w-680px {
  width: 680px;
}

.w-681 {
  width: 681%;
}

.w-681rem {
  width: 681rem;
}

.w-681px {
  width: 681px;
}

.w-682 {
  width: 682%;
}

.w-682rem {
  width: 682rem;
}

.w-682px {
  width: 682px;
}

.w-683 {
  width: 683%;
}

.w-683rem {
  width: 683rem;
}

.w-683px {
  width: 683px;
}

.w-684 {
  width: 684%;
}

.w-684rem {
  width: 684rem;
}

.w-684px {
  width: 684px;
}

.w-685 {
  width: 685%;
}

.w-685rem {
  width: 685rem;
}

.w-685px {
  width: 685px;
}

.w-686 {
  width: 686%;
}

.w-686rem {
  width: 686rem;
}

.w-686px {
  width: 686px;
}

.w-687 {
  width: 687%;
}

.w-687rem {
  width: 687rem;
}

.w-687px {
  width: 687px;
}

.w-688 {
  width: 688%;
}

.w-688rem {
  width: 688rem;
}

.w-688px {
  width: 688px;
}

.w-689 {
  width: 689%;
}

.w-689rem {
  width: 689rem;
}

.w-689px {
  width: 689px;
}

.w-690 {
  width: 690%;
}

.w-690rem {
  width: 690rem;
}

.w-690px {
  width: 690px;
}

.w-691 {
  width: 691%;
}

.w-691rem {
  width: 691rem;
}

.w-691px {
  width: 691px;
}

.w-692 {
  width: 692%;
}

.w-692rem {
  width: 692rem;
}

.w-692px {
  width: 692px;
}

.w-693 {
  width: 693%;
}

.w-693rem {
  width: 693rem;
}

.w-693px {
  width: 693px;
}

.w-694 {
  width: 694%;
}

.w-694rem {
  width: 694rem;
}

.w-694px {
  width: 694px;
}

.w-695 {
  width: 695%;
}

.w-695rem {
  width: 695rem;
}

.w-695px {
  width: 695px;
}

.w-696 {
  width: 696%;
}

.w-696rem {
  width: 696rem;
}

.w-696px {
  width: 696px;
}

.w-697 {
  width: 697%;
}

.w-697rem {
  width: 697rem;
}

.w-697px {
  width: 697px;
}

.w-698 {
  width: 698%;
}

.w-698rem {
  width: 698rem;
}

.w-698px {
  width: 698px;
}

.w-699 {
  width: 699%;
}

.w-699rem {
  width: 699rem;
}

.w-699px {
  width: 699px;
}

.w-700 {
  width: 700%;
}

.w-700rem {
  width: 700rem;
}

.w-700px {
  width: 700px;
}

.w-701 {
  width: 701%;
}

.w-701rem {
  width: 701rem;
}

.w-701px {
  width: 701px;
}

.w-702 {
  width: 702%;
}

.w-702rem {
  width: 702rem;
}

.w-702px {
  width: 702px;
}

.w-703 {
  width: 703%;
}

.w-703rem {
  width: 703rem;
}

.w-703px {
  width: 703px;
}

.w-704 {
  width: 704%;
}

.w-704rem {
  width: 704rem;
}

.w-704px {
  width: 704px;
}

.w-705 {
  width: 705%;
}

.w-705rem {
  width: 705rem;
}

.w-705px {
  width: 705px;
}

.w-706 {
  width: 706%;
}

.w-706rem {
  width: 706rem;
}

.w-706px {
  width: 706px;
}

.w-707 {
  width: 707%;
}

.w-707rem {
  width: 707rem;
}

.w-707px {
  width: 707px;
}

.w-708 {
  width: 708%;
}

.w-708rem {
  width: 708rem;
}

.w-708px {
  width: 708px;
}

.w-709 {
  width: 709%;
}

.w-709rem {
  width: 709rem;
}

.w-709px {
  width: 709px;
}

.w-710 {
  width: 710%;
}

.w-710rem {
  width: 710rem;
}

.w-710px {
  width: 710px;
}

.w-711 {
  width: 711%;
}

.w-711rem {
  width: 711rem;
}

.w-711px {
  width: 711px;
}

.w-712 {
  width: 712%;
}

.w-712rem {
  width: 712rem;
}

.w-712px {
  width: 712px;
}

.w-713 {
  width: 713%;
}

.w-713rem {
  width: 713rem;
}

.w-713px {
  width: 713px;
}

.w-714 {
  width: 714%;
}

.w-714rem {
  width: 714rem;
}

.w-714px {
  width: 714px;
}

.w-715 {
  width: 715%;
}

.w-715rem {
  width: 715rem;
}

.w-715px {
  width: 715px;
}

.w-716 {
  width: 716%;
}

.w-716rem {
  width: 716rem;
}

.w-716px {
  width: 716px;
}

.w-717 {
  width: 717%;
}

.w-717rem {
  width: 717rem;
}

.w-717px {
  width: 717px;
}

.w-718 {
  width: 718%;
}

.w-718rem {
  width: 718rem;
}

.w-718px {
  width: 718px;
}

.w-719 {
  width: 719%;
}

.w-719rem {
  width: 719rem;
}

.w-719px {
  width: 719px;
}

.w-720 {
  width: 720%;
}

.w-720rem {
  width: 720rem;
}

.w-720px {
  width: 720px;
}

.w-721 {
  width: 721%;
}

.w-721rem {
  width: 721rem;
}

.w-721px {
  width: 721px;
}

.w-722 {
  width: 722%;
}

.w-722rem {
  width: 722rem;
}

.w-722px {
  width: 722px;
}

.w-723 {
  width: 723%;
}

.w-723rem {
  width: 723rem;
}

.w-723px {
  width: 723px;
}

.w-724 {
  width: 724%;
}

.w-724rem {
  width: 724rem;
}

.w-724px {
  width: 724px;
}

.w-725 {
  width: 725%;
}

.w-725rem {
  width: 725rem;
}

.w-725px {
  width: 725px;
}

.w-726 {
  width: 726%;
}

.w-726rem {
  width: 726rem;
}

.w-726px {
  width: 726px;
}

.w-727 {
  width: 727%;
}

.w-727rem {
  width: 727rem;
}

.w-727px {
  width: 727px;
}

.w-728 {
  width: 728%;
}

.w-728rem {
  width: 728rem;
}

.w-728px {
  width: 728px;
}

.w-729 {
  width: 729%;
}

.w-729rem {
  width: 729rem;
}

.w-729px {
  width: 729px;
}

.w-730 {
  width: 730%;
}

.w-730rem {
  width: 730rem;
}

.w-730px {
  width: 730px;
}

.w-731 {
  width: 731%;
}

.w-731rem {
  width: 731rem;
}

.w-731px {
  width: 731px;
}

.w-732 {
  width: 732%;
}

.w-732rem {
  width: 732rem;
}

.w-732px {
  width: 732px;
}

.w-733 {
  width: 733%;
}

.w-733rem {
  width: 733rem;
}

.w-733px {
  width: 733px;
}

.w-734 {
  width: 734%;
}

.w-734rem {
  width: 734rem;
}

.w-734px {
  width: 734px;
}

.w-735 {
  width: 735%;
}

.w-735rem {
  width: 735rem;
}

.w-735px {
  width: 735px;
}

.w-736 {
  width: 736%;
}

.w-736rem {
  width: 736rem;
}

.w-736px {
  width: 736px;
}

.w-737 {
  width: 737%;
}

.w-737rem {
  width: 737rem;
}

.w-737px {
  width: 737px;
}

.w-738 {
  width: 738%;
}

.w-738rem {
  width: 738rem;
}

.w-738px {
  width: 738px;
}

.w-739 {
  width: 739%;
}

.w-739rem {
  width: 739rem;
}

.w-739px {
  width: 739px;
}

.w-740 {
  width: 740%;
}

.w-740rem {
  width: 740rem;
}

.w-740px {
  width: 740px;
}

.w-741 {
  width: 741%;
}

.w-741rem {
  width: 741rem;
}

.w-741px {
  width: 741px;
}

.w-742 {
  width: 742%;
}

.w-742rem {
  width: 742rem;
}

.w-742px {
  width: 742px;
}

.w-743 {
  width: 743%;
}

.w-743rem {
  width: 743rem;
}

.w-743px {
  width: 743px;
}

.w-744 {
  width: 744%;
}

.w-744rem {
  width: 744rem;
}

.w-744px {
  width: 744px;
}

.w-745 {
  width: 745%;
}

.w-745rem {
  width: 745rem;
}

.w-745px {
  width: 745px;
}

.w-746 {
  width: 746%;
}

.w-746rem {
  width: 746rem;
}

.w-746px {
  width: 746px;
}

.w-747 {
  width: 747%;
}

.w-747rem {
  width: 747rem;
}

.w-747px {
  width: 747px;
}

.w-748 {
  width: 748%;
}

.w-748rem {
  width: 748rem;
}

.w-748px {
  width: 748px;
}

.w-749 {
  width: 749%;
}

.w-749rem {
  width: 749rem;
}

.w-749px {
  width: 749px;
}

.w-750 {
  width: 750%;
}

.w-750rem {
  width: 750rem;
}

.w-750px {
  width: 750px;
}

.w-751 {
  width: 751%;
}

.w-751rem {
  width: 751rem;
}

.w-751px {
  width: 751px;
}

.w-752 {
  width: 752%;
}

.w-752rem {
  width: 752rem;
}

.w-752px {
  width: 752px;
}

.w-753 {
  width: 753%;
}

.w-753rem {
  width: 753rem;
}

.w-753px {
  width: 753px;
}

.w-754 {
  width: 754%;
}

.w-754rem {
  width: 754rem;
}

.w-754px {
  width: 754px;
}

.w-755 {
  width: 755%;
}

.w-755rem {
  width: 755rem;
}

.w-755px {
  width: 755px;
}

.w-756 {
  width: 756%;
}

.w-756rem {
  width: 756rem;
}

.w-756px {
  width: 756px;
}

.w-757 {
  width: 757%;
}

.w-757rem {
  width: 757rem;
}

.w-757px {
  width: 757px;
}

.w-758 {
  width: 758%;
}

.w-758rem {
  width: 758rem;
}

.w-758px {
  width: 758px;
}

.w-759 {
  width: 759%;
}

.w-759rem {
  width: 759rem;
}

.w-759px {
  width: 759px;
}

.w-760 {
  width: 760%;
}

.w-760rem {
  width: 760rem;
}

.w-760px {
  width: 760px;
}

.w-761 {
  width: 761%;
}

.w-761rem {
  width: 761rem;
}

.w-761px {
  width: 761px;
}

.w-762 {
  width: 762%;
}

.w-762rem {
  width: 762rem;
}

.w-762px {
  width: 762px;
}

.w-763 {
  width: 763%;
}

.w-763rem {
  width: 763rem;
}

.w-763px {
  width: 763px;
}

.w-764 {
  width: 764%;
}

.w-764rem {
  width: 764rem;
}

.w-764px {
  width: 764px;
}

.w-765 {
  width: 765%;
}

.w-765rem {
  width: 765rem;
}

.w-765px {
  width: 765px;
}

.w-766 {
  width: 766%;
}

.w-766rem {
  width: 766rem;
}

.w-766px {
  width: 766px;
}

.w-767 {
  width: 767%;
}

.w-767rem {
  width: 767rem;
}

.w-767px {
  width: 767px;
}

.w-768 {
  width: 768%;
}

.w-768rem {
  width: 768rem;
}

.w-768px {
  width: 768px;
}

.w-769 {
  width: 769%;
}

.w-769rem {
  width: 769rem;
}

.w-769px {
  width: 769px;
}

.w-770 {
  width: 770%;
}

.w-770rem {
  width: 770rem;
}

.w-770px {
  width: 770px;
}

.w-771 {
  width: 771%;
}

.w-771rem {
  width: 771rem;
}

.w-771px {
  width: 771px;
}

.w-772 {
  width: 772%;
}

.w-772rem {
  width: 772rem;
}

.w-772px {
  width: 772px;
}

.w-773 {
  width: 773%;
}

.w-773rem {
  width: 773rem;
}

.w-773px {
  width: 773px;
}

.w-774 {
  width: 774%;
}

.w-774rem {
  width: 774rem;
}

.w-774px {
  width: 774px;
}

.w-775 {
  width: 775%;
}

.w-775rem {
  width: 775rem;
}

.w-775px {
  width: 775px;
}

.w-776 {
  width: 776%;
}

.w-776rem {
  width: 776rem;
}

.w-776px {
  width: 776px;
}

.w-777 {
  width: 777%;
}

.w-777rem {
  width: 777rem;
}

.w-777px {
  width: 777px;
}

.w-778 {
  width: 778%;
}

.w-778rem {
  width: 778rem;
}

.w-778px {
  width: 778px;
}

.w-779 {
  width: 779%;
}

.w-779rem {
  width: 779rem;
}

.w-779px {
  width: 779px;
}

.w-780 {
  width: 780%;
}

.w-780rem {
  width: 780rem;
}

.w-780px {
  width: 780px;
}

.w-781 {
  width: 781%;
}

.w-781rem {
  width: 781rem;
}

.w-781px {
  width: 781px;
}

.w-782 {
  width: 782%;
}

.w-782rem {
  width: 782rem;
}

.w-782px {
  width: 782px;
}

.w-783 {
  width: 783%;
}

.w-783rem {
  width: 783rem;
}

.w-783px {
  width: 783px;
}

.w-784 {
  width: 784%;
}

.w-784rem {
  width: 784rem;
}

.w-784px {
  width: 784px;
}

.w-785 {
  width: 785%;
}

.w-785rem {
  width: 785rem;
}

.w-785px {
  width: 785px;
}

.w-786 {
  width: 786%;
}

.w-786rem {
  width: 786rem;
}

.w-786px {
  width: 786px;
}

.w-787 {
  width: 787%;
}

.w-787rem {
  width: 787rem;
}

.w-787px {
  width: 787px;
}

.w-788 {
  width: 788%;
}

.w-788rem {
  width: 788rem;
}

.w-788px {
  width: 788px;
}

.w-789 {
  width: 789%;
}

.w-789rem {
  width: 789rem;
}

.w-789px {
  width: 789px;
}

.w-790 {
  width: 790%;
}

.w-790rem {
  width: 790rem;
}

.w-790px {
  width: 790px;
}

.w-791 {
  width: 791%;
}

.w-791rem {
  width: 791rem;
}

.w-791px {
  width: 791px;
}

.w-792 {
  width: 792%;
}

.w-792rem {
  width: 792rem;
}

.w-792px {
  width: 792px;
}

.w-793 {
  width: 793%;
}

.w-793rem {
  width: 793rem;
}

.w-793px {
  width: 793px;
}

.w-794 {
  width: 794%;
}

.w-794rem {
  width: 794rem;
}

.w-794px {
  width: 794px;
}

.w-795 {
  width: 795%;
}

.w-795rem {
  width: 795rem;
}

.w-795px {
  width: 795px;
}

.w-796 {
  width: 796%;
}

.w-796rem {
  width: 796rem;
}

.w-796px {
  width: 796px;
}

.w-797 {
  width: 797%;
}

.w-797rem {
  width: 797rem;
}

.w-797px {
  width: 797px;
}

.w-798 {
  width: 798%;
}

.w-798rem {
  width: 798rem;
}

.w-798px {
  width: 798px;
}

.w-799 {
  width: 799%;
}

.w-799rem {
  width: 799rem;
}

.w-799px {
  width: 799px;
}

.w-800 {
  width: 800%;
}

.w-800rem {
  width: 800rem;
}

.w-800px {
  width: 800px;
}

.w-801 {
  width: 801%;
}

.w-801rem {
  width: 801rem;
}

.w-801px {
  width: 801px;
}

.w-802 {
  width: 802%;
}

.w-802rem {
  width: 802rem;
}

.w-802px {
  width: 802px;
}

.w-803 {
  width: 803%;
}

.w-803rem {
  width: 803rem;
}

.w-803px {
  width: 803px;
}

.w-804 {
  width: 804%;
}

.w-804rem {
  width: 804rem;
}

.w-804px {
  width: 804px;
}

.w-805 {
  width: 805%;
}

.w-805rem {
  width: 805rem;
}

.w-805px {
  width: 805px;
}

.w-806 {
  width: 806%;
}

.w-806rem {
  width: 806rem;
}

.w-806px {
  width: 806px;
}

.w-807 {
  width: 807%;
}

.w-807rem {
  width: 807rem;
}

.w-807px {
  width: 807px;
}

.w-808 {
  width: 808%;
}

.w-808rem {
  width: 808rem;
}

.w-808px {
  width: 808px;
}

.w-809 {
  width: 809%;
}

.w-809rem {
  width: 809rem;
}

.w-809px {
  width: 809px;
}

.w-810 {
  width: 810%;
}

.w-810rem {
  width: 810rem;
}

.w-810px {
  width: 810px;
}

.w-811 {
  width: 811%;
}

.w-811rem {
  width: 811rem;
}

.w-811px {
  width: 811px;
}

.w-812 {
  width: 812%;
}

.w-812rem {
  width: 812rem;
}

.w-812px {
  width: 812px;
}

.w-813 {
  width: 813%;
}

.w-813rem {
  width: 813rem;
}

.w-813px {
  width: 813px;
}

.w-814 {
  width: 814%;
}

.w-814rem {
  width: 814rem;
}

.w-814px {
  width: 814px;
}

.w-815 {
  width: 815%;
}

.w-815rem {
  width: 815rem;
}

.w-815px {
  width: 815px;
}

.w-816 {
  width: 816%;
}

.w-816rem {
  width: 816rem;
}

.w-816px {
  width: 816px;
}

.w-817 {
  width: 817%;
}

.w-817rem {
  width: 817rem;
}

.w-817px {
  width: 817px;
}

.w-818 {
  width: 818%;
}

.w-818rem {
  width: 818rem;
}

.w-818px {
  width: 818px;
}

.w-819 {
  width: 819%;
}

.w-819rem {
  width: 819rem;
}

.w-819px {
  width: 819px;
}

.w-820 {
  width: 820%;
}

.w-820rem {
  width: 820rem;
}

.w-820px {
  width: 820px;
}

.w-821 {
  width: 821%;
}

.w-821rem {
  width: 821rem;
}

.w-821px {
  width: 821px;
}

.w-822 {
  width: 822%;
}

.w-822rem {
  width: 822rem;
}

.w-822px {
  width: 822px;
}

.w-823 {
  width: 823%;
}

.w-823rem {
  width: 823rem;
}

.w-823px {
  width: 823px;
}

.w-824 {
  width: 824%;
}

.w-824rem {
  width: 824rem;
}

.w-824px {
  width: 824px;
}

.w-825 {
  width: 825%;
}

.w-825rem {
  width: 825rem;
}

.w-825px {
  width: 825px;
}

.w-826 {
  width: 826%;
}

.w-826rem {
  width: 826rem;
}

.w-826px {
  width: 826px;
}

.w-827 {
  width: 827%;
}

.w-827rem {
  width: 827rem;
}

.w-827px {
  width: 827px;
}

.w-828 {
  width: 828%;
}

.w-828rem {
  width: 828rem;
}

.w-828px {
  width: 828px;
}

.w-829 {
  width: 829%;
}

.w-829rem {
  width: 829rem;
}

.w-829px {
  width: 829px;
}

.w-830 {
  width: 830%;
}

.w-830rem {
  width: 830rem;
}

.w-830px {
  width: 830px;
}

.w-831 {
  width: 831%;
}

.w-831rem {
  width: 831rem;
}

.w-831px {
  width: 831px;
}

.w-832 {
  width: 832%;
}

.w-832rem {
  width: 832rem;
}

.w-832px {
  width: 832px;
}

.w-833 {
  width: 833%;
}

.w-833rem {
  width: 833rem;
}

.w-833px {
  width: 833px;
}

.w-834 {
  width: 834%;
}

.w-834rem {
  width: 834rem;
}

.w-834px {
  width: 834px;
}

.w-835 {
  width: 835%;
}

.w-835rem {
  width: 835rem;
}

.w-835px {
  width: 835px;
}

.w-836 {
  width: 836%;
}

.w-836rem {
  width: 836rem;
}

.w-836px {
  width: 836px;
}

.w-837 {
  width: 837%;
}

.w-837rem {
  width: 837rem;
}

.w-837px {
  width: 837px;
}

.w-838 {
  width: 838%;
}

.w-838rem {
  width: 838rem;
}

.w-838px {
  width: 838px;
}

.w-839 {
  width: 839%;
}

.w-839rem {
  width: 839rem;
}

.w-839px {
  width: 839px;
}

.w-840 {
  width: 840%;
}

.w-840rem {
  width: 840rem;
}

.w-840px {
  width: 840px;
}

.w-841 {
  width: 841%;
}

.w-841rem {
  width: 841rem;
}

.w-841px {
  width: 841px;
}

.w-842 {
  width: 842%;
}

.w-842rem {
  width: 842rem;
}

.w-842px {
  width: 842px;
}

.w-843 {
  width: 843%;
}

.w-843rem {
  width: 843rem;
}

.w-843px {
  width: 843px;
}

.w-844 {
  width: 844%;
}

.w-844rem {
  width: 844rem;
}

.w-844px {
  width: 844px;
}

.w-845 {
  width: 845%;
}

.w-845rem {
  width: 845rem;
}

.w-845px {
  width: 845px;
}

.w-846 {
  width: 846%;
}

.w-846rem {
  width: 846rem;
}

.w-846px {
  width: 846px;
}

.w-847 {
  width: 847%;
}

.w-847rem {
  width: 847rem;
}

.w-847px {
  width: 847px;
}

.w-848 {
  width: 848%;
}

.w-848rem {
  width: 848rem;
}

.w-848px {
  width: 848px;
}

.w-849 {
  width: 849%;
}

.w-849rem {
  width: 849rem;
}

.w-849px {
  width: 849px;
}

.w-850 {
  width: 850%;
}

.w-850rem {
  width: 850rem;
}

.w-850px {
  width: 850px;
}

.w-851 {
  width: 851%;
}

.w-851rem {
  width: 851rem;
}

.w-851px {
  width: 851px;
}

.w-852 {
  width: 852%;
}

.w-852rem {
  width: 852rem;
}

.w-852px {
  width: 852px;
}

.w-853 {
  width: 853%;
}

.w-853rem {
  width: 853rem;
}

.w-853px {
  width: 853px;
}

.w-854 {
  width: 854%;
}

.w-854rem {
  width: 854rem;
}

.w-854px {
  width: 854px;
}

.w-855 {
  width: 855%;
}

.w-855rem {
  width: 855rem;
}

.w-855px {
  width: 855px;
}

.w-856 {
  width: 856%;
}

.w-856rem {
  width: 856rem;
}

.w-856px {
  width: 856px;
}

.w-857 {
  width: 857%;
}

.w-857rem {
  width: 857rem;
}

.w-857px {
  width: 857px;
}

.w-858 {
  width: 858%;
}

.w-858rem {
  width: 858rem;
}

.w-858px {
  width: 858px;
}

.w-859 {
  width: 859%;
}

.w-859rem {
  width: 859rem;
}

.w-859px {
  width: 859px;
}

.w-860 {
  width: 860%;
}

.w-860rem {
  width: 860rem;
}

.w-860px {
  width: 860px;
}

.w-861 {
  width: 861%;
}

.w-861rem {
  width: 861rem;
}

.w-861px {
  width: 861px;
}

.w-862 {
  width: 862%;
}

.w-862rem {
  width: 862rem;
}

.w-862px {
  width: 862px;
}

.w-863 {
  width: 863%;
}

.w-863rem {
  width: 863rem;
}

.w-863px {
  width: 863px;
}

.w-864 {
  width: 864%;
}

.w-864rem {
  width: 864rem;
}

.w-864px {
  width: 864px;
}

.w-865 {
  width: 865%;
}

.w-865rem {
  width: 865rem;
}

.w-865px {
  width: 865px;
}

.w-866 {
  width: 866%;
}

.w-866rem {
  width: 866rem;
}

.w-866px {
  width: 866px;
}

.w-867 {
  width: 867%;
}

.w-867rem {
  width: 867rem;
}

.w-867px {
  width: 867px;
}

.w-868 {
  width: 868%;
}

.w-868rem {
  width: 868rem;
}

.w-868px {
  width: 868px;
}

.w-869 {
  width: 869%;
}

.w-869rem {
  width: 869rem;
}

.w-869px {
  width: 869px;
}

.w-870 {
  width: 870%;
}

.w-870rem {
  width: 870rem;
}

.w-870px {
  width: 870px;
}

.w-871 {
  width: 871%;
}

.w-871rem {
  width: 871rem;
}

.w-871px {
  width: 871px;
}

.w-872 {
  width: 872%;
}

.w-872rem {
  width: 872rem;
}

.w-872px {
  width: 872px;
}

.w-873 {
  width: 873%;
}

.w-873rem {
  width: 873rem;
}

.w-873px {
  width: 873px;
}

.w-874 {
  width: 874%;
}

.w-874rem {
  width: 874rem;
}

.w-874px {
  width: 874px;
}

.w-875 {
  width: 875%;
}

.w-875rem {
  width: 875rem;
}

.w-875px {
  width: 875px;
}

.w-876 {
  width: 876%;
}

.w-876rem {
  width: 876rem;
}

.w-876px {
  width: 876px;
}

.w-877 {
  width: 877%;
}

.w-877rem {
  width: 877rem;
}

.w-877px {
  width: 877px;
}

.w-878 {
  width: 878%;
}

.w-878rem {
  width: 878rem;
}

.w-878px {
  width: 878px;
}

.w-879 {
  width: 879%;
}

.w-879rem {
  width: 879rem;
}

.w-879px {
  width: 879px;
}

.w-880 {
  width: 880%;
}

.w-880rem {
  width: 880rem;
}

.w-880px {
  width: 880px;
}

.w-881 {
  width: 881%;
}

.w-881rem {
  width: 881rem;
}

.w-881px {
  width: 881px;
}

.w-882 {
  width: 882%;
}

.w-882rem {
  width: 882rem;
}

.w-882px {
  width: 882px;
}

.w-883 {
  width: 883%;
}

.w-883rem {
  width: 883rem;
}

.w-883px {
  width: 883px;
}

.w-884 {
  width: 884%;
}

.w-884rem {
  width: 884rem;
}

.w-884px {
  width: 884px;
}

.w-885 {
  width: 885%;
}

.w-885rem {
  width: 885rem;
}

.w-885px {
  width: 885px;
}

.w-886 {
  width: 886%;
}

.w-886rem {
  width: 886rem;
}

.w-886px {
  width: 886px;
}

.w-887 {
  width: 887%;
}

.w-887rem {
  width: 887rem;
}

.w-887px {
  width: 887px;
}

.w-888 {
  width: 888%;
}

.w-888rem {
  width: 888rem;
}

.w-888px {
  width: 888px;
}

.w-889 {
  width: 889%;
}

.w-889rem {
  width: 889rem;
}

.w-889px {
  width: 889px;
}

.w-890 {
  width: 890%;
}

.w-890rem {
  width: 890rem;
}

.w-890px {
  width: 890px;
}

.w-891 {
  width: 891%;
}

.w-891rem {
  width: 891rem;
}

.w-891px {
  width: 891px;
}

.w-892 {
  width: 892%;
}

.w-892rem {
  width: 892rem;
}

.w-892px {
  width: 892px;
}

.w-893 {
  width: 893%;
}

.w-893rem {
  width: 893rem;
}

.w-893px {
  width: 893px;
}

.w-894 {
  width: 894%;
}

.w-894rem {
  width: 894rem;
}

.w-894px {
  width: 894px;
}

.w-895 {
  width: 895%;
}

.w-895rem {
  width: 895rem;
}

.w-895px {
  width: 895px;
}

.w-896 {
  width: 896%;
}

.w-896rem {
  width: 896rem;
}

.w-896px {
  width: 896px;
}

.w-897 {
  width: 897%;
}

.w-897rem {
  width: 897rem;
}

.w-897px {
  width: 897px;
}

.w-898 {
  width: 898%;
}

.w-898rem {
  width: 898rem;
}

.w-898px {
  width: 898px;
}

.w-899 {
  width: 899%;
}

.w-899rem {
  width: 899rem;
}

.w-899px {
  width: 899px;
}

.w-900 {
  width: 900%;
}

.w-900rem {
  width: 900rem;
}

.w-900px {
  width: 900px;
}

.w-901 {
  width: 901%;
}

.w-901rem {
  width: 901rem;
}

.w-901px {
  width: 901px;
}

.w-902 {
  width: 902%;
}

.w-902rem {
  width: 902rem;
}

.w-902px {
  width: 902px;
}

.w-903 {
  width: 903%;
}

.w-903rem {
  width: 903rem;
}

.w-903px {
  width: 903px;
}

.w-904 {
  width: 904%;
}

.w-904rem {
  width: 904rem;
}

.w-904px {
  width: 904px;
}

.w-905 {
  width: 905%;
}

.w-905rem {
  width: 905rem;
}

.w-905px {
  width: 905px;
}

.w-906 {
  width: 906%;
}

.w-906rem {
  width: 906rem;
}

.w-906px {
  width: 906px;
}

.w-907 {
  width: 907%;
}

.w-907rem {
  width: 907rem;
}

.w-907px {
  width: 907px;
}

.w-908 {
  width: 908%;
}

.w-908rem {
  width: 908rem;
}

.w-908px {
  width: 908px;
}

.w-909 {
  width: 909%;
}

.w-909rem {
  width: 909rem;
}

.w-909px {
  width: 909px;
}

.w-910 {
  width: 910%;
}

.w-910rem {
  width: 910rem;
}

.w-910px {
  width: 910px;
}

.w-911 {
  width: 911%;
}

.w-911rem {
  width: 911rem;
}

.w-911px {
  width: 911px;
}

.w-912 {
  width: 912%;
}

.w-912rem {
  width: 912rem;
}

.w-912px {
  width: 912px;
}

.w-913 {
  width: 913%;
}

.w-913rem {
  width: 913rem;
}

.w-913px {
  width: 913px;
}

.w-914 {
  width: 914%;
}

.w-914rem {
  width: 914rem;
}

.w-914px {
  width: 914px;
}

.w-915 {
  width: 915%;
}

.w-915rem {
  width: 915rem;
}

.w-915px {
  width: 915px;
}

.w-916 {
  width: 916%;
}

.w-916rem {
  width: 916rem;
}

.w-916px {
  width: 916px;
}

.w-917 {
  width: 917%;
}

.w-917rem {
  width: 917rem;
}

.w-917px {
  width: 917px;
}

.w-918 {
  width: 918%;
}

.w-918rem {
  width: 918rem;
}

.w-918px {
  width: 918px;
}

.w-919 {
  width: 919%;
}

.w-919rem {
  width: 919rem;
}

.w-919px {
  width: 919px;
}

.w-920 {
  width: 920%;
}

.w-920rem {
  width: 920rem;
}

.w-920px {
  width: 920px;
}

.w-921 {
  width: 921%;
}

.w-921rem {
  width: 921rem;
}

.w-921px {
  width: 921px;
}

.w-922 {
  width: 922%;
}

.w-922rem {
  width: 922rem;
}

.w-922px {
  width: 922px;
}

.w-923 {
  width: 923%;
}

.w-923rem {
  width: 923rem;
}

.w-923px {
  width: 923px;
}

.w-924 {
  width: 924%;
}

.w-924rem {
  width: 924rem;
}

.w-924px {
  width: 924px;
}

.w-925 {
  width: 925%;
}

.w-925rem {
  width: 925rem;
}

.w-925px {
  width: 925px;
}

.w-926 {
  width: 926%;
}

.w-926rem {
  width: 926rem;
}

.w-926px {
  width: 926px;
}

.w-927 {
  width: 927%;
}

.w-927rem {
  width: 927rem;
}

.w-927px {
  width: 927px;
}

.w-928 {
  width: 928%;
}

.w-928rem {
  width: 928rem;
}

.w-928px {
  width: 928px;
}

.w-929 {
  width: 929%;
}

.w-929rem {
  width: 929rem;
}

.w-929px {
  width: 929px;
}

.w-930 {
  width: 930%;
}

.w-930rem {
  width: 930rem;
}

.w-930px {
  width: 930px;
}

.w-931 {
  width: 931%;
}

.w-931rem {
  width: 931rem;
}

.w-931px {
  width: 931px;
}

.w-932 {
  width: 932%;
}

.w-932rem {
  width: 932rem;
}

.w-932px {
  width: 932px;
}

.w-933 {
  width: 933%;
}

.w-933rem {
  width: 933rem;
}

.w-933px {
  width: 933px;
}

.w-934 {
  width: 934%;
}

.w-934rem {
  width: 934rem;
}

.w-934px {
  width: 934px;
}

.w-935 {
  width: 935%;
}

.w-935rem {
  width: 935rem;
}

.w-935px {
  width: 935px;
}

.w-936 {
  width: 936%;
}

.w-936rem {
  width: 936rem;
}

.w-936px {
  width: 936px;
}

.w-937 {
  width: 937%;
}

.w-937rem {
  width: 937rem;
}

.w-937px {
  width: 937px;
}

.w-938 {
  width: 938%;
}

.w-938rem {
  width: 938rem;
}

.w-938px {
  width: 938px;
}

.w-939 {
  width: 939%;
}

.w-939rem {
  width: 939rem;
}

.w-939px {
  width: 939px;
}

.w-940 {
  width: 940%;
}

.w-940rem {
  width: 940rem;
}

.w-940px {
  width: 940px;
}

.w-941 {
  width: 941%;
}

.w-941rem {
  width: 941rem;
}

.w-941px {
  width: 941px;
}

.w-942 {
  width: 942%;
}

.w-942rem {
  width: 942rem;
}

.w-942px {
  width: 942px;
}

.w-943 {
  width: 943%;
}

.w-943rem {
  width: 943rem;
}

.w-943px {
  width: 943px;
}

.w-944 {
  width: 944%;
}

.w-944rem {
  width: 944rem;
}

.w-944px {
  width: 944px;
}

.w-945 {
  width: 945%;
}

.w-945rem {
  width: 945rem;
}

.w-945px {
  width: 945px;
}

.w-946 {
  width: 946%;
}

.w-946rem {
  width: 946rem;
}

.w-946px {
  width: 946px;
}

.w-947 {
  width: 947%;
}

.w-947rem {
  width: 947rem;
}

.w-947px {
  width: 947px;
}

.w-948 {
  width: 948%;
}

.w-948rem {
  width: 948rem;
}

.w-948px {
  width: 948px;
}

.w-949 {
  width: 949%;
}

.w-949rem {
  width: 949rem;
}

.w-949px {
  width: 949px;
}

.w-950 {
  width: 950%;
}

.w-950rem {
  width: 950rem;
}

.w-950px {
  width: 950px;
}

.w-951 {
  width: 951%;
}

.w-951rem {
  width: 951rem;
}

.w-951px {
  width: 951px;
}

.w-952 {
  width: 952%;
}

.w-952rem {
  width: 952rem;
}

.w-952px {
  width: 952px;
}

.w-953 {
  width: 953%;
}

.w-953rem {
  width: 953rem;
}

.w-953px {
  width: 953px;
}

.w-954 {
  width: 954%;
}

.w-954rem {
  width: 954rem;
}

.w-954px {
  width: 954px;
}

.w-955 {
  width: 955%;
}

.w-955rem {
  width: 955rem;
}

.w-955px {
  width: 955px;
}

.w-956 {
  width: 956%;
}

.w-956rem {
  width: 956rem;
}

.w-956px {
  width: 956px;
}

.w-957 {
  width: 957%;
}

.w-957rem {
  width: 957rem;
}

.w-957px {
  width: 957px;
}

.w-958 {
  width: 958%;
}

.w-958rem {
  width: 958rem;
}

.w-958px {
  width: 958px;
}

.w-959 {
  width: 959%;
}

.w-959rem {
  width: 959rem;
}

.w-959px {
  width: 959px;
}

.w-960 {
  width: 960%;
}

.w-960rem {
  width: 960rem;
}

.w-960px {
  width: 960px;
}

.w-961 {
  width: 961%;
}

.w-961rem {
  width: 961rem;
}

.w-961px {
  width: 961px;
}

.w-962 {
  width: 962%;
}

.w-962rem {
  width: 962rem;
}

.w-962px {
  width: 962px;
}

.w-963 {
  width: 963%;
}

.w-963rem {
  width: 963rem;
}

.w-963px {
  width: 963px;
}

.w-964 {
  width: 964%;
}

.w-964rem {
  width: 964rem;
}

.w-964px {
  width: 964px;
}

.w-965 {
  width: 965%;
}

.w-965rem {
  width: 965rem;
}

.w-965px {
  width: 965px;
}

.w-966 {
  width: 966%;
}

.w-966rem {
  width: 966rem;
}

.w-966px {
  width: 966px;
}

.w-967 {
  width: 967%;
}

.w-967rem {
  width: 967rem;
}

.w-967px {
  width: 967px;
}

.w-968 {
  width: 968%;
}

.w-968rem {
  width: 968rem;
}

.w-968px {
  width: 968px;
}

.w-969 {
  width: 969%;
}

.w-969rem {
  width: 969rem;
}

.w-969px {
  width: 969px;
}

.w-970 {
  width: 970%;
}

.w-970rem {
  width: 970rem;
}

.w-970px {
  width: 970px;
}

.w-971 {
  width: 971%;
}

.w-971rem {
  width: 971rem;
}

.w-971px {
  width: 971px;
}

.w-972 {
  width: 972%;
}

.w-972rem {
  width: 972rem;
}

.w-972px {
  width: 972px;
}

.w-973 {
  width: 973%;
}

.w-973rem {
  width: 973rem;
}

.w-973px {
  width: 973px;
}

.w-974 {
  width: 974%;
}

.w-974rem {
  width: 974rem;
}

.w-974px {
  width: 974px;
}

.w-975 {
  width: 975%;
}

.w-975rem {
  width: 975rem;
}

.w-975px {
  width: 975px;
}

.w-976 {
  width: 976%;
}

.w-976rem {
  width: 976rem;
}

.w-976px {
  width: 976px;
}

.w-977 {
  width: 977%;
}

.w-977rem {
  width: 977rem;
}

.w-977px {
  width: 977px;
}

.w-978 {
  width: 978%;
}

.w-978rem {
  width: 978rem;
}

.w-978px {
  width: 978px;
}

.w-979 {
  width: 979%;
}

.w-979rem {
  width: 979rem;
}

.w-979px {
  width: 979px;
}

.w-980 {
  width: 980%;
}

.w-980rem {
  width: 980rem;
}

.w-980px {
  width: 980px;
}

.w-981 {
  width: 981%;
}

.w-981rem {
  width: 981rem;
}

.w-981px {
  width: 981px;
}

.w-982 {
  width: 982%;
}

.w-982rem {
  width: 982rem;
}

.w-982px {
  width: 982px;
}

.w-983 {
  width: 983%;
}

.w-983rem {
  width: 983rem;
}

.w-983px {
  width: 983px;
}

.w-984 {
  width: 984%;
}

.w-984rem {
  width: 984rem;
}

.w-984px {
  width: 984px;
}

.w-985 {
  width: 985%;
}

.w-985rem {
  width: 985rem;
}

.w-985px {
  width: 985px;
}

.w-986 {
  width: 986%;
}

.w-986rem {
  width: 986rem;
}

.w-986px {
  width: 986px;
}

.w-987 {
  width: 987%;
}

.w-987rem {
  width: 987rem;
}

.w-987px {
  width: 987px;
}

.w-988 {
  width: 988%;
}

.w-988rem {
  width: 988rem;
}

.w-988px {
  width: 988px;
}

.w-989 {
  width: 989%;
}

.w-989rem {
  width: 989rem;
}

.w-989px {
  width: 989px;
}

.w-990 {
  width: 990%;
}

.w-990rem {
  width: 990rem;
}

.w-990px {
  width: 990px;
}

.w-991 {
  width: 991%;
}

.w-991rem {
  width: 991rem;
}

.w-991px {
  width: 991px;
}

.w-992 {
  width: 992%;
}

.w-992rem {
  width: 992rem;
}

.w-992px {
  width: 992px;
}

.w-993 {
  width: 993%;
}

.w-993rem {
  width: 993rem;
}

.w-993px {
  width: 993px;
}

.w-994 {
  width: 994%;
}

.w-994rem {
  width: 994rem;
}

.w-994px {
  width: 994px;
}

.w-995 {
  width: 995%;
}

.w-995rem {
  width: 995rem;
}

.w-995px {
  width: 995px;
}

.w-996 {
  width: 996%;
}

.w-996rem {
  width: 996rem;
}

.w-996px {
  width: 996px;
}

.w-997 {
  width: 997%;
}

.w-997rem {
  width: 997rem;
}

.w-997px {
  width: 997px;
}

.w-998 {
  width: 998%;
}

.w-998rem {
  width: 998rem;
}

.w-998px {
  width: 998px;
}

.w-999 {
  width: 999%;
}

.w-999rem {
  width: 999rem;
}

.w-999px {
  width: 999px;
}

.w-1000 {
  width: 1000%;
}

.w-1000rem {
  width: 1000rem;
}

.w-1000px {
  width: 1000px;
}

.w-1001 {
  width: 1001%;
}

.w-1001rem {
  width: 1001rem;
}

.w-1001px {
  width: 1001px;
}

.w-1002 {
  width: 1002%;
}

.w-1002rem {
  width: 1002rem;
}

.w-1002px {
  width: 1002px;
}

.w-1003 {
  width: 1003%;
}

.w-1003rem {
  width: 1003rem;
}

.w-1003px {
  width: 1003px;
}

.w-1004 {
  width: 1004%;
}

.w-1004rem {
  width: 1004rem;
}

.w-1004px {
  width: 1004px;
}

.w-1005 {
  width: 1005%;
}

.w-1005rem {
  width: 1005rem;
}

.w-1005px {
  width: 1005px;
}

.w-1006 {
  width: 1006%;
}

.w-1006rem {
  width: 1006rem;
}

.w-1006px {
  width: 1006px;
}

.w-1007 {
  width: 1007%;
}

.w-1007rem {
  width: 1007rem;
}

.w-1007px {
  width: 1007px;
}

.w-1008 {
  width: 1008%;
}

.w-1008rem {
  width: 1008rem;
}

.w-1008px {
  width: 1008px;
}

.w-1009 {
  width: 1009%;
}

.w-1009rem {
  width: 1009rem;
}

.w-1009px {
  width: 1009px;
}

.w-1010 {
  width: 1010%;
}

.w-1010rem {
  width: 1010rem;
}

.w-1010px {
  width: 1010px;
}

.w-1011 {
  width: 1011%;
}

.w-1011rem {
  width: 1011rem;
}

.w-1011px {
  width: 1011px;
}

.w-1012 {
  width: 1012%;
}

.w-1012rem {
  width: 1012rem;
}

.w-1012px {
  width: 1012px;
}

.w-1013 {
  width: 1013%;
}

.w-1013rem {
  width: 1013rem;
}

.w-1013px {
  width: 1013px;
}

.w-1014 {
  width: 1014%;
}

.w-1014rem {
  width: 1014rem;
}

.w-1014px {
  width: 1014px;
}

.w-1015 {
  width: 1015%;
}

.w-1015rem {
  width: 1015rem;
}

.w-1015px {
  width: 1015px;
}

.w-1016 {
  width: 1016%;
}

.w-1016rem {
  width: 1016rem;
}

.w-1016px {
  width: 1016px;
}

.w-1017 {
  width: 1017%;
}

.w-1017rem {
  width: 1017rem;
}

.w-1017px {
  width: 1017px;
}

.w-1018 {
  width: 1018%;
}

.w-1018rem {
  width: 1018rem;
}

.w-1018px {
  width: 1018px;
}

.w-1019 {
  width: 1019%;
}

.w-1019rem {
  width: 1019rem;
}

.w-1019px {
  width: 1019px;
}

.w-1020 {
  width: 1020%;
}

.w-1020rem {
  width: 1020rem;
}

.w-1020px {
  width: 1020px;
}

.w-1021 {
  width: 1021%;
}

.w-1021rem {
  width: 1021rem;
}

.w-1021px {
  width: 1021px;
}

.w-1022 {
  width: 1022%;
}

.w-1022rem {
  width: 1022rem;
}

.w-1022px {
  width: 1022px;
}

.w-1023 {
  width: 1023%;
}

.w-1023rem {
  width: 1023rem;
}

.w-1023px {
  width: 1023px;
}

.w-1024 {
  width: 1024%;
}

.w-1024rem {
  width: 1024rem;
}

.w-1024px {
  width: 1024px;
}

.w-1025 {
  width: 1025%;
}

.w-1025rem {
  width: 1025rem;
}

.w-1025px {
  width: 1025px;
}

.w-1026 {
  width: 1026%;
}

.w-1026rem {
  width: 1026rem;
}

.w-1026px {
  width: 1026px;
}

.w-1027 {
  width: 1027%;
}

.w-1027rem {
  width: 1027rem;
}

.w-1027px {
  width: 1027px;
}

.w-1028 {
  width: 1028%;
}

.w-1028rem {
  width: 1028rem;
}

.w-1028px {
  width: 1028px;
}

.w-1029 {
  width: 1029%;
}

.w-1029rem {
  width: 1029rem;
}

.w-1029px {
  width: 1029px;
}

.w-1030 {
  width: 1030%;
}

.w-1030rem {
  width: 1030rem;
}

.w-1030px {
  width: 1030px;
}

.w-1031 {
  width: 1031%;
}

.w-1031rem {
  width: 1031rem;
}

.w-1031px {
  width: 1031px;
}

.w-1032 {
  width: 1032%;
}

.w-1032rem {
  width: 1032rem;
}

.w-1032px {
  width: 1032px;
}

.w-1033 {
  width: 1033%;
}

.w-1033rem {
  width: 1033rem;
}

.w-1033px {
  width: 1033px;
}

.w-1034 {
  width: 1034%;
}

.w-1034rem {
  width: 1034rem;
}

.w-1034px {
  width: 1034px;
}

.w-1035 {
  width: 1035%;
}

.w-1035rem {
  width: 1035rem;
}

.w-1035px {
  width: 1035px;
}

.w-1036 {
  width: 1036%;
}

.w-1036rem {
  width: 1036rem;
}

.w-1036px {
  width: 1036px;
}

.w-1037 {
  width: 1037%;
}

.w-1037rem {
  width: 1037rem;
}

.w-1037px {
  width: 1037px;
}

.w-1038 {
  width: 1038%;
}

.w-1038rem {
  width: 1038rem;
}

.w-1038px {
  width: 1038px;
}

.w-1039 {
  width: 1039%;
}

.w-1039rem {
  width: 1039rem;
}

.w-1039px {
  width: 1039px;
}

.w-1040 {
  width: 1040%;
}

.w-1040rem {
  width: 1040rem;
}

.w-1040px {
  width: 1040px;
}

.w-1041 {
  width: 1041%;
}

.w-1041rem {
  width: 1041rem;
}

.w-1041px {
  width: 1041px;
}

.w-1042 {
  width: 1042%;
}

.w-1042rem {
  width: 1042rem;
}

.w-1042px {
  width: 1042px;
}

.w-1043 {
  width: 1043%;
}

.w-1043rem {
  width: 1043rem;
}

.w-1043px {
  width: 1043px;
}

.w-1044 {
  width: 1044%;
}

.w-1044rem {
  width: 1044rem;
}

.w-1044px {
  width: 1044px;
}

.w-1045 {
  width: 1045%;
}

.w-1045rem {
  width: 1045rem;
}

.w-1045px {
  width: 1045px;
}

.w-1046 {
  width: 1046%;
}

.w-1046rem {
  width: 1046rem;
}

.w-1046px {
  width: 1046px;
}

.w-1047 {
  width: 1047%;
}

.w-1047rem {
  width: 1047rem;
}

.w-1047px {
  width: 1047px;
}

.w-1048 {
  width: 1048%;
}

.w-1048rem {
  width: 1048rem;
}

.w-1048px {
  width: 1048px;
}

.w-1049 {
  width: 1049%;
}

.w-1049rem {
  width: 1049rem;
}

.w-1049px {
  width: 1049px;
}

.w-1050 {
  width: 1050%;
}

.w-1050rem {
  width: 1050rem;
}

.w-1050px {
  width: 1050px;
}

.w-1051 {
  width: 1051%;
}

.w-1051rem {
  width: 1051rem;
}

.w-1051px {
  width: 1051px;
}

.w-1052 {
  width: 1052%;
}

.w-1052rem {
  width: 1052rem;
}

.w-1052px {
  width: 1052px;
}

.w-1053 {
  width: 1053%;
}

.w-1053rem {
  width: 1053rem;
}

.w-1053px {
  width: 1053px;
}

.w-1054 {
  width: 1054%;
}

.w-1054rem {
  width: 1054rem;
}

.w-1054px {
  width: 1054px;
}

.w-1055 {
  width: 1055%;
}

.w-1055rem {
  width: 1055rem;
}

.w-1055px {
  width: 1055px;
}

.w-1056 {
  width: 1056%;
}

.w-1056rem {
  width: 1056rem;
}

.w-1056px {
  width: 1056px;
}

.w-1057 {
  width: 1057%;
}

.w-1057rem {
  width: 1057rem;
}

.w-1057px {
  width: 1057px;
}

.w-1058 {
  width: 1058%;
}

.w-1058rem {
  width: 1058rem;
}

.w-1058px {
  width: 1058px;
}

.w-1059 {
  width: 1059%;
}

.w-1059rem {
  width: 1059rem;
}

.w-1059px {
  width: 1059px;
}

.w-1060 {
  width: 1060%;
}

.w-1060rem {
  width: 1060rem;
}

.w-1060px {
  width: 1060px;
}

.w-1061 {
  width: 1061%;
}

.w-1061rem {
  width: 1061rem;
}

.w-1061px {
  width: 1061px;
}

.w-1062 {
  width: 1062%;
}

.w-1062rem {
  width: 1062rem;
}

.w-1062px {
  width: 1062px;
}

.w-1063 {
  width: 1063%;
}

.w-1063rem {
  width: 1063rem;
}

.w-1063px {
  width: 1063px;
}

.w-1064 {
  width: 1064%;
}

.w-1064rem {
  width: 1064rem;
}

.w-1064px {
  width: 1064px;
}

.w-1065 {
  width: 1065%;
}

.w-1065rem {
  width: 1065rem;
}

.w-1065px {
  width: 1065px;
}

.w-1066 {
  width: 1066%;
}

.w-1066rem {
  width: 1066rem;
}

.w-1066px {
  width: 1066px;
}

.w-1067 {
  width: 1067%;
}

.w-1067rem {
  width: 1067rem;
}

.w-1067px {
  width: 1067px;
}

.w-1068 {
  width: 1068%;
}

.w-1068rem {
  width: 1068rem;
}

.w-1068px {
  width: 1068px;
}

.w-1069 {
  width: 1069%;
}

.w-1069rem {
  width: 1069rem;
}

.w-1069px {
  width: 1069px;
}

.w-1070 {
  width: 1070%;
}

.w-1070rem {
  width: 1070rem;
}

.w-1070px {
  width: 1070px;
}

.w-1071 {
  width: 1071%;
}

.w-1071rem {
  width: 1071rem;
}

.w-1071px {
  width: 1071px;
}

.w-1072 {
  width: 1072%;
}

.w-1072rem {
  width: 1072rem;
}

.w-1072px {
  width: 1072px;
}

.w-1073 {
  width: 1073%;
}

.w-1073rem {
  width: 1073rem;
}

.w-1073px {
  width: 1073px;
}

.w-1074 {
  width: 1074%;
}

.w-1074rem {
  width: 1074rem;
}

.w-1074px {
  width: 1074px;
}

.w-1075 {
  width: 1075%;
}

.w-1075rem {
  width: 1075rem;
}

.w-1075px {
  width: 1075px;
}

.w-1076 {
  width: 1076%;
}

.w-1076rem {
  width: 1076rem;
}

.w-1076px {
  width: 1076px;
}

.w-1077 {
  width: 1077%;
}

.w-1077rem {
  width: 1077rem;
}

.w-1077px {
  width: 1077px;
}

.w-1078 {
  width: 1078%;
}

.w-1078rem {
  width: 1078rem;
}

.w-1078px {
  width: 1078px;
}

.w-1079 {
  width: 1079%;
}

.w-1079rem {
  width: 1079rem;
}

.w-1079px {
  width: 1079px;
}

.w-1080 {
  width: 1080%;
}

.w-1080rem {
  width: 1080rem;
}

.w-1080px {
  width: 1080px;
}

.w-1081 {
  width: 1081%;
}

.w-1081rem {
  width: 1081rem;
}

.w-1081px {
  width: 1081px;
}

.w-1082 {
  width: 1082%;
}

.w-1082rem {
  width: 1082rem;
}

.w-1082px {
  width: 1082px;
}

.w-1083 {
  width: 1083%;
}

.w-1083rem {
  width: 1083rem;
}

.w-1083px {
  width: 1083px;
}

.w-1084 {
  width: 1084%;
}

.w-1084rem {
  width: 1084rem;
}

.w-1084px {
  width: 1084px;
}

.w-1085 {
  width: 1085%;
}

.w-1085rem {
  width: 1085rem;
}

.w-1085px {
  width: 1085px;
}

.w-1086 {
  width: 1086%;
}

.w-1086rem {
  width: 1086rem;
}

.w-1086px {
  width: 1086px;
}

.w-1087 {
  width: 1087%;
}

.w-1087rem {
  width: 1087rem;
}

.w-1087px {
  width: 1087px;
}

.w-1088 {
  width: 1088%;
}

.w-1088rem {
  width: 1088rem;
}

.w-1088px {
  width: 1088px;
}

.w-1089 {
  width: 1089%;
}

.w-1089rem {
  width: 1089rem;
}

.w-1089px {
  width: 1089px;
}

.w-1090 {
  width: 1090%;
}

.w-1090rem {
  width: 1090rem;
}

.w-1090px {
  width: 1090px;
}

.w-1091 {
  width: 1091%;
}

.w-1091rem {
  width: 1091rem;
}

.w-1091px {
  width: 1091px;
}

.w-1092 {
  width: 1092%;
}

.w-1092rem {
  width: 1092rem;
}

.w-1092px {
  width: 1092px;
}

.w-1093 {
  width: 1093%;
}

.w-1093rem {
  width: 1093rem;
}

.w-1093px {
  width: 1093px;
}

.w-1094 {
  width: 1094%;
}

.w-1094rem {
  width: 1094rem;
}

.w-1094px {
  width: 1094px;
}

.w-1095 {
  width: 1095%;
}

.w-1095rem {
  width: 1095rem;
}

.w-1095px {
  width: 1095px;
}

.w-1096 {
  width: 1096%;
}

.w-1096rem {
  width: 1096rem;
}

.w-1096px {
  width: 1096px;
}

.w-1097 {
  width: 1097%;
}

.w-1097rem {
  width: 1097rem;
}

.w-1097px {
  width: 1097px;
}

.w-1098 {
  width: 1098%;
}

.w-1098rem {
  width: 1098rem;
}

.w-1098px {
  width: 1098px;
}

.w-1099 {
  width: 1099%;
}

.w-1099rem {
  width: 1099rem;
}

.w-1099px {
  width: 1099px;
}

.w-1100 {
  width: 1100%;
}

.w-1100rem {
  width: 1100rem;
}

.w-1100px {
  width: 1100px;
}

.w-1101 {
  width: 1101%;
}

.w-1101rem {
  width: 1101rem;
}

.w-1101px {
  width: 1101px;
}

.w-1102 {
  width: 1102%;
}

.w-1102rem {
  width: 1102rem;
}

.w-1102px {
  width: 1102px;
}

.w-1103 {
  width: 1103%;
}

.w-1103rem {
  width: 1103rem;
}

.w-1103px {
  width: 1103px;
}

.w-1104 {
  width: 1104%;
}

.w-1104rem {
  width: 1104rem;
}

.w-1104px {
  width: 1104px;
}

.w-1105 {
  width: 1105%;
}

.w-1105rem {
  width: 1105rem;
}

.w-1105px {
  width: 1105px;
}

.w-1106 {
  width: 1106%;
}

.w-1106rem {
  width: 1106rem;
}

.w-1106px {
  width: 1106px;
}

.w-1107 {
  width: 1107%;
}

.w-1107rem {
  width: 1107rem;
}

.w-1107px {
  width: 1107px;
}

.w-1108 {
  width: 1108%;
}

.w-1108rem {
  width: 1108rem;
}

.w-1108px {
  width: 1108px;
}

.w-1109 {
  width: 1109%;
}

.w-1109rem {
  width: 1109rem;
}

.w-1109px {
  width: 1109px;
}

.w-1110 {
  width: 1110%;
}

.w-1110rem {
  width: 1110rem;
}

.w-1110px {
  width: 1110px;
}

.w-1111 {
  width: 1111%;
}

.w-1111rem {
  width: 1111rem;
}

.w-1111px {
  width: 1111px;
}

.w-1112 {
  width: 1112%;
}

.w-1112rem {
  width: 1112rem;
}

.w-1112px {
  width: 1112px;
}

.w-1113 {
  width: 1113%;
}

.w-1113rem {
  width: 1113rem;
}

.w-1113px {
  width: 1113px;
}

.w-1114 {
  width: 1114%;
}

.w-1114rem {
  width: 1114rem;
}

.w-1114px {
  width: 1114px;
}

.w-1115 {
  width: 1115%;
}

.w-1115rem {
  width: 1115rem;
}

.w-1115px {
  width: 1115px;
}

.w-1116 {
  width: 1116%;
}

.w-1116rem {
  width: 1116rem;
}

.w-1116px {
  width: 1116px;
}

.w-1117 {
  width: 1117%;
}

.w-1117rem {
  width: 1117rem;
}

.w-1117px {
  width: 1117px;
}

.w-1118 {
  width: 1118%;
}

.w-1118rem {
  width: 1118rem;
}

.w-1118px {
  width: 1118px;
}

.w-1119 {
  width: 1119%;
}

.w-1119rem {
  width: 1119rem;
}

.w-1119px {
  width: 1119px;
}

.w-1120 {
  width: 1120%;
}

.w-1120rem {
  width: 1120rem;
}

.w-1120px {
  width: 1120px;
}

.w-1121 {
  width: 1121%;
}

.w-1121rem {
  width: 1121rem;
}

.w-1121px {
  width: 1121px;
}

.w-1122 {
  width: 1122%;
}

.w-1122rem {
  width: 1122rem;
}

.w-1122px {
  width: 1122px;
}

.w-1123 {
  width: 1123%;
}

.w-1123rem {
  width: 1123rem;
}

.w-1123px {
  width: 1123px;
}

.w-1124 {
  width: 1124%;
}

.w-1124rem {
  width: 1124rem;
}

.w-1124px {
  width: 1124px;
}

.w-1125 {
  width: 1125%;
}

.w-1125rem {
  width: 1125rem;
}

.w-1125px {
  width: 1125px;
}

.w-1126 {
  width: 1126%;
}

.w-1126rem {
  width: 1126rem;
}

.w-1126px {
  width: 1126px;
}

.w-1127 {
  width: 1127%;
}

.w-1127rem {
  width: 1127rem;
}

.w-1127px {
  width: 1127px;
}

.w-1128 {
  width: 1128%;
}

.w-1128rem {
  width: 1128rem;
}

.w-1128px {
  width: 1128px;
}

.w-1129 {
  width: 1129%;
}

.w-1129rem {
  width: 1129rem;
}

.w-1129px {
  width: 1129px;
}

.w-1130 {
  width: 1130%;
}

.w-1130rem {
  width: 1130rem;
}

.w-1130px {
  width: 1130px;
}

.w-1131 {
  width: 1131%;
}

.w-1131rem {
  width: 1131rem;
}

.w-1131px {
  width: 1131px;
}

.w-1132 {
  width: 1132%;
}

.w-1132rem {
  width: 1132rem;
}

.w-1132px {
  width: 1132px;
}

.w-1133 {
  width: 1133%;
}

.w-1133rem {
  width: 1133rem;
}

.w-1133px {
  width: 1133px;
}

.w-1134 {
  width: 1134%;
}

.w-1134rem {
  width: 1134rem;
}

.w-1134px {
  width: 1134px;
}

.w-1135 {
  width: 1135%;
}

.w-1135rem {
  width: 1135rem;
}

.w-1135px {
  width: 1135px;
}

.w-1136 {
  width: 1136%;
}

.w-1136rem {
  width: 1136rem;
}

.w-1136px {
  width: 1136px;
}

.w-1137 {
  width: 1137%;
}

.w-1137rem {
  width: 1137rem;
}

.w-1137px {
  width: 1137px;
}

.w-1138 {
  width: 1138%;
}

.w-1138rem {
  width: 1138rem;
}

.w-1138px {
  width: 1138px;
}

.w-1139 {
  width: 1139%;
}

.w-1139rem {
  width: 1139rem;
}

.w-1139px {
  width: 1139px;
}

.w-1140 {
  width: 1140%;
}

.w-1140rem {
  width: 1140rem;
}

.w-1140px {
  width: 1140px;
}

.w-1141 {
  width: 1141%;
}

.w-1141rem {
  width: 1141rem;
}

.w-1141px {
  width: 1141px;
}

.w-1142 {
  width: 1142%;
}

.w-1142rem {
  width: 1142rem;
}

.w-1142px {
  width: 1142px;
}

.w-1143 {
  width: 1143%;
}

.w-1143rem {
  width: 1143rem;
}

.w-1143px {
  width: 1143px;
}

.w-1144 {
  width: 1144%;
}

.w-1144rem {
  width: 1144rem;
}

.w-1144px {
  width: 1144px;
}

.w-1145 {
  width: 1145%;
}

.w-1145rem {
  width: 1145rem;
}

.w-1145px {
  width: 1145px;
}

.w-1146 {
  width: 1146%;
}

.w-1146rem {
  width: 1146rem;
}

.w-1146px {
  width: 1146px;
}

.w-1147 {
  width: 1147%;
}

.w-1147rem {
  width: 1147rem;
}

.w-1147px {
  width: 1147px;
}

.w-1148 {
  width: 1148%;
}

.w-1148rem {
  width: 1148rem;
}

.w-1148px {
  width: 1148px;
}

.w-1149 {
  width: 1149%;
}

.w-1149rem {
  width: 1149rem;
}

.w-1149px {
  width: 1149px;
}

.w-1150 {
  width: 1150%;
}

.w-1150rem {
  width: 1150rem;
}

.w-1150px {
  width: 1150px;
}

.w-1151 {
  width: 1151%;
}

.w-1151rem {
  width: 1151rem;
}

.w-1151px {
  width: 1151px;
}

.w-1152 {
  width: 1152%;
}

.w-1152rem {
  width: 1152rem;
}

.w-1152px {
  width: 1152px;
}

.w-1153 {
  width: 1153%;
}

.w-1153rem {
  width: 1153rem;
}

.w-1153px {
  width: 1153px;
}

.w-1154 {
  width: 1154%;
}

.w-1154rem {
  width: 1154rem;
}

.w-1154px {
  width: 1154px;
}

.w-1155 {
  width: 1155%;
}

.w-1155rem {
  width: 1155rem;
}

.w-1155px {
  width: 1155px;
}

.w-1156 {
  width: 1156%;
}

.w-1156rem {
  width: 1156rem;
}

.w-1156px {
  width: 1156px;
}

.w-1157 {
  width: 1157%;
}

.w-1157rem {
  width: 1157rem;
}

.w-1157px {
  width: 1157px;
}

.w-1158 {
  width: 1158%;
}

.w-1158rem {
  width: 1158rem;
}

.w-1158px {
  width: 1158px;
}

.w-1159 {
  width: 1159%;
}

.w-1159rem {
  width: 1159rem;
}

.w-1159px {
  width: 1159px;
}

.w-1160 {
  width: 1160%;
}

.w-1160rem {
  width: 1160rem;
}

.w-1160px {
  width: 1160px;
}

.w-1161 {
  width: 1161%;
}

.w-1161rem {
  width: 1161rem;
}

.w-1161px {
  width: 1161px;
}

.w-1162 {
  width: 1162%;
}

.w-1162rem {
  width: 1162rem;
}

.w-1162px {
  width: 1162px;
}

.w-1163 {
  width: 1163%;
}

.w-1163rem {
  width: 1163rem;
}

.w-1163px {
  width: 1163px;
}

.w-1164 {
  width: 1164%;
}

.w-1164rem {
  width: 1164rem;
}

.w-1164px {
  width: 1164px;
}

.w-1165 {
  width: 1165%;
}

.w-1165rem {
  width: 1165rem;
}

.w-1165px {
  width: 1165px;
}

.w-1166 {
  width: 1166%;
}

.w-1166rem {
  width: 1166rem;
}

.w-1166px {
  width: 1166px;
}

.w-1167 {
  width: 1167%;
}

.w-1167rem {
  width: 1167rem;
}

.w-1167px {
  width: 1167px;
}

.w-1168 {
  width: 1168%;
}

.w-1168rem {
  width: 1168rem;
}

.w-1168px {
  width: 1168px;
}

.w-1169 {
  width: 1169%;
}

.w-1169rem {
  width: 1169rem;
}

.w-1169px {
  width: 1169px;
}

.w-1170 {
  width: 1170%;
}

.w-1170rem {
  width: 1170rem;
}

.w-1170px {
  width: 1170px;
}

.w-1171 {
  width: 1171%;
}

.w-1171rem {
  width: 1171rem;
}

.w-1171px {
  width: 1171px;
}

.w-1172 {
  width: 1172%;
}

.w-1172rem {
  width: 1172rem;
}

.w-1172px {
  width: 1172px;
}

.w-1173 {
  width: 1173%;
}

.w-1173rem {
  width: 1173rem;
}

.w-1173px {
  width: 1173px;
}

.w-1174 {
  width: 1174%;
}

.w-1174rem {
  width: 1174rem;
}

.w-1174px {
  width: 1174px;
}

.w-1175 {
  width: 1175%;
}

.w-1175rem {
  width: 1175rem;
}

.w-1175px {
  width: 1175px;
}

.w-1176 {
  width: 1176%;
}

.w-1176rem {
  width: 1176rem;
}

.w-1176px {
  width: 1176px;
}

.w-1177 {
  width: 1177%;
}

.w-1177rem {
  width: 1177rem;
}

.w-1177px {
  width: 1177px;
}

.w-1178 {
  width: 1178%;
}

.w-1178rem {
  width: 1178rem;
}

.w-1178px {
  width: 1178px;
}

.w-1179 {
  width: 1179%;
}

.w-1179rem {
  width: 1179rem;
}

.w-1179px {
  width: 1179px;
}

.w-1180 {
  width: 1180%;
}

.w-1180rem {
  width: 1180rem;
}

.w-1180px {
  width: 1180px;
}

.w-1181 {
  width: 1181%;
}

.w-1181rem {
  width: 1181rem;
}

.w-1181px {
  width: 1181px;
}

.w-1182 {
  width: 1182%;
}

.w-1182rem {
  width: 1182rem;
}

.w-1182px {
  width: 1182px;
}

.w-1183 {
  width: 1183%;
}

.w-1183rem {
  width: 1183rem;
}

.w-1183px {
  width: 1183px;
}

.w-1184 {
  width: 1184%;
}

.w-1184rem {
  width: 1184rem;
}

.w-1184px {
  width: 1184px;
}

.w-1185 {
  width: 1185%;
}

.w-1185rem {
  width: 1185rem;
}

.w-1185px {
  width: 1185px;
}

.w-1186 {
  width: 1186%;
}

.w-1186rem {
  width: 1186rem;
}

.w-1186px {
  width: 1186px;
}

.w-1187 {
  width: 1187%;
}

.w-1187rem {
  width: 1187rem;
}

.w-1187px {
  width: 1187px;
}

.w-1188 {
  width: 1188%;
}

.w-1188rem {
  width: 1188rem;
}

.w-1188px {
  width: 1188px;
}

.w-1189 {
  width: 1189%;
}

.w-1189rem {
  width: 1189rem;
}

.w-1189px {
  width: 1189px;
}

.w-1190 {
  width: 1190%;
}

.w-1190rem {
  width: 1190rem;
}

.w-1190px {
  width: 1190px;
}

.w-1191 {
  width: 1191%;
}

.w-1191rem {
  width: 1191rem;
}

.w-1191px {
  width: 1191px;
}

.w-1192 {
  width: 1192%;
}

.w-1192rem {
  width: 1192rem;
}

.w-1192px {
  width: 1192px;
}

.w-1193 {
  width: 1193%;
}

.w-1193rem {
  width: 1193rem;
}

.w-1193px {
  width: 1193px;
}

.w-1194 {
  width: 1194%;
}

.w-1194rem {
  width: 1194rem;
}

.w-1194px {
  width: 1194px;
}

.w-1195 {
  width: 1195%;
}

.w-1195rem {
  width: 1195rem;
}

.w-1195px {
  width: 1195px;
}

.w-1196 {
  width: 1196%;
}

.w-1196rem {
  width: 1196rem;
}

.w-1196px {
  width: 1196px;
}

.w-1197 {
  width: 1197%;
}

.w-1197rem {
  width: 1197rem;
}

.w-1197px {
  width: 1197px;
}

.w-1198 {
  width: 1198%;
}

.w-1198rem {
  width: 1198rem;
}

.w-1198px {
  width: 1198px;
}

.w-1199 {
  width: 1199%;
}

.w-1199rem {
  width: 1199rem;
}

.w-1199px {
  width: 1199px;
}

.w-1200 {
  width: 1200%;
}

.w-1200rem {
  width: 1200rem;
}

.w-1200px {
  width: 1200px;
}

.w-fit-content {
  width: fit-content;
}

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }
}
.h-0 {
  height: 0%;
}

.lh-0 {
  line-height: 0%;
}

.h-0px {
  height: 0px;
}

.lh-0px {
  line-height: 0px;
}

.h-1 {
  height: 1%;
}

.lh-1 {
  line-height: 1%;
}

.h-1px {
  height: 1px;
}

.lh-1px {
  line-height: 1px;
}

.h-2 {
  height: 2%;
}

.lh-2 {
  line-height: 2%;
}

.h-2px {
  height: 2px;
}

.lh-2px {
  line-height: 2px;
}

.h-3 {
  height: 3%;
}

.lh-3 {
  line-height: 3%;
}

.h-3px {
  height: 3px;
}

.lh-3px {
  line-height: 3px;
}

.h-4 {
  height: 4%;
}

.lh-4 {
  line-height: 4%;
}

.h-4px {
  height: 4px;
}

.lh-4px {
  line-height: 4px;
}

.h-5 {
  height: 5%;
}

.lh-5 {
  line-height: 5%;
}

.h-5px {
  height: 5px;
}

.lh-5px {
  line-height: 5px;
}

.h-6 {
  height: 6%;
}

.lh-6 {
  line-height: 6%;
}

.h-6px {
  height: 6px;
}

.lh-6px {
  line-height: 6px;
}

.h-7 {
  height: 7%;
}

.lh-7 {
  line-height: 7%;
}

.h-7px {
  height: 7px;
}

.lh-7px {
  line-height: 7px;
}

.h-8 {
  height: 8%;
}

.lh-8 {
  line-height: 8%;
}

.h-8px {
  height: 8px;
}

.lh-8px {
  line-height: 8px;
}

.h-9 {
  height: 9%;
}

.lh-9 {
  line-height: 9%;
}

.h-9px {
  height: 9px;
}

.lh-9px {
  line-height: 9px;
}

.h-10 {
  height: 10%;
}

.lh-10 {
  line-height: 10%;
}

.h-10px {
  height: 10px;
}

.lh-10px {
  line-height: 10px;
}

.h-11 {
  height: 11%;
}

.lh-11 {
  line-height: 11%;
}

.h-11px {
  height: 11px;
}

.lh-11px {
  line-height: 11px;
}

.h-12 {
  height: 12%;
}

.lh-12 {
  line-height: 12%;
}

.h-12px {
  height: 12px;
}

.lh-12px {
  line-height: 12px;
}

.h-13 {
  height: 13%;
}

.lh-13 {
  line-height: 13%;
}

.h-13px {
  height: 13px;
}

.lh-13px {
  line-height: 13px;
}

.h-14 {
  height: 14%;
}

.lh-14 {
  line-height: 14%;
}

.h-14px {
  height: 14px;
}

.lh-14px {
  line-height: 14px;
}

.h-15 {
  height: 15%;
}

.lh-15 {
  line-height: 15%;
}

.h-15px {
  height: 15px;
}

.lh-15px {
  line-height: 15px;
}

.h-16 {
  height: 16%;
}

.lh-16 {
  line-height: 16%;
}

.h-16px {
  height: 16px;
}

.lh-16px {
  line-height: 16px;
}

.h-17 {
  height: 17%;
}

.lh-17 {
  line-height: 17%;
}

.h-17px {
  height: 17px;
}

.lh-17px {
  line-height: 17px;
}

.h-18 {
  height: 18%;
}

.lh-18 {
  line-height: 18%;
}

.h-18px {
  height: 18px;
}

.lh-18px {
  line-height: 18px;
}

.h-19 {
  height: 19%;
}

.lh-19 {
  line-height: 19%;
}

.h-19px {
  height: 19px;
}

.lh-19px {
  line-height: 19px;
}

.h-20 {
  height: 20%;
}

.lh-20 {
  line-height: 20%;
}

.h-20px {
  height: 20px;
}

.lh-20px {
  line-height: 20px;
}

.h-21 {
  height: 21%;
}

.lh-21 {
  line-height: 21%;
}

.h-21px {
  height: 21px;
}

.lh-21px {
  line-height: 21px;
}

.h-22 {
  height: 22%;
}

.lh-22 {
  line-height: 22%;
}

.h-22px {
  height: 22px;
}

.lh-22px {
  line-height: 22px;
}

.h-23 {
  height: 23%;
}

.lh-23 {
  line-height: 23%;
}

.h-23px {
  height: 23px;
}

.lh-23px {
  line-height: 23px;
}

.h-24 {
  height: 24%;
}

.lh-24 {
  line-height: 24%;
}

.h-24px {
  height: 24px;
}

.lh-24px {
  line-height: 24px;
}

.h-25 {
  height: 25%;
}

.lh-25 {
  line-height: 25%;
}

.h-25px {
  height: 25px;
}

.lh-25px {
  line-height: 25px;
}

.h-26 {
  height: 26%;
}

.lh-26 {
  line-height: 26%;
}

.h-26px {
  height: 26px;
}

.lh-26px {
  line-height: 26px;
}

.h-27 {
  height: 27%;
}

.lh-27 {
  line-height: 27%;
}

.h-27px {
  height: 27px;
}

.lh-27px {
  line-height: 27px;
}

.h-28 {
  height: 28%;
}

.lh-28 {
  line-height: 28%;
}

.h-28px {
  height: 28px;
}

.lh-28px {
  line-height: 28px;
}

.h-29 {
  height: 29%;
}

.lh-29 {
  line-height: 29%;
}

.h-29px {
  height: 29px;
}

.lh-29px {
  line-height: 29px;
}

.h-30 {
  height: 30%;
}

.lh-30 {
  line-height: 30%;
}

.h-30px {
  height: 30px;
}

.lh-30px {
  line-height: 30px;
}

.h-31 {
  height: 31%;
}

.lh-31 {
  line-height: 31%;
}

.h-31px {
  height: 31px;
}

.lh-31px {
  line-height: 31px;
}

.h-32 {
  height: 32%;
}

.lh-32 {
  line-height: 32%;
}

.h-32px {
  height: 32px;
}

.lh-32px {
  line-height: 32px;
}

.h-33 {
  height: 33%;
}

.lh-33 {
  line-height: 33%;
}

.h-33px {
  height: 33px;
}

.lh-33px {
  line-height: 33px;
}

.h-34 {
  height: 34%;
}

.lh-34 {
  line-height: 34%;
}

.h-34px {
  height: 34px;
}

.lh-34px {
  line-height: 34px;
}

.h-35 {
  height: 35%;
}

.lh-35 {
  line-height: 35%;
}

.h-35px {
  height: 35px;
}

.lh-35px {
  line-height: 35px;
}

.h-36 {
  height: 36%;
}

.lh-36 {
  line-height: 36%;
}

.h-36px {
  height: 36px;
}

.lh-36px {
  line-height: 36px;
}

.h-37 {
  height: 37%;
}

.lh-37 {
  line-height: 37%;
}

.h-37px {
  height: 37px;
}

.lh-37px {
  line-height: 37px;
}

.h-38 {
  height: 38%;
}

.lh-38 {
  line-height: 38%;
}

.h-38px {
  height: 38px;
}

.lh-38px {
  line-height: 38px;
}

.h-39 {
  height: 39%;
}

.lh-39 {
  line-height: 39%;
}

.h-39px {
  height: 39px;
}

.lh-39px {
  line-height: 39px;
}

.h-40 {
  height: 40%;
}

.lh-40 {
  line-height: 40%;
}

.h-40px {
  height: 40px;
}

.lh-40px {
  line-height: 40px;
}

.h-41 {
  height: 41%;
}

.lh-41 {
  line-height: 41%;
}

.h-41px {
  height: 41px;
}

.lh-41px {
  line-height: 41px;
}

.h-42 {
  height: 42%;
}

.lh-42 {
  line-height: 42%;
}

.h-42px {
  height: 42px;
}

.lh-42px {
  line-height: 42px;
}

.h-43 {
  height: 43%;
}

.lh-43 {
  line-height: 43%;
}

.h-43px {
  height: 43px;
}

.lh-43px {
  line-height: 43px;
}

.h-44 {
  height: 44%;
}

.lh-44 {
  line-height: 44%;
}

.h-44px {
  height: 44px;
}

.lh-44px {
  line-height: 44px;
}

.h-45 {
  height: 45%;
}

.lh-45 {
  line-height: 45%;
}

.h-45px {
  height: 45px;
}

.lh-45px {
  line-height: 45px;
}

.h-46 {
  height: 46%;
}

.lh-46 {
  line-height: 46%;
}

.h-46px {
  height: 46px;
}

.lh-46px {
  line-height: 46px;
}

.h-47 {
  height: 47%;
}

.lh-47 {
  line-height: 47%;
}

.h-47px {
  height: 47px;
}

.lh-47px {
  line-height: 47px;
}

.h-48 {
  height: 48%;
}

.lh-48 {
  line-height: 48%;
}

.h-48px {
  height: 48px;
}

.lh-48px {
  line-height: 48px;
}

.h-49 {
  height: 49%;
}

.lh-49 {
  line-height: 49%;
}

.h-49px {
  height: 49px;
}

.lh-49px {
  line-height: 49px;
}

.h-50 {
  height: 50%;
}

.lh-50 {
  line-height: 50%;
}

.h-50px {
  height: 50px;
}

.lh-50px {
  line-height: 50px;
}

.h-51 {
  height: 51%;
}

.lh-51 {
  line-height: 51%;
}

.h-51px {
  height: 51px;
}

.lh-51px {
  line-height: 51px;
}

.h-52 {
  height: 52%;
}

.lh-52 {
  line-height: 52%;
}

.h-52px {
  height: 52px;
}

.lh-52px {
  line-height: 52px;
}

.h-53 {
  height: 53%;
}

.lh-53 {
  line-height: 53%;
}

.h-53px {
  height: 53px;
}

.lh-53px {
  line-height: 53px;
}

.h-54 {
  height: 54%;
}

.lh-54 {
  line-height: 54%;
}

.h-54px {
  height: 54px;
}

.lh-54px {
  line-height: 54px;
}

.h-55 {
  height: 55%;
}

.lh-55 {
  line-height: 55%;
}

.h-55px {
  height: 55px;
}

.lh-55px {
  line-height: 55px;
}

.h-56 {
  height: 56%;
}

.lh-56 {
  line-height: 56%;
}

.h-56px {
  height: 56px;
}

.lh-56px {
  line-height: 56px;
}

.h-57 {
  height: 57%;
}

.lh-57 {
  line-height: 57%;
}

.h-57px {
  height: 57px;
}

.lh-57px {
  line-height: 57px;
}

.h-58 {
  height: 58%;
}

.lh-58 {
  line-height: 58%;
}

.h-58px {
  height: 58px;
}

.lh-58px {
  line-height: 58px;
}

.h-59 {
  height: 59%;
}

.lh-59 {
  line-height: 59%;
}

.h-59px {
  height: 59px;
}

.lh-59px {
  line-height: 59px;
}

.h-60 {
  height: 60%;
}

.lh-60 {
  line-height: 60%;
}

.h-60px {
  height: 60px;
}

.lh-60px {
  line-height: 60px;
}

.h-61 {
  height: 61%;
}

.lh-61 {
  line-height: 61%;
}

.h-61px {
  height: 61px;
}

.lh-61px {
  line-height: 61px;
}

.h-62 {
  height: 62%;
}

.lh-62 {
  line-height: 62%;
}

.h-62px {
  height: 62px;
}

.lh-62px {
  line-height: 62px;
}

.h-63 {
  height: 63%;
}

.lh-63 {
  line-height: 63%;
}

.h-63px {
  height: 63px;
}

.lh-63px {
  line-height: 63px;
}

.h-64 {
  height: 64%;
}

.lh-64 {
  line-height: 64%;
}

.h-64px {
  height: 64px;
}

.lh-64px {
  line-height: 64px;
}

.h-65 {
  height: 65%;
}

.lh-65 {
  line-height: 65%;
}

.h-65px {
  height: 65px;
}

.lh-65px {
  line-height: 65px;
}

.h-66 {
  height: 66%;
}

.lh-66 {
  line-height: 66%;
}

.h-66px {
  height: 66px;
}

.lh-66px {
  line-height: 66px;
}

.h-67 {
  height: 67%;
}

.lh-67 {
  line-height: 67%;
}

.h-67px {
  height: 67px;
}

.lh-67px {
  line-height: 67px;
}

.h-68 {
  height: 68%;
}

.lh-68 {
  line-height: 68%;
}

.h-68px {
  height: 68px;
}

.lh-68px {
  line-height: 68px;
}

.h-69 {
  height: 69%;
}

.lh-69 {
  line-height: 69%;
}

.h-69px {
  height: 69px;
}

.lh-69px {
  line-height: 69px;
}

.h-70 {
  height: 70%;
}

.lh-70 {
  line-height: 70%;
}

.h-70px {
  height: 70px;
}

.lh-70px {
  line-height: 70px;
}

.h-71 {
  height: 71%;
}

.lh-71 {
  line-height: 71%;
}

.h-71px {
  height: 71px;
}

.lh-71px {
  line-height: 71px;
}

.h-72 {
  height: 72%;
}

.lh-72 {
  line-height: 72%;
}

.h-72px {
  height: 72px;
}

.lh-72px {
  line-height: 72px;
}

.h-73 {
  height: 73%;
}

.lh-73 {
  line-height: 73%;
}

.h-73px {
  height: 73px;
}

.lh-73px {
  line-height: 73px;
}

.h-74 {
  height: 74%;
}

.lh-74 {
  line-height: 74%;
}

.h-74px {
  height: 74px;
}

.lh-74px {
  line-height: 74px;
}

.h-75 {
  height: 75%;
}

.lh-75 {
  line-height: 75%;
}

.h-75px {
  height: 75px;
}

.lh-75px {
  line-height: 75px;
}

.h-76 {
  height: 76%;
}

.lh-76 {
  line-height: 76%;
}

.h-76px {
  height: 76px;
}

.lh-76px {
  line-height: 76px;
}

.h-77 {
  height: 77%;
}

.lh-77 {
  line-height: 77%;
}

.h-77px {
  height: 77px;
}

.lh-77px {
  line-height: 77px;
}

.h-78 {
  height: 78%;
}

.lh-78 {
  line-height: 78%;
}

.h-78px {
  height: 78px;
}

.lh-78px {
  line-height: 78px;
}

.h-79 {
  height: 79%;
}

.lh-79 {
  line-height: 79%;
}

.h-79px {
  height: 79px;
}

.lh-79px {
  line-height: 79px;
}

.h-80 {
  height: 80%;
}

.lh-80 {
  line-height: 80%;
}

.h-80px {
  height: 80px;
}

.lh-80px {
  line-height: 80px;
}

.h-81 {
  height: 81%;
}

.lh-81 {
  line-height: 81%;
}

.h-81px {
  height: 81px;
}

.lh-81px {
  line-height: 81px;
}

.h-82 {
  height: 82%;
}

.lh-82 {
  line-height: 82%;
}

.h-82px {
  height: 82px;
}

.lh-82px {
  line-height: 82px;
}

.h-83 {
  height: 83%;
}

.lh-83 {
  line-height: 83%;
}

.h-83px {
  height: 83px;
}

.lh-83px {
  line-height: 83px;
}

.h-84 {
  height: 84%;
}

.lh-84 {
  line-height: 84%;
}

.h-84px {
  height: 84px;
}

.lh-84px {
  line-height: 84px;
}

.h-85 {
  height: 85%;
}

.lh-85 {
  line-height: 85%;
}

.h-85px {
  height: 85px;
}

.lh-85px {
  line-height: 85px;
}

.h-86 {
  height: 86%;
}

.lh-86 {
  line-height: 86%;
}

.h-86px {
  height: 86px;
}

.lh-86px {
  line-height: 86px;
}

.h-87 {
  height: 87%;
}

.lh-87 {
  line-height: 87%;
}

.h-87px {
  height: 87px;
}

.lh-87px {
  line-height: 87px;
}

.h-88 {
  height: 88%;
}

.lh-88 {
  line-height: 88%;
}

.h-88px {
  height: 88px;
}

.lh-88px {
  line-height: 88px;
}

.h-89 {
  height: 89%;
}

.lh-89 {
  line-height: 89%;
}

.h-89px {
  height: 89px;
}

.lh-89px {
  line-height: 89px;
}

.h-90 {
  height: 90%;
}

.lh-90 {
  line-height: 90%;
}

.h-90px {
  height: 90px;
}

.lh-90px {
  line-height: 90px;
}

.h-91 {
  height: 91%;
}

.lh-91 {
  line-height: 91%;
}

.h-91px {
  height: 91px;
}

.lh-91px {
  line-height: 91px;
}

.h-92 {
  height: 92%;
}

.lh-92 {
  line-height: 92%;
}

.h-92px {
  height: 92px;
}

.lh-92px {
  line-height: 92px;
}

.h-93 {
  height: 93%;
}

.lh-93 {
  line-height: 93%;
}

.h-93px {
  height: 93px;
}

.lh-93px {
  line-height: 93px;
}

.h-94 {
  height: 94%;
}

.lh-94 {
  line-height: 94%;
}

.h-94px {
  height: 94px;
}

.lh-94px {
  line-height: 94px;
}

.h-95 {
  height: 95%;
}

.lh-95 {
  line-height: 95%;
}

.h-95px {
  height: 95px;
}

.lh-95px {
  line-height: 95px;
}

.h-96 {
  height: 96%;
}

.lh-96 {
  line-height: 96%;
}

.h-96px {
  height: 96px;
}

.lh-96px {
  line-height: 96px;
}

.h-97 {
  height: 97%;
}

.lh-97 {
  line-height: 97%;
}

.h-97px {
  height: 97px;
}

.lh-97px {
  line-height: 97px;
}

.h-98 {
  height: 98%;
}

.lh-98 {
  line-height: 98%;
}

.h-98px {
  height: 98px;
}

.lh-98px {
  line-height: 98px;
}

.h-99 {
  height: 99%;
}

.lh-99 {
  line-height: 99%;
}

.h-99px {
  height: 99px;
}

.lh-99px {
  line-height: 99px;
}

.h-100 {
  height: 100%;
}

.lh-100 {
  line-height: 100%;
}

.h-100px {
  height: 100px;
}

.lh-100px {
  line-height: 100px;
}

.h-101 {
  height: 101%;
}

.lh-101 {
  line-height: 101%;
}

.h-101px {
  height: 101px;
}

.lh-101px {
  line-height: 101px;
}

.h-102 {
  height: 102%;
}

.lh-102 {
  line-height: 102%;
}

.h-102px {
  height: 102px;
}

.lh-102px {
  line-height: 102px;
}

.h-103 {
  height: 103%;
}

.lh-103 {
  line-height: 103%;
}

.h-103px {
  height: 103px;
}

.lh-103px {
  line-height: 103px;
}

.h-104 {
  height: 104%;
}

.lh-104 {
  line-height: 104%;
}

.h-104px {
  height: 104px;
}

.lh-104px {
  line-height: 104px;
}

.h-105 {
  height: 105%;
}

.lh-105 {
  line-height: 105%;
}

.h-105px {
  height: 105px;
}

.lh-105px {
  line-height: 105px;
}

.h-106 {
  height: 106%;
}

.lh-106 {
  line-height: 106%;
}

.h-106px {
  height: 106px;
}

.lh-106px {
  line-height: 106px;
}

.h-107 {
  height: 107%;
}

.lh-107 {
  line-height: 107%;
}

.h-107px {
  height: 107px;
}

.lh-107px {
  line-height: 107px;
}

.h-108 {
  height: 108%;
}

.lh-108 {
  line-height: 108%;
}

.h-108px {
  height: 108px;
}

.lh-108px {
  line-height: 108px;
}

.h-109 {
  height: 109%;
}

.lh-109 {
  line-height: 109%;
}

.h-109px {
  height: 109px;
}

.lh-109px {
  line-height: 109px;
}

.h-110 {
  height: 110%;
}

.lh-110 {
  line-height: 110%;
}

.h-110px {
  height: 110px;
}

.lh-110px {
  line-height: 110px;
}

.h-111 {
  height: 111%;
}

.lh-111 {
  line-height: 111%;
}

.h-111px {
  height: 111px;
}

.lh-111px {
  line-height: 111px;
}

.h-112 {
  height: 112%;
}

.lh-112 {
  line-height: 112%;
}

.h-112px {
  height: 112px;
}

.lh-112px {
  line-height: 112px;
}

.h-113 {
  height: 113%;
}

.lh-113 {
  line-height: 113%;
}

.h-113px {
  height: 113px;
}

.lh-113px {
  line-height: 113px;
}

.h-114 {
  height: 114%;
}

.lh-114 {
  line-height: 114%;
}

.h-114px {
  height: 114px;
}

.lh-114px {
  line-height: 114px;
}

.h-115 {
  height: 115%;
}

.lh-115 {
  line-height: 115%;
}

.h-115px {
  height: 115px;
}

.lh-115px {
  line-height: 115px;
}

.h-116 {
  height: 116%;
}

.lh-116 {
  line-height: 116%;
}

.h-116px {
  height: 116px;
}

.lh-116px {
  line-height: 116px;
}

.h-117 {
  height: 117%;
}

.lh-117 {
  line-height: 117%;
}

.h-117px {
  height: 117px;
}

.lh-117px {
  line-height: 117px;
}

.h-118 {
  height: 118%;
}

.lh-118 {
  line-height: 118%;
}

.h-118px {
  height: 118px;
}

.lh-118px {
  line-height: 118px;
}

.h-119 {
  height: 119%;
}

.lh-119 {
  line-height: 119%;
}

.h-119px {
  height: 119px;
}

.lh-119px {
  line-height: 119px;
}

.h-120 {
  height: 120%;
}

.lh-120 {
  line-height: 120%;
}

.h-120px {
  height: 120px;
}

.lh-120px {
  line-height: 120px;
}

.h-121 {
  height: 121%;
}

.lh-121 {
  line-height: 121%;
}

.h-121px {
  height: 121px;
}

.lh-121px {
  line-height: 121px;
}

.h-122 {
  height: 122%;
}

.lh-122 {
  line-height: 122%;
}

.h-122px {
  height: 122px;
}

.lh-122px {
  line-height: 122px;
}

.h-123 {
  height: 123%;
}

.lh-123 {
  line-height: 123%;
}

.h-123px {
  height: 123px;
}

.lh-123px {
  line-height: 123px;
}

.h-124 {
  height: 124%;
}

.lh-124 {
  line-height: 124%;
}

.h-124px {
  height: 124px;
}

.lh-124px {
  line-height: 124px;
}

.h-125 {
  height: 125%;
}

.lh-125 {
  line-height: 125%;
}

.h-125px {
  height: 125px;
}

.lh-125px {
  line-height: 125px;
}

.h-126 {
  height: 126%;
}

.lh-126 {
  line-height: 126%;
}

.h-126px {
  height: 126px;
}

.lh-126px {
  line-height: 126px;
}

.h-127 {
  height: 127%;
}

.lh-127 {
  line-height: 127%;
}

.h-127px {
  height: 127px;
}

.lh-127px {
  line-height: 127px;
}

.h-128 {
  height: 128%;
}

.lh-128 {
  line-height: 128%;
}

.h-128px {
  height: 128px;
}

.lh-128px {
  line-height: 128px;
}

.h-129 {
  height: 129%;
}

.lh-129 {
  line-height: 129%;
}

.h-129px {
  height: 129px;
}

.lh-129px {
  line-height: 129px;
}

.h-130 {
  height: 130%;
}

.lh-130 {
  line-height: 130%;
}

.h-130px {
  height: 130px;
}

.lh-130px {
  line-height: 130px;
}

.h-131 {
  height: 131%;
}

.lh-131 {
  line-height: 131%;
}

.h-131px {
  height: 131px;
}

.lh-131px {
  line-height: 131px;
}

.h-132 {
  height: 132%;
}

.lh-132 {
  line-height: 132%;
}

.h-132px {
  height: 132px;
}

.lh-132px {
  line-height: 132px;
}

.h-133 {
  height: 133%;
}

.lh-133 {
  line-height: 133%;
}

.h-133px {
  height: 133px;
}

.lh-133px {
  line-height: 133px;
}

.h-134 {
  height: 134%;
}

.lh-134 {
  line-height: 134%;
}

.h-134px {
  height: 134px;
}

.lh-134px {
  line-height: 134px;
}

.h-135 {
  height: 135%;
}

.lh-135 {
  line-height: 135%;
}

.h-135px {
  height: 135px;
}

.lh-135px {
  line-height: 135px;
}

.h-136 {
  height: 136%;
}

.lh-136 {
  line-height: 136%;
}

.h-136px {
  height: 136px;
}

.lh-136px {
  line-height: 136px;
}

.h-137 {
  height: 137%;
}

.lh-137 {
  line-height: 137%;
}

.h-137px {
  height: 137px;
}

.lh-137px {
  line-height: 137px;
}

.h-138 {
  height: 138%;
}

.lh-138 {
  line-height: 138%;
}

.h-138px {
  height: 138px;
}

.lh-138px {
  line-height: 138px;
}

.h-139 {
  height: 139%;
}

.lh-139 {
  line-height: 139%;
}

.h-139px {
  height: 139px;
}

.lh-139px {
  line-height: 139px;
}

.h-140 {
  height: 140%;
}

.lh-140 {
  line-height: 140%;
}

.h-140px {
  height: 140px;
}

.lh-140px {
  line-height: 140px;
}

.h-141 {
  height: 141%;
}

.lh-141 {
  line-height: 141%;
}

.h-141px {
  height: 141px;
}

.lh-141px {
  line-height: 141px;
}

.h-142 {
  height: 142%;
}

.lh-142 {
  line-height: 142%;
}

.h-142px {
  height: 142px;
}

.lh-142px {
  line-height: 142px;
}

.h-143 {
  height: 143%;
}

.lh-143 {
  line-height: 143%;
}

.h-143px {
  height: 143px;
}

.lh-143px {
  line-height: 143px;
}

.h-144 {
  height: 144%;
}

.lh-144 {
  line-height: 144%;
}

.h-144px {
  height: 144px;
}

.lh-144px {
  line-height: 144px;
}

.h-145 {
  height: 145%;
}

.lh-145 {
  line-height: 145%;
}

.h-145px {
  height: 145px;
}

.lh-145px {
  line-height: 145px;
}

.h-146 {
  height: 146%;
}

.lh-146 {
  line-height: 146%;
}

.h-146px {
  height: 146px;
}

.lh-146px {
  line-height: 146px;
}

.h-147 {
  height: 147%;
}

.lh-147 {
  line-height: 147%;
}

.h-147px {
  height: 147px;
}

.lh-147px {
  line-height: 147px;
}

.h-148 {
  height: 148%;
}

.lh-148 {
  line-height: 148%;
}

.h-148px {
  height: 148px;
}

.lh-148px {
  line-height: 148px;
}

.h-149 {
  height: 149%;
}

.lh-149 {
  line-height: 149%;
}

.h-149px {
  height: 149px;
}

.lh-149px {
  line-height: 149px;
}

.h-150 {
  height: 150%;
}

.lh-150 {
  line-height: 150%;
}

.h-150px {
  height: 150px;
}

.lh-150px {
  line-height: 150px;
}

.h-151 {
  height: 151%;
}

.lh-151 {
  line-height: 151%;
}

.h-151px {
  height: 151px;
}

.lh-151px {
  line-height: 151px;
}

.h-152 {
  height: 152%;
}

.lh-152 {
  line-height: 152%;
}

.h-152px {
  height: 152px;
}

.lh-152px {
  line-height: 152px;
}

.h-153 {
  height: 153%;
}

.lh-153 {
  line-height: 153%;
}

.h-153px {
  height: 153px;
}

.lh-153px {
  line-height: 153px;
}

.h-154 {
  height: 154%;
}

.lh-154 {
  line-height: 154%;
}

.h-154px {
  height: 154px;
}

.lh-154px {
  line-height: 154px;
}

.h-155 {
  height: 155%;
}

.lh-155 {
  line-height: 155%;
}

.h-155px {
  height: 155px;
}

.lh-155px {
  line-height: 155px;
}

.h-156 {
  height: 156%;
}

.lh-156 {
  line-height: 156%;
}

.h-156px {
  height: 156px;
}

.lh-156px {
  line-height: 156px;
}

.h-157 {
  height: 157%;
}

.lh-157 {
  line-height: 157%;
}

.h-157px {
  height: 157px;
}

.lh-157px {
  line-height: 157px;
}

.h-158 {
  height: 158%;
}

.lh-158 {
  line-height: 158%;
}

.h-158px {
  height: 158px;
}

.lh-158px {
  line-height: 158px;
}

.h-159 {
  height: 159%;
}

.lh-159 {
  line-height: 159%;
}

.h-159px {
  height: 159px;
}

.lh-159px {
  line-height: 159px;
}

.h-160 {
  height: 160%;
}

.lh-160 {
  line-height: 160%;
}

.h-160px {
  height: 160px;
}

.lh-160px {
  line-height: 160px;
}

.h-161 {
  height: 161%;
}

.lh-161 {
  line-height: 161%;
}

.h-161px {
  height: 161px;
}

.lh-161px {
  line-height: 161px;
}

.h-162 {
  height: 162%;
}

.lh-162 {
  line-height: 162%;
}

.h-162px {
  height: 162px;
}

.lh-162px {
  line-height: 162px;
}

.h-163 {
  height: 163%;
}

.lh-163 {
  line-height: 163%;
}

.h-163px {
  height: 163px;
}

.lh-163px {
  line-height: 163px;
}

.h-164 {
  height: 164%;
}

.lh-164 {
  line-height: 164%;
}

.h-164px {
  height: 164px;
}

.lh-164px {
  line-height: 164px;
}

.h-165 {
  height: 165%;
}

.lh-165 {
  line-height: 165%;
}

.h-165px {
  height: 165px;
}

.lh-165px {
  line-height: 165px;
}

.h-166 {
  height: 166%;
}

.lh-166 {
  line-height: 166%;
}

.h-166px {
  height: 166px;
}

.lh-166px {
  line-height: 166px;
}

.h-167 {
  height: 167%;
}

.lh-167 {
  line-height: 167%;
}

.h-167px {
  height: 167px;
}

.lh-167px {
  line-height: 167px;
}

.h-168 {
  height: 168%;
}

.lh-168 {
  line-height: 168%;
}

.h-168px {
  height: 168px;
}

.lh-168px {
  line-height: 168px;
}

.h-169 {
  height: 169%;
}

.lh-169 {
  line-height: 169%;
}

.h-169px {
  height: 169px;
}

.lh-169px {
  line-height: 169px;
}

.h-170 {
  height: 170%;
}

.lh-170 {
  line-height: 170%;
}

.h-170px {
  height: 170px;
}

.lh-170px {
  line-height: 170px;
}

.h-171 {
  height: 171%;
}

.lh-171 {
  line-height: 171%;
}

.h-171px {
  height: 171px;
}

.lh-171px {
  line-height: 171px;
}

.h-172 {
  height: 172%;
}

.lh-172 {
  line-height: 172%;
}

.h-172px {
  height: 172px;
}

.lh-172px {
  line-height: 172px;
}

.h-173 {
  height: 173%;
}

.lh-173 {
  line-height: 173%;
}

.h-173px {
  height: 173px;
}

.lh-173px {
  line-height: 173px;
}

.h-174 {
  height: 174%;
}

.lh-174 {
  line-height: 174%;
}

.h-174px {
  height: 174px;
}

.lh-174px {
  line-height: 174px;
}

.h-175 {
  height: 175%;
}

.lh-175 {
  line-height: 175%;
}

.h-175px {
  height: 175px;
}

.lh-175px {
  line-height: 175px;
}

.h-176 {
  height: 176%;
}

.lh-176 {
  line-height: 176%;
}

.h-176px {
  height: 176px;
}

.lh-176px {
  line-height: 176px;
}

.h-177 {
  height: 177%;
}

.lh-177 {
  line-height: 177%;
}

.h-177px {
  height: 177px;
}

.lh-177px {
  line-height: 177px;
}

.h-178 {
  height: 178%;
}

.lh-178 {
  line-height: 178%;
}

.h-178px {
  height: 178px;
}

.lh-178px {
  line-height: 178px;
}

.h-179 {
  height: 179%;
}

.lh-179 {
  line-height: 179%;
}

.h-179px {
  height: 179px;
}

.lh-179px {
  line-height: 179px;
}

.h-180 {
  height: 180%;
}

.lh-180 {
  line-height: 180%;
}

.h-180px {
  height: 180px;
}

.lh-180px {
  line-height: 180px;
}

.h-181 {
  height: 181%;
}

.lh-181 {
  line-height: 181%;
}

.h-181px {
  height: 181px;
}

.lh-181px {
  line-height: 181px;
}

.h-182 {
  height: 182%;
}

.lh-182 {
  line-height: 182%;
}

.h-182px {
  height: 182px;
}

.lh-182px {
  line-height: 182px;
}

.h-183 {
  height: 183%;
}

.lh-183 {
  line-height: 183%;
}

.h-183px {
  height: 183px;
}

.lh-183px {
  line-height: 183px;
}

.h-184 {
  height: 184%;
}

.lh-184 {
  line-height: 184%;
}

.h-184px {
  height: 184px;
}

.lh-184px {
  line-height: 184px;
}

.h-185 {
  height: 185%;
}

.lh-185 {
  line-height: 185%;
}

.h-185px {
  height: 185px;
}

.lh-185px {
  line-height: 185px;
}

.h-186 {
  height: 186%;
}

.lh-186 {
  line-height: 186%;
}

.h-186px {
  height: 186px;
}

.lh-186px {
  line-height: 186px;
}

.h-187 {
  height: 187%;
}

.lh-187 {
  line-height: 187%;
}

.h-187px {
  height: 187px;
}

.lh-187px {
  line-height: 187px;
}

.h-188 {
  height: 188%;
}

.lh-188 {
  line-height: 188%;
}

.h-188px {
  height: 188px;
}

.lh-188px {
  line-height: 188px;
}

.h-189 {
  height: 189%;
}

.lh-189 {
  line-height: 189%;
}

.h-189px {
  height: 189px;
}

.lh-189px {
  line-height: 189px;
}

.h-190 {
  height: 190%;
}

.lh-190 {
  line-height: 190%;
}

.h-190px {
  height: 190px;
}

.lh-190px {
  line-height: 190px;
}

.h-191 {
  height: 191%;
}

.lh-191 {
  line-height: 191%;
}

.h-191px {
  height: 191px;
}

.lh-191px {
  line-height: 191px;
}

.h-192 {
  height: 192%;
}

.lh-192 {
  line-height: 192%;
}

.h-192px {
  height: 192px;
}

.lh-192px {
  line-height: 192px;
}

.h-193 {
  height: 193%;
}

.lh-193 {
  line-height: 193%;
}

.h-193px {
  height: 193px;
}

.lh-193px {
  line-height: 193px;
}

.h-194 {
  height: 194%;
}

.lh-194 {
  line-height: 194%;
}

.h-194px {
  height: 194px;
}

.lh-194px {
  line-height: 194px;
}

.h-195 {
  height: 195%;
}

.lh-195 {
  line-height: 195%;
}

.h-195px {
  height: 195px;
}

.lh-195px {
  line-height: 195px;
}

.h-196 {
  height: 196%;
}

.lh-196 {
  line-height: 196%;
}

.h-196px {
  height: 196px;
}

.lh-196px {
  line-height: 196px;
}

.h-197 {
  height: 197%;
}

.lh-197 {
  line-height: 197%;
}

.h-197px {
  height: 197px;
}

.lh-197px {
  line-height: 197px;
}

.h-198 {
  height: 198%;
}

.lh-198 {
  line-height: 198%;
}

.h-198px {
  height: 198px;
}

.lh-198px {
  line-height: 198px;
}

.h-199 {
  height: 199%;
}

.lh-199 {
  line-height: 199%;
}

.h-199px {
  height: 199px;
}

.lh-199px {
  line-height: 199px;
}

.h-200 {
  height: 200%;
}

.lh-200 {
  line-height: 200%;
}

.h-200px {
  height: 200px;
}

.lh-200px {
  line-height: 200px;
}

.h-201 {
  height: 201%;
}

.lh-201 {
  line-height: 201%;
}

.h-201px {
  height: 201px;
}

.lh-201px {
  line-height: 201px;
}

.h-202 {
  height: 202%;
}

.lh-202 {
  line-height: 202%;
}

.h-202px {
  height: 202px;
}

.lh-202px {
  line-height: 202px;
}

.h-203 {
  height: 203%;
}

.lh-203 {
  line-height: 203%;
}

.h-203px {
  height: 203px;
}

.lh-203px {
  line-height: 203px;
}

.h-204 {
  height: 204%;
}

.lh-204 {
  line-height: 204%;
}

.h-204px {
  height: 204px;
}

.lh-204px {
  line-height: 204px;
}

.h-205 {
  height: 205%;
}

.lh-205 {
  line-height: 205%;
}

.h-205px {
  height: 205px;
}

.lh-205px {
  line-height: 205px;
}

.h-206 {
  height: 206%;
}

.lh-206 {
  line-height: 206%;
}

.h-206px {
  height: 206px;
}

.lh-206px {
  line-height: 206px;
}

.h-207 {
  height: 207%;
}

.lh-207 {
  line-height: 207%;
}

.h-207px {
  height: 207px;
}

.lh-207px {
  line-height: 207px;
}

.h-208 {
  height: 208%;
}

.lh-208 {
  line-height: 208%;
}

.h-208px {
  height: 208px;
}

.lh-208px {
  line-height: 208px;
}

.h-209 {
  height: 209%;
}

.lh-209 {
  line-height: 209%;
}

.h-209px {
  height: 209px;
}

.lh-209px {
  line-height: 209px;
}

.h-210 {
  height: 210%;
}

.lh-210 {
  line-height: 210%;
}

.h-210px {
  height: 210px;
}

.lh-210px {
  line-height: 210px;
}

.h-211 {
  height: 211%;
}

.lh-211 {
  line-height: 211%;
}

.h-211px {
  height: 211px;
}

.lh-211px {
  line-height: 211px;
}

.h-212 {
  height: 212%;
}

.lh-212 {
  line-height: 212%;
}

.h-212px {
  height: 212px;
}

.lh-212px {
  line-height: 212px;
}

.h-213 {
  height: 213%;
}

.lh-213 {
  line-height: 213%;
}

.h-213px {
  height: 213px;
}

.lh-213px {
  line-height: 213px;
}

.h-214 {
  height: 214%;
}

.lh-214 {
  line-height: 214%;
}

.h-214px {
  height: 214px;
}

.lh-214px {
  line-height: 214px;
}

.h-215 {
  height: 215%;
}

.lh-215 {
  line-height: 215%;
}

.h-215px {
  height: 215px;
}

.lh-215px {
  line-height: 215px;
}

.h-216 {
  height: 216%;
}

.lh-216 {
  line-height: 216%;
}

.h-216px {
  height: 216px;
}

.lh-216px {
  line-height: 216px;
}

.h-217 {
  height: 217%;
}

.lh-217 {
  line-height: 217%;
}

.h-217px {
  height: 217px;
}

.lh-217px {
  line-height: 217px;
}

.h-218 {
  height: 218%;
}

.lh-218 {
  line-height: 218%;
}

.h-218px {
  height: 218px;
}

.lh-218px {
  line-height: 218px;
}

.h-219 {
  height: 219%;
}

.lh-219 {
  line-height: 219%;
}

.h-219px {
  height: 219px;
}

.lh-219px {
  line-height: 219px;
}

.h-220 {
  height: 220%;
}

.lh-220 {
  line-height: 220%;
}

.h-220px {
  height: 220px;
}

.lh-220px {
  line-height: 220px;
}

.h-221 {
  height: 221%;
}

.lh-221 {
  line-height: 221%;
}

.h-221px {
  height: 221px;
}

.lh-221px {
  line-height: 221px;
}

.h-222 {
  height: 222%;
}

.lh-222 {
  line-height: 222%;
}

.h-222px {
  height: 222px;
}

.lh-222px {
  line-height: 222px;
}

.h-223 {
  height: 223%;
}

.lh-223 {
  line-height: 223%;
}

.h-223px {
  height: 223px;
}

.lh-223px {
  line-height: 223px;
}

.h-224 {
  height: 224%;
}

.lh-224 {
  line-height: 224%;
}

.h-224px {
  height: 224px;
}

.lh-224px {
  line-height: 224px;
}

.h-225 {
  height: 225%;
}

.lh-225 {
  line-height: 225%;
}

.h-225px {
  height: 225px;
}

.lh-225px {
  line-height: 225px;
}

.h-226 {
  height: 226%;
}

.lh-226 {
  line-height: 226%;
}

.h-226px {
  height: 226px;
}

.lh-226px {
  line-height: 226px;
}

.h-227 {
  height: 227%;
}

.lh-227 {
  line-height: 227%;
}

.h-227px {
  height: 227px;
}

.lh-227px {
  line-height: 227px;
}

.h-228 {
  height: 228%;
}

.lh-228 {
  line-height: 228%;
}

.h-228px {
  height: 228px;
}

.lh-228px {
  line-height: 228px;
}

.h-229 {
  height: 229%;
}

.lh-229 {
  line-height: 229%;
}

.h-229px {
  height: 229px;
}

.lh-229px {
  line-height: 229px;
}

.h-230 {
  height: 230%;
}

.lh-230 {
  line-height: 230%;
}

.h-230px {
  height: 230px;
}

.lh-230px {
  line-height: 230px;
}

.h-231 {
  height: 231%;
}

.lh-231 {
  line-height: 231%;
}

.h-231px {
  height: 231px;
}

.lh-231px {
  line-height: 231px;
}

.h-232 {
  height: 232%;
}

.lh-232 {
  line-height: 232%;
}

.h-232px {
  height: 232px;
}

.lh-232px {
  line-height: 232px;
}

.h-233 {
  height: 233%;
}

.lh-233 {
  line-height: 233%;
}

.h-233px {
  height: 233px;
}

.lh-233px {
  line-height: 233px;
}

.h-234 {
  height: 234%;
}

.lh-234 {
  line-height: 234%;
}

.h-234px {
  height: 234px;
}

.lh-234px {
  line-height: 234px;
}

.h-235 {
  height: 235%;
}

.lh-235 {
  line-height: 235%;
}

.h-235px {
  height: 235px;
}

.lh-235px {
  line-height: 235px;
}

.h-236 {
  height: 236%;
}

.lh-236 {
  line-height: 236%;
}

.h-236px {
  height: 236px;
}

.lh-236px {
  line-height: 236px;
}

.h-237 {
  height: 237%;
}

.lh-237 {
  line-height: 237%;
}

.h-237px {
  height: 237px;
}

.lh-237px {
  line-height: 237px;
}

.h-238 {
  height: 238%;
}

.lh-238 {
  line-height: 238%;
}

.h-238px {
  height: 238px;
}

.lh-238px {
  line-height: 238px;
}

.h-239 {
  height: 239%;
}

.lh-239 {
  line-height: 239%;
}

.h-239px {
  height: 239px;
}

.lh-239px {
  line-height: 239px;
}

.h-240 {
  height: 240%;
}

.lh-240 {
  line-height: 240%;
}

.h-240px {
  height: 240px;
}

.lh-240px {
  line-height: 240px;
}

.h-241 {
  height: 241%;
}

.lh-241 {
  line-height: 241%;
}

.h-241px {
  height: 241px;
}

.lh-241px {
  line-height: 241px;
}

.h-242 {
  height: 242%;
}

.lh-242 {
  line-height: 242%;
}

.h-242px {
  height: 242px;
}

.lh-242px {
  line-height: 242px;
}

.h-243 {
  height: 243%;
}

.lh-243 {
  line-height: 243%;
}

.h-243px {
  height: 243px;
}

.lh-243px {
  line-height: 243px;
}

.h-244 {
  height: 244%;
}

.lh-244 {
  line-height: 244%;
}

.h-244px {
  height: 244px;
}

.lh-244px {
  line-height: 244px;
}

.h-245 {
  height: 245%;
}

.lh-245 {
  line-height: 245%;
}

.h-245px {
  height: 245px;
}

.lh-245px {
  line-height: 245px;
}

.h-246 {
  height: 246%;
}

.lh-246 {
  line-height: 246%;
}

.h-246px {
  height: 246px;
}

.lh-246px {
  line-height: 246px;
}

.h-247 {
  height: 247%;
}

.lh-247 {
  line-height: 247%;
}

.h-247px {
  height: 247px;
}

.lh-247px {
  line-height: 247px;
}

.h-248 {
  height: 248%;
}

.lh-248 {
  line-height: 248%;
}

.h-248px {
  height: 248px;
}

.lh-248px {
  line-height: 248px;
}

.h-249 {
  height: 249%;
}

.lh-249 {
  line-height: 249%;
}

.h-249px {
  height: 249px;
}

.lh-249px {
  line-height: 249px;
}

.h-250 {
  height: 250%;
}

.lh-250 {
  line-height: 250%;
}

.h-250px {
  height: 250px;
}

.lh-250px {
  line-height: 250px;
}

.h-251 {
  height: 251%;
}

.lh-251 {
  line-height: 251%;
}

.h-251px {
  height: 251px;
}

.lh-251px {
  line-height: 251px;
}

.h-252 {
  height: 252%;
}

.lh-252 {
  line-height: 252%;
}

.h-252px {
  height: 252px;
}

.lh-252px {
  line-height: 252px;
}

.h-253 {
  height: 253%;
}

.lh-253 {
  line-height: 253%;
}

.h-253px {
  height: 253px;
}

.lh-253px {
  line-height: 253px;
}

.h-254 {
  height: 254%;
}

.lh-254 {
  line-height: 254%;
}

.h-254px {
  height: 254px;
}

.lh-254px {
  line-height: 254px;
}

.h-255 {
  height: 255%;
}

.lh-255 {
  line-height: 255%;
}

.h-255px {
  height: 255px;
}

.lh-255px {
  line-height: 255px;
}

.h-256 {
  height: 256%;
}

.lh-256 {
  line-height: 256%;
}

.h-256px {
  height: 256px;
}

.lh-256px {
  line-height: 256px;
}

.h-257 {
  height: 257%;
}

.lh-257 {
  line-height: 257%;
}

.h-257px {
  height: 257px;
}

.lh-257px {
  line-height: 257px;
}

.h-258 {
  height: 258%;
}

.lh-258 {
  line-height: 258%;
}

.h-258px {
  height: 258px;
}

.lh-258px {
  line-height: 258px;
}

.h-259 {
  height: 259%;
}

.lh-259 {
  line-height: 259%;
}

.h-259px {
  height: 259px;
}

.lh-259px {
  line-height: 259px;
}

.h-260 {
  height: 260%;
}

.lh-260 {
  line-height: 260%;
}

.h-260px {
  height: 260px;
}

.lh-260px {
  line-height: 260px;
}

.h-261 {
  height: 261%;
}

.lh-261 {
  line-height: 261%;
}

.h-261px {
  height: 261px;
}

.lh-261px {
  line-height: 261px;
}

.h-262 {
  height: 262%;
}

.lh-262 {
  line-height: 262%;
}

.h-262px {
  height: 262px;
}

.lh-262px {
  line-height: 262px;
}

.h-263 {
  height: 263%;
}

.lh-263 {
  line-height: 263%;
}

.h-263px {
  height: 263px;
}

.lh-263px {
  line-height: 263px;
}

.h-264 {
  height: 264%;
}

.lh-264 {
  line-height: 264%;
}

.h-264px {
  height: 264px;
}

.lh-264px {
  line-height: 264px;
}

.h-265 {
  height: 265%;
}

.lh-265 {
  line-height: 265%;
}

.h-265px {
  height: 265px;
}

.lh-265px {
  line-height: 265px;
}

.h-266 {
  height: 266%;
}

.lh-266 {
  line-height: 266%;
}

.h-266px {
  height: 266px;
}

.lh-266px {
  line-height: 266px;
}

.h-267 {
  height: 267%;
}

.lh-267 {
  line-height: 267%;
}

.h-267px {
  height: 267px;
}

.lh-267px {
  line-height: 267px;
}

.h-268 {
  height: 268%;
}

.lh-268 {
  line-height: 268%;
}

.h-268px {
  height: 268px;
}

.lh-268px {
  line-height: 268px;
}

.h-269 {
  height: 269%;
}

.lh-269 {
  line-height: 269%;
}

.h-269px {
  height: 269px;
}

.lh-269px {
  line-height: 269px;
}

.h-270 {
  height: 270%;
}

.lh-270 {
  line-height: 270%;
}

.h-270px {
  height: 270px;
}

.lh-270px {
  line-height: 270px;
}

.h-271 {
  height: 271%;
}

.lh-271 {
  line-height: 271%;
}

.h-271px {
  height: 271px;
}

.lh-271px {
  line-height: 271px;
}

.h-272 {
  height: 272%;
}

.lh-272 {
  line-height: 272%;
}

.h-272px {
  height: 272px;
}

.lh-272px {
  line-height: 272px;
}

.h-273 {
  height: 273%;
}

.lh-273 {
  line-height: 273%;
}

.h-273px {
  height: 273px;
}

.lh-273px {
  line-height: 273px;
}

.h-274 {
  height: 274%;
}

.lh-274 {
  line-height: 274%;
}

.h-274px {
  height: 274px;
}

.lh-274px {
  line-height: 274px;
}

.h-275 {
  height: 275%;
}

.lh-275 {
  line-height: 275%;
}

.h-275px {
  height: 275px;
}

.lh-275px {
  line-height: 275px;
}

.h-276 {
  height: 276%;
}

.lh-276 {
  line-height: 276%;
}

.h-276px {
  height: 276px;
}

.lh-276px {
  line-height: 276px;
}

.h-277 {
  height: 277%;
}

.lh-277 {
  line-height: 277%;
}

.h-277px {
  height: 277px;
}

.lh-277px {
  line-height: 277px;
}

.h-278 {
  height: 278%;
}

.lh-278 {
  line-height: 278%;
}

.h-278px {
  height: 278px;
}

.lh-278px {
  line-height: 278px;
}

.h-279 {
  height: 279%;
}

.lh-279 {
  line-height: 279%;
}

.h-279px {
  height: 279px;
}

.lh-279px {
  line-height: 279px;
}

.h-280 {
  height: 280%;
}

.lh-280 {
  line-height: 280%;
}

.h-280px {
  height: 280px;
}

.lh-280px {
  line-height: 280px;
}

.h-281 {
  height: 281%;
}

.lh-281 {
  line-height: 281%;
}

.h-281px {
  height: 281px;
}

.lh-281px {
  line-height: 281px;
}

.h-282 {
  height: 282%;
}

.lh-282 {
  line-height: 282%;
}

.h-282px {
  height: 282px;
}

.lh-282px {
  line-height: 282px;
}

.h-283 {
  height: 283%;
}

.lh-283 {
  line-height: 283%;
}

.h-283px {
  height: 283px;
}

.lh-283px {
  line-height: 283px;
}

.h-284 {
  height: 284%;
}

.lh-284 {
  line-height: 284%;
}

.h-284px {
  height: 284px;
}

.lh-284px {
  line-height: 284px;
}

.h-285 {
  height: 285%;
}

.lh-285 {
  line-height: 285%;
}

.h-285px {
  height: 285px;
}

.lh-285px {
  line-height: 285px;
}

.h-286 {
  height: 286%;
}

.lh-286 {
  line-height: 286%;
}

.h-286px {
  height: 286px;
}

.lh-286px {
  line-height: 286px;
}

.h-287 {
  height: 287%;
}

.lh-287 {
  line-height: 287%;
}

.h-287px {
  height: 287px;
}

.lh-287px {
  line-height: 287px;
}

.h-288 {
  height: 288%;
}

.lh-288 {
  line-height: 288%;
}

.h-288px {
  height: 288px;
}

.lh-288px {
  line-height: 288px;
}

.h-289 {
  height: 289%;
}

.lh-289 {
  line-height: 289%;
}

.h-289px {
  height: 289px;
}

.lh-289px {
  line-height: 289px;
}

.h-290 {
  height: 290%;
}

.lh-290 {
  line-height: 290%;
}

.h-290px {
  height: 290px;
}

.lh-290px {
  line-height: 290px;
}

.h-291 {
  height: 291%;
}

.lh-291 {
  line-height: 291%;
}

.h-291px {
  height: 291px;
}

.lh-291px {
  line-height: 291px;
}

.h-292 {
  height: 292%;
}

.lh-292 {
  line-height: 292%;
}

.h-292px {
  height: 292px;
}

.lh-292px {
  line-height: 292px;
}

.h-293 {
  height: 293%;
}

.lh-293 {
  line-height: 293%;
}

.h-293px {
  height: 293px;
}

.lh-293px {
  line-height: 293px;
}

.h-294 {
  height: 294%;
}

.lh-294 {
  line-height: 294%;
}

.h-294px {
  height: 294px;
}

.lh-294px {
  line-height: 294px;
}

.h-295 {
  height: 295%;
}

.lh-295 {
  line-height: 295%;
}

.h-295px {
  height: 295px;
}

.lh-295px {
  line-height: 295px;
}

.h-296 {
  height: 296%;
}

.lh-296 {
  line-height: 296%;
}

.h-296px {
  height: 296px;
}

.lh-296px {
  line-height: 296px;
}

.h-297 {
  height: 297%;
}

.lh-297 {
  line-height: 297%;
}

.h-297px {
  height: 297px;
}

.lh-297px {
  line-height: 297px;
}

.h-298 {
  height: 298%;
}

.lh-298 {
  line-height: 298%;
}

.h-298px {
  height: 298px;
}

.lh-298px {
  line-height: 298px;
}

.h-299 {
  height: 299%;
}

.lh-299 {
  line-height: 299%;
}

.h-299px {
  height: 299px;
}

.lh-299px {
  line-height: 299px;
}

.h-300 {
  height: 300%;
}

.lh-300 {
  line-height: 300%;
}

.h-300px {
  height: 300px;
}

.lh-300px {
  line-height: 300px;
}

.h-301 {
  height: 301%;
}

.lh-301 {
  line-height: 301%;
}

.h-301px {
  height: 301px;
}

.lh-301px {
  line-height: 301px;
}

.h-302 {
  height: 302%;
}

.lh-302 {
  line-height: 302%;
}

.h-302px {
  height: 302px;
}

.lh-302px {
  line-height: 302px;
}

.h-303 {
  height: 303%;
}

.lh-303 {
  line-height: 303%;
}

.h-303px {
  height: 303px;
}

.lh-303px {
  line-height: 303px;
}

.h-304 {
  height: 304%;
}

.lh-304 {
  line-height: 304%;
}

.h-304px {
  height: 304px;
}

.lh-304px {
  line-height: 304px;
}

.h-305 {
  height: 305%;
}

.lh-305 {
  line-height: 305%;
}

.h-305px {
  height: 305px;
}

.lh-305px {
  line-height: 305px;
}

.h-306 {
  height: 306%;
}

.lh-306 {
  line-height: 306%;
}

.h-306px {
  height: 306px;
}

.lh-306px {
  line-height: 306px;
}

.h-307 {
  height: 307%;
}

.lh-307 {
  line-height: 307%;
}

.h-307px {
  height: 307px;
}

.lh-307px {
  line-height: 307px;
}

.h-308 {
  height: 308%;
}

.lh-308 {
  line-height: 308%;
}

.h-308px {
  height: 308px;
}

.lh-308px {
  line-height: 308px;
}

.h-309 {
  height: 309%;
}

.lh-309 {
  line-height: 309%;
}

.h-309px {
  height: 309px;
}

.lh-309px {
  line-height: 309px;
}

.h-310 {
  height: 310%;
}

.lh-310 {
  line-height: 310%;
}

.h-310px {
  height: 310px;
}

.lh-310px {
  line-height: 310px;
}

.h-311 {
  height: 311%;
}

.lh-311 {
  line-height: 311%;
}

.h-311px {
  height: 311px;
}

.lh-311px {
  line-height: 311px;
}

.h-312 {
  height: 312%;
}

.lh-312 {
  line-height: 312%;
}

.h-312px {
  height: 312px;
}

.lh-312px {
  line-height: 312px;
}

.h-313 {
  height: 313%;
}

.lh-313 {
  line-height: 313%;
}

.h-313px {
  height: 313px;
}

.lh-313px {
  line-height: 313px;
}

.h-314 {
  height: 314%;
}

.lh-314 {
  line-height: 314%;
}

.h-314px {
  height: 314px;
}

.lh-314px {
  line-height: 314px;
}

.h-315 {
  height: 315%;
}

.lh-315 {
  line-height: 315%;
}

.h-315px {
  height: 315px;
}

.lh-315px {
  line-height: 315px;
}

.h-316 {
  height: 316%;
}

.lh-316 {
  line-height: 316%;
}

.h-316px {
  height: 316px;
}

.lh-316px {
  line-height: 316px;
}

.h-317 {
  height: 317%;
}

.lh-317 {
  line-height: 317%;
}

.h-317px {
  height: 317px;
}

.lh-317px {
  line-height: 317px;
}

.h-318 {
  height: 318%;
}

.lh-318 {
  line-height: 318%;
}

.h-318px {
  height: 318px;
}

.lh-318px {
  line-height: 318px;
}

.h-319 {
  height: 319%;
}

.lh-319 {
  line-height: 319%;
}

.h-319px {
  height: 319px;
}

.lh-319px {
  line-height: 319px;
}

.h-320 {
  height: 320%;
}

.lh-320 {
  line-height: 320%;
}

.h-320px {
  height: 320px;
}

.lh-320px {
  line-height: 320px;
}

.h-321 {
  height: 321%;
}

.lh-321 {
  line-height: 321%;
}

.h-321px {
  height: 321px;
}

.lh-321px {
  line-height: 321px;
}

.h-322 {
  height: 322%;
}

.lh-322 {
  line-height: 322%;
}

.h-322px {
  height: 322px;
}

.lh-322px {
  line-height: 322px;
}

.h-323 {
  height: 323%;
}

.lh-323 {
  line-height: 323%;
}

.h-323px {
  height: 323px;
}

.lh-323px {
  line-height: 323px;
}

.h-324 {
  height: 324%;
}

.lh-324 {
  line-height: 324%;
}

.h-324px {
  height: 324px;
}

.lh-324px {
  line-height: 324px;
}

.h-325 {
  height: 325%;
}

.lh-325 {
  line-height: 325%;
}

.h-325px {
  height: 325px;
}

.lh-325px {
  line-height: 325px;
}

.h-326 {
  height: 326%;
}

.lh-326 {
  line-height: 326%;
}

.h-326px {
  height: 326px;
}

.lh-326px {
  line-height: 326px;
}

.h-327 {
  height: 327%;
}

.lh-327 {
  line-height: 327%;
}

.h-327px {
  height: 327px;
}

.lh-327px {
  line-height: 327px;
}

.h-328 {
  height: 328%;
}

.lh-328 {
  line-height: 328%;
}

.h-328px {
  height: 328px;
}

.lh-328px {
  line-height: 328px;
}

.h-329 {
  height: 329%;
}

.lh-329 {
  line-height: 329%;
}

.h-329px {
  height: 329px;
}

.lh-329px {
  line-height: 329px;
}

.h-330 {
  height: 330%;
}

.lh-330 {
  line-height: 330%;
}

.h-330px {
  height: 330px;
}

.lh-330px {
  line-height: 330px;
}

.h-331 {
  height: 331%;
}

.lh-331 {
  line-height: 331%;
}

.h-331px {
  height: 331px;
}

.lh-331px {
  line-height: 331px;
}

.h-332 {
  height: 332%;
}

.lh-332 {
  line-height: 332%;
}

.h-332px {
  height: 332px;
}

.lh-332px {
  line-height: 332px;
}

.h-333 {
  height: 333%;
}

.lh-333 {
  line-height: 333%;
}

.h-333px {
  height: 333px;
}

.lh-333px {
  line-height: 333px;
}

.h-334 {
  height: 334%;
}

.lh-334 {
  line-height: 334%;
}

.h-334px {
  height: 334px;
}

.lh-334px {
  line-height: 334px;
}

.h-335 {
  height: 335%;
}

.lh-335 {
  line-height: 335%;
}

.h-335px {
  height: 335px;
}

.lh-335px {
  line-height: 335px;
}

.h-336 {
  height: 336%;
}

.lh-336 {
  line-height: 336%;
}

.h-336px {
  height: 336px;
}

.lh-336px {
  line-height: 336px;
}

.h-337 {
  height: 337%;
}

.lh-337 {
  line-height: 337%;
}

.h-337px {
  height: 337px;
}

.lh-337px {
  line-height: 337px;
}

.h-338 {
  height: 338%;
}

.lh-338 {
  line-height: 338%;
}

.h-338px {
  height: 338px;
}

.lh-338px {
  line-height: 338px;
}

.h-339 {
  height: 339%;
}

.lh-339 {
  line-height: 339%;
}

.h-339px {
  height: 339px;
}

.lh-339px {
  line-height: 339px;
}

.h-340 {
  height: 340%;
}

.lh-340 {
  line-height: 340%;
}

.h-340px {
  height: 340px;
}

.lh-340px {
  line-height: 340px;
}

.h-341 {
  height: 341%;
}

.lh-341 {
  line-height: 341%;
}

.h-341px {
  height: 341px;
}

.lh-341px {
  line-height: 341px;
}

.h-342 {
  height: 342%;
}

.lh-342 {
  line-height: 342%;
}

.h-342px {
  height: 342px;
}

.lh-342px {
  line-height: 342px;
}

.h-343 {
  height: 343%;
}

.lh-343 {
  line-height: 343%;
}

.h-343px {
  height: 343px;
}

.lh-343px {
  line-height: 343px;
}

.h-344 {
  height: 344%;
}

.lh-344 {
  line-height: 344%;
}

.h-344px {
  height: 344px;
}

.lh-344px {
  line-height: 344px;
}

.h-345 {
  height: 345%;
}

.lh-345 {
  line-height: 345%;
}

.h-345px {
  height: 345px;
}

.lh-345px {
  line-height: 345px;
}

.h-346 {
  height: 346%;
}

.lh-346 {
  line-height: 346%;
}

.h-346px {
  height: 346px;
}

.lh-346px {
  line-height: 346px;
}

.h-347 {
  height: 347%;
}

.lh-347 {
  line-height: 347%;
}

.h-347px {
  height: 347px;
}

.lh-347px {
  line-height: 347px;
}

.h-348 {
  height: 348%;
}

.lh-348 {
  line-height: 348%;
}

.h-348px {
  height: 348px;
}

.lh-348px {
  line-height: 348px;
}

.h-349 {
  height: 349%;
}

.lh-349 {
  line-height: 349%;
}

.h-349px {
  height: 349px;
}

.lh-349px {
  line-height: 349px;
}

.h-350 {
  height: 350%;
}

.lh-350 {
  line-height: 350%;
}

.h-350px {
  height: 350px;
}

.lh-350px {
  line-height: 350px;
}

.h-351 {
  height: 351%;
}

.lh-351 {
  line-height: 351%;
}

.h-351px {
  height: 351px;
}

.lh-351px {
  line-height: 351px;
}

.h-352 {
  height: 352%;
}

.lh-352 {
  line-height: 352%;
}

.h-352px {
  height: 352px;
}

.lh-352px {
  line-height: 352px;
}

.h-353 {
  height: 353%;
}

.lh-353 {
  line-height: 353%;
}

.h-353px {
  height: 353px;
}

.lh-353px {
  line-height: 353px;
}

.h-354 {
  height: 354%;
}

.lh-354 {
  line-height: 354%;
}

.h-354px {
  height: 354px;
}

.lh-354px {
  line-height: 354px;
}

.h-355 {
  height: 355%;
}

.lh-355 {
  line-height: 355%;
}

.h-355px {
  height: 355px;
}

.lh-355px {
  line-height: 355px;
}

.h-356 {
  height: 356%;
}

.lh-356 {
  line-height: 356%;
}

.h-356px {
  height: 356px;
}

.lh-356px {
  line-height: 356px;
}

.h-357 {
  height: 357%;
}

.lh-357 {
  line-height: 357%;
}

.h-357px {
  height: 357px;
}

.lh-357px {
  line-height: 357px;
}

.h-358 {
  height: 358%;
}

.lh-358 {
  line-height: 358%;
}

.h-358px {
  height: 358px;
}

.lh-358px {
  line-height: 358px;
}

.h-359 {
  height: 359%;
}

.lh-359 {
  line-height: 359%;
}

.h-359px {
  height: 359px;
}

.lh-359px {
  line-height: 359px;
}

.h-360 {
  height: 360%;
}

.lh-360 {
  line-height: 360%;
}

.h-360px {
  height: 360px;
}

.lh-360px {
  line-height: 360px;
}

.h-361 {
  height: 361%;
}

.lh-361 {
  line-height: 361%;
}

.h-361px {
  height: 361px;
}

.lh-361px {
  line-height: 361px;
}

.h-362 {
  height: 362%;
}

.lh-362 {
  line-height: 362%;
}

.h-362px {
  height: 362px;
}

.lh-362px {
  line-height: 362px;
}

.h-363 {
  height: 363%;
}

.lh-363 {
  line-height: 363%;
}

.h-363px {
  height: 363px;
}

.lh-363px {
  line-height: 363px;
}

.h-364 {
  height: 364%;
}

.lh-364 {
  line-height: 364%;
}

.h-364px {
  height: 364px;
}

.lh-364px {
  line-height: 364px;
}

.h-365 {
  height: 365%;
}

.lh-365 {
  line-height: 365%;
}

.h-365px {
  height: 365px;
}

.lh-365px {
  line-height: 365px;
}

.h-366 {
  height: 366%;
}

.lh-366 {
  line-height: 366%;
}

.h-366px {
  height: 366px;
}

.lh-366px {
  line-height: 366px;
}

.h-367 {
  height: 367%;
}

.lh-367 {
  line-height: 367%;
}

.h-367px {
  height: 367px;
}

.lh-367px {
  line-height: 367px;
}

.h-368 {
  height: 368%;
}

.lh-368 {
  line-height: 368%;
}

.h-368px {
  height: 368px;
}

.lh-368px {
  line-height: 368px;
}

.h-369 {
  height: 369%;
}

.lh-369 {
  line-height: 369%;
}

.h-369px {
  height: 369px;
}

.lh-369px {
  line-height: 369px;
}

.h-370 {
  height: 370%;
}

.lh-370 {
  line-height: 370%;
}

.h-370px {
  height: 370px;
}

.lh-370px {
  line-height: 370px;
}

.h-371 {
  height: 371%;
}

.lh-371 {
  line-height: 371%;
}

.h-371px {
  height: 371px;
}

.lh-371px {
  line-height: 371px;
}

.h-372 {
  height: 372%;
}

.lh-372 {
  line-height: 372%;
}

.h-372px {
  height: 372px;
}

.lh-372px {
  line-height: 372px;
}

.h-373 {
  height: 373%;
}

.lh-373 {
  line-height: 373%;
}

.h-373px {
  height: 373px;
}

.lh-373px {
  line-height: 373px;
}

.h-374 {
  height: 374%;
}

.lh-374 {
  line-height: 374%;
}

.h-374px {
  height: 374px;
}

.lh-374px {
  line-height: 374px;
}

.h-375 {
  height: 375%;
}

.lh-375 {
  line-height: 375%;
}

.h-375px {
  height: 375px;
}

.lh-375px {
  line-height: 375px;
}

.h-376 {
  height: 376%;
}

.lh-376 {
  line-height: 376%;
}

.h-376px {
  height: 376px;
}

.lh-376px {
  line-height: 376px;
}

.h-377 {
  height: 377%;
}

.lh-377 {
  line-height: 377%;
}

.h-377px {
  height: 377px;
}

.lh-377px {
  line-height: 377px;
}

.h-378 {
  height: 378%;
}

.lh-378 {
  line-height: 378%;
}

.h-378px {
  height: 378px;
}

.lh-378px {
  line-height: 378px;
}

.h-379 {
  height: 379%;
}

.lh-379 {
  line-height: 379%;
}

.h-379px {
  height: 379px;
}

.lh-379px {
  line-height: 379px;
}

.h-380 {
  height: 380%;
}

.lh-380 {
  line-height: 380%;
}

.h-380px {
  height: 380px;
}

.lh-380px {
  line-height: 380px;
}

.h-381 {
  height: 381%;
}

.lh-381 {
  line-height: 381%;
}

.h-381px {
  height: 381px;
}

.lh-381px {
  line-height: 381px;
}

.h-382 {
  height: 382%;
}

.lh-382 {
  line-height: 382%;
}

.h-382px {
  height: 382px;
}

.lh-382px {
  line-height: 382px;
}

.h-383 {
  height: 383%;
}

.lh-383 {
  line-height: 383%;
}

.h-383px {
  height: 383px;
}

.lh-383px {
  line-height: 383px;
}

.h-384 {
  height: 384%;
}

.lh-384 {
  line-height: 384%;
}

.h-384px {
  height: 384px;
}

.lh-384px {
  line-height: 384px;
}

.h-385 {
  height: 385%;
}

.lh-385 {
  line-height: 385%;
}

.h-385px {
  height: 385px;
}

.lh-385px {
  line-height: 385px;
}

.h-386 {
  height: 386%;
}

.lh-386 {
  line-height: 386%;
}

.h-386px {
  height: 386px;
}

.lh-386px {
  line-height: 386px;
}

.h-387 {
  height: 387%;
}

.lh-387 {
  line-height: 387%;
}

.h-387px {
  height: 387px;
}

.lh-387px {
  line-height: 387px;
}

.h-388 {
  height: 388%;
}

.lh-388 {
  line-height: 388%;
}

.h-388px {
  height: 388px;
}

.lh-388px {
  line-height: 388px;
}

.h-389 {
  height: 389%;
}

.lh-389 {
  line-height: 389%;
}

.h-389px {
  height: 389px;
}

.lh-389px {
  line-height: 389px;
}

.h-390 {
  height: 390%;
}

.lh-390 {
  line-height: 390%;
}

.h-390px {
  height: 390px;
}

.lh-390px {
  line-height: 390px;
}

.h-391 {
  height: 391%;
}

.lh-391 {
  line-height: 391%;
}

.h-391px {
  height: 391px;
}

.lh-391px {
  line-height: 391px;
}

.h-392 {
  height: 392%;
}

.lh-392 {
  line-height: 392%;
}

.h-392px {
  height: 392px;
}

.lh-392px {
  line-height: 392px;
}

.h-393 {
  height: 393%;
}

.lh-393 {
  line-height: 393%;
}

.h-393px {
  height: 393px;
}

.lh-393px {
  line-height: 393px;
}

.h-394 {
  height: 394%;
}

.lh-394 {
  line-height: 394%;
}

.h-394px {
  height: 394px;
}

.lh-394px {
  line-height: 394px;
}

.h-395 {
  height: 395%;
}

.lh-395 {
  line-height: 395%;
}

.h-395px {
  height: 395px;
}

.lh-395px {
  line-height: 395px;
}

.h-396 {
  height: 396%;
}

.lh-396 {
  line-height: 396%;
}

.h-396px {
  height: 396px;
}

.lh-396px {
  line-height: 396px;
}

.h-397 {
  height: 397%;
}

.lh-397 {
  line-height: 397%;
}

.h-397px {
  height: 397px;
}

.lh-397px {
  line-height: 397px;
}

.h-398 {
  height: 398%;
}

.lh-398 {
  line-height: 398%;
}

.h-398px {
  height: 398px;
}

.lh-398px {
  line-height: 398px;
}

.h-399 {
  height: 399%;
}

.lh-399 {
  line-height: 399%;
}

.h-399px {
  height: 399px;
}

.lh-399px {
  line-height: 399px;
}

.h-400 {
  height: 400%;
}

.lh-400 {
  line-height: 400%;
}

.h-400px {
  height: 400px;
}

.lh-400px {
  line-height: 400px;
}

.h-401 {
  height: 401%;
}

.lh-401 {
  line-height: 401%;
}

.h-401px {
  height: 401px;
}

.lh-401px {
  line-height: 401px;
}

.h-402 {
  height: 402%;
}

.lh-402 {
  line-height: 402%;
}

.h-402px {
  height: 402px;
}

.lh-402px {
  line-height: 402px;
}

.h-403 {
  height: 403%;
}

.lh-403 {
  line-height: 403%;
}

.h-403px {
  height: 403px;
}

.lh-403px {
  line-height: 403px;
}

.h-404 {
  height: 404%;
}

.lh-404 {
  line-height: 404%;
}

.h-404px {
  height: 404px;
}

.lh-404px {
  line-height: 404px;
}

.h-405 {
  height: 405%;
}

.lh-405 {
  line-height: 405%;
}

.h-405px {
  height: 405px;
}

.lh-405px {
  line-height: 405px;
}

.h-406 {
  height: 406%;
}

.lh-406 {
  line-height: 406%;
}

.h-406px {
  height: 406px;
}

.lh-406px {
  line-height: 406px;
}

.h-407 {
  height: 407%;
}

.lh-407 {
  line-height: 407%;
}

.h-407px {
  height: 407px;
}

.lh-407px {
  line-height: 407px;
}

.h-408 {
  height: 408%;
}

.lh-408 {
  line-height: 408%;
}

.h-408px {
  height: 408px;
}

.lh-408px {
  line-height: 408px;
}

.h-409 {
  height: 409%;
}

.lh-409 {
  line-height: 409%;
}

.h-409px {
  height: 409px;
}

.lh-409px {
  line-height: 409px;
}

.h-410 {
  height: 410%;
}

.lh-410 {
  line-height: 410%;
}

.h-410px {
  height: 410px;
}

.lh-410px {
  line-height: 410px;
}

.h-411 {
  height: 411%;
}

.lh-411 {
  line-height: 411%;
}

.h-411px {
  height: 411px;
}

.lh-411px {
  line-height: 411px;
}

.h-412 {
  height: 412%;
}

.lh-412 {
  line-height: 412%;
}

.h-412px {
  height: 412px;
}

.lh-412px {
  line-height: 412px;
}

.h-413 {
  height: 413%;
}

.lh-413 {
  line-height: 413%;
}

.h-413px {
  height: 413px;
}

.lh-413px {
  line-height: 413px;
}

.h-414 {
  height: 414%;
}

.lh-414 {
  line-height: 414%;
}

.h-414px {
  height: 414px;
}

.lh-414px {
  line-height: 414px;
}

.h-415 {
  height: 415%;
}

.lh-415 {
  line-height: 415%;
}

.h-415px {
  height: 415px;
}

.lh-415px {
  line-height: 415px;
}

.h-416 {
  height: 416%;
}

.lh-416 {
  line-height: 416%;
}

.h-416px {
  height: 416px;
}

.lh-416px {
  line-height: 416px;
}

.h-417 {
  height: 417%;
}

.lh-417 {
  line-height: 417%;
}

.h-417px {
  height: 417px;
}

.lh-417px {
  line-height: 417px;
}

.h-418 {
  height: 418%;
}

.lh-418 {
  line-height: 418%;
}

.h-418px {
  height: 418px;
}

.lh-418px {
  line-height: 418px;
}

.h-419 {
  height: 419%;
}

.lh-419 {
  line-height: 419%;
}

.h-419px {
  height: 419px;
}

.lh-419px {
  line-height: 419px;
}

.h-420 {
  height: 420%;
}

.lh-420 {
  line-height: 420%;
}

.h-420px {
  height: 420px;
}

.lh-420px {
  line-height: 420px;
}

.h-421 {
  height: 421%;
}

.lh-421 {
  line-height: 421%;
}

.h-421px {
  height: 421px;
}

.lh-421px {
  line-height: 421px;
}

.h-422 {
  height: 422%;
}

.lh-422 {
  line-height: 422%;
}

.h-422px {
  height: 422px;
}

.lh-422px {
  line-height: 422px;
}

.h-423 {
  height: 423%;
}

.lh-423 {
  line-height: 423%;
}

.h-423px {
  height: 423px;
}

.lh-423px {
  line-height: 423px;
}

.h-424 {
  height: 424%;
}

.lh-424 {
  line-height: 424%;
}

.h-424px {
  height: 424px;
}

.lh-424px {
  line-height: 424px;
}

.h-425 {
  height: 425%;
}

.lh-425 {
  line-height: 425%;
}

.h-425px {
  height: 425px;
}

.lh-425px {
  line-height: 425px;
}

.h-426 {
  height: 426%;
}

.lh-426 {
  line-height: 426%;
}

.h-426px {
  height: 426px;
}

.lh-426px {
  line-height: 426px;
}

.h-427 {
  height: 427%;
}

.lh-427 {
  line-height: 427%;
}

.h-427px {
  height: 427px;
}

.lh-427px {
  line-height: 427px;
}

.h-428 {
  height: 428%;
}

.lh-428 {
  line-height: 428%;
}

.h-428px {
  height: 428px;
}

.lh-428px {
  line-height: 428px;
}

.h-429 {
  height: 429%;
}

.lh-429 {
  line-height: 429%;
}

.h-429px {
  height: 429px;
}

.lh-429px {
  line-height: 429px;
}

.h-430 {
  height: 430%;
}

.lh-430 {
  line-height: 430%;
}

.h-430px {
  height: 430px;
}

.lh-430px {
  line-height: 430px;
}

.h-431 {
  height: 431%;
}

.lh-431 {
  line-height: 431%;
}

.h-431px {
  height: 431px;
}

.lh-431px {
  line-height: 431px;
}

.h-432 {
  height: 432%;
}

.lh-432 {
  line-height: 432%;
}

.h-432px {
  height: 432px;
}

.lh-432px {
  line-height: 432px;
}

.h-433 {
  height: 433%;
}

.lh-433 {
  line-height: 433%;
}

.h-433px {
  height: 433px;
}

.lh-433px {
  line-height: 433px;
}

.h-434 {
  height: 434%;
}

.lh-434 {
  line-height: 434%;
}

.h-434px {
  height: 434px;
}

.lh-434px {
  line-height: 434px;
}

.h-435 {
  height: 435%;
}

.lh-435 {
  line-height: 435%;
}

.h-435px {
  height: 435px;
}

.lh-435px {
  line-height: 435px;
}

.h-436 {
  height: 436%;
}

.lh-436 {
  line-height: 436%;
}

.h-436px {
  height: 436px;
}

.lh-436px {
  line-height: 436px;
}

.h-437 {
  height: 437%;
}

.lh-437 {
  line-height: 437%;
}

.h-437px {
  height: 437px;
}

.lh-437px {
  line-height: 437px;
}

.h-438 {
  height: 438%;
}

.lh-438 {
  line-height: 438%;
}

.h-438px {
  height: 438px;
}

.lh-438px {
  line-height: 438px;
}

.h-439 {
  height: 439%;
}

.lh-439 {
  line-height: 439%;
}

.h-439px {
  height: 439px;
}

.lh-439px {
  line-height: 439px;
}

.h-440 {
  height: 440%;
}

.lh-440 {
  line-height: 440%;
}

.h-440px {
  height: 440px;
}

.lh-440px {
  line-height: 440px;
}

.h-441 {
  height: 441%;
}

.lh-441 {
  line-height: 441%;
}

.h-441px {
  height: 441px;
}

.lh-441px {
  line-height: 441px;
}

.h-442 {
  height: 442%;
}

.lh-442 {
  line-height: 442%;
}

.h-442px {
  height: 442px;
}

.lh-442px {
  line-height: 442px;
}

.h-443 {
  height: 443%;
}

.lh-443 {
  line-height: 443%;
}

.h-443px {
  height: 443px;
}

.lh-443px {
  line-height: 443px;
}

.h-444 {
  height: 444%;
}

.lh-444 {
  line-height: 444%;
}

.h-444px {
  height: 444px;
}

.lh-444px {
  line-height: 444px;
}

.h-445 {
  height: 445%;
}

.lh-445 {
  line-height: 445%;
}

.h-445px {
  height: 445px;
}

.lh-445px {
  line-height: 445px;
}

.h-446 {
  height: 446%;
}

.lh-446 {
  line-height: 446%;
}

.h-446px {
  height: 446px;
}

.lh-446px {
  line-height: 446px;
}

.h-447 {
  height: 447%;
}

.lh-447 {
  line-height: 447%;
}

.h-447px {
  height: 447px;
}

.lh-447px {
  line-height: 447px;
}

.h-448 {
  height: 448%;
}

.lh-448 {
  line-height: 448%;
}

.h-448px {
  height: 448px;
}

.lh-448px {
  line-height: 448px;
}

.h-449 {
  height: 449%;
}

.lh-449 {
  line-height: 449%;
}

.h-449px {
  height: 449px;
}

.lh-449px {
  line-height: 449px;
}

.h-450 {
  height: 450%;
}

.lh-450 {
  line-height: 450%;
}

.h-450px {
  height: 450px;
}

.lh-450px {
  line-height: 450px;
}

.h-451 {
  height: 451%;
}

.lh-451 {
  line-height: 451%;
}

.h-451px {
  height: 451px;
}

.lh-451px {
  line-height: 451px;
}

.h-452 {
  height: 452%;
}

.lh-452 {
  line-height: 452%;
}

.h-452px {
  height: 452px;
}

.lh-452px {
  line-height: 452px;
}

.h-453 {
  height: 453%;
}

.lh-453 {
  line-height: 453%;
}

.h-453px {
  height: 453px;
}

.lh-453px {
  line-height: 453px;
}

.h-454 {
  height: 454%;
}

.lh-454 {
  line-height: 454%;
}

.h-454px {
  height: 454px;
}

.lh-454px {
  line-height: 454px;
}

.h-455 {
  height: 455%;
}

.lh-455 {
  line-height: 455%;
}

.h-455px {
  height: 455px;
}

.lh-455px {
  line-height: 455px;
}

.h-456 {
  height: 456%;
}

.lh-456 {
  line-height: 456%;
}

.h-456px {
  height: 456px;
}

.lh-456px {
  line-height: 456px;
}

.h-457 {
  height: 457%;
}

.lh-457 {
  line-height: 457%;
}

.h-457px {
  height: 457px;
}

.lh-457px {
  line-height: 457px;
}

.h-458 {
  height: 458%;
}

.lh-458 {
  line-height: 458%;
}

.h-458px {
  height: 458px;
}

.lh-458px {
  line-height: 458px;
}

.h-459 {
  height: 459%;
}

.lh-459 {
  line-height: 459%;
}

.h-459px {
  height: 459px;
}

.lh-459px {
  line-height: 459px;
}

.h-460 {
  height: 460%;
}

.lh-460 {
  line-height: 460%;
}

.h-460px {
  height: 460px;
}

.lh-460px {
  line-height: 460px;
}

.h-461 {
  height: 461%;
}

.lh-461 {
  line-height: 461%;
}

.h-461px {
  height: 461px;
}

.lh-461px {
  line-height: 461px;
}

.h-462 {
  height: 462%;
}

.lh-462 {
  line-height: 462%;
}

.h-462px {
  height: 462px;
}

.lh-462px {
  line-height: 462px;
}

.h-463 {
  height: 463%;
}

.lh-463 {
  line-height: 463%;
}

.h-463px {
  height: 463px;
}

.lh-463px {
  line-height: 463px;
}

.h-464 {
  height: 464%;
}

.lh-464 {
  line-height: 464%;
}

.h-464px {
  height: 464px;
}

.lh-464px {
  line-height: 464px;
}

.h-465 {
  height: 465%;
}

.lh-465 {
  line-height: 465%;
}

.h-465px {
  height: 465px;
}

.lh-465px {
  line-height: 465px;
}

.h-466 {
  height: 466%;
}

.lh-466 {
  line-height: 466%;
}

.h-466px {
  height: 466px;
}

.lh-466px {
  line-height: 466px;
}

.h-467 {
  height: 467%;
}

.lh-467 {
  line-height: 467%;
}

.h-467px {
  height: 467px;
}

.lh-467px {
  line-height: 467px;
}

.h-468 {
  height: 468%;
}

.lh-468 {
  line-height: 468%;
}

.h-468px {
  height: 468px;
}

.lh-468px {
  line-height: 468px;
}

.h-469 {
  height: 469%;
}

.lh-469 {
  line-height: 469%;
}

.h-469px {
  height: 469px;
}

.lh-469px {
  line-height: 469px;
}

.h-470 {
  height: 470%;
}

.lh-470 {
  line-height: 470%;
}

.h-470px {
  height: 470px;
}

.lh-470px {
  line-height: 470px;
}

.h-471 {
  height: 471%;
}

.lh-471 {
  line-height: 471%;
}

.h-471px {
  height: 471px;
}

.lh-471px {
  line-height: 471px;
}

.h-472 {
  height: 472%;
}

.lh-472 {
  line-height: 472%;
}

.h-472px {
  height: 472px;
}

.lh-472px {
  line-height: 472px;
}

.h-473 {
  height: 473%;
}

.lh-473 {
  line-height: 473%;
}

.h-473px {
  height: 473px;
}

.lh-473px {
  line-height: 473px;
}

.h-474 {
  height: 474%;
}

.lh-474 {
  line-height: 474%;
}

.h-474px {
  height: 474px;
}

.lh-474px {
  line-height: 474px;
}

.h-475 {
  height: 475%;
}

.lh-475 {
  line-height: 475%;
}

.h-475px {
  height: 475px;
}

.lh-475px {
  line-height: 475px;
}

.h-476 {
  height: 476%;
}

.lh-476 {
  line-height: 476%;
}

.h-476px {
  height: 476px;
}

.lh-476px {
  line-height: 476px;
}

.h-477 {
  height: 477%;
}

.lh-477 {
  line-height: 477%;
}

.h-477px {
  height: 477px;
}

.lh-477px {
  line-height: 477px;
}

.h-478 {
  height: 478%;
}

.lh-478 {
  line-height: 478%;
}

.h-478px {
  height: 478px;
}

.lh-478px {
  line-height: 478px;
}

.h-479 {
  height: 479%;
}

.lh-479 {
  line-height: 479%;
}

.h-479px {
  height: 479px;
}

.lh-479px {
  line-height: 479px;
}

.h-480 {
  height: 480%;
}

.lh-480 {
  line-height: 480%;
}

.h-480px {
  height: 480px;
}

.lh-480px {
  line-height: 480px;
}

.h-481 {
  height: 481%;
}

.lh-481 {
  line-height: 481%;
}

.h-481px {
  height: 481px;
}

.lh-481px {
  line-height: 481px;
}

.h-482 {
  height: 482%;
}

.lh-482 {
  line-height: 482%;
}

.h-482px {
  height: 482px;
}

.lh-482px {
  line-height: 482px;
}

.h-483 {
  height: 483%;
}

.lh-483 {
  line-height: 483%;
}

.h-483px {
  height: 483px;
}

.lh-483px {
  line-height: 483px;
}

.h-484 {
  height: 484%;
}

.lh-484 {
  line-height: 484%;
}

.h-484px {
  height: 484px;
}

.lh-484px {
  line-height: 484px;
}

.h-485 {
  height: 485%;
}

.lh-485 {
  line-height: 485%;
}

.h-485px {
  height: 485px;
}

.lh-485px {
  line-height: 485px;
}

.h-486 {
  height: 486%;
}

.lh-486 {
  line-height: 486%;
}

.h-486px {
  height: 486px;
}

.lh-486px {
  line-height: 486px;
}

.h-487 {
  height: 487%;
}

.lh-487 {
  line-height: 487%;
}

.h-487px {
  height: 487px;
}

.lh-487px {
  line-height: 487px;
}

.h-488 {
  height: 488%;
}

.lh-488 {
  line-height: 488%;
}

.h-488px {
  height: 488px;
}

.lh-488px {
  line-height: 488px;
}

.h-489 {
  height: 489%;
}

.lh-489 {
  line-height: 489%;
}

.h-489px {
  height: 489px;
}

.lh-489px {
  line-height: 489px;
}

.h-490 {
  height: 490%;
}

.lh-490 {
  line-height: 490%;
}

.h-490px {
  height: 490px;
}

.lh-490px {
  line-height: 490px;
}

.h-491 {
  height: 491%;
}

.lh-491 {
  line-height: 491%;
}

.h-491px {
  height: 491px;
}

.lh-491px {
  line-height: 491px;
}

.h-492 {
  height: 492%;
}

.lh-492 {
  line-height: 492%;
}

.h-492px {
  height: 492px;
}

.lh-492px {
  line-height: 492px;
}

.h-493 {
  height: 493%;
}

.lh-493 {
  line-height: 493%;
}

.h-493px {
  height: 493px;
}

.lh-493px {
  line-height: 493px;
}

.h-494 {
  height: 494%;
}

.lh-494 {
  line-height: 494%;
}

.h-494px {
  height: 494px;
}

.lh-494px {
  line-height: 494px;
}

.h-495 {
  height: 495%;
}

.lh-495 {
  line-height: 495%;
}

.h-495px {
  height: 495px;
}

.lh-495px {
  line-height: 495px;
}

.h-496 {
  height: 496%;
}

.lh-496 {
  line-height: 496%;
}

.h-496px {
  height: 496px;
}

.lh-496px {
  line-height: 496px;
}

.h-497 {
  height: 497%;
}

.lh-497 {
  line-height: 497%;
}

.h-497px {
  height: 497px;
}

.lh-497px {
  line-height: 497px;
}

.h-498 {
  height: 498%;
}

.lh-498 {
  line-height: 498%;
}

.h-498px {
  height: 498px;
}

.lh-498px {
  line-height: 498px;
}

.h-499 {
  height: 499%;
}

.lh-499 {
  line-height: 499%;
}

.h-499px {
  height: 499px;
}

.lh-499px {
  line-height: 499px;
}

.h-500 {
  height: 500%;
}

.lh-500 {
  line-height: 500%;
}

.h-500px {
  height: 500px;
}

.lh-500px {
  line-height: 500px;
}

.h-501 {
  height: 501%;
}

.lh-501 {
  line-height: 501%;
}

.h-501px {
  height: 501px;
}

.lh-501px {
  line-height: 501px;
}

.h-502 {
  height: 502%;
}

.lh-502 {
  line-height: 502%;
}

.h-502px {
  height: 502px;
}

.lh-502px {
  line-height: 502px;
}

.h-503 {
  height: 503%;
}

.lh-503 {
  line-height: 503%;
}

.h-503px {
  height: 503px;
}

.lh-503px {
  line-height: 503px;
}

.h-504 {
  height: 504%;
}

.lh-504 {
  line-height: 504%;
}

.h-504px {
  height: 504px;
}

.lh-504px {
  line-height: 504px;
}

.h-505 {
  height: 505%;
}

.lh-505 {
  line-height: 505%;
}

.h-505px {
  height: 505px;
}

.lh-505px {
  line-height: 505px;
}

.h-506 {
  height: 506%;
}

.lh-506 {
  line-height: 506%;
}

.h-506px {
  height: 506px;
}

.lh-506px {
  line-height: 506px;
}

.h-507 {
  height: 507%;
}

.lh-507 {
  line-height: 507%;
}

.h-507px {
  height: 507px;
}

.lh-507px {
  line-height: 507px;
}

.h-508 {
  height: 508%;
}

.lh-508 {
  line-height: 508%;
}

.h-508px {
  height: 508px;
}

.lh-508px {
  line-height: 508px;
}

.h-509 {
  height: 509%;
}

.lh-509 {
  line-height: 509%;
}

.h-509px {
  height: 509px;
}

.lh-509px {
  line-height: 509px;
}

.h-510 {
  height: 510%;
}

.lh-510 {
  line-height: 510%;
}

.h-510px {
  height: 510px;
}

.lh-510px {
  line-height: 510px;
}

.h-511 {
  height: 511%;
}

.lh-511 {
  line-height: 511%;
}

.h-511px {
  height: 511px;
}

.lh-511px {
  line-height: 511px;
}

.h-512 {
  height: 512%;
}

.lh-512 {
  line-height: 512%;
}

.h-512px {
  height: 512px;
}

.lh-512px {
  line-height: 512px;
}

.h-513 {
  height: 513%;
}

.lh-513 {
  line-height: 513%;
}

.h-513px {
  height: 513px;
}

.lh-513px {
  line-height: 513px;
}

.h-514 {
  height: 514%;
}

.lh-514 {
  line-height: 514%;
}

.h-514px {
  height: 514px;
}

.lh-514px {
  line-height: 514px;
}

.h-515 {
  height: 515%;
}

.lh-515 {
  line-height: 515%;
}

.h-515px {
  height: 515px;
}

.lh-515px {
  line-height: 515px;
}

.h-516 {
  height: 516%;
}

.lh-516 {
  line-height: 516%;
}

.h-516px {
  height: 516px;
}

.lh-516px {
  line-height: 516px;
}

.h-517 {
  height: 517%;
}

.lh-517 {
  line-height: 517%;
}

.h-517px {
  height: 517px;
}

.lh-517px {
  line-height: 517px;
}

.h-518 {
  height: 518%;
}

.lh-518 {
  line-height: 518%;
}

.h-518px {
  height: 518px;
}

.lh-518px {
  line-height: 518px;
}

.h-519 {
  height: 519%;
}

.lh-519 {
  line-height: 519%;
}

.h-519px {
  height: 519px;
}

.lh-519px {
  line-height: 519px;
}

.h-520 {
  height: 520%;
}

.lh-520 {
  line-height: 520%;
}

.h-520px {
  height: 520px;
}

.lh-520px {
  line-height: 520px;
}

.h-521 {
  height: 521%;
}

.lh-521 {
  line-height: 521%;
}

.h-521px {
  height: 521px;
}

.lh-521px {
  line-height: 521px;
}

.h-522 {
  height: 522%;
}

.lh-522 {
  line-height: 522%;
}

.h-522px {
  height: 522px;
}

.lh-522px {
  line-height: 522px;
}

.h-523 {
  height: 523%;
}

.lh-523 {
  line-height: 523%;
}

.h-523px {
  height: 523px;
}

.lh-523px {
  line-height: 523px;
}

.h-524 {
  height: 524%;
}

.lh-524 {
  line-height: 524%;
}

.h-524px {
  height: 524px;
}

.lh-524px {
  line-height: 524px;
}

.h-525 {
  height: 525%;
}

.lh-525 {
  line-height: 525%;
}

.h-525px {
  height: 525px;
}

.lh-525px {
  line-height: 525px;
}

.h-526 {
  height: 526%;
}

.lh-526 {
  line-height: 526%;
}

.h-526px {
  height: 526px;
}

.lh-526px {
  line-height: 526px;
}

.h-527 {
  height: 527%;
}

.lh-527 {
  line-height: 527%;
}

.h-527px {
  height: 527px;
}

.lh-527px {
  line-height: 527px;
}

.h-528 {
  height: 528%;
}

.lh-528 {
  line-height: 528%;
}

.h-528px {
  height: 528px;
}

.lh-528px {
  line-height: 528px;
}

.h-529 {
  height: 529%;
}

.lh-529 {
  line-height: 529%;
}

.h-529px {
  height: 529px;
}

.lh-529px {
  line-height: 529px;
}

.h-530 {
  height: 530%;
}

.lh-530 {
  line-height: 530%;
}

.h-530px {
  height: 530px;
}

.lh-530px {
  line-height: 530px;
}

.h-531 {
  height: 531%;
}

.lh-531 {
  line-height: 531%;
}

.h-531px {
  height: 531px;
}

.lh-531px {
  line-height: 531px;
}

.h-532 {
  height: 532%;
}

.lh-532 {
  line-height: 532%;
}

.h-532px {
  height: 532px;
}

.lh-532px {
  line-height: 532px;
}

.h-533 {
  height: 533%;
}

.lh-533 {
  line-height: 533%;
}

.h-533px {
  height: 533px;
}

.lh-533px {
  line-height: 533px;
}

.h-534 {
  height: 534%;
}

.lh-534 {
  line-height: 534%;
}

.h-534px {
  height: 534px;
}

.lh-534px {
  line-height: 534px;
}

.h-535 {
  height: 535%;
}

.lh-535 {
  line-height: 535%;
}

.h-535px {
  height: 535px;
}

.lh-535px {
  line-height: 535px;
}

.h-536 {
  height: 536%;
}

.lh-536 {
  line-height: 536%;
}

.h-536px {
  height: 536px;
}

.lh-536px {
  line-height: 536px;
}

.h-537 {
  height: 537%;
}

.lh-537 {
  line-height: 537%;
}

.h-537px {
  height: 537px;
}

.lh-537px {
  line-height: 537px;
}

.h-538 {
  height: 538%;
}

.lh-538 {
  line-height: 538%;
}

.h-538px {
  height: 538px;
}

.lh-538px {
  line-height: 538px;
}

.h-539 {
  height: 539%;
}

.lh-539 {
  line-height: 539%;
}

.h-539px {
  height: 539px;
}

.lh-539px {
  line-height: 539px;
}

.h-540 {
  height: 540%;
}

.lh-540 {
  line-height: 540%;
}

.h-540px {
  height: 540px;
}

.lh-540px {
  line-height: 540px;
}

.h-541 {
  height: 541%;
}

.lh-541 {
  line-height: 541%;
}

.h-541px {
  height: 541px;
}

.lh-541px {
  line-height: 541px;
}

.h-542 {
  height: 542%;
}

.lh-542 {
  line-height: 542%;
}

.h-542px {
  height: 542px;
}

.lh-542px {
  line-height: 542px;
}

.h-543 {
  height: 543%;
}

.lh-543 {
  line-height: 543%;
}

.h-543px {
  height: 543px;
}

.lh-543px {
  line-height: 543px;
}

.h-544 {
  height: 544%;
}

.lh-544 {
  line-height: 544%;
}

.h-544px {
  height: 544px;
}

.lh-544px {
  line-height: 544px;
}

.h-545 {
  height: 545%;
}

.lh-545 {
  line-height: 545%;
}

.h-545px {
  height: 545px;
}

.lh-545px {
  line-height: 545px;
}

.h-546 {
  height: 546%;
}

.lh-546 {
  line-height: 546%;
}

.h-546px {
  height: 546px;
}

.lh-546px {
  line-height: 546px;
}

.h-547 {
  height: 547%;
}

.lh-547 {
  line-height: 547%;
}

.h-547px {
  height: 547px;
}

.lh-547px {
  line-height: 547px;
}

.h-548 {
  height: 548%;
}

.lh-548 {
  line-height: 548%;
}

.h-548px {
  height: 548px;
}

.lh-548px {
  line-height: 548px;
}

.h-549 {
  height: 549%;
}

.lh-549 {
  line-height: 549%;
}

.h-549px {
  height: 549px;
}

.lh-549px {
  line-height: 549px;
}

.h-550 {
  height: 550%;
}

.lh-550 {
  line-height: 550%;
}

.h-550px {
  height: 550px;
}

.lh-550px {
  line-height: 550px;
}

.h-551 {
  height: 551%;
}

.lh-551 {
  line-height: 551%;
}

.h-551px {
  height: 551px;
}

.lh-551px {
  line-height: 551px;
}

.h-552 {
  height: 552%;
}

.lh-552 {
  line-height: 552%;
}

.h-552px {
  height: 552px;
}

.lh-552px {
  line-height: 552px;
}

.h-553 {
  height: 553%;
}

.lh-553 {
  line-height: 553%;
}

.h-553px {
  height: 553px;
}

.lh-553px {
  line-height: 553px;
}

.h-554 {
  height: 554%;
}

.lh-554 {
  line-height: 554%;
}

.h-554px {
  height: 554px;
}

.lh-554px {
  line-height: 554px;
}

.h-555 {
  height: 555%;
}

.lh-555 {
  line-height: 555%;
}

.h-555px {
  height: 555px;
}

.lh-555px {
  line-height: 555px;
}

.h-556 {
  height: 556%;
}

.lh-556 {
  line-height: 556%;
}

.h-556px {
  height: 556px;
}

.lh-556px {
  line-height: 556px;
}

.h-557 {
  height: 557%;
}

.lh-557 {
  line-height: 557%;
}

.h-557px {
  height: 557px;
}

.lh-557px {
  line-height: 557px;
}

.h-558 {
  height: 558%;
}

.lh-558 {
  line-height: 558%;
}

.h-558px {
  height: 558px;
}

.lh-558px {
  line-height: 558px;
}

.h-559 {
  height: 559%;
}

.lh-559 {
  line-height: 559%;
}

.h-559px {
  height: 559px;
}

.lh-559px {
  line-height: 559px;
}

.h-560 {
  height: 560%;
}

.lh-560 {
  line-height: 560%;
}

.h-560px {
  height: 560px;
}

.lh-560px {
  line-height: 560px;
}

.h-561 {
  height: 561%;
}

.lh-561 {
  line-height: 561%;
}

.h-561px {
  height: 561px;
}

.lh-561px {
  line-height: 561px;
}

.h-562 {
  height: 562%;
}

.lh-562 {
  line-height: 562%;
}

.h-562px {
  height: 562px;
}

.lh-562px {
  line-height: 562px;
}

.h-563 {
  height: 563%;
}

.lh-563 {
  line-height: 563%;
}

.h-563px {
  height: 563px;
}

.lh-563px {
  line-height: 563px;
}

.h-564 {
  height: 564%;
}

.lh-564 {
  line-height: 564%;
}

.h-564px {
  height: 564px;
}

.lh-564px {
  line-height: 564px;
}

.h-565 {
  height: 565%;
}

.lh-565 {
  line-height: 565%;
}

.h-565px {
  height: 565px;
}

.lh-565px {
  line-height: 565px;
}

.h-566 {
  height: 566%;
}

.lh-566 {
  line-height: 566%;
}

.h-566px {
  height: 566px;
}

.lh-566px {
  line-height: 566px;
}

.h-567 {
  height: 567%;
}

.lh-567 {
  line-height: 567%;
}

.h-567px {
  height: 567px;
}

.lh-567px {
  line-height: 567px;
}

.h-568 {
  height: 568%;
}

.lh-568 {
  line-height: 568%;
}

.h-568px {
  height: 568px;
}

.lh-568px {
  line-height: 568px;
}

.h-569 {
  height: 569%;
}

.lh-569 {
  line-height: 569%;
}

.h-569px {
  height: 569px;
}

.lh-569px {
  line-height: 569px;
}

.h-570 {
  height: 570%;
}

.lh-570 {
  line-height: 570%;
}

.h-570px {
  height: 570px;
}

.lh-570px {
  line-height: 570px;
}

.h-571 {
  height: 571%;
}

.lh-571 {
  line-height: 571%;
}

.h-571px {
  height: 571px;
}

.lh-571px {
  line-height: 571px;
}

.h-572 {
  height: 572%;
}

.lh-572 {
  line-height: 572%;
}

.h-572px {
  height: 572px;
}

.lh-572px {
  line-height: 572px;
}

.h-573 {
  height: 573%;
}

.lh-573 {
  line-height: 573%;
}

.h-573px {
  height: 573px;
}

.lh-573px {
  line-height: 573px;
}

.h-574 {
  height: 574%;
}

.lh-574 {
  line-height: 574%;
}

.h-574px {
  height: 574px;
}

.lh-574px {
  line-height: 574px;
}

.h-575 {
  height: 575%;
}

.lh-575 {
  line-height: 575%;
}

.h-575px {
  height: 575px;
}

.lh-575px {
  line-height: 575px;
}

.h-576 {
  height: 576%;
}

.lh-576 {
  line-height: 576%;
}

.h-576px {
  height: 576px;
}

.lh-576px {
  line-height: 576px;
}

.h-577 {
  height: 577%;
}

.lh-577 {
  line-height: 577%;
}

.h-577px {
  height: 577px;
}

.lh-577px {
  line-height: 577px;
}

.h-578 {
  height: 578%;
}

.lh-578 {
  line-height: 578%;
}

.h-578px {
  height: 578px;
}

.lh-578px {
  line-height: 578px;
}

.h-579 {
  height: 579%;
}

.lh-579 {
  line-height: 579%;
}

.h-579px {
  height: 579px;
}

.lh-579px {
  line-height: 579px;
}

.h-580 {
  height: 580%;
}

.lh-580 {
  line-height: 580%;
}

.h-580px {
  height: 580px;
}

.lh-580px {
  line-height: 580px;
}

.h-581 {
  height: 581%;
}

.lh-581 {
  line-height: 581%;
}

.h-581px {
  height: 581px;
}

.lh-581px {
  line-height: 581px;
}

.h-582 {
  height: 582%;
}

.lh-582 {
  line-height: 582%;
}

.h-582px {
  height: 582px;
}

.lh-582px {
  line-height: 582px;
}

.h-583 {
  height: 583%;
}

.lh-583 {
  line-height: 583%;
}

.h-583px {
  height: 583px;
}

.lh-583px {
  line-height: 583px;
}

.h-584 {
  height: 584%;
}

.lh-584 {
  line-height: 584%;
}

.h-584px {
  height: 584px;
}

.lh-584px {
  line-height: 584px;
}

.h-585 {
  height: 585%;
}

.lh-585 {
  line-height: 585%;
}

.h-585px {
  height: 585px;
}

.lh-585px {
  line-height: 585px;
}

.h-586 {
  height: 586%;
}

.lh-586 {
  line-height: 586%;
}

.h-586px {
  height: 586px;
}

.lh-586px {
  line-height: 586px;
}

.h-587 {
  height: 587%;
}

.lh-587 {
  line-height: 587%;
}

.h-587px {
  height: 587px;
}

.lh-587px {
  line-height: 587px;
}

.h-588 {
  height: 588%;
}

.lh-588 {
  line-height: 588%;
}

.h-588px {
  height: 588px;
}

.lh-588px {
  line-height: 588px;
}

.h-589 {
  height: 589%;
}

.lh-589 {
  line-height: 589%;
}

.h-589px {
  height: 589px;
}

.lh-589px {
  line-height: 589px;
}

.h-590 {
  height: 590%;
}

.lh-590 {
  line-height: 590%;
}

.h-590px {
  height: 590px;
}

.lh-590px {
  line-height: 590px;
}

.h-591 {
  height: 591%;
}

.lh-591 {
  line-height: 591%;
}

.h-591px {
  height: 591px;
}

.lh-591px {
  line-height: 591px;
}

.h-592 {
  height: 592%;
}

.lh-592 {
  line-height: 592%;
}

.h-592px {
  height: 592px;
}

.lh-592px {
  line-height: 592px;
}

.h-593 {
  height: 593%;
}

.lh-593 {
  line-height: 593%;
}

.h-593px {
  height: 593px;
}

.lh-593px {
  line-height: 593px;
}

.h-594 {
  height: 594%;
}

.lh-594 {
  line-height: 594%;
}

.h-594px {
  height: 594px;
}

.lh-594px {
  line-height: 594px;
}

.h-595 {
  height: 595%;
}

.lh-595 {
  line-height: 595%;
}

.h-595px {
  height: 595px;
}

.lh-595px {
  line-height: 595px;
}

.h-596 {
  height: 596%;
}

.lh-596 {
  line-height: 596%;
}

.h-596px {
  height: 596px;
}

.lh-596px {
  line-height: 596px;
}

.h-597 {
  height: 597%;
}

.lh-597 {
  line-height: 597%;
}

.h-597px {
  height: 597px;
}

.lh-597px {
  line-height: 597px;
}

.h-598 {
  height: 598%;
}

.lh-598 {
  line-height: 598%;
}

.h-598px {
  height: 598px;
}

.lh-598px {
  line-height: 598px;
}

.h-599 {
  height: 599%;
}

.lh-599 {
  line-height: 599%;
}

.h-599px {
  height: 599px;
}

.lh-599px {
  line-height: 599px;
}

.h-600 {
  height: 600%;
}

.lh-600 {
  line-height: 600%;
}

.h-600px {
  height: 600px;
}

.lh-600px {
  line-height: 600px;
}

.h-601 {
  height: 601%;
}

.lh-601 {
  line-height: 601%;
}

.h-601px {
  height: 601px;
}

.lh-601px {
  line-height: 601px;
}

.h-602 {
  height: 602%;
}

.lh-602 {
  line-height: 602%;
}

.h-602px {
  height: 602px;
}

.lh-602px {
  line-height: 602px;
}

.h-603 {
  height: 603%;
}

.lh-603 {
  line-height: 603%;
}

.h-603px {
  height: 603px;
}

.lh-603px {
  line-height: 603px;
}

.h-604 {
  height: 604%;
}

.lh-604 {
  line-height: 604%;
}

.h-604px {
  height: 604px;
}

.lh-604px {
  line-height: 604px;
}

.h-605 {
  height: 605%;
}

.lh-605 {
  line-height: 605%;
}

.h-605px {
  height: 605px;
}

.lh-605px {
  line-height: 605px;
}

.h-606 {
  height: 606%;
}

.lh-606 {
  line-height: 606%;
}

.h-606px {
  height: 606px;
}

.lh-606px {
  line-height: 606px;
}

.h-607 {
  height: 607%;
}

.lh-607 {
  line-height: 607%;
}

.h-607px {
  height: 607px;
}

.lh-607px {
  line-height: 607px;
}

.h-608 {
  height: 608%;
}

.lh-608 {
  line-height: 608%;
}

.h-608px {
  height: 608px;
}

.lh-608px {
  line-height: 608px;
}

.h-609 {
  height: 609%;
}

.lh-609 {
  line-height: 609%;
}

.h-609px {
  height: 609px;
}

.lh-609px {
  line-height: 609px;
}

.h-610 {
  height: 610%;
}

.lh-610 {
  line-height: 610%;
}

.h-610px {
  height: 610px;
}

.lh-610px {
  line-height: 610px;
}

.h-611 {
  height: 611%;
}

.lh-611 {
  line-height: 611%;
}

.h-611px {
  height: 611px;
}

.lh-611px {
  line-height: 611px;
}

.h-612 {
  height: 612%;
}

.lh-612 {
  line-height: 612%;
}

.h-612px {
  height: 612px;
}

.lh-612px {
  line-height: 612px;
}

.h-613 {
  height: 613%;
}

.lh-613 {
  line-height: 613%;
}

.h-613px {
  height: 613px;
}

.lh-613px {
  line-height: 613px;
}

.h-614 {
  height: 614%;
}

.lh-614 {
  line-height: 614%;
}

.h-614px {
  height: 614px;
}

.lh-614px {
  line-height: 614px;
}

.h-615 {
  height: 615%;
}

.lh-615 {
  line-height: 615%;
}

.h-615px {
  height: 615px;
}

.lh-615px {
  line-height: 615px;
}

.h-616 {
  height: 616%;
}

.lh-616 {
  line-height: 616%;
}

.h-616px {
  height: 616px;
}

.lh-616px {
  line-height: 616px;
}

.h-617 {
  height: 617%;
}

.lh-617 {
  line-height: 617%;
}

.h-617px {
  height: 617px;
}

.lh-617px {
  line-height: 617px;
}

.h-618 {
  height: 618%;
}

.lh-618 {
  line-height: 618%;
}

.h-618px {
  height: 618px;
}

.lh-618px {
  line-height: 618px;
}

.h-619 {
  height: 619%;
}

.lh-619 {
  line-height: 619%;
}

.h-619px {
  height: 619px;
}

.lh-619px {
  line-height: 619px;
}

.h-620 {
  height: 620%;
}

.lh-620 {
  line-height: 620%;
}

.h-620px {
  height: 620px;
}

.lh-620px {
  line-height: 620px;
}

.h-621 {
  height: 621%;
}

.lh-621 {
  line-height: 621%;
}

.h-621px {
  height: 621px;
}

.lh-621px {
  line-height: 621px;
}

.h-622 {
  height: 622%;
}

.lh-622 {
  line-height: 622%;
}

.h-622px {
  height: 622px;
}

.lh-622px {
  line-height: 622px;
}

.h-623 {
  height: 623%;
}

.lh-623 {
  line-height: 623%;
}

.h-623px {
  height: 623px;
}

.lh-623px {
  line-height: 623px;
}

.h-624 {
  height: 624%;
}

.lh-624 {
  line-height: 624%;
}

.h-624px {
  height: 624px;
}

.lh-624px {
  line-height: 624px;
}

.h-625 {
  height: 625%;
}

.lh-625 {
  line-height: 625%;
}

.h-625px {
  height: 625px;
}

.lh-625px {
  line-height: 625px;
}

.h-626 {
  height: 626%;
}

.lh-626 {
  line-height: 626%;
}

.h-626px {
  height: 626px;
}

.lh-626px {
  line-height: 626px;
}

.h-627 {
  height: 627%;
}

.lh-627 {
  line-height: 627%;
}

.h-627px {
  height: 627px;
}

.lh-627px {
  line-height: 627px;
}

.h-628 {
  height: 628%;
}

.lh-628 {
  line-height: 628%;
}

.h-628px {
  height: 628px;
}

.lh-628px {
  line-height: 628px;
}

.h-629 {
  height: 629%;
}

.lh-629 {
  line-height: 629%;
}

.h-629px {
  height: 629px;
}

.lh-629px {
  line-height: 629px;
}

.h-630 {
  height: 630%;
}

.lh-630 {
  line-height: 630%;
}

.h-630px {
  height: 630px;
}

.lh-630px {
  line-height: 630px;
}

.h-631 {
  height: 631%;
}

.lh-631 {
  line-height: 631%;
}

.h-631px {
  height: 631px;
}

.lh-631px {
  line-height: 631px;
}

.h-632 {
  height: 632%;
}

.lh-632 {
  line-height: 632%;
}

.h-632px {
  height: 632px;
}

.lh-632px {
  line-height: 632px;
}

.h-633 {
  height: 633%;
}

.lh-633 {
  line-height: 633%;
}

.h-633px {
  height: 633px;
}

.lh-633px {
  line-height: 633px;
}

.h-634 {
  height: 634%;
}

.lh-634 {
  line-height: 634%;
}

.h-634px {
  height: 634px;
}

.lh-634px {
  line-height: 634px;
}

.h-635 {
  height: 635%;
}

.lh-635 {
  line-height: 635%;
}

.h-635px {
  height: 635px;
}

.lh-635px {
  line-height: 635px;
}

.h-636 {
  height: 636%;
}

.lh-636 {
  line-height: 636%;
}

.h-636px {
  height: 636px;
}

.lh-636px {
  line-height: 636px;
}

.h-637 {
  height: 637%;
}

.lh-637 {
  line-height: 637%;
}

.h-637px {
  height: 637px;
}

.lh-637px {
  line-height: 637px;
}

.h-638 {
  height: 638%;
}

.lh-638 {
  line-height: 638%;
}

.h-638px {
  height: 638px;
}

.lh-638px {
  line-height: 638px;
}

.h-639 {
  height: 639%;
}

.lh-639 {
  line-height: 639%;
}

.h-639px {
  height: 639px;
}

.lh-639px {
  line-height: 639px;
}

.h-640 {
  height: 640%;
}

.lh-640 {
  line-height: 640%;
}

.h-640px {
  height: 640px;
}

.lh-640px {
  line-height: 640px;
}

.h-641 {
  height: 641%;
}

.lh-641 {
  line-height: 641%;
}

.h-641px {
  height: 641px;
}

.lh-641px {
  line-height: 641px;
}

.h-642 {
  height: 642%;
}

.lh-642 {
  line-height: 642%;
}

.h-642px {
  height: 642px;
}

.lh-642px {
  line-height: 642px;
}

.h-643 {
  height: 643%;
}

.lh-643 {
  line-height: 643%;
}

.h-643px {
  height: 643px;
}

.lh-643px {
  line-height: 643px;
}

.h-644 {
  height: 644%;
}

.lh-644 {
  line-height: 644%;
}

.h-644px {
  height: 644px;
}

.lh-644px {
  line-height: 644px;
}

.h-645 {
  height: 645%;
}

.lh-645 {
  line-height: 645%;
}

.h-645px {
  height: 645px;
}

.lh-645px {
  line-height: 645px;
}

.h-646 {
  height: 646%;
}

.lh-646 {
  line-height: 646%;
}

.h-646px {
  height: 646px;
}

.lh-646px {
  line-height: 646px;
}

.h-647 {
  height: 647%;
}

.lh-647 {
  line-height: 647%;
}

.h-647px {
  height: 647px;
}

.lh-647px {
  line-height: 647px;
}

.h-648 {
  height: 648%;
}

.lh-648 {
  line-height: 648%;
}

.h-648px {
  height: 648px;
}

.lh-648px {
  line-height: 648px;
}

.h-649 {
  height: 649%;
}

.lh-649 {
  line-height: 649%;
}

.h-649px {
  height: 649px;
}

.lh-649px {
  line-height: 649px;
}

.h-650 {
  height: 650%;
}

.lh-650 {
  line-height: 650%;
}

.h-650px {
  height: 650px;
}

.lh-650px {
  line-height: 650px;
}

.h-651 {
  height: 651%;
}

.lh-651 {
  line-height: 651%;
}

.h-651px {
  height: 651px;
}

.lh-651px {
  line-height: 651px;
}

.h-652 {
  height: 652%;
}

.lh-652 {
  line-height: 652%;
}

.h-652px {
  height: 652px;
}

.lh-652px {
  line-height: 652px;
}

.h-653 {
  height: 653%;
}

.lh-653 {
  line-height: 653%;
}

.h-653px {
  height: 653px;
}

.lh-653px {
  line-height: 653px;
}

.h-654 {
  height: 654%;
}

.lh-654 {
  line-height: 654%;
}

.h-654px {
  height: 654px;
}

.lh-654px {
  line-height: 654px;
}

.h-655 {
  height: 655%;
}

.lh-655 {
  line-height: 655%;
}

.h-655px {
  height: 655px;
}

.lh-655px {
  line-height: 655px;
}

.h-656 {
  height: 656%;
}

.lh-656 {
  line-height: 656%;
}

.h-656px {
  height: 656px;
}

.lh-656px {
  line-height: 656px;
}

.h-657 {
  height: 657%;
}

.lh-657 {
  line-height: 657%;
}

.h-657px {
  height: 657px;
}

.lh-657px {
  line-height: 657px;
}

.h-658 {
  height: 658%;
}

.lh-658 {
  line-height: 658%;
}

.h-658px {
  height: 658px;
}

.lh-658px {
  line-height: 658px;
}

.h-659 {
  height: 659%;
}

.lh-659 {
  line-height: 659%;
}

.h-659px {
  height: 659px;
}

.lh-659px {
  line-height: 659px;
}

.h-660 {
  height: 660%;
}

.lh-660 {
  line-height: 660%;
}

.h-660px {
  height: 660px;
}

.lh-660px {
  line-height: 660px;
}

.h-661 {
  height: 661%;
}

.lh-661 {
  line-height: 661%;
}

.h-661px {
  height: 661px;
}

.lh-661px {
  line-height: 661px;
}

.h-662 {
  height: 662%;
}

.lh-662 {
  line-height: 662%;
}

.h-662px {
  height: 662px;
}

.lh-662px {
  line-height: 662px;
}

.h-663 {
  height: 663%;
}

.lh-663 {
  line-height: 663%;
}

.h-663px {
  height: 663px;
}

.lh-663px {
  line-height: 663px;
}

.h-664 {
  height: 664%;
}

.lh-664 {
  line-height: 664%;
}

.h-664px {
  height: 664px;
}

.lh-664px {
  line-height: 664px;
}

.h-665 {
  height: 665%;
}

.lh-665 {
  line-height: 665%;
}

.h-665px {
  height: 665px;
}

.lh-665px {
  line-height: 665px;
}

.h-666 {
  height: 666%;
}

.lh-666 {
  line-height: 666%;
}

.h-666px {
  height: 666px;
}

.lh-666px {
  line-height: 666px;
}

.h-667 {
  height: 667%;
}

.lh-667 {
  line-height: 667%;
}

.h-667px {
  height: 667px;
}

.lh-667px {
  line-height: 667px;
}

.h-668 {
  height: 668%;
}

.lh-668 {
  line-height: 668%;
}

.h-668px {
  height: 668px;
}

.lh-668px {
  line-height: 668px;
}

.h-669 {
  height: 669%;
}

.lh-669 {
  line-height: 669%;
}

.h-669px {
  height: 669px;
}

.lh-669px {
  line-height: 669px;
}

.h-670 {
  height: 670%;
}

.lh-670 {
  line-height: 670%;
}

.h-670px {
  height: 670px;
}

.lh-670px {
  line-height: 670px;
}

.h-671 {
  height: 671%;
}

.lh-671 {
  line-height: 671%;
}

.h-671px {
  height: 671px;
}

.lh-671px {
  line-height: 671px;
}

.h-672 {
  height: 672%;
}

.lh-672 {
  line-height: 672%;
}

.h-672px {
  height: 672px;
}

.lh-672px {
  line-height: 672px;
}

.h-673 {
  height: 673%;
}

.lh-673 {
  line-height: 673%;
}

.h-673px {
  height: 673px;
}

.lh-673px {
  line-height: 673px;
}

.h-674 {
  height: 674%;
}

.lh-674 {
  line-height: 674%;
}

.h-674px {
  height: 674px;
}

.lh-674px {
  line-height: 674px;
}

.h-675 {
  height: 675%;
}

.lh-675 {
  line-height: 675%;
}

.h-675px {
  height: 675px;
}

.lh-675px {
  line-height: 675px;
}

.h-676 {
  height: 676%;
}

.lh-676 {
  line-height: 676%;
}

.h-676px {
  height: 676px;
}

.lh-676px {
  line-height: 676px;
}

.h-677 {
  height: 677%;
}

.lh-677 {
  line-height: 677%;
}

.h-677px {
  height: 677px;
}

.lh-677px {
  line-height: 677px;
}

.h-678 {
  height: 678%;
}

.lh-678 {
  line-height: 678%;
}

.h-678px {
  height: 678px;
}

.lh-678px {
  line-height: 678px;
}

.h-679 {
  height: 679%;
}

.lh-679 {
  line-height: 679%;
}

.h-679px {
  height: 679px;
}

.lh-679px {
  line-height: 679px;
}

.h-680 {
  height: 680%;
}

.lh-680 {
  line-height: 680%;
}

.h-680px {
  height: 680px;
}

.lh-680px {
  line-height: 680px;
}

.h-681 {
  height: 681%;
}

.lh-681 {
  line-height: 681%;
}

.h-681px {
  height: 681px;
}

.lh-681px {
  line-height: 681px;
}

.h-682 {
  height: 682%;
}

.lh-682 {
  line-height: 682%;
}

.h-682px {
  height: 682px;
}

.lh-682px {
  line-height: 682px;
}

.h-683 {
  height: 683%;
}

.lh-683 {
  line-height: 683%;
}

.h-683px {
  height: 683px;
}

.lh-683px {
  line-height: 683px;
}

.h-684 {
  height: 684%;
}

.lh-684 {
  line-height: 684%;
}

.h-684px {
  height: 684px;
}

.lh-684px {
  line-height: 684px;
}

.h-685 {
  height: 685%;
}

.lh-685 {
  line-height: 685%;
}

.h-685px {
  height: 685px;
}

.lh-685px {
  line-height: 685px;
}

.h-686 {
  height: 686%;
}

.lh-686 {
  line-height: 686%;
}

.h-686px {
  height: 686px;
}

.lh-686px {
  line-height: 686px;
}

.h-687 {
  height: 687%;
}

.lh-687 {
  line-height: 687%;
}

.h-687px {
  height: 687px;
}

.lh-687px {
  line-height: 687px;
}

.h-688 {
  height: 688%;
}

.lh-688 {
  line-height: 688%;
}

.h-688px {
  height: 688px;
}

.lh-688px {
  line-height: 688px;
}

.h-689 {
  height: 689%;
}

.lh-689 {
  line-height: 689%;
}

.h-689px {
  height: 689px;
}

.lh-689px {
  line-height: 689px;
}

.h-690 {
  height: 690%;
}

.lh-690 {
  line-height: 690%;
}

.h-690px {
  height: 690px;
}

.lh-690px {
  line-height: 690px;
}

.h-691 {
  height: 691%;
}

.lh-691 {
  line-height: 691%;
}

.h-691px {
  height: 691px;
}

.lh-691px {
  line-height: 691px;
}

.h-692 {
  height: 692%;
}

.lh-692 {
  line-height: 692%;
}

.h-692px {
  height: 692px;
}

.lh-692px {
  line-height: 692px;
}

.h-693 {
  height: 693%;
}

.lh-693 {
  line-height: 693%;
}

.h-693px {
  height: 693px;
}

.lh-693px {
  line-height: 693px;
}

.h-694 {
  height: 694%;
}

.lh-694 {
  line-height: 694%;
}

.h-694px {
  height: 694px;
}

.lh-694px {
  line-height: 694px;
}

.h-695 {
  height: 695%;
}

.lh-695 {
  line-height: 695%;
}

.h-695px {
  height: 695px;
}

.lh-695px {
  line-height: 695px;
}

.h-696 {
  height: 696%;
}

.lh-696 {
  line-height: 696%;
}

.h-696px {
  height: 696px;
}

.lh-696px {
  line-height: 696px;
}

.h-697 {
  height: 697%;
}

.lh-697 {
  line-height: 697%;
}

.h-697px {
  height: 697px;
}

.lh-697px {
  line-height: 697px;
}

.h-698 {
  height: 698%;
}

.lh-698 {
  line-height: 698%;
}

.h-698px {
  height: 698px;
}

.lh-698px {
  line-height: 698px;
}

.h-699 {
  height: 699%;
}

.lh-699 {
  line-height: 699%;
}

.h-699px {
  height: 699px;
}

.lh-699px {
  line-height: 699px;
}

.h-700 {
  height: 700%;
}

.lh-700 {
  line-height: 700%;
}

.h-700px {
  height: 700px;
}

.lh-700px {
  line-height: 700px;
}

.h-701 {
  height: 701%;
}

.lh-701 {
  line-height: 701%;
}

.h-701px {
  height: 701px;
}

.lh-701px {
  line-height: 701px;
}

.h-702 {
  height: 702%;
}

.lh-702 {
  line-height: 702%;
}

.h-702px {
  height: 702px;
}

.lh-702px {
  line-height: 702px;
}

.h-703 {
  height: 703%;
}

.lh-703 {
  line-height: 703%;
}

.h-703px {
  height: 703px;
}

.lh-703px {
  line-height: 703px;
}

.h-704 {
  height: 704%;
}

.lh-704 {
  line-height: 704%;
}

.h-704px {
  height: 704px;
}

.lh-704px {
  line-height: 704px;
}

.h-705 {
  height: 705%;
}

.lh-705 {
  line-height: 705%;
}

.h-705px {
  height: 705px;
}

.lh-705px {
  line-height: 705px;
}

.h-706 {
  height: 706%;
}

.lh-706 {
  line-height: 706%;
}

.h-706px {
  height: 706px;
}

.lh-706px {
  line-height: 706px;
}

.h-707 {
  height: 707%;
}

.lh-707 {
  line-height: 707%;
}

.h-707px {
  height: 707px;
}

.lh-707px {
  line-height: 707px;
}

.h-708 {
  height: 708%;
}

.lh-708 {
  line-height: 708%;
}

.h-708px {
  height: 708px;
}

.lh-708px {
  line-height: 708px;
}

.h-709 {
  height: 709%;
}

.lh-709 {
  line-height: 709%;
}

.h-709px {
  height: 709px;
}

.lh-709px {
  line-height: 709px;
}

.h-710 {
  height: 710%;
}

.lh-710 {
  line-height: 710%;
}

.h-710px {
  height: 710px;
}

.lh-710px {
  line-height: 710px;
}

.h-711 {
  height: 711%;
}

.lh-711 {
  line-height: 711%;
}

.h-711px {
  height: 711px;
}

.lh-711px {
  line-height: 711px;
}

.h-712 {
  height: 712%;
}

.lh-712 {
  line-height: 712%;
}

.h-712px {
  height: 712px;
}

.lh-712px {
  line-height: 712px;
}

.h-713 {
  height: 713%;
}

.lh-713 {
  line-height: 713%;
}

.h-713px {
  height: 713px;
}

.lh-713px {
  line-height: 713px;
}

.h-714 {
  height: 714%;
}

.lh-714 {
  line-height: 714%;
}

.h-714px {
  height: 714px;
}

.lh-714px {
  line-height: 714px;
}

.h-715 {
  height: 715%;
}

.lh-715 {
  line-height: 715%;
}

.h-715px {
  height: 715px;
}

.lh-715px {
  line-height: 715px;
}

.h-716 {
  height: 716%;
}

.lh-716 {
  line-height: 716%;
}

.h-716px {
  height: 716px;
}

.lh-716px {
  line-height: 716px;
}

.h-717 {
  height: 717%;
}

.lh-717 {
  line-height: 717%;
}

.h-717px {
  height: 717px;
}

.lh-717px {
  line-height: 717px;
}

.h-718 {
  height: 718%;
}

.lh-718 {
  line-height: 718%;
}

.h-718px {
  height: 718px;
}

.lh-718px {
  line-height: 718px;
}

.h-719 {
  height: 719%;
}

.lh-719 {
  line-height: 719%;
}

.h-719px {
  height: 719px;
}

.lh-719px {
  line-height: 719px;
}

.h-720 {
  height: 720%;
}

.lh-720 {
  line-height: 720%;
}

.h-720px {
  height: 720px;
}

.lh-720px {
  line-height: 720px;
}

.h-721 {
  height: 721%;
}

.lh-721 {
  line-height: 721%;
}

.h-721px {
  height: 721px;
}

.lh-721px {
  line-height: 721px;
}

.h-722 {
  height: 722%;
}

.lh-722 {
  line-height: 722%;
}

.h-722px {
  height: 722px;
}

.lh-722px {
  line-height: 722px;
}

.h-723 {
  height: 723%;
}

.lh-723 {
  line-height: 723%;
}

.h-723px {
  height: 723px;
}

.lh-723px {
  line-height: 723px;
}

.h-724 {
  height: 724%;
}

.lh-724 {
  line-height: 724%;
}

.h-724px {
  height: 724px;
}

.lh-724px {
  line-height: 724px;
}

.h-725 {
  height: 725%;
}

.lh-725 {
  line-height: 725%;
}

.h-725px {
  height: 725px;
}

.lh-725px {
  line-height: 725px;
}

.h-726 {
  height: 726%;
}

.lh-726 {
  line-height: 726%;
}

.h-726px {
  height: 726px;
}

.lh-726px {
  line-height: 726px;
}

.h-727 {
  height: 727%;
}

.lh-727 {
  line-height: 727%;
}

.h-727px {
  height: 727px;
}

.lh-727px {
  line-height: 727px;
}

.h-728 {
  height: 728%;
}

.lh-728 {
  line-height: 728%;
}

.h-728px {
  height: 728px;
}

.lh-728px {
  line-height: 728px;
}

.h-729 {
  height: 729%;
}

.lh-729 {
  line-height: 729%;
}

.h-729px {
  height: 729px;
}

.lh-729px {
  line-height: 729px;
}

.h-730 {
  height: 730%;
}

.lh-730 {
  line-height: 730%;
}

.h-730px {
  height: 730px;
}

.lh-730px {
  line-height: 730px;
}

.h-731 {
  height: 731%;
}

.lh-731 {
  line-height: 731%;
}

.h-731px {
  height: 731px;
}

.lh-731px {
  line-height: 731px;
}

.h-732 {
  height: 732%;
}

.lh-732 {
  line-height: 732%;
}

.h-732px {
  height: 732px;
}

.lh-732px {
  line-height: 732px;
}

.h-733 {
  height: 733%;
}

.lh-733 {
  line-height: 733%;
}

.h-733px {
  height: 733px;
}

.lh-733px {
  line-height: 733px;
}

.h-734 {
  height: 734%;
}

.lh-734 {
  line-height: 734%;
}

.h-734px {
  height: 734px;
}

.lh-734px {
  line-height: 734px;
}

.h-735 {
  height: 735%;
}

.lh-735 {
  line-height: 735%;
}

.h-735px {
  height: 735px;
}

.lh-735px {
  line-height: 735px;
}

.h-736 {
  height: 736%;
}

.lh-736 {
  line-height: 736%;
}

.h-736px {
  height: 736px;
}

.lh-736px {
  line-height: 736px;
}

.h-737 {
  height: 737%;
}

.lh-737 {
  line-height: 737%;
}

.h-737px {
  height: 737px;
}

.lh-737px {
  line-height: 737px;
}

.h-738 {
  height: 738%;
}

.lh-738 {
  line-height: 738%;
}

.h-738px {
  height: 738px;
}

.lh-738px {
  line-height: 738px;
}

.h-739 {
  height: 739%;
}

.lh-739 {
  line-height: 739%;
}

.h-739px {
  height: 739px;
}

.lh-739px {
  line-height: 739px;
}

.h-740 {
  height: 740%;
}

.lh-740 {
  line-height: 740%;
}

.h-740px {
  height: 740px;
}

.lh-740px {
  line-height: 740px;
}

.h-741 {
  height: 741%;
}

.lh-741 {
  line-height: 741%;
}

.h-741px {
  height: 741px;
}

.lh-741px {
  line-height: 741px;
}

.h-742 {
  height: 742%;
}

.lh-742 {
  line-height: 742%;
}

.h-742px {
  height: 742px;
}

.lh-742px {
  line-height: 742px;
}

.h-743 {
  height: 743%;
}

.lh-743 {
  line-height: 743%;
}

.h-743px {
  height: 743px;
}

.lh-743px {
  line-height: 743px;
}

.h-744 {
  height: 744%;
}

.lh-744 {
  line-height: 744%;
}

.h-744px {
  height: 744px;
}

.lh-744px {
  line-height: 744px;
}

.h-745 {
  height: 745%;
}

.lh-745 {
  line-height: 745%;
}

.h-745px {
  height: 745px;
}

.lh-745px {
  line-height: 745px;
}

.h-746 {
  height: 746%;
}

.lh-746 {
  line-height: 746%;
}

.h-746px {
  height: 746px;
}

.lh-746px {
  line-height: 746px;
}

.h-747 {
  height: 747%;
}

.lh-747 {
  line-height: 747%;
}

.h-747px {
  height: 747px;
}

.lh-747px {
  line-height: 747px;
}

.h-748 {
  height: 748%;
}

.lh-748 {
  line-height: 748%;
}

.h-748px {
  height: 748px;
}

.lh-748px {
  line-height: 748px;
}

.h-749 {
  height: 749%;
}

.lh-749 {
  line-height: 749%;
}

.h-749px {
  height: 749px;
}

.lh-749px {
  line-height: 749px;
}

.h-750 {
  height: 750%;
}

.lh-750 {
  line-height: 750%;
}

.h-750px {
  height: 750px;
}

.lh-750px {
  line-height: 750px;
}

.h-751 {
  height: 751%;
}

.lh-751 {
  line-height: 751%;
}

.h-751px {
  height: 751px;
}

.lh-751px {
  line-height: 751px;
}

.h-752 {
  height: 752%;
}

.lh-752 {
  line-height: 752%;
}

.h-752px {
  height: 752px;
}

.lh-752px {
  line-height: 752px;
}

.h-753 {
  height: 753%;
}

.lh-753 {
  line-height: 753%;
}

.h-753px {
  height: 753px;
}

.lh-753px {
  line-height: 753px;
}

.h-754 {
  height: 754%;
}

.lh-754 {
  line-height: 754%;
}

.h-754px {
  height: 754px;
}

.lh-754px {
  line-height: 754px;
}

.h-755 {
  height: 755%;
}

.lh-755 {
  line-height: 755%;
}

.h-755px {
  height: 755px;
}

.lh-755px {
  line-height: 755px;
}

.h-756 {
  height: 756%;
}

.lh-756 {
  line-height: 756%;
}

.h-756px {
  height: 756px;
}

.lh-756px {
  line-height: 756px;
}

.h-757 {
  height: 757%;
}

.lh-757 {
  line-height: 757%;
}

.h-757px {
  height: 757px;
}

.lh-757px {
  line-height: 757px;
}

.h-758 {
  height: 758%;
}

.lh-758 {
  line-height: 758%;
}

.h-758px {
  height: 758px;
}

.lh-758px {
  line-height: 758px;
}

.h-759 {
  height: 759%;
}

.lh-759 {
  line-height: 759%;
}

.h-759px {
  height: 759px;
}

.lh-759px {
  line-height: 759px;
}

.h-760 {
  height: 760%;
}

.lh-760 {
  line-height: 760%;
}

.h-760px {
  height: 760px;
}

.lh-760px {
  line-height: 760px;
}

.h-761 {
  height: 761%;
}

.lh-761 {
  line-height: 761%;
}

.h-761px {
  height: 761px;
}

.lh-761px {
  line-height: 761px;
}

.h-762 {
  height: 762%;
}

.lh-762 {
  line-height: 762%;
}

.h-762px {
  height: 762px;
}

.lh-762px {
  line-height: 762px;
}

.h-763 {
  height: 763%;
}

.lh-763 {
  line-height: 763%;
}

.h-763px {
  height: 763px;
}

.lh-763px {
  line-height: 763px;
}

.h-764 {
  height: 764%;
}

.lh-764 {
  line-height: 764%;
}

.h-764px {
  height: 764px;
}

.lh-764px {
  line-height: 764px;
}

.h-765 {
  height: 765%;
}

.lh-765 {
  line-height: 765%;
}

.h-765px {
  height: 765px;
}

.lh-765px {
  line-height: 765px;
}

.h-766 {
  height: 766%;
}

.lh-766 {
  line-height: 766%;
}

.h-766px {
  height: 766px;
}

.lh-766px {
  line-height: 766px;
}

.h-767 {
  height: 767%;
}

.lh-767 {
  line-height: 767%;
}

.h-767px {
  height: 767px;
}

.lh-767px {
  line-height: 767px;
}

.h-768 {
  height: 768%;
}

.lh-768 {
  line-height: 768%;
}

.h-768px {
  height: 768px;
}

.lh-768px {
  line-height: 768px;
}

.h-769 {
  height: 769%;
}

.lh-769 {
  line-height: 769%;
}

.h-769px {
  height: 769px;
}

.lh-769px {
  line-height: 769px;
}

.h-770 {
  height: 770%;
}

.lh-770 {
  line-height: 770%;
}

.h-770px {
  height: 770px;
}

.lh-770px {
  line-height: 770px;
}

.h-771 {
  height: 771%;
}

.lh-771 {
  line-height: 771%;
}

.h-771px {
  height: 771px;
}

.lh-771px {
  line-height: 771px;
}

.h-772 {
  height: 772%;
}

.lh-772 {
  line-height: 772%;
}

.h-772px {
  height: 772px;
}

.lh-772px {
  line-height: 772px;
}

.h-773 {
  height: 773%;
}

.lh-773 {
  line-height: 773%;
}

.h-773px {
  height: 773px;
}

.lh-773px {
  line-height: 773px;
}

.h-774 {
  height: 774%;
}

.lh-774 {
  line-height: 774%;
}

.h-774px {
  height: 774px;
}

.lh-774px {
  line-height: 774px;
}

.h-775 {
  height: 775%;
}

.lh-775 {
  line-height: 775%;
}

.h-775px {
  height: 775px;
}

.lh-775px {
  line-height: 775px;
}

.h-776 {
  height: 776%;
}

.lh-776 {
  line-height: 776%;
}

.h-776px {
  height: 776px;
}

.lh-776px {
  line-height: 776px;
}

.h-777 {
  height: 777%;
}

.lh-777 {
  line-height: 777%;
}

.h-777px {
  height: 777px;
}

.lh-777px {
  line-height: 777px;
}

.h-778 {
  height: 778%;
}

.lh-778 {
  line-height: 778%;
}

.h-778px {
  height: 778px;
}

.lh-778px {
  line-height: 778px;
}

.h-779 {
  height: 779%;
}

.lh-779 {
  line-height: 779%;
}

.h-779px {
  height: 779px;
}

.lh-779px {
  line-height: 779px;
}

.h-780 {
  height: 780%;
}

.lh-780 {
  line-height: 780%;
}

.h-780px {
  height: 780px;
}

.lh-780px {
  line-height: 780px;
}

.h-781 {
  height: 781%;
}

.lh-781 {
  line-height: 781%;
}

.h-781px {
  height: 781px;
}

.lh-781px {
  line-height: 781px;
}

.h-782 {
  height: 782%;
}

.lh-782 {
  line-height: 782%;
}

.h-782px {
  height: 782px;
}

.lh-782px {
  line-height: 782px;
}

.h-783 {
  height: 783%;
}

.lh-783 {
  line-height: 783%;
}

.h-783px {
  height: 783px;
}

.lh-783px {
  line-height: 783px;
}

.h-784 {
  height: 784%;
}

.lh-784 {
  line-height: 784%;
}

.h-784px {
  height: 784px;
}

.lh-784px {
  line-height: 784px;
}

.h-785 {
  height: 785%;
}

.lh-785 {
  line-height: 785%;
}

.h-785px {
  height: 785px;
}

.lh-785px {
  line-height: 785px;
}

.h-786 {
  height: 786%;
}

.lh-786 {
  line-height: 786%;
}

.h-786px {
  height: 786px;
}

.lh-786px {
  line-height: 786px;
}

.h-787 {
  height: 787%;
}

.lh-787 {
  line-height: 787%;
}

.h-787px {
  height: 787px;
}

.lh-787px {
  line-height: 787px;
}

.h-788 {
  height: 788%;
}

.lh-788 {
  line-height: 788%;
}

.h-788px {
  height: 788px;
}

.lh-788px {
  line-height: 788px;
}

.h-789 {
  height: 789%;
}

.lh-789 {
  line-height: 789%;
}

.h-789px {
  height: 789px;
}

.lh-789px {
  line-height: 789px;
}

.h-790 {
  height: 790%;
}

.lh-790 {
  line-height: 790%;
}

.h-790px {
  height: 790px;
}

.lh-790px {
  line-height: 790px;
}

.h-791 {
  height: 791%;
}

.lh-791 {
  line-height: 791%;
}

.h-791px {
  height: 791px;
}

.lh-791px {
  line-height: 791px;
}

.h-792 {
  height: 792%;
}

.lh-792 {
  line-height: 792%;
}

.h-792px {
  height: 792px;
}

.lh-792px {
  line-height: 792px;
}

.h-793 {
  height: 793%;
}

.lh-793 {
  line-height: 793%;
}

.h-793px {
  height: 793px;
}

.lh-793px {
  line-height: 793px;
}

.h-794 {
  height: 794%;
}

.lh-794 {
  line-height: 794%;
}

.h-794px {
  height: 794px;
}

.lh-794px {
  line-height: 794px;
}

.h-795 {
  height: 795%;
}

.lh-795 {
  line-height: 795%;
}

.h-795px {
  height: 795px;
}

.lh-795px {
  line-height: 795px;
}

.h-796 {
  height: 796%;
}

.lh-796 {
  line-height: 796%;
}

.h-796px {
  height: 796px;
}

.lh-796px {
  line-height: 796px;
}

.h-797 {
  height: 797%;
}

.lh-797 {
  line-height: 797%;
}

.h-797px {
  height: 797px;
}

.lh-797px {
  line-height: 797px;
}

.h-798 {
  height: 798%;
}

.lh-798 {
  line-height: 798%;
}

.h-798px {
  height: 798px;
}

.lh-798px {
  line-height: 798px;
}

.h-799 {
  height: 799%;
}

.lh-799 {
  line-height: 799%;
}

.h-799px {
  height: 799px;
}

.lh-799px {
  line-height: 799px;
}

.h-800 {
  height: 800%;
}

.lh-800 {
  line-height: 800%;
}

.h-800px {
  height: 800px;
}

.lh-800px {
  line-height: 800px;
}

.h-801 {
  height: 801%;
}

.lh-801 {
  line-height: 801%;
}

.h-801px {
  height: 801px;
}

.lh-801px {
  line-height: 801px;
}

.h-802 {
  height: 802%;
}

.lh-802 {
  line-height: 802%;
}

.h-802px {
  height: 802px;
}

.lh-802px {
  line-height: 802px;
}

.h-803 {
  height: 803%;
}

.lh-803 {
  line-height: 803%;
}

.h-803px {
  height: 803px;
}

.lh-803px {
  line-height: 803px;
}

.h-804 {
  height: 804%;
}

.lh-804 {
  line-height: 804%;
}

.h-804px {
  height: 804px;
}

.lh-804px {
  line-height: 804px;
}

.h-805 {
  height: 805%;
}

.lh-805 {
  line-height: 805%;
}

.h-805px {
  height: 805px;
}

.lh-805px {
  line-height: 805px;
}

.h-806 {
  height: 806%;
}

.lh-806 {
  line-height: 806%;
}

.h-806px {
  height: 806px;
}

.lh-806px {
  line-height: 806px;
}

.h-807 {
  height: 807%;
}

.lh-807 {
  line-height: 807%;
}

.h-807px {
  height: 807px;
}

.lh-807px {
  line-height: 807px;
}

.h-808 {
  height: 808%;
}

.lh-808 {
  line-height: 808%;
}

.h-808px {
  height: 808px;
}

.lh-808px {
  line-height: 808px;
}

.h-809 {
  height: 809%;
}

.lh-809 {
  line-height: 809%;
}

.h-809px {
  height: 809px;
}

.lh-809px {
  line-height: 809px;
}

.h-810 {
  height: 810%;
}

.lh-810 {
  line-height: 810%;
}

.h-810px {
  height: 810px;
}

.lh-810px {
  line-height: 810px;
}

.h-811 {
  height: 811%;
}

.lh-811 {
  line-height: 811%;
}

.h-811px {
  height: 811px;
}

.lh-811px {
  line-height: 811px;
}

.h-812 {
  height: 812%;
}

.lh-812 {
  line-height: 812%;
}

.h-812px {
  height: 812px;
}

.lh-812px {
  line-height: 812px;
}

.h-813 {
  height: 813%;
}

.lh-813 {
  line-height: 813%;
}

.h-813px {
  height: 813px;
}

.lh-813px {
  line-height: 813px;
}

.h-814 {
  height: 814%;
}

.lh-814 {
  line-height: 814%;
}

.h-814px {
  height: 814px;
}

.lh-814px {
  line-height: 814px;
}

.h-815 {
  height: 815%;
}

.lh-815 {
  line-height: 815%;
}

.h-815px {
  height: 815px;
}

.lh-815px {
  line-height: 815px;
}

.h-816 {
  height: 816%;
}

.lh-816 {
  line-height: 816%;
}

.h-816px {
  height: 816px;
}

.lh-816px {
  line-height: 816px;
}

.h-817 {
  height: 817%;
}

.lh-817 {
  line-height: 817%;
}

.h-817px {
  height: 817px;
}

.lh-817px {
  line-height: 817px;
}

.h-818 {
  height: 818%;
}

.lh-818 {
  line-height: 818%;
}

.h-818px {
  height: 818px;
}

.lh-818px {
  line-height: 818px;
}

.h-819 {
  height: 819%;
}

.lh-819 {
  line-height: 819%;
}

.h-819px {
  height: 819px;
}

.lh-819px {
  line-height: 819px;
}

.h-820 {
  height: 820%;
}

.lh-820 {
  line-height: 820%;
}

.h-820px {
  height: 820px;
}

.lh-820px {
  line-height: 820px;
}

.h-821 {
  height: 821%;
}

.lh-821 {
  line-height: 821%;
}

.h-821px {
  height: 821px;
}

.lh-821px {
  line-height: 821px;
}

.h-822 {
  height: 822%;
}

.lh-822 {
  line-height: 822%;
}

.h-822px {
  height: 822px;
}

.lh-822px {
  line-height: 822px;
}

.h-823 {
  height: 823%;
}

.lh-823 {
  line-height: 823%;
}

.h-823px {
  height: 823px;
}

.lh-823px {
  line-height: 823px;
}

.h-824 {
  height: 824%;
}

.lh-824 {
  line-height: 824%;
}

.h-824px {
  height: 824px;
}

.lh-824px {
  line-height: 824px;
}

.h-825 {
  height: 825%;
}

.lh-825 {
  line-height: 825%;
}

.h-825px {
  height: 825px;
}

.lh-825px {
  line-height: 825px;
}

.h-826 {
  height: 826%;
}

.lh-826 {
  line-height: 826%;
}

.h-826px {
  height: 826px;
}

.lh-826px {
  line-height: 826px;
}

.h-827 {
  height: 827%;
}

.lh-827 {
  line-height: 827%;
}

.h-827px {
  height: 827px;
}

.lh-827px {
  line-height: 827px;
}

.h-828 {
  height: 828%;
}

.lh-828 {
  line-height: 828%;
}

.h-828px {
  height: 828px;
}

.lh-828px {
  line-height: 828px;
}

.h-829 {
  height: 829%;
}

.lh-829 {
  line-height: 829%;
}

.h-829px {
  height: 829px;
}

.lh-829px {
  line-height: 829px;
}

.h-830 {
  height: 830%;
}

.lh-830 {
  line-height: 830%;
}

.h-830px {
  height: 830px;
}

.lh-830px {
  line-height: 830px;
}

.h-831 {
  height: 831%;
}

.lh-831 {
  line-height: 831%;
}

.h-831px {
  height: 831px;
}

.lh-831px {
  line-height: 831px;
}

.h-832 {
  height: 832%;
}

.lh-832 {
  line-height: 832%;
}

.h-832px {
  height: 832px;
}

.lh-832px {
  line-height: 832px;
}

.h-833 {
  height: 833%;
}

.lh-833 {
  line-height: 833%;
}

.h-833px {
  height: 833px;
}

.lh-833px {
  line-height: 833px;
}

.h-834 {
  height: 834%;
}

.lh-834 {
  line-height: 834%;
}

.h-834px {
  height: 834px;
}

.lh-834px {
  line-height: 834px;
}

.h-835 {
  height: 835%;
}

.lh-835 {
  line-height: 835%;
}

.h-835px {
  height: 835px;
}

.lh-835px {
  line-height: 835px;
}

.h-836 {
  height: 836%;
}

.lh-836 {
  line-height: 836%;
}

.h-836px {
  height: 836px;
}

.lh-836px {
  line-height: 836px;
}

.h-837 {
  height: 837%;
}

.lh-837 {
  line-height: 837%;
}

.h-837px {
  height: 837px;
}

.lh-837px {
  line-height: 837px;
}

.h-838 {
  height: 838%;
}

.lh-838 {
  line-height: 838%;
}

.h-838px {
  height: 838px;
}

.lh-838px {
  line-height: 838px;
}

.h-839 {
  height: 839%;
}

.lh-839 {
  line-height: 839%;
}

.h-839px {
  height: 839px;
}

.lh-839px {
  line-height: 839px;
}

.h-840 {
  height: 840%;
}

.lh-840 {
  line-height: 840%;
}

.h-840px {
  height: 840px;
}

.lh-840px {
  line-height: 840px;
}

.h-841 {
  height: 841%;
}

.lh-841 {
  line-height: 841%;
}

.h-841px {
  height: 841px;
}

.lh-841px {
  line-height: 841px;
}

.h-842 {
  height: 842%;
}

.lh-842 {
  line-height: 842%;
}

.h-842px {
  height: 842px;
}

.lh-842px {
  line-height: 842px;
}

.h-843 {
  height: 843%;
}

.lh-843 {
  line-height: 843%;
}

.h-843px {
  height: 843px;
}

.lh-843px {
  line-height: 843px;
}

.h-844 {
  height: 844%;
}

.lh-844 {
  line-height: 844%;
}

.h-844px {
  height: 844px;
}

.lh-844px {
  line-height: 844px;
}

.h-845 {
  height: 845%;
}

.lh-845 {
  line-height: 845%;
}

.h-845px {
  height: 845px;
}

.lh-845px {
  line-height: 845px;
}

.h-846 {
  height: 846%;
}

.lh-846 {
  line-height: 846%;
}

.h-846px {
  height: 846px;
}

.lh-846px {
  line-height: 846px;
}

.h-847 {
  height: 847%;
}

.lh-847 {
  line-height: 847%;
}

.h-847px {
  height: 847px;
}

.lh-847px {
  line-height: 847px;
}

.h-848 {
  height: 848%;
}

.lh-848 {
  line-height: 848%;
}

.h-848px {
  height: 848px;
}

.lh-848px {
  line-height: 848px;
}

.h-849 {
  height: 849%;
}

.lh-849 {
  line-height: 849%;
}

.h-849px {
  height: 849px;
}

.lh-849px {
  line-height: 849px;
}

.h-850 {
  height: 850%;
}

.lh-850 {
  line-height: 850%;
}

.h-850px {
  height: 850px;
}

.lh-850px {
  line-height: 850px;
}

.h-851 {
  height: 851%;
}

.lh-851 {
  line-height: 851%;
}

.h-851px {
  height: 851px;
}

.lh-851px {
  line-height: 851px;
}

.h-852 {
  height: 852%;
}

.lh-852 {
  line-height: 852%;
}

.h-852px {
  height: 852px;
}

.lh-852px {
  line-height: 852px;
}

.h-853 {
  height: 853%;
}

.lh-853 {
  line-height: 853%;
}

.h-853px {
  height: 853px;
}

.lh-853px {
  line-height: 853px;
}

.h-854 {
  height: 854%;
}

.lh-854 {
  line-height: 854%;
}

.h-854px {
  height: 854px;
}

.lh-854px {
  line-height: 854px;
}

.h-855 {
  height: 855%;
}

.lh-855 {
  line-height: 855%;
}

.h-855px {
  height: 855px;
}

.lh-855px {
  line-height: 855px;
}

.h-856 {
  height: 856%;
}

.lh-856 {
  line-height: 856%;
}

.h-856px {
  height: 856px;
}

.lh-856px {
  line-height: 856px;
}

.h-857 {
  height: 857%;
}

.lh-857 {
  line-height: 857%;
}

.h-857px {
  height: 857px;
}

.lh-857px {
  line-height: 857px;
}

.h-858 {
  height: 858%;
}

.lh-858 {
  line-height: 858%;
}

.h-858px {
  height: 858px;
}

.lh-858px {
  line-height: 858px;
}

.h-859 {
  height: 859%;
}

.lh-859 {
  line-height: 859%;
}

.h-859px {
  height: 859px;
}

.lh-859px {
  line-height: 859px;
}

.h-860 {
  height: 860%;
}

.lh-860 {
  line-height: 860%;
}

.h-860px {
  height: 860px;
}

.lh-860px {
  line-height: 860px;
}

.h-861 {
  height: 861%;
}

.lh-861 {
  line-height: 861%;
}

.h-861px {
  height: 861px;
}

.lh-861px {
  line-height: 861px;
}

.h-862 {
  height: 862%;
}

.lh-862 {
  line-height: 862%;
}

.h-862px {
  height: 862px;
}

.lh-862px {
  line-height: 862px;
}

.h-863 {
  height: 863%;
}

.lh-863 {
  line-height: 863%;
}

.h-863px {
  height: 863px;
}

.lh-863px {
  line-height: 863px;
}

.h-864 {
  height: 864%;
}

.lh-864 {
  line-height: 864%;
}

.h-864px {
  height: 864px;
}

.lh-864px {
  line-height: 864px;
}

.h-865 {
  height: 865%;
}

.lh-865 {
  line-height: 865%;
}

.h-865px {
  height: 865px;
}

.lh-865px {
  line-height: 865px;
}

.h-866 {
  height: 866%;
}

.lh-866 {
  line-height: 866%;
}

.h-866px {
  height: 866px;
}

.lh-866px {
  line-height: 866px;
}

.h-867 {
  height: 867%;
}

.lh-867 {
  line-height: 867%;
}

.h-867px {
  height: 867px;
}

.lh-867px {
  line-height: 867px;
}

.h-868 {
  height: 868%;
}

.lh-868 {
  line-height: 868%;
}

.h-868px {
  height: 868px;
}

.lh-868px {
  line-height: 868px;
}

.h-869 {
  height: 869%;
}

.lh-869 {
  line-height: 869%;
}

.h-869px {
  height: 869px;
}

.lh-869px {
  line-height: 869px;
}

.h-870 {
  height: 870%;
}

.lh-870 {
  line-height: 870%;
}

.h-870px {
  height: 870px;
}

.lh-870px {
  line-height: 870px;
}

.h-871 {
  height: 871%;
}

.lh-871 {
  line-height: 871%;
}

.h-871px {
  height: 871px;
}

.lh-871px {
  line-height: 871px;
}

.h-872 {
  height: 872%;
}

.lh-872 {
  line-height: 872%;
}

.h-872px {
  height: 872px;
}

.lh-872px {
  line-height: 872px;
}

.h-873 {
  height: 873%;
}

.lh-873 {
  line-height: 873%;
}

.h-873px {
  height: 873px;
}

.lh-873px {
  line-height: 873px;
}

.h-874 {
  height: 874%;
}

.lh-874 {
  line-height: 874%;
}

.h-874px {
  height: 874px;
}

.lh-874px {
  line-height: 874px;
}

.h-875 {
  height: 875%;
}

.lh-875 {
  line-height: 875%;
}

.h-875px {
  height: 875px;
}

.lh-875px {
  line-height: 875px;
}

.h-876 {
  height: 876%;
}

.lh-876 {
  line-height: 876%;
}

.h-876px {
  height: 876px;
}

.lh-876px {
  line-height: 876px;
}

.h-877 {
  height: 877%;
}

.lh-877 {
  line-height: 877%;
}

.h-877px {
  height: 877px;
}

.lh-877px {
  line-height: 877px;
}

.h-878 {
  height: 878%;
}

.lh-878 {
  line-height: 878%;
}

.h-878px {
  height: 878px;
}

.lh-878px {
  line-height: 878px;
}

.h-879 {
  height: 879%;
}

.lh-879 {
  line-height: 879%;
}

.h-879px {
  height: 879px;
}

.lh-879px {
  line-height: 879px;
}

.h-880 {
  height: 880%;
}

.lh-880 {
  line-height: 880%;
}

.h-880px {
  height: 880px;
}

.lh-880px {
  line-height: 880px;
}

.h-881 {
  height: 881%;
}

.lh-881 {
  line-height: 881%;
}

.h-881px {
  height: 881px;
}

.lh-881px {
  line-height: 881px;
}

.h-882 {
  height: 882%;
}

.lh-882 {
  line-height: 882%;
}

.h-882px {
  height: 882px;
}

.lh-882px {
  line-height: 882px;
}

.h-883 {
  height: 883%;
}

.lh-883 {
  line-height: 883%;
}

.h-883px {
  height: 883px;
}

.lh-883px {
  line-height: 883px;
}

.h-884 {
  height: 884%;
}

.lh-884 {
  line-height: 884%;
}

.h-884px {
  height: 884px;
}

.lh-884px {
  line-height: 884px;
}

.h-885 {
  height: 885%;
}

.lh-885 {
  line-height: 885%;
}

.h-885px {
  height: 885px;
}

.lh-885px {
  line-height: 885px;
}

.h-886 {
  height: 886%;
}

.lh-886 {
  line-height: 886%;
}

.h-886px {
  height: 886px;
}

.lh-886px {
  line-height: 886px;
}

.h-887 {
  height: 887%;
}

.lh-887 {
  line-height: 887%;
}

.h-887px {
  height: 887px;
}

.lh-887px {
  line-height: 887px;
}

.h-888 {
  height: 888%;
}

.lh-888 {
  line-height: 888%;
}

.h-888px {
  height: 888px;
}

.lh-888px {
  line-height: 888px;
}

.h-889 {
  height: 889%;
}

.lh-889 {
  line-height: 889%;
}

.h-889px {
  height: 889px;
}

.lh-889px {
  line-height: 889px;
}

.h-890 {
  height: 890%;
}

.lh-890 {
  line-height: 890%;
}

.h-890px {
  height: 890px;
}

.lh-890px {
  line-height: 890px;
}

.h-891 {
  height: 891%;
}

.lh-891 {
  line-height: 891%;
}

.h-891px {
  height: 891px;
}

.lh-891px {
  line-height: 891px;
}

.h-892 {
  height: 892%;
}

.lh-892 {
  line-height: 892%;
}

.h-892px {
  height: 892px;
}

.lh-892px {
  line-height: 892px;
}

.h-893 {
  height: 893%;
}

.lh-893 {
  line-height: 893%;
}

.h-893px {
  height: 893px;
}

.lh-893px {
  line-height: 893px;
}

.h-894 {
  height: 894%;
}

.lh-894 {
  line-height: 894%;
}

.h-894px {
  height: 894px;
}

.lh-894px {
  line-height: 894px;
}

.h-895 {
  height: 895%;
}

.lh-895 {
  line-height: 895%;
}

.h-895px {
  height: 895px;
}

.lh-895px {
  line-height: 895px;
}

.h-896 {
  height: 896%;
}

.lh-896 {
  line-height: 896%;
}

.h-896px {
  height: 896px;
}

.lh-896px {
  line-height: 896px;
}

.h-897 {
  height: 897%;
}

.lh-897 {
  line-height: 897%;
}

.h-897px {
  height: 897px;
}

.lh-897px {
  line-height: 897px;
}

.h-898 {
  height: 898%;
}

.lh-898 {
  line-height: 898%;
}

.h-898px {
  height: 898px;
}

.lh-898px {
  line-height: 898px;
}

.h-899 {
  height: 899%;
}

.lh-899 {
  line-height: 899%;
}

.h-899px {
  height: 899px;
}

.lh-899px {
  line-height: 899px;
}

.h-900 {
  height: 900%;
}

.lh-900 {
  line-height: 900%;
}

.h-900px {
  height: 900px;
}

.lh-900px {
  line-height: 900px;
}

.h-901 {
  height: 901%;
}

.lh-901 {
  line-height: 901%;
}

.h-901px {
  height: 901px;
}

.lh-901px {
  line-height: 901px;
}

.h-902 {
  height: 902%;
}

.lh-902 {
  line-height: 902%;
}

.h-902px {
  height: 902px;
}

.lh-902px {
  line-height: 902px;
}

.h-903 {
  height: 903%;
}

.lh-903 {
  line-height: 903%;
}

.h-903px {
  height: 903px;
}

.lh-903px {
  line-height: 903px;
}

.h-904 {
  height: 904%;
}

.lh-904 {
  line-height: 904%;
}

.h-904px {
  height: 904px;
}

.lh-904px {
  line-height: 904px;
}

.h-905 {
  height: 905%;
}

.lh-905 {
  line-height: 905%;
}

.h-905px {
  height: 905px;
}

.lh-905px {
  line-height: 905px;
}

.h-906 {
  height: 906%;
}

.lh-906 {
  line-height: 906%;
}

.h-906px {
  height: 906px;
}

.lh-906px {
  line-height: 906px;
}

.h-907 {
  height: 907%;
}

.lh-907 {
  line-height: 907%;
}

.h-907px {
  height: 907px;
}

.lh-907px {
  line-height: 907px;
}

.h-908 {
  height: 908%;
}

.lh-908 {
  line-height: 908%;
}

.h-908px {
  height: 908px;
}

.lh-908px {
  line-height: 908px;
}

.h-909 {
  height: 909%;
}

.lh-909 {
  line-height: 909%;
}

.h-909px {
  height: 909px;
}

.lh-909px {
  line-height: 909px;
}

.h-910 {
  height: 910%;
}

.lh-910 {
  line-height: 910%;
}

.h-910px {
  height: 910px;
}

.lh-910px {
  line-height: 910px;
}

.h-911 {
  height: 911%;
}

.lh-911 {
  line-height: 911%;
}

.h-911px {
  height: 911px;
}

.lh-911px {
  line-height: 911px;
}

.h-912 {
  height: 912%;
}

.lh-912 {
  line-height: 912%;
}

.h-912px {
  height: 912px;
}

.lh-912px {
  line-height: 912px;
}

.h-913 {
  height: 913%;
}

.lh-913 {
  line-height: 913%;
}

.h-913px {
  height: 913px;
}

.lh-913px {
  line-height: 913px;
}

.h-914 {
  height: 914%;
}

.lh-914 {
  line-height: 914%;
}

.h-914px {
  height: 914px;
}

.lh-914px {
  line-height: 914px;
}

.h-915 {
  height: 915%;
}

.lh-915 {
  line-height: 915%;
}

.h-915px {
  height: 915px;
}

.lh-915px {
  line-height: 915px;
}

.h-916 {
  height: 916%;
}

.lh-916 {
  line-height: 916%;
}

.h-916px {
  height: 916px;
}

.lh-916px {
  line-height: 916px;
}

.h-917 {
  height: 917%;
}

.lh-917 {
  line-height: 917%;
}

.h-917px {
  height: 917px;
}

.lh-917px {
  line-height: 917px;
}

.h-918 {
  height: 918%;
}

.lh-918 {
  line-height: 918%;
}

.h-918px {
  height: 918px;
}

.lh-918px {
  line-height: 918px;
}

.h-919 {
  height: 919%;
}

.lh-919 {
  line-height: 919%;
}

.h-919px {
  height: 919px;
}

.lh-919px {
  line-height: 919px;
}

.h-920 {
  height: 920%;
}

.lh-920 {
  line-height: 920%;
}

.h-920px {
  height: 920px;
}

.lh-920px {
  line-height: 920px;
}

.h-921 {
  height: 921%;
}

.lh-921 {
  line-height: 921%;
}

.h-921px {
  height: 921px;
}

.lh-921px {
  line-height: 921px;
}

.h-922 {
  height: 922%;
}

.lh-922 {
  line-height: 922%;
}

.h-922px {
  height: 922px;
}

.lh-922px {
  line-height: 922px;
}

.h-923 {
  height: 923%;
}

.lh-923 {
  line-height: 923%;
}

.h-923px {
  height: 923px;
}

.lh-923px {
  line-height: 923px;
}

.h-924 {
  height: 924%;
}

.lh-924 {
  line-height: 924%;
}

.h-924px {
  height: 924px;
}

.lh-924px {
  line-height: 924px;
}

.h-925 {
  height: 925%;
}

.lh-925 {
  line-height: 925%;
}

.h-925px {
  height: 925px;
}

.lh-925px {
  line-height: 925px;
}

.h-926 {
  height: 926%;
}

.lh-926 {
  line-height: 926%;
}

.h-926px {
  height: 926px;
}

.lh-926px {
  line-height: 926px;
}

.h-927 {
  height: 927%;
}

.lh-927 {
  line-height: 927%;
}

.h-927px {
  height: 927px;
}

.lh-927px {
  line-height: 927px;
}

.h-928 {
  height: 928%;
}

.lh-928 {
  line-height: 928%;
}

.h-928px {
  height: 928px;
}

.lh-928px {
  line-height: 928px;
}

.h-929 {
  height: 929%;
}

.lh-929 {
  line-height: 929%;
}

.h-929px {
  height: 929px;
}

.lh-929px {
  line-height: 929px;
}

.h-930 {
  height: 930%;
}

.lh-930 {
  line-height: 930%;
}

.h-930px {
  height: 930px;
}

.lh-930px {
  line-height: 930px;
}

.h-931 {
  height: 931%;
}

.lh-931 {
  line-height: 931%;
}

.h-931px {
  height: 931px;
}

.lh-931px {
  line-height: 931px;
}

.h-932 {
  height: 932%;
}

.lh-932 {
  line-height: 932%;
}

.h-932px {
  height: 932px;
}

.lh-932px {
  line-height: 932px;
}

.h-933 {
  height: 933%;
}

.lh-933 {
  line-height: 933%;
}

.h-933px {
  height: 933px;
}

.lh-933px {
  line-height: 933px;
}

.h-934 {
  height: 934%;
}

.lh-934 {
  line-height: 934%;
}

.h-934px {
  height: 934px;
}

.lh-934px {
  line-height: 934px;
}

.h-935 {
  height: 935%;
}

.lh-935 {
  line-height: 935%;
}

.h-935px {
  height: 935px;
}

.lh-935px {
  line-height: 935px;
}

.h-936 {
  height: 936%;
}

.lh-936 {
  line-height: 936%;
}

.h-936px {
  height: 936px;
}

.lh-936px {
  line-height: 936px;
}

.h-937 {
  height: 937%;
}

.lh-937 {
  line-height: 937%;
}

.h-937px {
  height: 937px;
}

.lh-937px {
  line-height: 937px;
}

.h-938 {
  height: 938%;
}

.lh-938 {
  line-height: 938%;
}

.h-938px {
  height: 938px;
}

.lh-938px {
  line-height: 938px;
}

.h-939 {
  height: 939%;
}

.lh-939 {
  line-height: 939%;
}

.h-939px {
  height: 939px;
}

.lh-939px {
  line-height: 939px;
}

.h-940 {
  height: 940%;
}

.lh-940 {
  line-height: 940%;
}

.h-940px {
  height: 940px;
}

.lh-940px {
  line-height: 940px;
}

.h-941 {
  height: 941%;
}

.lh-941 {
  line-height: 941%;
}

.h-941px {
  height: 941px;
}

.lh-941px {
  line-height: 941px;
}

.h-942 {
  height: 942%;
}

.lh-942 {
  line-height: 942%;
}

.h-942px {
  height: 942px;
}

.lh-942px {
  line-height: 942px;
}

.h-943 {
  height: 943%;
}

.lh-943 {
  line-height: 943%;
}

.h-943px {
  height: 943px;
}

.lh-943px {
  line-height: 943px;
}

.h-944 {
  height: 944%;
}

.lh-944 {
  line-height: 944%;
}

.h-944px {
  height: 944px;
}

.lh-944px {
  line-height: 944px;
}

.h-945 {
  height: 945%;
}

.lh-945 {
  line-height: 945%;
}

.h-945px {
  height: 945px;
}

.lh-945px {
  line-height: 945px;
}

.h-946 {
  height: 946%;
}

.lh-946 {
  line-height: 946%;
}

.h-946px {
  height: 946px;
}

.lh-946px {
  line-height: 946px;
}

.h-947 {
  height: 947%;
}

.lh-947 {
  line-height: 947%;
}

.h-947px {
  height: 947px;
}

.lh-947px {
  line-height: 947px;
}

.h-948 {
  height: 948%;
}

.lh-948 {
  line-height: 948%;
}

.h-948px {
  height: 948px;
}

.lh-948px {
  line-height: 948px;
}

.h-949 {
  height: 949%;
}

.lh-949 {
  line-height: 949%;
}

.h-949px {
  height: 949px;
}

.lh-949px {
  line-height: 949px;
}

.h-950 {
  height: 950%;
}

.lh-950 {
  line-height: 950%;
}

.h-950px {
  height: 950px;
}

.lh-950px {
  line-height: 950px;
}

.h-951 {
  height: 951%;
}

.lh-951 {
  line-height: 951%;
}

.h-951px {
  height: 951px;
}

.lh-951px {
  line-height: 951px;
}

.h-952 {
  height: 952%;
}

.lh-952 {
  line-height: 952%;
}

.h-952px {
  height: 952px;
}

.lh-952px {
  line-height: 952px;
}

.h-953 {
  height: 953%;
}

.lh-953 {
  line-height: 953%;
}

.h-953px {
  height: 953px;
}

.lh-953px {
  line-height: 953px;
}

.h-954 {
  height: 954%;
}

.lh-954 {
  line-height: 954%;
}

.h-954px {
  height: 954px;
}

.lh-954px {
  line-height: 954px;
}

.h-955 {
  height: 955%;
}

.lh-955 {
  line-height: 955%;
}

.h-955px {
  height: 955px;
}

.lh-955px {
  line-height: 955px;
}

.h-956 {
  height: 956%;
}

.lh-956 {
  line-height: 956%;
}

.h-956px {
  height: 956px;
}

.lh-956px {
  line-height: 956px;
}

.h-957 {
  height: 957%;
}

.lh-957 {
  line-height: 957%;
}

.h-957px {
  height: 957px;
}

.lh-957px {
  line-height: 957px;
}

.h-958 {
  height: 958%;
}

.lh-958 {
  line-height: 958%;
}

.h-958px {
  height: 958px;
}

.lh-958px {
  line-height: 958px;
}

.h-959 {
  height: 959%;
}

.lh-959 {
  line-height: 959%;
}

.h-959px {
  height: 959px;
}

.lh-959px {
  line-height: 959px;
}

.h-960 {
  height: 960%;
}

.lh-960 {
  line-height: 960%;
}

.h-960px {
  height: 960px;
}

.lh-960px {
  line-height: 960px;
}

.h-961 {
  height: 961%;
}

.lh-961 {
  line-height: 961%;
}

.h-961px {
  height: 961px;
}

.lh-961px {
  line-height: 961px;
}

.h-962 {
  height: 962%;
}

.lh-962 {
  line-height: 962%;
}

.h-962px {
  height: 962px;
}

.lh-962px {
  line-height: 962px;
}

.h-963 {
  height: 963%;
}

.lh-963 {
  line-height: 963%;
}

.h-963px {
  height: 963px;
}

.lh-963px {
  line-height: 963px;
}

.h-964 {
  height: 964%;
}

.lh-964 {
  line-height: 964%;
}

.h-964px {
  height: 964px;
}

.lh-964px {
  line-height: 964px;
}

.h-965 {
  height: 965%;
}

.lh-965 {
  line-height: 965%;
}

.h-965px {
  height: 965px;
}

.lh-965px {
  line-height: 965px;
}

.h-966 {
  height: 966%;
}

.lh-966 {
  line-height: 966%;
}

.h-966px {
  height: 966px;
}

.lh-966px {
  line-height: 966px;
}

.h-967 {
  height: 967%;
}

.lh-967 {
  line-height: 967%;
}

.h-967px {
  height: 967px;
}

.lh-967px {
  line-height: 967px;
}

.h-968 {
  height: 968%;
}

.lh-968 {
  line-height: 968%;
}

.h-968px {
  height: 968px;
}

.lh-968px {
  line-height: 968px;
}

.h-969 {
  height: 969%;
}

.lh-969 {
  line-height: 969%;
}

.h-969px {
  height: 969px;
}

.lh-969px {
  line-height: 969px;
}

.h-970 {
  height: 970%;
}

.lh-970 {
  line-height: 970%;
}

.h-970px {
  height: 970px;
}

.lh-970px {
  line-height: 970px;
}

.h-971 {
  height: 971%;
}

.lh-971 {
  line-height: 971%;
}

.h-971px {
  height: 971px;
}

.lh-971px {
  line-height: 971px;
}

.h-972 {
  height: 972%;
}

.lh-972 {
  line-height: 972%;
}

.h-972px {
  height: 972px;
}

.lh-972px {
  line-height: 972px;
}

.h-973 {
  height: 973%;
}

.lh-973 {
  line-height: 973%;
}

.h-973px {
  height: 973px;
}

.lh-973px {
  line-height: 973px;
}

.h-974 {
  height: 974%;
}

.lh-974 {
  line-height: 974%;
}

.h-974px {
  height: 974px;
}

.lh-974px {
  line-height: 974px;
}

.h-975 {
  height: 975%;
}

.lh-975 {
  line-height: 975%;
}

.h-975px {
  height: 975px;
}

.lh-975px {
  line-height: 975px;
}

.h-976 {
  height: 976%;
}

.lh-976 {
  line-height: 976%;
}

.h-976px {
  height: 976px;
}

.lh-976px {
  line-height: 976px;
}

.h-977 {
  height: 977%;
}

.lh-977 {
  line-height: 977%;
}

.h-977px {
  height: 977px;
}

.lh-977px {
  line-height: 977px;
}

.h-978 {
  height: 978%;
}

.lh-978 {
  line-height: 978%;
}

.h-978px {
  height: 978px;
}

.lh-978px {
  line-height: 978px;
}

.h-979 {
  height: 979%;
}

.lh-979 {
  line-height: 979%;
}

.h-979px {
  height: 979px;
}

.lh-979px {
  line-height: 979px;
}

.h-980 {
  height: 980%;
}

.lh-980 {
  line-height: 980%;
}

.h-980px {
  height: 980px;
}

.lh-980px {
  line-height: 980px;
}

.h-981 {
  height: 981%;
}

.lh-981 {
  line-height: 981%;
}

.h-981px {
  height: 981px;
}

.lh-981px {
  line-height: 981px;
}

.h-982 {
  height: 982%;
}

.lh-982 {
  line-height: 982%;
}

.h-982px {
  height: 982px;
}

.lh-982px {
  line-height: 982px;
}

.h-983 {
  height: 983%;
}

.lh-983 {
  line-height: 983%;
}

.h-983px {
  height: 983px;
}

.lh-983px {
  line-height: 983px;
}

.h-984 {
  height: 984%;
}

.lh-984 {
  line-height: 984%;
}

.h-984px {
  height: 984px;
}

.lh-984px {
  line-height: 984px;
}

.h-985 {
  height: 985%;
}

.lh-985 {
  line-height: 985%;
}

.h-985px {
  height: 985px;
}

.lh-985px {
  line-height: 985px;
}

.h-986 {
  height: 986%;
}

.lh-986 {
  line-height: 986%;
}

.h-986px {
  height: 986px;
}

.lh-986px {
  line-height: 986px;
}

.h-987 {
  height: 987%;
}

.lh-987 {
  line-height: 987%;
}

.h-987px {
  height: 987px;
}

.lh-987px {
  line-height: 987px;
}

.h-988 {
  height: 988%;
}

.lh-988 {
  line-height: 988%;
}

.h-988px {
  height: 988px;
}

.lh-988px {
  line-height: 988px;
}

.h-989 {
  height: 989%;
}

.lh-989 {
  line-height: 989%;
}

.h-989px {
  height: 989px;
}

.lh-989px {
  line-height: 989px;
}

.h-990 {
  height: 990%;
}

.lh-990 {
  line-height: 990%;
}

.h-990px {
  height: 990px;
}

.lh-990px {
  line-height: 990px;
}

.h-991 {
  height: 991%;
}

.lh-991 {
  line-height: 991%;
}

.h-991px {
  height: 991px;
}

.lh-991px {
  line-height: 991px;
}

.h-992 {
  height: 992%;
}

.lh-992 {
  line-height: 992%;
}

.h-992px {
  height: 992px;
}

.lh-992px {
  line-height: 992px;
}

.h-993 {
  height: 993%;
}

.lh-993 {
  line-height: 993%;
}

.h-993px {
  height: 993px;
}

.lh-993px {
  line-height: 993px;
}

.h-994 {
  height: 994%;
}

.lh-994 {
  line-height: 994%;
}

.h-994px {
  height: 994px;
}

.lh-994px {
  line-height: 994px;
}

.h-995 {
  height: 995%;
}

.lh-995 {
  line-height: 995%;
}

.h-995px {
  height: 995px;
}

.lh-995px {
  line-height: 995px;
}

.h-996 {
  height: 996%;
}

.lh-996 {
  line-height: 996%;
}

.h-996px {
  height: 996px;
}

.lh-996px {
  line-height: 996px;
}

.h-997 {
  height: 997%;
}

.lh-997 {
  line-height: 997%;
}

.h-997px {
  height: 997px;
}

.lh-997px {
  line-height: 997px;
}

.h-998 {
  height: 998%;
}

.lh-998 {
  line-height: 998%;
}

.h-998px {
  height: 998px;
}

.lh-998px {
  line-height: 998px;
}

.h-999 {
  height: 999%;
}

.lh-999 {
  line-height: 999%;
}

.h-999px {
  height: 999px;
}

.lh-999px {
  line-height: 999px;
}

.h-1000 {
  height: 1000%;
}

.lh-1000 {
  line-height: 1000%;
}

.h-1000px {
  height: 1000px;
}

.lh-1000px {
  line-height: 1000px;
}

.h-1001 {
  height: 1001%;
}

.lh-1001 {
  line-height: 1001%;
}

.h-1001px {
  height: 1001px;
}

.lh-1001px {
  line-height: 1001px;
}

.h-1002 {
  height: 1002%;
}

.lh-1002 {
  line-height: 1002%;
}

.h-1002px {
  height: 1002px;
}

.lh-1002px {
  line-height: 1002px;
}

.h-1003 {
  height: 1003%;
}

.lh-1003 {
  line-height: 1003%;
}

.h-1003px {
  height: 1003px;
}

.lh-1003px {
  line-height: 1003px;
}

.h-1004 {
  height: 1004%;
}

.lh-1004 {
  line-height: 1004%;
}

.h-1004px {
  height: 1004px;
}

.lh-1004px {
  line-height: 1004px;
}

.h-1005 {
  height: 1005%;
}

.lh-1005 {
  line-height: 1005%;
}

.h-1005px {
  height: 1005px;
}

.lh-1005px {
  line-height: 1005px;
}

.h-1006 {
  height: 1006%;
}

.lh-1006 {
  line-height: 1006%;
}

.h-1006px {
  height: 1006px;
}

.lh-1006px {
  line-height: 1006px;
}

.h-1007 {
  height: 1007%;
}

.lh-1007 {
  line-height: 1007%;
}

.h-1007px {
  height: 1007px;
}

.lh-1007px {
  line-height: 1007px;
}

.h-1008 {
  height: 1008%;
}

.lh-1008 {
  line-height: 1008%;
}

.h-1008px {
  height: 1008px;
}

.lh-1008px {
  line-height: 1008px;
}

.h-1009 {
  height: 1009%;
}

.lh-1009 {
  line-height: 1009%;
}

.h-1009px {
  height: 1009px;
}

.lh-1009px {
  line-height: 1009px;
}

.h-1010 {
  height: 1010%;
}

.lh-1010 {
  line-height: 1010%;
}

.h-1010px {
  height: 1010px;
}

.lh-1010px {
  line-height: 1010px;
}

.h-1011 {
  height: 1011%;
}

.lh-1011 {
  line-height: 1011%;
}

.h-1011px {
  height: 1011px;
}

.lh-1011px {
  line-height: 1011px;
}

.h-1012 {
  height: 1012%;
}

.lh-1012 {
  line-height: 1012%;
}

.h-1012px {
  height: 1012px;
}

.lh-1012px {
  line-height: 1012px;
}

.h-1013 {
  height: 1013%;
}

.lh-1013 {
  line-height: 1013%;
}

.h-1013px {
  height: 1013px;
}

.lh-1013px {
  line-height: 1013px;
}

.h-1014 {
  height: 1014%;
}

.lh-1014 {
  line-height: 1014%;
}

.h-1014px {
  height: 1014px;
}

.lh-1014px {
  line-height: 1014px;
}

.h-1015 {
  height: 1015%;
}

.lh-1015 {
  line-height: 1015%;
}

.h-1015px {
  height: 1015px;
}

.lh-1015px {
  line-height: 1015px;
}

.h-1016 {
  height: 1016%;
}

.lh-1016 {
  line-height: 1016%;
}

.h-1016px {
  height: 1016px;
}

.lh-1016px {
  line-height: 1016px;
}

.h-1017 {
  height: 1017%;
}

.lh-1017 {
  line-height: 1017%;
}

.h-1017px {
  height: 1017px;
}

.lh-1017px {
  line-height: 1017px;
}

.h-1018 {
  height: 1018%;
}

.lh-1018 {
  line-height: 1018%;
}

.h-1018px {
  height: 1018px;
}

.lh-1018px {
  line-height: 1018px;
}

.h-1019 {
  height: 1019%;
}

.lh-1019 {
  line-height: 1019%;
}

.h-1019px {
  height: 1019px;
}

.lh-1019px {
  line-height: 1019px;
}

.h-1020 {
  height: 1020%;
}

.lh-1020 {
  line-height: 1020%;
}

.h-1020px {
  height: 1020px;
}

.lh-1020px {
  line-height: 1020px;
}

.h-1021 {
  height: 1021%;
}

.lh-1021 {
  line-height: 1021%;
}

.h-1021px {
  height: 1021px;
}

.lh-1021px {
  line-height: 1021px;
}

.h-1022 {
  height: 1022%;
}

.lh-1022 {
  line-height: 1022%;
}

.h-1022px {
  height: 1022px;
}

.lh-1022px {
  line-height: 1022px;
}

.h-1023 {
  height: 1023%;
}

.lh-1023 {
  line-height: 1023%;
}

.h-1023px {
  height: 1023px;
}

.lh-1023px {
  line-height: 1023px;
}

.h-1024 {
  height: 1024%;
}

.lh-1024 {
  line-height: 1024%;
}

.h-1024px {
  height: 1024px;
}

.lh-1024px {
  line-height: 1024px;
}

.h-1025 {
  height: 1025%;
}

.lh-1025 {
  line-height: 1025%;
}

.h-1025px {
  height: 1025px;
}

.lh-1025px {
  line-height: 1025px;
}

.h-1026 {
  height: 1026%;
}

.lh-1026 {
  line-height: 1026%;
}

.h-1026px {
  height: 1026px;
}

.lh-1026px {
  line-height: 1026px;
}

.h-1027 {
  height: 1027%;
}

.lh-1027 {
  line-height: 1027%;
}

.h-1027px {
  height: 1027px;
}

.lh-1027px {
  line-height: 1027px;
}

.h-1028 {
  height: 1028%;
}

.lh-1028 {
  line-height: 1028%;
}

.h-1028px {
  height: 1028px;
}

.lh-1028px {
  line-height: 1028px;
}

.h-1029 {
  height: 1029%;
}

.lh-1029 {
  line-height: 1029%;
}

.h-1029px {
  height: 1029px;
}

.lh-1029px {
  line-height: 1029px;
}

.h-1030 {
  height: 1030%;
}

.lh-1030 {
  line-height: 1030%;
}

.h-1030px {
  height: 1030px;
}

.lh-1030px {
  line-height: 1030px;
}

.h-1031 {
  height: 1031%;
}

.lh-1031 {
  line-height: 1031%;
}

.h-1031px {
  height: 1031px;
}

.lh-1031px {
  line-height: 1031px;
}

.h-1032 {
  height: 1032%;
}

.lh-1032 {
  line-height: 1032%;
}

.h-1032px {
  height: 1032px;
}

.lh-1032px {
  line-height: 1032px;
}

.h-1033 {
  height: 1033%;
}

.lh-1033 {
  line-height: 1033%;
}

.h-1033px {
  height: 1033px;
}

.lh-1033px {
  line-height: 1033px;
}

.h-1034 {
  height: 1034%;
}

.lh-1034 {
  line-height: 1034%;
}

.h-1034px {
  height: 1034px;
}

.lh-1034px {
  line-height: 1034px;
}

.h-1035 {
  height: 1035%;
}

.lh-1035 {
  line-height: 1035%;
}

.h-1035px {
  height: 1035px;
}

.lh-1035px {
  line-height: 1035px;
}

.h-1036 {
  height: 1036%;
}

.lh-1036 {
  line-height: 1036%;
}

.h-1036px {
  height: 1036px;
}

.lh-1036px {
  line-height: 1036px;
}

.h-1037 {
  height: 1037%;
}

.lh-1037 {
  line-height: 1037%;
}

.h-1037px {
  height: 1037px;
}

.lh-1037px {
  line-height: 1037px;
}

.h-1038 {
  height: 1038%;
}

.lh-1038 {
  line-height: 1038%;
}

.h-1038px {
  height: 1038px;
}

.lh-1038px {
  line-height: 1038px;
}

.h-1039 {
  height: 1039%;
}

.lh-1039 {
  line-height: 1039%;
}

.h-1039px {
  height: 1039px;
}

.lh-1039px {
  line-height: 1039px;
}

.h-1040 {
  height: 1040%;
}

.lh-1040 {
  line-height: 1040%;
}

.h-1040px {
  height: 1040px;
}

.lh-1040px {
  line-height: 1040px;
}

.h-1041 {
  height: 1041%;
}

.lh-1041 {
  line-height: 1041%;
}

.h-1041px {
  height: 1041px;
}

.lh-1041px {
  line-height: 1041px;
}

.h-1042 {
  height: 1042%;
}

.lh-1042 {
  line-height: 1042%;
}

.h-1042px {
  height: 1042px;
}

.lh-1042px {
  line-height: 1042px;
}

.h-1043 {
  height: 1043%;
}

.lh-1043 {
  line-height: 1043%;
}

.h-1043px {
  height: 1043px;
}

.lh-1043px {
  line-height: 1043px;
}

.h-1044 {
  height: 1044%;
}

.lh-1044 {
  line-height: 1044%;
}

.h-1044px {
  height: 1044px;
}

.lh-1044px {
  line-height: 1044px;
}

.h-1045 {
  height: 1045%;
}

.lh-1045 {
  line-height: 1045%;
}

.h-1045px {
  height: 1045px;
}

.lh-1045px {
  line-height: 1045px;
}

.h-1046 {
  height: 1046%;
}

.lh-1046 {
  line-height: 1046%;
}

.h-1046px {
  height: 1046px;
}

.lh-1046px {
  line-height: 1046px;
}

.h-1047 {
  height: 1047%;
}

.lh-1047 {
  line-height: 1047%;
}

.h-1047px {
  height: 1047px;
}

.lh-1047px {
  line-height: 1047px;
}

.h-1048 {
  height: 1048%;
}

.lh-1048 {
  line-height: 1048%;
}

.h-1048px {
  height: 1048px;
}

.lh-1048px {
  line-height: 1048px;
}

.h-1049 {
  height: 1049%;
}

.lh-1049 {
  line-height: 1049%;
}

.h-1049px {
  height: 1049px;
}

.lh-1049px {
  line-height: 1049px;
}

.h-1050 {
  height: 1050%;
}

.lh-1050 {
  line-height: 1050%;
}

.h-1050px {
  height: 1050px;
}

.lh-1050px {
  line-height: 1050px;
}

.h-1051 {
  height: 1051%;
}

.lh-1051 {
  line-height: 1051%;
}

.h-1051px {
  height: 1051px;
}

.lh-1051px {
  line-height: 1051px;
}

.h-1052 {
  height: 1052%;
}

.lh-1052 {
  line-height: 1052%;
}

.h-1052px {
  height: 1052px;
}

.lh-1052px {
  line-height: 1052px;
}

.h-1053 {
  height: 1053%;
}

.lh-1053 {
  line-height: 1053%;
}

.h-1053px {
  height: 1053px;
}

.lh-1053px {
  line-height: 1053px;
}

.h-1054 {
  height: 1054%;
}

.lh-1054 {
  line-height: 1054%;
}

.h-1054px {
  height: 1054px;
}

.lh-1054px {
  line-height: 1054px;
}

.h-1055 {
  height: 1055%;
}

.lh-1055 {
  line-height: 1055%;
}

.h-1055px {
  height: 1055px;
}

.lh-1055px {
  line-height: 1055px;
}

.h-1056 {
  height: 1056%;
}

.lh-1056 {
  line-height: 1056%;
}

.h-1056px {
  height: 1056px;
}

.lh-1056px {
  line-height: 1056px;
}

.h-1057 {
  height: 1057%;
}

.lh-1057 {
  line-height: 1057%;
}

.h-1057px {
  height: 1057px;
}

.lh-1057px {
  line-height: 1057px;
}

.h-1058 {
  height: 1058%;
}

.lh-1058 {
  line-height: 1058%;
}

.h-1058px {
  height: 1058px;
}

.lh-1058px {
  line-height: 1058px;
}

.h-1059 {
  height: 1059%;
}

.lh-1059 {
  line-height: 1059%;
}

.h-1059px {
  height: 1059px;
}

.lh-1059px {
  line-height: 1059px;
}

.h-1060 {
  height: 1060%;
}

.lh-1060 {
  line-height: 1060%;
}

.h-1060px {
  height: 1060px;
}

.lh-1060px {
  line-height: 1060px;
}

.h-1061 {
  height: 1061%;
}

.lh-1061 {
  line-height: 1061%;
}

.h-1061px {
  height: 1061px;
}

.lh-1061px {
  line-height: 1061px;
}

.h-1062 {
  height: 1062%;
}

.lh-1062 {
  line-height: 1062%;
}

.h-1062px {
  height: 1062px;
}

.lh-1062px {
  line-height: 1062px;
}

.h-1063 {
  height: 1063%;
}

.lh-1063 {
  line-height: 1063%;
}

.h-1063px {
  height: 1063px;
}

.lh-1063px {
  line-height: 1063px;
}

.h-1064 {
  height: 1064%;
}

.lh-1064 {
  line-height: 1064%;
}

.h-1064px {
  height: 1064px;
}

.lh-1064px {
  line-height: 1064px;
}

.h-1065 {
  height: 1065%;
}

.lh-1065 {
  line-height: 1065%;
}

.h-1065px {
  height: 1065px;
}

.lh-1065px {
  line-height: 1065px;
}

.h-1066 {
  height: 1066%;
}

.lh-1066 {
  line-height: 1066%;
}

.h-1066px {
  height: 1066px;
}

.lh-1066px {
  line-height: 1066px;
}

.h-1067 {
  height: 1067%;
}

.lh-1067 {
  line-height: 1067%;
}

.h-1067px {
  height: 1067px;
}

.lh-1067px {
  line-height: 1067px;
}

.h-1068 {
  height: 1068%;
}

.lh-1068 {
  line-height: 1068%;
}

.h-1068px {
  height: 1068px;
}

.lh-1068px {
  line-height: 1068px;
}

.h-1069 {
  height: 1069%;
}

.lh-1069 {
  line-height: 1069%;
}

.h-1069px {
  height: 1069px;
}

.lh-1069px {
  line-height: 1069px;
}

.h-1070 {
  height: 1070%;
}

.lh-1070 {
  line-height: 1070%;
}

.h-1070px {
  height: 1070px;
}

.lh-1070px {
  line-height: 1070px;
}

.h-1071 {
  height: 1071%;
}

.lh-1071 {
  line-height: 1071%;
}

.h-1071px {
  height: 1071px;
}

.lh-1071px {
  line-height: 1071px;
}

.h-1072 {
  height: 1072%;
}

.lh-1072 {
  line-height: 1072%;
}

.h-1072px {
  height: 1072px;
}

.lh-1072px {
  line-height: 1072px;
}

.h-1073 {
  height: 1073%;
}

.lh-1073 {
  line-height: 1073%;
}

.h-1073px {
  height: 1073px;
}

.lh-1073px {
  line-height: 1073px;
}

.h-1074 {
  height: 1074%;
}

.lh-1074 {
  line-height: 1074%;
}

.h-1074px {
  height: 1074px;
}

.lh-1074px {
  line-height: 1074px;
}

.h-1075 {
  height: 1075%;
}

.lh-1075 {
  line-height: 1075%;
}

.h-1075px {
  height: 1075px;
}

.lh-1075px {
  line-height: 1075px;
}

.h-1076 {
  height: 1076%;
}

.lh-1076 {
  line-height: 1076%;
}

.h-1076px {
  height: 1076px;
}

.lh-1076px {
  line-height: 1076px;
}

.h-1077 {
  height: 1077%;
}

.lh-1077 {
  line-height: 1077%;
}

.h-1077px {
  height: 1077px;
}

.lh-1077px {
  line-height: 1077px;
}

.h-1078 {
  height: 1078%;
}

.lh-1078 {
  line-height: 1078%;
}

.h-1078px {
  height: 1078px;
}

.lh-1078px {
  line-height: 1078px;
}

.h-1079 {
  height: 1079%;
}

.lh-1079 {
  line-height: 1079%;
}

.h-1079px {
  height: 1079px;
}

.lh-1079px {
  line-height: 1079px;
}

.h-1080 {
  height: 1080%;
}

.lh-1080 {
  line-height: 1080%;
}

.h-1080px {
  height: 1080px;
}

.lh-1080px {
  line-height: 1080px;
}

.h-1081 {
  height: 1081%;
}

.lh-1081 {
  line-height: 1081%;
}

.h-1081px {
  height: 1081px;
}

.lh-1081px {
  line-height: 1081px;
}

.h-1082 {
  height: 1082%;
}

.lh-1082 {
  line-height: 1082%;
}

.h-1082px {
  height: 1082px;
}

.lh-1082px {
  line-height: 1082px;
}

.h-1083 {
  height: 1083%;
}

.lh-1083 {
  line-height: 1083%;
}

.h-1083px {
  height: 1083px;
}

.lh-1083px {
  line-height: 1083px;
}

.h-1084 {
  height: 1084%;
}

.lh-1084 {
  line-height: 1084%;
}

.h-1084px {
  height: 1084px;
}

.lh-1084px {
  line-height: 1084px;
}

.h-1085 {
  height: 1085%;
}

.lh-1085 {
  line-height: 1085%;
}

.h-1085px {
  height: 1085px;
}

.lh-1085px {
  line-height: 1085px;
}

.h-1086 {
  height: 1086%;
}

.lh-1086 {
  line-height: 1086%;
}

.h-1086px {
  height: 1086px;
}

.lh-1086px {
  line-height: 1086px;
}

.h-1087 {
  height: 1087%;
}

.lh-1087 {
  line-height: 1087%;
}

.h-1087px {
  height: 1087px;
}

.lh-1087px {
  line-height: 1087px;
}

.h-1088 {
  height: 1088%;
}

.lh-1088 {
  line-height: 1088%;
}

.h-1088px {
  height: 1088px;
}

.lh-1088px {
  line-height: 1088px;
}

.h-1089 {
  height: 1089%;
}

.lh-1089 {
  line-height: 1089%;
}

.h-1089px {
  height: 1089px;
}

.lh-1089px {
  line-height: 1089px;
}

.h-1090 {
  height: 1090%;
}

.lh-1090 {
  line-height: 1090%;
}

.h-1090px {
  height: 1090px;
}

.lh-1090px {
  line-height: 1090px;
}

.h-1091 {
  height: 1091%;
}

.lh-1091 {
  line-height: 1091%;
}

.h-1091px {
  height: 1091px;
}

.lh-1091px {
  line-height: 1091px;
}

.h-1092 {
  height: 1092%;
}

.lh-1092 {
  line-height: 1092%;
}

.h-1092px {
  height: 1092px;
}

.lh-1092px {
  line-height: 1092px;
}

.h-1093 {
  height: 1093%;
}

.lh-1093 {
  line-height: 1093%;
}

.h-1093px {
  height: 1093px;
}

.lh-1093px {
  line-height: 1093px;
}

.h-1094 {
  height: 1094%;
}

.lh-1094 {
  line-height: 1094%;
}

.h-1094px {
  height: 1094px;
}

.lh-1094px {
  line-height: 1094px;
}

.h-1095 {
  height: 1095%;
}

.lh-1095 {
  line-height: 1095%;
}

.h-1095px {
  height: 1095px;
}

.lh-1095px {
  line-height: 1095px;
}

.h-1096 {
  height: 1096%;
}

.lh-1096 {
  line-height: 1096%;
}

.h-1096px {
  height: 1096px;
}

.lh-1096px {
  line-height: 1096px;
}

.h-1097 {
  height: 1097%;
}

.lh-1097 {
  line-height: 1097%;
}

.h-1097px {
  height: 1097px;
}

.lh-1097px {
  line-height: 1097px;
}

.h-1098 {
  height: 1098%;
}

.lh-1098 {
  line-height: 1098%;
}

.h-1098px {
  height: 1098px;
}

.lh-1098px {
  line-height: 1098px;
}

.h-1099 {
  height: 1099%;
}

.lh-1099 {
  line-height: 1099%;
}

.h-1099px {
  height: 1099px;
}

.lh-1099px {
  line-height: 1099px;
}

.h-1100 {
  height: 1100%;
}

.lh-1100 {
  line-height: 1100%;
}

.h-1100px {
  height: 1100px;
}

.lh-1100px {
  line-height: 1100px;
}

.h-1101 {
  height: 1101%;
}

.lh-1101 {
  line-height: 1101%;
}

.h-1101px {
  height: 1101px;
}

.lh-1101px {
  line-height: 1101px;
}

.h-1102 {
  height: 1102%;
}

.lh-1102 {
  line-height: 1102%;
}

.h-1102px {
  height: 1102px;
}

.lh-1102px {
  line-height: 1102px;
}

.h-1103 {
  height: 1103%;
}

.lh-1103 {
  line-height: 1103%;
}

.h-1103px {
  height: 1103px;
}

.lh-1103px {
  line-height: 1103px;
}

.h-1104 {
  height: 1104%;
}

.lh-1104 {
  line-height: 1104%;
}

.h-1104px {
  height: 1104px;
}

.lh-1104px {
  line-height: 1104px;
}

.h-1105 {
  height: 1105%;
}

.lh-1105 {
  line-height: 1105%;
}

.h-1105px {
  height: 1105px;
}

.lh-1105px {
  line-height: 1105px;
}

.h-1106 {
  height: 1106%;
}

.lh-1106 {
  line-height: 1106%;
}

.h-1106px {
  height: 1106px;
}

.lh-1106px {
  line-height: 1106px;
}

.h-1107 {
  height: 1107%;
}

.lh-1107 {
  line-height: 1107%;
}

.h-1107px {
  height: 1107px;
}

.lh-1107px {
  line-height: 1107px;
}

.h-1108 {
  height: 1108%;
}

.lh-1108 {
  line-height: 1108%;
}

.h-1108px {
  height: 1108px;
}

.lh-1108px {
  line-height: 1108px;
}

.h-1109 {
  height: 1109%;
}

.lh-1109 {
  line-height: 1109%;
}

.h-1109px {
  height: 1109px;
}

.lh-1109px {
  line-height: 1109px;
}

.h-1110 {
  height: 1110%;
}

.lh-1110 {
  line-height: 1110%;
}

.h-1110px {
  height: 1110px;
}

.lh-1110px {
  line-height: 1110px;
}

.h-1111 {
  height: 1111%;
}

.lh-1111 {
  line-height: 1111%;
}

.h-1111px {
  height: 1111px;
}

.lh-1111px {
  line-height: 1111px;
}

.h-1112 {
  height: 1112%;
}

.lh-1112 {
  line-height: 1112%;
}

.h-1112px {
  height: 1112px;
}

.lh-1112px {
  line-height: 1112px;
}

.h-1113 {
  height: 1113%;
}

.lh-1113 {
  line-height: 1113%;
}

.h-1113px {
  height: 1113px;
}

.lh-1113px {
  line-height: 1113px;
}

.h-1114 {
  height: 1114%;
}

.lh-1114 {
  line-height: 1114%;
}

.h-1114px {
  height: 1114px;
}

.lh-1114px {
  line-height: 1114px;
}

.h-1115 {
  height: 1115%;
}

.lh-1115 {
  line-height: 1115%;
}

.h-1115px {
  height: 1115px;
}

.lh-1115px {
  line-height: 1115px;
}

.h-1116 {
  height: 1116%;
}

.lh-1116 {
  line-height: 1116%;
}

.h-1116px {
  height: 1116px;
}

.lh-1116px {
  line-height: 1116px;
}

.h-1117 {
  height: 1117%;
}

.lh-1117 {
  line-height: 1117%;
}

.h-1117px {
  height: 1117px;
}

.lh-1117px {
  line-height: 1117px;
}

.h-1118 {
  height: 1118%;
}

.lh-1118 {
  line-height: 1118%;
}

.h-1118px {
  height: 1118px;
}

.lh-1118px {
  line-height: 1118px;
}

.h-1119 {
  height: 1119%;
}

.lh-1119 {
  line-height: 1119%;
}

.h-1119px {
  height: 1119px;
}

.lh-1119px {
  line-height: 1119px;
}

.h-1120 {
  height: 1120%;
}

.lh-1120 {
  line-height: 1120%;
}

.h-1120px {
  height: 1120px;
}

.lh-1120px {
  line-height: 1120px;
}

.h-1121 {
  height: 1121%;
}

.lh-1121 {
  line-height: 1121%;
}

.h-1121px {
  height: 1121px;
}

.lh-1121px {
  line-height: 1121px;
}

.h-1122 {
  height: 1122%;
}

.lh-1122 {
  line-height: 1122%;
}

.h-1122px {
  height: 1122px;
}

.lh-1122px {
  line-height: 1122px;
}

.h-1123 {
  height: 1123%;
}

.lh-1123 {
  line-height: 1123%;
}

.h-1123px {
  height: 1123px;
}

.lh-1123px {
  line-height: 1123px;
}

.h-1124 {
  height: 1124%;
}

.lh-1124 {
  line-height: 1124%;
}

.h-1124px {
  height: 1124px;
}

.lh-1124px {
  line-height: 1124px;
}

.h-1125 {
  height: 1125%;
}

.lh-1125 {
  line-height: 1125%;
}

.h-1125px {
  height: 1125px;
}

.lh-1125px {
  line-height: 1125px;
}

.h-1126 {
  height: 1126%;
}

.lh-1126 {
  line-height: 1126%;
}

.h-1126px {
  height: 1126px;
}

.lh-1126px {
  line-height: 1126px;
}

.h-1127 {
  height: 1127%;
}

.lh-1127 {
  line-height: 1127%;
}

.h-1127px {
  height: 1127px;
}

.lh-1127px {
  line-height: 1127px;
}

.h-1128 {
  height: 1128%;
}

.lh-1128 {
  line-height: 1128%;
}

.h-1128px {
  height: 1128px;
}

.lh-1128px {
  line-height: 1128px;
}

.h-1129 {
  height: 1129%;
}

.lh-1129 {
  line-height: 1129%;
}

.h-1129px {
  height: 1129px;
}

.lh-1129px {
  line-height: 1129px;
}

.h-1130 {
  height: 1130%;
}

.lh-1130 {
  line-height: 1130%;
}

.h-1130px {
  height: 1130px;
}

.lh-1130px {
  line-height: 1130px;
}

.h-1131 {
  height: 1131%;
}

.lh-1131 {
  line-height: 1131%;
}

.h-1131px {
  height: 1131px;
}

.lh-1131px {
  line-height: 1131px;
}

.h-1132 {
  height: 1132%;
}

.lh-1132 {
  line-height: 1132%;
}

.h-1132px {
  height: 1132px;
}

.lh-1132px {
  line-height: 1132px;
}

.h-1133 {
  height: 1133%;
}

.lh-1133 {
  line-height: 1133%;
}

.h-1133px {
  height: 1133px;
}

.lh-1133px {
  line-height: 1133px;
}

.h-1134 {
  height: 1134%;
}

.lh-1134 {
  line-height: 1134%;
}

.h-1134px {
  height: 1134px;
}

.lh-1134px {
  line-height: 1134px;
}

.h-1135 {
  height: 1135%;
}

.lh-1135 {
  line-height: 1135%;
}

.h-1135px {
  height: 1135px;
}

.lh-1135px {
  line-height: 1135px;
}

.h-1136 {
  height: 1136%;
}

.lh-1136 {
  line-height: 1136%;
}

.h-1136px {
  height: 1136px;
}

.lh-1136px {
  line-height: 1136px;
}

.h-1137 {
  height: 1137%;
}

.lh-1137 {
  line-height: 1137%;
}

.h-1137px {
  height: 1137px;
}

.lh-1137px {
  line-height: 1137px;
}

.h-1138 {
  height: 1138%;
}

.lh-1138 {
  line-height: 1138%;
}

.h-1138px {
  height: 1138px;
}

.lh-1138px {
  line-height: 1138px;
}

.h-1139 {
  height: 1139%;
}

.lh-1139 {
  line-height: 1139%;
}

.h-1139px {
  height: 1139px;
}

.lh-1139px {
  line-height: 1139px;
}

.h-1140 {
  height: 1140%;
}

.lh-1140 {
  line-height: 1140%;
}

.h-1140px {
  height: 1140px;
}

.lh-1140px {
  line-height: 1140px;
}

.h-1141 {
  height: 1141%;
}

.lh-1141 {
  line-height: 1141%;
}

.h-1141px {
  height: 1141px;
}

.lh-1141px {
  line-height: 1141px;
}

.h-1142 {
  height: 1142%;
}

.lh-1142 {
  line-height: 1142%;
}

.h-1142px {
  height: 1142px;
}

.lh-1142px {
  line-height: 1142px;
}

.h-1143 {
  height: 1143%;
}

.lh-1143 {
  line-height: 1143%;
}

.h-1143px {
  height: 1143px;
}

.lh-1143px {
  line-height: 1143px;
}

.h-1144 {
  height: 1144%;
}

.lh-1144 {
  line-height: 1144%;
}

.h-1144px {
  height: 1144px;
}

.lh-1144px {
  line-height: 1144px;
}

.h-1145 {
  height: 1145%;
}

.lh-1145 {
  line-height: 1145%;
}

.h-1145px {
  height: 1145px;
}

.lh-1145px {
  line-height: 1145px;
}

.h-1146 {
  height: 1146%;
}

.lh-1146 {
  line-height: 1146%;
}

.h-1146px {
  height: 1146px;
}

.lh-1146px {
  line-height: 1146px;
}

.h-1147 {
  height: 1147%;
}

.lh-1147 {
  line-height: 1147%;
}

.h-1147px {
  height: 1147px;
}

.lh-1147px {
  line-height: 1147px;
}

.h-1148 {
  height: 1148%;
}

.lh-1148 {
  line-height: 1148%;
}

.h-1148px {
  height: 1148px;
}

.lh-1148px {
  line-height: 1148px;
}

.h-1149 {
  height: 1149%;
}

.lh-1149 {
  line-height: 1149%;
}

.h-1149px {
  height: 1149px;
}

.lh-1149px {
  line-height: 1149px;
}

.h-1150 {
  height: 1150%;
}

.lh-1150 {
  line-height: 1150%;
}

.h-1150px {
  height: 1150px;
}

.lh-1150px {
  line-height: 1150px;
}

.h-1151 {
  height: 1151%;
}

.lh-1151 {
  line-height: 1151%;
}

.h-1151px {
  height: 1151px;
}

.lh-1151px {
  line-height: 1151px;
}

.h-1152 {
  height: 1152%;
}

.lh-1152 {
  line-height: 1152%;
}

.h-1152px {
  height: 1152px;
}

.lh-1152px {
  line-height: 1152px;
}

.h-1153 {
  height: 1153%;
}

.lh-1153 {
  line-height: 1153%;
}

.h-1153px {
  height: 1153px;
}

.lh-1153px {
  line-height: 1153px;
}

.h-1154 {
  height: 1154%;
}

.lh-1154 {
  line-height: 1154%;
}

.h-1154px {
  height: 1154px;
}

.lh-1154px {
  line-height: 1154px;
}

.h-1155 {
  height: 1155%;
}

.lh-1155 {
  line-height: 1155%;
}

.h-1155px {
  height: 1155px;
}

.lh-1155px {
  line-height: 1155px;
}

.h-1156 {
  height: 1156%;
}

.lh-1156 {
  line-height: 1156%;
}

.h-1156px {
  height: 1156px;
}

.lh-1156px {
  line-height: 1156px;
}

.h-1157 {
  height: 1157%;
}

.lh-1157 {
  line-height: 1157%;
}

.h-1157px {
  height: 1157px;
}

.lh-1157px {
  line-height: 1157px;
}

.h-1158 {
  height: 1158%;
}

.lh-1158 {
  line-height: 1158%;
}

.h-1158px {
  height: 1158px;
}

.lh-1158px {
  line-height: 1158px;
}

.h-1159 {
  height: 1159%;
}

.lh-1159 {
  line-height: 1159%;
}

.h-1159px {
  height: 1159px;
}

.lh-1159px {
  line-height: 1159px;
}

.h-1160 {
  height: 1160%;
}

.lh-1160 {
  line-height: 1160%;
}

.h-1160px {
  height: 1160px;
}

.lh-1160px {
  line-height: 1160px;
}

.h-1161 {
  height: 1161%;
}

.lh-1161 {
  line-height: 1161%;
}

.h-1161px {
  height: 1161px;
}

.lh-1161px {
  line-height: 1161px;
}

.h-1162 {
  height: 1162%;
}

.lh-1162 {
  line-height: 1162%;
}

.h-1162px {
  height: 1162px;
}

.lh-1162px {
  line-height: 1162px;
}

.h-1163 {
  height: 1163%;
}

.lh-1163 {
  line-height: 1163%;
}

.h-1163px {
  height: 1163px;
}

.lh-1163px {
  line-height: 1163px;
}

.h-1164 {
  height: 1164%;
}

.lh-1164 {
  line-height: 1164%;
}

.h-1164px {
  height: 1164px;
}

.lh-1164px {
  line-height: 1164px;
}

.h-1165 {
  height: 1165%;
}

.lh-1165 {
  line-height: 1165%;
}

.h-1165px {
  height: 1165px;
}

.lh-1165px {
  line-height: 1165px;
}

.h-1166 {
  height: 1166%;
}

.lh-1166 {
  line-height: 1166%;
}

.h-1166px {
  height: 1166px;
}

.lh-1166px {
  line-height: 1166px;
}

.h-1167 {
  height: 1167%;
}

.lh-1167 {
  line-height: 1167%;
}

.h-1167px {
  height: 1167px;
}

.lh-1167px {
  line-height: 1167px;
}

.h-1168 {
  height: 1168%;
}

.lh-1168 {
  line-height: 1168%;
}

.h-1168px {
  height: 1168px;
}

.lh-1168px {
  line-height: 1168px;
}

.h-1169 {
  height: 1169%;
}

.lh-1169 {
  line-height: 1169%;
}

.h-1169px {
  height: 1169px;
}

.lh-1169px {
  line-height: 1169px;
}

.h-1170 {
  height: 1170%;
}

.lh-1170 {
  line-height: 1170%;
}

.h-1170px {
  height: 1170px;
}

.lh-1170px {
  line-height: 1170px;
}

.h-1171 {
  height: 1171%;
}

.lh-1171 {
  line-height: 1171%;
}

.h-1171px {
  height: 1171px;
}

.lh-1171px {
  line-height: 1171px;
}

.h-1172 {
  height: 1172%;
}

.lh-1172 {
  line-height: 1172%;
}

.h-1172px {
  height: 1172px;
}

.lh-1172px {
  line-height: 1172px;
}

.h-1173 {
  height: 1173%;
}

.lh-1173 {
  line-height: 1173%;
}

.h-1173px {
  height: 1173px;
}

.lh-1173px {
  line-height: 1173px;
}

.h-1174 {
  height: 1174%;
}

.lh-1174 {
  line-height: 1174%;
}

.h-1174px {
  height: 1174px;
}

.lh-1174px {
  line-height: 1174px;
}

.h-1175 {
  height: 1175%;
}

.lh-1175 {
  line-height: 1175%;
}

.h-1175px {
  height: 1175px;
}

.lh-1175px {
  line-height: 1175px;
}

.h-1176 {
  height: 1176%;
}

.lh-1176 {
  line-height: 1176%;
}

.h-1176px {
  height: 1176px;
}

.lh-1176px {
  line-height: 1176px;
}

.h-1177 {
  height: 1177%;
}

.lh-1177 {
  line-height: 1177%;
}

.h-1177px {
  height: 1177px;
}

.lh-1177px {
  line-height: 1177px;
}

.h-1178 {
  height: 1178%;
}

.lh-1178 {
  line-height: 1178%;
}

.h-1178px {
  height: 1178px;
}

.lh-1178px {
  line-height: 1178px;
}

.h-1179 {
  height: 1179%;
}

.lh-1179 {
  line-height: 1179%;
}

.h-1179px {
  height: 1179px;
}

.lh-1179px {
  line-height: 1179px;
}

.h-1180 {
  height: 1180%;
}

.lh-1180 {
  line-height: 1180%;
}

.h-1180px {
  height: 1180px;
}

.lh-1180px {
  line-height: 1180px;
}

.h-1181 {
  height: 1181%;
}

.lh-1181 {
  line-height: 1181%;
}

.h-1181px {
  height: 1181px;
}

.lh-1181px {
  line-height: 1181px;
}

.h-1182 {
  height: 1182%;
}

.lh-1182 {
  line-height: 1182%;
}

.h-1182px {
  height: 1182px;
}

.lh-1182px {
  line-height: 1182px;
}

.h-1183 {
  height: 1183%;
}

.lh-1183 {
  line-height: 1183%;
}

.h-1183px {
  height: 1183px;
}

.lh-1183px {
  line-height: 1183px;
}

.h-1184 {
  height: 1184%;
}

.lh-1184 {
  line-height: 1184%;
}

.h-1184px {
  height: 1184px;
}

.lh-1184px {
  line-height: 1184px;
}

.h-1185 {
  height: 1185%;
}

.lh-1185 {
  line-height: 1185%;
}

.h-1185px {
  height: 1185px;
}

.lh-1185px {
  line-height: 1185px;
}

.h-1186 {
  height: 1186%;
}

.lh-1186 {
  line-height: 1186%;
}

.h-1186px {
  height: 1186px;
}

.lh-1186px {
  line-height: 1186px;
}

.h-1187 {
  height: 1187%;
}

.lh-1187 {
  line-height: 1187%;
}

.h-1187px {
  height: 1187px;
}

.lh-1187px {
  line-height: 1187px;
}

.h-1188 {
  height: 1188%;
}

.lh-1188 {
  line-height: 1188%;
}

.h-1188px {
  height: 1188px;
}

.lh-1188px {
  line-height: 1188px;
}

.h-1189 {
  height: 1189%;
}

.lh-1189 {
  line-height: 1189%;
}

.h-1189px {
  height: 1189px;
}

.lh-1189px {
  line-height: 1189px;
}

.h-1190 {
  height: 1190%;
}

.lh-1190 {
  line-height: 1190%;
}

.h-1190px {
  height: 1190px;
}

.lh-1190px {
  line-height: 1190px;
}

.h-1191 {
  height: 1191%;
}

.lh-1191 {
  line-height: 1191%;
}

.h-1191px {
  height: 1191px;
}

.lh-1191px {
  line-height: 1191px;
}

.h-1192 {
  height: 1192%;
}

.lh-1192 {
  line-height: 1192%;
}

.h-1192px {
  height: 1192px;
}

.lh-1192px {
  line-height: 1192px;
}

.h-1193 {
  height: 1193%;
}

.lh-1193 {
  line-height: 1193%;
}

.h-1193px {
  height: 1193px;
}

.lh-1193px {
  line-height: 1193px;
}

.h-1194 {
  height: 1194%;
}

.lh-1194 {
  line-height: 1194%;
}

.h-1194px {
  height: 1194px;
}

.lh-1194px {
  line-height: 1194px;
}

.h-1195 {
  height: 1195%;
}

.lh-1195 {
  line-height: 1195%;
}

.h-1195px {
  height: 1195px;
}

.lh-1195px {
  line-height: 1195px;
}

.h-1196 {
  height: 1196%;
}

.lh-1196 {
  line-height: 1196%;
}

.h-1196px {
  height: 1196px;
}

.lh-1196px {
  line-height: 1196px;
}

.h-1197 {
  height: 1197%;
}

.lh-1197 {
  line-height: 1197%;
}

.h-1197px {
  height: 1197px;
}

.lh-1197px {
  line-height: 1197px;
}

.h-1198 {
  height: 1198%;
}

.lh-1198 {
  line-height: 1198%;
}

.h-1198px {
  height: 1198px;
}

.lh-1198px {
  line-height: 1198px;
}

.h-1199 {
  height: 1199%;
}

.lh-1199 {
  line-height: 1199%;
}

.h-1199px {
  height: 1199px;
}

.lh-1199px {
  line-height: 1199px;
}

.h-1200 {
  height: 1200%;
}

.lh-1200 {
  line-height: 1200%;
}

.h-1200px {
  height: 1200px;
}

.lh-1200px {
  line-height: 1200px;
}

/* purgecss start ignore */
.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-23 {
  font-size: 23px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-25 {
  font-size: 25px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-27 {
  font-size: 27px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-29 {
  font-size: 29px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-31 {
  font-size: 31px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-33 {
  font-size: 33px;
}

.font-size-34 {
  font-size: 34px;
}

.font-size-35 {
  font-size: 35px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-37 {
  font-size: 37px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-39 {
  font-size: 39px;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-41 {
  font-size: 41px;
}

.font-size-42 {
  font-size: 42px;
}

.font-size-43 {
  font-size: 43px;
}

.font-size-44 {
  font-size: 44px;
}

.font-size-45 {
  font-size: 45px;
}

.font-size-46 {
  font-size: 46px;
}

.font-size-47 {
  font-size: 47px;
}

.font-size-48 {
  font-size: 48px;
}

.font-size-49 {
  font-size: 49px;
}

.font-size-50 {
  font-size: 50px;
}

.font-size-51 {
  font-size: 51px;
}

.font-size-52 {
  font-size: 52px;
}

.font-size-53 {
  font-size: 53px;
}

.font-size-54 {
  font-size: 54px;
}

.font-size-55 {
  font-size: 55px;
}

.font-size-56 {
  font-size: 56px;
}

.font-size-57 {
  font-size: 57px;
}

.font-size-58 {
  font-size: 58px;
}

.font-size-59 {
  font-size: 59px;
}

.font-size-60 {
  font-size: 60px;
}

.font-size-61 {
  font-size: 61px;
}

.font-size-62 {
  font-size: 62px;
}

.font-size-63 {
  font-size: 63px;
}

.font-size-64 {
  font-size: 64px;
}

.font-size-65 {
  font-size: 65px;
}

.font-size-66 {
  font-size: 66px;
}

.font-size-67 {
  font-size: 67px;
}

.font-size-68 {
  font-size: 68px;
}

.font-size-69 {
  font-size: 69px;
}

.font-size-70 {
  font-size: 70px;
}

.font-size-71 {
  font-size: 71px;
}

.font-size-72 {
  font-size: 72px;
}

.font-size-73 {
  font-size: 73px;
}

.font-size-74 {
  font-size: 74px;
}

.font-size-75 {
  font-size: 75px;
}

.font-size-76 {
  font-size: 76px;
}

.font-size-77 {
  font-size: 77px;
}

.font-size-78 {
  font-size: 78px;
}

.font-size-79 {
  font-size: 79px;
}

.font-size-80 {
  font-size: 80px;
}

.font-size-81 {
  font-size: 81px;
}

.font-size-82 {
  font-size: 82px;
}

.font-size-83 {
  font-size: 83px;
}

.font-size-84 {
  font-size: 84px;
}

.font-size-85 {
  font-size: 85px;
}

.font-size-86 {
  font-size: 86px;
}

.font-size-87 {
  font-size: 87px;
}

.font-size-88 {
  font-size: 88px;
}

.font-size-89 {
  font-size: 89px;
}

.font-size-90 {
  font-size: 90px;
}

.font-size-91 {
  font-size: 91px;
}

.font-size-92 {
  font-size: 92px;
}

.font-size-93 {
  font-size: 93px;
}

.font-size-94 {
  font-size: 94px;
}

.font-size-95 {
  font-size: 95px;
}

.font-size-96 {
  font-size: 96px;
}

.font-size-97 {
  font-size: 97px;
}

.font-size-98 {
  font-size: 98px;
}

.font-size-99 {
  font-size: 99px;
}

.font-size-100 {
  font-size: 100px;
}

.font-size-xs-10 {
  font-size: 10px;
}

.font-size-xs-11 {
  font-size: 11px;
}

.font-size-xs-12 {
  font-size: 12px;
}

.font-size-xs-13 {
  font-size: 13px;
}

.font-size-xs-14 {
  font-size: 14px;
}

.font-size-xs-15 {
  font-size: 15px;
}

.font-size-xs-16 {
  font-size: 16px;
}

.font-size-xs-17 {
  font-size: 17px;
}

.font-size-xs-18 {
  font-size: 18px;
}

.font-size-xs-19 {
  font-size: 19px;
}

.font-size-xs-20 {
  font-size: 20px;
}

.font-size-xs-21 {
  font-size: 21px;
}

.font-size-xs-22 {
  font-size: 22px;
}

.font-size-xs-23 {
  font-size: 23px;
}

.font-size-xs-24 {
  font-size: 24px;
}

.font-size-xs-25 {
  font-size: 25px;
}

.font-size-xs-26 {
  font-size: 26px;
}

.font-size-xs-27 {
  font-size: 27px;
}

.font-size-xs-28 {
  font-size: 28px;
}

.font-size-xs-29 {
  font-size: 29px;
}

.font-size-xs-30 {
  font-size: 30px;
}

.font-size-xs-31 {
  font-size: 31px;
}

.font-size-xs-32 {
  font-size: 32px;
}

.font-size-xs-33 {
  font-size: 33px;
}

.font-size-xs-34 {
  font-size: 34px;
}

.font-size-xs-35 {
  font-size: 35px;
}

.font-size-xs-36 {
  font-size: 36px;
}

.font-size-xs-37 {
  font-size: 37px;
}

.font-size-xs-38 {
  font-size: 38px;
}

.font-size-xs-39 {
  font-size: 39px;
}

.font-size-xs-40 {
  font-size: 40px;
}

.font-size-xs-41 {
  font-size: 41px;
}

.font-size-xs-42 {
  font-size: 42px;
}

.font-size-xs-43 {
  font-size: 43px;
}

.font-size-xs-44 {
  font-size: 44px;
}

.font-size-xs-45 {
  font-size: 45px;
}

.font-size-xs-46 {
  font-size: 46px;
}

.font-size-xs-47 {
  font-size: 47px;
}

.font-size-xs-48 {
  font-size: 48px;
}

.font-size-xs-49 {
  font-size: 49px;
}

.font-size-xs-50 {
  font-size: 50px;
}

.font-size-xs-51 {
  font-size: 51px;
}

.font-size-xs-52 {
  font-size: 52px;
}

.font-size-xs-53 {
  font-size: 53px;
}

.font-size-xs-54 {
  font-size: 54px;
}

.font-size-xs-55 {
  font-size: 55px;
}

.font-size-xs-56 {
  font-size: 56px;
}

.font-size-xs-57 {
  font-size: 57px;
}

.font-size-xs-58 {
  font-size: 58px;
}

.font-size-xs-59 {
  font-size: 59px;
}

.font-size-xs-60 {
  font-size: 60px;
}

.font-size-xs-61 {
  font-size: 61px;
}

.font-size-xs-62 {
  font-size: 62px;
}

.font-size-xs-63 {
  font-size: 63px;
}

.font-size-xs-64 {
  font-size: 64px;
}

.font-size-xs-65 {
  font-size: 65px;
}

.font-size-xs-66 {
  font-size: 66px;
}

.font-size-xs-67 {
  font-size: 67px;
}

.font-size-xs-68 {
  font-size: 68px;
}

.font-size-xs-69 {
  font-size: 69px;
}

.font-size-xs-70 {
  font-size: 70px;
}

.font-size-xs-71 {
  font-size: 71px;
}

.font-size-xs-72 {
  font-size: 72px;
}

.font-size-xs-73 {
  font-size: 73px;
}

.font-size-xs-74 {
  font-size: 74px;
}

.font-size-xs-75 {
  font-size: 75px;
}

.font-size-xs-76 {
  font-size: 76px;
}

.font-size-xs-77 {
  font-size: 77px;
}

.font-size-xs-78 {
  font-size: 78px;
}

.font-size-xs-79 {
  font-size: 79px;
}

.font-size-xs-80 {
  font-size: 80px;
}

.font-size-xs-81 {
  font-size: 81px;
}

.font-size-xs-82 {
  font-size: 82px;
}

.font-size-xs-83 {
  font-size: 83px;
}

.font-size-xs-84 {
  font-size: 84px;
}

.font-size-xs-85 {
  font-size: 85px;
}

.font-size-xs-86 {
  font-size: 86px;
}

.font-size-xs-87 {
  font-size: 87px;
}

.font-size-xs-88 {
  font-size: 88px;
}

.font-size-xs-89 {
  font-size: 89px;
}

.font-size-xs-90 {
  font-size: 90px;
}

.font-size-xs-91 {
  font-size: 91px;
}

.font-size-xs-92 {
  font-size: 92px;
}

.font-size-xs-93 {
  font-size: 93px;
}

.font-size-xs-94 {
  font-size: 94px;
}

.font-size-xs-95 {
  font-size: 95px;
}

.font-size-xs-96 {
  font-size: 96px;
}

.font-size-xs-97 {
  font-size: 97px;
}

.font-size-xs-98 {
  font-size: 98px;
}

.font-size-xs-99 {
  font-size: 99px;
}

.font-size-xs-100 {
  font-size: 100px;
}

@media (min-width: 576px) {
  .font-size-sm-10 {
    font-size: 10px;
  }
  .font-size-sm-11 {
    font-size: 11px;
  }
  .font-size-sm-12 {
    font-size: 12px;
  }
  .font-size-sm-13 {
    font-size: 13px;
  }
  .font-size-sm-14 {
    font-size: 14px;
  }
  .font-size-sm-15 {
    font-size: 15px;
  }
  .font-size-sm-16 {
    font-size: 16px;
  }
  .font-size-sm-17 {
    font-size: 17px;
  }
  .font-size-sm-18 {
    font-size: 18px;
  }
  .font-size-sm-19 {
    font-size: 19px;
  }
  .font-size-sm-20 {
    font-size: 20px;
  }
  .font-size-sm-21 {
    font-size: 21px;
  }
  .font-size-sm-22 {
    font-size: 22px;
  }
  .font-size-sm-23 {
    font-size: 23px;
  }
  .font-size-sm-24 {
    font-size: 24px;
  }
  .font-size-sm-25 {
    font-size: 25px;
  }
  .font-size-sm-26 {
    font-size: 26px;
  }
  .font-size-sm-27 {
    font-size: 27px;
  }
  .font-size-sm-28 {
    font-size: 28px;
  }
  .font-size-sm-29 {
    font-size: 29px;
  }
  .font-size-sm-30 {
    font-size: 30px;
  }
  .font-size-sm-31 {
    font-size: 31px;
  }
  .font-size-sm-32 {
    font-size: 32px;
  }
  .font-size-sm-33 {
    font-size: 33px;
  }
  .font-size-sm-34 {
    font-size: 34px;
  }
  .font-size-sm-35 {
    font-size: 35px;
  }
  .font-size-sm-36 {
    font-size: 36px;
  }
  .font-size-sm-37 {
    font-size: 37px;
  }
  .font-size-sm-38 {
    font-size: 38px;
  }
  .font-size-sm-39 {
    font-size: 39px;
  }
  .font-size-sm-40 {
    font-size: 40px;
  }
  .font-size-sm-41 {
    font-size: 41px;
  }
  .font-size-sm-42 {
    font-size: 42px;
  }
  .font-size-sm-43 {
    font-size: 43px;
  }
  .font-size-sm-44 {
    font-size: 44px;
  }
  .font-size-sm-45 {
    font-size: 45px;
  }
  .font-size-sm-46 {
    font-size: 46px;
  }
  .font-size-sm-47 {
    font-size: 47px;
  }
  .font-size-sm-48 {
    font-size: 48px;
  }
  .font-size-sm-49 {
    font-size: 49px;
  }
  .font-size-sm-50 {
    font-size: 50px;
  }
  .font-size-sm-51 {
    font-size: 51px;
  }
  .font-size-sm-52 {
    font-size: 52px;
  }
  .font-size-sm-53 {
    font-size: 53px;
  }
  .font-size-sm-54 {
    font-size: 54px;
  }
  .font-size-sm-55 {
    font-size: 55px;
  }
  .font-size-sm-56 {
    font-size: 56px;
  }
  .font-size-sm-57 {
    font-size: 57px;
  }
  .font-size-sm-58 {
    font-size: 58px;
  }
  .font-size-sm-59 {
    font-size: 59px;
  }
  .font-size-sm-60 {
    font-size: 60px;
  }
  .font-size-sm-61 {
    font-size: 61px;
  }
  .font-size-sm-62 {
    font-size: 62px;
  }
  .font-size-sm-63 {
    font-size: 63px;
  }
  .font-size-sm-64 {
    font-size: 64px;
  }
  .font-size-sm-65 {
    font-size: 65px;
  }
  .font-size-sm-66 {
    font-size: 66px;
  }
  .font-size-sm-67 {
    font-size: 67px;
  }
  .font-size-sm-68 {
    font-size: 68px;
  }
  .font-size-sm-69 {
    font-size: 69px;
  }
  .font-size-sm-70 {
    font-size: 70px;
  }
  .font-size-sm-71 {
    font-size: 71px;
  }
  .font-size-sm-72 {
    font-size: 72px;
  }
  .font-size-sm-73 {
    font-size: 73px;
  }
  .font-size-sm-74 {
    font-size: 74px;
  }
  .font-size-sm-75 {
    font-size: 75px;
  }
  .font-size-sm-76 {
    font-size: 76px;
  }
  .font-size-sm-77 {
    font-size: 77px;
  }
  .font-size-sm-78 {
    font-size: 78px;
  }
  .font-size-sm-79 {
    font-size: 79px;
  }
  .font-size-sm-80 {
    font-size: 80px;
  }
  .font-size-sm-81 {
    font-size: 81px;
  }
  .font-size-sm-82 {
    font-size: 82px;
  }
  .font-size-sm-83 {
    font-size: 83px;
  }
  .font-size-sm-84 {
    font-size: 84px;
  }
  .font-size-sm-85 {
    font-size: 85px;
  }
  .font-size-sm-86 {
    font-size: 86px;
  }
  .font-size-sm-87 {
    font-size: 87px;
  }
  .font-size-sm-88 {
    font-size: 88px;
  }
  .font-size-sm-89 {
    font-size: 89px;
  }
  .font-size-sm-90 {
    font-size: 90px;
  }
  .font-size-sm-91 {
    font-size: 91px;
  }
  .font-size-sm-92 {
    font-size: 92px;
  }
  .font-size-sm-93 {
    font-size: 93px;
  }
  .font-size-sm-94 {
    font-size: 94px;
  }
  .font-size-sm-95 {
    font-size: 95px;
  }
  .font-size-sm-96 {
    font-size: 96px;
  }
  .font-size-sm-97 {
    font-size: 97px;
  }
  .font-size-sm-98 {
    font-size: 98px;
  }
  .font-size-sm-99 {
    font-size: 99px;
  }
  .font-size-sm-100 {
    font-size: 100px;
  }
}
@media (min-width: 768px) {
  .font-size-md-10 {
    font-size: 10px;
  }
  .font-size-md-11 {
    font-size: 11px;
  }
  .font-size-md-12 {
    font-size: 12px;
  }
  .font-size-md-13 {
    font-size: 13px;
  }
  .font-size-md-14 {
    font-size: 14px;
  }
  .font-size-md-15 {
    font-size: 15px;
  }
  .font-size-md-16 {
    font-size: 16px;
  }
  .font-size-md-17 {
    font-size: 17px;
  }
  .font-size-md-18 {
    font-size: 18px;
  }
  .font-size-md-19 {
    font-size: 19px;
  }
  .font-size-md-20 {
    font-size: 20px;
  }
  .font-size-md-21 {
    font-size: 21px;
  }
  .font-size-md-22 {
    font-size: 22px;
  }
  .font-size-md-23 {
    font-size: 23px;
  }
  .font-size-md-24 {
    font-size: 24px;
  }
  .font-size-md-25 {
    font-size: 25px;
  }
  .font-size-md-26 {
    font-size: 26px;
  }
  .font-size-md-27 {
    font-size: 27px;
  }
  .font-size-md-28 {
    font-size: 28px;
  }
  .font-size-md-29 {
    font-size: 29px;
  }
  .font-size-md-30 {
    font-size: 30px;
  }
  .font-size-md-31 {
    font-size: 31px;
  }
  .font-size-md-32 {
    font-size: 32px;
  }
  .font-size-md-33 {
    font-size: 33px;
  }
  .font-size-md-34 {
    font-size: 34px;
  }
  .font-size-md-35 {
    font-size: 35px;
  }
  .font-size-md-36 {
    font-size: 36px;
  }
  .font-size-md-37 {
    font-size: 37px;
  }
  .font-size-md-38 {
    font-size: 38px;
  }
  .font-size-md-39 {
    font-size: 39px;
  }
  .font-size-md-40 {
    font-size: 40px;
  }
  .font-size-md-41 {
    font-size: 41px;
  }
  .font-size-md-42 {
    font-size: 42px;
  }
  .font-size-md-43 {
    font-size: 43px;
  }
  .font-size-md-44 {
    font-size: 44px;
  }
  .font-size-md-45 {
    font-size: 45px;
  }
  .font-size-md-46 {
    font-size: 46px;
  }
  .font-size-md-47 {
    font-size: 47px;
  }
  .font-size-md-48 {
    font-size: 48px;
  }
  .font-size-md-49 {
    font-size: 49px;
  }
  .font-size-md-50 {
    font-size: 50px;
  }
  .font-size-md-51 {
    font-size: 51px;
  }
  .font-size-md-52 {
    font-size: 52px;
  }
  .font-size-md-53 {
    font-size: 53px;
  }
  .font-size-md-54 {
    font-size: 54px;
  }
  .font-size-md-55 {
    font-size: 55px;
  }
  .font-size-md-56 {
    font-size: 56px;
  }
  .font-size-md-57 {
    font-size: 57px;
  }
  .font-size-md-58 {
    font-size: 58px;
  }
  .font-size-md-59 {
    font-size: 59px;
  }
  .font-size-md-60 {
    font-size: 60px;
  }
  .font-size-md-61 {
    font-size: 61px;
  }
  .font-size-md-62 {
    font-size: 62px;
  }
  .font-size-md-63 {
    font-size: 63px;
  }
  .font-size-md-64 {
    font-size: 64px;
  }
  .font-size-md-65 {
    font-size: 65px;
  }
  .font-size-md-66 {
    font-size: 66px;
  }
  .font-size-md-67 {
    font-size: 67px;
  }
  .font-size-md-68 {
    font-size: 68px;
  }
  .font-size-md-69 {
    font-size: 69px;
  }
  .font-size-md-70 {
    font-size: 70px;
  }
  .font-size-md-71 {
    font-size: 71px;
  }
  .font-size-md-72 {
    font-size: 72px;
  }
  .font-size-md-73 {
    font-size: 73px;
  }
  .font-size-md-74 {
    font-size: 74px;
  }
  .font-size-md-75 {
    font-size: 75px;
  }
  .font-size-md-76 {
    font-size: 76px;
  }
  .font-size-md-77 {
    font-size: 77px;
  }
  .font-size-md-78 {
    font-size: 78px;
  }
  .font-size-md-79 {
    font-size: 79px;
  }
  .font-size-md-80 {
    font-size: 80px;
  }
  .font-size-md-81 {
    font-size: 81px;
  }
  .font-size-md-82 {
    font-size: 82px;
  }
  .font-size-md-83 {
    font-size: 83px;
  }
  .font-size-md-84 {
    font-size: 84px;
  }
  .font-size-md-85 {
    font-size: 85px;
  }
  .font-size-md-86 {
    font-size: 86px;
  }
  .font-size-md-87 {
    font-size: 87px;
  }
  .font-size-md-88 {
    font-size: 88px;
  }
  .font-size-md-89 {
    font-size: 89px;
  }
  .font-size-md-90 {
    font-size: 90px;
  }
  .font-size-md-91 {
    font-size: 91px;
  }
  .font-size-md-92 {
    font-size: 92px;
  }
  .font-size-md-93 {
    font-size: 93px;
  }
  .font-size-md-94 {
    font-size: 94px;
  }
  .font-size-md-95 {
    font-size: 95px;
  }
  .font-size-md-96 {
    font-size: 96px;
  }
  .font-size-md-97 {
    font-size: 97px;
  }
  .font-size-md-98 {
    font-size: 98px;
  }
  .font-size-md-99 {
    font-size: 99px;
  }
  .font-size-md-100 {
    font-size: 100px;
  }
}
@media (min-width: 992px) {
  .font-size-lg-10 {
    font-size: 10px;
  }
  .font-size-lg-11 {
    font-size: 11px;
  }
  .font-size-lg-12 {
    font-size: 12px;
  }
  .font-size-lg-13 {
    font-size: 13px;
  }
  .font-size-lg-14 {
    font-size: 14px;
  }
  .font-size-lg-15 {
    font-size: 15px;
  }
  .font-size-lg-16 {
    font-size: 16px;
  }
  .font-size-lg-17 {
    font-size: 17px;
  }
  .font-size-lg-18 {
    font-size: 18px;
  }
  .font-size-lg-19 {
    font-size: 19px;
  }
  .font-size-lg-20 {
    font-size: 20px;
  }
  .font-size-lg-21 {
    font-size: 21px;
  }
  .font-size-lg-22 {
    font-size: 22px;
  }
  .font-size-lg-23 {
    font-size: 23px;
  }
  .font-size-lg-24 {
    font-size: 24px;
  }
  .font-size-lg-25 {
    font-size: 25px;
  }
  .font-size-lg-26 {
    font-size: 26px;
  }
  .font-size-lg-27 {
    font-size: 27px;
  }
  .font-size-lg-28 {
    font-size: 28px;
  }
  .font-size-lg-29 {
    font-size: 29px;
  }
  .font-size-lg-30 {
    font-size: 30px;
  }
  .font-size-lg-31 {
    font-size: 31px;
  }
  .font-size-lg-32 {
    font-size: 32px;
  }
  .font-size-lg-33 {
    font-size: 33px;
  }
  .font-size-lg-34 {
    font-size: 34px;
  }
  .font-size-lg-35 {
    font-size: 35px;
  }
  .font-size-lg-36 {
    font-size: 36px;
  }
  .font-size-lg-37 {
    font-size: 37px;
  }
  .font-size-lg-38 {
    font-size: 38px;
  }
  .font-size-lg-39 {
    font-size: 39px;
  }
  .font-size-lg-40 {
    font-size: 40px;
  }
  .font-size-lg-41 {
    font-size: 41px;
  }
  .font-size-lg-42 {
    font-size: 42px;
  }
  .font-size-lg-43 {
    font-size: 43px;
  }
  .font-size-lg-44 {
    font-size: 44px;
  }
  .font-size-lg-45 {
    font-size: 45px;
  }
  .font-size-lg-46 {
    font-size: 46px;
  }
  .font-size-lg-47 {
    font-size: 47px;
  }
  .font-size-lg-48 {
    font-size: 48px;
  }
  .font-size-lg-49 {
    font-size: 49px;
  }
  .font-size-lg-50 {
    font-size: 50px;
  }
  .font-size-lg-51 {
    font-size: 51px;
  }
  .font-size-lg-52 {
    font-size: 52px;
  }
  .font-size-lg-53 {
    font-size: 53px;
  }
  .font-size-lg-54 {
    font-size: 54px;
  }
  .font-size-lg-55 {
    font-size: 55px;
  }
  .font-size-lg-56 {
    font-size: 56px;
  }
  .font-size-lg-57 {
    font-size: 57px;
  }
  .font-size-lg-58 {
    font-size: 58px;
  }
  .font-size-lg-59 {
    font-size: 59px;
  }
  .font-size-lg-60 {
    font-size: 60px;
  }
  .font-size-lg-61 {
    font-size: 61px;
  }
  .font-size-lg-62 {
    font-size: 62px;
  }
  .font-size-lg-63 {
    font-size: 63px;
  }
  .font-size-lg-64 {
    font-size: 64px;
  }
  .font-size-lg-65 {
    font-size: 65px;
  }
  .font-size-lg-66 {
    font-size: 66px;
  }
  .font-size-lg-67 {
    font-size: 67px;
  }
  .font-size-lg-68 {
    font-size: 68px;
  }
  .font-size-lg-69 {
    font-size: 69px;
  }
  .font-size-lg-70 {
    font-size: 70px;
  }
  .font-size-lg-71 {
    font-size: 71px;
  }
  .font-size-lg-72 {
    font-size: 72px;
  }
  .font-size-lg-73 {
    font-size: 73px;
  }
  .font-size-lg-74 {
    font-size: 74px;
  }
  .font-size-lg-75 {
    font-size: 75px;
  }
  .font-size-lg-76 {
    font-size: 76px;
  }
  .font-size-lg-77 {
    font-size: 77px;
  }
  .font-size-lg-78 {
    font-size: 78px;
  }
  .font-size-lg-79 {
    font-size: 79px;
  }
  .font-size-lg-80 {
    font-size: 80px;
  }
  .font-size-lg-81 {
    font-size: 81px;
  }
  .font-size-lg-82 {
    font-size: 82px;
  }
  .font-size-lg-83 {
    font-size: 83px;
  }
  .font-size-lg-84 {
    font-size: 84px;
  }
  .font-size-lg-85 {
    font-size: 85px;
  }
  .font-size-lg-86 {
    font-size: 86px;
  }
  .font-size-lg-87 {
    font-size: 87px;
  }
  .font-size-lg-88 {
    font-size: 88px;
  }
  .font-size-lg-89 {
    font-size: 89px;
  }
  .font-size-lg-90 {
    font-size: 90px;
  }
  .font-size-lg-91 {
    font-size: 91px;
  }
  .font-size-lg-92 {
    font-size: 92px;
  }
  .font-size-lg-93 {
    font-size: 93px;
  }
  .font-size-lg-94 {
    font-size: 94px;
  }
  .font-size-lg-95 {
    font-size: 95px;
  }
  .font-size-lg-96 {
    font-size: 96px;
  }
  .font-size-lg-97 {
    font-size: 97px;
  }
  .font-size-lg-98 {
    font-size: 98px;
  }
  .font-size-lg-99 {
    font-size: 99px;
  }
  .font-size-lg-100 {
    font-size: 100px;
  }
}
@media (min-width: 1200px) {
  .font-size-xl-10 {
    font-size: 10px;
  }
  .font-size-xl-11 {
    font-size: 11px;
  }
  .font-size-xl-12 {
    font-size: 12px;
  }
  .font-size-xl-13 {
    font-size: 13px;
  }
  .font-size-xl-14 {
    font-size: 14px;
  }
  .font-size-xl-15 {
    font-size: 15px;
  }
  .font-size-xl-16 {
    font-size: 16px;
  }
  .font-size-xl-17 {
    font-size: 17px;
  }
  .font-size-xl-18 {
    font-size: 18px;
  }
  .font-size-xl-19 {
    font-size: 19px;
  }
  .font-size-xl-20 {
    font-size: 20px;
  }
  .font-size-xl-21 {
    font-size: 21px;
  }
  .font-size-xl-22 {
    font-size: 22px;
  }
  .font-size-xl-23 {
    font-size: 23px;
  }
  .font-size-xl-24 {
    font-size: 24px;
  }
  .font-size-xl-25 {
    font-size: 25px;
  }
  .font-size-xl-26 {
    font-size: 26px;
  }
  .font-size-xl-27 {
    font-size: 27px;
  }
  .font-size-xl-28 {
    font-size: 28px;
  }
  .font-size-xl-29 {
    font-size: 29px;
  }
  .font-size-xl-30 {
    font-size: 30px;
  }
  .font-size-xl-31 {
    font-size: 31px;
  }
  .font-size-xl-32 {
    font-size: 32px;
  }
  .font-size-xl-33 {
    font-size: 33px;
  }
  .font-size-xl-34 {
    font-size: 34px;
  }
  .font-size-xl-35 {
    font-size: 35px;
  }
  .font-size-xl-36 {
    font-size: 36px;
  }
  .font-size-xl-37 {
    font-size: 37px;
  }
  .font-size-xl-38 {
    font-size: 38px;
  }
  .font-size-xl-39 {
    font-size: 39px;
  }
  .font-size-xl-40 {
    font-size: 40px;
  }
  .font-size-xl-41 {
    font-size: 41px;
  }
  .font-size-xl-42 {
    font-size: 42px;
  }
  .font-size-xl-43 {
    font-size: 43px;
  }
  .font-size-xl-44 {
    font-size: 44px;
  }
  .font-size-xl-45 {
    font-size: 45px;
  }
  .font-size-xl-46 {
    font-size: 46px;
  }
  .font-size-xl-47 {
    font-size: 47px;
  }
  .font-size-xl-48 {
    font-size: 48px;
  }
  .font-size-xl-49 {
    font-size: 49px;
  }
  .font-size-xl-50 {
    font-size: 50px;
  }
  .font-size-xl-51 {
    font-size: 51px;
  }
  .font-size-xl-52 {
    font-size: 52px;
  }
  .font-size-xl-53 {
    font-size: 53px;
  }
  .font-size-xl-54 {
    font-size: 54px;
  }
  .font-size-xl-55 {
    font-size: 55px;
  }
  .font-size-xl-56 {
    font-size: 56px;
  }
  .font-size-xl-57 {
    font-size: 57px;
  }
  .font-size-xl-58 {
    font-size: 58px;
  }
  .font-size-xl-59 {
    font-size: 59px;
  }
  .font-size-xl-60 {
    font-size: 60px;
  }
  .font-size-xl-61 {
    font-size: 61px;
  }
  .font-size-xl-62 {
    font-size: 62px;
  }
  .font-size-xl-63 {
    font-size: 63px;
  }
  .font-size-xl-64 {
    font-size: 64px;
  }
  .font-size-xl-65 {
    font-size: 65px;
  }
  .font-size-xl-66 {
    font-size: 66px;
  }
  .font-size-xl-67 {
    font-size: 67px;
  }
  .font-size-xl-68 {
    font-size: 68px;
  }
  .font-size-xl-69 {
    font-size: 69px;
  }
  .font-size-xl-70 {
    font-size: 70px;
  }
  .font-size-xl-71 {
    font-size: 71px;
  }
  .font-size-xl-72 {
    font-size: 72px;
  }
  .font-size-xl-73 {
    font-size: 73px;
  }
  .font-size-xl-74 {
    font-size: 74px;
  }
  .font-size-xl-75 {
    font-size: 75px;
  }
  .font-size-xl-76 {
    font-size: 76px;
  }
  .font-size-xl-77 {
    font-size: 77px;
  }
  .font-size-xl-78 {
    font-size: 78px;
  }
  .font-size-xl-79 {
    font-size: 79px;
  }
  .font-size-xl-80 {
    font-size: 80px;
  }
  .font-size-xl-81 {
    font-size: 81px;
  }
  .font-size-xl-82 {
    font-size: 82px;
  }
  .font-size-xl-83 {
    font-size: 83px;
  }
  .font-size-xl-84 {
    font-size: 84px;
  }
  .font-size-xl-85 {
    font-size: 85px;
  }
  .font-size-xl-86 {
    font-size: 86px;
  }
  .font-size-xl-87 {
    font-size: 87px;
  }
  .font-size-xl-88 {
    font-size: 88px;
  }
  .font-size-xl-89 {
    font-size: 89px;
  }
  .font-size-xl-90 {
    font-size: 90px;
  }
  .font-size-xl-91 {
    font-size: 91px;
  }
  .font-size-xl-92 {
    font-size: 92px;
  }
  .font-size-xl-93 {
    font-size: 93px;
  }
  .font-size-xl-94 {
    font-size: 94px;
  }
  .font-size-xl-95 {
    font-size: 95px;
  }
  .font-size-xl-96 {
    font-size: 96px;
  }
  .font-size-xl-97 {
    font-size: 97px;
  }
  .font-size-xl-98 {
    font-size: 98px;
  }
  .font-size-xl-99 {
    font-size: 99px;
  }
  .font-size-xl-100 {
    font-size: 100px;
  }
}
@media (min-width: 1400px) {
  .font-size-xxl-10 {
    font-size: 10px;
  }
  .font-size-xxl-11 {
    font-size: 11px;
  }
  .font-size-xxl-12 {
    font-size: 12px;
  }
  .font-size-xxl-13 {
    font-size: 13px;
  }
  .font-size-xxl-14 {
    font-size: 14px;
  }
  .font-size-xxl-15 {
    font-size: 15px;
  }
  .font-size-xxl-16 {
    font-size: 16px;
  }
  .font-size-xxl-17 {
    font-size: 17px;
  }
  .font-size-xxl-18 {
    font-size: 18px;
  }
  .font-size-xxl-19 {
    font-size: 19px;
  }
  .font-size-xxl-20 {
    font-size: 20px;
  }
  .font-size-xxl-21 {
    font-size: 21px;
  }
  .font-size-xxl-22 {
    font-size: 22px;
  }
  .font-size-xxl-23 {
    font-size: 23px;
  }
  .font-size-xxl-24 {
    font-size: 24px;
  }
  .font-size-xxl-25 {
    font-size: 25px;
  }
  .font-size-xxl-26 {
    font-size: 26px;
  }
  .font-size-xxl-27 {
    font-size: 27px;
  }
  .font-size-xxl-28 {
    font-size: 28px;
  }
  .font-size-xxl-29 {
    font-size: 29px;
  }
  .font-size-xxl-30 {
    font-size: 30px;
  }
  .font-size-xxl-31 {
    font-size: 31px;
  }
  .font-size-xxl-32 {
    font-size: 32px;
  }
  .font-size-xxl-33 {
    font-size: 33px;
  }
  .font-size-xxl-34 {
    font-size: 34px;
  }
  .font-size-xxl-35 {
    font-size: 35px;
  }
  .font-size-xxl-36 {
    font-size: 36px;
  }
  .font-size-xxl-37 {
    font-size: 37px;
  }
  .font-size-xxl-38 {
    font-size: 38px;
  }
  .font-size-xxl-39 {
    font-size: 39px;
  }
  .font-size-xxl-40 {
    font-size: 40px;
  }
  .font-size-xxl-41 {
    font-size: 41px;
  }
  .font-size-xxl-42 {
    font-size: 42px;
  }
  .font-size-xxl-43 {
    font-size: 43px;
  }
  .font-size-xxl-44 {
    font-size: 44px;
  }
  .font-size-xxl-45 {
    font-size: 45px;
  }
  .font-size-xxl-46 {
    font-size: 46px;
  }
  .font-size-xxl-47 {
    font-size: 47px;
  }
  .font-size-xxl-48 {
    font-size: 48px;
  }
  .font-size-xxl-49 {
    font-size: 49px;
  }
  .font-size-xxl-50 {
    font-size: 50px;
  }
  .font-size-xxl-51 {
    font-size: 51px;
  }
  .font-size-xxl-52 {
    font-size: 52px;
  }
  .font-size-xxl-53 {
    font-size: 53px;
  }
  .font-size-xxl-54 {
    font-size: 54px;
  }
  .font-size-xxl-55 {
    font-size: 55px;
  }
  .font-size-xxl-56 {
    font-size: 56px;
  }
  .font-size-xxl-57 {
    font-size: 57px;
  }
  .font-size-xxl-58 {
    font-size: 58px;
  }
  .font-size-xxl-59 {
    font-size: 59px;
  }
  .font-size-xxl-60 {
    font-size: 60px;
  }
  .font-size-xxl-61 {
    font-size: 61px;
  }
  .font-size-xxl-62 {
    font-size: 62px;
  }
  .font-size-xxl-63 {
    font-size: 63px;
  }
  .font-size-xxl-64 {
    font-size: 64px;
  }
  .font-size-xxl-65 {
    font-size: 65px;
  }
  .font-size-xxl-66 {
    font-size: 66px;
  }
  .font-size-xxl-67 {
    font-size: 67px;
  }
  .font-size-xxl-68 {
    font-size: 68px;
  }
  .font-size-xxl-69 {
    font-size: 69px;
  }
  .font-size-xxl-70 {
    font-size: 70px;
  }
  .font-size-xxl-71 {
    font-size: 71px;
  }
  .font-size-xxl-72 {
    font-size: 72px;
  }
  .font-size-xxl-73 {
    font-size: 73px;
  }
  .font-size-xxl-74 {
    font-size: 74px;
  }
  .font-size-xxl-75 {
    font-size: 75px;
  }
  .font-size-xxl-76 {
    font-size: 76px;
  }
  .font-size-xxl-77 {
    font-size: 77px;
  }
  .font-size-xxl-78 {
    font-size: 78px;
  }
  .font-size-xxl-79 {
    font-size: 79px;
  }
  .font-size-xxl-80 {
    font-size: 80px;
  }
  .font-size-xxl-81 {
    font-size: 81px;
  }
  .font-size-xxl-82 {
    font-size: 82px;
  }
  .font-size-xxl-83 {
    font-size: 83px;
  }
  .font-size-xxl-84 {
    font-size: 84px;
  }
  .font-size-xxl-85 {
    font-size: 85px;
  }
  .font-size-xxl-86 {
    font-size: 86px;
  }
  .font-size-xxl-87 {
    font-size: 87px;
  }
  .font-size-xxl-88 {
    font-size: 88px;
  }
  .font-size-xxl-89 {
    font-size: 89px;
  }
  .font-size-xxl-90 {
    font-size: 90px;
  }
  .font-size-xxl-91 {
    font-size: 91px;
  }
  .font-size-xxl-92 {
    font-size: 92px;
  }
  .font-size-xxl-93 {
    font-size: 93px;
  }
  .font-size-xxl-94 {
    font-size: 94px;
  }
  .font-size-xxl-95 {
    font-size: 95px;
  }
  .font-size-xxl-96 {
    font-size: 96px;
  }
  .font-size-xxl-97 {
    font-size: 97px;
  }
  .font-size-xxl-98 {
    font-size: 98px;
  }
  .font-size-xxl-99 {
    font-size: 99px;
  }
  .font-size-xxl-100 {
    font-size: 100px;
  }
}
@media (max-width: 991.98px) {
  .font-size-50 {
    font-size: 30px;
  }
  .font-size-51 {
    font-size: 31px;
  }
  .font-size-52 {
    font-size: 32px;
  }
  .font-size-53 {
    font-size: 33px;
  }
  .font-size-54 {
    font-size: 34px;
  }
  .font-size-55 {
    font-size: 35px;
  }
  .font-size-56 {
    font-size: 36px;
  }
  .font-size-40 {
    font-size: 28px;
  }
  .font-size-24, .font-size-23 {
    font-size: 18px;
  }
  .font-size-17 {
    font-size: 14px;
  }
}
/* purgecss end ignore */
.t-0 {
  top: 0%;
}

.t-0px {
  top: 0px;
}

.tn-0 {
  top: -0%;
}

.tn-0px {
  top: -0px;
}

.t-1 {
  top: 1%;
}

.t-1px {
  top: 1px;
}

.tn-1 {
  top: -1%;
}

.tn-1px {
  top: -1px;
}

.t-2 {
  top: 2%;
}

.t-2px {
  top: 2px;
}

.tn-2 {
  top: -2%;
}

.tn-2px {
  top: -2px;
}

.t-3 {
  top: 3%;
}

.t-3px {
  top: 3px;
}

.tn-3 {
  top: -3%;
}

.tn-3px {
  top: -3px;
}

.t-4 {
  top: 4%;
}

.t-4px {
  top: 4px;
}

.tn-4 {
  top: -4%;
}

.tn-4px {
  top: -4px;
}

.t-5 {
  top: 5%;
}

.t-5px {
  top: 5px;
}

.tn-5 {
  top: -5%;
}

.tn-5px {
  top: -5px;
}

.t-6 {
  top: 6%;
}

.t-6px {
  top: 6px;
}

.tn-6 {
  top: -6%;
}

.tn-6px {
  top: -6px;
}

.t-7 {
  top: 7%;
}

.t-7px {
  top: 7px;
}

.tn-7 {
  top: -7%;
}

.tn-7px {
  top: -7px;
}

.t-8 {
  top: 8%;
}

.t-8px {
  top: 8px;
}

.tn-8 {
  top: -8%;
}

.tn-8px {
  top: -8px;
}

.t-9 {
  top: 9%;
}

.t-9px {
  top: 9px;
}

.tn-9 {
  top: -9%;
}

.tn-9px {
  top: -9px;
}

.t-10 {
  top: 10%;
}

.t-10px {
  top: 10px;
}

.tn-10 {
  top: -10%;
}

.tn-10px {
  top: -10px;
}

.t-11 {
  top: 11%;
}

.t-11px {
  top: 11px;
}

.tn-11 {
  top: -11%;
}

.tn-11px {
  top: -11px;
}

.t-12 {
  top: 12%;
}

.t-12px {
  top: 12px;
}

.tn-12 {
  top: -12%;
}

.tn-12px {
  top: -12px;
}

.t-13 {
  top: 13%;
}

.t-13px {
  top: 13px;
}

.tn-13 {
  top: -13%;
}

.tn-13px {
  top: -13px;
}

.t-14 {
  top: 14%;
}

.t-14px {
  top: 14px;
}

.tn-14 {
  top: -14%;
}

.tn-14px {
  top: -14px;
}

.t-15 {
  top: 15%;
}

.t-15px {
  top: 15px;
}

.tn-15 {
  top: -15%;
}

.tn-15px {
  top: -15px;
}

.t-16 {
  top: 16%;
}

.t-16px {
  top: 16px;
}

.tn-16 {
  top: -16%;
}

.tn-16px {
  top: -16px;
}

.t-17 {
  top: 17%;
}

.t-17px {
  top: 17px;
}

.tn-17 {
  top: -17%;
}

.tn-17px {
  top: -17px;
}

.t-18 {
  top: 18%;
}

.t-18px {
  top: 18px;
}

.tn-18 {
  top: -18%;
}

.tn-18px {
  top: -18px;
}

.t-19 {
  top: 19%;
}

.t-19px {
  top: 19px;
}

.tn-19 {
  top: -19%;
}

.tn-19px {
  top: -19px;
}

.t-20 {
  top: 20%;
}

.t-20px {
  top: 20px;
}

.tn-20 {
  top: -20%;
}

.tn-20px {
  top: -20px;
}

.t-21 {
  top: 21%;
}

.t-21px {
  top: 21px;
}

.tn-21 {
  top: -21%;
}

.tn-21px {
  top: -21px;
}

.t-22 {
  top: 22%;
}

.t-22px {
  top: 22px;
}

.tn-22 {
  top: -22%;
}

.tn-22px {
  top: -22px;
}

.t-23 {
  top: 23%;
}

.t-23px {
  top: 23px;
}

.tn-23 {
  top: -23%;
}

.tn-23px {
  top: -23px;
}

.t-24 {
  top: 24%;
}

.t-24px {
  top: 24px;
}

.tn-24 {
  top: -24%;
}

.tn-24px {
  top: -24px;
}

.t-25 {
  top: 25%;
}

.t-25px {
  top: 25px;
}

.tn-25 {
  top: -25%;
}

.tn-25px {
  top: -25px;
}

.t-26 {
  top: 26%;
}

.t-26px {
  top: 26px;
}

.tn-26 {
  top: -26%;
}

.tn-26px {
  top: -26px;
}

.t-27 {
  top: 27%;
}

.t-27px {
  top: 27px;
}

.tn-27 {
  top: -27%;
}

.tn-27px {
  top: -27px;
}

.t-28 {
  top: 28%;
}

.t-28px {
  top: 28px;
}

.tn-28 {
  top: -28%;
}

.tn-28px {
  top: -28px;
}

.t-29 {
  top: 29%;
}

.t-29px {
  top: 29px;
}

.tn-29 {
  top: -29%;
}

.tn-29px {
  top: -29px;
}

.t-30 {
  top: 30%;
}

.t-30px {
  top: 30px;
}

.tn-30 {
  top: -30%;
}

.tn-30px {
  top: -30px;
}

.t-31 {
  top: 31%;
}

.t-31px {
  top: 31px;
}

.tn-31 {
  top: -31%;
}

.tn-31px {
  top: -31px;
}

.t-32 {
  top: 32%;
}

.t-32px {
  top: 32px;
}

.tn-32 {
  top: -32%;
}

.tn-32px {
  top: -32px;
}

.t-33 {
  top: 33%;
}

.t-33px {
  top: 33px;
}

.tn-33 {
  top: -33%;
}

.tn-33px {
  top: -33px;
}

.t-34 {
  top: 34%;
}

.t-34px {
  top: 34px;
}

.tn-34 {
  top: -34%;
}

.tn-34px {
  top: -34px;
}

.t-35 {
  top: 35%;
}

.t-35px {
  top: 35px;
}

.tn-35 {
  top: -35%;
}

.tn-35px {
  top: -35px;
}

.t-36 {
  top: 36%;
}

.t-36px {
  top: 36px;
}

.tn-36 {
  top: -36%;
}

.tn-36px {
  top: -36px;
}

.t-37 {
  top: 37%;
}

.t-37px {
  top: 37px;
}

.tn-37 {
  top: -37%;
}

.tn-37px {
  top: -37px;
}

.t-38 {
  top: 38%;
}

.t-38px {
  top: 38px;
}

.tn-38 {
  top: -38%;
}

.tn-38px {
  top: -38px;
}

.t-39 {
  top: 39%;
}

.t-39px {
  top: 39px;
}

.tn-39 {
  top: -39%;
}

.tn-39px {
  top: -39px;
}

.t-40 {
  top: 40%;
}

.t-40px {
  top: 40px;
}

.tn-40 {
  top: -40%;
}

.tn-40px {
  top: -40px;
}

.t-41 {
  top: 41%;
}

.t-41px {
  top: 41px;
}

.tn-41 {
  top: -41%;
}

.tn-41px {
  top: -41px;
}

.t-42 {
  top: 42%;
}

.t-42px {
  top: 42px;
}

.tn-42 {
  top: -42%;
}

.tn-42px {
  top: -42px;
}

.t-43 {
  top: 43%;
}

.t-43px {
  top: 43px;
}

.tn-43 {
  top: -43%;
}

.tn-43px {
  top: -43px;
}

.t-44 {
  top: 44%;
}

.t-44px {
  top: 44px;
}

.tn-44 {
  top: -44%;
}

.tn-44px {
  top: -44px;
}

.t-45 {
  top: 45%;
}

.t-45px {
  top: 45px;
}

.tn-45 {
  top: -45%;
}

.tn-45px {
  top: -45px;
}

.t-46 {
  top: 46%;
}

.t-46px {
  top: 46px;
}

.tn-46 {
  top: -46%;
}

.tn-46px {
  top: -46px;
}

.t-47 {
  top: 47%;
}

.t-47px {
  top: 47px;
}

.tn-47 {
  top: -47%;
}

.tn-47px {
  top: -47px;
}

.t-48 {
  top: 48%;
}

.t-48px {
  top: 48px;
}

.tn-48 {
  top: -48%;
}

.tn-48px {
  top: -48px;
}

.t-49 {
  top: 49%;
}

.t-49px {
  top: 49px;
}

.tn-49 {
  top: -49%;
}

.tn-49px {
  top: -49px;
}

.t-50 {
  top: 50%;
}

.t-50px {
  top: 50px;
}

.tn-50 {
  top: -50%;
}

.tn-50px {
  top: -50px;
}

.t-51 {
  top: 51%;
}

.t-51px {
  top: 51px;
}

.tn-51 {
  top: -51%;
}

.tn-51px {
  top: -51px;
}

.t-52 {
  top: 52%;
}

.t-52px {
  top: 52px;
}

.tn-52 {
  top: -52%;
}

.tn-52px {
  top: -52px;
}

.t-53 {
  top: 53%;
}

.t-53px {
  top: 53px;
}

.tn-53 {
  top: -53%;
}

.tn-53px {
  top: -53px;
}

.t-54 {
  top: 54%;
}

.t-54px {
  top: 54px;
}

.tn-54 {
  top: -54%;
}

.tn-54px {
  top: -54px;
}

.t-55 {
  top: 55%;
}

.t-55px {
  top: 55px;
}

.tn-55 {
  top: -55%;
}

.tn-55px {
  top: -55px;
}

.t-56 {
  top: 56%;
}

.t-56px {
  top: 56px;
}

.tn-56 {
  top: -56%;
}

.tn-56px {
  top: -56px;
}

.t-57 {
  top: 57%;
}

.t-57px {
  top: 57px;
}

.tn-57 {
  top: -57%;
}

.tn-57px {
  top: -57px;
}

.t-58 {
  top: 58%;
}

.t-58px {
  top: 58px;
}

.tn-58 {
  top: -58%;
}

.tn-58px {
  top: -58px;
}

.t-59 {
  top: 59%;
}

.t-59px {
  top: 59px;
}

.tn-59 {
  top: -59%;
}

.tn-59px {
  top: -59px;
}

.t-60 {
  top: 60%;
}

.t-60px {
  top: 60px;
}

.tn-60 {
  top: -60%;
}

.tn-60px {
  top: -60px;
}

.t-61 {
  top: 61%;
}

.t-61px {
  top: 61px;
}

.tn-61 {
  top: -61%;
}

.tn-61px {
  top: -61px;
}

.t-62 {
  top: 62%;
}

.t-62px {
  top: 62px;
}

.tn-62 {
  top: -62%;
}

.tn-62px {
  top: -62px;
}

.t-63 {
  top: 63%;
}

.t-63px {
  top: 63px;
}

.tn-63 {
  top: -63%;
}

.tn-63px {
  top: -63px;
}

.t-64 {
  top: 64%;
}

.t-64px {
  top: 64px;
}

.tn-64 {
  top: -64%;
}

.tn-64px {
  top: -64px;
}

.t-65 {
  top: 65%;
}

.t-65px {
  top: 65px;
}

.tn-65 {
  top: -65%;
}

.tn-65px {
  top: -65px;
}

.t-66 {
  top: 66%;
}

.t-66px {
  top: 66px;
}

.tn-66 {
  top: -66%;
}

.tn-66px {
  top: -66px;
}

.t-67 {
  top: 67%;
}

.t-67px {
  top: 67px;
}

.tn-67 {
  top: -67%;
}

.tn-67px {
  top: -67px;
}

.t-68 {
  top: 68%;
}

.t-68px {
  top: 68px;
}

.tn-68 {
  top: -68%;
}

.tn-68px {
  top: -68px;
}

.t-69 {
  top: 69%;
}

.t-69px {
  top: 69px;
}

.tn-69 {
  top: -69%;
}

.tn-69px {
  top: -69px;
}

.t-70 {
  top: 70%;
}

.t-70px {
  top: 70px;
}

.tn-70 {
  top: -70%;
}

.tn-70px {
  top: -70px;
}

.t-71 {
  top: 71%;
}

.t-71px {
  top: 71px;
}

.tn-71 {
  top: -71%;
}

.tn-71px {
  top: -71px;
}

.t-72 {
  top: 72%;
}

.t-72px {
  top: 72px;
}

.tn-72 {
  top: -72%;
}

.tn-72px {
  top: -72px;
}

.t-73 {
  top: 73%;
}

.t-73px {
  top: 73px;
}

.tn-73 {
  top: -73%;
}

.tn-73px {
  top: -73px;
}

.t-74 {
  top: 74%;
}

.t-74px {
  top: 74px;
}

.tn-74 {
  top: -74%;
}

.tn-74px {
  top: -74px;
}

.t-75 {
  top: 75%;
}

.t-75px {
  top: 75px;
}

.tn-75 {
  top: -75%;
}

.tn-75px {
  top: -75px;
}

.t-76 {
  top: 76%;
}

.t-76px {
  top: 76px;
}

.tn-76 {
  top: -76%;
}

.tn-76px {
  top: -76px;
}

.t-77 {
  top: 77%;
}

.t-77px {
  top: 77px;
}

.tn-77 {
  top: -77%;
}

.tn-77px {
  top: -77px;
}

.t-78 {
  top: 78%;
}

.t-78px {
  top: 78px;
}

.tn-78 {
  top: -78%;
}

.tn-78px {
  top: -78px;
}

.t-79 {
  top: 79%;
}

.t-79px {
  top: 79px;
}

.tn-79 {
  top: -79%;
}

.tn-79px {
  top: -79px;
}

.t-80 {
  top: 80%;
}

.t-80px {
  top: 80px;
}

.tn-80 {
  top: -80%;
}

.tn-80px {
  top: -80px;
}

.t-81 {
  top: 81%;
}

.t-81px {
  top: 81px;
}

.tn-81 {
  top: -81%;
}

.tn-81px {
  top: -81px;
}

.t-82 {
  top: 82%;
}

.t-82px {
  top: 82px;
}

.tn-82 {
  top: -82%;
}

.tn-82px {
  top: -82px;
}

.t-83 {
  top: 83%;
}

.t-83px {
  top: 83px;
}

.tn-83 {
  top: -83%;
}

.tn-83px {
  top: -83px;
}

.t-84 {
  top: 84%;
}

.t-84px {
  top: 84px;
}

.tn-84 {
  top: -84%;
}

.tn-84px {
  top: -84px;
}

.t-85 {
  top: 85%;
}

.t-85px {
  top: 85px;
}

.tn-85 {
  top: -85%;
}

.tn-85px {
  top: -85px;
}

.t-86 {
  top: 86%;
}

.t-86px {
  top: 86px;
}

.tn-86 {
  top: -86%;
}

.tn-86px {
  top: -86px;
}

.t-87 {
  top: 87%;
}

.t-87px {
  top: 87px;
}

.tn-87 {
  top: -87%;
}

.tn-87px {
  top: -87px;
}

.t-88 {
  top: 88%;
}

.t-88px {
  top: 88px;
}

.tn-88 {
  top: -88%;
}

.tn-88px {
  top: -88px;
}

.t-89 {
  top: 89%;
}

.t-89px {
  top: 89px;
}

.tn-89 {
  top: -89%;
}

.tn-89px {
  top: -89px;
}

.t-90 {
  top: 90%;
}

.t-90px {
  top: 90px;
}

.tn-90 {
  top: -90%;
}

.tn-90px {
  top: -90px;
}

.t-91 {
  top: 91%;
}

.t-91px {
  top: 91px;
}

.tn-91 {
  top: -91%;
}

.tn-91px {
  top: -91px;
}

.t-92 {
  top: 92%;
}

.t-92px {
  top: 92px;
}

.tn-92 {
  top: -92%;
}

.tn-92px {
  top: -92px;
}

.t-93 {
  top: 93%;
}

.t-93px {
  top: 93px;
}

.tn-93 {
  top: -93%;
}

.tn-93px {
  top: -93px;
}

.t-94 {
  top: 94%;
}

.t-94px {
  top: 94px;
}

.tn-94 {
  top: -94%;
}

.tn-94px {
  top: -94px;
}

.t-95 {
  top: 95%;
}

.t-95px {
  top: 95px;
}

.tn-95 {
  top: -95%;
}

.tn-95px {
  top: -95px;
}

.t-96 {
  top: 96%;
}

.t-96px {
  top: 96px;
}

.tn-96 {
  top: -96%;
}

.tn-96px {
  top: -96px;
}

.t-97 {
  top: 97%;
}

.t-97px {
  top: 97px;
}

.tn-97 {
  top: -97%;
}

.tn-97px {
  top: -97px;
}

.t-98 {
  top: 98%;
}

.t-98px {
  top: 98px;
}

.tn-98 {
  top: -98%;
}

.tn-98px {
  top: -98px;
}

.t-99 {
  top: 99%;
}

.t-99px {
  top: 99px;
}

.tn-99 {
  top: -99%;
}

.tn-99px {
  top: -99px;
}

.t-100 {
  top: 100%;
}

.t-100px {
  top: 100px;
}

.tn-100 {
  top: -100%;
}

.tn-100px {
  top: -100px;
}

.r-0 {
  right: 0%;
}

.r-0px {
  right: 0px;
}

.rn-0 {
  right: -0%;
}

.rn-0px {
  right: -0px;
}

.r-1 {
  right: 1%;
}

.r-1px {
  right: 1px;
}

.rn-1 {
  right: -1%;
}

.rn-1px {
  right: -1px;
}

.r-2 {
  right: 2%;
}

.r-2px {
  right: 2px;
}

.rn-2 {
  right: -2%;
}

.rn-2px {
  right: -2px;
}

.r-3 {
  right: 3%;
}

.r-3px {
  right: 3px;
}

.rn-3 {
  right: -3%;
}

.rn-3px {
  right: -3px;
}

.r-4 {
  right: 4%;
}

.r-4px {
  right: 4px;
}

.rn-4 {
  right: -4%;
}

.rn-4px {
  right: -4px;
}

.r-5 {
  right: 5%;
}

.r-5px {
  right: 5px;
}

.rn-5 {
  right: -5%;
}

.rn-5px {
  right: -5px;
}

.r-6 {
  right: 6%;
}

.r-6px {
  right: 6px;
}

.rn-6 {
  right: -6%;
}

.rn-6px {
  right: -6px;
}

.r-7 {
  right: 7%;
}

.r-7px {
  right: 7px;
}

.rn-7 {
  right: -7%;
}

.rn-7px {
  right: -7px;
}

.r-8 {
  right: 8%;
}

.r-8px {
  right: 8px;
}

.rn-8 {
  right: -8%;
}

.rn-8px {
  right: -8px;
}

.r-9 {
  right: 9%;
}

.r-9px {
  right: 9px;
}

.rn-9 {
  right: -9%;
}

.rn-9px {
  right: -9px;
}

.r-10 {
  right: 10%;
}

.r-10px {
  right: 10px;
}

.rn-10 {
  right: -10%;
}

.rn-10px {
  right: -10px;
}

.r-11 {
  right: 11%;
}

.r-11px {
  right: 11px;
}

.rn-11 {
  right: -11%;
}

.rn-11px {
  right: -11px;
}

.r-12 {
  right: 12%;
}

.r-12px {
  right: 12px;
}

.rn-12 {
  right: -12%;
}

.rn-12px {
  right: -12px;
}

.r-13 {
  right: 13%;
}

.r-13px {
  right: 13px;
}

.rn-13 {
  right: -13%;
}

.rn-13px {
  right: -13px;
}

.r-14 {
  right: 14%;
}

.r-14px {
  right: 14px;
}

.rn-14 {
  right: -14%;
}

.rn-14px {
  right: -14px;
}

.r-15 {
  right: 15%;
}

.r-15px {
  right: 15px;
}

.rn-15 {
  right: -15%;
}

.rn-15px {
  right: -15px;
}

.r-16 {
  right: 16%;
}

.r-16px {
  right: 16px;
}

.rn-16 {
  right: -16%;
}

.rn-16px {
  right: -16px;
}

.r-17 {
  right: 17%;
}

.r-17px {
  right: 17px;
}

.rn-17 {
  right: -17%;
}

.rn-17px {
  right: -17px;
}

.r-18 {
  right: 18%;
}

.r-18px {
  right: 18px;
}

.rn-18 {
  right: -18%;
}

.rn-18px {
  right: -18px;
}

.r-19 {
  right: 19%;
}

.r-19px {
  right: 19px;
}

.rn-19 {
  right: -19%;
}

.rn-19px {
  right: -19px;
}

.r-20 {
  right: 20%;
}

.r-20px {
  right: 20px;
}

.rn-20 {
  right: -20%;
}

.rn-20px {
  right: -20px;
}

.r-21 {
  right: 21%;
}

.r-21px {
  right: 21px;
}

.rn-21 {
  right: -21%;
}

.rn-21px {
  right: -21px;
}

.r-22 {
  right: 22%;
}

.r-22px {
  right: 22px;
}

.rn-22 {
  right: -22%;
}

.rn-22px {
  right: -22px;
}

.r-23 {
  right: 23%;
}

.r-23px {
  right: 23px;
}

.rn-23 {
  right: -23%;
}

.rn-23px {
  right: -23px;
}

.r-24 {
  right: 24%;
}

.r-24px {
  right: 24px;
}

.rn-24 {
  right: -24%;
}

.rn-24px {
  right: -24px;
}

.r-25 {
  right: 25%;
}

.r-25px {
  right: 25px;
}

.rn-25 {
  right: -25%;
}

.rn-25px {
  right: -25px;
}

.r-26 {
  right: 26%;
}

.r-26px {
  right: 26px;
}

.rn-26 {
  right: -26%;
}

.rn-26px {
  right: -26px;
}

.r-27 {
  right: 27%;
}

.r-27px {
  right: 27px;
}

.rn-27 {
  right: -27%;
}

.rn-27px {
  right: -27px;
}

.r-28 {
  right: 28%;
}

.r-28px {
  right: 28px;
}

.rn-28 {
  right: -28%;
}

.rn-28px {
  right: -28px;
}

.r-29 {
  right: 29%;
}

.r-29px {
  right: 29px;
}

.rn-29 {
  right: -29%;
}

.rn-29px {
  right: -29px;
}

.r-30 {
  right: 30%;
}

.r-30px {
  right: 30px;
}

.rn-30 {
  right: -30%;
}

.rn-30px {
  right: -30px;
}

.r-31 {
  right: 31%;
}

.r-31px {
  right: 31px;
}

.rn-31 {
  right: -31%;
}

.rn-31px {
  right: -31px;
}

.r-32 {
  right: 32%;
}

.r-32px {
  right: 32px;
}

.rn-32 {
  right: -32%;
}

.rn-32px {
  right: -32px;
}

.r-33 {
  right: 33%;
}

.r-33px {
  right: 33px;
}

.rn-33 {
  right: -33%;
}

.rn-33px {
  right: -33px;
}

.r-34 {
  right: 34%;
}

.r-34px {
  right: 34px;
}

.rn-34 {
  right: -34%;
}

.rn-34px {
  right: -34px;
}

.r-35 {
  right: 35%;
}

.r-35px {
  right: 35px;
}

.rn-35 {
  right: -35%;
}

.rn-35px {
  right: -35px;
}

.r-36 {
  right: 36%;
}

.r-36px {
  right: 36px;
}

.rn-36 {
  right: -36%;
}

.rn-36px {
  right: -36px;
}

.r-37 {
  right: 37%;
}

.r-37px {
  right: 37px;
}

.rn-37 {
  right: -37%;
}

.rn-37px {
  right: -37px;
}

.r-38 {
  right: 38%;
}

.r-38px {
  right: 38px;
}

.rn-38 {
  right: -38%;
}

.rn-38px {
  right: -38px;
}

.r-39 {
  right: 39%;
}

.r-39px {
  right: 39px;
}

.rn-39 {
  right: -39%;
}

.rn-39px {
  right: -39px;
}

.r-40 {
  right: 40%;
}

.r-40px {
  right: 40px;
}

.rn-40 {
  right: -40%;
}

.rn-40px {
  right: -40px;
}

.r-41 {
  right: 41%;
}

.r-41px {
  right: 41px;
}

.rn-41 {
  right: -41%;
}

.rn-41px {
  right: -41px;
}

.r-42 {
  right: 42%;
}

.r-42px {
  right: 42px;
}

.rn-42 {
  right: -42%;
}

.rn-42px {
  right: -42px;
}

.r-43 {
  right: 43%;
}

.r-43px {
  right: 43px;
}

.rn-43 {
  right: -43%;
}

.rn-43px {
  right: -43px;
}

.r-44 {
  right: 44%;
}

.r-44px {
  right: 44px;
}

.rn-44 {
  right: -44%;
}

.rn-44px {
  right: -44px;
}

.r-45 {
  right: 45%;
}

.r-45px {
  right: 45px;
}

.rn-45 {
  right: -45%;
}

.rn-45px {
  right: -45px;
}

.r-46 {
  right: 46%;
}

.r-46px {
  right: 46px;
}

.rn-46 {
  right: -46%;
}

.rn-46px {
  right: -46px;
}

.r-47 {
  right: 47%;
}

.r-47px {
  right: 47px;
}

.rn-47 {
  right: -47%;
}

.rn-47px {
  right: -47px;
}

.r-48 {
  right: 48%;
}

.r-48px {
  right: 48px;
}

.rn-48 {
  right: -48%;
}

.rn-48px {
  right: -48px;
}

.r-49 {
  right: 49%;
}

.r-49px {
  right: 49px;
}

.rn-49 {
  right: -49%;
}

.rn-49px {
  right: -49px;
}

.r-50 {
  right: 50%;
}

.r-50px {
  right: 50px;
}

.rn-50 {
  right: -50%;
}

.rn-50px {
  right: -50px;
}

.r-51 {
  right: 51%;
}

.r-51px {
  right: 51px;
}

.rn-51 {
  right: -51%;
}

.rn-51px {
  right: -51px;
}

.r-52 {
  right: 52%;
}

.r-52px {
  right: 52px;
}

.rn-52 {
  right: -52%;
}

.rn-52px {
  right: -52px;
}

.r-53 {
  right: 53%;
}

.r-53px {
  right: 53px;
}

.rn-53 {
  right: -53%;
}

.rn-53px {
  right: -53px;
}

.r-54 {
  right: 54%;
}

.r-54px {
  right: 54px;
}

.rn-54 {
  right: -54%;
}

.rn-54px {
  right: -54px;
}

.r-55 {
  right: 55%;
}

.r-55px {
  right: 55px;
}

.rn-55 {
  right: -55%;
}

.rn-55px {
  right: -55px;
}

.r-56 {
  right: 56%;
}

.r-56px {
  right: 56px;
}

.rn-56 {
  right: -56%;
}

.rn-56px {
  right: -56px;
}

.r-57 {
  right: 57%;
}

.r-57px {
  right: 57px;
}

.rn-57 {
  right: -57%;
}

.rn-57px {
  right: -57px;
}

.r-58 {
  right: 58%;
}

.r-58px {
  right: 58px;
}

.rn-58 {
  right: -58%;
}

.rn-58px {
  right: -58px;
}

.r-59 {
  right: 59%;
}

.r-59px {
  right: 59px;
}

.rn-59 {
  right: -59%;
}

.rn-59px {
  right: -59px;
}

.r-60 {
  right: 60%;
}

.r-60px {
  right: 60px;
}

.rn-60 {
  right: -60%;
}

.rn-60px {
  right: -60px;
}

.r-61 {
  right: 61%;
}

.r-61px {
  right: 61px;
}

.rn-61 {
  right: -61%;
}

.rn-61px {
  right: -61px;
}

.r-62 {
  right: 62%;
}

.r-62px {
  right: 62px;
}

.rn-62 {
  right: -62%;
}

.rn-62px {
  right: -62px;
}

.r-63 {
  right: 63%;
}

.r-63px {
  right: 63px;
}

.rn-63 {
  right: -63%;
}

.rn-63px {
  right: -63px;
}

.r-64 {
  right: 64%;
}

.r-64px {
  right: 64px;
}

.rn-64 {
  right: -64%;
}

.rn-64px {
  right: -64px;
}

.r-65 {
  right: 65%;
}

.r-65px {
  right: 65px;
}

.rn-65 {
  right: -65%;
}

.rn-65px {
  right: -65px;
}

.r-66 {
  right: 66%;
}

.r-66px {
  right: 66px;
}

.rn-66 {
  right: -66%;
}

.rn-66px {
  right: -66px;
}

.r-67 {
  right: 67%;
}

.r-67px {
  right: 67px;
}

.rn-67 {
  right: -67%;
}

.rn-67px {
  right: -67px;
}

.r-68 {
  right: 68%;
}

.r-68px {
  right: 68px;
}

.rn-68 {
  right: -68%;
}

.rn-68px {
  right: -68px;
}

.r-69 {
  right: 69%;
}

.r-69px {
  right: 69px;
}

.rn-69 {
  right: -69%;
}

.rn-69px {
  right: -69px;
}

.r-70 {
  right: 70%;
}

.r-70px {
  right: 70px;
}

.rn-70 {
  right: -70%;
}

.rn-70px {
  right: -70px;
}

.r-71 {
  right: 71%;
}

.r-71px {
  right: 71px;
}

.rn-71 {
  right: -71%;
}

.rn-71px {
  right: -71px;
}

.r-72 {
  right: 72%;
}

.r-72px {
  right: 72px;
}

.rn-72 {
  right: -72%;
}

.rn-72px {
  right: -72px;
}

.r-73 {
  right: 73%;
}

.r-73px {
  right: 73px;
}

.rn-73 {
  right: -73%;
}

.rn-73px {
  right: -73px;
}

.r-74 {
  right: 74%;
}

.r-74px {
  right: 74px;
}

.rn-74 {
  right: -74%;
}

.rn-74px {
  right: -74px;
}

.r-75 {
  right: 75%;
}

.r-75px {
  right: 75px;
}

.rn-75 {
  right: -75%;
}

.rn-75px {
  right: -75px;
}

.r-76 {
  right: 76%;
}

.r-76px {
  right: 76px;
}

.rn-76 {
  right: -76%;
}

.rn-76px {
  right: -76px;
}

.r-77 {
  right: 77%;
}

.r-77px {
  right: 77px;
}

.rn-77 {
  right: -77%;
}

.rn-77px {
  right: -77px;
}

.r-78 {
  right: 78%;
}

.r-78px {
  right: 78px;
}

.rn-78 {
  right: -78%;
}

.rn-78px {
  right: -78px;
}

.r-79 {
  right: 79%;
}

.r-79px {
  right: 79px;
}

.rn-79 {
  right: -79%;
}

.rn-79px {
  right: -79px;
}

.r-80 {
  right: 80%;
}

.r-80px {
  right: 80px;
}

.rn-80 {
  right: -80%;
}

.rn-80px {
  right: -80px;
}

.r-81 {
  right: 81%;
}

.r-81px {
  right: 81px;
}

.rn-81 {
  right: -81%;
}

.rn-81px {
  right: -81px;
}

.r-82 {
  right: 82%;
}

.r-82px {
  right: 82px;
}

.rn-82 {
  right: -82%;
}

.rn-82px {
  right: -82px;
}

.r-83 {
  right: 83%;
}

.r-83px {
  right: 83px;
}

.rn-83 {
  right: -83%;
}

.rn-83px {
  right: -83px;
}

.r-84 {
  right: 84%;
}

.r-84px {
  right: 84px;
}

.rn-84 {
  right: -84%;
}

.rn-84px {
  right: -84px;
}

.r-85 {
  right: 85%;
}

.r-85px {
  right: 85px;
}

.rn-85 {
  right: -85%;
}

.rn-85px {
  right: -85px;
}

.r-86 {
  right: 86%;
}

.r-86px {
  right: 86px;
}

.rn-86 {
  right: -86%;
}

.rn-86px {
  right: -86px;
}

.r-87 {
  right: 87%;
}

.r-87px {
  right: 87px;
}

.rn-87 {
  right: -87%;
}

.rn-87px {
  right: -87px;
}

.r-88 {
  right: 88%;
}

.r-88px {
  right: 88px;
}

.rn-88 {
  right: -88%;
}

.rn-88px {
  right: -88px;
}

.r-89 {
  right: 89%;
}

.r-89px {
  right: 89px;
}

.rn-89 {
  right: -89%;
}

.rn-89px {
  right: -89px;
}

.r-90 {
  right: 90%;
}

.r-90px {
  right: 90px;
}

.rn-90 {
  right: -90%;
}

.rn-90px {
  right: -90px;
}

.r-91 {
  right: 91%;
}

.r-91px {
  right: 91px;
}

.rn-91 {
  right: -91%;
}

.rn-91px {
  right: -91px;
}

.r-92 {
  right: 92%;
}

.r-92px {
  right: 92px;
}

.rn-92 {
  right: -92%;
}

.rn-92px {
  right: -92px;
}

.r-93 {
  right: 93%;
}

.r-93px {
  right: 93px;
}

.rn-93 {
  right: -93%;
}

.rn-93px {
  right: -93px;
}

.r-94 {
  right: 94%;
}

.r-94px {
  right: 94px;
}

.rn-94 {
  right: -94%;
}

.rn-94px {
  right: -94px;
}

.r-95 {
  right: 95%;
}

.r-95px {
  right: 95px;
}

.rn-95 {
  right: -95%;
}

.rn-95px {
  right: -95px;
}

.r-96 {
  right: 96%;
}

.r-96px {
  right: 96px;
}

.rn-96 {
  right: -96%;
}

.rn-96px {
  right: -96px;
}

.r-97 {
  right: 97%;
}

.r-97px {
  right: 97px;
}

.rn-97 {
  right: -97%;
}

.rn-97px {
  right: -97px;
}

.r-98 {
  right: 98%;
}

.r-98px {
  right: 98px;
}

.rn-98 {
  right: -98%;
}

.rn-98px {
  right: -98px;
}

.r-99 {
  right: 99%;
}

.r-99px {
  right: 99px;
}

.rn-99 {
  right: -99%;
}

.rn-99px {
  right: -99px;
}

.r-100 {
  right: 100%;
}

.r-100px {
  right: 100px;
}

.rn-100 {
  right: -100%;
}

.rn-100px {
  right: -100px;
}

header #hide {
  display: none;
}
header.navbar {
  padding: 1.5rem 0;
}
header.navbar .nav-item:hover > .nav-link {
  color: #3699FF !important;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  header.navbar .nav-item > .nav-link {
    font-size: 14px;
  }
}
header.navbar .nav-item > .nav-link.active {
  color: #3699FF !important;
  font-weight: bold;
}
header.navbar .media {
  display: flex;
  align-items: flex-start;
}
header.navbar .media .media-body {
  flex: 1;
}
header.navbar.navbar-dark {
  background-color: #242939 !important;
}
header.navbar.navbar-dark .navbar-absolute-top-scroller {
  background-color: #242939;
}
header.navbar.navbar-dark .navbar-absolute-top-scroller .navbar-nav {
  background-color: #242939;
}
header.navbar.navbar-dark .dropdown-dark {
  background: #242939 !important;
}
header.navbar.navbar-dark .dropdown-dark .dropdown-item {
  color: white;
}
header.navbar.navbar-dark .dropdown-dark .dropdown-item:hover {
  color: #3699FF;
  background-color: rgba(255, 255, 255, 0.05);
}
header.navbar.navbar-scrolled {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* purgecss start ignore */
.hs-menu-initialized {
  position: relative;
  z-index: 10;
}

.hs-menu-initialized .animated {
  animation-duration: 300ms;
}

.hs-overflow-x-locked {
  overflow-x: hidden;
}

.hs-sub-menu,
.hs-mega-menu {
  display: none;
  background-color: #fff;
}

@media (min-width: 576px) {
  .hs-mega-menu-desktop-sm,
.hs-sub-menu-desktop-sm {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    margin-top: -0.002rem;
  }
}
@media (min-width: 768px) {
  .hs-mega-menu-desktop-md,
.hs-sub-menu-desktop-md {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    margin-top: -0.002rem;
  }
}
@media (min-width: 992px) {
  .hs-mega-menu-desktop-lg,
.hs-sub-menu-desktop-lg {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    margin-top: -0.002rem;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 1.5rem 0.5rem;
  }
}
@media (min-width: 1200px) {
  .hs-mega-menu-desktop-xl,
.hs-sub-menu-desktop-xl {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    margin-top: -0.002rem;
  }
}
.hs-sub-menu {
  min-width: 180px;
}

.hs-has-sub-menu {
  overflow: hidden;
  position: relative;
}

.hs-sub-menu-opened {
  overflow: visible;
}

.hs-sub-menu .hs-sub-menu,
.hs-mega-menu .hs-sub-menu,
.hs-sub-menu .hs-mega-menu,
.hs-mega-menu .hs-mega-menu {
  top: 0;
  left: 100%;
  margin-top: 0;
}

/*------------------------------------
    Vertical Direction
------------------------------------*/
.hs-menu-vertical .hs-sub-menu,
.hs-menu-vertical .hs-mega-menu {
  top: 0;
  left: 100%;
  margin-top: 0;
}

.hs-menu-vertical .hs-sub-menu {
  width: auto;
}

.hs-menu-vertical .hs-mega-menu {
  height: 100%;
}

/*------------------------------------
    Mobile state
------------------------------------*/
.hs-mobile-state .hs-sub-menu,
.hs-mobile-state .hs-mega-menu {
  position: static;
  visibility: visible;
}

.hs-mobile-state .hs-has-mega-menu[data-max-width] > .hs-mega-menu,
.hs-mobile-state .hs-has-sub-menu[data-max-width] > .hs-sub-menu {
  max-width: initial !important;
}

/*------------------------------------
    RTL
------------------------------------*/
.hs-menu-initialized.hs-rtl {
  direction: rtl;
  unicode-bidi: embed;
}

.hs-menu-initialized.hs-rtl .hs-sub-menu,
.hs-menu-initialized.hs-rtl .hs-mega-menu {
  left: auto;
  right: 0;
}

.hs-menu-initialized.hs-rtl .hs-sub-menu .hs-sub-menu,
.hs-menu-initialized.hs-rtl .hs-mega-menu .hs-sub-menu,
.hs-menu-initialized.hs-rtl .hs-sub-menu .hs-mega-menu,
.hs-menu-initialized.hs-rtl .hs-mega-menu .hs-mega-menu {
  left: auto;
  right: 100%;
}

/*------------------------------------
    Smart Position
------------------------------------*/
.hs-menu-initialized:not(.hs-mobile-state) .hs-sub-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-mega-menu.hs-reversed {
  left: auto;
  right: 0;
}

.hs-menu-initialized:not(.hs-mobile-state) .hs-sub-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-mega-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-sub-menu .hs-mega-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-mega-menu .hs-mega-menu.hs-reversed {
  left: auto;
  right: 100%;
}

.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-sub-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-mega-menu.hs-reversed {
  right: auto;
  left: 0;
}

.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-sub-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-mega-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-sub-menu .hs-mega-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-mega-menu .hs-mega-menu.hs-reversed {
  right: auto;
  left: 100%;
}

/*------------------------------------
    Positions
    (only 'horizontal' direction)
------------------------------------*/
.hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-left {
  left: 0;
  right: auto;
}

.hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-right {
  left: auto;
  right: 0;
}

.hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-center {
  right: auto;
  left: 50%;
  transform: translate(-50%, 0);
  margin: auto;
}

/* purgecss end ignore */
.section-bandeau .devices-top-start-50 {
  top: 135px;
}
.section-bandeau .devices-top-start-50::before {
  content: "";
  background-color: #F2F5FA;
  border-radius: 60% 40% 30% 45%/60% 30% 70% 25%;
  width: 125%;
  height: 130%;
  position: absolute;
  top: -120px;
  opacity: 0.8;
}
.section-bandeau .device-tablet-img, .section-bandeau .device-browser-img {
  transition: transform 0.3s ease-in-out;
}
.section-bandeau .devices-rotated {
  transform: rotate(-7deg);
}
.section-bandeau .devices-rotated .devices-rotated-body {
  transform: translate3d(12%, 0, 0);
}
.section-bandeau .device-mobile-frame {
  border-radius: 0.5rem;
}
.section-bandeau .overlay {
  position: absolute;
  bottom: 0;
  background: linear-gradient(135deg, #3699FF 0%, #3699FF 25%, #3699FF 50%, #3699FF 75%, #3699FF 100%);
  height: 100%;
  border-radius: 18px;
  left: 1px;
  width: 100%;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
.section-bandeau .video-player-icon {
  color: #FFF;
  background-color: #377DFF;
  top: 35%;
  left: 44%;
}
.section-bandeau .video-player:hover .video-player-icon {
  background-color: #FFF;
  color: #377DFF;
}
.section-bandeau .video-player:hover ~ .image .overlay {
  opacity: 0.12;
}

@media (max-width: 991px) {
  .section-bandeau .heading {
    text-align: center;
  }
  .section-bandeau .essaigratuit {
    justify-content: center;
  }
}
@media (max-width: 1200px) {
  .avoid-page-jump {
    min-height: 255px;
  }
}
@media (max-width: 991px) {
  .avoid-page-jump {
    min-height: 130px;
  }
}
@media (max-width: 768px) {
  .avoid-page-jump {
    min-height: 0;
  }
}

.card-video .content-video .overlay {
  position: absolute;
  bottom: 0;
  background: linear-gradient(135deg, #3699FF 0%, #3699FF 25%, #3699FF 50%, #3699FF 75%, #3699FF 100%);
  height: 100%;
  border-radius: 18px;
  left: 1px;
  width: 100%;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
.card-video .content-video .video-player-icon {
  color: #FFF;
  background-color: #377DFF;
  width: 3rem;
  height: 3rem;
}
.card-video .content-video a {
  position: relative;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.card-video .content-video a:hover .video-player-icon {
  background-color: #FFF;
  color: #377DFF;
}
.card-video .content-video a:hover .image .overlay {
  opacity: 0.12;
}

.card.with-button:hover .accordion-content {
  max-height: 300px;
  transform: scaleY(1);
}
.card.with-button .accordion-content {
  /*transition: max-height 1s;
  position: absolute;
  background: $primary-color;
  color: #FFF;
  overflow: hidden;
  // "height: 0" not work with css transitions
  max-height: 0;*/
  position: absolute;
  background: #3699FF;
  bottom: -45px;
  border-radius: 0.5rem;
  transform-origin: 0 0%;
  width: 100%;
  transition: transform 0.25s;
  transform: scaleY(0);
}
.card.with-button .accordion-content:hover {
  background: #242939;
}
.card.with-button .accordion-inner {
  padding: 0 15px;
}
.card.with-button .accordion-toggle {
  background: #3699FF;
  display: block;
  font-size: 30px;
  margin: 0 0 10px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
}

.content-img {
  width: 100%;
  height: 100%;
  display: table;
  border-radius: 70% 40% 70% 40%;
}
.content-img img {
  border-radius: 40% 70% 40% 70%;
}
.content-img.tarifs {
  border-radius: 20% 73% 20% 73%;
}
.content-img.tarifs img {
  border-radius: 90% 20% 90% 20%;
}

.img-fit-cover {
  object-fit: cover;
}

.gratuitMois .content-img {
  width: 100%;
  height: 100%;
  display: table;
  border-radius: 70% 40% 70% 40%;
}
.gratuitMois .content-img img {
  border-radius: 40% 70% 40% 70%;
}

@media (max-width: 991px) {
  .gratuitMois figure {
    text-align: center;
  }
}
.section-interne .video-bg {
  min-height: 55vh;
}
.section-interne .video-bg .video-bg-content {
  position: relative;
  height: 100%;
  border-radius: 0.5rem;
}
.section-interne .video-bg .video-bg-replacer-img {
  background-image: url(/images/videos/working-in-office.webp);
}